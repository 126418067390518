<template>
  <div>
    <div class="popover-content-wrapper">
      <slot name="popover" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'base-map-marker',
    props: {
      label: { type: String },
      position: {
        type: Object,
        default: () => ({ lat: 0, lng: 0 }),
      },
      icon: {
        type: String,
        default() {
          return require("src/assets/icons-2/google-map-marker.svg");
        }
      }
    },
    data() {
      return {
        marker: null,
        popover: null,
      };
    },
    mounted() {
      this.$parent.getMap((map) => {
        this.marker = new window.google.maps.Marker({
          position: this.position,
          map: map,
          title: this.title || '',
          label: this.label || '',
          icon: this.icon,
        });
        this.marker.addListener('click', (event) => {
          this.$emit('click', event);
        });

        if (this.$slots.popover) this.setupPopover(map);
      });
    },
    methods: {
      getPopoverContent() {
        return this.$el.getElementsByClassName('popover-content-wrapper')[0].innerHTML;
      },
      setupPopover(map) {
        this.popover = new window.google.maps.InfoWindow({
          content: this.getPopoverContent(),
        });

        this.marker.addListener('mouseover', (event) => {
          this.popover.open(map, this.marker);
        });
        this.marker.addListener('mouseout', (event) => {
          this.popover.close(map, this.marker);
        });

        this.observer = new MutationObserver((mutations) => {
          this.popover.setContent(this.getPopoverContent());
        });

        this.observer.observe(this.$el.getElementsByClassName('popover-content-wrapper')[0], {
          attributes: true,
          childList: true,
          characterData: true,
          subtree: true,
        });
      },
    },
    watch: {
      position: {
        handler() {
          if (this.marker) this.marker.setPosition(this.position);
        },
        deep: true,
      },
      icon(val) {
        this.marker.setIcon(val);
      },
      label(val) {
        this.marker.setLabel(val);
      },
    },
    beforeDestroy() {
      this.marker.setMap(null);
      window.google.maps.event.clearInstanceListeners(this.marker);
    },
    render() {
      return null;
    },
  };
</script>

<style scoped>
  .popover-content-wrapper {
    display: none;
  }
</style>
