

import api from 'src/axiosWithDelimiterFile.js';



export default {
  namespaced: true,
  state: {
        id:null,
        carData:{},
        time_line:[],


  },

  mutations: { 
      
  },
  actions: {
    updateTimeLine({state},data){
        state.time_line.unshift(data);
    },
    fetchCarData({state},id){
            api.get(`teltonika/tracker/${id}`).then(res=>{
                if(res.data.msg === 'success'){
                    state.carData = res.data.data;
                }
            }).catch(err=>{console.log(err)})
    },
    fetchTimeline({state},{id,startDate,endDate}) {
          startDate = startDate.toISOString();
          endDate = endDate.toISOString();
          // let date_copy = new Date(date.getTime());
          // date_copy.setHours(0, 0, 0, 0);
          // let startDate = new Date(date_copy.getTime()).toISOString();
          // let endDate = new Date(date_copy.getTime());
          // endDate.setDate(endDate.getDate() + 1);
          // endDate = endDate.toISOString();
          api.get(
              `teltonika/devices/${id}/timeline?start=${startDate}&end=${endDate}`
            )
            .then((res) => {
              if (res.data.msg === "success") {
                state.time_line = res.data.data;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        
      }
  }
}
