<template>
  <div class="col pt-2 px-0 d-inline-block" style>

    <grid-layout
      style="background:inherit;"
      :layout.sync="layout.data"
      :col-num="12"
      :cols="customCols"
      :row-height="20"
      :is-draggable="true"
      :is-resizable="true"
      :responsive="true"
      :is-mirrored="false"
      :vertical-compact="true"
      :margin="[10, 10]"
      :use-css-transforms="true"
    >
      <grid-item
        :ref="'item'+item.i"
        :id="'item'+item.i"
        style="background: inherit;padding: 0;"
        class="col-sm-12"
        v-for="item in layout.data"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
        :is-resizable="false"
        drag-allow-from=".custom-draggable-icon-location"
      >
        <div style="height: 100%;overflow: hidden;">
          <component :ref="'com'+item.i" :is="item.componentName" v-bind="item.props?item.props:item.data" :predefined="item.data"></component>
        </div>
      </grid-item>
    </grid-layout>
  </div>
</template>
<script>
import VueGridLayout from "vue-grid-layout";
import StatsCard from "src/components/Cards/StatsCard";

import DeviceStatus from "../MainDashboard/components/DeviceStatus";

import DevicesStatistics from "@/pages/SWAM/core/Structures-Statistics/devices.vue"
import OperationalAlarmsStatistics from "@/pages/SWAM/core/Structures-Statistics/operational-alarms.vue"
import StatusAlarmsStatistics from "@/pages/SWAM/core/Structures-Statistics/status-alarms.vue"

import StructuresLocation from "../SWAM/core/StructuresLocations/index.vue"
import StructureStatistics from "../SWAM/core/Statistics/Overall.vue"

import StructureComparison from "../SWAM/core/Structure-Comparison/index"
import DeviceComparison from "../SWAM/core/Device-Comparison/index"
import AlarmsCount from "../SWAM/core/Alarms-Count/index";

import StructuresWithDevicesList from "../SWAM/components/StructuresWithDevicesList.vue"

import SolutionStatistics from "../SWAM/core/Solution-Statistics/index"

let statsCards = [
  {
    title: "150GB",
    subTitle: "Number",
    type: "warning",
    icon: "tim-icons icon-chat-33",
    footer: '<i class="tim-icons icon-refresh-01"></i> Update Now'
  }
];
export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    StatsCard,
    DeviceStatus,
    AlarmsCount,
    DevicesStatistics,
    OperationalAlarmsStatistics,
    StatusAlarmsStatistics,
    StructuresLocation,
    StructureStatistics,
    StructureComparison,
    DeviceComparison,
    StructuresWithDevicesList,
    SolutionStatistics
  },
  created() {
    this.getUserDashboards();
  },
  mounted() {
    let me = this;
    this.$eventBus.$on("changeCurrentDashboard", id => {
      me.changeCurrentDashboard(id);
    });
  },
  data() {
    return {

      dropDownFlag: false,
      dropDownFlagSettings: false,
      customCols: { lg: 12, md: 12, sm: 1, xs: 1, xxs: 1 },
      dashboards: [{ name: "default", active: true }],
      layout: {
        id: null,
        data: []
      },
      };
  },
  methods: {
    changeCurrentDashboard(id) {

      console.log(id)

      for (let i in this.dashboards) {
        this.dashboards[i].active = false;
      }

      let index = this.dashboards.findIndex(item => item.id == id);
      if (index != -1) {
        this.layout.data = this.dashboards[index].data;
        this.layout.id = this.dashboards[index].id;
      }
    },
    EditCurrentDash() {
      this.$router.push("/userDashboard");
    },
    removeCurrentDash() {
      if (this.layout.id != -2) {
        let index = this.dashboards.findIndex(
          item => item.id == this.layout.id
        );

        if (index != -1) {
          this.$api.delete(`user/dashboard/${this.layout.id}`);
          this.dashboards.splice(index, 1);
        }
      }
    },
    changeCurrentDash(item) {
      for (let i in this.dashboards) {
        this.dashboards[i].active = false;
      }

      item.active = true;

      this.layout.data = item.data;
      this.layout.id = item.id;
    },
    changeCurrentDashAndFlip(item, index2) {
      for (let i in this.dashboards) {
        this.dashboards[i].active = false;
      }

      item.active = true;

      this.layout.data = item.data;
      this.layout.id = item.id;

      let b = this.dashboards[3];
      this.dashboards[3] = this.dashboards[index2];
      this.dashboards[index2] = b;
    },
    setAsDefaultDash() {
      this.$api
        .patch(`/user/dashboard/${this.layout.id}/activate`)
        .then(res => {})
        .catch(err => {
          console.log("Error White Create User Dashboard.");
        });
    },
      setPropsData(dash) {
      for (let j in dash.data) {
        if (dash.data[j].data !== undefined && dash.data[j].data !== null) {
          dash.data[j].props = JSON.parse(dash.data[j].data);
        }
      }
    },
    async getUserDashboards() {

      let me = this;
      this.$api
        .get("user/dashboard")
        .then(res => {
          if (res) {
            me.dashboards = res.data.dashboards;
            let default_dash = res.data.default;
            if(Object.keys(default_dash).length != 0 && default_dash.constructor === Object){
              default_dash.active = 0;
              default_dash.name = 'default dashboard';
              default_dash.id = -2;
              me.dashboards.unshift(default_dash);
            }

            let flag = false;
            for (let i in me.dashboards) {
              me.setPropsData(me.dashboards[i]);
              if (me.dashboards[i].active) {
                me.layout.data = me.dashboards[i].data;
                me.layout.id = me.dashboards[i].id;
                me.layout.name = me.dashboards[i].name;
                flag = true;
                if (i > 0) {
                  let b = me.dashboards[0];
                  me.dashboards[0] = me.dashboards[i];
                  me.dashboards[i] = b;
                }
              }
            }

            if (!flag) {
              this.layout.data = default_dash.data;
              this.layout.id = default_dash.id;
              this.dashboards[0].active = true;
            }
          }
        })
        .catch(err => {
          console.log(err)
          console.log("Error White getting User Dashboards.");
        });
    },
    // async getUserDashboards() {
    //   this.$api
    //     .get("user/dashboard")
    //     .then(res => {
    //       if (res) {
    //         this.dashboards = res.data.dashboards;
    //         let data = this.getDefaultDash();
    //         if (data) {
    //           let dash = {
    //             active: 0,
    //             data: data,
    //             id: -2,
    //             name: "default dashboard"
    //           };
    //           this.dashboards.unshift(dash);
    //         }

    //         let flag = false;
    //         for (let i in this.dashboards) {
    //            this.setPropsData(this.dashboards[i]);
    //           if (this.dashboards[i].active) {
    //             this.layout.data = this.dashboards[i].data;
    //             this.layout.id = this.dashboards[i].id;
    //             flag = true;
    //           }
    //         }

    //         if (!flag) {
    //           this.layout.data = data;
    //           this.layout.id = -2;
    //           this.dashboards[0].active = 1;
    //         }
    //       }
    //     })
    //     .catch(err => {
    //       console.log("Error White getting User Dashboards.");
    //     });
    // },

    // getDefaultDash() {
    //   let user = localStorage.getItem("user");
    //   let userObject = user ? JSON.parse(user) : null;

    //   if (userObject && userObject.role) {
    //     if (userObject.role == "super_admin") {
    //       // console.log("ADMIN ....");
    //       return this.adminDashboard;
    //     } else {
    //       // console.log("AMC ....");
    //       return this.amcDashboard;
    //     }
    //   }
    //   return null;
    // }
  }
};
</script>
<style>
.custom-dropdown > .dropdown-menu::before {
  display: inline-block !important;
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  vertical-align: middle !important;
  content: "" !important;
  top: -5px !important;
  right: 30% !important;
  left: auto !important;
  color: #ffffff !important;
  border-bottom: 0.4em solid !important;
  border-right: 0.4em solid transparent !important;
  border-left: 0.4em solid transparent !important;
}
.modal-active {
  display: block;
}
.vue-grid-item.vue-grid-placeholder {
  background: green;
}

.vue-grid-item .vue-resizable-handle {
  z-index: 99999 !important;
  width: 15px;
  height: 15px;
  background-color: #e8e8e8;
  background-position: 65% 71%;
  border-radius: 20%;
  border-bottom-right-radius: 0px;
}

.dodo {
  display: inherit !important;
  left: 100%;
}
</style>
<style scoped>
.under-line:after {
  content: "";
  width: 100%;
  height: 4px;
  background: #41b580;
  position: absolute;
  left: 50%;
  bottom: -16px;
  transform: translateX(-50%);
}
.dash-item-settings {
  float: right;
  font-size: 14px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-20 {
  font-size: 20px;
}
.font-size-12 {
  font-size: 12px;
}
.color-black {
  color: black;
}
.pointer {
  cursor: pointer;
}
.font-size-16 {
  font-size: 16px;
}
.text-secondary-white {
  color: #f1e9e9;
}
.font-size-15 {
  font-size: 15px;
}
.custom-btns-location {
  top: -16px;
  right: 13px;
  z-index: 10;
}

.show-hide-widgets {
  top: -16px;
  right: 33px;
  z-index: 10;
  padding: 9px 25px;
}
.font-size-21 {
  font-size: 21px;
}

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.custom-location {
  background-color: #e8e8e8;
  width: 15px;
  height: 15px;
  top: 0px;
  right: 0px;
  text-align: center;
  padding: 2px 17px 15px 2px;
  border-bottom-left-radius: 20%;
  z-index: 100;
  cursor: pointer;
}

.custom-draggable-icon-location {
  background-color: #e8e8e8;
  color: #565454;
  width: 22px;
  height: 18px;
  top: 0px;
  left: 0px;
  text-align: center;
  padding: 2px 13px 15px 3px;
  border-bottom-left-radius: 20%;
  z-index: 100;
  cursor: move;
}

.pointer {
  cursor: pointer;
}
</style>
