<template>
  <sidebar-item
    v-if="canModule(M.alarms)"
    :link="{
      name: $t('sidebar.swam.swam'),
      cartLeft: true,
    }"
  >
    <sidebar-item
      v-if="canModule(M.analysis)"
      :link="{
        name: 'portfolio',
        path: '/SWAM/Structure-Portfolio/overall',
      }"
    >
    </sidebar-item>
    <sidebar-item
      :link="{
        name: $t('alarmsWords.operationalAlarms'),
        path: '/SWAM/Alarms/operational',
      }"
    >
    </sidebar-item>
    <sidebar-item
      v-if="canModule(M.status_alarms)"
      :link="{
        name: $t('deviceWords.statusAlarms'),
        path: '/SWAM/Alarms/status',
      }"
    >
    </sidebar-item>
    <sidebar-item
      v-if="canModule(M.iot)"
      :link="{ name: 'devices', path: '/SWAM/Devices/list' }"
    >
    </sidebar-item>
    <sidebar-item
      v-if="canModule(M.iot)"
      :link="{ name: 'analysis', path: '/SWAM/Structure-Portfolio/analysis' }"
    >
    </sidebar-item>
    <!-- <sidebar-item
      v-if="canModule(M.sensors)"
      :link="{ name: 'sensors', path: '/SWAM/Sensors/list' }"
    >
    </sidebar-item> -->
  </sidebar-item>
</template>

<script>
import SidebarItem from '../../../components/SidebarPlugin/SidebarItem.vue';
export default {
    name:"swam-sidebar-items",
    components:{
        SidebarItem
    }
}
</script>

<style>
</style>