


export default (store) => {
    

  
    return store.subscribe((mutation) => {
        
      switch (mutation.type) {
      case 'setToken':

        let token = mutation.payload;
        if(token){
            store.dispatch('init');
        }
            
        break;
      case 'devicesstore/setDuration':
        store.dispatch('devicesstore/feed')
        break;
      
      
      }
    })
  }