<template>
  <card class="high-card-shadow mb-0z" v-loading="!device">
        <div class="d-flex justify-content-between">
          <h3>Device Info</h3>
        </div>
        <div class="card-description row">
          <div class="col-lg-5  col-10 mb-1 sec-div mb-1 sec-div">
            <div class="row">
              <div class="col-5">
                <label>Name :</label>
              </div>
              <div class="col-7 blue" v-if="device">{{device.device_name}}</div>
            </div>
          </div>
          <div class="col-lg-5  col-10 mb-1 sec-div">
            <div class="row">
              <div class="col-5">
                <label>Communication Type:</label>
              </div>
              <div class="col-7 blue" v-if="device">{{device.communication}}</div>
            </div>
          </div>
          <div class="col-lg-5  col-10 mb-1 sec-div">
            <div class="row">
              <div class="col-5">
                <label>Contract Duration :</label>
              </div>
              <div class="col-7 blue" v-if="device">{{device.contract_duration}}</div>
            </div>
          </div>
          <div class="col-lg-5  col-10 mb-1 sec-div">
            <div class="row">
              <div class="col-5">
                <label>Paying Customer :</label>
              </div>
              <div class="col-7 blue" v-if="device">{{device.paying_customer}}</div>
            </div>
          </div>
          <div class="col-lg-5  col-10 mb-1 sec-div">
            <div class="row">
              <div class="col-5">
                <label>AMC :</label>
              </div>
              <div class="col-7 blue" v-if="device">{{device.amc}}</div>
            </div>
          </div>
          <div class="col-lg-5  col-10 mb-1 sec-div">
            <div class="row">
              <div class="col-5">
                <label>FMC :</label>
              </div>
              <div class="col-7 blue" v-if="device">{{device.fmc}}</div>
            </div>
          </div>
          <div class="col-lg-5  col-10 mb-1 sec-div">
            <div class="row">
              <div class="col-5">
                <label>Authority Name :</label>
              </div>
              <div class="col-7 blue" v-if="device">{{device.authority_name}}</div>
            </div>
          </div>
          <div class="col-lg-5  col-10 mb-1 sec-div ">
            <div class="row">
              <div class="col-5">
                <label>Structure :</label>
              </div>
              <div class="col-7 blue" v-if="device">{{device.structure}}</div>
            </div>
          </div>
          <div class="col-lg-5  col-10 mb-1 sec-div">
            <div class="row">
              <div class="col-5">
                <label>Template Name :</label>
              </div>
              <div class="col-7 blue" v-if="device">{{device.template_name}}</div>
            </div>
          </div>
          <div class="col-lg-5  col-10 mb-1 sec-div">
            <div class="row">
              <div class="col-5">
                <label>Alarm Template :</label>
              </div>
              <div class="col-7 blue" v-if="device">{{device.alarm_notification_template_name}}</div>
            </div>
          </div>
          <div class="col-lg-5  col-10 mb-1 sec-divT">
            <div class="row">
              <div class="col-5">
                <label>Device Details :</label>
              </div>
              <div class="col-7 blue" v-if="device">{{device.device_details}}</div>
            </div>
          </div>
          <div class="col-lg-5  col-10 mb-1 sec-divT">
            <div class="row">
              <div class="col-5">
                <label>Creation Date :</label>
              </div>
              <div class="col-7 blue" v-if="device">{{gDateFormat(device.created_at)}}</div>
            </div>
          </div>
        </div>
        <card class="card-end">
            <h6>Sensors</h6>
          <el-table :data="device.sensors" v-if="device" class="table-end">
            <el-table-column min-width="100" prop="id" label="ID" ></el-table-column>
            <el-table-column min-width="150" prop="name" label="Name"></el-table-column>
            <el-table-column min-width="250" prop="expectedDeathDate" label="Expiry Date" :formatter="(row, column, cellValue) => gDateFormat(cellValue)"></el-table-column>
          </el-table>
        </card>
      </card>
</template>

<script>
export default {
props:['device']
}
</script>

<style scoped>
@media (max-width: 575.98px) {
label{
  font-size:11px;
}
.sec-divT{
      font-size: 10px;
}
  .sec-div{
    font-size: 10px;
  }
 }

@media (min-width: 576px) and (max-width: 767.98px) {
  label{
  font-size:11px;
}

  .sec-divT{
      font-size: 10px;
}
  .sec-div{
    font-size: 10px;
  } }


@media (min-width: 768px) and (max-width: 991.98px) { label{
  font-size:11px;
}
 .sec-divT{
      font-size: 10px;
}
  .sec-div{
    font-size: 10px;
  }}


@media (min-width: 992px) and (max-width: 1199.98px) {
  label{
  font-size:11px;
}
  .sec-divT{
      font-size: 10px;

}
  .sec-div{
    font-size: 10px;
  }}

  @media (min-width: 1200px) {

   }
label{
  color:white;
  font-family: MTextFont;
}
.blue{
  color:rgb(129, 133, 171);
  font-family: greyFont;
}
.sec-div .row div::before{
     content: '';
    background-color: #b0b0b9
    ;
    width: 100%;
     height: 1px;
    left: 0;
    position: absolute;
    bottom: -1px;

}

.sec-div .row div:last-child::before{
     content: '';
    background-color: #fafafa;
    width: 90%;
    height: 1px;
    left: 0;
    position: absolute;
    bottom: -1px;

}
.white-content .sec-div .row div:last-child::before{
     content: '';
    background-color: #6d7a9c;
    width: 90%;
    height: 1px;
    left: 0;
    position: absolute;
    bottom: -1px;

}
.sec-div{

 /*border-bottom: 1px solid #8a7f7f;*/
    margin: 4px;
}
.sec-divT{

    margin: 4px;     margin-top: 5px;
}
.row{
      justify-content: center;
}
.white-content .table-end{
  background-color: transparent;
}
.table-end{
  background-color: rgb(42, 44, 64);
}
.card-end{
  margin-top: 5px;
}

.table-end >>> td{
  color:#a8acd2 !important
}
.mb-1, .my-1 {
    margin-bottom: 0rem !important;}
</style>
