<template>
  <div class="text-center">
    <input
      type="file"
      accept="image/*"
      hidden
      style="position:absolute; height:100%;width:100%; z-index:2px; opacity:0"
      id="uploader"
      @change="onImageChange"
    />
    <base-dropdown class="my-dropdown">
      <template slot="title">
        <img class="avatar mb-0" :src="imgPath" :alt="alt" style="z-index:-2px" />
      </template>
      <div class="image-dropdown">
        <li class="nav-link" v-if="canEdit" onclick="document.getElementById('uploader').click()">Load new Image</li>
        <li class="nav-link" v-if="imgURL" @click="openImageURL">View Image</li>
        <li class="nav-link" v-if="imgURL && canEdit" @click="$emit('deleteImg')">Delete Image</li>
      </div>
    </base-dropdown>
  </div>
</template>

<style  scoped>
::v-deep .dropdown .dropdown-toggle {
  background: transparent;
  border: none;
  outline: none;
}
::v-deep .dropdown .dropdown-menu {
  left: 35%;
}

.dropdown {
  display: inline-block;
}
</style>

<script>
export default {
  name: "profile-picture",
  props: {
    imgURL: {
      type: String,
      default() {
        return "";
      }
    },
    placeholderURL: {
      type: String,
      default() {
        return "";
      }
    },
    canEdit: {
        type: Boolean
    },
    alt: {
      type: String
    }
  },
  computed: {
    imgPath() {
      if (this.imgURL) return this.imgURL;
      return this.placeholderURL;
    }
  },
  methods: {
    openImageURL() {
      this.$store.commit("openImage", { img: this.imgURL });
    },
    validateSize(file) {
      const FileSize = file.size / 1024 / 1024; // in MB
      if (FileSize > 3) {
        return false;
      }
      return true;
    },
    async onImageChange(event) {
      let userImage = event.target.files[0];

      if (!userImage) {
        return;
      }

      if (!userImage.type.startsWith("image/")) {
        this.$notify({
          title: "Wrong File Formats",
          message: "Only images are allowed",
          timeout: 5000,
          type: "danger"
        });
        return;
      }
      if (!this.validateSize(userImage)) {
        console.log("size exceeded");
        this.$notify({
          title: "Image size limit exceeded",
          message: "Profile Image must be less than 3 MB",
          timeout: 5000,
          type: "danger"
        });
        return;
      }
      let base64 = userImage ? await this.$helper.getBase64(userImage) : null;
      this.$emit("imageChanged", base64);
    }
  }
};
</script>