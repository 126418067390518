import SwamService from '@/pages/SWAM/services/swam.service';
import {dateFormat} from '@/util/helper'
import { twoPeriods } from '@/util/periodOptions';

export default {
    namespaced: true,
    state: () => ({  
        currentStructureId: undefined,
        currentSolutionId: undefined,
        currentSensorId: undefined,
        currentPeriodIndex: 0,
        loading: false,
        period1:{
            performance:{
                ok:{count:0},
                poor:{count:0},
                problem:{count:0},
            },
            stats:{
              min: '-',
              avg: '-',
              max: '-',
            },
            measurements:[],
            dates:[],
          },
          period2:{
            performance:{
              ok:{count:0},
              poor:{count:0},
              problem:{count:0},
            },
            stats:{
              min: '-',
              avg: '-',
              max: '-',
            },
            measurements:[],
            dates:[],
        },
        ranges:[]
    }),
    getters:{
        loading(state) {
            return state.loading;
        },
        periodsOptions(state) {
          return twoPeriods;
        },
        firstPeriod(state) {
            return state.period1;
        },
        secondPeriod(state) {
            return state.period2;
        },
        performanceData(state) {
            let result = [];
            // firstPeriod
            result.push({
              title: twoPeriods[state.currentPeriodIndex].periods[0].title,
              ok:state.period1.performance.ok.count,
              poor:state.period1.performance.poor.count,
              problem:state.period1.performance.problem.count,
            });
            // secondPeriod
            result.push({
              title: twoPeriods[state.currentPeriodIndex].periods[1].title,
              ok:state.period2.performance.ok.count,
              poor:state.period2.performance.poor.count,
              problem:state.period2.performance.problem.count,
            });
            return result;
        },
        gaugeChartData(state) {
            let result = [];
            result.push({
                title: twoPeriods[state.currentPeriodIndex].periods[0].title 
                + ' ' 
                + dateFormat(twoPeriods[state.currentPeriodIndex].periods[0].from())
                + ' -> '
                + dateFormat(twoPeriods[state.currentPeriodIndex].periods[0].to()),
                stats: state.period1.stats,
                sensorTypeId:state.currentSensorId,
                ranges:state.ranges
              });
              result.push({
                title: twoPeriods[state.currentPeriodIndex].periods[1].title 
                + ' ' 
                + dateFormat(twoPeriods[state.currentPeriodIndex].periods[1].from())
                + ' -> '
                + dateFormat(twoPeriods[state.currentPeriodIndex].periods[1].to()),
                stats: state.period2.stats,
                sensorTypeId:state.currentSensorId,
                ranges:state.ranges
            });
            return result;
        },
        firstPeriodMeasurements(state){
          return state.period1.measurements;
        },
        secondPeriodMeasurements(state){
          return state.period2.measurements;
        },
        firstPeriodMeasurementsDates(state){
          return state.period1.dates;
        },
        secondPeriodMeasurementsDates(state){
          return state.period2.dates;
        },
    },
    mutations:{
        setLoading(state,value) {
            state.loading = value;
        },
        selectStructure(state,value) {
            state.currentStructureId = value;
        },
        selectSoluiton(state,value) {
            state.currentSolutionId = value;
        },
        selectSensor(state,value) {
            state.currentSensorId = value;
        },
        selectPeriod(state,value) {
            state.currentPeriodIndex = value;
        },
        selectCustomPeriod(state,value) {
          twoPeriods[5].periods = value;
        },
        setData(state,data) {
            state.period1 = data.p1;
            state.period2 = data.p2;
            const x = data.p1.dates.length;
            const y = data.p2.dates.length;
            if(x != y){
              const z = Math.min(x, y);
              if(z == x){
                data.p1.dates.push("");
                data.p1.measurements.push(null);
              }else{
                data.p2.dates.push("");
                data.p2.measurements.push(null);
              }
            }
            state.ranges = data.ranges;
        }
    },
    actions:{
        async fetchData({ state, commit, dispatch }) {
            if(!state.currentSolutionId || !state.currentSensorId)
                return;
            const by = 'structure';// temporarily
            commit('setLoading',true);
            try {
              let params = {
                by,
                id:state.currentStructureId,
                sensor:state.currentSensorId,
                solution:state.currentSolutionId,
                p1from: twoPeriods[state.currentPeriodIndex].periods[0].from().toDate(),
                p2from: twoPeriods[state.currentPeriodIndex].periods[1].from().toDate(),
                p1to: twoPeriods[state.currentPeriodIndex].periods[0].to().toDate(), 
                p2to: twoPeriods[state.currentPeriodIndex].periods[1].to().toDate(),
              };
              const data = await SwamService.getStructureComparisonData(params);
              commit('setData',data);
              commit("setLoading", false);
            } catch (error) {
              commit("setLoading", false);
              console.error(error);
            }
        },
        resetData({state}) {
          let p = {
            performance:{
                ok:{count:0},
                poor:{count:0},
                problem:{count:0},
            },
            stats:{
              min: '-',
              avg: '-',
              max: '-',
            },
            measurements:[],
            dates:[],
          };  
          state.period1 = p;
          state.period2 = p;
          state.ranges = [];
        },
        reset({state,dispatch}) {
          state.currentPeriodIndex = 0;
          state.currentStructureId = undefined;
          state.currentSolutionId = undefined;
          state.currentSensorId = undefined;
          dispatch('resetData');
        },
      }
}