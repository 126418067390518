import ForkliftService from "../../services/forklift.service"

export default {
  namespaced: true,
  state: {
    cities: [],
    mapData: [],
    loading: false
  },
  getters: {
    cities(state) {
      return state.cities
    },
    mapData(state) {
      return state.mapData
    },
    loading(state){
      return state.loading
    }
  },
  mutations: {
    SET_CITIES(state, payload) {
      state.cities = payload
    },
    SET_MAPDATA(state, payload) {
      state.mapData = payload
    },
    SET_LOADING(state, payload){
      state.loading = payload
    }
  },
  actions: {
    fetchCities({ commit }) {
      commit('SET_LOADING', true)
      return new Promise((resolve, reject) => {
        ForkliftService.getCities().then(res => {
          let cities = []
          res.data.data.result.map(city => {
            Object.defineProperty(city, 'title',
              Object.getOwnPropertyDescriptor(city, 'name'));
            delete city['name'];
            cities.push(city)
          })
          commit('SET_LOADING', false)
          commit('SET_CITIES', cities)
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    fetchMapData({ commit }, payload) {
      const city_ids= [payload.id, payload.id]
      return new Promise((resolve, reject) => {
        ForkliftService.getMap(city_ids).then(res => {
          commit('SET_MAPDATA', res.data.data)
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
  }
}