<template>
  <router-view :key="$route.path"></router-view>
</template>

<script>
export default {
  destroyed(){
    this.$store.commit('reset_permissions_store')
    this.$store.unregisterModule('PermissionsStore')
  }
}
</script>

<style>

</style>