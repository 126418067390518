<template>
  <base-nav v-model="showMenu" class="navbar-absolute top-navbar custom-navbar font-color-5" type="white"
            :transparent="true">
    <div slot="brand" class="navbar-wrapper">
      <el-tooltip
        :content="!fixNavbar?'Fix Navbar':'Hide Navbar'"
        effect="light"
        :open-delay="300"
        placement="right"
      >
        <span class="fixed-nav-area" v-on:click="$emit('fixnavbar',fixNavbar=!fixNavbar)">
          <i
            :class="fixNavbar ? 'primary-color' : ''"
            class="rotate-90 fas fa-thumbtack"
          ></i>
        </span>

      </el-tooltip>
      <div class="navbar-minimize d-inline">
        <sidebar-toggle-button class="sidebar-toggle-btn font-color-5"/>
      </div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>

      </div>
      <button class="back-button font-color-5" @click="goBack" style="cursor: pointer">
        <i
          :class="$rtl.isRTL ? 'icon-minimal-right' : 'icon-minimal-left'"
          class="tim-icons font-weight-bolder mb-1"
        ></i>
      </button>
      <h2 class="navbar-brand font-color-5 mb-1">{{ $t(routeName.toLowerCase()) }}</h2>

      <!--      <div class="search-bar input-group m-0" @click="searchModalVisible = true">-->
      <!--        <button-->
      <!--          class="btn btn-link d-flex justify-content-center align-items-center"-->
      <!--          id="search-button-0"-->
      <!--          data-toggle="modal"-->
      <!--          data-target="#searchModal"-->
      <!--        >-->
      <!--          <i class="fas fa-search font-color-5 mx-1 f-18"></i>-->
      <!--          <p class="font-color-5  f-12">{{$t('nav.searchParagraph')}}</p>-->
      <!--        </button>-->
      <!--      </div>-->
      <!--      <modal-->
      <!--        :show.sync="searchModalVisible"-->
      <!--        class="modal-search"-->
      <!--        id="searchModal"-->
      <!--        :centered="false"-->
      <!--        :show-close="true"-->
      <!--      >-->
      <!--        <input-->
      <!--          slot="header"-->
      <!--          v-model="searchQuery"-->
      <!--          type="text"-->
      <!--          class="form-control"-->
      <!--          id="inlineFormInputGroup"-->
      <!--          placeholder="SEARCH"-->
      <!--        />-->
      <!--      </modal>-->
      <ul class="navbar-nav list-unstyled d-flex justify-content-center align-items-center"
          :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
        <!--       <div class="search-bar input-group" @click="searchModalVisible = true">-->
        <!--        <button-->
        <!--          class="btn btn-link"-->
        <!--          id="search-button"-->
        <!--          data-toggle="modal"-->
        <!--          data-target="#searchModal"-->
        <!--        >-->
        <!--          <i class="tim-icons icon-zoom-split"></i>-->
        <!--        </button>-->
        <!--      </div>-->
        <!--      <modal-->
        <!--        :show.sync="searchModalVisible"-->
        <!--        class="modal-search"-->
        <!--        id="searchModal"-->
        <!--        :centered="false"-->
        <!--        :show-close="true"-->
        <!--      >-->
        <!--        <input-->
        <!--          slot="header"-->
        <!--          v-model="searchQuery"-->
        <!--          type="text"-->
        <!--          class="form-control"-->
        <!--          id="inlineFormInputGroup"-->
        <!--          placeholder="SEARCH"-->
        <!--        />-->
        <!--      </modal>-->

        <!-- lang switch -->
        <li class="nav-item">
          <div
            v-if="allowTranslation"
            @click="SwitchLang"
          >
            <button
              class="btn btn-link m-0"
              id="search-button"
              data-toggle="modal"
              data-target="#searchModal"
            >
              <i class="fas fa-globe-europe font-color-5 f-18"></i>
            </button>
          </div>
        </li>
        <!-- Notifications -->
        <li class="nav-item">
          <el-popover
            placement="bottom"
            width="400"
            trigger="manual"
            v-model="notificationsVisible"
          >
            <NotificationList/>
            <!-- Alarm Icon -->
            <div class="navbar-minimize d-lg-block d-xl-block" slot="reference">
              <button
                class="btn btn-link btn-just-icon m-0"
                @click="notificationsVisible = !notificationsVisible"
              >
                <i class="fas fa-bell font-color-5 last-icon f-18"></i>
              </button>
            </div>
          </el-popover>
        </li>
        <!--      <li class="li-divider"></li>-->
        <ul class="nav-item p-0">
          <ul class="list-unstyled">
            <el-popover
              placement="bottom"
              width="400"
              trigger="manual"
              v-model="dropdownVisible"
            >

              <li class="nav-link">
                <router-link to="/profiles/user/me" class="nav-item dropdown-item">Profile</router-link>
              </li>
              <!-- <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Settings</a>
              </li>-->
              <li class="dropdown-divider"></li>
              <li class="nav-link">
                <a href="#" @click="logout" class="nav-item dropdown-item">Log out</a>
              </li>
              <!-- Alarm Icon -->
              <li class="navbar-minimize d-lg-block d-xl-block" slot="reference">
                <div
                  @click="dropdownVisible = !dropdownVisible"
                  class="nav-title-area d-flex justify-content-center align-items-center">
                  <div
                    class="photo"
                    :class="{
              'socket-connected': socketConnected,
              socket_disconnected: !socketConnected,
            }"
                  >
                    <img :src="avatar ? avatar : '/img/placeholder.jpg'" alt/>
                  </div>
                  <i class="fas fa-ellipsis-v mobile-menu"></i>
                  <b class="caret d-none d-lg-block d-xl-block"></b>
                </div>
              </li>
            </el-popover>
          </ul>
        </ul>
      </ul>
    </div>
  </base-nav>
</template>
<script>
  import {CollapseTransition} from "vue2-transitions";
  import {BaseNav, Modal} from "@/components";
  import SidebarToggleButton from "./SidebarToggleButton";
  import {mapActions} from "vuex";
  import {translatedPages} from "../../store";

  import NotificationList from "../Notifications/NotificationList.vue";
  import {popover} from "element-ui";

  export default {
    components: {
      SidebarToggleButton,
      CollapseTransition,
      BaseNav,
      Modal,
      NotificationList,
      popover,
    },
    computed: {
      allowTranslation() {
        return translatedPages.some(tp=> this.$route.path.toLowerCase().includes(tp.toLowerCase()));
      },
      title() {
        return process.env.VUE_APP_TITLE;
      },
      routeName() {
        const {name} = this.$route;
        return name;
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
      avatar() {
        let user = this.$store.state.user;

        if (user && user.img_path) {
          return user.img_path;
        }

      return null;
    },
    socketConnected(){
      return  this.$IoTSocket.is_connected && this.$TeltonikaSocket.is_connected;
      
    }
  },

    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: "",
        notificationsVisible: false,
        fixNavbar: false,
        dropdownVisible : false,

      };
    },
    methods: {
      //save choosen lang
      ...mapActions(["setPreferences"]),
      SwitchLang() {
        let lang = this.$i18n.locale;
        lang = lang === "ar" ? "en" : "ar";
        // if (lang === "ar") {
        //   document.getElementsByTagName("html")[0].setAttribute("lang", "en");
        //   localStorage.setItem("lang", "en");
        //   this.$i18n.locale = "en";
        //   this.$rtl.disableRTL();
        // } else {
        //   localStorage.setItem("lang", "ar");
        //   document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
        //   this.$i18n.locale = "ar";
        //   this.$rtl.enableRTL();
        // }

        this.setPreferences({language: lang});
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        console.log("toggleSidebar")
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      logout() {
        this.$api.post("user/logout").then((_) => {
          this.$store.commit("logout");
          this.$router.push({path: "/login"});
        });
      },
      goBack() {
        this.$router.back();
      },
    },
  };
</script>
<style scoped lang="scss">

  .top-navbar {
    top: 0px;
  }

  .navbar-wrapper {
    width: 100%;
    display: inline-flex;
  }

  .socket-connected {
    border: 4px solid green;
  }

  .socket_disconnected {
    border: 4px solid red;
  }

  .sidebar-toggle-btn {
    margin-left: -3px;
  }

  .nav-title-area{
    width: max-content;
    margin: 0 30px;
    cursor: pointer;
  }

  .nav-item {
    // margin: -10px !important;
  }

  .f-12 {
    font-size: 12px;
  }

  .f-15 {
    font-size: 15px !important;
  }

  .f-18 {
    font-size: 18px !important;
  }

  .navbar-nav li {
    padding: 0 5px;
  }

  .fixed-nav-area {
    position: absolute;
    z-index: 999999;
    top: 33px;
    right: 7px;
    cursor: pointer;
  }

  .rotate-90 {
    transform: rotate(40deg);
  }

  .dropdown {
    margin: 0 10px !important;
  }
  .mobile-menu{
    display: none;
  }
  @media (max-width: 992px) {
    [dir="rtl"] .navbar .caret {
      margin-right: 75px;
      margin-top: 1px;
    }
    .photo{
      display: none;
    }
    .mobile-menu{
      display: block;
    }
  }

  [dir="rtl"] ::v-deep .dropdown-menu.dropdown-navbar:before,
  [dir="rtl"] ::v-deep .dropdown-menu.dropdown-navbar:after {
    left: 17px;
    right: unset;
  }
  .dropdown-item:hover, .dropdown-item:focus{
    background-color: initial;
  }
  .nav-link:hover{
    background-color: #f6f9fc;
  }
</style>
<style lang="scss">
  .custom-navbar {
    position: fixed !important;
    height: 60px;
    /*border-radius: 0 0 25px 25px;*/
    /*padding: 5px;*/
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1)
  }

  .primary-color {
    color: $primary-green !important;
  }

  .back-button {
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }

  .back-button:hover {
    background: #00000030;
  }
  @media (max-width: 992px) {
    .navbar-collapse {
      background-color: $light-bg !important;
    }
     .navbar-nav  {
      flex-direction: row;
    }
    .nav-item{
      padding: 0 !important;
    }
    .nav-title-area .caret{
      display: none;
    }
    ul .nav-item button , .nav-title-area  , #search-button , .nav-btn{
      padding: 0;
      margin: 0;
    }
    .nav-title-area{
      width: max-content;
      margin: 10px !important;
    }
  }
</style>
