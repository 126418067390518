import api from "@/axiosWithDelimiterFile";
import SwamService from './swam.service';

export default {
    async list(query) {
      return SwamService.getDevicesList(query);
    },
    async find({id,query}) {
      const device = await api.get('',{
          params:query
      })
      
      return {
        data: device.data.data,
      };
    }
  };
  