

import {
    VSaaSCustomersService
  } from "../../../services/vsaas-services.bundle";
  import ActionsTypes from "../../types/actions-types";
  import MutationsTypes from "../../types/mutations-types";
  
  import { mapArrayOfObjectsToArrayOfCustomerTableSingleRow } from "../../../models/customer-table-single-row";
  import { mapArrayOfObjectsToArrayOfMapLocationWrapper } from "../../../models/map-location-wrapper";
  
  import USER_PREFERENCES_ACTIONS from "../../../../../global/user-preferences-store/action-types";
  import { USER_PREFERENCES_KEYS } from "../../../../../global/user-preferences-store/keys";



export const customerActions = {
    
  /**
   *
   * @param commit
   * @param customerId
   * @returns {Promise<boolean>}
   */
  [ActionsTypes.GETTING_CUSTOMER_INFORMATION_ACTION]({ commit }, customerId) {
    return new Promise(async (resolve, reject) => {
      const vsaasService = new VSaaSCustomersService();
      const dbResponse = await vsaasService.getCustomerInformation(customerId);
      if (dbResponse != null) {
        commit(MutationsTypes.GETTING_CUSTOMER_INFORMATION_MUTATION, dbResponse.data);
        resolve(dbResponse.data);
      } else {
        reject(null);
      }
    });
  },
    /**
   *
   * @param commit
   * @param queryParams
   * @returns {Promise<boolean>}
   */
  [ActionsTypes.GETTING_CUSTOMER_ACTION]({ commit }, queryParams) {
    return new Promise(async (resolve, reject) => {
      const vsaasService = new VSaaSCustomersService();
      const dbResponse = await vsaasService.getCustomerList(queryParams);
      if (dbResponse != null) {
        // Sanitizing
        const customerList = dbResponse.data;

        const contactsPromises = [];
        customerList.forEach(customer => {
          contactsPromises.push(
            vsaasService.getCustomerContacts(customer.customer_id)
          );
        });

        await Promise.all(contactsPromises)
          .then(contacts => {
            customerList.forEach((customer, i) => {
              customer.contacts = contacts[i].data;
            });

            const customersWithContacts = mapArrayOfObjectsToArrayOfCustomerTableSingleRow(
              dbResponse.data
            );

            // Comitting
            commit(
              MutationsTypes.GETTING_CUSTOMER_MUTATION,
              customersWithContacts
            );
            commit(
              MutationsTypes.CUSTOMER_TOTAL_RECORDS_MUTATION,
              dbResponse.totalRecords
            );
          })
          .catch(contactsError => {
            console.log("contacts error [action.js 105]: ", contactsError);
            reject(false);
          });
        resolve(true);
      } else {
        reject(false);
      }
    });
  },


  /**
   *
   * @param commit
   * @returns {Promise<boolean>}
   */
  [ActionsTypes.CUSTOMER_LOCATIONS_ARRAY_ACTION]({ commit }) {
    return new Promise(async (resolve, reject) => {
      const vsaasService = new VSaaSCustomersService();
      const dbResponse = await vsaasService.getCustomerLocationsArray();
      if (dbResponse != null) {
        // Sanitizing data
        const locations = mapArrayOfObjectsToArrayOfMapLocationWrapper(
          dbResponse.data
        );
        // Committing
        commit(MutationsTypes.CUSTOMER_LOCATIONS_ARRAY_MUTATION, locations);
        resolve(true);
      } else {
        reject(false);
      }
    });
  },


    
  /**
   *
   * @param commit
   * @param queryParams
   * @returns {Promise<boolean>}
   */
  [ActionsTypes.CUSTOMER_CONTACTS_LIST_ACTION]({ commit }, customerId) {
    return new Promise(async (resolve, reject) => {
      const vsaasService = new VSaaSCustomersService();
      const dbResponse = await vsaasService.getCustomerContacts(customerId);
      if (dbResponse != null) {
        commit(MutationsTypes.CUSTOMER_CONTACTS_LIST_MUTATION, dbResponse.data);
        resolve(true);
      } else {
        reject(false);
      }
    });
  },
}