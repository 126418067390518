<template>
  <card
    class="overflow-hidden full-height-card"
    bodyClasses="p-0"
  >
    <div @mouseover="isHovering = true" @mouseout="isHovering = false">
      <base-map :zoom="zoom" :center="center" :options="mapOptionsStyle" :style="`height: ${height}`">
        <template v-for="(marker, index) in mapMarkers">
          <base-map-marker
            :key="index"
            :position="marker"
            :title="marker.structure_name"
            :label="markerLabel(marker)"
            :icon="markerIcon"
            @click="onMarkerClicked(marker)"
          />
        </template>
        <base-heatmap v-if="showHeatmap" :heatmapData="heatmapData" :radius="60"/>
      </base-map>
    </div>
    <div slot="footer">
      <slot name="footer">
        <div>
          <slot name="left-footer"></slot>
        </div>
        <div>
          <slot name="right-footer"></slot>
        </div>
      </slot>
    </div>
  </card>
</template>

<script>
// it's an array to style map
import mapStyle from "./MapStyles";
import BaseMap from 'src/components/BaseMap/Map'
import MapMarker from "src/components/BaseMap/MapMarker.vue";
import HeatMap from "src/components/BaseMap/HeatMap.vue";


export default {
  name: "Locations",
  components: {
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker,
    [HeatMap.name]: HeatMap,
  },
  props: {
    markers:{
      default: ()=>[]
    },
    center:{
      default: ()=> ({
        lat: 25.221643,
        lng: 55.255257
      })
    },
    mapType:{
      default: 1
    },
    zoom:{
      default: 11
    },
    markerIcon:{
      default(){
        return require("src/assets/icons-2/google-map-marker.svg")
      }
    },
    markerLabel: {
      type: Function,
      default: (marker) => {
        return ''
      }
    },
    height:{
      type: String,
      default: '250px'
    },
    showHeatmap: {
      type: Boolean
    }
  },
  data: function(){
    return{
      isHovering: false,
      mapOptions: {
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
      },
    };
  },
  computed: {
    mapMarkers() {
      return this.markers.map((s) => {
        return {...s, lat: +s.lat, lng: +s.lng};
      });
    },
    mapOptionsStyle() {
      let defaultMapOptions = { ...this.mapOptions }

      if (this.mapType != 1) defaultMapOptions.styles = mapStyle
      else defaultMapOptions.styles = []

      return defaultMapOptions;
    },
    heatmapData() {
      return this.mapMarkers.map(marker => ({
        lat: marker.lat,
        lng: marker.lng,
        weight: marker.activeIntrusionAlarms
      }));
    }
  },
  methods: {
    onMarkerClicked(pos) {
      this.$emit("onMarkerClicked", pos);
    },
  },
};
</script>

<style>
</style>
