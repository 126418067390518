import AddLock from '../pages/addLock/store'
import Locks from '../pages/locks/store'
export default {
    namespaced: true,

    modules: {
        AddLock,
        Locks
    },

    state: {},

    getters: {},

    mutations: {},

    actions: {}
}