import api from "src/axiosWithDelimiterFile";
import { VSaaSConfigurations } from "./configuration";
import { resolvePromiseForObjectOrNull } from "../../common/wrappers/promise-resolver";

export class VSaaSCustomersService {
  /**
   * 
   * @param {Integer} customerId 
   */
  async getCustomerInformation(customerId) {
    return resolvePromiseForObjectOrNull(
      api.get(VSaaSConfigurations.GETTING_CUSTOMER_INFORMATION(customerId))
    );
  }


  /**
   *
   * @param {Object} QueryParams
   */
  async getCustomerList(QueryParams) {
    return resolvePromiseForObjectOrNull(
      api.get(VSaaSConfigurations.GETTING_VILLAS_URL, {
        params: QueryParams
      })
    )
  }

  /**
   * 
   * @param {Integer} customerId 
   */
  async getCustomerContacts(customerId) {
    return resolvePromiseForObjectOrNull(
      api.get(VSaaSConfigurations.GETTING_CUSTOMER_CONTACTS(customerId))
    );
  }

  /**
   * 
   */
  async getCustomerLocationsArray() {
    return resolvePromiseForObjectOrNull(api.get(VSaaSConfigurations.GETTING_VILLAS_FOR_MAP_URL));
  }
}
