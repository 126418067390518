import SwamService from '@/pages/SWAM/services/swam.service';
export default {
    namespaced:true,
    state:() => ({
        loading:false,
        structureData: [
            {ok:0,poor:0,problem:0},
            {ok:0,poor:0,problem:0},
        ],
    }),
    getters: {
        loading(state) {
            return state.loading;
        },
        getData(state) {
             let result = state.structureData.map(s => {
                return{
                    id:s.id,
                    ...s.status,
                }
            })
            result.splice(2);
            return result;
        }
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setData(state,data) {
            state.structureData = data;
        },
    },
    actions: {
        async fetchData({state,commit}) {
            commit('setLoading',true);
            try {
                let params = {
                    interval:'day',
                };
                const data = await SwamService.getHighestProblems(params);
                commit('setData',data);
                commit("setLoading", false);
            } catch (error) {
                commit("setLoading", false);
                console.error(error);
            }
        },
        reset({state}) {
            state.structureData = [
                {ok:0,poor:0,problem:0},
                {ok:0,poor:0,problem:0},
            ];
        }
    },
}