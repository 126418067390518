
import io from 'socket.io-client';


export default {
    install(Vue) {

        let TeltonikaSocket = new Vue({
            data(){
                return{
                    socket: io(`${process.env.VUE_APP_URL}teltonika`, {
                        autoConnect: false,
                        path: '/ws/socket.io'
                    }),
                    is_connected: false,
                    events: []
                }
            },
            methods:{
                open(token){
                    let vm = this;
                    this.socket.io.opts.query =  `token=${token}`;
                    
                    this.socket.open();
                    this.socket.on('connect', function () {
                        console.log('teltonika socket connected....');
                        vm.is_connected = true;

                    });
                    this.socket.on('disconnect',function(){
                        console.log('socket disconnected...')
                        vm.is_connected = false;
                    })


                },

               
                subscribe(event, callback) {
                    if (this.socket ) {
                        if (this.events.indexOf(event) === -1) {
                            this.socket.on(event, callback);
                            this.events.push(event);
    
                        }
    
                    }
    
                },
                unSubscribeAll() {
                    if (this.socket) {
                        console.log(this.events)
                        this.events.forEach(event => {
                            this.socket.off(event)
                        })
                        this.events = [];
                    }
                },
                emit(event,data){
                    if(this.socket){
                        this.socket.emit(event,data)
                    }
                }



            }
        });

       

        Vue.prototype.$TeltonikaSocket = TeltonikaSocket;

    }
}