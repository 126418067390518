import { app } from "../../../../main"
import AlarmsActionsStore from "@/pages/SWAM/components/AlarmsComponents/AlarmsActionsStore";
import {
    dateTableCellFomatter,
} from "@/modules/common/utils/helper";
import i18n from '@/i18n'
import SwamService from '@/pages/SWAM/services/swam.service';

export default {
    namespaced: true,
    modules: {
        AlarmsActionsStore
    },
    state: {
        loading: false,
        alarms: [],
        totalAlarms: 0,
        filter: {
            solutionId: undefined,
            structureId: undefined,
            cityId: undefined,
            communityId: undefined,
            acknowledgement: undefined,
            status: undefined, // ok || poor || problem
        },
        pagination: {
            limit: 10,
            offset: 0,
        },
        isSocketConnected: false,
        currentAction: null,
        columns:[
            {
                prop:"id",
                label:i18n.t("alarmID"),
                minWidth:"100",
            },
            {
                prop:"device.id",
                label:i18n.t("deviceWords.device"),
                minWidth:"210",
                customTemplate: true
            },
            {
                prop:"status",
                label:i18n.t("sensorWords.sensor"),
                minWidth:"170",
                customTemplate: true
            },
            {
                prop:"acknowledgement.created_at",
                label:i18n.t("acknowledgedBy"),
                minWidth:"200",
                customTemplate: true
            },
            {
                prop:"created_at",
                label:i18n.t("receiveTime"),
                minWidth:"150",
                formatter: dateTableCellFomatter
            },
            {
                prop:"updated_at",
                label:i18n.t("lastUpdate"),
                minWidth:"150",
                formatter: dateTableCellFomatter
            },
            {
                prop:"device.structure_name",
                label:i18n.t("deviceWords.location"),
                minWidth:"200",
            },
            {
                prop:"alarm_number",
                label:i18n.t("alarmsCount"),
                minWidth:"140",
            },
            {
                prop:"ended_at",
                label:i18n.t("normalizationTime"),
                minWidth:"140",
                formatter: dateTableCellFomatter
            },
        ],
    },
    getters: {
        alarms(state) {
            return state.alarms
        },
        totalAlarms(state) {
            return state.totalAlarms;
        },
        loading(state) {
            return state.loading
        },
        filter(state) {
            return state.filter;
        },
        isLive(state) {
            return state.isSocketConnected;
        },
        pagination(state) {
            return state.pagination;
        },
        statusFilterOptions(state) {
            return [
                { title: 'ok', value: false },
                { title: 'poor', value: false },
                { title: 'problem', value: false },
            ]
        },
        columns(state) {
            return state.columns;
        }
    },
    mutations: {
        reset(state) {
            state.loading = false;
            state.alarms = [];
            state.totalAlarms = 0;
            state.filter = {
                solutionId: undefined,
                structureId: undefined,
                cityId: undefined,
                communityId: undefined,
                acknowledgement: undefined,
                status: undefined, // ok || poor || problem
            };
            state.pagination = {
                limit: 10,
                offset: 0,
            };
            state.isSocketConnected = false;
            state.currentAction = null;
        },
        destroy() {

        },
        setFilter(state, newFilter) {
            state.filter = newFilter;
        },
        setPaginationFilter(state, data) {
            state.pagination = data;
        },
        resetPaginationFilter(state, data) {
            state.pagination = {
                limit: 10,
                offset: 0,
            };
        },
        startLoading(state) {
            state.loading = true;
        },
        finishLoading(state) {
            state.loading = false;
        },
        setAlarmsData(state, data) {
            state.alarms = data.data;
            state.totalAlarms = data.total_records;
        },
        changeConnectionState(state, newState) {
            state.isSocketConnected = newState;
        },
        addNewAlarm(state, newAlarm) {
            let index = state.alarms.findIndex((a) => a.id == newAlarm.id);
            if (index > -1) {
                state.alarms.splice(index, 1, newAlarm);
            } else {
                state.alarms.unshift(newAlarm);
                state.totalAlarms += 1;
            }
        },
        updateAlarm(state, data) {
            let index = state.alarms.findIndex((alarm) => alarm.id == data.id);
            if (index > -1) {
                let alarm = state.alarms[index];
                alarm.updated_at = data.updated_at;
                state.alarms.splice(index, 1, alarm);
            }
        },
        deleteAlarm(state, data) {
            let index = state.alarms.findIndex((alarm) => alarm.id == data.id);
            if (index > -1) {
                state.alarms.splice(index, 1);
                state.totalAlarms -= 1;
            }
        },
        ackAlarm(state, data) {
            let acknowledgeData = {
                ack_by: data.user ? data.user.id : null,
                ack_date: data.ack_date,
                user: data.user,
                status: data.ack_date ? "acknowledged" : "pending",
            };
            let index = state.alarms.findIndex((alarm) => alarm.id == data.id);
            if (index > -1) {
                let alarm = state.alarms[index];
                alarm = { ...alarm, ...acknowledgeData };
                state.alarms.splice(index, 1, alarm);
            }
        }
    },
    actions: {
        async updateFilter({ state, commit, dispatch }) {
            const {
                limit = state.pagination.limit,
                offset = state.pagination.offset,
                solutionId,
                structureId,
                cityId,
                communityId,
                acknowledgement,
                status
            } = state.filter;
            let params= {
                limit,
                offset,
                solution_id: solutionId,
                structure_id: structureId,
                city_id: cityId,
                community_id: communityId,
                acknowledged: acknowledgement,
                status
            };
            commit("startLoading")
            try {
                const data = await SwamService.getOperationalAlarms(params);
                commit('setAlarmsData', data);
                commit("finishLoading")
            } catch (error) {
                commit("finishLoading")
                console.error(error);
            }
        },
        getComments({ state, commit, dispatch, rootState }, alarm) {
            commit("startLoading")
            return SwamService.getAlarmComments('operational',alarm.id)
                .finally(_ => {
                    commit("finishLoading")
                })
        },
        submitComment({ state, commit, dispatch, rootState }, { alarm, msgText }) {
            return SwamService.submitAlarmComment('operational',alarm.id,msgText);
        },
        escalate({ state, commit, dispatch, rootState }, { alarm, data }) {
            this.commit("setSpinner", true);
            return SwamService.escalateAlarm('operational',alarm.id,data);
        },
        acknowledge({ state, commit, dispatch, rootState }, { alarm, data }) {
            this.commit("setSpinner", true);
            return SwamService.acknowledgeAlarm('operational',alarm.id,data);
        },

        goLive({ state, commit, dispatch, rootState }) {
            commit('changeConnectionState', true);
            console.log(app.$IoTSocket.socket);
            console.log(app.$IoTSocket.is_connected);

            app.$IoTSocket.subscribe("new alarm", function (data) {
                console.log("new alarm event", data);
                commit('addNewAlarm', data);
            });

            app.$IoTSocket.subscribe("update alarm", function (data) {
                console.log("update alarm event", data);
                commit('updateAlarm', data);
            });

            app.$IoTSocket.subscribe("remove alarm", function (data) {
                console.log("remove alarm event", data);
                commit("deleteAlarm", data);
            });
            app.$IoTSocket.subscribe("acknowledge alarm", function (data) {
                console.log("acknowledge alarm event", data);
                commit('ackAlarm', data);
            });

        },
        disconnect({ state, commit, dispatch, rootState }) {
            commit('changeConnectionState', false);
            app.$IoTSocket.unSubscribeAll();
        },
    }
}