<template>
  <div>
    <div class="row justify-content-center mb-2">
      <div class="col-7">
        <base-input
          label="Person Email"
          type="email"
          :placeholder="$t('Email')"
          v-model="$v.newUser.email.$model"
        >
          <template v-if="$v.newUser.email.$error" v-slot:error>
            <label class="error" v-if="!$v.newUser.email.required">{{$t("requireds.Email")}}</label>
            <label
              class="error"
              v-if="!$v.newUser.email.email"
            >{{$t("validations.Not valid email")}}</label>
          </template>
        </base-input>
      </div>
    </div>

    <div class="row justify-content-center mb-2">
      <div class="col-7">
        <base-input label="Person Phone number">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{{CompanyData.callCode}}</span>
            </div>
            <input
              type="number"
              v-model="$v.newUser.phone.$model"
              class="form-control"
              :placeholder="$t('Phone')"
            />
          </div>
          <template v-if="$v.newUser.phone.$error" v-slot:error>
            <label class="error" v-if="!$v.newUser.phone.required">{{$t("requireds.Phone")}}</label>
            <label
              class="error"
              v-if="!$v.newUser.phone.isValidPhone"
            >{{$t("validations.Not valid phone number")}}</label>
          </template>
        </base-input>
      </div>
    </div>
    <div class="row justify-content-center mb-2">
      <div class="col-7">
        <base-select label="Person Type" v-model="newUser.type" :options="options">
          <template v-if="$v.newUser.type.$error" v-slot:error>
            <label class="error" v-if="!$v.newUser.type.required">You Must select A Type</label>
          </template>
        </base-select>
      </div>
    </div>
    <div class="row justify-content-center mb-2">
      <div class="col-7">
        <base-button @click.native="validate" block type="success">Submit</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { mapGetters } from "vuex";
import { users_types } from "../../../store/index";
export default {
  data() {
    return {
      newUser: {
        email: null,
        phone: null,
        type: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      theNewUser: "getNewUser",
      CompanyData: "CompanyData",
    }),
    options() {
      return Object.keys(users_types)
        .map((t) => {
          return { value: users_types[t], label: t.replace(/_/g, " ") };
        });
    },
  },
  methods: {
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$notify({
          message: "You Have Errors on Your new data!",
          type: "danger",
        });
        return;
      }
      this.$store
        .dispatch("addNewUser", this.newUser)
        .then((_) => {
          this.newUser = {};
        })
        .catch(console.error);
      return this.newUser;
    },
  },
  validations: {
    newUser: {
      type: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        isValidPhone: function (value) {
          if (!value) {
            return true;
          }
          let phoneNumber = parsePhoneNumberFromString(
            this.CompanyData.callCode + value
          );
          if (phoneNumber) {
            return phoneNumber.isValid();
          }
          return false;
        },
      },
    },
  },
};
</script>

<style>
</style>