




import {applyUniqueIdToAllObjectKeys} from "../../../../util/unique-id-generator";

let types = {

  // Resetting
  RESET_THE_WHOLE_STATE_ACTION: 'RESET_THE_WHOLE_STATE_ACTION',

  // Alarms data actions
  GETTING_INTRUSION_ALARMS_ACTION: 'GETTING_INTRUSION_ALARMS_ACTION',
  GETTING_STATUS_ALARMS_ACTION: 'GETTING_STATUS_ALARMS_ACTION',
  GETTING_VILLAS_LIST_ACTION: 'GETTING_VILLAS_LIST_ACTION',
  UPDATE_FILTER: 'UPDATE_FILTER',

  
  // Actions on alarms
  BY_MORO_ACKNOWLEDGE_ALARM_ACTION: 'BY_MORO_ACKNOWLEDGE_ALARM_ACTION',
  BY_POLICE_ACKNOWLEDGE_ALARM_ACTION: 'BY_POLICE_ACKNOWLEDGE_ALARM_ACTION',
  VERIFY_ALARM_ACTION: 'VERIFY_ALARM_ACTION',
  TERMINATE_ALARM_ACTION: 'TERMINATE_ALARM_ACTION',
  COMMENT_ON_ALARM_ACTION: 'COMMENT_ON_ALARM_ACTION',
  OPEN_TERMINATE_MODAL_ACTION: 'OPEN_TERMINATE_MODAL_ACTION',
  
  // Actions on villas
  GET_VILLA_INFO_ACTION: 'GET_VILLA_INFO_ACTION',
  OPEN_VILLA_INFORMATION_MODAL_ACTION: 'OPEN_VILLA_INFORMATION_MODAL_ACTION',


  //
  GET_MORE_INFO_ABOUT_CUSTOMER: 'GET_MORE_INFO_ABOUT_CUSTOMER',
  CUSTOMER_CONTACTS_LIST_ACTION: 'CUSTOMER_CONTACTS_LIST_ACTION',
  OPEN_ACKNOWLEDGEMENT_MODAL_ACTION: 'OPEN_ACKNOWLEDGEMENT_MODAL_ACTION',

  RE_OPEN_ACK_MODAL_ACTION: 'RE_OPEN_ACK_MODAL_ACTION',
  MORO_OR_POLICE_ACTION: 'MORO_OR_POLICE_ACTION',
  OPEN_MORE_INFORMATION_MODAL_ACTION: 'OPEN_MORE_INFORMATION_MODAL_ACTION',

  // Socket
  LISTEN_TO_ACKNOWLEDGE_ALARM_ACTION: 'LISTEN_TO_ACKNOWLEDGE_ALARM_ACTION',
  NEW_ALARM_RECEIVED_FROM_SERVER_ACTION: 'NEW_ALARM_RECEIVED_FROM_SERVER_ACTION',
  LISTEN_TO_TERMINATE_ALARM_EVENT_ACTION: 'LISTEN_TO_TERMINATE_ALARM_EVENT_ACTION',
  LISTEN_TO_VERIFY_ALARM_EVENT_ACTION: 'LISTEN_TO_VERIFY_ALARM_EVENT_ACTION',
  HISTORY_ALARMS_TOTAL_RECORDS_ACTION: 'HISTORY_ALARMS_TOTAL_RECORDS_ACTION',
  GETTING_HISTORY_DATA_ACTION: 'GETTING_HISTORY_DATA_ACTION',
  GETTING_INTRUSION_ALARMS_STATISTICS_ACTION: 'GETTING_INTRUSION_ALARMS_STATISTICS_ACTION',
  CUSTOMER_LOCATIONS_ARRAY_ACTION: 'CUSTOMER_LOCATIONS_ARRAY_ACTION',
  MORE_INFORMATION_FOR_ALARMS_ACTION: 'MORE_INFORMATION_FOR_ALARMS_ACTION',


};


types = applyUniqueIdToAllObjectKeys(types);
export default types;
