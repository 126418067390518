import ScooterService from "../../../../services/globalMobility.service";

export default {
  namespaced: true,
  state: {
    loading: false,
    payments: []
  },
  getters: {
    loading(state){
      return state.loading
    },
    payments(state){
      return state.payments
    }
  },
  mutations: {
    SET_LOADING(state, payload){
      state.loading = payload
    },
    SET_PAYMENTS(state, payload){
      state.payments = payload
    }
  },
  actions: {
    async fetchPaymentsData({ commit }, scooterID) {
      return new Promise((resolve, reject) => {
          commit('SET_LOADING', true);
          ScooterService.getPayments(scooterID)
              .then((res) => {
                  commit('SET_PAYMENTS', res.data.data)
                  commit("SET_LOADING", false);
                  resolve(res.data.data)
              })
              .catch((err) => reject(err));
      });
    },
  }
}