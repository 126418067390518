<template>
  <card id="singleTask" :style="`top:${top}px`">
    <div slot="header" class>
      <p v-if="task.id">{{task.name}}</p>
      <p v-else>{{$t('New Task')}}</p>
    </div>
    <div class="row">
      <div v-if="task.id" class="col-12 form-group">
        <label for="Cr">
          {{$t('CREATED AT')}}</label>
        <input
          class="form-control"
          id="Cr"
          type="text"
          autocomplete="off"
          :value="gDateFormat(task.created_at)"
          readonly
        />
      </div>
      <div v-if="task.id && task.seconds_elapsed != 0" class="col-12 form-group">
        <label for="ET">
          {{$t('Elapsed Time')}}</label>
        <input class="form-control" id="ET" type="text" autocomplete="off" :value="getTime()" />
      </div>
      <div v-if="!task.id" class="col-12 form-group mb-2">
        <label for="name">
          {{$t('Task Title')}}</label>
        <input
          class="form-control"
          id="name"
          type="text"
          placeholder="Task Title"
          autocomplete="off"
          v-model.trim="task.name"
          :readonly="task.id"
        />
      </div>
      <div class="col-12 form-group mb-2">
        <label for="inside_location">
          {{$t('Inside Location')}}</label>
        <textarea
          v-if="!task.id || (task.inside_location && task.inside_location.length!=0)"
          class="form-control"
          id="inside_location"
          :placeholder="$t('Inside Location')"
          v-model="task.inside_location"
          :readonly="task.id"
        ></textarea>
        <textarea
          v-else
          class="form-control"
          id="inside_location"
          :placeholder="$t('Inside Location')"
          :value="'No Location Provided'"
          :readonly="task.id"
          style="text-decoration: underline;"
        ></textarea>
      </div>
      <div class="col-12 form-group mb-2">
        <label for="desc">
          {{$t('Task Description')}}</label>
        <textarea
          v-if="!task.id || (task.description && task.description.length!=0)"
          v-model="task.description"
          class="form-control"
          id="desc"
          :placeholder="$t('Task Description')"
          :readonly="task.id"
        ></textarea>
        <textarea
          v-else
          :value="$t('No Description Provided')"
          class="form-control"
          id="desc"
          :readonly="task.id"
          style="text-decoration: underline;"
        ></textarea>
      </div>
    </div>
    <div slot="footer" class="row">
      <div class="col-12">
        <BaseButton v-if="!task.id" @click="submit">
          {{$t('Submit')}}</BaseButton>
        <!-- <BaseButton v-if="canFinishTask" @click="complete">Complete</BaseButton>
        <BaseButton v-if="canUnFinishTask" @click="complete">Resotre</BaseButton> -->
      </div>
    </div>
  </card>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import BaseButton from "./../../../components/BaseButton";
import { hhmmss } from "./../../../util/helper";
export default {
  data() {
    return {
      top: 0,
      id: -1
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    // this.handleScroll(document
    //   .getElementsByClassName("main-panel")[0])
    document
      .getElementsByClassName("main-panel")[0]
      .addEventListener("ps-scroll-y", this.handleScroll);
  },
  destroyed() {
    document
      .getElementsByClassName("main-panel")[0]
      .removeEventListener("ps-scroll-y", this.handleScroll);
  },
  computed: {
    ...mapGetters({
      task: "currentTask",
      mo: "selectedMO",
    }),
    canUnFinishTask(){
      return this.$store.getters.canUnFinishTask;
    },
    canFinishTask(){
      return this.$store.getters.canFinishTask;
    }
  },
  methods: {
    submit() {
      if(!this.task.name || this.task.name.legnth < 6){
        this.$notify({
          type:'warning',
          message:'Task Title Must Be More Than 5 characters'
        })
        return;
      }
      this.$api
        .post(`maintenance/order/${this.id}/task`, this.task)
        .then(res => {
          this.$set(this.task, "id", res.data.id);
          this.$set(this.task, "finished", false);
          this.$set(this.task, "created_at", res.data.created_at);
          this.$set(this.task, "seconds_elapsed", 0);
          this.$set(
            this.task,
            "mo_id",
            this.mo.id
          );
          if (res.data.state) {
            this.mo.state.id = res.data.state;
            this.$store.commit("selectMO", this.mo);
            this.$store.dispatch("getHeaderAction", this.mo);
          }
          this.$store.dispatch("updatePercentage");
        })
        .catch(err => {
          console.log(err);
        });
    },
    complete() {
      this.$store.dispatch('changeTaskState',{task:this.task,newState:1})
    },
    handleScroll(e) {
      const h = document.getElementById("tasksTab").getBoundingClientRect()
        .height;
      const h2 = document.getElementById("singleTask").getBoundingClientRect()
        .height;
      const s = e.srcElement.scrollTop;
      if (h2 + s > h) {
        if (h - h2 - 40 < 0) {
          this.top = 0;
        } else {
          this.top = h - h2 - 40;
        }
      } else {
        this.top = s;
      }
    },
    getTime() {
      return hhmmss(this.task.seconds_elapsed);
    }
  },
  components: {
    BaseButton
  }
};
</script>
<style scoped>
p {
  text-transform: capitalize;
}
::v-deep .card-header {
  border-bottom: 1px solid #ffffff29;
  border-radius: 3px;
}
textarea {
  width: 100%;
  height: 100px;
  max-height: 100px;
  background: #ffffff08;
  border-radius: 5px;
}
textarea:read-only,
input:read-only {
  color: white;
  cursor: unset;
}
.my-scrollable {
  position: relative;
}
</style>
