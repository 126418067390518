export const areaChartConfigurations = {
    type: "area",
    title: {
      y: "15px",
      "background-color": "none",
      "font-color": "#05636c",
      "font-size": "24px",
      height: "25px",
      "adjust-layout": true
    },
    plotarea: {
      "margin-top": "0",
      "margin-right": "30",
      "margin-bottom": "90",
      "margin-left": "30"
    },
    scaleX: {
      label: {
        text: "Date",
        "font-size": "14px",
        "font-weight": "normal",
        "offset-x": "10%",
        "font-angle": 360
      },
      item: {
        "text-align": "center",
        "font-color": "#2A4B65"
      },
      zooming: 1,
      "max-labels": 12,
      "max-items": 12,
      "items-overlap": true,
      guide: {
        "line-width": "0px"
      },
      tick: {
        "line-color": "none",
        "line-width": "2px"
      }
    },
    "crosshair-x": {
      "line-color": "#fff",
      "line-width": 1,
      "plot-label": {
        visible: false
      }
    },
    scaleY: {
      "line-color": "none",
      item: {
        "font-color": "#2A4B65",
        "font-weight": "normal"
      },
      label: {
        // "text":"Metrics",
        "font-size": "14px"
      },
      tick: {
        "line-color": "none"
      },
      guide: {
        "line-width": "0px",
        alpha: 0.2,
        "line-style": "dashed"
      }
    },
    plot: {
      "line-width": 4,
      "font-color": "",
      tooltip: {
        "font-size": "15px",
        text: "%t: %v",
        "text-align": "left",
        "border-radius": 5,
        padding: 10
      }
    }
  };