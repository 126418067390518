import VsaasService from '../../services/vsaas.service';
import { dateTableCellFomatter,emptyTableCellFormatter } from "@/modules/common/utils/helper";

export default {
    namespaced:true,
    state: {
        loading: false,
        columns: [
            {
                label: "Alarm ID",
                prop: "id",
                minWidth: 150,
            },
            {
                label: "Alarm Time",
                prop: "createdAt",
                minWidth: 200,
                formatter: dateTableCellFomatter
            },
            {
                label: "Alarm Type",
                prop: "status",
                minWidth: 200,
                formatter: emptyTableCellFormatter
            },
            {
                label: "device type",
                prop: "device.type",
                minWidth: 200,
            },
            {
                label: "channel id",
                prop: "device.channelId",
                minWidth: 100,
                formatter: emptyTableCellFormatter
            },
            {
                label: "device status",
                prop: "isDeviceOff",
                minWidth: 100,
                customTemplate: true
            },
            {
                label: "alarm email",
                prop: "device.alarmEmail",
                minWidth: 200,
                formatter: emptyTableCellFormatter
            },
            {
                label: "villa name",
                prop: "device.structureName",
                minWidth: 200,
            },
            {
                label: "Area",
                prop: "device.communityNameEn",
                minWidth: 200,
            },
            {
                label: "Customer Name",
                prop: "device.customerName",
                minWidth: 200,
            },
            {
                label: "Operator Acknowledgement Time",
                prop: "operatorAcknowledgementTime",
                minWidth: 200,
                formatter: dateTableCellFomatter
            },
            {
                label: "Operator Email",
                prop: "operatorEmail",
                minWidth: 200,
            },
            {
                label: "Terminated At",
                prop: "termination.createdAt",
                minWidth: 200,
                formatter: dateTableCellFomatter
            }
        ],
        tableData: [],
        pagination: {
            limit: 10,
            offset: 0,
        },
        total: 0,
        filters: {},
        filterOptions: {},
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        columns(state) {
            return state.columns;
        },
        tableData(state) {
            return state.tableData;
        },
        pagination(state) {
            return state.pagination
        },
        total(state) {
            return state.total;
        },
        filterOptions(state) {
            return state.filterOptions;
        },
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setData(state,data) {
            state.tableData = data;
        },
        setTotal(state,value) {
            state.total = value;
        },
        updatePagination(state,value) {
            state.pagination = value;
        },
        updateFilters(state,value) {
            state.filters = value;
        },
        setFilterOptions(state,value) {
            state.filterOptions = value;
        },
        addNewAlarm(state, newAlarm) {
            newAlarm.highlight = true;
            state.tableData.unshift(newAlarm);
            state.total += 1;
        },
        ackAlarm(state, data) {
            let index = state.tableData.findIndex((alarm) => alarm.id == data.id);
            if (index > -1) {
                let alarm = state.tableData[index];
                alarm.operatorAcknowledgementTime = data.acknowledgedAt;
                alarm.operatorEmail = data.user.email;
                alarm.acknowledgement = data;
                alarm.highlight = true;
                state.tableData.splice(index, 1, alarm);
            }
        },
        terminateAlarm(state,data) {
            let index = state.tableData.findIndex((alarm) => alarm.id == data.id);
            if(index != -1) {
                state.tableData[index].highlight = true;
                state.tableData[index].endedAt = data.endedAt;
                if(data.termination && data.termination.userId) {
                    state.tableData[index].termination = {
                      userId: data.termination.userId,
                      createdAt: data.termination.createdAt
                    };
                } else {
                    state.tableData[index].termination = {
                      userId: null,
                      createdAt: data.endedAt
                    }
                }
            }
        },
        updateAlarm(state,data) {
            let index = state.tableData.findIndex((alarm) => alarm.id == data.id);
            if(index != -1) {
                state.tableData[index].device.on = data.on;
                state.tableData[index].highlight = true;
            }
        },
        archiveAlarm(state,data) {
            let index = state.tableData.findIndex((alarm) => alarm.id == data.id);
            if(index != -1) {
                state.tableData.splice(index,1);
            }
        }
    },
    actions: {
        async fetchData({state,commit},payload) {
            commit('setLoading',true);
            try {
                let params = {
                    ...state.pagination,
                    ...payload,
                    ...state.filters
                };
                const data = await VsaasService.getOperationalAlarms(params);
                commit('setData',data.data);
                if(data.totalRecords)
                    commit('setTotal',data.totalRecords);
                commit('setLoading',false);
            } catch(error) {
                commit('setLoading',false);
                console.log(error);
            }
        },
        async fetchFilterOptions({state,commit}) {
            try {
                const data = await VsaasService.getAlarmsInfo();
                let filterOptions = {
                    villaOptions: [{label: "all villas"},...data.villas.map(v=>({
                      label: v.name,
                      value: v.id
                    }))],
                    areaOptions: [{label: "all areas"},...data.areas.map(v=>({
                      label: v.name,
                      value: v.id
                    }))],
                    alarmOptions: [{label: "all alarms"},...data.alarms.map(v=>({
                      label: v,
                      value: v
                    }))],
                    statusOptions: [{label: "all"}]
                };
                commit('setFilterOptions',filterOptions);
            } catch(error) {
                console.log(error);
            }
        },
        onNewAlarmSocketEvent({commit},payload) {
            commit('addNewAlarm',payload);
        },
        onAlarmIsAcknowledgedSocketEvent({commit},payload) {
            commit('ackAlarm',payload);
        },
        onAlarmIsTerminatedSocketEvent({commit},payload) {
            commit('terminateAlarm',payload);
        },
        onUpdateStatusSocketEvent({commit},payload) {
            commit('updateAlarm',payload);
        },
        onArchiveAlarmSocketEvent({commit},payload) {
            commit('archiveAlarm',payload);
        }
    }
};