<template>
  <div class="col-12 p-0" v-loading="loading">
    <h4 class="col-12 card-title font-weight-bold">{{ $t('administrativeStatistics') }}</h4>
    <hr class="m-0" style="border-top-width: 2px" />
    <div class="col-12 background-1">
      <template v-for="(item, i) in data">
        <cardView 
          :link="item.key == 'alarmsNotAcknowledged' ? getAckUrl() : ''" 
          :key="i" 
          :label="$t(item.key)" 
          :value="item.value" 
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions,  mapState } from "vuex";
import cardView from '@/components/cardView';
export default {
    components:{
      cardView,
    },
    props: {
        namespace:{
            type:String,
            required:true,
        },
        disableDataFetch: {
          type: Boolean,
          default: false
        },
        structureId:{}
    },
    computed: {
      ...mapState({
          loading(state,getters){
            return getters[this.namespace+"/Administrative/loading"]
          },
          data(state,getters){
            return getters[this.namespace+"/Administrative/getData"]
          },
      }),
    },
    methods: {
      ...mapActions({
          fetchData(dispatch, payload){
            return dispatch(this.namespace+"/Administrative/fetchData",payload)
          },
          fetchDataByStructure(dispatch, payload){
            return dispatch(this.namespace+"/Administrative/fetchDataByStructure",payload)
          },
      }),
      getAckUrl() {
        let url = '/SWAM/Alarms/status?acknowledgement=false';
        url += (this.structureId ? '&structure_id=' + this.structureId : '');
        return url;
      }
    },
    mounted() {
        if(this.disableDataFetch) {
          return;
        }
        if(this.structureId) {
            this.fetchDataByStructure(this.structureId);
        } else {
            this.fetchData();
        }
    },
    watch: {
      disableDataFetch() {
        if(this.disableDataFetch) {
          return;
        }
        if(this.structureId) {
            this.fetchDataByStructure(this.structureId);
        } else {
            this.fetchData();
        }
      },
      structureId() {
        if(this.disableDataFetch) {
          return;
        }
        if(this.structureId) {
            this.fetchDataByStructure(this.structureId);
        } else {
            this.fetchData();
        }
      }
    },
}
</script>

<style scoped lang="scss">
h4 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>