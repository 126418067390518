<template>
  <tr :class="{'invalid':($v.$anyError || !unique) }">
    <td class="group-name-col">
      <a v-if="isGroupMode || (isRulesMode && isNewOne)"
         class="aspointer has-tooltip m-1"
         @click="deleteItem()">
        <!-- <a v-if="isGroupMode" class="aspointer has-tooltip m-1" @click="deleteGroup()"> -->
        <i aria-hidden="true" class="fa fa-trash fa-2x text-danger"></i>
      </a>
      <input :placeholder='$t("Name") +" "+ (mode==="Rule"? $t("Rule") :$t("Group") ) '
             type="text"
             v-model="item.name"
             class="my-input"
             :disabled="disabled || isNameEditable" />
      <small v-if="!disabled && isNameEditable"
             class="form-text text-muted">{{$t("Basic Rule, The Name Is Not Editable")}}</small>
      <div class="help-block ml-5"
           v-if="$v.item.name.$error && !$v.item.name.required">{{ $t("requireds.name") }}</div>
      <div class="help-block ml-5" v-if="!unique">{{$t("This Name Was Taken")}}</div>
    </td>
    <td>
      <div class="d-flex" style="height: max-content;">
        <div class="col-10 col-md-11 row"
             v-if="item.permissions.length!=0"
             style="height: fit-content;">
          <div v-for="(perm,index) in item.permissions"
               :key="index"
               class="col-md-4 text-align-center">
            <button :disabled="disabled"
                    @click="removePermission(perm,index,item)"
                    class="btn btn-round btn-default btn-sm">
              <span class="d-inline" v-text="(perm.name && viewAliases)? perm.name : perm.key"></span>
              <i v-if="!disabled" class="el-icon el-icon-close"></i>
            </button>
          </div>
          <span class="help-block text-center w-100"
                v-if="$v.item.permissions.$error">{{$t("Activate One Permission At least")}}</span>
        </div>
        <div v-else class="col-10 col-md-11 row">
          <p @click="addPermission()"
             class="h5 text-center w-100 no-per"
             style="color: #839BBB;">{{$t("No Permissions Selected")}}</p>
          <span class="help-block text-center w-100"
                v-if="$v.item.permissions.$error">{{$t("Select One Permission At least")}}</span>
        </div>
        <div class="col-2 col-md-1 d-flex align-items-center">
          <a v-if="!edit"
             @click="addPermission()"
             class="aspointer has-tooltip"
             data-original-title="null">
            <i aria-hidden="true" class="fa fa-plus-circle fa-2x add-btn"></i>
          </a>
          <a v-if="edit"
             @click="$emit('edit')"
             class="aspointer has-tooltip"
             data-original-title="null">
            <i aria-hidden="true" class="fa fa-edit fa-2x add-btn"></i>
          </a>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
  import { required, requiredIf } from "vuelidate/lib/validators";
  import { mapGetters } from "vuex";
  import helper from "../../../util/helper";
  const debounce = helper.debounce;

  export default {
    props: ["item", "edit", "disabled"],
    data() {
      return {
        unique: true,
        oldName: null
      };
    },
    computed: {
      name() {
        return this.item.name;
      },
      ...mapGetters({
        isGroupMode: "isGroupMode",
        isRulesMode: "isRulesMode",
        mode: "permissionsPage__mode",
        viewAliases: "viewAliases"
      }),
      isNewOne() {
        return typeof this.item.id != "number";
      },
      isNameEditable() {
        return this.isRulesMode && !this.isNewOne && this.item.id <= 6;
      }
    },
    watch: {
      // name: debounce(function () {
      //   this.checkName();
      // })
    },
    methods: {
      addPermission() {
        if (!this.disabled) {
          this.$emit("addPermission");
        } else {
          this.$emit("edit");
        }
      },
      deleteItem() {
        this.$emit("delete");
      },
      checkName() {
        console.log(this.oldName)
        if (
          this.item.name == "" ||
          this.item.name.toLowerCase().trim() == this.oldName
        ) {
          this.unique = true;
          return;
        }
        this.$api
          .get(`permission/${this.mode}s/${this.item.name}`)
          .then(res => {
            this.unique = !res.data.data;
          })
          .catch(err => {
            console.log(err);
          });
      },
      removePermission(perm, index, item) {
        perm.markedBy = 0;
        item.permissions.splice(index, 1);
      },
      deletePermission() { }
    },
    created() {
      this.oldName = this.item.id ? this.item.name.toLowerCase().trim() : null;
    },
    validations: {
      item: {
        name: {
          required
        },
        permissions: {
          required: requiredIf(function (val) {
            return this.isGroupMode;
          }),
        }
      }
    }
  };
</script>
<style>
</style>
<style scoped>
  .form-text {
    padding: 0 1.4rem;
    position: relative;
    top: -15px;
  }

  .btn-default:disabled,
  .btn[disabled] {
    opacity: 1;
  }

  .btn-default {
    margin: 3px 0 !important;
  }

  .btn {
    max-width: -webkit-fill-available;
  }

  .help-block {
    color: #ff5959 !important;
  }

  td {
    border-top: 3px solid #839bbb !important;
  }

  .my-input {
    background: inherit;
    height: 3rem;
    border-radius: 0.25rem;
    margin: 0 0.5rem;
    color: white;
    padding: 0 1rem;
    outline: none;
    font-size: 1.3rem;
    border: 0;
    border-bottom: 1px solid transparent;
    width: 80%;
    text-transform: capitalize;
  }
  .white-content .my-input {

    color: #1d253b;

  }
  .group-name-col {
    width: 40%;
  }

  .invalid {
    background-color: #f800003b !important;
  }

  .no-per:hover {
    text-decoration: underline !important;
    color: #d5e5f9 !important;
    cursor: pointer;
  }

  .my-badge {
    padding: 0.25em 0.4em;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background-color: #343a40;
  }
</style>
