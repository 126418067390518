<template>
  <div
    class="form-group"
    :class="{
      'input-group-focus': focused,
      'has-danger': error || $slots.error,
      'has-success': !error && !$slots.error && touched,
      'has-label': label,
      'has-icon': hasIcon,
    }"
  >
    <slot name="label">
      <label v-if="label"> {{ label }} {{ required ? '*' : '' }} </label>
    </slot>
    <div class="mb-0" :class="{'input-group': hasIcon}">
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text"><i :class="addonLeftIcon"></i></div>
        </span>
      </slot>
      <slot>
             <el-select
                  multiple
                  class="select-primary"
                  size="large"
                  :placeholder="placeholder"
                  :value="value"
                  :collapse-tags="collapseTags"
                  @change="onInput"
                >
                  <el-option
                    v-for="option in options"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
        </el-select>
      </slot>
      <slot name="addonRight">
        <span v-if="addonRightIcon" class="input-group-append">
          <div class="input-group-text"><i :class="addonRightIcon"></i></div>
        </span>
      </slot>
    </div>

    <slot name="error" v-if="error || $slots.error">
      <label class="error">{{ error }}</label>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'base-multi-select',
  props: {
    required: Boolean,
    label: {
      type: String,
      description: 'Input label'
    },
    error: {
      type: String,
      description: 'Input error',
      default: ''
    },
    value: {
      type: Array,
      description: 'Input value'
    },
    options:{
        type:Array
    },
    addonRightIcon: {
      type: String,
      description: 'Input icon on the right'
    },
    addonLeftIcon: {
      type: String,
      description: 'Input icon on the left'
    },
    placeholder: {},
    collapseTags:{
      
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      touched: false
    };
  },
  computed: {
    hasIcon() {
      return this.hasLeftAddon || this.hasRightAddon
    },
    hasLeftAddon() {
      const { addonLeft } = this.$slots;
      return (
        addonLeft !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    hasRightAddon() {
      const { addonRight } = this.$slots;
      return (
        addonRight !== undefined ||
        this.addonRightIcon !== undefined
      );
    },
    // listeners() {
    //   return {
    //     ...this.$listeners,
    //     input: this.onInput,
    //     blur: this.onBlur,
    //     focus: this.onFocus,
    //   };
    // }
  },

  methods: {
    onInput(val) {
      if (!this.touched) {
        this.touched = true;
      }
      console.log('select base',val)
       this.$emit('input', val);
    },
    onChange(evt){
        console.log('change:',evt);
       //this.$emit('change', evt.target.value); 
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit('focus', evt)
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit('blur', evt)
    }
  }
};
</script>
<style></style>
