import {
  VsaaSAlarmsService,
  VSaaSCustomersService
} from "../../../services/vsaas-services.bundle";
import ActionsTypes from "../../types/actions-types";
import MutationsTypes from "../../types/mutations-types";
import StateTypes from "../../types/state-types";

import { mapArrayOfObjectsToArrayOfAlarmsSingleRow } from "../../../models/alarms-table-single-row";
import { mapArrayOfObjectsToContacts } from "../../../models/contact";

import USER_PREFERENCES_ACTIONS from "../../../../../global/user-preferences-store/action-types";
import { USER_PREFERENCES_KEYS } from "../../../../../global/user-preferences-store/keys";
import { mergeColumnsConfigurationsArrays } from "../../../../common/utils/helper";

export const operationalAlarmsActions = {
  [ActionsTypes.MORE_INFORMATION_FOR_ALARMS_ACTION]: ({}) => {
    return new Promise(async (resolve, reject) => {
      const vsaasService = new VsaaSAlarmsService();
      const reply = await vsaasService.getMoreInformationForAlarms();
      if (reply != null) {
        resolve(reply.data);
      } else {
        reject(null);
      }
    });
  },
  /**
   *
   * @param commit
   * @param queryParams
   * @returns {Promise<boolean>}
   */
  [ActionsTypes.GETTING_ALARMS_ACTION]({ commit }, queryParams) {
    return new Promise(async (resolve, reject) => {
      const vsaasService = new VsaaSAlarmsService();
      const gettingAlarmsResponse = await vsaasService.getVSaaSAlarms(
        queryParams
      );

      if (gettingAlarmsResponse != null) {
        const alarms = mapArrayOfObjectsToArrayOfAlarmsSingleRow(
          gettingAlarmsResponse.data
        );
        commit(MutationsTypes.GETTING_ALARMS_MUTATION, alarms);
        commit(
          MutationsTypes.ALARMS_TOTAL_RECORDS_MUTATION,
          gettingAlarmsResponse.totalRecords
        );
        resolve(true);
      } else {
        reject(false);
      }
    });
  },

  /**
   *
   * @param commit
   * @param queryParams
   * @returns {Promise<boolean>}
   */
  [ActionsTypes.GETTING_ALARMS_HISTORY_ACTION]({ commit }, queryParams) {
    return new Promise(async (resolve, reject) => {
      const vsaasService = new VsaaSAlarmsService();
      const alarmsHistoryResponse = await vsaasService.getAlarmsHistory(
        queryParams
      );
      if (alarmsHistoryResponse != null) {
        commit(
          MutationsTypes.GETTING_ALARMS_HISTORY_MUTATION,
          alarmsHistoryResponse.data
        );
        commit(
          MutationsTypes.HISTORY_ALARMS_TOTAL_RECORDS_MUTATION,
          alarmsHistoryResponse.totalRecords
        );
        resolve(true);
      } else {
        reject(false);
      }
    });
  },

  /**
   *
   * @param commit
   * @param {Object} closeOrOpen
   * @returns {Promise<boolean>}
   */
  async [ActionsTypes.OPEN_ACKNOWLEDGEMENT_MODAL_ACTION]({ commit }, ackData) {
    if (ackData.closeOrOpen) {
      // means open the acknoeldge modal so we need to get data before
      const vsaasService = new VSaaSCustomersService();
      const reply = await vsaasService.getCustomerContacts(ackData.customerId);
      if (reply != null) {
        const contacts = mapArrayOfObjectsToContacts(reply.data);
        commit(MutationsTypes.CUSTOMER_CONTACTS_LIST_MUTATION, contacts);
        commit(
          MutationsTypes.OPEN_ACKNOWLEDGEMENT_MODAL_MUTATION,
          ackData.closeOrOpen
        );
      }
    } else {
      commit(
        MutationsTypes.OPEN_ACKNOWLEDGEMENT_MODAL_MUTATION,
        ackData.closeOrOpen
      );
    }
  },
  /**
   *
   * @param commit
   * @param {boolean} closeOrOpen
   * @returns {Promise<boolean>}
   */
  [ActionsTypes.OPEN_COMMENTS_MODAL_ACTION]({ commit }, closeOrOpen) {
    commit(MutationsTypes.OPEN_COMMENTS_MODAL_MUTATION, closeOrOpen);
  },

  /**
   *
   * @param commit
   * @param {boolean} closeOrOpen
   * @returns {Promise<boolean>}
   */
  [ActionsTypes.OPEN_TERMINATE_MODAL_ACTION]({ commit }, closeOrOpen) {
    commit(MutationsTypes.OPEN_TERMINATE_MODAL_MUTATION, closeOrOpen);
  },
  /**
   *
   * @param commit
   * @param {Object} escObject
   * @returns {Promise<boolean>}
   */
  async [ActionsTypes.OPEN_ESCALATE_MODAL_ACTION]({ commit }, escObject) {
    if (escObject.closeOrOpen) {
      // means open the escalate modal so we need to get data before
      const vsaasService = new VsaaSAlarmsService();
      const reply = await vsaasService.getDevicesCompanies(escObject.deviceId);
      if (reply != null) {
        commit(MutationsTypes.DEVICES_COMPANIES_MUTATION, reply.data);
        commit(
          MutationsTypes.OPEN_ESCALATE_MODAL_MUTATION,
          escObject.closeOrOpen
        );
      }
    } else {
      // just close the modal
      commit(
        MutationsTypes.OPEN_ESCALATE_MODAL_MUTATION,
        escObject.closeOrOpen
      );
    }
  },

  async [ActionsTypes.DEVICES_COMPANIES_ACTION]({ commit }, deviceId) {
    const vsaasService = new VsaaSAlarmsService();
    const reply = await vsaasService.getDevicesCompanies(deviceId);
    if (reply != null) {
      commit(MutationsTypes.DEVICES_COMPANIES_ACTION, reply.data);
    }
  },

  [ActionsTypes.UPDATE_ALARMS_TABLE_COLUMNS_CONFIG_ACTION](
    { commit, dispatch },
    newConfig
  ) {
    const dataToStore = {
      key: USER_PREFERENCES_KEYS.VSAAS_OPERATION_ALARMS_TABLE_CONFIGURATIONS,
      value: newConfig.map(c => ({ ...c, tableProps: undefined }))
    };
    dispatch(USER_PREFERENCES_ACTIONS.SET_ITEM_ACTION, dataToStore, {
      root: true
    })
      .then(stored => {
        if (stored) {
          commit(
            MutationsTypes.UPDATE_ALARMS_TABLE_COLUMNS_CONFIG_MUTATION,
            newConfig
          );
        }
      })
      .catch(err => {
        console.log("Error while storing config in server: ", err);
      });
  },

  /**
   * @param {Object} ack
   */
  [ActionsTypes.ACK_ALARM_ACTION]: async (_, ack) => {
    return new Promise(async (resolve, reject) => {
      const vsaasService = new VsaaSAlarmsService();
      const ackRes = await vsaasService.acknowledgeAlarm(
        ack.alarmId,
        ack.comment
      );
      if (ackRes != null) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  },

  /**
   * @param {Object} escalate
   */
  [ActionsTypes.ESCALATE_ALARM_ACTION]: (_, escalate) => {
    return new Promise(async (resolve, reject) => {
      const vsaasService = new VsaaSAlarmsService();
      const escalateRes = await vsaasService.escalateAlarm(escalate.alarmId, {
        comment: escalate.comment,
        company_ids: escalate.companyIds
      });
      if (escalateRes != null) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  },

  [ActionsTypes.LISTEN_TO_ACKNOWLEDGE_ALARM_ACTION]: (
    { state, commit, rootState },
    data
  ) => {
    data.rootUserId = rootState.user.id;
    commit(MutationsTypes.LISTEN_TO_ACKNOWLEDGE_ALARM_MUTATION, data);
  },

  [ActionsTypes.NEW_ALARM_RECEIVED_FROM_SERVER_ACTION]: ({ commit }, data) => {
    commit(MutationsTypes.NEW_ALARM_RECEIVED_FROM_SERVER_MUTATION, data);
  },
  [ActionsTypes.TERMINATE_ALARM_ACTION]: ({ commit }, terminateData) => {
    return new Promise(async (resolve, reject) => {
      const vsaasService = new VsaaSAlarmsService();
      const terminateRes = await vsaasService.terminateAlarm(
        terminateData.alarmId,
        { comment: terminateData.comment }
      );
      if (terminateRes != null) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  },

  [ActionsTypes.LISTEN_TO_TERMINATE_ALARM_EVENT_ACTION]: ({ commit }, data) => {
    commit(MutationsTypes.LISTEN_TO_TERMINATE_ALARM_EVENT_MUTATION, data);
  },

  [ActionsTypes.UPDATE_ALARM_STATUS_ACTION]: ({ commit }, data) => {
    commit(MutationsTypes.UPDATE_ALARM_STATUS_MUTATION, data);
  },
  [ActionsTypes.GETTING_ALARMS_STATISTICS_ACTION]: ({ commit }, data) => {
    return new Promise(async (resolve, reject) => {
      const vsaasService = new VsaaSAlarmsService();
      const reply = await vsaasService.getAlarmsStatistics();
      if (reply != null) {
        commit(MutationsTypes.GETTING_ALARMS_STATISTICS_MUTATION, reply.data);
        resolve(true);
      } else {
        reject(false);
      }
    });
  },

  [ActionsTypes.GETTING_ALARMS_TABLE_COLUMNS_CONFIG_ACTION]: ({
    commit,
    dispatch,
    state
  }) => {
    return dispatch(
      USER_PREFERENCES_ACTIONS.GET_ITEM_ACTION,
      USER_PREFERENCES_KEYS.VSAAS_OPERATION_ALARMS_TABLE_CONFIGURATIONS,
      { root: true }
    )
      .then(reply => {
        const data = JSON.parse(reply.data);
        if (Array.isArray(data) && data.length > 0) {
          const currentConfig =
            state[StateTypes.OPERATIONAL_ALARMS_TABLE_COLUMNS_STATE_TYPE];
          mergeColumnsConfigurationsArrays(currentConfig, data);
          commit(
            MutationsTypes.UPDATE_ALARMS_TABLE_COLUMNS_CONFIG_MUTATION,
            currentConfig
          );
        }
      })
      .catch(err => {
        console.log(
          `Error while getting ${USER_PREFERENCES_KEYS.VSAAS_OPERATION_ALARMS_TABLE_CONFIGURATIONS}: `,
          err
        );
      });
  }
};
