<template>
  <div>
    <div class="row widgets justify-content-around">
      <div
        class="col-12 col-md-6 text-center mb-4"
        v-for="widget in widgets"
        :key="widget.data.widget_id"
      >
        <img
          class="widget-img"
          :src="widget.imagePath"
          :alt="widget.label"
          @click="selectedWidget = widget.data.widget_id"
          :class="{'img-active':selectedWidget == widget.data.widget_id}"
        />
        <p
          class="widget-title"
          :class="{'widget-title-selected': selectedWidget == widget.data.widget_id}"
        >{{ $t(widget.label) }}</p>
      </div>
    </div>
    <div class="col-6 mt-5 text-center" style="margin: 0 auto;">
      <base-button type="primary" :disabled="!selectedWidget" block @click="submit">{{ $t('instructions.addWidget') }}</base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "new-widget-modal",
  data() {
    return {
      selectedWidget: null,
    };
  },
  computed: {
    ...mapGetters({
      widgets: "ReportsDesignerStore/widgets",
    }),
  },
  methods: {
    submit() {
      const val = this.widgets.find((w) => w.value == this.selectedWidget);
      if (val) {
        this.$emit("submit", val.data);
      }
    },
  },
};
</script>

<style scoped>
.margin {
  margin-top: 70%;
}
.widgets {
  max-height: 42vh;
  overflow-y: auto;
  margin: 0px auto;
}

.widget-img {
  border: 4px solid white;
  border-radius: 8px;
  width: 260px;
  height: 140px;
}
.widget-img:not(.disabled):hover {
  border-color: var(--primary);
  cursor: pointer;
}
.widget-img.disabled {
  cursor: no-drop;
}
.img-active {
  border-color: var(--primary);
}
.widget-title {
  font-size: 1.3rem;
  margin-top: 5px;
  text-align: center;
  letter-spacing: 1.5px;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-title.widget-title-selected {
  color: var(--primary);
}
.icon-rotate-wrapper {
  display: flex;
  justify-content: center;
}
</style>