import {
  dateCellMinWidth,
  dateTableCellFomatter,
  emptyTableCellFormatter
} from "../../../common/utils/helper";
import StateTypes from "../types/state-types";

export const initialState = () => ({
  // Alarms and villas
  [StateTypes.GETTING_INTRUSION_ALARMS_STATE_TYPE]: [],
  [StateTypes.STATUS_ALARMS_TOTAL_RECORDS_STATE_TYPE]: [],
  [StateTypes.GETTING_VILLAS_LIST_STATE_TYPE]: [],
  [StateTypes.HISTORY_DATA_STATE_TYPE]: [],
  [StateTypes.INTRUSION_ALARMS_CURRENT_FILTER]: {},

  // Total records
  [StateTypes.INTRUSION_ALARMS_TOTAL_RECORDS_STATE_TYPE]: 0,
  [StateTypes.VILLAS_DETAILS_TOTAL_RECORDS_STATE_TYPE]: 0,

  [StateTypes.OPEN_ACKNOWLEDGEMENT_MODAL_STATE_TYPE]: false,
  [StateTypes.OPEN_MORE_INFORMATION_MODAL_STATE_TYPE]: false,
  [StateTypes.INTRUSION_ALARMS_STATISTICS_STATE_TYPE]: [],
  [StateTypes.CUSTOMER_LOCATIONS_ARRAY_STATE_TYPE]: [],

  [StateTypes.VILLAS_DEATILS_COLUMNS_CONFIG_STATE_TYPE]: [
    {
      label: "ID",
      prop: "structure_id",
      minWidth: 100,
    },
    {
      label: "Structure Name",
      prop: "structure_name",
      minWidth: 200,
      maxWidth: 300,
    },
    {
      label: "Area",
      prop: "community_name_en",
      minWidth: 200,
      maxWidth: 300,
    },
    {
      label: "City",
      prop: "city_name",
      minWidth: 200,
      maxWidth: 300
    },
    {
      label: "Country",
      prop: "country_name",
      minWidth: 200,
      maxWidth: 300
    },
    {
      label: "Customer Name",
      prop: "customer_name",
      minWidth: 200,
      maxWidth: 300,
    },
    {
      label: "Device Count",
      prop: "device_count",
      minWidth: 200,
      maxWidth: 300,
    },
    {
      label: "Active Intrusion Alarms",
      prop: "active_intrusion_alarms",
      minWidth: 200,
      maxWidth: 300,
    },
    {
      label: "Active Operational Alarms",
      prop: "active_operational_alarms",
      minWidth: 200,
      maxWidth: 300,
    },
  ],

  [StateTypes.FOR_MORO_INTRUSION_ALARMS_HISTORY_TABLE_COLUMNS_STATE_TYPE]: [
    {
      label: "Alarm ID",
      prop: "alarm.id",
      minWidth: 200,
      maxWidth: 300
    },
    {
      label: "Alarm Date",
      prop: "alarm.created_at",
      minWidth: 200,
      maxWidth: 300,
      formatter: dateTableCellFomatter
    },
    {
      label: "ended at",
      prop: "alarm.ended_at",
      minWidth: 200,
      maxWidth: 300,
      formatter: dateTableCellFomatter
    },
    {
      label: "event time",
      prop: "event.created_at",
      minWidth: 200,
      maxWidth: 300,
      formatter: dateTableCellFomatter
    },
    {
      label: "event type",
      prop: "event.type",
      minWidth: 200,
      maxWidth: 300
    },
    {
      label: "status",
      prop: "alarm.status",
      minWidth: 200,
      maxWidth: 300
    },
    {
      label: "by",
      prop: "event.user.email",
      minWidth: 200,
      maxWidth: 300,
      formatter: emptyTableCellFormatter
    },
    {
      label: "comment",
      prop: "event.comment",
      minWidth: 200,
      maxWidth: 500,
      formatter: emptyTableCellFormatter
    },
    {
      label: "device name",
      prop: "device.device_name",
      minWidth: 200,
      maxWidth: 500
    },
    {
      label: "device status",
      prop: "device.off",
      minWidth: 200,
      maxWidth: 500,
      formatter: (_, __, value) => {
        return value ? "off" : "on";
      }
    },
    {
      label: "villa",
      prop: "device.structure_name",
      minWidth: 200,
      maxWidth: 500
    },
    {
      label: "area",
      prop: "device.community_name_en",
      minWidth: 200,
      maxWidth: 500
    },
  ],
  [StateTypes.HISTORY_ALARMS_TOTAL_RECORDS_STATE_TYPE]: 0,
  [StateTypes.INTRUSION_MORO_TABLE_CONFIGS_STATE_TYPE]: [
    {
      label: "Alarm ID",
      prop: "id",
      minWidth: 100
    },
    {
      label: "Alarm Time",
      prop: "created_at",
      minWidth: dateCellMinWidth,
      formatter: dateTableCellFomatter
    },
    {
      label: "Alarm Type",
      prop: "status",
      minWidth: 250
    },
    {
      label: "villa",
      prop: "device.structure_name",
      minWidth: 250,
      customTemplate:true
    },
    {
      label: "customer",
      prop: "customer.name",
      minWidth: 250
    },
    {
      label: "Police Zone",
      prop: "police_station.police_station_name",
      minWidth: 250
    },
    {
      label: "device name",
      prop: "device.device_name",
      minWidth: 250
    },
    {
      label: "Moro Ack Time",
      prop: "acknowledgement.created_at",
      minWidth: dateCellMinWidth,
      formatter: dateTableCellFomatter
    },
    {
      label: "Ack By",
      prop: "acknowledgement.email",
      minWidth: 200,
      formatter: emptyTableCellFormatter
    },
    {
      label: "verification time",
      prop: "verification.created_at",
      minWidth: dateCellMinWidth,
      formatter: dateTableCellFomatter
    },
    {
      label: "verified By",
      prop: "verification.email",
      minWidth: 200,
    },
    {
      label: "police ack time",
      prop: "police_acknowledgement.created_at",
      minWidth: dateCellMinWidth,
      formatter: dateTableCellFomatter
    },
    {
      label: "ended at",
      prop: "termination.created_at",
      minWidth: dateCellMinWidth,
      formatter: dateTableCellFomatter
    }
  ],
  [StateTypes.CUSTOMER_CONTACTS_LIST_STATE_TYPE]: [],
  [StateTypes.INTRUSION_POLICE_TABLE_CONFIGS_STATE_TYPE]: [
    {
      label: "Alarm ID",
      prop: "id",
      minWidth: 100
    },
    {
      label: "Received Time",
      prop: "created_at",
      minWidth: dateCellMinWidth,
      formatter: dateTableCellFomatter
    },
    {
      label: "Alarm Type",
      prop: "status",
      minWidth: 250
    },
    {
      label: "villa",
      prop: "device.structure_name",
      minWidth: 250
    },
    {
      label: "customer",
      prop: "customer.name",
      minWidth: 250
    },
    {
      label: "Police Zone",
      prop: "police_station.police_station_name",
      minWidth: 250
    },
    {
      label: "device name",
      prop: "device.device_name",
      minWidth: 250
    },
    {
      label: "police ack time",
      prop: "police_acknowledgement.created_at",
      minWidth: dateCellMinWidth,
      formatter: dateTableCellFomatter
    },
    {
      label: "police Opertator",
      prop: "police_acknowledgement.email",
      minWidth: dateCellMinWidth,
      formatter: emptyTableCellFormatter
    },
    {
      label: "ended at",
      prop: "termination.created_at",
      minWidth: dateCellMinWidth,
      formatter: dateTableCellFomatter
    }
  ]
});
