import VueRouter from 'vue-router';
import store, { translatedPages } from './../store';
import { getAll, checkAccess } from "./../Permissions";
import { app } from '../main';
//import routes from './routes.js'

// configure router
const router = new VueRouter({
  linkActiveClass: 'active',
  mode: 'history',
  //routes,
  scrollBehavior(to, from, savedPosition) {
    // if (savedPosition) {
    //   return savedPosition
    // } else {
    //   return { x: 0, y: 0 }
    // }
    (document.getElementsByClassName("wrapper")[0] || {}).scrollTop = 0;
    // const tabScroll = document.getElementsByClassName("wrapper")[0];
    // window.scrollTo({
    //   'behavior': 'smooth',
    //   'left': 0,
    //   'top': tabScroll.offsetTop - 80
    // });
  }
});

getAll.finally(() => {
  console.log('test1')

  router.addRoutes(require('./routes').default);

  router.beforeEach((to, from, next) => {

    let user = store.state.user;
    let token = localStorage.getItem('token');

    console.log('from : ', from)
    console.log('to : ', to)
    console.log('user : ', user)
    if (to.name === 'set password') {
      store.commit('deleteUser');
      callNext(next, to);
    }
    else if (to.name === 'token' && store.state.holduserdata) callNext(next, to);
    else if (to.name !== 'Login' && (!user || !token)) { console.log('forwarding to login'); next({ name: 'Login' }) }
    else if (to.name === 'Login') callNext(next, to);
    else
      try {

        if (user.medical_info_required && to.name != "set medical info") {
          return next({ name: "set medical info" });
        }

        if (to.meta.permissions == -1) {
          return callNext(next, to);
        }

        let hasModulesAccess = true;
        to.matched.forEach(r => {
          if (r.meta.modules) {
            if (r.meta.modules.opration == 'or') {
              hasModulesAccess = false;
              for (const m of r.meta.modules.data) {
                if (checkAccess(user.modules, m)) {
                  hasModulesAccess = true;
                  break;
                }
              }
            } else {
              hasModulesAccess = checkAccess(user.modules, ...r.meta.modules.data);
            }
          }
        });
        if (!hasModulesAccess) {
          router.app.$notify({ message: 'You Do Not Have Access To This Module!' });
          console.log('deny Modules access to ', to.name, '  module')
          if (from.name == null && from.path == '/') {
            return next('/')
          } else
            return next(false)
        }

        const modulePermissions = [];
        let hasRoleAccess = true;

        to.matched.forEach(r => {
          if (typeof r.meta.module === "number") {
            modulePermissions.push(r.meta.module)
          }
          if (typeof r.meta.role === "number" && r.meta.role < store.state.user.role_id) {
            hasRoleAccess = false;
          }
        })
        if (!hasRoleAccess) {
          router.app.$notify({ message: 'You Do Not Have Access To This Module!' });
          console.log('deny Role access to ', to.name, '  module')
          if (from.name == null && from.path == '/') {
            return next('/')
          } else
            return next(false)
        }

        if (typeof (to.meta.permissions) === 'number') {
          to.meta.permissions = [to.meta.permissions];
        } else if (typeof to.meta.permissions === 'undefined') {
          to.meta.permissions = [];
        }

        //to.meta.permissions = to.meta.permissions ? to.meta.permissions : [];// just during the development, because not all pages have permissions meta yet


        //to.meta.permissions = (to.meta.permissions.length !=0) ? to.meta.permissions : [to.meta.permissions];

        if (checkAccess(user.modules, ...modulePermissions)) {
          if (checkAccess(user.permissions, ...to.meta.permissions)) {
            console.log('allowe access to ', to.name)
            return callNext(next, to);
          } else {
            console.log('deny access to ', to.name, '  page')
            router.app.$notify({ message: 'You Do Not Have Access To This Page!' });
            if (from.name == null && from.path == '/') {
              return next('/')
            } else
              return next(false)
          }
        } else {
          console.log('deny access to ', to.name, '  module')
          router.app.$notify({ message: 'You Do Not Have Access To This Module!' });
          if (from.name == null && from.path == '/') {
            return next('/')
          } else
            return next(false)
        }
      } catch (error) {
        console.log('Error while navigating ', error)
        return next(false);
      }
  })
})

function callNext(next, to) {
  if (app && app.$rtl.isRTL && to.matched[0]) {
    const allowAr = translatedPages.some(tp=> to.path.toLowerCase().includes(tp.toLowerCase()));
    if (!allowAr) {
      store.dispatch('setPreferences', { language: 'en', silent: true });
    }
  } else if (store.state.preferences.actualLanguage == "ar" && app && !app.$rtl.isRTL) {
    const allowAr = translatedPages.some(tp=> to.path.toLowerCase().includes(tp.toLowerCase()));
    if (allowAr) {
      store.dispatch('setPreferences', { language: 'ar', silent: true });
    }
  }
  next();
}

export default router;
