import ForkliftService from "../../services/forklift.service";

export default {
    namespaced: true,
    state: {
        loading: false,
        driverInfo: {},
        drivers: [],
        currentDriverId: undefined
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        driverInfo(state) {
            return state.driverInfo;
        },
        drivers(state) {
            return state.drivers;
        },
    },
    mutations: {
        setLoading(state, value) {
            state.loading = value;
        },
        setDriverInfo(state, driverInfo) {
            state.driverInfo = driverInfo;
        },
        setDrivers(state, drivers) {
            state.drivers = drivers
        },
        selectDriver(state, value) {
            state.currentDriverId = value;
        }
    },
    actions: {
        async fetchDriverInfo({ state, commit }, { id, params = '' }) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true);
                ForkliftService.getVehicleInfo(id, params)
                    .then((res) => {
                        commit("setDriverInfo", res.data.data);
                        commit("setLoading", false);
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        commit('setLoading', false)
                        reject(err)
                    });
            });
        },
        async fetchDrivers({ state, commit }, { id }) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true);
                ForkliftService.getDrivers(id)
                    .then((res) => {
                        commit("setDrivers", res.data.data);
                        commit("selectDriver", res.data.data[0].id)
                        commit("setLoading", false);
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        commit('setLoading', false)
                        reject(err)
                    });
            });
        },
    }
}