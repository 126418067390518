import MutationsTypes from "../types/mutations-types";
import StateTypes from "../types/state-types";
import { initialState } from "./initial-state";
import { mapObjectToSingleAlarmRow } from "../../models/alarms-table-single-row";

export const mutations = {
  [MutationsTypes.RESET_THE_WHOLE_STATE_MUTATION]: (state, _) => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  [MutationsTypes.GETTING_ALARMS_MUTATION]: (state, data) => {
    state[StateTypes.GETTING_ALARMS_STATE_TYPE] = data;
  },
  [MutationsTypes.ALARMS_TOTAL_RECORDS_MUTATION]: (state, data) => {
    state[StateTypes.ALARMS_TOTAL_RECORDS_STATE_TYPE] = data;
  },

  [MutationsTypes.GETTING_CUSTOMER_MUTATION]: (state, data) => {
    state[StateTypes.CUSTOMER_STATE_TYPE] = data;
  },

  [MutationsTypes.CUSTOMER_TOTAL_RECORDS_MUTATION]: (state, data) => {
    state[StateTypes.CUSTOMER_TOTAL_RECORDS_STATE_TYPE] = data;
  },

  [MutationsTypes.CUSTOMER_LOCATIONS_ARRAY_MUTATION]: (state, data) => {
    state[StateTypes.CUSTOMER_LOCATIONS_ARRAY_STATE_TYPE] = data;
  },

  [MutationsTypes.CUSTOMER_CONTACTS_LIST_MUTATION]: (state, data) => {
    state[StateTypes.CUSTOMER_CONTACTS_LIST_STATE_TYPE] = data;
  },
  [MutationsTypes.OPEN_ACKNOWLEDGEMENT_MODAL_MUTATION]: (state, data) => {
    state[StateTypes.OPEN_ACKNOWLEDGEMENT_MODAL_STATE_TYPE] = data;
  },
  [MutationsTypes.OPEN_COMMENTS_MODAL_MUTATION]: (state, data) => {
    state[StateTypes.OPEN_COMMENTS_MODAL_STATE_TYPE] = data;
  },
  [MutationsTypes.OPEN_ESCALATE_MODAL_MUTATION]: (state, data) => {
    state[StateTypes.OPEN_ESCALATE_MODAL_STATE_TYPE] = data;
  },
  [MutationsTypes.OPEN_TERMINATE_MODAL_MUTATION]: (state, data) => {
    state[StateTypes.OPEN_TERMINATE_MODAL_STATE_TYPE] = data;
  },
  [MutationsTypes.UPDATE_ALARMS_TABLE_COLUMNS_CONFIG_MUTATION]: (
    state,
    data
  ) => {
    state[StateTypes.OPERATIONAL_ALARMS_TABLE_COLUMNS_STATE_TYPE] = data;
  },

  [MutationsTypes.DEVICES_COMPANIES_MUTATION]: (state, data) => {
    state[StateTypes.DEVICES_COMPANIES_STATE_TYPE] = data;
  },

  /**
   *
   */
  [MutationsTypes.LISTEN_TO_ACKNOWLEDGE_ALARM_MUTATION]: (state, data) => {
    const alarms = state[StateTypes.GETTING_ALARMS_STATE_TYPE];
    const anAlarmToAck = alarms.filter(a => +a.id === +data.id)[0];
    console.log("anAlarmToAck: ", anAlarmToAck);
    if (anAlarmToAck) {
      const idx = alarms.indexOf(anAlarmToAck);
      if (anAlarmToAck.endedAt) {
        // Alarm is terminated, and now is acknowledged so remove it
        alarms.splice(idx, 1);
      } else {
        // just acknowldged
        anAlarmToAck.operatorAcknowledgementTime = data.acknowledged_at;
        anAlarmToAck.operatorEmail = data.user.email;
        anAlarmToAck.acknowledgement = data;
        if (
          (data.user && +data.user.id !== +data.rootUserId) ||
          (!data.user && !data.user_id) ||
          (!data.user && +data.user_id !== +data.rootUserId)
        ) {
          anAlarmToAck.highlight = true;
        }
        alarms[idx] = anAlarmToAck;
      }
    }
  },

  /**
   *
   */
  [MutationsTypes.NEW_ALARM_RECEIVED_FROM_SERVER_MUTATION]: (
    state,
    newAlarm
  ) => {
    const newSingleRowAlarm = mapObjectToSingleAlarmRow(newAlarm);
    newSingleRowAlarm.highlight = true;
    const alarms = state[StateTypes.GETTING_ALARMS_STATE_TYPE];
    alarms.unshift(newSingleRowAlarm);

    // Updating statistics
    const statistics = state[StateTypes.GETTING_ALARMS_STATISTICS_STATE_TYPE];
    if (statistics) {
      const requiredStatisticCard = statistics.filter(
        aStatistic => aStatistic["status"] == newSingleRowAlarm["alarmType"]
      )[0];
      if (requiredStatisticCard) {
        requiredStatisticCard["active_alarms"] =
          +requiredStatisticCard["active_alarms"] + 1;
        requiredStatisticCard["week"] = +requiredStatisticCard["week"] + 1;
        requiredStatisticCard["month"] = +requiredStatisticCard["month"] + 1;
      } else {
        const newStatisticCard = {
          month: 1,
          week: 1,
          active_alarms: 1,
          status: newSingleRowAlarm["alarmType"]
        };
        statistics.push(newStatisticCard);
      }
    }
  },

  [MutationsTypes.LISTEN_TO_TERMINATE_ALARM_EVENT_MUTATION]: (state, data) => {
    const alarms = state[StateTypes.GETTING_ALARMS_STATE_TYPE];
    const anAlarmToTerminate = alarms.filter(a => +a.id === +data.id)[0];
    if (anAlarmToTerminate) {
      const idx = alarms.indexOf(anAlarmToTerminate);
      if (anAlarmToTerminate.acknowledgement) {
        // The alarm is acknowledged, so we need to remove it
        alarms.splice(idx, 1);
      } else {
        // The alarm is terminated yet is not acknowledged, so just update the data
        anAlarmToTerminate.endedAt = data.ended_at;
        if(data.termination && data.termination.user_id){
          anAlarmToTerminate.termination = {
            user_id: data.termination.user_id,
            created_at: data.termination.created_at
          };
        }else{
          anAlarmToTerminate.termination = {
            user_id: null,
            created_at: data.ended_at
          }
        }

        if (
          (data.user && +data.user.id !== +data.rootUserId) ||
          (!data.user && !data.user_id) ||
          (!data.user && +data.user_id !== +data.rootUserId)
        ) {
          anAlarmToTerminate.highlight = true;
        }
        alarms[idx] = anAlarmToTerminate;
      }

      // Updating statistics
      const statistics = state[StateTypes.GETTING_ALARMS_STATISTICS_STATE_TYPE];
      if (statistics) {
        const requiredStatisticCard = statistics.filter(
          aStatistic => aStatistic["status"] == anAlarmToTerminate["alarmType"]
        )[0];
        if (requiredStatisticCard) {
          requiredStatisticCard["active_alarms"] =
            +requiredStatisticCard["active_alarms"] - 1;
        }
      }
    }
  },

  [MutationsTypes.UPDATE_ALARM_STATUS_MUTATION]: (state, data) => {
    try {
      const alarms = state[StateTypes.GETTING_ALARMS_STATE_TYPE];
      const anAlarmToUpdate = alarms.filter(
        a => +a.id === +data.id && +a.device.parent_id === +data.parent_id
      )[0];

      if (anAlarmToUpdate) {
        anAlarmToUpdate.device.off = data.off;
        anAlarmToUpdate.highlight = true;
        const idx = alarms.indexOf(anAlarmToUpdate);
        alarms[idx] = anAlarmToUpdate;
      }
    } catch (e) {}
  },

  [MutationsTypes.GETTING_ALARMS_STATISTICS_MUTATION]: (state, data) => {
    state[StateTypes.GETTING_ALARMS_STATISTICS_STATE_TYPE] = data;
  },

  [MutationsTypes.UPDATE_ALARMS_TABLE_COLUMNS_CONFIG_MUTATION]: (
    state,
    data
  ) => {
    state[StateTypes.OPERATIONAL_ALARMS_TABLE_COLUMNS_STATE_TYPE] = data;
  },

  [MutationsTypes.GETTING_ALARMS_HISTORY_MUTATION]: (state, data) => {
    state[StateTypes.GETTING_ALARMS_HISTORY_STATE_TYPE] = data;
  },
  [MutationsTypes.HISTORY_ALARMS_TOTAL_RECORDS_MUTATION]: (state, data) => {
    state[StateTypes.HISTORY_ALARMS_TOTAL_RECORDS_STATE_TYPE] = data;
  },
  [MutationsTypes.GETTING_CUSTOMER_INFORMATION_MUTATION]: (state, data) => {
    state[StateTypes.CUSTOMER_INFORMATION_STATE_TYPE] = data;
  }
};
