

export const USER_PREFERENCES_KEYS = { 
    VSAAS_OPERATION_ALARMS_TABLE_CONFIGURATIONS: 'VSAAS_OPERATION_ALARMS_TABLE_CONFIGURATIONS',
    VSAAS_OPERATIONAL_ALARMS_HISTORY_TABLE_CONFIGURATIONS: 'VSAAS_OPERATIONAL_ALARMS_HISTORY_TABLE_CONFIGURATIONS',
    INTRUSION_OPERATIONAL_ALARMS_TABLE_CONFIGURATIONS: 'INTRUSION_OPERATIONAL_ALARMS_TABLE_CONFIGURATIONS',
    INTRUSION_INTRUSION_ALARMS_TABLE_CONFIGURATIONS: 'INTRUSION_INTRUSION_ALARMS_TABLE_CONFIGURATIONS',
    INTRUSION_INTRUSION_ALARMS_HISTORY_TABLE_CONFIGURATIONS: 'INTRUSION_INTRUSION_ALARMS_HISTORY_TABLE_CONFIGURATIONS',
    SWAM_STATUS_ALARMS_TABLE_CONFIGURATIONS: 'SWAM_STATUS_ALARMS_TABLE_CONFIGURATIONS',
    SWAM_OPERATIONAL_ALARMS_TABLE_CONFIGURATIONS: 'SWAM_OPERATIONAL_ALARMS_TABLE_CONFIGURATIONS',
    SWAM_OPERATIONAL_ALARMS_HISTORY_TABLE_CONFIGURATIONS: 'SWAM_OPERATIONAL_ALARMS_HISTORY_TABLE_CONFIGURATIONS',
    SWAM_STATUS_ALARMS_HISTORY_TABLE_CONFIGURATIONS: 'SWAM_STATUS_ALARMS_HISTORY_TABLE_CONFIGURATIONS'
}