import api from "@/axiosWithDelimiterFile";
import router from "@/routes/router";
const BUILDING = 1;
const VILLA = 2;
const WAREHOUSE = 3;
export default {
    state: {
        building_types: null,
        building_sub_types: null,
        structure_types: null,

        building_type: null,
        building_sub_type: null,
        structure_type: BUILDING,
        information: null,
        floors: null,
        UGFloorsCount: null,
        spaces: null,
        str_loading: false,
        rightWindow: 'StructureInfo',
        spaceOptions: null,

        selectedFloor: null,
        selectedSpace: null,
        selectedIndoorSpace: null,
        selectedDevice: null,
        toolsWindow: false,
        floorToCopy: null
    },
    getters: {
        s_type(state) {
            return state.structure_type;
        },
        buildingType(state) {
            console.log(state)
            return state.building_type;
        },
        buildingSubType(state) {
            return state.building_sub_type;
        },
        isBuilding(state) {
            return state.structure_type == BUILDING;
        },
        isVilla(state) {
            return state.structure_type == VILLA;
        },
        isWarehouse(state) {
            return state.structure_type == WAREHOUSE;
        },
        structure_types(state) {
            if (state.structure_types) {
                return state.structure_types;
            }
            return [];
        },
        building_types(state) {
            if (state.building_types) {
                return state.building_types;
            }
            return [];
        },
        building_sub_types(state) {
            if (state.building_sub_types) {
                return state.building_sub_types;
            }
            return [];
        },
        structureInformation(state) {
            return state.information;
        },
        structure_floors(state) {
            return state.floors;
        },
        structure_spaces(state) {
            return state.spaces;
        },
        floor_spaces(state) {

        },
        rightWindow(state) {
            return state.rightWindow;
        },
        str_loading(state) {
            return state.str_loading;
        },
        spaceOptions(state) {
            return state.spaceOptions;
        },
        toolsWindow(state) {
            return state.toolsWindow;
        },
        selectedFloor(state) {
            return state.selectedFloor;
        },
        selectedSpace(state) {
            return state.selectedSpace;
        },
        selectedIndoorSpace(state) {
            return state.selectedIndoorSpace;
        },
        selectedDevice(state) {
            return state.selectedDevice;
        },
        currentPath(state) {
            const path = [];
            if (state.information) {
                path.push(state.information.structure_name)
                if (state.selectedFloor) {
                    path.push(state.selectedFloor.name)
                }
                if (state.selectedSpace) {
                    path.push(state.selectedSpace.display)
                }
                if (state.selectedIndoorSpace) {
                    path.push(state.selectedIndoorSpace.display)
                }
                if (state.selectedDevice) {
                    path.push(state.selectedDevice.device_name)
                }
            }
            return path;
        },
        floorToCopy(state) {
            return state.floorToCopy;
        }
    },
    mutations: {
        setStructurePrimaryInfo(state, { building_types, building_sub_types, structure_types }) {
            state.building_types = building_types;
            state.building_sub_types = building_sub_types;
            state.structure_types = structure_types;
        },
        setSpacesOptions(state, payload) {
            state.spaceOptions = payload;
        },
        setStructureType(state, payload) {
            state.building_type = null;
            state.building_sub_type = null;
            state.structure_type = payload;
        },
        setBuildingType(state, payload) {
            state.building_type = payload;
        },
        setBuildingSubType(state, payload) {
            state.building_sub_type = payload;
        },
        setStructureInformation(state, payload) {
            state.information = payload;
        },
        setStructureInnerInfo(state, { UGFloorsCount, floors, spaces }) {
            state.floors = floors;
            state.spaces = spaces;
            state.UGFloorsCount = UGFloorsCount;
        },
        reset_structure_store(state) {
            state.structure_type = BUILDING;
            state.information = null;
            state.floors = null;
            state.UGFloorsCount = null;
            state.spaces = null;
            state.str_loading = false;
            state.rightWindow = 'StructureInfo';
            state.selectedFloor = null;
            state.selectedSpace = null;
            state.toolsWindow = false;
            state.selectedIndoorSpace = null;
            state.selectedDevice = null;
        },
        refreshValue(state,payload){
            state[payload.key]={...payload.value}
        },
        openFloor(state, payload) {
            state.selectedFloor = payload;
            if (payload)//open floor
                state.rightWindow = 'FloorInfo';
            else//close floor
                state.rightWindow = 'StructureInfo'
        },
        openSpace(state, payload) {
            if (payload) {
                state.selectedSpace = payload.space;
                if (payload.devices)
                    state.selectedSpace.devices = payload.devices;
                state.rightWindow = 'SpaceInfo';
            }
            else {
                state.selectedSpace = null;
                if (state.selectedFloor) {
                    state.rightWindow = 'FloorInfo';
                } else {
                    state.rightWindow = 'StructureInfo'
                }
            }
        },
        openIndoorSpace(state, payload) {
            if (payload) {
                state.selectedIndoorSpace = payload.space;
                //state.selectedIndoorSpace.devices = payload.devices;
            } else {
                state.selectedIndoorSpace = null;
            }
        },
        openDevice(state, payload) {
            state.selectedDevice = payload;
            if (payload)//open
                state.rightWindow = 'DeviceInfo';
            else//close
                state.rightWindow = 'SpaceInfo'
        },
        toolsWindow(state, payload) {
            state.toolsWindow = payload;
        },
        str_loading(state, payload) {
            state.str_loading = payload;
        },
        updateSpaces(state, newSpace) {
            if (state.selectedSpace) {
                state.selectedSpace.indoorSpaces.push(newSpace);
                state.selectedSpace.spacesTypes = getSpacesTypes(state.selectedSpace.indoorSpaces);
            } else if (state.selectedFloor) {
                state.selectedFloor.spaces.push(newSpace);
                state.selectedFloor.spacesTypes = getSpacesTypes(state.selectedFloor.spaces);
            } else {
                state.spaces.push(newSpace)
            }
        },
        copyCurrentFloor(state) {
            if (!state.selectedFloor) {
                throw new Error('No Floor Selected!');
            }
            state.floorToCopy = state.selectedFloor;
        },
        clearClipboard(state) {
            state.floorToCopy = null;
        }
    },
    actions: {
        activateStructureModule({ state: { building_types, building_sub_types, structure_types } }) {
            if (!building_types || !building_sub_types || !structure_types) {
                api.get(`structures/primaryInfo`)
                    .then(({ data }) => {
                        this.commit('setStructurePrimaryInfo', data.data);
                    })
                    .catch(console.error);
            }
        },
        getSpacesTypes({ state }) {
            if (!state.spaceOptions)
                api.get('spaces/Types')
                    .then(({ data }) => {
                        this.commit('setSpacesOptions', data.data.map(rec => {
                            return {
                                ...rec,
                                value: rec.id,
                                label: rec.name,
                            }
                        }))
                    })
                    .catch(console.error)
        },
        submitStructure({ state }) {
            api.post(`structures`, {
                ...state.information,
                floors: state.floors,
                UGFloorsCount: state.UGFloorsCount
            })
                .then(res => {
                    router.push('/structure/list')
                })
                .catch(console.error);
        },
        getStructure({ state, commit, rootState }, id) {
            this.commit('setSpinner', true);
            api.get(`structures/${id}`)
                .then(res => {
                    res = res.data.data;
                    this.commit('setStructureType', res.structure_type)
                    delete res.structure_type;
                    if (res.building_type) {
                        res.building_type = { name: res.building_type, id: res.building_type_id }
                    }
                    res.parking = rootState.parkings.filter(val => val.id == res.parking).map(val => { return { name: val.name, id: val.id } })[0]
                    res.center = {
                        lat: +res.lat,
                        lng: +res.lng
                    }
                    console.log(res);
                    res.markers = [{ position: res.center }]
                    this.commit('setStructureInformation', res)
                })
                .catch(console.error)
        },
        getFloors({ state }) {
            if ((state.floors == null || state.spaces == null) && !state.str_loading) {
                this.commit('str_loading', true);
                api.get(`structures/${state.information.id}/info`)
                    .then(({ data }) => {
                        data.data.spaces.forEach(s => {
                            s.display = s.name;
                        })
                        data.data.floors.forEach((f, i) => {
                            f.index = i + 1;
                        });
                        this.commit('setStructureInnerInfo', { floors: data.data.floors, spaces: data.data.spaces });
                    })
                    .catch(console.error)
                    .finally(_ => { this.commit('str_loading', false); })
            }
        },
        openFloor({ state }, floor) {
            if (!floor.spaces) {
                this.commit('str_loading', true);
                api.get(`floors/${floor.id}/spaces`)
                    .then(({ data }) => {
                        floor.spaces = data.data;
                        floor.spaces.forEach(s => {
                            s.display = s.number;
                        })
                        floor.spacesTypes = getSpacesTypes(floor.spaces);
                        this.commit('openFloor', floor);
                    })
                    .catch(console.error)
                    .finally(_ => {
                        this.commit('str_loading', false);
                    });
                return;
            }
            this.commit('openFloor', floor)
        },
        openSpace({ state }, space) {
            if (!space.spaces) {
                this.commit('str_loading', true);

                api.get(`spaces/${space.id}/info?withDevices=true`)
                    .then((res) => {
                        space.indoorSpaces = res.data.data;

                        const devices = res.data.devices;
                        const childrenSpaces = {};
                        devices.forEach(d => {
                            if (d.space_id != space.id) {
                                if (childrenSpaces[`${d.space_id}`]) {
                                    childrenSpaces[`${d.space_id}`].push(d);
                                } else {
                                    childrenSpaces[`${d.space_id}`] = [d];
                                }
                            }
                        });
                        space.indoorSpaces.forEach(s => {
                            s.display = s.name;
                            if (childrenSpaces[`${s.id}`]) {
                                s.devices = childrenSpaces[`${s.id}`];
                                s.devices.forEach(d => {
                                    d.space_name = s.name;
                                })
                            } else {
                                s.devices = [];
                            }
                        })
                        space.spacesTypes = getSpacesTypes(space.indoorSpaces);

                        this.commit('openSpace', { space, devices });

                    }).catch(console.error)
                    .finally(_ => {
                        this.commit('str_loading', false);
                    });
                return;
            }
            this.commit('openSpace', { space })
        },
        openIndoorSpace({ state }, indoorSpace) {
            this.commit('openIndoorSpace', { space: indoorSpace });
        },
        submitNewSpace({ state }, payload) {
            this.commit('setSpinner', true);
            return api.post(`spaces`, {
                ...payload,
                floor_id: state.selectedFloor ? state.selectedFloor.id : undefined,
                space_id: state.selectedSpace ? state.selectedSpace.id : undefined,
                structure_id: state.information.id
            })
                .then(({ data }) => {
                    const newSpace = {
                        id: data.data,
                        description: payload.description,
                        type: [state.spaceOptions.find(t => t.value == payload.type_id)].map(r => { return { id: r.value, name: r.label, extendable: r.extendable } })[0],
                        structure_id: state.information.id,
                        number: payload.number,
                        name: payload.name
                    }
                    if (state.selectedSpace) {
                        newSpace.space_id = state.selectedSpace.id;
                        newSpace.display = payload.name;
                    } else if (state.selectedFloor) {
                        newSpace.display = payload.number;
                        newSpace.floor_id = state.selectedFloor.id;
                        state.selectedFloor.spaces_count++;
                        state.selectedFloor.checked = false;

                    } else {
                        newSpace.display = payload.name;
                    }
                    this.commit('updateSpaces', newSpace)
                    this.commit('toolsWindow', false)
                })
                .catch(err => { console.log(err); return Promise.reject(err) })
        },
        toStructure({ state }) {
            this.commit('openFloor', null)
        },
        openDevice({ state }, device) {
            this.commit('openDevice', device)
        },
        goBack({ state }) {
            console.log('GO BACK')
            if (state.selectedDevice) {
                this.commit('openDevice', null)
            } else if (state.selectedIndoorSpace) {
                this.commit('openIndoorSpace', null)
            } else if (state.selectedSpace) {
                this.commit('openSpace', null)
            } else if (state.selectedFloor) {
                this.commit('openFloor', null)
            } else {
                throw new Error('Unknowen path')
            }
        },
        paste({ state }) {
            if (state.floorToCopy && state.floors) {
                const floors = state.floors.filter(f => f.checked);
                const floorsIds = floors.map(f => f.id);

                api.post(`floors/${state.floorToCopy.id}/copy`, {
                    to: floorsIds
                }).then(({ data }) => {
                    const spacesArray = JSON.stringify(state.floorToCopy.spaces);
                    const typesArray = JSON.stringify(state.floorToCopy.spacesTypes);
                    const newFloor = data.copyResult;
                    console.log(data)
                    console.log(newFloor)
                    floors.forEach(f => {
                        if (newFloor[`${f.id}`]) {
                            f.spaces = JSON.parse(spacesArray);
                            f.spaces.forEach(s => {
                                if (s.indoorSpaces) {
                                    s.indoorSpaces.forEach(ins => {
                                        ins.id = newFloor[`${f.id}`][`${s.id}`].children[`${ins.id}`]
                                    })
                                }
                                s.id = newFloor[`${f.id}`][`${s.id}`].new;
                            })
                            f.spacesTypes = JSON.parse(typesArray);
                            f.spaces_count = state.floorToCopy.spaces_count;
                            f.checked = false;
                        } else {
                            this._vm.$notify({
                                title: `Copying To floor: (${f.name}) Failed`,
                                message: `Please refresh the page and try again`,
                                timeout: 10000,
                                icon: 'tim-icons icon-bell-55',
                                horizontalAlign: 'right',
                                verticalAlign: 'top',
                                type: 'danger'
                            })
                        }
                    });
                    this.commit('clearClipboard');
                })
                    .catch(console.error)

            }
        },
        duplicateSpace({ state }, { spaces }) {
            this.commit('setSpinner', true);
            const space = state.selectedSpace;
            api.post(`spaces/${space.id}/duplicate`,{spaces})
                .then(({ data }) => {
                    const type = JSON.stringify(space.type);
                    data.data.forEach((id, index) => {
                        if (id) {
                            state.selectedFloor.spaces.push({
                                id,
                                name: spaces[index].name,
                                number: spaces[index].number,
                                display: spaces[index].number,
                                type: JSON.parse(type),
                                craeted_at: space.craeted_at,
                                description: space.description,
                                floor_id: space.floor_id,
                                structure_id: space.structure_id,
                                type_id: space.type_id
                            })
                            state.selectedFloor.spaces_count++;
                            state.selectedFloor.spacesTypes[space.type.name]++;
                        } else {
                            this._vm.$notify({
                                title: `creating space with number: (${spaces[index].number}) Failed`,
                                message: `Please refresh the page and try again`,
                                timeout: 10000,
                                icon: 'tim-icons icon-bell-55',
                                horizontalAlign: 'right',
                                verticalAlign: 'top',
                                type: 'danger'
                            })
                        }
                    })
                    this.commit('toolsWindow',false)
                    //this.commit('refreshValue',{key:'selectedFloor',value:state.selectedFloor})
                    this.dispatch('goBack');
                })
                .catch(console.error);
        }
    }
}
export function getSpacesTypes(spaces) {
    if (spaces) {
        const types = {};
        spaces.forEach(s => {
            if (types[s.type.name]) {
                types[s.type.name]++;
            } else {
                types[s.type.name] = 1;
            }
        });
        return types;
    }
    return null;
}
export const FLOORS_MAX_COUNT = 300;
