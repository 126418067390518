<template>
  <sidebar-item
    v-if="canModule(M.intrusion)"
    :link="{
      name: 'Intrusion System',
      cartLeft: true,
    }"
  >
    <sidebar-item
      :link="{
        name: 'general status',
        path: '/intrusion-system/dashboard',
      }"
    >
    </sidebar-item>
    <sidebar-item
      :link="{
        name: 'Intrusion Alarms',
        path: '/intrusion/intrusion-alarms',
      }"
    >
    </sidebar-item>
    <sidebar-item
      v-if="canModule(M.intrusion_operational)"
      :link="{
        name: 'Operational Alarms',
        path: '/intrusion/operational-alarms',
      }"
    >
    </sidebar-item>
    <sidebar-item
      :link="{
        name: 'Villas List',
        path: '/intrusion/villas-list',
      }"
    >
    </sidebar-item>
  </sidebar-item>
</template>

<script>
import SidebarItem from "../../../components/SidebarPlugin/SidebarItem";
export default {
  name: "intrusion-system-sidebar-items",
  components: {
    SidebarItem,
  },
};
</script>

<style>
</style>