import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import { M } from "../../Permissions";

const Logs = () =>
    import('./Logs.vue');

export default {
    path: '/logs',
    component: DashboardLayout,
    redirect: '/logs/list',
    name: 'logs',
    meta: {
        module: M.logs
    },
    children: [
        {
            path: 'list',
            name: 'logs list',
            component: Logs
        },


    ]
}
