var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.slider)?_c('div',{staticClass:"row justify-content-around"},[_c('div',{staticClass:"prev"},[_c('button',{staticClass:"float-left btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.prev}},[_c('svg',{staticClass:"bi bi-arrow-left-circle",attrs:{"width":"1.5em","height":"1.5em","viewBox":"0 4 18 10","fill":"currentColor","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"}})])])]),_c('div',{class:[
      { 'col-md-4': _vm.vertical && !_vm.tabNavWrapperClasses },
      { 'col-12': _vm.centered && !_vm.tabNavWrapperClasses },
      _vm.tabNavWrapperClasses,
      'tabs'
    ]},[_c('transition-group',{staticClass:"nav nav-pills",class:[
        ("nav-pills-" + _vm.type),
        { 'nav-pills-icons': _vm.square },
        { 'flex-column': _vm.vertical },
        { 'justify-content-center': _vm.centered },
        _vm.tabNavClasses
      ],attrs:{"name":"list","tag":"ul","role":"tablist","closable":"true"}},_vm._l((_vm.tabsVisible),function(tab){return _c('li',{key:tab.id,staticClass:"nav-item active tab-Dashboard list-item",attrs:{"data-toggle":"tab","role":"tablist","aria-expanded":"true","closable":"true"}},[_c('a',{staticClass:"nav-link",class:{ active: tab.active },attrs:{"data-toggle":"tab","role":"tablist","closable":"true","href":("#" + (tab.id)),"aria-expanded":tab.active},on:{"click":function($event){$event.preventDefault();return _vm.activateTab(tab)}}},[_c('tab-item-content',{attrs:{"tab":tab}})],1)])}),0)],1),_c('div',{staticClass:"next"},[_c('button',{staticClass:"float-right btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.next}},[_c('svg',{staticClass:"bi bi-arrow-right-circle",attrs:{"width":"1.5em","height":"1.5em","viewBox":"0 4 18 10","fill":"currentColor","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"}})])])]),_c('div',{staticClass:"tab-content",class:[
      { 'tab-space col-11': !_vm.vertical },
      { 'col-md-8': _vm.vertical && !_vm.tabContentClasses },
      _vm.tabContentClasses
    ]},[_vm._t("default")],2)]):_c('div',[_c('div',{class:[
      { 'col-md-4': _vm.vertical && !_vm.tabNavWrapperClasses },
      { 'col-12': _vm.centered && !_vm.tabNavWrapperClasses },
      _vm.tabNavWrapperClasses
    ]},[_c('ul',{staticClass:"nav nav-pills",class:[
        ("nav-pills-" + _vm.type),
        { 'nav-pills-icons': _vm.square },
        { 'flex-column': _vm.vertical },
        { 'justify-content-center': _vm.centered },
        _vm.tabNavClasses
      ],attrs:{"role":"tablist","closable":"true"}},_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.id,staticClass:"nav-item active tab-Dashboard",attrs:{"data-toggle":"tab","role":"tablist","aria-expanded":"true","closable":"true"}},[_c('a',{staticClass:"nav-link",class:{ active: tab.active },attrs:{"data-toggle":"tab","role":"tablist","closable":"true","href":("#" + (tab.id)),"aria-expanded":tab.active},on:{"click":function($event){$event.preventDefault();return _vm.activateTab(tab)}}},[_c('tab-item-content',{attrs:{"tab":tab}})],1)])}),0)]),_c('div',{staticClass:"tab-content",class:[
      { 'tab-space': !_vm.vertical },
      { 'col-md-8': _vm.vertical && !_vm.tabContentClasses },
      _vm.tabContentClasses
    ]},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }