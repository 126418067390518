import {applyUniqueIdToAllObjectKeys} from "../../../../util/unique-id-generator";

let types = {

  // Alarms state types
  GETTING_INTRUSION_ALARMS_STATE_TYPE: 'GETTING_INTRUSION_ALARMS_STATE_TYPE',
  GETTING_STATUS_ALARMS_STATE_TYPE: 'GETTING_STATUS_ALARMS_STATE_TYPE',
  GETTING_VILLAS_LIST_STATE_TYPE: 'GETTING_VILLAS_LIST_STATE_TYPE',


  // Total records
  INTRUSION_ALARMS_TOTAL_RECORDS_STATE_TYPE: 'INTRUSION_ALARMS_TOTAL_RECORDS_STATE_TYPE',
  STATUS_ALARMS_TOTAL_RECORDS_STATE_TYPE: 'STATUS_ALARMS_TOTAL_RECORDS_STATE_TYPE',
  VILLAS_DETAILS_TOTAL_RECORDS_STATE_TYPE: 'VILLAS_DETAILS_TOTAL_RECORDS_STATE_TYPE',

  // Table Configs
  INTRUSION_MORO_TABLE_CONFIGS_STATE_TYPE: "INTRUSION_MORO_TABLE_CONFIGS_STATE_TYPE",
  INTRUSION_POLICE_TABLE_CONFIGS_STATE_TYPE: "INTRUSION_POLICE_TABLE_CONFIGS_STATE_TYPE",

  // Modals
  OPEN_ACKNOWLEDGEMENT_MODAL_STATE_TYPE: 'OPEN_ACKNOWLEDGEMENT_MODAL_STATE_TYPE',
  RE_OPEN_ACK_MODAL_STATE_TYPE: 'RE_OPEN_ACK_MODAL_STATE_TYPE',
  CUSTOMER_CONTACTS_LIST_STATE_TYPE: 'CUSTOMER_CONTACTS_LIST_STATE_TYPE',
  OPEN_MORE_INFORMATION_MODAL_STATE_TYPE: 'OPEN_MORE_INFORMATION_MODAL_STATE_TYPE',
  OPEN_VILLA_INFORMATION_MODAL_STATE_TYPE: 'OPEN_VILLA_INFORMATION_MODAL_STATE_TYPE',

  INTRUSION_ALARMS_HISTORY_TABLE_COLUMNS_STATE_TYPE: 'INTRUSION_ALARMS_HISTORY_TABLE_COLUMNS_STATE_TYPE',

  FOR_MORO_INTRUSION_ALARMS_HISTORY_TABLE_COLUMNS_STATE_TYPE: 'FOR_MORO_INTRUSION_ALARMS_HISTORY_TABLE_COLUMNS_STATE_TYPE',

  HISTORY_ALARMS_TOTAL_RECORDS_STATE_TYPE: 'HISTORY_ALARMS_TOTAL_RECORDS_STATE_TYPE',
  HISTORY_DATA_STATE_TYPE: 'HISTORY_DATA_STATE_TYPE',
  INTRUSION_ALARMS_STATISTICS_STATE_TYPE: 'INTRUSION_ALARMS_STATISTICS_STATE_TYPE',
  INTRUSION_ALARMS_CURRENT_FILTER: 'INTRUSION_ALARMS_CURRENT_FILTER',
  CUSTOMER_LOCATIONS_ARRAY_STATE_TYPE: 'CUSTOMER_LOCATIONS_ARRAY_STATE_TYPE',
  VILLAS_DEATILS_COLUMNS_CONFIG_STATE_TYPE: 'VILLAS_DEATILS_COLUMNS_CONFIG_STATE_TYPE',


};


types = applyUniqueIdToAllObjectKeys(types);
export default types;
