import api from 'src/axiosWithDelimiterFile.js';

export default {
    namespaced: true,
    state: {
        show: 'WorkOrderList',
        order: null,
        lot_list: null,
        selected_lot: null,
        devices: [],

    },
    mutations: {
        setQuantity(state, op) {
            let selected_lot = state.selected_lot;
            let order = state.order
            if (selected_lot && order) {
                if (op == 'add') {
                    selected_lot.quantity = selected_lot.quantity + 1;
                    order.total_quantity = order.total_quantity + 1;
                } else if (op == 'minus') {
                    selected_lot.quantity = selected_lot.quantity - 1;
                    order.total_quantity = order.total_quantity - 1;
                }

                state.selected_lot = selected_lot;
                state.order = order;
            }
        }
    },
    actions: {
        getOrderDetails({ state }, id) {
            api
                .get(`orders/${id}`)
                .then(res => {

                    let order = res.data.data;

                    
                    state.show = 'HardwareLotList'
                    state.order = order;
                    state.lot_list = order.blockLOTS;

                    // this.$store.dispatch("workorderstore/setWorkorderView", {
                    //     show: "HardwareLotList",
                    //     order,
                    //     lot_list: order.blockLOTS,
                    //     lot_details: null
                    // });
                })
                .catch(err => {
                    console.log(err);
                });
        },

        getDevices({ state, rootState }, lot) {
            let url = '';
            let category = rootState.device_categories.find(cat => cat.id === lot.template.category_id);
            if (category) {
                switch (category.name) {
                    case 'IOT':
                        url = `iot/devices/search?order_detail_id=${lot.id}`;
                        break;
                    case 'Teltonika':
                        url = `teltonika/devices/search?order_detail_id=${lot.id}`;
                        break;

                }
            }

            api.get(url).then(res => {
                console.log('devices', res);
                if (res.status === 200) {
                    state.selected_lot = lot;

                    let devices = res.data.data;

                    
                    devices.forEach(device => {
                            if (device.device_details) {
                                const arr = device.device_details.split("\n");
                                if (arr.length >= 2) {
                                    device.spaceAddress = arr[0].replace(/&gt;/g, ">");
                                    let device_details = "";
                                    for (let i = 1; i < arr.length; i++) {
                                        if (arr[i] != "") device_details += arr[i] + "\n";
                                    }
                                    device.device_details =
                                        device_details == "" ? null : device_details;
                                }
                            }
                        });
                    state.devices = devices;
                    
                    state.show = 'LotDetails'
                }
            }).catch(err => { console.log(err) })
        },

        setWorkorderView({ state }, workorder) {
            state.show = workorder.show;
            state.order = workorder.order
            state.lot_list = workorder.lot_list;
            state.lot_details = workorder.lot_details;
            state.devices = workorder.devices;
        },
        addDevice({ state }, device) {

            state.devices.push(device);

        },
        updateDevice({ state }, device) {
            let index = state.devices.findIndex(dev => dev.id == device.id);

            state.devices.splice(index, 1, device);

        },
        addLot({ state }, newLot) {

            state.lot_list.push(newLot);
        },
        updateLot({ state }, newLot) {
            let lot_list = state.lot_list;

            let index = lot_list.findIndex(lot => lot.id == newLot.id);



            if (index > -1) {
                lot_list.splice(index, 1, newLot);
                state.lot_list = lot_list;
            }
        },
        reset({ state }) {
            state.show = 'WorkOrderList';
            state.order = null;
            state.lot_list = null;
            state.selected_lot = null;
            state.devices = [];
        }
    },

}