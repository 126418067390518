<template>
  <card class="my-container">
    <div class="row justify-content-between">
      <div class="col-3">
        <base-input label="Name" placeholder="Enter Department Name" v-model="departmentName"></base-input>
      </div>
      <div class="col-3 d-flex align-items-center">
        <base-button @click="openZonesDialog = true">Geo-fences</base-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <working-periods ref="workingPeriods"></working-periods>
      </div>
    </div>
    <el-dialog :visible.sync="openZonesDialog">
      <p slot="title" class="all-zones-title">All Zones</p>

      <ZonesSelector
        :zones="zones"
        :selectedZones="departmentZones"
        @submit="openZonesDialog = false"
      ></ZonesSelector>
    </el-dialog>
    <div class="row d-flex justify-content-center mt-3">
      <base-button @click="submit">Submit</base-button>
    </div>
  </card>
</template>

<script>
import workingPeriods from "../../../components/WorkingPeriods/working-periods.vue";
import ZonesSelector from "../components/Zones-Selector";
import { Dialog } from "element-ui";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      openZonesDialog: false,
      departmentName: "",
      departmentZones: [],
      zones: [],
    };
  },
  created() {
    this.$store.dispatch("getZones").then((res) => {
      this.zones = res;
    });
  },
  computed: {
    ...mapGetters(["CompanyData"]),
  },
  methods: {
    submit() {
      const data = this.$refs.workingPeriods.getData();
      if (!data) {
        this.$notify({
          title: "Invalid Data",
          message: "Check Working Days data",
          type: "danger",
        });
        return;
      }
      this.$store
        .dispatch("createDepartment", {
          name: this.departmentName,
          zones: this.departmentZones.map(x=>x.zone),
          floors: [],
          schedule: data
            .filter((d) => d.checked)
            .map((w) => {
              return { to: w.to, from: w.from, name: w.name };
            }),
        })
        .then((_) => {
            this.$router.replace('./')
        }).catch(console.error);
    },
  },
  components: {
    workingPeriods,
    ZonesSelector,
    [Dialog.name]: Dialog,
  },
};
</script>

<style scoped>
.my-container {
  margin-top: 50px;
}
.card {
  background-color: #27293d99 !important;
}
</style>
