<template>
  <div class="d-flex flex-column">
      <div class="row" v-if="data.title">
        <h5 class="col-12 m-0 font-weight-bold">
            {{ $t(data.title) }}
        </h5>
        </div>
        <div class="row" :class="{'pl-3':data.title}">
            <div class="d-flex px-2 py-1" :class="vertical ? 'col-12' : 'col-6'">
                <div class="col-7 d-flex align-items-center">
                    <span class="point background-ok"></span>
                    <p class="font-weight-bold font-color-4 m-0" v-if="!link">{{ $t('ok') }}</p>
                    <router-link
                        :to="getUrl('ok')"
                        class="clickable font-weight-bold font-color-3" 
                        v-else
                    >
                        {{ $t('ok') }}
                    </router-link>
                </div>
                <span class="col-5 text-right font-weight-bold font-color-5">
                    {{ data.ok }}
                </span>
            </div>
            <div class="d-flex px-2 py-1" :class="vertical ? 'col-12' : 'col-6'">
                <div class="col-7 d-flex align-items-center">
                    <span class="point background-poor"></span>
                    <p class="font-weight-bold font-color-4 m-0" v-if="!link">{{ $t('poor') }}</p>
                    <router-link
                        :to="getUrl('poor')"
                        class="clickable font-weight-bold font-color-3" 
                        v-else
                    >
                        {{ $t('poor') }}
                    </router-link>
                </div>
                <span class="col-5 text-right font-weight-bold font-color-5">
                    {{ data.poor }}
                </span>
            </div>
            <div class="d-flex px-2 py-1" :class="vertical ? 'col-12' : 'col-6'"> 
                <div class="col-7 d-flex align-items-center">
                    <span class="point background-problem"></span>
                    <p class="font-weight-bold font-color-4 m-0" v-if="!link">{{ $t('problem') }}</p>
                    <router-link
                        :to="getUrl('problem')"
                        class="clickable font-weight-bold font-color-3" 
                        v-else
                    >
                        {{ $t('problem') }}
                    </router-link>
                </div>
                <span class="col-5 text-right font-weight-bold font-color-5">
                    {{ data.problem }}
                </span>
            </div>
        </div>
        <progressBar v-if="!hideBar" :data="barData" />
  </div>
</template>

<script>
import progressBar from '@/components/progress-bar';
export default {
    components: {
        progressBar,
    },
    props: {
        data:{
            type: Object,
            desciption:'{ok:0,poor:0,problem:0,title:(Optional)}'
        },
        hideBar: {
            type: Boolean,
            default:false,
        },
        vertical: {
            type: Boolean,
            default: false,
        },
        link: {
            type: String,
            default: '',
        }
    },
    methods: {
        getUrl(state) {
            let url = this.link;
            if(!url.includes('=')) {
                url += '?';
            } else {
                url += '&';
            }
            return url + 'status=' + state;
        },
    },
    computed: {
        barData() {
            let result = [];
            result.push({state:'ok',value:this.data.ok});
            result.push({state:'poor',value:this.data.poor});
            result.push({state:'problem',value:this.data.problem});
            return result;
        },
    },
}
</script>

<style scoped>

.point{
    position: relative;
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    right: 9px;
}

[dir="rtl"] .point {
    right: unset;
    left: 9px;
}

</style>