import ScooterService from "../../../../services/globalMobility.service";

export default {
  namespaced: true,
  state: {
    loading: false,
    data: []
  },
  getters: {
    loading(state){
      return state.loading
    },
    data(state){
      return state.data
    }
  },
  mutations: {
    SET_LOADING(state, payload){
      state.loading = payload
    },
    SET_DATA(state, payload){
      state.data = payload
    }
  },
  actions: {
    async fetchAlarmsData({ commit }, payload) {
      return new Promise((resolve, reject) => {
          commit('SET_LOADING', true);
          ScooterService.getAlarms(payload)
              .then((res) => {
                  commit('SET_DATA', res.data.data)
                  commit("SET_LOADING", false);
                  resolve(res.data.data)
              })
              .catch((err) => reject(err));
      });
    },
  }
}