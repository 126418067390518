import store from '../../store/index'
import MainView from "./Main-View.vue";
import UserProfile from "./User-Profile.vue";
import UserUpdatedProfile from '../Profiles-new/pages/UserProfile'
import WorkingInfo from "./Create-User/Working-Info.vue";
import MedicalInfo from "./Create-User/Medical-info.vue";

import CompanyProfile from "./Company-Profile.vue";
import CompanyZones from "./Company-Pages/Company-Zones.vue";
import CompanyUsers from './Company-Pages/Company-Users.vue';
import CompanyDepartments from './Company-Pages/Company-Departments.vue';
import CompanyDepartmentsCreate from './Company-Pages/Company-Departments-Create.vue';

const beforeEnterUser = function (to, from, next) {
    store.dispatch('getUserData', to.params.id).then(_ => {
        next();
    }).catch(_ => { rejectRoute(to, from, next) });
}
const beforeEnterCompany = function (to, from, next) {
    store.dispatch('getCompanyData', to.params).then(_ => {
        next();
    }).catch(_ => { rejectRoute(to, from, next) });
}
const rejectRoute = (to, from, next) => {
    if (from.name == null && from.path == '/') {
        next({ path: '/' });
    } else {
        next(false)
    }
}
export default [
    {
        path: '/profiles',
        component: MainView,
        props: true,
        redirect: '/profiles/user/me',
        children: [
            {
                path: 'user/me',
                name: 'my profile',
                component: UserProfile,
                props: true,
                beforeEnter: (to, from, next) => {
                    store.dispatch('getUserData', JSON.parse(localStorage.getItem('user')).id).then(_ => {
                        next();
                    }).catch(_ => { next(false) });
                }
            },
            {
                path: 'user/:id',
                name: 'user profile',
                component: UserProfile,
                props: true,
                beforeEnter: beforeEnterUser
            },
            {
                path: 'test/:id',
                name: 'user profile',
                component: UserUpdatedProfile,
                props: true,
                beforeEnter: beforeEnterUser
            },
            {
                path: 'user/:id/working-info',
                name: 'user working info',
                component: WorkingInfo,
                props: true,
                beforeEnter: (to, from, next) => {
                    if (store.state.ProfilesStore.UserData) {
                        store.dispatch('getUserWorkingInfo').then(_ => {
                            return next();
                        }).catch(_ => {
                            rejectRoute(to, from, next);
                        })
                    } else {
                        rejectRoute(to, from, next);
                    }
                }
            },
            {
                path: 'user/:id/medical-info',
                name: 'user medical info',
                component: MedicalInfo,
                props: true,
                beforeEnter: (to, from, next) => {
                    if (store.state.ProfilesStore.UserData) {
                        store.dispatch('getUserMedicalInfo',to.params.id).then(_ => {
                            return next();
                        }).catch(_ => {
                            rejectRoute(to, from, next);
                        })
                    } else {
                        rejectRoute(to, from, next);
                    }
                }
            },
            {
                path: 'Company/:type/:id',
                name: 'company profile',
                component: CompanyProfile,
                props: true,
                beforeEnter: beforeEnterCompany
            },
            {
                path: 'Company/:type/:id/users',
                name: 'company users',
                component: CompanyUsers,
                props: true,
                beforeEnter: beforeEnterCompany
            },
            {
                path: 'Company/:type/:id/departments',
                name: 'company departments',
                component: CompanyDepartments,
                props: true,
                beforeEnter: beforeEnterCompany
            },
            {
                path: 'Company/customer/:id/departments/create',
                name: 'register new department',
                component: CompanyDepartmentsCreate,
                props: true,
                beforeEnter: (to, from, next) => {
                    if(store.getters.CompanyData){
                        return next();
                    }
                    return next(`/profiles/Company/customer/${to.params.id}`);
                 }
            },
        ]
    },
    {
        path: 'profiles/customer/:id/zones',
        name: 'customer zones',
        component: CompanyZones,
        props: true,
        // beforeEnter:beforeEnterCompany
    },
]
