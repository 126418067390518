import ForkliftService from "../../services/forklift.service";
import VehicleInfo from "../../core/VehicleInfo/store";
import DriverInfo from "../../core/DriverInfo/store";
export default {
    namespaced: true,
    modules: {
        VehicleInfo,
        DriverInfo
    },
    state: {
        loading: false,
        device: {},
        dashboard: {}
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        device(state) {
            return state.device;
        },
        dashboard(state) {
            return state.dashboard;
        },
    },
    mutations: {
        setLoading(state, value) {
            state.loading = value;
        },
        setDevice(state, device) {
            state.device = device;
        },
        setDashboard(state, dashboard) {
            state.dashboard = dashboard;
        },
    },
    actions: {
        async fetchDeviceInfo({ state, commit },{id, params}) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true);
                ForkliftService.getForkliftInfo(id, params)
                    .then((res) => {
                        commit("setDevice", res.data.data);
                        commit("setLoading", false);
                        resolve(res.data.data)
                    })
                    .catch((err) => reject(err));
            });
        },
        async fetchDashboardInfo({ state, commit },{id, params}) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true);
                ForkliftService.getDashboardInfo(id, params)
                    .then((res) => {
                        commit("setDashboard", res.data.data);
                        commit("setLoading", false);
                        resolve(res.data.data)
                    })
                    .catch((err) => reject(err));
            });
        },
    }
}