import DashboardLayout from "src/pages/Layout/DashboardLayout.vue";

// Routes components
const IntrusionAlarms = () => import("./components/intrusion-alarms.vue");
const OperationalAlamrs = () => import("./components/operational-alarms.vue");
const VillasList = () => import("./components/villas-list.vue");
const VillaInfo = () => import("./components/villa-info.vue");
const IntrusionAlarmsHistory = () => import('./components/intrusion-alarms-history.vue');
const VillaSensorInteractive = () => import('./pages/InteractiveMap/index.vue');

export default {
  path: "/intrusion",
  component: DashboardLayout,
  redirect: "/intrusion/intrusion-alarms",
  name: "Intrusion",
  children: [
    {
      path: "intrusion-alarms",
      name: "Intrusion Alarms",
      component: IntrusionAlarms
    },
    {
      path: "intrusion-alarms-history",
      name: "Intrusion Alarms History",
      component: IntrusionAlarmsHistory
    },
    {
      path: "villa-sensor-interactive",
      name: "Villa sensor interactive",
      component: VillaSensorInteractive
    },
    {
      path: "operational-alarms",
      name: "intrusion operational alarms",
      component: OperationalAlamrs
    },
    {
      path: "operational-alarms-history",
      name: "intrusion operational alarms history",
      component: IntrusionAlarmsHistory
    },
    {
      path: "villas-list",
      name: "Villas List",
      component: VillasList
    },
    {
      path: "villas-info/:villaId",
      name: "Villa Info",
      component: VillaInfo
    }
  ]
};
