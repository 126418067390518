import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import { M } from "../../Permissions";

const CreateAdmins = () =>
    import( './create-admin.vue');

const AdminList = () =>
    import( './admin-list.vue');


export default {
    path: '/admins',
    component: DashboardLayout,
    redirect:'/admins/list',
    name:'admins',
    meta:{
        module: M.admin
    },
    children: [
        {
            path: 'list',
            name:'Admin List',
            component: AdminList
        },
        {
            path: 'create',
            name: 'Admin Create',
            component: CreateAdmins
        },
        {
            path: 'update/:id',
            name: 'Admin Update',
            component: CreateAdmins
        },
    ]
}
