<template>
  <div>
    <card class="mb-0">
      <p class="h6 text-center">
        {{ $t('maintenanceOrder.descriptionText.acceptedBy') }}
        <em>{{acceptedBy}}</em>
      </p>
      <p
        v-if="working"
        class="h6 text-center"
      >{{ $t('maintenanceOrder.descriptionText.beingProcessed') }}</p>
      <p v-if="stopped" class="h6 text-center">{{ $t('maintenanceOrder.descriptionText.paused') }}</p>
      <p
        v-if="resolved"
        class="h6 text-center"
      >{{ $t('maintenanceOrder.descriptionText.resolved') }}</p>
      <p v-if="closed" class="h6 text-center">{{ $t('maintenanceOrder.descriptionText.closed') }}</p>
      <div v-if="byMe && !(resolved || closed)" class="row justify-content-center">
        <div v-if="accepted || stopped" class="col-md-6 text-center">
          <BaseButton @click="state('startWork')" type="success">{{ $t('maintenanceOrder.startWork') }}</BaseButton>
        </div>
        <div v-if="working" class="col-md-6 text-center">
          <BaseButton @click="state('stopWork')" type="warning">{{ $t('maintenanceOrder.pause') }}</BaseButton>
        </div>
      </div>
      <div v-if="isCreator && resolved" class="row justify-content-center">
        <div class="col-md-6 text-center">
          <BaseButton @click="state('close')" type="success">{{ $t('maintenanceOrder.close') }}</BaseButton>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Badge, BaseButton } from "src/components";

export default {
  name: "rest",
  data() {
    return {
      showModal: false
    };
  },
  components: {
    BaseButton,
    Badge
  },
  methods: {
    state(action) {
      this.$store.dispatch("moStateAction", { newState: action });
    }
  },
  computed: {
    ...mapGetters({
      rejected: "rejected",
      accepted: "accepted",
      working: "working",
      stopped: "stopped",
      resolved: "resolved",
      closed: "closed",
      isCreator: "isCreator",
      mo: "selectedMO"
    }),
    byMe() {
      if (!this.mo.current_assignment.user) {
        return false;
      }
      if (this.mo.current_assignment.user.id == this.$store.state.user.id) {
        return true;
      }
      return false;
    },
    acceptedBy() {
      if (!this.mo.current_assignment.user) {
        return "N/A";
      }
      if (this.byMe) {
        return "You";
      }
      return (
        this.mo.current_assignment.user.first_name +
        " " +
        this.mo.current_assignment.user.last_name
      );
    }
  }
};
</script>

