<template>
    <div>

        <card card-body-classes="table-full-width">
            <div class="customtableheader">
                <div class="py-2 d-flex justify-content-between py-2 col-sm-12">
                    <div class="d-flex flex-row">
                        <NameField ref="added" :item="this.rule" :edit="mode != 'edit'" :disabled="mode != 'edit'"/>
                        <div v-if="mode == 'edit'">
                            <base-select v-model="type" :options="types" class="mb-0"/>
                        </div>
                    </div>

                    <div>
                        <button @click="switchMode" class="btn btn-primary" v-if="mode == 'edit'">{{$t('View')}}</button>
                        <button @click="switchMode" class="btn btn-warning" v-if="mode == 'view'">{{$t('Edit')}}</button>
                    </div>
                </div>
            </div>

            <div>
                <div v-if="mode == 'edit'">
                    <div class="p-5 d-flex justify-content-center" v-if="type == 'permissions'">
                        <el-transfer
                                v-model="rule.permissions"
                                filterable
                                :props="{key: 'action_id', label: 'key'}"
                                :render-content="renderFunc"
                                :titles="['Unselected', 'Selected']"
                                :format="{noChecked: '${total}', hasChecked: '${checked}/${total}'}"
                                :data="permissions"/>
                    </div>
                    <div class="p-5 d-flex justify-content-center" v-if="type == 'modules'">
                        <el-transfer
                                v-model="rule.modules"
                                filterable
                                :props="{key: 'module_id', label: 'key'}"
                                :render-content="renderFunc"
                                :titles="['Unselected', 'Selected']"
                                :format="{noChecked: '${total}', hasChecked: '${checked}/${total}'}"
                                :data="modules"/>
                    </div>
                    <div class="d-flex justify-content-end px-3">
                        <button @click="submit" class="btn btn-success">{{$t("Submit")}}</button>
                    </div>
                </div>
                <div class="row" v-if="mode == 'view'">
                    <div class="col-sm-6">
                        <card card-body-classes="table-full-width">
                            <el-table :data="rule.permissionsArray" height="500px">
                                <el-table-column prop="key" label="Permissions"/>
                            </el-table>
                        </card>
                    </div>
                    <div class="col-sm-6">
                        <card card-body-classes="table-full-width">
                            <el-table :data="rule.modulesArray" height="500px">
                                <el-table-column prop="key" label="Modules"/>
                            </el-table>
                        </card>
                    </div>
                </div>
            </div>
        </card>


    </div>
</template>

<script>
    import NameField from './components/NameField'

    export default {
        components: {NameField},
        data() {
            return {
                mode: 'view',
                type: 'permissions',
                types: [
                    {label: this.$t('Permissions'), value: 'permissions'},
                    {label: this.$t('Modules'), value: 'modules'}
                ],
                permissions: [],
                modules: [],
                rule: {},
                originalRule: {},
                renderFunc: (h, option) => <span>{ option.key }</span>,
            }
        },
        mounted() {
            this.$store.dispatch("getPermissions")
                .then(res => {
                    this.permissions = res
                })

            this.$store.dispatch("getModulesArray")
                .then(res => {
                    this.modules = res
                })

            this.getRule()
        },
        methods: {
            getRule() {
                this.$store.dispatch("getRules")
                    .then(res => {
                        this.rule = res.find(rule => rule.id == this.$route.params.id)

                        if(!this.rule)
                            this.$router.push('/permissions/rules')

                        this.originalRule = this.rule
                        this.rule = {...this.rule}
                        this.rule.permissionsArray = this.rule.permissions
                        this.rule.permissions = this.rule.permissions.map(permission => permission.action_id)
                        this.rule.modulesArray = this.rule.modules
                        this.rule.modules = this.rule.modules.map(module => module.module_id)
                    })
            },
            submit() {

                if (this.validate()) {
                    this.$notify({
                        message: "There are Invalid Data",
                        type: "danger"
                    });
                } else {
                    this.$store.commit("setSpinner", true);

                    this.$api.post(`permission/rules`, [this.rule])
                        .then(res => {
                            const arr = res.data.data;

                            if (arr[0]) {
                                this.$notify({
                                    type: "success",
                                    message: `Rule With Name: ${this.rule.name} Was Updated`
                                })
                                this.updateOriginalRule()

                                this.$router.push('/permissions/rules')
                            } else {
                                this.$notify({
                                    type: "danger",
                                    title: "Duplicated Name",
                                    message: `Rule With Name: ${this.rule.name} Already Exist`,
                                    duration: 7000
                                });
                            }
                        });
                }
            },
            validate() {
                let flag = false;

                let el = this.$refs["added"]
                el.$v.$touch()
                if (el.$v.$invalid || !el.unique) {
                    flag = true
                }

                return flag
            },
            switchMode() {
                this.mode = (this.mode == 'view') ? 'edit' : 'view'
            },
            updateOriginalRule() {
                this.rule.permissionsArray = this.rule.permissions ? this.rule.permissions.map(permissionID => this.permissions.find(p => permissionID == p.action_id)) : []
                this.rule.modulesArray = this.rule.modules ? this.rule.modules.map(moduleID => this.modules.find(p => moduleID == p.module_id)) : []

                this.originalRule.name = this.rule.name
                this.originalRule.permissions = this.rule.permissionsArray
                this.originalRule.modules = this.rule.modulesArray
            }
        }

    }
</script>

<style lang="scss">
    .el-transfer-panel {
      width: 400px;
    }
</style>