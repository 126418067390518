import ScooterService from "../../../../services/globalMobility.service";

export default {
  namespaced: true,
  state: {
    headerLoading: false,
    headerData: [],
    timelineLoading: false,
    timelineData: []
  },
  getters: {
    headerLoading(state){
      return state.headerLoading
    },
    headerData(state){
      return state.headerData
    },
    timelineLoading(state){
      return state.timelineLoading
    },
    timelineData(state){
      return state.timelineData
    },
  },
  mutations: {
    SET_HEADERLOADING(state, payload){
      state.headerLoading = payload
    },
    SET_HEADERDATA(state, payload){
      state.headerData = payload
    },
    SET_TIMELINELOADING(state, payload){
      state.timelineLoading = payload
    },
    SET_TIMELINEDATA(state, payload){
      state.timelineData = payload
    },
  },
  actions: {
    async fetchBasicInfoData({ commit }, scooterID) {
      return new Promise((resolve, reject) => {
          commit('SET_HEADERLOADING', true);
          ScooterService.getBasicInfo(scooterID)
              .then((res) => {
                  commit('SET_HEADERDATA', res.data.data)
                  commit("SET_HEADERLOADING", false);
                  resolve(res.data.data)
              })
              .catch((err) => reject(err));
      });
    },
    async fetchTimeline({ commit }, payload) {
      return new Promise((resolve, reject) => {
          commit('SET_TIMELINELOADING', true);
          ScooterService.getTimeline(payload)
              .then((res) => {
                  commit('SET_TIMELINEDATA', res.data)
                  commit("SET_TIMELINELOADING", false);
                  resolve(res.data.data)
              })
              .catch((err) => reject(err));
      });
    },
  }
}