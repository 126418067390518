<template>
  <card class="d-flex overflow-auto p-0 shadow-none" style="height:400px">
    <div>
      <div v-for="info in data.rows" :key="info.id" class="content">
        <div class="d-flex justify-content-between">
          <div class="d-flex justify-content-between">
            <router-link
              :tag="info.route ? 'a' : 'div'"
              :to="info.route ? info.route : { path: '#' }"
            >
              <h4 class="img-container">
                <inline-svg
                  v-if="info.icon"
                  :src="info.icon"
                  fill="white"
                ></inline-svg>
              </h4>
            </router-link>
            <h4 class="key">{{ $t(info.key) }}</h4>
          </div>
          <div>
            <h4 class="value">{{ info.value ? info.value : "0" }}</h4>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-center">
        <base-button type="success" rounded @click="close" class="mt-3">
          Close
        </base-button>
      </div> -->
    </div>
  </card>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";
export default {
  components: { BaseButton },
  name: "LocationInfo",
  props: ["data"],
  methods: {
    close() {
      this.$emit("closeModel");
    },
  },
};
</script>

<style lang="scss" scoped>
.location-info{
  height:100px
}
.img-container {
  width: 17%;
  max-width: 60px;
  margin-inline-end: 1rem;
}
.img-container svg {
  width: 20px;
  height: auto;
  filter: invert(40%) sepia(8%) saturate(225%) hue-rotate(182deg)
    brightness(91%) contrast(87%);
}
h4,
h2 {
  font-weight: bolder;
}
::v-deep.content {
  margin-top: 1rem;
  padding: 0.5rem 1rem 0 1rem;
  box-shadow: $custom-shadow;
  border-radius: 10px;
  font-weight: bolder;

  .img-container svg path {
    fill: white !important;
  }
}
</style>
