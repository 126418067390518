import AuthLayout from './AuthLayout.vue';
import Login from './Login.vue';
import api from "./../../axiosWithDelimiterFile";

const Token = () =>
    import( './Token.vue');


const Register = () =>
    import( './Register.vue');

const SetPassword = ()=>
    import('./SetPassword.vue');

export default {
    path: '/',
    component: AuthLayout,
    name: 'Authentication',
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login
      },
      {
        path: '/register',
        name: 'Register',
        component: Register
      },


      {
        path: '/token',
        name: 'token',
        component: Token
      },
      {
        path: '/setpassword/:id/:token',
        name: 'set password',
        component: SetPassword,
        async beforeEnter(to, from, next) {
          try {
            await api.post(`auth/validate_token/${to.params.id}`,{token:to.params.token})
            next();
          } catch (error) {
            next({name:'Login'});
          }
        }
      },
    ]
  };
