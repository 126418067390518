<template>
  <card class="statCard">
      <div align="right" class="d-flex flex-row-reverse">
          <slot name="header-right"></slot>
      </div>
      <div class="d-flex justify-content-center" v-if="globalMode">
            <base-select
              :inline="true"
              :label="'Structure: '"
              v-model="currentStructure"
              :options="structuresOptions"
            />
      </div>
      <div class="row">
    
        <div class="col-lg-4 col-md-12 ">
            <Solutions
                :namespace="namespace+'/'+moduleName"
                :structureId="selectedStructureId"
                :disableDataFetch="disableDataFetch"
            />
        </div>
        <div class="col-lg-4 col-md-12">
              <Administrative
                  :namespace="namespace+'/'+moduleName"
                  :structureId="selectedStructureId"
                  :disableDataFetch="disableDataFetch"
              />
        </div>
        <div class="col-lg-4 col-md-12">
              <HighestProblems
                  :namespace="namespace+'/'+moduleName"
                  :disableDataFetch="disableDataFetch"
              />
        </div>
          
      </div>
      
  </card>
</template>

<script>
import HighestProblems from "./highest-Problems/index.vue"
import Administrative from "./Administrative/index.vue";
import Solutions from "./Solutions/index.vue";
import { mapActions, mapGetters,mapState } from "vuex";

export default {
    components: {
        HighestProblems,
        Administrative,
        Solutions,
    },
    props: {
        namespace: {
            type: String,
            default: "SwamStore/StructurePortfolio"
            // required:true
        },
        moduleName: {
            type: String,
            default: 'Statistics'
        },
        globalMode: {
            type: Boolean,
            default: false
        },
        disableDataFetch: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            structureId(state,getters) {
                return getters[this.namespace+"/structureId"];
            }
        }),
        ...mapGetters({
            structuresOptions: "SwamStore/structuresOptions"
        }),
        selectedStructureId() {
            if(this.globalMode) {
                return this.currentStructure;
            } else {
                return this.structureId;
            }
        }
    },
    data() {
        return {
            currentStructure: undefined,
        }
    }
}
</script>

<style scoped>

@media (min-width: 992px) { /* Larg Secreen */
    .statCard {
        height: 318px;
    }
}
@media (max-width: 992px) { /* !Larg Secreen */
    .statCard {
        margin-top: 20px;
    }
}

</style>