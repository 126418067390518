<template>
  <div
    class="wrapper"
    ref="wrapper"
    :class="{ 'nav-open': $sidebar.showSidebar }"
    @scroll.prevent.stop="onScroll"
    @mouseover.prevent.stop="onMouseOver"
  >
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
      :class="
        !fixedNavbar && scrolled ? 'sidebar_extend sideToTop' : 'sidebar_normal'
      "
    >
      <template slot="links-header">
        <sidebar-item
          v-if="user.role_id != 6 && user.role_id != 9"
          :link="{
            name: $t('sidebar.dashBoard'),
            path: '/dashboard',
            icon: 'fa fa-home'
          }"
        ></sidebar-item>
      </template>
      <template slot="links">
        <sidebar-item
          class="side-bar__user-name"
          :link="{
            name: userName,
            icon: 'fa fa-user',
            path: '/profiles/user/me'
          }"
        ></sidebar-item>
        <!-- <sidebar-item
          v-if="user.role_id != 6 && user.role_id != 9"
          :link="{
            name: $t('sidebar.UserDashboard'),
            icon: 'fa fa-chart-pie',
            path: '/dashboard',
          }"
        ></sidebar-item> -->

        <sidebar-item
          :link="{
            name: $t('sidebar.usersAndTemplates'),
            icon: 'fa fa-circle-notch'
          }"
          v-if="
            canModule(M.admin) ||
              canModule(M.fmc) ||
              canModule(M.amc) ||
              canModule(M.operator) ||
              canModule(M.authority) ||
              canModule(M.paying_customer) ||
              canModule(M.templates) ||
              canModule(M.sensors) ||
              canModule(M.structures)
          "
        >
          <sidebar-item
            v-if="canModule(M.admin)"
            :link="{ name: $t('sidebar.admins'), path: '/admins' }"
          ></sidebar-item>
          <sidebar-item
            v-if="
              canModule(M.fmc) ||
                canModule(M.amc) ||
                canModule(M.operator) ||
                canModule(M.authority) ||
                canModule(M.paying_customer)
            "
            :link="{ name: $t('sidebar.companies'), path: '/company' }"
          ></sidebar-item>

          <sidebar-item
            v-if="canModule(M.templates)"
            :link="{ name: $t('sidebar.deviceTemplates'), path: '/template' }"
          ></sidebar-item>

          <sidebar-item
            v-if="canModule(M.sensors)"
            :link="{
              name: $t('sidebar.sensorTemplates'),
              path: '/SWAM/Sensors/list'
            }"
          ></sidebar-item>

          <sidebar-item
            v-if="canModule(M.structures)"
            :link="{
              name: $tc('structureWords.structure', 2),
              path: '/structure'
            }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          v-if="canModule(M.orders)"
          :link="{
            name: $t('sidebar.workOrders'),
            path: '/order',
            icon: 'fa fa-file-alt'
          }"
        ></sidebar-item>
        <!-- sidebar application area -->
        <sidebar-application-item
          :link="{ name: $t('sidebar.application'), icon: 'fa fa-bars' }"
        >
          <!--swam area-->
          <swam-sidebar-items />
          <!--vsaas Smb  area-->
          <vsaas-sidebar-items />

          <!-- Intrusion System area -->
          <intrusion-system-sidebar-items />

          <!-- Forklift  area-->
          <sidebar-item
            v-if="canModule(M.vsaas)"
            :link="{
              name: $t('sidebar.forklift.forklift'),
              cartLeft: true
            }"
          >
            <sidebar-item
              :link="{
                name: $t('sidebar.forklift.portfolio'),
                path: '/forklift/portfolio',
              }"
            >
            </sidebar-item>
            <sidebar-item
              :link="{
                name: $t('sidebar.forklift.devices'),
                path: '/forklift/devices',
              }"
            >
            </sidebar-item>
          </sidebar-item>
           <!-- GlobalMobility -->
          <sidebar-item v-if="canModule(M.globalmobility)" :link="{ name: 'Global Mobility', cartLeft : true, }">
            <sidebar-item :link="{ name: 'Scooter', path: '/globalmobility/scooters', }"> </sidebar-item>
          </sidebar-item>
           <!-- Smart Lock -->
           <sidebar-item v-if="canModule(M.smartlock)" :link="{ name: 'Smart Lock', cartLeft : true, }">
            <sidebar-item :link="{ name: 'Locks', path: '/smartlock/locks', }"> </sidebar-item>
            <sidebar-item :link="{ name: 'Lock', path: '/smartlock/lock', }"> </sidebar-item>
            <sidebar-item :link="{ name: 'Permissions', path: '/smartlock/permissions', }"> </sidebar-item>
          </sidebar-item>


        </sidebar-application-item>

        <sidebar-item
          v-if="canModule(M.attendance)"
          :link="{
            name: $t('ATTENDANCE'),
            path: '/attendence',
            icon: 'tim-icons icon-badge'
          }"
        ></sidebar-item>

        <!-- home automation -->
        <!-- <sidebar-item
          v-if="app_title === 'Smart IOT'"
          :link="{ name:'Home Automation', icon: 'tim-icons icon-tap-02' }"
        >
          </sidebar-item>


          
        </sidebar-application-item>

        <sidebar-item
          v-if="canModule(M.ticketing)"
          :link="{
            name: $t('sidebar.ticketing'),
            path: '/tickets',
            icon: 'fas fa-envelope',
          }"
        ></sidebar-item>

         Devices -->
        <sidebar-item
          :link="{
            name: $tc('deviceWords.device', 2),
            icon: 'tim-icons icon-controller'
          }"
          v-if="user.role_id != 9"
        >
          <!--<sidebar-item
            v-if="canModule(M.iot)"
            :link="{ name: 'SWAM', path: '/device' }"
          ></sidebar-item> -->
          <sidebar-item
            v-if="canModule(M.teltonika)"
            :link="{ name: $t('Health Care'), path: '/healthcare' }"
          ></sidebar-item>
          <sidebar-item
            v-if="canModule(M.tracking)"
            :link="{ name: $t('Vehicle Tracking'), path: '/tracking' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('Smart Tracker'), path: '/smarttracker' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('VSASS Customer'), path: '/vsass-customer' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('VSASS Customer 2'), path: '/vsass-customer-2' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          v-if="canModule(M.maintenance)"
          :link="{
            name: $tc('sidebar.maintenanceOrders'),
            path: '/maintenance',
            icon: 'fas fa-tools'
          }"
        ></sidebar-item>

        <sidebar-item
          v-if="canModule(M.rules)"
          :link="{
            name: $t('sidebar.rules'),
            path: '/permissions/rules',
            icon: 'fa fa-unlock-alt'
          }"
        ></sidebar-item>

        <sidebar-item
          v-if="canModule(M.reports_builder)"
          :link="{
            name: $t('sidebar.reportsBuilder'),
            path: '/reports-builder',
            icon: 'fa fa-edit'
          }"
        ></sidebar-item>

        <!-- setting -->

        <sidebar-item
          :link="{
            name: $t('sidebar.settings'),
            icon: 'fa fa-cog'
          }"
        >
          <sidebar-item
            v-if="canModule(M.user_dashboard)"
            :link="{
              name: $t('sidebar.dashboard'),
              icon: 'tim-icons icon-puzzle-10',
              path: '/userDashboard'
            }"
          ></sidebar-item>
          <!-- release -->
          <sidebar-item
            v-if="canModule(M.releases)"
            :link="{
              name: $t('sidebar.releases'),
              path: '/release',
              icon: 'tim-icons icon-single-copy-04'
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          v-if="canModule(M.logs)"
          :link="{
            name: $t('sidebar.logs'),
            icon: 'fas fa-file-export',
            path: '/logs'
          }"
        ></sidebar-item>
        <DemosSidebarItems />
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <sidebar-share></sidebar-share>
    <div class="main-panel">
      <dashboard-navbar
        v-on:fixnavbar="fixNavbar"
        :class="!fixedNavbar && scrolled ? 'navbar_hide' : 'navbar_show'"
      ></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import SidebarShare from "./SidebarSharePlugin";

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import SidebarFixedToggleButton from "./SidebarFixedToggleButton.vue";
import { SlideYDownTransition, ZoomCenterTransition } from "vue2-transitions";

import { mapState, mapActions } from "vuex";
import SidebarApplicationItem from "../../components/SidebarPlugin/SidebarApplicationItem";

import IntrusionSystemSidebarItems from "../Intrusion-System/components/IntrusionSystemSidebarItems.vue";
import SwamSidebarItems from "../SWAM/components/SwamSidebarItems.vue";
import DemosSidebarItems from "../../demos/index.js";
import VsaasSidebarItems from "@/pages/VSAAS/components/VsaasSidebarItems";

export default {
  components: {
    SidebarApplicationItem,
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    SidebarShare,
    IntrusionSystemSidebarItems,
    SwamSidebarItems,
    DemosSidebarItems,
    VsaasSidebarItems
  },
  data() {
    return {
      solutions: [],

      // data for track scrolling
      limitPosition: 82,
      scrolled: false,
      lastPosition: 0,
      fixedNavbar: false,
      sideToTop: false
    };
  },
  created() {
    this.solutions = this.$store.state.solutions;
  },
  computed: {
    ...mapState({
      preferences: state => state.preferences
    }),
    app_title() {
      return process.env.VUE_APP_TITLE;
    },
    user() {
      return this.$store.getters.user;
    },
    userName() {
      return this.user.first_name + "  " + this.user.last_name;
    }
  },
  mounted() {
    let token = localStorage.getItem("token");

    if (token) {
      this.$IoTSocket.open(token);
      this.$TeltonikaSocket.open(token);
    }
  },
  methods: {
    ...mapActions(["setPreferences"]),
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    // scroll handler
    onScroll() {
      //console.log(event.target.scrollTop);
      let currentScrollPosition = event.target.scrollTop;
      this.sideToTop = !this.sideToTop;

      if (
        this.lastPosition < currentScrollPosition &&
        this.limitPosition < currentScrollPosition
      ) {
        // moving down
        this.scrolled = true;
      }

      if (this.lastPosition > currentScrollPosition) {
        // moving up
        this.scrolled = false;
      }
      //console.log("scrolled",this.scrolled);
      this.lastPosition = currentScrollPosition;
    },
    onMouseOver() {
      let currentMousePositionY = event.pageY;
      let currentDivScrollPosition = this.$refs.wrapper.scrollTop;
      if (
        currentMousePositionY < this.limitPosition ||
        currentDivScrollPosition < 10
      ) {
        this.scrolled = false;
      } else {
        this.scrolled = true;
      }
    },

    fixNavbar(value) {
      this.fixedNavbar = value;
    }
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.user-name {
  text-align: center;
}

.side-bar__user-name a p {
  letter-spacing: 1.1px;
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.navbar_hide {
  transform: translateY(-100%);
  transition-duration: 0.4s;
}

.navbar_show {
  transform: translateY(0);
  transition-duration: 0.4s;
}

.sidebar_extend {
  height: 100% !important;
  margin-top: 0;
  transition: all 0.4s ease-in-out;
}

.sidebar_normal {
  //height:100% !important;
  transition: all 0.4s ease-in-out;
}

.sideToTop {
  top: 0 !important;
  left: 0 !important;
}
</style>
