import ScooterService from "../../../../services/globalMobility.service";

export default {
  namespaced: true,
  state: {
    loading: false,
    speed: []
  },
  getters: {
    loading(state){
      return state.loading
    },
    speed(state){
      return state.speed
    }
  },
  mutations: {
    SET_LOADING(state, payload){
      state.loading = payload
    },
    SET_SPEED(state, payload){
      state.speed = payload
    }
  },
  actions: {
    async fetchSpeedData({ commit }, payload) {
      return new Promise((resolve, reject) => {
          commit('SET_LOADING', true);
          ScooterService.getSpeed(payload)
              .then((res) => {
                  commit('SET_SPEED', res.data.data)
                  commit("SET_LOADING", false);
                  resolve(res.data.data)
              })
              .catch((err) => reject(err));
      });
    },
  }
}