var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar },on:{"scroll":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onScroll($event)},"mouseover":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onMouseOver($event)}}},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{class:!_vm.fixedNavbar && _vm.scrolled ? 'sidebar_extend sideToTop' : 'sidebar_normal',attrs:{"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links-header"},[(_vm.user.role_id != 6 && _vm.user.role_id != 9)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.dashBoard'),
          path: '/dashboard',
          icon: 'fa fa-home'
        }}}):_vm._e()],1),_c('template',{slot:"links"},[_c('sidebar-item',{staticClass:"side-bar__user-name",attrs:{"link":{
          name: _vm.userName,
          icon: 'fa fa-user',
          path: '/profiles/user/me'
        }}}),(
          _vm.canModule(_vm.M.admin) ||
            _vm.canModule(_vm.M.fmc) ||
            _vm.canModule(_vm.M.amc) ||
            _vm.canModule(_vm.M.operator) ||
            _vm.canModule(_vm.M.authority) ||
            _vm.canModule(_vm.M.paying_customer) ||
            _vm.canModule(_vm.M.templates) ||
            _vm.canModule(_vm.M.sensors) ||
            _vm.canModule(_vm.M.structures)
        )?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.usersAndTemplates'),
          icon: 'fa fa-circle-notch'
        }}},[(_vm.canModule(_vm.M.admin))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.admins'), path: '/admins' }}}):_vm._e(),(
            _vm.canModule(_vm.M.fmc) ||
              _vm.canModule(_vm.M.amc) ||
              _vm.canModule(_vm.M.operator) ||
              _vm.canModule(_vm.M.authority) ||
              _vm.canModule(_vm.M.paying_customer)
          )?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.companies'), path: '/company' }}}):_vm._e(),(_vm.canModule(_vm.M.templates))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.deviceTemplates'), path: '/template' }}}):_vm._e(),(_vm.canModule(_vm.M.sensors))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.sensorTemplates'),
            path: '/SWAM/Sensors/list'
          }}}):_vm._e(),(_vm.canModule(_vm.M.structures))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$tc('structureWords.structure', 2),
            path: '/structure'
          }}}):_vm._e()],1):_vm._e(),(_vm.canModule(_vm.M.orders))?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.workOrders'),
          path: '/order',
          icon: 'fa fa-file-alt'
        }}}):_vm._e(),_c('sidebar-application-item',{attrs:{"link":{ name: _vm.$t('sidebar.application'), icon: 'fa fa-bars' }}},[_c('swam-sidebar-items'),_c('vsaas-sidebar-items'),_c('intrusion-system-sidebar-items'),(_vm.canModule(_vm.M.vsaas))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.forklift.forklift'),
            cartLeft: true
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('sidebar.forklift.portfolio'),
              path: '/forklift/portfolio',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('sidebar.forklift.devices'),
              path: '/forklift/devices',
            }}})],1):_vm._e(),(_vm.canModule(_vm.M.globalmobility))?_c('sidebar-item',{attrs:{"link":{ name: 'Global Mobility', cartLeft : true, }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Scooter', path: '/globalmobility/scooters', }}})],1):_vm._e(),(_vm.canModule(_vm.M.smartlock))?_c('sidebar-item',{attrs:{"link":{ name: 'Smart Lock', cartLeft : true, }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Locks', path: '/smartlock/locks', }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Lock', path: '/smartlock/lock', }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Permissions', path: '/smartlock/permissions', }}})],1):_vm._e()],1),(_vm.canModule(_vm.M.attendance))?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('ATTENDANCE'),
          path: '/attendence',
          icon: 'tim-icons icon-badge'
        }}}):_vm._e(),(_vm.user.role_id != 9)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$tc('deviceWords.device', 2),
          icon: 'tim-icons icon-controller'
        }}},[(_vm.canModule(_vm.M.teltonika))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('Health Care'), path: '/healthcare' }}}):_vm._e(),(_vm.canModule(_vm.M.tracking))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('Vehicle Tracking'), path: '/tracking' }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('Smart Tracker'), path: '/smarttracker' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('VSASS Customer'), path: '/vsass-customer' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('VSASS Customer 2'), path: '/vsass-customer-2' }}})],1):_vm._e(),(_vm.canModule(_vm.M.maintenance))?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$tc('sidebar.maintenanceOrders'),
          path: '/maintenance',
          icon: 'fas fa-tools'
        }}}):_vm._e(),(_vm.canModule(_vm.M.rules))?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.rules'),
          path: '/permissions/rules',
          icon: 'fa fa-unlock-alt'
        }}}):_vm._e(),(_vm.canModule(_vm.M.reports_builder))?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.reportsBuilder'),
          path: '/reports-builder',
          icon: 'fa fa-edit'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.settings'),
          icon: 'fa fa-cog'
        }}},[(_vm.canModule(_vm.M.user_dashboard))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.dashboard'),
            icon: 'tim-icons icon-puzzle-10',
            path: '/userDashboard'
          }}}):_vm._e(),(_vm.canModule(_vm.M.releases))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.releases'),
            path: '/release',
            icon: 'tim-icons icon-single-copy-04'
          }}}):_vm._e()],1),(_vm.canModule(_vm.M.logs))?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.logs'),
          icon: 'fas fa-file-export',
          path: '/logs'
        }}}):_vm._e(),_c('DemosSidebarItems')],1)],2),_c('sidebar-share'),_c('div',{staticClass:"main-panel"},[_c('dashboard-navbar',{class:!_vm.fixedNavbar && _vm.scrolled ? 'navbar_hide' : 'navbar_show',on:{"fixnavbar":_vm.fixNavbar}}),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }