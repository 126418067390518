<template>
  <base-select
    size="mini"
    v-model="selectedChartType"
    :options="this.chartTypeOptionsGetter"
    :popperClass="requireZoom ? 'zoomedout-widget' : ''"
    :light="true"
    class="mb-0"
    :style="{ 'max-width': maxWidth }"
  ></base-select>
</template>

<script>
export default {
  data() {
    return {
      selectedChartType: "area",
    };
  },
  created() {
    this.selectedChartType = this.chartType;
  },
  mounted() {
    // this.updateChartAction(this.selectedChartType);
    this.$emit("changeChart", this.selectedChartType);
  },
  props: {
    requireZoom: {
      type: Boolean,
      required: true,
      default: false,
    },
    maxWidth: {
      type: String,
      required: false,
      default: "100px",
    },
    chartType: {
      type: String,
      required: false,
      default: "area",
    },
  },
  watch: {
    selectedChartType: {
      handler(newValue, oldValue) {
        this.$emit("changeChart", this.selectedChartType);
      },
    },
  },
  methods: {},
  computed: {
    chartTypeOptionsGetter() {
      return [
        { value: "area", label: "area" },
        { value: "line", label: "line" },
        { value: "bar", label: "bar" },
      ];
    },
  },
};
</script>