<template>
  <div class="my-progress-bar">
    <template v-for="(item, index) in data">
      <el-tooltip v-if="item.value" :content="item.value+''" :key="index" placement="bottom">
        <div
          class="piece"
          :class="'background-'+item.state"
          :style="`width:${(item.value/total)*100}%;`"
        >
        </div>
      </el-tooltip>
    </template>
    <div
      v-if="total == 0"
      class="piece"
      style="width:100%;background-color:Var(--lighter)"
    >
    </div>
  </div>
</template>

<script>
export default {
    props: {
        data: {
          type: Array,
          default: function() {
            let data = [
                {state:'problem',value:0},
                {state:'ok',value:0},
                {state:'poor',value:0}
              ];
            return data
          },
        },
    },
    computed: {
        total() {
            let count = 0;
            this.data.forEach((e) => {
              count += e.value;
            });
            return count;
        }
    },
}
</script>

<style scoped lang="scss">
.my-progress-bar {
  height: 10px;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  .piece {
    height: 100%;
    display: inline-block;
    border: none;
    transition: all 0.3s linear;
  }
  .piece:hover {
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.55);
    transform: translateY(-1px);
  }
}

[dir="ltr"] .piece:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

[dir="rtl"] .piece:first-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

[dir="ltr"] .piece:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

[dir="rtl"] .piece:last-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
  
</style>