export const barChartConfigurations = {
    type: "bar",
    title: {
      // "text": "Tech Giant Quarterly Revenue",
      "font-color": "#7E7E7E",
      backgroundColor: "none",
      "font-size": "22px",
      alpha: 1,
      "adjust-layout": true
    },
    plotarea: {
      "margin-top": "0",
      "margin-right": "30",
      "margin-bottom": "90",
      "margin-left": "30"
    },
    plot: {
      "bars-space-left": 0.15,
      "bars-space-right": 0.15,
      borderRadius: 3,
      "bar-width": "30px",
      animation: {
        effect: "ANIMATION_SLIDE_BOTTOM",
        sequence: 0,
        speed: 800,
        delay: 800
      }
    },
    scaleY: {
      "line-color": "",
      item: {
        "font-color": "#7e7e7e"
      },
      guide: {
        visible: true
      },
      tick: {
        "line-color": ""
      },
      label: {
        // "text": "$ Billions",
        "font-family": "arial",
        bold: true,
        "font-size": "14px",
        "font-color": "#7E7E7E"
      }
    },
    scaleX: {
      // label: {
      //   text: "Date",
      //   "font-size": "14px",
      //   "font-weight": "normal",
      //   "offset-x": "10%",
      //   "font-angle": 360,
      // },
      label: {
        text: "Date",
        "font-size": "14px",
        "font-weight": "normal",
        "offset-x": "10%",
        "font-angle": 360
      },
      "line-color": "",
      placement: "default",
      tick: {
        "line-color": ""
        // size: 20
      },
      item: {
        // offsetY: -10
      }
    },
    tooltip: {
      "font-size": "15px",
      text: "%t: %v",
      "text-align": "left",
      "border-radius": 5,
      padding: 10
    },
    tooltip: {
      "font-size": "15px",
      text: "%t: %v",
      "text-align": "left",
      "border-radius": 5,
      padding: 10
    }
  };