<template>
  <form :class="{'my-container':!formOnly}" @submit.prevent="submitForm">
    <div class="row">
      <div class="col-md-4 custom-input">
        <base-input
          label="Emergency Name"
          type="text"
          placeholder="Enter a Name"
          v-model.trim="$v.emergencyName.$model"
          :disabled="!edit"
        >
          <template v-slot:error v-if="$v.emergencyName.$error">
            <span class="message">Emergency name is required</span>
          </template>
        </base-input>
      </div>
      <div class="col-md-4 custom-input">
        <base-input
          label="Emergency Number"
          type="text"
          placeholder="Enter Emergency Number"
          v-model.trim="$v.emergencyNumber.$model"
          :disabled="!edit"
        >
          <template v-slot:error v-if="$v.emergencyNumber.$error">
            <span class="message">Emergency number is required</span>
          </template>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-md-4 custom-input">
        <base-input
          type="number"
          label="Height"
          v-model.trim="$v.height.$model"
          :disabled="!edit"
          placeholder="Enter Your Height"
        >
          <template v-slot:error v-if="$v.height.$error">
            <span class="message">Height is requried</span>
          </template>
        </base-input>
      </div>
      <div class="col-6 col-md-4 custom-input">
        <base-input
          type="number"
          label="Weight"
          v-model.trim="$v.weight.$model"
          :disabled="!edit"
          placeholder="Enter Your Weight"
        >
          <template v-slot:error v-if="$v.weight.$error">
            <span class="message">Weight is requried</span>
          </template>
        </base-input>
      </div>
      <div class="col-md-4 custom-input">
        <base-input type="text" label="Blood Type" :disabled="!edit">
          <select
            name
            id="bloodType"
            class="form-control"
            v-model.trim="$v.bloodType.$model"
            :disabled="!edit"
          >
            <option selected>Choose Your Blood Type</option>
            <option v-for="type in bloodTypes" :key="type" :value="type">{{ type }}</option>
          </select>
          <template v-slot:error v-if="$v.bloodType.$error">
            <span class="message">Blood Type is requried</span>
          </template>
        </base-input>
      </div>
    </div>
    <div class="row pt-4 inspections">
      <div class="col-12 col-md-2 text-center">
        <span>
          <inline-svg :src="require('@/assets/Icons/covid.svg')"></inline-svg>
        </span>
      </div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-4 col-md-4 col-lg-2 pt-2">
            <span>Covid-19 Test Result</span>
          </div>
          <div class="col-4 col-md-4 col-lg-2">
            <base-radio inline name="positive" v-model="covidTest" :disabled="!edit">Positive</base-radio>
          </div>
          <div class="col-4 col-md-4 col-lg-2">
            <base-radio inline name="negative" v-model="covidTest" :disabled="!edit">Negative</base-radio>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-12">
            <div class="row mt-4">
              <div class="col-12 col-md-2 mt-1">
                <span>Last Positive Test Date</span>
              </div>
              <div class="col-12 col-md-4 custom-input">
                <base-input>
                  <el-date-picker
                    :disabled="covidTest == 'negative' || !edit"
                    type="date"
                    placeholder="Pick a day"
                    v-model="covidTestDate"
                  ></el-date-picker>
                </base-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-4 pb-4 inspections">
      <div class="col-12 col-md-2 text-center">
        <span>
          <inline-svg :src="require('@/assets/Icons/sars.svg')"></inline-svg>
        </span>
      </div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-4 col-md-4 col-lg-2 pt-2">
            <span>Sars Test Result</span>
          </div>
          <div class="col-4 col-md-4 col-lg-2">
            <base-radio inline name="positive" v-model="sarsTest" :disabled="!edit">Positive</base-radio>
          </div>
          <div class="col-4 col-md-4 col-lg-2">
            <base-radio inline name="negative" v-model="sarsTest" :disabled="!edit">Negative</base-radio>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-12">
            <div class="row mt-4">
              <div class="col-12 col-md-2 mt-1">
                <span>Last Positive Test Date</span>
              </div>
              <div class="col-12 col-md-4 custom-input">
                <base-input>
                  <el-date-picker
                    :disabled="sarsTest == 'negative' || !edit"
                    type="date"
                    placeholder="Pick a day"
                    v-model="sarsTestDate"
                  ></el-date-picker>
                </base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-12 col-md-2">
            <span>Sars Type</span>
          </div>
          <div class="col-12 col-md-4 custom-input">
            <base-select
              :disabled="sarsTest == 'negative'"
              :options="sarsTypes"
              placeholder="Select Sars Type"
              v-model="sarsType"
            ></base-select>
            <!-- <span v-if="!edit">{{sarsType}}</span> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-2 pb-4 inspections">
      <div class="col-12 col-md-2 text-center">
        <span>
          <inline-svg :src="require('@/assets/Icons/medical.svg')"></inline-svg>
        </span>
      </div>
      <div class="col-md-10">
        <span>Sick History</span>
        <div class="row">
          <div class="col-md-3" v-for="disease in diseases" :key="disease.id">
            <base-input>
              <base-checkbox
                class="test"
                :disabled="!edit"
                v-model="disease.checked"
              >{{ disease.name }}</base-checkbox>
            </base-input>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-10">
            <span v-if="edit">Type any medical allergy info</span>
            <span v-else>medical allergy info</span>
          </div>
          <div class="col-12 col-md-10">
            <base-input>
              <textarea class="form-control" rows="3" v-model="allergyInfo" :disabled="!edit"></textarea>
            </base-input>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-around">
      <base-button
        v-if="!formOnly"
        @click="$router.back()"
        style="margin-top: 20px"
        type="info"
      >Back</base-button>
      <base-button
        native-type="submit"
        :disabled="!edit"
        style="margin-top: 20px"
        type="primary"
      >Submit</base-button>

      <base-button
        v-if="!formOnly"
        type="button"
        style="margin-top: 20px"
        @click="toggleEdit"
      >{{ edit ? 'Cancel': 'Edit'}}</base-button>
    </div>
  </form>
</template>
<script>
import { Select, Option, TimeSelect } from "element-ui";
import { BaseRadio } from "src/components";
import BaseSelect from "../../../components/Inputs/BaseSelect";
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

const BLOOD_TYPES = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

const inBloodTypes = (value) => {
  return BLOOD_TYPES.indexOf(value) != -1;
};

export default {
  created() {
    this.edit = this.formOnly;
    this.sarsTypes = this.medicalPrimaryInfo.sars_type.map((s) => {
      return {
        label: s.name,
        value: s.id,
      };
    });

    this.diseases = this.medicalPrimaryInfo.chronic_diseases;
    this.emergencyNumber = this.populateWith.emergency_phone;
    this.emergencyName = this.populateWith.emergency_name;
    this.height = this.populateWith.height;
    this.weight = this.populateWith.weight;
    this.bloodType = this.populateWith.blood_group;
    this.allergyInfo = this.populateWith.allergies;

    if (this.populateWith.positive_covid_date) {
      this.covidTestDate = this.populateWith.positive_covid_date;
      this.covidTest = "positive";
    }

    this.diseases.forEach((d) => {
      d.checked = this.populateWith.chronic_diseases.some(
        (dd) => d.id == dd.id
      );
    });

    const mySars = this.populateWith.sars_diseases[0];

    if (mySars) {
      this.sarsType = mySars.id;
      this.sarsTestDate = mySars.date_of_infection;
      this.sarsTest = "positive";
    }
  },
  props: {
    bloodTypes: {
      type: Array,
      default: function () {
        return BLOOD_TYPES;
      },
    },
    id: {},
    formOnly: {},
  },
  computed: {
    ...mapGetters({
      populateWith: "medicalInfo",
      medicalPrimaryInfo: "medicalPrimaryInfo",
    }),
  },
  data() {
    return {
      emergencyName: "",
      emergencyNumber: "",
      height: "",
      weight: "",
      bloodType: "",
      allergyInfo: "",
      diseases: [],
      sarsTypes: [],
      covidTest: "negative",
      covidTestDate: "",
      sarsTest: "negative",
      sarsType: "",
      sarsTestDate: "",
      edit: false,
    };
  },
  methods: {
    submitForm(e) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let sars_diseases;
      if (
        this.sarsType &&
        this.sarsType.length != 0 &&
        this.sarsTest == "positive"
      ) {
        sars_diseases = [
          {
            id: this.sarsType,
            date_of_infection: this.gDateFormat(this.sarsTestDate),
          },
        ];
      }
      this.$api
        .patch(`people/${this.id}/medical`, {
          height: this.height,
          weight: this.weight,
          emergency_name: this.emergencyName,
          emergency_phone: this.emergencyNumber,
          blood_group: this.bloodType,
          positive_covid_date:
            this.covidTest == "positive"
              ? this.gDateFormat(this.covidTestDate)
              : undefined,
          allergies: this.allergyInfo,
          chronic_diseases: this.diseases
            .filter((d) => d.checked)
            .map((d) => d.id),
          sars_diseases,
        })
        .then(({ data }) => {
          this.edit = false;
          this.$emit('submitted');
        })
        .catch(console.log);
    },
    toggleEdit() {
      this.edit = !this.edit;
    },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [TimeSelect.name]: TimeSelect,
    [BaseRadio.name]: BaseRadio,
    [BaseSelect.name]: BaseSelect,
  },
  validations: {
    emergencyName: { required },
    emergencyNumber: { required },
    height: { required },
    weight: { required },
    bloodType: { required, inBloodTypes },
    covidTest: { required },
    sarsTest: { required },
  },
  watch: {
    medicalPrimaryInfo() {
      this.sarsTypes = this.medicalPrimaryInfo.sars_type.map((s) => {
        return {
          label: s.name,
          value: s.id,
        };
      });

      this.diseases = this.medicalPrimaryInfo.chronic_diseases;
    },
  },
  destroyed() {
    this.medicalPrimaryInfo.chronic_diseases.forEach((d) => {
      d.checked = false;
    });
  },
};
</script>

<style scoped>
.message {
  color: white;
}
select option {
  color: black;
}

.inspections span {
  color: white;
}
.my-container {
  margin-top: 100px;
}

.edit-icon {
  /* border: 1px solid var(--primary); */
  color: var(--primary);
}
.tim-icons {
  padding: 3px;
  border-radius: 25%;
  cursor: pointer;
}
.custom-input >>> input:disabled {
  background: transparent;
  border: none;
  outline: none;
  cursor: default;
  color: aliceblue;
}

.custom-input >>> select:disabled {
  background: transparent;
  border: none;
  outline: none;
  cursor: default;
  color: aliceblue;
}

.test.disabled >>> label {
  color: aliceblue;
  cursor: default;
}

textarea:disabled {
  background: transparent;
  border: none;
  outline: none;
  cursor: default;
  color: aliceblue;
  padding-left: 0;
}
</style>