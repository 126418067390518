import ForklifReporttService from "../../services/forklift-report.service";

export default {
    namespaced: true,
    state: {
        loading: false,
        data: [],
        autoshopData: []
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        },
        autoshopData(state) {
            return state.autoshopData;
        },
    },
    mutations: {
        setLoading(state, value) {
            state.loading = value;
        },
        setData(state, data) {
            state.data = data;
        },
        setAutoshopsData(state, data) {
            state.autoshopData = data;
        },
    },
    actions: {
        async fetchData({ commit }, forkID) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true);
                ForklifReporttService.getForkliftReport(forkID)
                    .then((res) => {
                        console.log('data', res)
                        commit('setData', res.data)
                        commit("setLoading", false);
                        resolve(res.data.data)
                    })
                    .catch((err) => reject(err));
            });
        },
        async getAutoshops({ commit }) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true);
                ForklifReporttService.getAutoshops()
                    .then((res) => {
                        commit('setAutoshopsData', res.data.data)
                        commit("setLoading", false);
                        resolve(res.data.data)
                    })
                    .catch((err) => reject(err));
            });
        },
        async updateReport(_, payload) {
            return new Promise((resolve, reject) => {
                ForklifReporttService.updateReport(payload.forkID, payload.data)
                    .then((res) => {
                        console.log('update report =>', res)
                        resolve(res.data.data)
                    })
                    .catch((err) => reject(err));
            });
        },
    }
}