import SidebarItems from "./sidebar-items.vue";
import router from "../routes/router";

const demosRoutes = [
    {
        path: "/demos",
        component: () => import('../pages/Layout/DashboardLayout.vue'),
        children: [
            {
                path: 'test',
                name: "test demo",
                component: () => import("./test/test.vue"),
            },
            {
                path: 'cloud-orbit',
                name: "cloud orbit demo",
                component: () => import("./cloud-orbit/index.vue"),
            },
        ],
    }
]

router.addRoutes(demosRoutes);
export default SidebarItems;