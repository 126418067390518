import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import store from '@/store/index';
import { M } from "@/Permissions";
export default {
    path: '/SWAM',
    component: DashboardLayout,
    name: "SWAM",
    redirect: "/SWAM/Structure-Portfolio",
    beforeEnter: (to, from, next) => {
        store.dispatch('SwamStore/FETCH_ITEMS',{
            key:'all',
          }).then(_=>{
            next();
        }).catch(_=>{
            console.error(_);
            next(false);
        });
    },
    children: [
        {
            path: "Structure-Portfolio/overall",
            component: ()=> import("../pages/Structure-Portfolio/index.vue"),
            name: "overall structures portfolio"
        },
        {
            path: "Structure-Portfolio/analysis",
            component: ()=> import("../pages/Analysis/index.vue"),
            name: "analysis structures portfolio"
        },
        {
            path: "Structure-Portfolio/:structureId",
            component: ()=> import("../pages/Structure-Portfolio/index.vue"),
            name: "structure portfolio"
        }, 
        {
            path: "Solution-Portfolio",
            component: ()=> import("../pages/Solution-Portfolio/index.vue"),
            name: "solution portfolio"
        },
        {
            path: 'Alarms/operational',
            name: 'swam operational alarms',
            component: ()=> import('../pages/Operational-Alarms/index.vue'),
            meta: {
                module: M.alarms
            },
        },
        {
            path: 'Alarms/status',
            name: 'status alarms',
            component: ()=> import('../pages/Status-Alarms/index.vue'),
            meta: {
                module: M.alarms
            },
        },
        {
          path: 'Alarms/operational/history',
          name: 'swam operational alarms history',
          component: ()=> import('../pages/Alarms-History/index.vue'),
          meta: {
            module: M.alarms
            },
        },
        {
          path: 'Alarms/status/history',
          name: 'swam status alarms history',
          component: ()=> import('../pages/Alarms-History/index.vue'),
          meta: {
            module: M.alarms
            },
        },
        {
            path: 'Devices/list',
            meta:{
                module:M.iot
            },
            name:'devices list',
            component:()=>  import('../pages/Devices-List/index.vue')
        },
        {
            path: 'Sensors/list',
            name:'Sensor List',
            component:()=> import('../pages/Sensor-Templates/SensorList.vue'),
            meta:{
                module:M.sensors
            },
        },
        {
            path: 'Sensors/create',
            name:'Create Sensor',
            component:()=> import('../pages/Sensor-Templates/SensorEdit.vue'),
            meta:{
                module:M.sensors
            },
        },
        {
            path: 'Sensors/update/:id',
            name:'Update Sensor',
            component:()=> import('../pages/Sensor-Templates/SensorEdit.vue'),
            meta:{
                module:M.sensors
            },
        },
        {
            path: 'Sensors/view/:id',
            name: 'Sensor View',
            component:()=> import('../pages/Sensor-Templates/SensorEdit.vue'),
            meta:{
                module:M.sensors
            },
        },
        {
            path: 'notifications-templates/create-template',
            name:'Create notfications template',
            component:()=> import('../pages/Notifications-Template/create-edit-tempate/CreateEditTemplate.vue')
        },
        {
            path: 'notifications-templates/edit-template',
            name:'Edit notfications template',
            component:()=> import('../pages/Notifications-Template/create-edit-tempate/CreateEditTemplate.vue')
        },
        {
            path: 'notifications-templates/list/:companyId',
            name:'notfications template',
            component:()=> import('../pages/Notifications-Template/templates-list/TemplatesList.vue'),
            props:true
        },
    ]
}