import DashboardLayout from "src/pages/Layout/DashboardLayout.vue";
import AdminRoutes from "src/pages/Admins/Admins-Router.js";

import store from "../store";
import WorkOrderRoutes from 'src/pages/WorkOrder/routes.js';
import DeviceRoutes from 'src/pages/Devices/routes.js';
import TeltonikaRoutes from 'src/pages/TeltonikaDevices/route.js';
import AuthRoutes from 'src/pages/Auth/routes.js';

import TrackingRoutes from 'src/pages/Tracking/routes.js';
import SmartTrackerRoutes from 'src/pages/SmartTracker/routes.js';
import HomeCameraControllRoutes from 'src/pages/HomeCameras/routes.js'


// home automation
//import HomeAutomationRoutes from 'src/pages/HomeAutomation/routes.js';

import CompanyRoutes from "src/pages/Company/routes.js";

import LogsRoutes from "src/pages/Logs/routes.js";
import StructureRoutes from "src/pages/Structure/Structures/routes.js";
import TemplateRoute from "src/pages/Template/routes.js";
import ReleaseRoutes from "src/pages/Release/routes.js";

// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";

import userDashboard from "src/pages/userDashboard.vue";

import TicketsRouter from "./../pages/Ticketing/Tickets-Router";
import MaintenanceRouter from "./../pages/Maintenance/Maintenance-Router";
import PermissionsRouter from "./../pages/Permissions/permissions-router";
import ProfilesRouter from "../pages/Profiles/Profiles-router";
import ReportsDesignerRouter from "../pages/Reports-Designer/router";

import MainDashboard from 'src/pages/MainDashboard/MainDashboard.vue';

import StartUpRoutes from "./../pages/StartUps/router";
import VSaaSRoutes from "../modules/vsaas/routes";
import IntrusionRoutes from "../modules/intrusion-system/routes";


import StyleGuide from "../pages/Style-Guide.vue";
import BaseMap from "../components/BaseMap/MapTest.vue";

import IntrusionSystem from "../pages/Intrusion-System/routes/router.js";
import SmartLock from '../pages/SmartLock/routes/router';

import Forklift from '../pages/Forklift/routes/router';
import GlobalMobility from '../pages/GlobalMobility/routes/router';
import Swam from "../pages/SWAM/routes/router.js";
import Vsaas from "../pages/VSAAS/router.js";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home"
  },
  AuthRoutes,
  VSaaSRoutes,
  IntrusionRoutes,
  TicketsRouter,
  MaintenanceRouter,
  AdminRoutes,
  CompanyRoutes,
  WorkOrderRoutes,
  DeviceRoutes,
  LogsRoutes,
  StructureRoutes,
  TemplateRoute,
//HomeAutomationRoutes,
...TeltonikaRoutes,
 HomeCameraControllRoutes,

  TrackingRoutes,
  SmartTrackerRoutes,
  ReleaseRoutes,
  ReportsDesignerRouter,
  IntrusionSystem,
  Forklift,
  GlobalMobility,
  SmartLock,
  Swam,
  Vsaas,
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    name: "Dashboard layout",
    children: [
      {
        path: "Style-Guide",
        component: StyleGuide
      },
      ...ProfilesRouter,
      PermissionsRouter,
      {
        beforeEnter: (to, from, next) => {
          if (store.state.user && store.state.user.role_id == 6) {
            return next("/SWAM/Structure-Portfolio/overall");
          }else if(store.state.user && store.state.user.role_id == 9){
            return next("/intrusion/intrusion-alarms");
          }
          store
            .dispatch("SwamStore/FETCH_ITEMS", {
              key: "all"
            })
            .finally(_ => next());
        },
        path: "dashboard",
        name: "Dashboard",
        components: {
          default: MainDashboard
        }
      },
      {
        path: "userDashboard",
        name: "User Dashboard",
        components: {
          default: userDashboard
        },
        beforeEnter: (to, from, next) => {
          store
            .dispatch("SwamStore/FETCH_ITEMS", {
              key: "all"
            })
            .finally(_ => next());
        }
      },
      {
        path: "/reporting",
        name: "Reporting",
        redirect: "/reports-builder"
      },
      {
        path: "/reporting/create",
        name: "Create New Report",
        redirect: "/reports-builder"
      }
    ]
  },
  StartUpRoutes,
  {
    path: "/map",
    component: BaseMap
  },
  {
    path: "*",
    component: NotFound
  }
];

export default routes;
