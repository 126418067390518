<template>
  <div v-if="user">
    <profile-picture
      :imgURL="user.img_path || ''"
      :placeholderURL="'/img/placeholder.jpg'"
    ></profile-picture>
    <div class="d-block text-center">
      <h1 class="m-0 font-weight-bold font-color-4 col-12 py-3">
        {{ user.first_name }} {{ user.last_name }}
      </h1>
      <span class="m-0 font-weight-bold font-color-4 py-3">
        {{ user.type }} 
      </span>
      <el-divider direction="vertical"></el-divider>
      <el-tag :type="getStatusColor">
          {{ user.status }}
      </el-tag>
    </div>
    <!-- </span> -->
  </div>
</template>

<script>
import ProfilePicture from "../../../components/ProfilePicture.vue";
import {Tag, Divider} from 'element-ui'
export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    ProfilePicture,
    [Tag.name]: Tag,
    [Divider.name]: Divider,

  },
  mounted() {
    console.log(this.getStatusColor)
  },
  computed: {
    getStatusColor() {
      let color = this.user.status === "active" || this.user.status === "verified"
        ? "success"
        : (this.user.status === "suspended"
        ? "warning"
        : "danger");
      return color;
    }
  }
};
</script>

<style></style>
