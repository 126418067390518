<template>
  <div
    class="form-group"
    :class="{
      'input-group-focus': focused,
      'has-danger': error || $slots.error,
      'has-success': !error && touched,
      'has-label': label,
      'has-icon': hasIcon,
    }"
  >
    <slot name="label">
      <label v-if="label">{{ label }} {{ required ? "*" : "" }}</label>
    </slot>
    <div class="mb-0" :class="{ 'input-group': hasIcon }">
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text">
            <i :class="addonLeftIcon"></i>
          </div>
        </span>
      </slot>
      <slot>
        <base-select
          id="CitySelect"
          v-model="value"
          filterable
          placeholder="Select City"
          no-data-text="Choose Country First"
          class="select-primary"
          @change="onSelect"
          :class="{'view-mode' : viewMode}"
          :disabled="disabled"
          :options="options"
        >
        </base-select>
      </slot>
      <slot name="addonRight">
        <span v-if="addonRightIcon" class="input-group-append">
          <div class="input-group-text">
            <i :class="addonRightIcon"></i>
          </div>
        </span>
      </slot>
    </div>

    <slot name="error" v-if="error || $slots.error">
      <label class="error">{{ error }}</label>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>

<script>
export default {
  name: "cities-select",

  props: {
    countryId: {},
    inputValue: {},
viewMode:{
      type: Boolean,
      default:false
    },
    required: Boolean,
    disabled:{
      type: Boolean,
      default:false
    },
    label: {
      type: String,
      description: "Input label",
    },
    error: {
      type: String,
      description: "Input error",
      default: "",
    },

    addonRightIcon: {
      type: String,
      description: "Input icon on the right",
    },
    addonLeftIcon: {
      type: String,
      description: "Input icon on the left",
    },
  },
  data() {
    return {
      city: "",
      focused: false,
      touched: false,
      options: [],
      cities: [],
      value: "",
    };
  },
  computed: {
    hasIcon() {
      return this.hasLeftAddon || this.hasRightAddon;
    },
    hasLeftAddon() {
      const { addonLeft } = this.$slots;
      return addonLeft !== undefined || this.addonLeftIcon !== undefined;
    },
    hasRightAddon() {
      const { addonRight } = this.$slots;
      return addonRight !== undefined || this.addonRightIcon !== undefined;
    },
  },

  methods: {
    onSelect() {
      this.city = this.cities.find((c) => c.id == this.value);
      this.$emit("input", this.city);
    },
    getCities() {
      if (this.countryId) {
        this.options = [];
        this.value = "";
        this.$store
          .dispatch("CountriesAndCitiesStore/getCities", this.countryId)
          .then((cities) => {
            if (this.countryId == 233) {
              this.value = "" + cities.find((op) => op.name === "Dubai").id;
              this.city = cities.find((c) => c.id == this.value);
              this.$emit("input", this.city);
            }
            this.cities = cities;



        if(this.inputValue){
               this.city = this.cities.find((c) => c.id == this.inputValue);
        if (this.city) {
          this.value = "" + this.inputValue;
          this.$emit("input", this.city);
        }
            }

            this.options = cities.map((city) => {
              return {
                value: "" + city.id,
                label: city.name,
              };
            });
          });
      }
    },
  },

  created() {
    this.getCities();
  },
  watch: {
    countryId: {
      handler() {
        this.getCities();
      },
    },
    inputValue: {
      handler() {
        //watch inputValueprop
        this.city = this.cities.find((c) => c.id == this.inputValue);
        if (this.city) {
          this.value = "" + this.inputValue;
          this.$emit("input", this.city);
        }
      },
    },
  },
};
</script>

<style>

</style>
