var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canModule(_vm.M.vsaas))?_c('sidebar-item',{attrs:{"link":{
      name: _vm.$t('sidebar.vsaasSmb.vsaasSmb'),
      cartLeft : true,
    }}},[_c('sidebar-item',{attrs:{"link":{
      name: _vm.$t('alarmsWords.operationalAlarms'),
      path: '/VSAAS/Operational-Alarms',
    }}}),_c('sidebar-item',{attrs:{"link":{
      name: _vm.$t('sidebar.vsaasSmb.customerList'),
      path: '/VSAAS/Customer-List',
    }}}),_c('sidebar-item',{attrs:{"link":{
      name: 'WorkOrder',
      path: '/VSAAS/WorkOrders',
    }}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }