import ForkliftService from "../../services/forklift.service";

export default {
  namespaced: true,
  state: {
    inventoryData: [],
    inventoryTotal: 0,
    repairHistory: [],
    repairHistoryTotal: 0,
    loadingHistory: false
  },
  getters: {
    inventoryData(state){
      return state.inventoryData
    },
    inventoryTotal(state){
      return state.inventoryTotal
    },
    repairHistory(state){
      return state.repairHistory
    },
    repairHistoryTotal(state){
      return state.repairHistoryTotal
    },
    loadingHistory(state){
      return state.loadingHistory
    },
  },
  mutations: {
    SET_INVENTORY(state, payload){
      state.inventoryData = payload
    },
    SET_INVENTORYTOTAL(state, payload){
      state.inventoryTotal = payload
    },
    SET_REPAIRHISTORY(state, payload){
      state.repairHistory = payload
    },
    SET_REPAIRHISTORYTOTAL(state, payload){
      state.repairHistoryTotal = payload
    },
    SET_LOADINGHISTORY(state, payload){
      state.loadingHistory = payload
    },
  },
  actions: {
    async fetchInventoryData({commit}, payload){
      return new Promise((resolve, reject) => {
        ForkliftService.getInventoryData(payload)
          .then(res => {
            commit('SET_INVENTORY', res.data.data.result)
            commit('SET_INVENTORYTOTAL', res.data.data.total_records)
            resolve(res)
          }).catch(err => reject(err))
      })
    },
    async fetchRepairHistoryData({commit}, payload){
      commit('SET_LOADINGHISTORY', true)
      return new Promise((resolve, reject) => {
        ForkliftService.getRepairHistoryData(payload)
          .then(res => {
            commit('SET_LOADINGHISTORY', false)
            commit('SET_REPAIRHISTORY', res.data.data)
            commit('SET_REPAIRHISTORYTOTAL', res.data.data.total_records)
            resolve(res)
          }).catch(err => reject(err))
      })
    },

  },
  
}