<template>
  <!-- -->
  <div
    class="form-group"
    :class="{
      'input-group-focus': focused,
      'has-danger': error || $slots.error,
      'has-success': !error && touched,
      'has-label': label,
      'has-icon': hasIcon,
    }"
  >
    <slot name="label">
      <label v-if="label">{{ label }} {{ required ? "*" : "" }}</label>
    </slot>
    <div class="mb-0" :class="{ 'input-group': hasIcon }">
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text">
            <i :class="addonLeftIcon"></i>
          </div>
        </span>
      </slot>
      <slot>
        <base-select
          :disabled="disabled"
          @change="onSelect"
          filterable
          class="select-primary"
          :class="{'view-mode' : viewMode}"
          id="CountrySelect"
          no-data-text="Loading..."
          placeholder="Select Country"
          :options="options"
          v-model="value"
        ></base-select>
      </slot>
      <slot name="addonRight">
        <span v-if="addonRightIcon" class="input-group-append">
          <div class="input-group-text">
            <i :class="addonRightIcon"></i>
          </div>
        </span>
      </slot>
    </div>

    <slot name="error" v-if="error || $slots.error">
      <label class="error">{{ error }}</label>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "countries-select",
  props: {
    inputValue: {},
    required: Boolean,

    viewMode: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      description: "Input label"
    },
    error: {
      type: String,
      description: "Input error",
      default: ""
    },

    addonRightIcon: {
      type: String,
      description: "Input icon on the right"
    },
    addonLeftIcon: {
      type: String,
      description: "Input icon on the left"
    }
  },

  data() {
    return {
      country: "",
      focused: false,
      touched: false,
      options: [],
      countries: [],
      value: ""
    };
  },
  computed: {
    hasIcon() {
      return this.hasLeftAddon || this.hasRightAddon;
    },
    hasLeftAddon() {
      const { addonLeft } = this.$slots;
      return addonLeft !== undefined || this.addonLeftIcon !== undefined;
    },
    hasRightAddon() {
      const { addonRight } = this.$slots;
      return addonRight !== undefined || this.addonRightIcon !== undefined;
    }
  },

  methods: {
    onSelect() {
      this.country = this.countries.find(c => c.id == this.value);
      this.$emit("input", this.country);
    },
    setDefault() {}
  },

  mounted() {
    this.setDefault();
  },

  created() {
    this.$store
      .dispatch("CountriesAndCitiesStore/getCountries")
      .then(countries => {
        this.countries = countries;
        if (!this.inputValue) {
          this.value =
            "" + countries.find(op => op.name === "United Arab Emirates").id;
          this.country = this.countries.find(c => c.id == this.value);
          this.$emit("input", this.country);
        } else {
          this.value = "" + this.inputValue;
          this.country = countries.find(c => c.id === this.inputValue);
          this.$emit("input", this.country);
        }
        this.options = countries.map(country => {
          return {
            value: "" + country.id,
            label: country.name
          };
        });
      });
  },
  watch: {
    inputValue: {
      handler() {
        this.country = this.countries.find(c => c.id === this.inputValue);
        if (this.country) {
          this.value = "" + this.inputValue;
          this.$emit("input", this.country);
        }
      }
    }
  }
};
</script>

