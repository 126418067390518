import IntrusionService from "../../services/intrusion.service";
import { onePeriod, twoPeriods } from "../../../../util/periodOptions";

const mapDates = (array) => {
    return array.map(date => new Date(date))
}

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        filters: {
            policeStationId: undefined,
            escalated: undefined,
            reason: undefined,
            alarmType: undefined,
            alarmStatus: undefined,
            periodIndex: 0,
            periodCount: 2
        },
        firstPeriod: {
            dates: [],
            values: [],
        },
        secondPeriod: {
            dates: [],
            values: [],
        },
    }),
    getters: {
        loading(state) {
            return state.loading;
        },
        filters(state) {
            return state.filters;
        },
        onePeriod(state) {
            return onePeriod;
        },
        twoPeriods(state) {
            return twoPeriods;
        },
        firstPeriod(state) {
            return state.firstPeriod;
        },
        secondPeriod(state) {
            return state.secondPeriod;
        },
    },
    mutations: {
        setLoading(state, value) {
            state.loading = value;
        },
        setData(state, data) {
            state.firstPeriod = data.firstPeriod;
            state.firstPeriod.dates = mapDates(state.firstPeriod.dates)
            if (state.filters.periodCount == 2) {
                state.secondPeriod = data.secondPeriod;
                state.secondPeriod.dates = mapDates(state.secondPeriod.dates)
                const x = state.firstPeriod.dates.length;
                const y = state.secondPeriod.dates.length;
                if(x != y){
                  const z = Math.min(x, y);
                  if(z == x){
                    state.firstPeriod.dates.push("");
                    state.firstPeriod.values.push(null);
                  }else{
                    state.secondPeriod.dates.push("");
                    state.secondPeriod.values.push(null);
                  }
                }
            } else {
                state.secondPeriod = { dates: [], values: [] };
            }

        },
        setFilters(state, value) {
            state.filters = value;
        },
        selectFilter(state, filter) {
            if(filter.alarmType == 'operational'){
                state.filters.policeStationId = undefined;
            }
            state.filters[filter.key] = filter.value;
        }
    },
    actions: {
        async fetchData({ state, commit }) {
            commit("setLoading", true);
            try {
                let period = (state.filters.periodCount == 1 ? onePeriod[state.filters.periodIndex] : twoPeriods[state.filters.periodIndex]);
                let filters = { ...state.filters };
                if (state.filters.periodCount == 1) {
                    filters.first_period_start = period.from().toDate();
                    filters.first_period_end = period.to().toDate();
                } else {
                    filters.first_period_start = period.periods[0].from().toDate();
                    filters.first_period_end = period.periods[0].to().toDate();
                    filters.second_period_start = period.periods[1].from().toDate();
                    filters.second_period_end = period.periods[1].to().toDate();
                }
                delete filters.periodCount;
                delete filters.periodIndex;
                const data = await IntrusionService.getIntrusionAlarmsComparison(filters);
                commit("setData", data);
                commit("setLoading", false);
            } catch (error) {
                commit("setLoading", false);
                console.error(error);
            }
        },
        reset({ state }) {
            state.filters = {
                policeStationId: undefined,
                escalated: undefined,
                reason: undefined,
                alarmType: undefined,
                alarmStatus: undefined,
                periodIndex: 0,
                periodCount: 2
            };
            state.firstPerio = {
                dates: [],
                values: [],
            };
            state.secondPeriod = {
                dates: [],
                values: [],
            };
        }
    }
}