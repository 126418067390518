<template>
  <div>
    <profile-header :user="user" class="pb-4" />
    <card class="h-100">
      <div class="row">
        <div class="col-md-6">
          <basic-info :user="user" />
        </div>
        <div class="col-md-6">
          <security-info :user="user" />
          <change-password :user="user" />
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { TabPane, Tabs } from "src/components";
import ProfileHeader from "../../components/ProfileHeader.vue";
import BasicInfo from "./components/BasicInfo.vue";
import SecurityInfo from "./components/SecurityInfo.vue";
import ChangePassword from "./components/ChangePassword.vue";
export default {
  components: {
    ProfileHeader,
    BasicInfo,
    SecurityInfo,
    TabPane,
    Tabs,
    ChangePassword,
  },
  computed: {
    ...mapGetters({
      user: "UserData",
      currentUser: "user",
    }),
  },
  data: () => ({}),
};
</script>

<style></style>
