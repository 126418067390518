<template>
  <component
    :is="baseComponent"
    :to="link.path ? link.path : '/'"
    :class="{ active: isActive }"
    class="nav-li-item"
    tag="li"
  >
    <a
      v-if="isMenu"
      class="sidebar-menu-item font-color-5"
      :aria-expanded="!collapsed"
      data-toggle="collapse"
      @click.prevent="collapseMenu"
    >
      <template v-if="addLink">
        <span class="sidebar-mini-icon font-color-5">{{ linkPrefix }}</span>
        <span class="sidebar-normal font-color-5 d-inline-flex justify-content-between w-75">
         <b class="caret font-color-5" :class="{'caret-left' : link.cartLeft}"></b>
          <span class="font-weight-500">{{ link.name }}</span>
        </span>
      </template>
      <template v-else>
        <i class="font-color-5" :class="link.icon"></i>
        <p class="font-color-5 font-weight-500">
          {{ link.name }}
          <b class="caret"  :class="{'caret-left' : link.cartLeft}"></b>
        </p>
      </template>
    </a>

    <collapse-transition>
      <div v-if="$slots.default || this.isMenu" v-show="!collapsed" class="collapse show">
        <ul class="nav">
          <slot></slot>
        </ul>
      </div>
    </collapse-transition>

    <slot name="title" v-if="children.length === 0 && !$slots.default && link.path">
      <component
        :to="link.path"
        @click="linkClick"
        :is="elementType(link, false)"
        :class="{ active: link.active }"
        class="sidebar-menu-item"
        :target="link.target"
        :href="link.path"
      >
        <template v-if="addLink">
          <span class="sidebar-mini-icon font-color-5">{{ linkPrefix }}</span>
          <span class="sidebar-normal font-color-5">{{ link.name }}</span>
        </template>
        <template v-else>
          <i class=" font-color-5" :class="link.icon"></i>
          <inline-svg
            v-if="link.img"
            :src="getSvg(link.img)"
            width="25"
            height="25"
            class="sidebar-menu-img"
          />
          <p class="font-color-5 font-weight-500" >{{ link.name }}</p>
        </template>
      </component>
    </slot>
  </component>
</template>
<script>
  import {CollapseTransition} from "vue2-transitions";

  export default {
    name: "sidebar-item",
    components: {
      CollapseTransition,
    },
    props: {
      menu: {
        type: Boolean,
        default: false,
        description:
          "Whether the item is a menu. Most of the item it's not used and should be used only if you want to override the default behavior.",
      },
      link: {
        type: Object,
        default: () => {
          return {
            name: "",
            path: "",
            cartLeft : false,
            children: [],
          };
        },
        description:
          "Sidebar link. Can contain name, path, icon and other attributes. See examples for more info",
      },
    },
    provide() {
      return {
        addLink: this.addChild,
        removeLink: this.removeChild,
      };
    },
    inject: {
      addLink: {default: null},
      removeLink: {default: null},
      autoClose: {
        default: true,
      },
    },
    data() {
      return {
        children: [],
        collapsed: true,
      };
    },
    computed: {
      baseComponent() {
        return this.isMenu || this.link.isRoute ? "li" : "router-link";
      },
      linkPrefix() {
        if (this.link.name) {
          let words = this.link.name.split(" ");
          return words.map((word) => word.substring(0, 1)).join("");
        }
      },
      isMenu() {
        return this.children.length > 0 || this.menu === true;
        // return false;
      },
      isActive() {
        if (this.$route && this.$route.path) {
          let matchingRoute = this.children.find((c) =>
            this.$route.path.startsWith(c.link.path)
          );
          if (matchingRoute !== undefined) {
            return true;
          }
        }
        return false;
      },
    },
    methods: {
      getSvg(img) {
        return require(`../../assets/Icons/${img}`);
      },
      addChild(item) {
        const index = this.$slots.default.indexOf(item.$vnode);
        this.children.splice(index, 0, item);
      },
      removeChild(item) {
        const tabs = this.children;
        const index = tabs.indexOf(item);
        tabs.splice(index, 1);
      },
      elementType(link, isParent = true) {
        if (link.isRoute === false) {
          return isParent ? "li" : "a";
        } else {
          return "router-link";
        }
      },
      linkAbbreviation(name) {
        const matches = name.match(/\b(\w)/g);
        return matches.join("");
      },
      linkClick() {
        if (
          this.autoClose &&
          this.$sidebar &&
          this.$sidebar.showSidebar === true
        ) {
          this.$sidebar.displaySidebar(false);
        }
      },
      collapseMenu() {
        this.collapsed = !this.collapsed;
        if (this.link.path && !this.collapsed) {
          this.$router.push(this.link.path).catch((_) => {
          });
        }
      },
      collapseSubMenu(link) {
        link.collapsed = !link.collapsed;
      },
    },
    mounted() {
      if (this.addLink) {
        this.addLink(this);
      }
      if (this.link.collapsed !== undefined) {
        this.collapsed = this.link.collapsed;
      }
      if (this.isActive && this.isMenu) {
        this.collapsed = false;
      }
    },
    destroyed() {
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
      if (this.removeLink) {
        this.removeLink(this);
      }
    },
  };
</script>
<style lang="scss">
  .sidebar-menu-img {
    float: left;
    margin-right: 19px;
    color: rgba(255, 255, 255, 0.8);
    position: relative;
    margin-left: 4px;
  }

  .sidebar-menu-img path {
    fill: rgba(255, 255, 255, 0.8);
  }

  .sidebar-menu-item {
    font-size: 14px !important;
    width: 100% !important;
    margin: 0 6px !important;
    cursor: pointer;
  }

  .nav-li-item:hover{
    background-color: #00000010;
    border-radius: 0 !important;
  }
  .nav-li-item a{
    white-space: nowrap !important;
  }
  // .collapse .nav .nav-li-item{
  //   background: $light-bg !important;
  // }
  .collapse .nav-li-item .sidebar-menu-item .dark-color {
    color: $dark-blue-4 !important;
  }
  .caret-left {
    left: -30px !important;
    top: 15px !important;
  }
</style>
