<template>
  <div v-if="dataMO" class="mo-de">
    <div class="row">
      <div class="col-12">
        <card class="text-center high-card-shadow">
          <div>
            <h3>{{$t('workTimer')}}</h3>
          </div>
          <h6 @mouseenter="showAll = true" @mouseleave="showAll = false">
            <!-- <span v-tooltip="{content:'Hours',show:showAll}" class="timer">{{hs}} :</span>
            <span v-tooltip="{content:'Minutes',show:showAll}" class="timer">{{mins}} :</span>
            <span v-tooltip="{content:'Seconds',show:showAll}" class="timer">{{secs}}</span> -->
            <span class="timer">{{hs}} :</span>
            <span class="timer">{{mins}} :</span>
            <span class="timer">{{secs}}</span>
          </h6>
        </card>
        <!-- <component :is="target" /> -->
        <!-- <DeviceInfoCard v-if="dataMO.device_info"/> -->
        <DeviceInfoCard />
        <!-- <SpaceInfoCard /> -->
        <card class="high-card-shadow">
          <h3>{{$t('maintenanceOrder.responsibilities')}}</h3>
          <div class="card-description">
            <div class="col-md-12">{{$t('Annual Maintenance Company')}}</div>

            <div style="font-size: 14px; letter-spacing: 0.6px; color: rgb(51, 51, 51);">
              <div style="color: #1d8cf8;">
                <span class="span-details">
                  &nbsp; &nbsp;
                  {{dataMO.current_assignment.name}}
                </span>
              </div>
            </div>

            <div class="col-md-12">{{$t('maintenanceOrder.currentMaintenanceWorker')}}</div>

            <div style="font-size: 14px; letter-spacing: 0.6px; color: rgb(51, 51, 51);">
              <div style="color: #1d8cf8;">
                <span
                  class="span-details"
                  v-if="dataMO.current_assignment.user"
                >&nbsp; &nbsp; {{dataMO.current_assignment.user.first_name + ' ' + dataMO.current_assignment.user.last_name}}</span>
                <span v-else>&nbsp; &nbsp; ---</span>
              </div>
            </div>

            <div class="col-md-12">{{$t('maintenanceOrder.requestedBy')}}</div>
            <div style="font-size: 14px; letter-spacing: 0.6px; color: rgb(51, 51, 51);">
              <div style="color: #1d8cf8;">
                <span class="span-details">&nbsp; &nbsp; {{dataMO.creator_name}}</span>
              </div>
            </div>

            <hr />
            <hr />
          </div>
          <div class="card-description">
            <h3>{{ $t('maintenanceOrder.details') }}</h3>
            <!-- <div class="col-md-12 mb-2">
              <div class="row">
                <div class="col-md-5">
                  <label class="labelWO">{{$t('Target Name')}}:</label>
                </div>
                <div class="col-md-7">
                  <span v-if="dataMO.structure_id">{{$t('Structure')}} :</span>
                  <span v-else-if="dataMO.device_id">{{$t('Device')}} :</span>
                  <span>{{dataMO.target_name}}</span>
                </div>
              </div>
            </div>-->
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-5">
                  <label class="labelWO">{{$t('maintenanceOrder.type')}}:</label>
                </div>
                <div class="col-md-7">{{dataMO.type.name}}</div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-5">
                  <label class="labelWO">{{$t('maintenanceOrder.priority')}} :</label>
                </div>
                <div class="col-md-7">{{dataMO.priority.name}}</div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-5">
                  <label class="labelWO">{{$t('maintenanceOrder.createdAt')}} :</label>
                </div>
                <div class="col-md-7">{{gDateFormat(dataMO.created_at)}}</div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-5">
                  <label class="labelWO">{{$t('maintenanceOrder.endDate')}} :</label>
                </div>
                <div v-if="dataMO.schedule_to" class="col-md-7">{{dataMO.schedule_to}}</div>
                <div v-else class="col-md-7">{{$t('unset')}}</div>
                <!--   schedule_from: "2020-05-20T09:48:59.000Z"
                schedule_to: "2020-05-14T09:48:59.000Z"-->
              </div>
            </div>

            <div class="col-md-12">
              <div class="row">
                <div class="col-md-5">
                  <label class="labelWO">{{$t('maintenanceOrder.state')}}:</label>
                </div>
                <div class="col-md-7">{{dataMO.state.name}}</div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-5">
                  <label class="labelWO">{{$t('maintenanceOrder.leaseStart')}} :</label>
                </div>
                <div v-if="dataMO.last_start" class="col-md-7">{{dataMO.last_start}}</div>
                <div v-else class="col-md-7">--</div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DeviceInfoCard from "./Targets-Info/Device";
import SpaceInfoCard from "./Targets-Info/Space";
import StructureInfoCard from "./Targets-Info/Structure";
function pad(num) {
  return ("0" + num).slice(-2);
}
export default {
  name: "mo-details",
  components: {
    DeviceInfoCard,
    StructureInfoCard,
    SpaceInfoCard
  },
  data: () => {
    return {
      id: -1,
      showAll: false
    };
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;

      // if (this.$store.state.MaintenaceStore.selectedMO != null)
      //   this.dataMO = this.$store.state.MaintenaceStore.selectedMO;
    }
  },
  computed: {
    ...mapGetters({
      dataMO: "selectedMO",
      workTime: "workTime"
    }),
    secs() {
      return pad(this.workTime % 60);
    },
    mins() {
      return pad(Math.floor(this.workTime / 60) % 60);
    },
    hs() {
      return pad(Math.floor(Math.floor(this.workTime / 60) / 60));
    },
    target() {
      if(this.dataMO.device_info){
        return 'DeviceInfoCard'
      }else if (this.dataMO.structure_info){
        return 'StructureInfoCard';
      }else {
        return null;
      }
    }
  }
};
</script>

<style scoped>
@media (min-width: 576px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .mo-de :not(h3) {
    font-size: 10px !important;
  }
  .mo-de h3 {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
.timer {
  text-align: center;
  font-size: 2rem;
  letter-spacing: 0.2vw;
}
.labelWO {
  color: var(--info);
  font-size: 0.95rem;
  color: rgb(115, 121, 183);
  font-family: MTextFont;
}
.card {
  font-family: greyFont !important;
}
.span-details {
  color: rgb(115, 121, 183);
  font-family: greyFont;
}
.rtl .high-card-shadow{
  text-align: right;
}
</style>
