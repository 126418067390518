import VsaasService from '../../services/vsaas.service';
import { dateTableCellFomatter,emptyTableCellFormatter } from "@/modules/common/utils/helper";
import WorkOrderDetails from '../../core/WorkOrderDetails/store';

export default {
    namespaced: true,
    modules: {
        WorkOrderDetails
    },
    state: {
        loading: false,
        orderListData: [],
        pagination: {
            limit: 10,
            offset: 0,
        },
        totalOrders: 0,
        orderListColumns: [
            {
                label: "Customer",
                prop: "customerId",
                minWidth: 150,
            },
            {
                label: "Structure id",
                prop: "structureId",
                minWidth: 150,
            },
            {
                label: "AMC ID",
                prop: "amcId",
                minWidth: 150,
            },
            {
                label: "Operator Id",
                prop: "operatorId",
                minWidth: 150,
            },
            {
                label: "Nvr Quantity",
                prop: "nvrQuantity",
                minWidth: 150,
            },
            {
                label: "Camera Quantity",
                prop: "cameraQuantity",
                minWidth: 150,
            },
            {
                label: "Created At",
                prop: "createdAt",
                minWidth: 200,
                formatter: dateTableCellFomatter
            },
            {
                label: "Actions",
                prop: "actions",
                minWidth: 150,
                customTemplate: true
            },
        ],
        devicesListColumns: [
            {
                label: "name",
                prop: "name",
                minWidth: 150,
                formatter: emptyTableCellFormatter
            },
            {
                label: "type",
                prop: "type",
                minWidth: 150,
                formatter: emptyTableCellFormatter
            },
            {
                label: "status",
                prop: "on",
                minWidth: 150,
                formatter: (_, __, value) => {
                    if(value == undefined)
                        return '--'
                    return value ? "On" : "Off";
                }
            },
            {
                label: "alarmEmail",
                prop: "alarmEmail",
                minWidth: 150,
                formatter: emptyTableCellFormatter
            },
            {
                label: "host",
                prop: "host",
                minWidth: 150,
                formatter: emptyTableCellFormatter
            },
            {
                label: "description",
                prop: "description",
                minWidth: 150,
                formatter: emptyTableCellFormatter
            },
            {
                label: "Created At",
                prop: "createdAt",
                minWidth: 150,
                formatter: dateTableCellFomatter
            },
            {
                label: "actions",
                prop: "actions",
                minWidth: 150,
                customTemplate: true,
            }
        ],
        devicesListData: [],
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        orderListColumns(state) {
            return state.orderListColumns;
        },
        orderListData(state) {
            return state.orderListData;
        },
        totalOrders(state) {
            return state.totalOrders;
        },
        devicesListColumns(state)  {
            return state.devicesListColumns;
        },
        devicesListData(state) {
            return state.devicesListData;
        }
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setOrdersData(state,data) {
            state.orderListData = data;
        },
        updatePagination(state,data) {
            state.pagination = data;
        },
        totalOrders(state,value) {
            state.totalOrders = value;
        },
        setDevicesData(state,data) {
            state.devicesListData = data;
        }
    },
    actions: {
        async fetchOrdersList({state,commit},payload) {
            commit('setLoading',true);
            try {
                let params = {
                    ...state.pagination,
                    ...payload
                };
                const data = await VsaasService.getWorkOrderList(params);
                commit('setOrdersData',data.data);
                if(data.totalRecords) {
                    commit('totalOrders',data.totalRecords);
                }
                commit('setLoading',false);
            } catch(error) {
                commit('setLoading',false);
                console.log(error);
            }
        },
        async createWorkOrder({commit},payload) {
            try {
                await VsaasService.createWorkOrder(payload);
            } catch(error) {
                console.log(error);
            }
        },
        async getWorkOrderData({state,commit},id) {
            commit('setLoading',true);
            try {
                const data = await VsaasService.getWorkOrderData(id);
                commit('setLoading',false);
                return data.data;
            } catch(error) {
                commit('setLoading',false);
                console.log(error);
            }
        },
        async updateWorkOrder({commit},payload) {
            commit('setLoading',true);
            try {
                const data = await VsaasService.updateWorkOrderData(payload.id,payload);
                commit('setLoading',false);
            } catch(error) {
                commit('setLoading',false);
                console.log(error);
            }
        },
        async deleteWorkOrder({commit},id) {
            commit('setLoading',true);
            try {
                const data = await VsaasService.deleteWorkOrder(id);
                commit('setLoading',false);
            } catch(error) {
                commit('setLoading',false);
                console.log(error);
            }
        },
        async fetchDevicesList({commit},id) {
            commit('setLoading',true);
            try {
                let params = {
                    orderId:id,
                };
                const data = await VsaasService.getDevicesList(params);
                commit('setDevicesData',data.data);
                commit('setLoading',false);
            } catch(error) {
                console.log(error);
                commit('setLoading',false);
            }
        },
        async createDevice({commit},payload) {
            await VsaasService.createDevice(payload);
        },
        async getDeviceData({commit},id) {
            try {
                const data = await VsaasService.getDeviceData(id);
                return data.data;
            } catch(error) {
                console.log(error);
            }
        },
        async updateDevice({commit},payload) {
            let id = payload.id;
            await VsaasService.updateDevice(id,payload);
        },
        async deleteDevice({commit},id) {
            await VsaasService.deleteDevice(id);
        }
    }
};  