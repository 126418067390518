<template>
      <!-- 'has-danger': error || $slots.error, -->
  <div
    class="form-group"
    :class="{
      'input-group-focus': focused,
      'has-success': !error && !$slots.error && touched,
      'has-label': label,
      'has-icon': hasIcon,
    }"
  >
    <slot name="label">
      <label v-if="label" class="font-color-4 font-weight-bold h4"> {{ label }} {{ required ? '*' : '' }} </label>
    </slot>
    <div class="mb-0" :class="{'input-group': hasIcon}">
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text">
            <i :class="addonLeftIcon"></i>
          </div>
        </span>
      </slot>
      <slot>
        <autocomplete

          :placeholder="placeholder"
          input-class="form-control"
          :hasErrorClass="error || $slots.error"
          ref="Autocomplete"
          :source="source"
          :results-property="resultsProperty"
          :results-display="resultsDisplay"
          :method="method"
          @selected="onInput"
          @clear="onClear"
          :disableInput="disabled"
        ></autocomplete>
      </slot>
      <slot name="addonRight">
        <span v-if="addonRightIcon" class="input-group-append">
          <div class="input-group-text">
            <i :class="addonRightIcon"></i>
          </div>
        </span>
      </slot>
    </div>

    <slot name="error" v-if="error || $slots.error">
      <label class="error" style="color: #ec250d;">{{ error }}</label>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
// import Autocomplete from "vuejs-auto-complete";
import Autocomplete from "./AutoComplete/Autocomplete"
export default {
  inheritAttrs: false,
  name: "base-auto-complete",
  components: {
    Autocomplete
  },
  props: {

    placeholder: {
      type: String
    },
    source: {
      type: [String, Function, Array, Object],
      required:true
    },
    required: Boolean,
    label: {
      type: String,
      description: "Input label"
    },
    error: {
      type: String,
      description: "Input error",
      default: ""
    },
    value: {
      type: Object,
      description: "Input value"
    },
    addonRightIcon: {
      type: String,
      description: "Input icon on the right"
    },
    addonLeftIcon: {
      type: String,
      description: "Input icon on the left"
    },
    resultsDisplay: {
      default: "name",
      type: String
    },
    resultsProperty: {
      default: "data",
      type: String
    },
    method: {
      default: "get",
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  model: {
    prop: "value",
    event: "input"
  },
  data() {
    return {
      focused: false,
      touched: false,
    };
  },


  watch:{
    value(newVal,oldVal){
      if(newVal && !this.touched){
        this.$refs.Autocomplete.display = newVal.display
      }
    }
  },
  computed: {
    hasIcon() {
      return this.hasLeftAddon || this.hasRightAddon;
    },
    hasLeftAddon() {
      const { addonLeft } = this.$slots;
      return addonLeft !== undefined || this.addonLeftIcon !== undefined;
    },
    hasRightAddon() {
      const { addonRight } = this.$slots;
      return addonRight !== undefined || this.addonRightIcon !== undefined;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    }
  },
  methods: {
    onInput(selecte) {
      if (!this.touched) {
        this.touched = true;
      }
      this.$emit("input", selecte);
    },
    onClear() {
      this.$emit("input", null);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit("focus", evt);
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit("blur", evt);
    },
    setValue(value) {
      this.$refs.Autocomplete.display = value;
    },
    clear(){
      this.$refs.Autocomplete.clear();
    }
  }
};
</script>
<style scoped>
.autocomplete >>> .autocomplete__box {
  border-color: #2b3553;
  border-radius: 0.4285rem;
  font-size: 0.75rem;
  -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  background: transparent;
}
.autocomplete >>> .autocomplete__results li {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.autocomplete >>> .autocomplete__results {
  border-radius: 7px;
  margin: 5px;
  width: 98% !important;
  background: #ecf8f3;
}
.autocomplete >>> .autocomplete__results::-webkit-scrollbar {
  width: 10px;
}
.autocomplete >>> .autocomplete__results::-webkit-scrollbar-track {
  background: #75757b;
  border-radius: 5px;
}
.autocomplete >>> .autocomplete__results::-webkit-scrollbar-thumb {
  background: #1e1e2e;
  border-radius: 5px;
}
.autocomplete >>> .autocomplete__results::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
