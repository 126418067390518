<template>
  <card class="mx-auto">
    <div class="header d-flex justify-content-between align-items-center">
      <h2 class="card-title">{{ $t("Basic Information") }}</h2>
      <span @click="editDialog()">
        <i class="tim-icons icon-pencil float-right edit-icon"></i>
      </span>
    </div>
    <info-item
      v-if="exist(user.first_name)"
      label="First Name :"
      :value="user.first_name"
    />
    <info-item
      v-if="exist(user.last_name)"
      label="Last Name :"
      :value="user.last_name"
    />
    <info-item
      v-if="exist(user.address)"
      label="Address :"
      :value="user.address"
    />
    <info-item v-if="exist(user.email)" label="Email :" :value="user.email" />
    <info-item
      v-if="exist(user.notifications_email)"
      label="Notification Email :"
      :value="user.notifications_email"
    />
    <info-item
      v-if="exist(user.billing_email)"
      label="Billing Email :"
      :value="user.billing_email"
    />
    <info-item v-if="exist(user.phone)" label="Phone :" :value="user.phone" />
    <info-item
      v-if="exist(user.phone2)"
      label="Phone2 :"
      :value="user.phone2"
    />
    <info-item
      v-if="exist(user.created_at)"
      label="Created At :"
      :value="gDateFormat(user.created_at)"
    />
    <info-item
      v-if="exist(user.updated_at)"
      label="Updated At"
      :value="gDateFormat(user.updated_at)"
      last
    />
    <basic-dialog @close="dialogVisible = false" :user="user" :isOpen="dialogVisible"/>
  </card>
</template>

<script>
import InfoItem from "../../../components/InfoItem";
import BasicDialog from './BasicInfoDialog'
export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    InfoItem,
    BasicDialog
  },
  data:() => ({
    dialogVisible: false
  }),
  methods: {
    exist(value) {
      return value && value != "";
    },
    editDialog() {
      this.dialogVisible = true;
    }
  }
};
</script>

<style scoped>
.tim-icons {
  padding: 5px;
  border-radius: 25%;
  cursor: pointer;
}
.edit-icon {
  border: 1px solid var(--primary);
  color: var(--primary);
}
.edit-icon:hover {
  background: var(--primary);
  color: var(--default);
}
</style>
