import MO_Details from "./MO-Main.vue";
import store from "./../../../store/index";
export default {
    path: 'order/:id',
    component: MO_Details,
    redirect: 'order/:id/summary',
    props: true,
    children: [
        {
            path: 'Summary',
            component: () => import('./Summary.vue'),
            props: true,
            name: "Maintenance Order Summary",
            beforeEnter: (to, from, next) => {
                store.commit("getSlot");
                store.commit("toggleRightSide",true);
                next();
            }
        },
        {
            path: 'Tasks',
            component: () => import('./Tasks.vue'),
            props: true,
            name: "Maintenance Order Tasks",
            beforeEnter: (to, from, next) => {
                store.commit("getSlot", null);
                store.commit("toggleRightSide",true);
                next();
            }
        },
        {
            path: 'History',
            component: () => import('./History.vue'),
            props: true,
            name: "Maintenance Order History",
            beforeEnter: (to, from, next) => {
                store.commit("toggleRightSide", false);
                next();
            }
        },
        {
            path: '*',
            redirect: 'Summary'
        }
    ]
}