<template>
  <div>
    <card class="mb-0">
      <p v-if="submitted" class="h6 text-center">
        {{$t('maintenanceOrder.message')}}
        <span>
          {{$t('maintenanceOrder.assignedToCompany')}} [
          <em>{{mo.current_assignment.name}}</em> ]
        </span>
        <br />
        {{$t("maintenanceOrder.pending")}}
      </p>
      <p
        v-if="rejected && !isCreator"
        class="h6 text-center"
      >{{ $t('maintenanceOrder.rejectedAccept') }}</p>
      <p
        v-if="rejected && isCreator"
        class="h6 text-center"
      >{{ $t('maintenanceOrder.rejectedReassign') }}</p>
      <div v-if="!isCreator" class="row justify-content-center">
        <div class="col-md-6 text-center">
          <BaseButton @click="state('accept')" type="success">{{$t('Accept')}}</BaseButton>
        </div>
        <div v-if="!rejected" class="col-md-6 text-center">
          <BaseButton @click="state('reject')" type="danger">{{$t('Reject')}}</BaseButton>
        </div>
      </div>
      <div v-if="isCreator" class="row justify-content-center">
        <div class="col-md-6 text-center">
          <BaseButton
            @click="showModal = true"
            type="info"
            class="text-capitalize"
          >{{$t('maintenanceOrder.reAssign')}}</BaseButton>
        </div>
      </div>
    </card>
    <modal :show.sync="showModal">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{$t('maintenanceOrder.reAssignOrder')}}
            <template v-if="amc">
              {{$t('to')}}
              <b>{{amc.name}}</b>
            </template>
          </h5>
        </template>
        <div class="row">
          <div class="col-12 form-group">
            <label :title="$t('Annual Maintenance Company')" for="amc">{{$t('AMC')}}</label>
            <autocomplete
              id="amc"
              :placeholder="$t('Annual Maintenance Company')"
              :title="$t('Annual Maintenance Company')"
              ref="amcAutoComplete"
              :source="amcSearch"
              results-property="data"
              :results-display="'name'"
              @input="selectAmc"
            ></autocomplete>
          </div>
        </div>
        <template slot="footer">
          <base-button type="secondary" @click="showModal = false">{{$t('close')}}</base-button>
          <base-button type="primary" :disabled="!amc" @click="ReAssign">{{$t('submit')}}</base-button>
        </template>
    </modal>
  </div>
</template>

<script>
// import BaseButton from "./../../../../components/BaseButton";
import { BaseButton } from "src/components";
import Autocomplete from "../../../../components/BaseAutoComplete";

import { mapGetters } from "vuex";
import Axios from "axios";

export default {
  name: "accept",
  data() {
    return {
      showModal: false,
      amc: null
    };
  },
  components: {
    BaseButton,
    Autocomplete
  },
  methods: {
    ReAssign() {
      if (!this.amc) return;
      this.$store.dispatch("moStateAction", {
        newState: "assign",
        company_id: this.amc.id
      });
      this.showModal = false;
    },
    state(action) {
      if (action == "reject") {
      }
      this.$store.dispatch("moStateAction", { newState: action });
    },
    amcSearch(input) {
      return Axios.defaults.baseURL + `maintenance/company/${input}`;
    },
    selectAmc(option) {
      this.amc = null;
      if (option) {
        this.amc = option.selectedObject;
      }
    }
  },
  computed: {
    ...mapGetters({
      submitted: "submitted",
      rejected: "rejected",
      isCreator: "isCreator",
      mo: "selectedMO"
    })
  }
};
</script>

<style scoped>
::v-deep .autocomplete__inputs .form-control {
  color: black;
}
::v-deep .modal-footer {
  padding: 24px 24px 16px 24px;
}
::v-deep .modal-content .modal-header i.tim-icons {
  font-weight: bold;
  font-size: 18px;
}
::v-deep .modal-contant {
  background-color: black;
}

::v-deep .modal-header {
  direction: ltr;
}
</style>
