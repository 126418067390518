<template>
    <div>
        <a class="aspointer has-tooltip m-1" @click="deleteItem()" v-if="isNewOne">
            <i aria-hidden="true" class="fa fa-trash fa-2x text-danger"></i>
        </a>
        <input :placeholder='`${$t("Name")} ${$t("Rule")} `' v-model="item.name" class="my-input" :disabled="disabled || isNameEditable" />
        <small v-if="!disabled && isNameEditable" class="form-text text-muted">{{$t("Basic Rule, The Name Is Not Editable")}}</small>
        <div class="help-block ml-5" v-if="$v.item.name.$error && !$v.item.name.required">{{ $t("requireds.name") }}</div>
        <div class="help-block ml-5" v-if="!unique">{{$t("This Name Was Taken")}}</div>
    </div>
</template>

<script>
    import { required } from "vuelidate/lib/validators";
    import helper from "../../../util/helper";
    const debounce = helper.debounce;

    export default {
        props: ["item", "newItems", "edit", "disabled"],
        data() {
            return {
                unique: true,
                oldName: null
            }
        },
        computed: {
            name() {
                return this.item.name;
            },
            isNewOne() {
                return typeof this.item.id != "number";
            },
            isNameEditable() {
                return this.isRulesMode && !this.isNewOne && this.item.id <= 6;
            }
        },
        watch: {
            name: debounce(function () {
                this.checkName()
            }),
            item(newVal, old) {
                this.oldName = newVal.name
            }
        },
        methods: {
            deleteItem() {
                this.$emit("delete");
            },
            checkName() {
                if (!this.item.name || this.item.name.toLowerCase().trim() == (this.oldName ? this.oldName.toLowerCase().trim() : null)) {
                    this.unique = true
                    return
                }

                if (this.duplicatedName()) {
                    this.unique = false
                    return
                }

                this.$api.get(`permission/rules/${this.item.name}`)
                    .then(res => {
                        this.unique = !res.data.data
                    })
                    .catch(console.error)
            },
            duplicatedName() {
                if (!this.newItems) return false

                let index = this.newItems.indexOf(this.item)

                let foundItem = this.newItems.find((item, i) => i != index && item.name == this.item.name)

                return foundItem
            }
        },
        validations: {
            item: {
                name: {
                    required
                },
            }
        },
        created() {
            this.oldName = this.item.id ? this.item.name.toLowerCase().trim() : null;
        },

    }
</script>

<style scoped>
    .form-text {
        padding: 0 1.4rem;
        position: relative;
        top: -15px;
    }

    .btn-default:disabled,
    .btn[disabled] {
        opacity: 1;
    }

    .btn-default {
        margin: 3px 0 !important;
    }

    .btn {
        max-width: -webkit-fill-available;
    }

    .help-block {
        color: #ff5959 !important;
    }

    td {
        border-top: 3px solid #839bbb !important;
    }

    .my-input {
        background: inherit;
        height: 3rem;
        border-radius: 0.25rem;
        margin: 0 0.5rem;
        color: white;
        padding: 0 1rem;
        outline: none;
        font-size: 1.3rem;
        border: 0;
        border-bottom: 1px solid transparent;
        width: 80%;
        text-transform: capitalize;
    }

    .group-name-col {
        width: 40%;
    }

    .invalid {
        background-color: #f800003b !important;
    }

    .no-per:hover {
        text-decoration: underline !important;
        color: #d5e5f9 !important;
        cursor: pointer;
    }

    .my-badge {
        padding: 0.25em 0.4em;
        font-weight: 500;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
        background-color: #343a40;
    }
</style>