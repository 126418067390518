import api from "./../../axiosWithDelimiterFile";
import { dateFormat } from "./../../util/helper";
export default {
    state: {
        openedTicket: null,
        ticketingPermissions: null,
        openedRoom: null
    },
    getters: {
        getTicketingPermissions(state) {
            return state.ticketingPermissions;
        },
        openedTicket(state) {
            return state.openedTicket;
        },
        openedRoom(state) {
            return state.openedRoom;
        }
    },
    mutations: {
        openTicket(state, ticket) {
            state.openedTicket = ticket;
        },
        openedRoom(state, receiver) {
            state.openedRoom = receiver;
        },
        setTicketingPermissions(state, p) {
            state.ticketingPermissions = p
        },
        logout(state) {
            state.openedTicket = null;
            state.ticketingPermissions = null;
        }
    },
    actions: {
        loadTicketingPermissions() {
            api.get('messages').then(res => {
                //this.ticketingPermissions = res.data.permissions
                this.commit('setTicketingPermissions', res.data.permissions)
            }).catch(err => {
                console.log(err);
            })
        },
        getTicketsList({ state,rootState }, { limit, offset }) {
            // this.commit('blockSpinner',true);
            return api.get(
                `messages/type/ticket/me?limit=${limit}&offset=${offset}&receiversNumber=5`
            )
                .then(res => {
                    res.data.data.forEach(ticket => {
                        ticket.created_at = dateFormat(ticket.created_at);

                        // TODO: check it again
                        if (ticket.last_action_at) {
                            ticket.last_action_at = dateFormat(ticket.last_action_at);
                        } else if (ticket.updated_at) {
                            ticket.last_action_at = dateFormat(ticket.updated_at);
                        }

                        WhoAmI(ticket,rootState.user);

                        isSingleReceiver(ticket);

                        forWhom(ticket);

                        fromWhom(ticket);

                        getStatus(ticket);

                    });
                    return res.data;
                })
                .catch(console.error);
        },

    }
}

function WhoAmI(ticket,user) {
    if (typeof ticket.receiversCount === 'number') {
        ticket.iAmSender = true;
        if(ticket.user_id == user.id){
            ticket.iAmOwner = true;
        }
    } else {
        ticket.iAmReceiver = true
    }
}

function isSingleReceiver(ticket) {
    if (ticket.iAmSender) {
        if (ticket.receivers.length == 1) {
            ticket.isOneReceiver = true;
            ticket.room_id = ticket.receivers[0].roomId;
        } else {
            ticket.multipleReceiver = true;
        }
    }
}

function isAdminReceiver(receiver) {
    if (!receiver) {
        console.error('ticketing store, unexpected Error!!')
        return;
    }
    if (!receiver.company_type && receiver.receiver_role_id) {
        return true;
    }
    return false;
}

function forWhom(ticket) {
    if (ticket.iAmReceiver) {
        // TODO: do something
        ticket.to = {
            name: 'Me'
        }
    } else if (ticket.iAmSender) {
        if (ticket.isOneReceiver) {

            ticket.to = extractReceiver(ticket.receivers[0])

            ticket.receivers[0].to = ticket.to;
        } else {
            ticket.to = 'Multiple Receiver'
        }
    }
}

function fromWhom(ticket) {
    let from = {

    };
    if (!ticket.company_type && ticket.user_role_id) {
        from = {
            name: toCamleCase(ticket.role_name),
            type: ticket.role_name,
        }
    } else {
        from = {
            name: toCamleCase(ticket.company_name),
            type: ticket.company_type,
            company:true
        };
    }
    
    from.id = ticket.user_id
    from.email = ticket.fromWho;

    ticket.from=from;

}

export function extractReceiver(receiver) {
    let to;
    if (isAdminReceiver(receiver)) {
        to = {
            name: toCamleCase(receiver.role_name),
            type: receiver.role_name,
            id: receiver.receiver_user_id,
            email: receiver.user_email,
        }
    } else {
        to = {
            name: toCamleCase(receiver.company_name),
            type: receiver.company_type,
            id: receiver.receiver_company_id,
            email: receiver.user_email,
            company:true
        };
    }
    return to;
}
function getStatus(ticket) {
    if (ticket.isOneReceiver)
        if (ticket.iAmSender) {
            ticket.status = ticket.owner_status;
        } else if (ticket.iAmReceiver) {
            ticket.status = ticket.receiver_status;
        }
}
/**
 * 
 * @param {String} string 
 */
function toCamleCase(string) {
    if(!string)return 'N/A'
    return string.replace(/\_/g, ' ')
}