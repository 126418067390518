import Devices from "../pages/devices/store"
import Device from "../pages/device/store"
import Report from "../pages/ForkliftReport/store"
import Portfolio from '../pages/portfolio/store';
export default {
    namespaced: true,

    modules: {
        Devices,
        Device,
        Report,
        Portfolio,
    },

    state: {},

    getters: {},

    mutations: {},

    actions: {}
}