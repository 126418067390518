import Dashboard from "../pages/dashboard/store"

import IntrusionService from "../services/intrusion.service";

export default {
    namespaced: true,

    modules: {
        Dashboard
    },

    state: {
        generalInfo: {
            alarms: [],
            areas: [],
            events: [],
            policeStations: [],
            villas: [],
            userType: undefined
        },
    },

    getters: {
        getVillas(state) {
            return state.generalInfo.villas;
        },
        getAreas(state) {
            return state.generalInfo.areas;
        },
        getAlarmsStatus(state) {
            return state.generalInfo.alarms;
        },
        getPoliceStations(state) {
            return state.generalInfo.policeStations;
        },
        getCurrentUserType(state, rootState,getters, rootGetters){
            return state.generalInfo.userType != undefined? state.generalInfo.userType : (rootGetters.isPoliceUser? 'police' : 'moro');
        },

        getVillaOptions(state) {
            const data =  state.generalInfo.villas.map(v => ({ label: v.name, value: v.id }));
            data.unshift({ label: "all villas" });
            return data;
        },
        getAreaOptions(state) {
            const data =  state.generalInfo.areas.map(a => ({ label: a.name, value: a.id }));
            data.unshift({ label: "all areas" });
            return data;
        },
        getAlarmStatusOptions(state) {
            const data =  state.generalInfo.alarms.map(a => ({ label: a, value: a }));
            data.unshift({ label: "all alarms statuses" });
            return data;
        },
        getPoliceStationOptions(state) {
            const data =  state.generalInfo.policeStations.map(ps => ({ label: ps.name, value: ps.id }));
            data.unshift({ label: "all stations" });
            return data;
        },
        esclationOptions(state, getters, rootState, rootGetters) {
            const data = [
                { label: "All" },
                { label: "Yes", value: true },
                { label: "No", value: false },
            ];
            if(rootGetters.isPoliceUser){
                data.pop();
            }
            return data;
        },
        alarmsReasonsOptions() {
            return [
                { label: "all" },
                { label: "false alarm", value: "false alarm" },
                { label: "verified intrusion", value: "verified intrusion" },
                { label: "customer did not respond", value: "customer did not respond" },
                { label: "other", value: "other" }
            ]
        },
        alarmsTypesOptions(state, getters, rootState, rootGetters) {
            if (rootGetters.isPoliceUser) {
                return [{
                    label: "intrusion",
                    value: "intrusion"
                }];
            }
            return [
                { label: "all" },
                { label: "intrusion", value: "intrusion" },
                { label: "operational", value: "operational" },
            ];
        }
    },

    mutations: {
        setGeneralInfo(state, data) {
            state.generalInfo = data;
        },
    },

    actions: {
        getGeneralInfo({ commit }) { //TODO: add caching here
            return IntrusionService.getGeneralInfo()
                .then(data => {
                    commit("setGeneralInfo", data);
                    return data;
                });
        }
    }
}