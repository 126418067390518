

import api from 'src/axiosWithDelimiterFile.js';



export default {
  namespaced: true,
  state: {
    //alive: false,
    //cameraAlive: false,
    rounds:0,
    selected_nvr:1,
    nvrAvailable: false,
    cameraAvailable: false,
    DPPoCXVR: false,
    TestCam1: false,
    TestCam2: false,
    TestCam3: false,
    TestCam4: false,
    nvrData:{
      1:{
        customer_id:'CU-1234',
        nvr_name:'DPPoCXVR',
        camera_num:4,
        location: { lat: 24.987141, lng: 55.415311 },
        cameras:[
          'TestCam1',
          'TestCam2',
          'TestCam3',
          'TestCam4',
         
        ]
      },
      2:{
        customer_id:'CU-11447',
        nvr_name:'nvrAvailable',
        camera_num:1,
        location:{ lat: 49.206148, lng: -122.5633105 },
        cameras:[
          'cameraAvailable',
        ]
      }
    },
    alarms: {
      total: 0,
      data:[],
      limit: 10,
      offset: 0,
      filter:{},
      loading:false
    }


  },

  mutations: {
    startAlarmsLoading(state){
      state.alarms.loading = true;
    },
    finishAlarmsLoading(state){
      state.alarms.loading = false;
    },
    setAlarms(state,data){
      state.alarms.total = data.total_records;
      state.alarms.data = data.data;
    },
    updateAlarmsPagination(state,data){
      state.alarms.limit = data.limit
      state.alarms.offset = data.offset
    },
    updateAlarmsFilter(state,dates){
      state.alarms.offset = 0;
      state.alarms.filter = {
        start: new Date(dates[0]),
        end: new Date(dates[1]),
      }
    },
    addNewAlarms(state,data){
      data.forEach(d=> d.new = true)
      setTimeout(()=>{
        data.forEach(d=>delete d.new)
        state.alarms.data = [...state.alarms.data];
      },5000);
      state.alarms.data = state.alarms.data.splice(0,state.alarms.limit - data.length)
      state.alarms.data.unshift(...data.reverse())
      state.alarms.total+=data.length;
    }
  },
  actions: {

    setNVR({state},nvr){
      state.selected_nvr = nvr;
    },

    checkConnection({ state }) {

      api.get(`nvr`).then(res => {
        if (res.status === 200) {
          let data = res.data;

          state.nvrAvailable = data.nvrAvailable;
          state.cameraAvailable = data.cameraAvailable;
          state.DPPoCXVR = data.DPPoCXVR;
          state.TestCam1 = data.TestCam1;
          state.TestCam2 = data.TestCam2;
          state.TestCam3 = data.TestCam3;
          state.TestCam4 = data.TestCam4;
          state.rounds += 1;

        }
      }).catch(err => { console.log(err) })
    },
    fetchAlarms({state,commit}){
      commit("startAlarmsLoading")
      return api.get(`nvr/alarms`,{
        params:{
          limit: state.alarms.limit,
          offset: state.alarms.offset,
          ...state.alarms.filter
        }
      }).then(_=>{
        commit("setAlarms",_.data);
      }).finally(_=>{
        commit("finishAlarmsLoading")
      });
    },
    updateAlarmsPagination({state,commit,dispatch},data){
      commit("updateAlarmsPagination",data);
      dispatch("fetchAlarms");
    },
    changeAlarmsDatesFilter({state,commit,dispatch},dates){
      commit("updateAlarmsFilter",dates);
      dispatch("fetchAlarms");
    },
    connectToAlarmsSocket({commit}){
      let token = localStorage.getItem('token'); 
     console.log(this._vm);
      if(token){
          this._vm.$NvrSocket.open(token);
          this._vm.$NvrSocket.subscribe("emails",function(data){
            console.log(data);
            commit("addNewAlarms",data)
          })
      }
    },
    disconnectFromAlarmsSocket({}){
      this._vm.$NvrSocket.unSubscribeAll();
    },



  },
  getters:{
    nvr_info:state=>{
        let rounds = state.rounds;
        let selected_nvr = state.selected_nvr;
        let nvrData = state.nvrData[selected_nvr];
        let name = nvrData.nvr_name;
        let customer_id = nvrData.customer_id;
        let cameras = nvrData.cameras;
        let cameras_num = cameras.length;
        let nvr_connected = state[name];
        let cameras_connected = 0;
        let cameras_disconnected = 0;
        
        for(var i=0;i<cameras.length;i++){
          let cam = cameras[i];
         
          if(state[cam]){
            cameras_connected += 1;
          }else{
            cameras_disconnected += 1;
          }
        }

        console.log(cameras_connected);
        console.log(cameras_disconnected);

        let location = nvrData.location;

        return {
          customer_id,
          nvr_connected,
          cameras_connected,
          cameras_disconnected,
          cameras_num,
          name,
          rounds,
          location
        }

        
        
    },
    getAlarms(state){
      return state.alarms;
    }
  }
}
