import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import { M } from '../../Permissions';



const Tracking = () =>
    import('./Dashboard/Tracking.vue');
const CarTracker = ()=> 
    import('./CarTracker/CarTracker.vue');




export default {
        path: '/tracking',
        component: DashboardLayout,
        redirect: '/tracking/dashborad',
        name: 'Tracking',
        children: [
            {
                path: 'dashborad',
                name: 'Vehicle Tracking Dashboard',
                component: Tracking
            },
            {
                path: 'car-tracker/:id',
                name: 'Vehicle Tracking Page',
                component: CarTracker,
                props:true
            },






        ]
    }



