

class Contact {
    constructor(anObject) {
        this.id = anObject['id'];
        this.email = anObject['email'];
        this.phone = anObject['phone'];
        this.firstName = anObject['first_name'];
        this.lastName = anObject['last_name'];
        this.fullName = `${this.firstName} ${this.lastName}`;

    }
}


export const mapArrayOfObjectsToContacts = (data) => data.map(obj => new Contact(obj));