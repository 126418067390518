<template>
  <component
    :is="'li'"
    :to="link.path ? link.path : '/'"
    :class="{ active: isActive}"
    class="application-menu p-10 nav-li-item"
    tag="li"
  >
    <a
      class="sidebar-menu-item font-color-5 application-menu-item"
      :aria-expanded="!collapsed"
      data-toggle="collapse"
      @click.prevent="collapseMenu"
    >
      <template v-if="addLink">
        <span class="sidebar-mini-icon font-color-5">{{ linkPrefix }}</span>
        <span class="sidebar-normal font-color-5 font-weight-500">
          {{ link.name }}
          <b class="caret application-cart font-color-5"></b>
        </span>
      </template>
      <template v-else>
        <i class="font-color-5" :class="link.icon"></i>
        <p class="font-color-5 font-weight-500">
          {{ link.name }}
          <b class="caret"></b>
        </p>
      </template>
      <div class="divider mt-3" :class="{dividerActive : !collapsed}"></div>
    </a>
    <collapse-transition>
      <div v-if="$slots.default || this.isMenu" v-show="!collapsed" class="collapse show">
        <ul class="nav nav-child list-unstyled">
          <slot></slot>
        </ul>
      </div>
    </collapse-transition>
  </component>
</template>

<script>
  import {CollapseTransition} from "vue2-transitions";

  export default {
    name: "SidebarApplicationItem",
    components: {
      CollapseTransition,
    },
    props: {
      menu: {
        type: Boolean,
        default: false,
        description:
          "Whether the item is a menu. Most of the item it's not used and should be used only if you want to override the default behavior.",
      },
      link: {
        type: Object,
        default: () => {
          return {
            name: "",
            path: "",
            children: [],
          };
        },
        description:
          "Sidebar link. Can contain name, path, icon and other attributes. See examples for more info",
      },
    },
    provide() {
      return {
        addLink: this.addChild,
        removeLink: this.removeChild,
      };
    },
    inject: {
      addLink: {default: null},
      removeLink: {default: null},
      autoClose: {
        default: true,
      },
    },
    data() {
      return {
        children: [],
        collapsed: true,
      };
    },
    computed: {
      linkPrefix() {
        if (this.link.name) {
          let words = this.link.name.split(" ");
          return words.map((word) => word.substring(0, 1)).join("");
        }
      },
      isActive() {
        if (this.$route && this.$route.path) {
          let matchingRoute = this.children.find((c) =>
            this.$route.path.startsWith(c.link.path)
          );
          if (matchingRoute !== undefined) {
            return true;
          }
        }
        return false;
      },
    },
    methods: {
      getSvg(img) {
        return require(`../../assets/Icons/${img}`);
      },
      addChild(item) {
        const index = this.$slots.default.indexOf(item.$vnode);
        this.children.splice(index, 0, item);
      },
      removeChild(item) {
        const tabs = this.children;
        const index = tabs.indexOf(item);
        tabs.splice(index, 1);
      },
      elementType(link, isParent = true) {
        if (link.isRoute === false) {
          return isParent ? "li" : "a";
        } else {
          return "router-link";
        }
      },
      linkAbbreviation(name) {
        const matches = name.match(/\b(\w)/g);
        return matches.join("");
      },
      linkClick() {
        if (
          this.autoClose &&
          this.$sidebar &&
          this.$sidebar.showSidebar === true
        ) {
          this.$sidebar.displaySidebar(false);
        }
      },
      collapseMenu() {
        this.collapsed = !this.collapsed;
        if (this.link.path && !this.collapsed) {
          this.$router.push(this.link.path).catch((_) => {
          });
        }
      },
      collapseSubMenu(link) {
        link.collapsed = !link.collapsed;
      },
    },
    mounted() {
      if (this.addLink) {
        this.addLink(this);
      }
      if (this.link.collapsed !== undefined) {
        this.collapsed = this.link.collapsed;
      }
      if (this.isActive && this.isMenu) {
        this.collapsed = false;
      }
    },
    destroyed() {
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
      if (this.removeLink) {
        this.removeLink(this);
      }
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-menu-img {
    float: left;
    margin-right: 19px;
    color: rgba(255, 255, 255, 0.8);
    position: relative;
    margin-left: 4px;
  }

  .sidebar-menu-img path {
    fill: rgba(255, 255, 255, 0.8);
  }

  .sidebar-menu-item {
    cursor: pointer;
  }
  .application-menu {
    box-shadow: 0 0 2px 0 $dark-gray;
    border-radius: 4px;
    width: 98%;
    margin: 0 1%;
  }
  .sidebar  .application-menu-item  i {
    margin-left: -2px !important;
  }
  .divider {
    display: none;
  }

  .dividerActive {
    display: block;
    width: 90%;
    margin: 0 5%;
    height: 2px;
    background-color: $dark-gray;
  }

  .nav-child li a {
    margin: 0 !important;
    word-break: break-word !important;
    overflow-wrap: break-word !important;
  }
  .application-cart{
    right: 7px !important;
  }
</style>
