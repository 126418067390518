<template>
  <div>
    <card>
      <div class="row">
        <div class="col-10 row">
          <base-input
            :t="'fddf'"
            class="col-3"
            :label="$t('report.name')"
            v-model="name"
            :placeholder="$t('instructions.enterReportName')"
          >
            <template v-if="$v.name.$error" v-slot:error>
              <label class="error position-absolute">{{$t('report.name')}} {{$t('validations.isRequired')}}</label>
            </template>
          </base-input>
          <base-select
            class="col-3"
            :label="$t('report.schedule')"
            v-model="schedule"
            :options="scheduleOptions"
          ></base-select>
          <div class="col-6 row">
            <div class="col-6">
              <base-input :label="$t('report.nextDueDate')">
                <el-date-picker
                  :disabled="!activeReport"
                  v-model="dueDate"
                  type="date"
                  :placeholder="$t('instructions.pickDay')"
                  :picker-options="dueDateOptions"
                ></el-date-picker>
                <template v-if="$v.dueDate.$error" v-slot:error>
                  <label
                    class="error position-absolute"
                  >{{$t('report.nextDueDate')}} {{$t('validations.isRequired')}}</label>
                </template>
              </base-input>
            </div>
            <div class="col-6 pt-4">
              <checkbox v-model="activeReport">{{$t('report.activeReport')}}</checkbox>
            </div>
          </div>
        </div>
        <div class="col-2">
          <el-tooltip :content="$t('report.download')" effect="light" :open-delay="300" placement="top">
            <div :class="{'float-right': !isRTL , 'float-left': isRTL}" @click="exportToPDF">
              <inline-svg :src="require('@/assets/Icons/pdf-colord.svg')" class="pdf-icon"></inline-svg>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-7 d-flex justify-content-between">
          <base-button @click="save" type="primary" size="sm">{{$t('save')}}</base-button>
          <span class="total-widgets">
            {{$t('totalWidgets')}}
            <span
              class="widgets-number"
              :class="{'widgets-number-error':$v.widgets.$error}"
            >{{widgets.length}}</span>
            <span
              class="widgets-number-error-msg"
              v-if="$v.widgets.$error"
            >{{$t('validations.atLeastOneWidget')}}</span>
          </span>
        </div>
        <div class="col-5 text-end">
          <el-tooltip :content="$t('instructions.addNewWidget')" effect="light" :open-delay="300" placement="top">
            <base-button round icon type="primary" @click="newWidgetDialog = true">
              <i class="tim-icons icon-simple-add"></i>
            </base-button>
          </el-tooltip>
        </div>
      </div>
    </card>
    <div class="d-flex">
      <div class="pr-3" style="flex: 1">
        <widget-wrapper
          v-for="(widget, index) in widgets"
          :key="widget.key"
          :widget="widget"
          :period="schedule"
          :ref="widget.key"
          :activeWidgetKey="activeWidgetKey"
          @valid="(config) => handleValid(config, index)"
          @create="indexToInsert = index; newWidgetDialog=true;"
          @up="moveWidgetUp(index)"
          @delete="deleteWidget(index)"
          @down="moveWidgetDown(index)"
        ></widget-wrapper>
      </div>
      <div class="d-flex justify-content-end" v-if="widgets.length">
        <div class="preview-section pl-2" style="width: 250px;max-width: 100%">
          <card>
            <div>
              <div class="background-4 p-1 c-title text-center">
                <i class="fa fa-eye font-color-1 mr-2"></i>
                <span class="font-color-1 font-weight-bold">Report Preview</span>
              </div>
              <div class="d-flex justify-content-center">
                <preview :widgets="widgets" @hover="previewHover" @blur="previewBlur"/>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
     <el-dialog :title="$t('widgets.widgetsCatalog')" top="10vh" style="overflow-y: hidden;" width="70%" :visible.sync="newWidgetDialog" :modal="false">
      <new-widget-modal  @submit="addNewWidget"></new-widget-modal>
    </el-dialog>
  </div>
</template>

<script>
import { required, requiredIf, minLength } from "vuelidate/lib/validators";
import { Dialog } from "element-ui";
import NewWidgetModal from "./components/NewWidgetModal";
import WidgetWrapper from "./components/WidgetWrapper";
import { mapGetters } from "vuex";
import checkbox from "./../../components/Inputs/BaseCheckbox";
import Preview from "./components/Preview"

export default {
  name: "report-editor",
  props: {
    startWith: {}
  },
  data() {
    return {
      scheduleOptions: scheduleOptions,
      schedule: null,
      name: null,
      widgets: [],
      newWidgetDialog: false,
      indexToInsert: null,
      activeReport: true,
      dueDateOptions: {
        disabledDate(time) {
          return time.getTime() < new Date().getTime() - 24 * 3600 * 1000;
        }
      },
      dueDate: new Date(),
      activeWidgetKey: ''
    };
  },
  computed: {
    ...mapGetters({
      allWidgets: "ReportsDesignerStore/widgets",
    }),
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  created() {
    if (this.startWith && this.startWith.nextDueDate) {
      this.dueDate = startWith.nextDueDate;
    }
    this.schedule = this.scheduleOptions[1].value;
    if (this.startWith) {
      this.name = this.startWith.name;
      this.schedule = this.startWith.every;
      this.activeReport = this.startWith.is_active;
      this.widgets = this.startWith.configurations.map(c => {
        return {
          widget_id: c.widget_id,
          key: "W" + Math.random(),
          id: this.allWidgets.find(w => w.data.widget_id == c.widget_id).data
            .id,
          data: c
        };
      });
    }
  },
  methods: {
    addNewWidget(data) {
      this.newWidgetDialog = false;
      if (this.indexToInsert != null) {
        this.indexToInsert++;
        console.log("i am here!!");
        console.log(this.indexToInsert);
        this.widgets.splice(
          this.indexToInsert,
          0,
          {
            ...data,
            key: "W" + Math.random()
          },
          ...this.widgets.splice(
            this.indexToInsert,
            this.widgets.length - this.indexToInsert
          )
        );
        // this.widgets = [
        //   ...this.widgets.splice(0, this.indexToInsert),
        //   {
        //     ...data,
        //     key: "W" + Math.random(),
        //   },
        //   ...this.widgets.splice(
        //     this.indexToInsert,
        //     this.widgets.length - this.indexToInsert
        //   ),
        // ];
        this.indexToInsert = null;
      } else {
        this.widgets.unshift({ ...data, key: "W" + Math.random() });
      }
    },
    exportToPDF() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = [];

        let allValid = this.widgets.every((widget) =>
          this.$refs[widget.key][0].isValid()
        );

        if (!allValid) {
          this.$notify({
            title: "Missing Required Config",
            message: "One or more of widgets are missing required config",
            type: "danger",
          });
          return;
        }
        this.widgets.forEach((widget) => {
          data.push({
            widget_id: widget.widget_id,
            ...this.$refs[widget.key][0].extractConfig(),
          });
        });
        this.$store.dispatch("ReportsDesignerStore/exportCurrentWorkspace", {
          name: this.name,
          every: this.schedule,
          configurations: data,
        });
        // const configs = {
        //   name: this.name,
        //   every: this.schedule,
        //   configurations: data,
        // };

        // this.$api
        //   .post(`iot/report/pdf`, configs, {
        //     responseType: "blob",
        //   })
        //   .then(({ data }) => {
        //     const url = window.URL.createObjectURL(new Blob([data]));
        //     const a = document.createElement("a");
        //     a.style.display = "none";
        //     a.href = url;
        //     a.download = "test.pdf";
        //     document.body.appendChild(a);
        //     a.click();
        //     window.URL.revokeObjectURL(url);
        //   })
        //   .catch(console.error);
      }
    },
    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = [];

        let allValid = this.widgets.every((widget) =>
          this.$refs[widget.key][0].isValid()
        );

        if (!allValid) {
          this.$notify({
            title: "Missing Required Config",
            message: "One or more of widgets are missing required config",
            type: "danger",
          });
          return;
        }
        this.widgets.forEach((widget) => {
          data.push({
            widget_id: widget.widget_id,
            ...this.$refs[widget.key][0].extractConfig(),
          });
        });
        this.$store.commit("setSpinner", true);
        this.$api[this.startWith ? "patch" : "post"](
          `iot/report-definition${
            this.startWith ? "/" + this.startWith.id : ""
          }`,
          {
            name: this.name,
            every: this.schedule,
            configurations: data,
            is_active: this.activeReport,
            next_due_date: this.activeReport ? new Date(this.dueDate) : null,
          }
        ).then((_) => {
          this.$router.push("./list");
        });
      }
    },
    handleValid(config, index) {
      let widget = this.widgets[index]
      widget.data = config

      this.$set(this.widgets, index, widget)
    },
    deleteWidget(index) {
      this.widgets.splice(index, 1);
    },
    moveWidgetUp(index) {
      if (index != 0) {
        this.widgets.splice(
          index - 1,
          2,
          this.widgets[index],
          this.widgets[index - 1]
        );
      }
    },
    moveWidgetDown(index) {
      if (index + 1 != this.widgets.length) {
        this.widgets.splice(
          index,
          2,
          this.widgets[index + 1],
          this.widgets[index]
        );
      }
    },
    previewHover(widget) {
      if (widget.key)
        this.activeWidgetKey = widget.key
    },
    previewBlur() {
      this.activeWidgetKey = ''
    }
  },
  validations: {
    name: {
      required,
    },
    widgets: {
      required,
      minLength: minLength(1),
    },
    dueDate: {
      required: requiredIf(function () {
        return this.activeReport;
      }),
    },
  },
  components: {
    [Dialog.name]: Dialog,
    NewWidgetModal,
    WidgetWrapper,
    checkbox,
    Preview
  },
};
const scheduleOptions = [
  {
    label: "report.weekly",
    value: "week",
  },
  {
    label: "report.monthly",
    value: "month",
  },
  {
    label: "report.quarterly",
    value: "quarter",
  },
  {
    label: "report.yearly",
    value: "year",
  },
];
</script>

<style lang="scss" scoped>

  .preview-section {
    position: sticky;
    top: 80px;
    align-self: flex-start;
  }
.pdf-icon {
  fill: $primary;
  width: 45px;
  height: auto;
  cursor: pointer;
}
.pdf-icon:hover {
  fill: $primary-states;
}
span.total-widgets {
  font-size: 1.2rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: white;
  text-align: end;
  margin: 0;
  margin-top: 10px;
  span.widgets-number {
    border: 1px whitesmoke solid;
    padding: 4px 10px;
    margin: 0 10px;
    border-radius: 5px;
  }
  span.widgets-number-error {
    border-color: #e02735;
    color: #e02735;
  }
  span.widgets-number-error-msg {
    color: #e02735;
    font-size: 0.9rem;
    position: absolute;
    width: fit-content;
    margin-top: 4px;
  }
}
::v-deep .form-check .form-check-label {
  text-align: center;
}

.text-end {
  text-align: end;
}

  .c-title {
    margin: -15px -15px 0 -15px;
  }
</style>

