import api from "src/axiosWithDelimiterFile";
import qs from 'qs'
export default class ForkliftService {

  static async getVehicles() {
    const response = await api.get('/teltonika/vehicles')
    return response;
  }
  static async getVehicleInfo(deviceId, params) {
    const response = await api.get(`/teltonika/vehicles/device/${deviceId}`)
    return response;
  }
  static async getSpeedInfo(forkliftId, params) {
    const response = await api.get(`/teltonika/forklift/${forkliftId}/speed${params}`)
    return response;
  }
  static async getAlarms(forkliftId, params) {
    const response = await api.get(`/teltonika/forklift/${forkliftId}/alarms${params}`)
    return response;
  }
  static async getTimeline(forkliftId, params) {
    const response = await api.get(`/teltonika/forklift/${forkliftId}/timeline${params}`)
    return response;
  }
  static async getGeofence(forkliftId) {
    const response = await api.get(`/teltonika/forklift/${forkliftId}/geofence`)
    return response;
  }
  static async goInRepair(forkliftId, body) {
    const response = await api.patch(`/teltonika/forklift/${forkliftId}/repair`, body)
    return response;
  }
  // ----------------------------------------- //
  static async getForklifts() {
    const response = await api.get('/teltonika/forklift')
    return response;
  }
  static async getForkliftInfo(forkliftId, params) {
    const response = await api.get(`/teltonika/forklift/${forkliftId}`)
    return response;
  }
  static async getDashboardInfo(deviceId, params) {
    const response = await api.get(`/teltonika/forklift/${deviceId}/dashboard`)
    return response;
  }
  // --------------------------------------- //
  static async getDrivers(forkliftId) {
    const response = await api.get(`/teltonika/forklift/${forkliftId}/drivers`)
    return response;
  }
  // --------------------------------------- //
  static async getForkliftsData(payload) {
    const response = await api.get(`/teltonika/forklift/reparation?limit=${payload.limit}&offset=${payload.offset}`)
    return response;
  }
  // --------------------------------------- //
  static async getForkliftReport(forkID) {
    const response = await api.get(`/teltonika/forklift/reparation/${forkID}`)
    return response;
  }
  static async getAutoshops() {
    const response = await api.get(`/teltonika/forklift/reparation/autoshops`)
    return response;
  }
  static async updateReport(forkID, data) {
    const response = await api.patch(`/teltonika/forklift/reparation/${forkID}`, data)
    return response;
  }
  // Portfolio
  static async getCities() {
    const response = await api.get(`/teltonika/forklift/portfolio/cities`)
    return response
  }
  static async getMap(data) {
    const response = await api.get(`/teltonika/forklift/portfolio/map`, {
      params: {
        city_ids: data
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
    return response
  }
  // static async getMap() {
  //   const response = await api.get(`/teltonika/forklift/portfolio/map`)
  //   return response
  // }
  static async getPortfolioVehicleInfo() {
    const response = await api.get(`/teltonika/forklift/portfolio/basic-info`)
    return response
  }
  static async getPortfolioAlarms(payload) {
    console.log(payload)
    const response = await api.get(`/teltonika/forklift/portfolio/alarms?type=${payload.type}&?limit=${payload.limit}&offset=${payload.offset}`)
    return response
  }
  static async getDriverPerformance(date) {
    const response = await api.get(`/teltonika/forklift/portfolio/driver-performance?start=${date.start}&end=${date.end}`)
    return response
  }
  static async getAttentionAlarms(payload) {
    const response = await api.get(`/teltonika/forklift/portfolio/expiry-alarms?type=${payload.type}&?limit=${payload.limit}&offset=${payload.offset}`)
    return response
  }
  static async getVehicleList() {
    const response = await api.get(`/teltonika/forklift/portfolio/vehicle-list`)
    return response
  }
  static async getStatusAlarms() {
    const response = await api.get(`/teltonika/forklift/portfolio/status-alarms`)
    return response
  }
  static async getInventoryData(payload) {
    const response = await api.get(`/teltonika/forklift/portfolio/repair-inventory?limit=${payload.limit}&offset=${payload.offset}`)
    return response
  }
  static async getRepairHistoryData(payload) {
    const response = await api.get(`/teltonika/forklift/portfolio/${payload.id}/repair-history?limit=${payload.limit}&offset=${payload.offset}`)
    return response
  }
  static async getChartForkliftComparison(data) {
    const response = await api.get(`https://iot5-back.disrupt-x.io/teltonika/forklift/portfolio/forklift-speed-comparison?start=${data.start}&end=${data.end}&forklift1=${data.forklift1}&forklift2=${data.forklift2}`)
    return response;
  }
  static async getChartIntervalComparison(data) {
    const response = await api.get(`https://iot5-back.disrupt-x.io/teltonika/forklift/portfolio/interval-speed-comparison?start1=${data.start1}&end1=${data.end1}&start2=${data.start2}&end2=${data.end2}&forklift_id=${data.forklift_id}`)
    return response;
  }

  // *************************************************
  // Scooter Page
  
}
