<template>
  <div :ref="_uid" style="height: 250px; background: transparent;" v-loading="loading">
    <slot></slot>
  </div>
</template>
<script>
import { load } from './utils/manager'
const minValueValidator = function(value) {
  return value >= 0;
};

let controlsOut = {
  mapTypeControl:false,
  zoomControl:false,
  panControl:false,
  streetViewControl:false,
  fullscreenControl: false
}

let controlsIn = {}

for(let c in controlsOut) {
  controlsIn[c] = true
}

export default {
  name: "base-map",
  props: {
    zoom: { type: Number, default: 4, validator: minValueValidator },
    minZoom: { type: Number, default: null, validator: minValueValidator },
    maxZoom: { type: Number, default: null, validator: minValueValidator },
    options: { type: Object, default: () => ({}) },
    center: {
      type: Object,
      default: () => ({ lat: 0, lng: 0 })
    }
  },
  data() {
    return {
      map: null,
      loading: true
    };
  },
  beforeCreate(){
    },
  mounted() {
    load({
      key: 'AIzaSyBpRIs7w1fBQn_ISEVJZEpuncss0XIYxw0',
      libraries: [ 'visualization', 'places' ]
    }).then(()=>{
      this.map = new window.google.maps.Map(this.$refs[this._uid], {
        center: this.center,
        zoom: this.zoom,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,
        options: this.options
      });
  
      this.configControls(this.map)
      this.mapEvents(this.map)
    }).finally(()=>{
      this.loading = false;
    })

  },
  methods: {
    getMap(callback) {
      // this method to be accessed by child components
      let vm = this;
      function checkForMap() {
        if (vm.map) callback(vm.map);
        else setTimeout(checkForMap, 200);
      }
      checkForMap();
    },
    configControls(map) {
      map.setOptions(controlsOut)
    },
    mapEvents(map) {
      window.google.maps.event.addDomListener(map.getDiv(), 'mouseenter', (e) => {
        e.cancelBubble = true
        if(!map.hover) {
          map.hover = true
          map.setOptions(controlsIn)
        }
      })

      window.google.maps.event.addDomListener(map.getDiv(), 'mouseleave', (e) => {
        if(map.hover) {
          map.setOptions(controlsOut)
          map.hover = false
        }
      })
    }
  },
  watch: {
    center: {
      handler() {
        this.map.panTo(this.center)
      },
      deep: true
    },
    options: {
      handler() {
        this.map.setOptions(this.options)
      },
      deep: true
    }
  }
};
</script>

<style scoped>
</style>