import api from "src/axiosWithDelimiterFile";
import { IntrusionConfigurations } from "./configuration";
import { resolvePromiseForObjectOrNull } from "../../common/wrappers/promise-resolver";

export class IntrusionVillasListService {
  /**
   *
   * @param {Object} QueryParams
   */
  async getVillasList(QueryParams) {
    const promise = api.get(IntrusionConfigurations.GETTING_VILLAS_DETAILS, {
      params: QueryParams
    });
    return resolvePromiseForObjectOrNull(promise);
  }

  async getVillaInfo(villaID, QueryParams) {
    const promise = api.get(IntrusionConfigurations.GETTING_VILLA_INFO(villaID), {
      params: QueryParams
    });
    return resolvePromiseForObjectOrNull(promise);
  }



  /**
   * 
   * @param {Integer} customerId 
   */
  async getCustomerContacts(customerId) {
    return resolvePromiseForObjectOrNull(
      api.get(IntrusionConfigurations.GETTING_CUSTOMER_CONTACTS(customerId))
    );
  }

  async getVillasLocations() {
    return resolvePromiseForObjectOrNull(api.get(IntrusionConfigurations.GETTING_VILLAS_LOCATIONS));
  }
}
