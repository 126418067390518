import store from "../../../store/index";
import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';


const StructureList = () =>
    import('./StructureList.vue');

const CreateStructure = () =>
    import('./CreateWizard.vue');

const StructureBrowser = () =>
    import('./Browsing/MainView.vue')


export default {
    path: '/structure',
    component: DashboardLayout,
    redirect: '/structure/list',
    children: [
        {
            path: 'list',
            name: 'Structure List',
            component: StructureList
        },
        {
            path: 'create',
            name: 'Register New Structure',
            beforeEnter: (to, from, next) => {
                store.dispatch('activateStructureModule');
                next();
            },
            component: CreateStructure
        },
        {
            path: ':id/browse',
            name: 'Structure Browsing',
            beforeEnter: (to, from, next) => {
                store.dispatch('activateStructureModule');
                store.dispatch('getSpacesTypes');
                next();
            },
            component: StructureBrowser,
            props: true
        },
    ]
}
