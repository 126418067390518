

import ActionsTypes from "../../types/actions-types";
import MutationsTypes from "../../types/mutations-type";
import {
  IntrusionVillasListService
} from "../../../services/intrusion-system-services.bundle";

export const villasListActions = {
  [ActionsTypes.GETTING_VILLAS_LIST_ACTION]: async ({ commit }, qs) => {
    const service = new IntrusionVillasListService();
    const reply = await service.getVillasList(qs);
    if (reply != null) {
      const { totalRecords, data } = reply;
      commit(MutationsTypes.GETTING_VILLAS_LIST_MUTATION, data);
      commit(MutationsTypes.VILLAS_DETAILS_TOTAL_RECORDS_MUTATION, totalRecords);
    }
  },


  [ActionsTypes.GET_MORE_INFO_ABOUT_CUSTOMER]({ commit, dispatch }, data) {
    dispatch(ActionsTypes.OPEN_MORE_INFORMATION_MODAL_ACTION, true);
  },

   /**
   *
   * @param commit
   * @param queryParams
   * @returns {Promise<boolean>}
   */
  [ActionsTypes.CUSTOMER_CONTACTS_LIST_ACTION]({ commit }, customerId) {
    return new Promise(async (resolve, reject) => {
      const service = new IntrusionVillasListService();
      const dbResponse = await service.getCustomerContacts(customerId);
      if (dbResponse != null) {
        const contacts = dbResponse.data.map(c=>({
          email: c.email,
          id: c.id,
          phone: c.phone,
          lastName: c.last_name,
          firstName: c.first_name,
        }));
        commit(MutationsTypes.CUSTOMER_CONTACTS_LIST_MUTATION, contacts);
        resolve(true);
      } else {
        reject(false);
      }
    });
  },

  [ActionsTypes.GET_VILLA_INFO_ACTION]: async ({ }, villaId) => {
    const service = new IntrusionVillasListService();
    return await service.getVillaInfo(villaId);
  },

  async [ActionsTypes.CUSTOMER_LOCATIONS_ARRAY_ACTION]({ commit }) {
    const service = new IntrusionVillasListService();
    const reply = await service.getVillasLocations();
    if(reply != null) { 
      reply.data.forEach(villa=>{
        villa.activeAlarmsCount = villa.active_operational_alarms + villa.active_intrusion_alarms;
        villa.devicesCount = villa.devices_count;
      })
      commit(MutationsTypes.CUSTOMER_LOCATIONS_ARRAY_MUTATION, reply.data);
    }
  }
};
