import { applyUniqueIdToAllObjectKeys } from "../../../../util/unique-id-generator";

let types = {
  // Alarms getters
  INTRUSION_ALARMS_GETTER: "INTRUSION_ALARMS_GETTER",
  STATUS_ALARMS_GETTER: "STATUS_ALARMS_GETTER",
  VILLAS_LIST_GETTER: "VILLAS_LIST_GETTER",
  INTRUSION_ALARMS_CURRENT_FILTER: "INTRUSION_ALARMS_CURRENT_FILTER",
  // Total records
  INTRUSION_ALARMS_TOTAL_RECORDS_GETTER: "INTRUSION_ALARMS_TOTAL_RECORDS_GETTER",
  VILLAS_DETAILS_TOTAL_RECORDS_GETTER: 'VILLAS_DETAILS_TOTAL_RECORDS_GETTER',


  // Columns config
  INTRUSION_ALARMS_TABLE_COLUMNS_CONFIG: 'INTRUSION_ALARMS_TABLE_COLUMNS_CONFIG',
  INTRUSION_ALARMS_HISTORY_TABLE_COLUMNS_GETTER: 'INTRUSION_ALARMS_HISTORY_TABLE_COLUMNS_GETTER',

  CUSTOMER_CONTACTS_LIST_GETTER: 'CUSTOMER_CONTACTS_LIST_GETTER',
  OPEN_ACKNOWLEDGEMENT_MODAL_GETTER: 'OPEN_ACKNOWLEDGEMENT_MODAL_GETTER',
  MORO_OR_POLICE_GETTER: 'MORO_OR_POLICE_GETTER',
  RE_OPEN_ACK_MODAL_GETTER: 'RE_OPEN_ACK_MODAL_GETTER',
  OPEN_MORE_INFORMATION_MODAL_GETTER: 'OPEN_MORE_INFORMATION_MODAL_GETTER',
  OPEN_VILLA_INFORMATION_MODAL_GETTER: 'OPEN_VILLA_INFORMATION_MODAL_GETTER',
  HISTORY_ALARMS_TOTAL_RECORDS_GETTER: 'HISTORY_ALARMS_TOTAL_RECORDS_GETTER',
  HISTORY_DATA_GETTER: 'HISTORY_DATA_GETTER',
  INTRUSION_ALARMS_STATISTICS_GETTER: 'INTRUSION_ALARMS_STATISTICS_GETTER',
  CUSTOMER_LOCATIONS_ARRAY_GETTER: 'CUSTOMER_LOCATIONS_ARRAY_GETTER',
  VILLAS_DEATILS_COLUMNS_CONFIG_GETTER: 'VILLAS_DEATILS_COLUMNS_CONFIG_GETTER',


};

types = applyUniqueIdToAllObjectKeys(types);
export default types;
