<template>
  <el-dialog :visible.sync="show">
      <div v-if="dialogContent" class="status-dialog">
        <p class="text-center" v-text="dialogContent.p"></p>
        <p class="text-center" v-text="dialogContent.p2"></p>
      </div>
      <div slot="footer" class="row justify-content-around" v-if="dialogContent">
        <base-button
          v-if="dialogContent.yes"
          @click="$emit('confirm')"
          class="text-capitalize"
          type="info"
        >{{dialogContent.yes}}</base-button>
        <base-button
          v-if="dialogContent.no"
          @click="$emit('close')"
          class="text-capitalize"
          type="warning"
        >{{dialogContent.no}}</base-button>
      </div>
    </el-dialog>
</template>

<script>
export default {
props: {
    isOpen: {
      type: Boolean
    },
    dialogContent: {
      type: Object,
      default:  {}
    }
  },
  computed: {
    show: {
      get() {
        return this.isOpen;
      },
      set(val) {
        this.$emit("close");
      }
    }
  }
}
</script>

<style>

</style>