<template>
  <div class="preview">


    <div class="page-wrapper" v-for="(page, p) in pages" :key="p">
      <div class="page">

        <div
          class="widget-wrapper"
          :class="`row-${widget.size}`"
          v-for="(widget, i) in page" :key="i"
          @mouseenter="$emit('hover', widget)"
          @mouseleave="$emit('blur')"
        >
          <div class="widget" :class="{ 'blank': widget.id == 5, 'empty': widget.id == 0 }">

            <div class="section-wrapper" :class="`section-row-${section.size}`" v-for="(section, j) in widget.sections" :key="j">
              <div class="section background-3">
                <div><span class="h6 font-weight-bold font-color-3 text-nowrap">{{widget.id != 5 && widget.id != 0 ? `${widget.num}.${section.num} ${widgetName(widget.id)}` : $t('widgets.blank')}}</span></div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  import getWidgetSections from './helpers/get-widget-sections'
  import { getProps } from './helpers/props'

  export default {
    props: ['widgets'],
    data: () => ({
      pages: [],
    }),
    mounted() {
      this.parseWidgets(this.widgets)
    },
    methods: {
      parseWidgets(widgets) {
        console.log('parsing...')
        let pageSize = getProps().pageSize
        let pages = []
        let page = []

        widgets.forEach((widget, i) => {
          let size = this.getPageSize(page)
          let sections = getWidgetSections(widget)
          let widgetSections = []

          sections.forEach((section, j) => {
            section.num = j + 1
            if (size + section.size <= pageSize) {
              widgetSections.push({...section})
              size += section.size
            } else {
              if (widgetSections.length)
                page.push({
                  id: widget.widget_id,
                  key: widget.key,
                  num: i + 1,
                  size: this.getWidgetSize(widgetSections),
                  sections: widgetSections
                })

              let emptySpace = pageSize - this.getPageSize(page)

              page.push({
                id: 0,
                size: emptySpace
              })

              pages.push(page)
              page = []
              widgetSections = [{...section}]
              size = section.size
            }
          })

          if (widgetSections.length) {
            page.push({
              id: widget.widget_id,
              key: widget.key,
              num: i + 1,
              size: this.getWidgetSize(widgetSections),
              sections: widgetSections
            })
            widgetSections = []
          }

        })

        if (page.length) {
          let emptySpace = pageSize - this.getPageSize(page)

          page.push({
            id: 0,
            size: emptySpace
          })

          pages.push(page)
          page = []
        }

        this.pages = pages
      },
      getPageSize(page) {
        return page.reduce((ac, widget) => ac + widget.size, 0)
      },
      getWidgetSize(sections) {
        return sections.reduce((ac, section) => ac + section.size, 0)
      },
      widgetName(id) {
        if (id == 1) {
          return 'Structure Comparison'
        } else if (id == 2) {
          return 'Device Comparison'
        } else if (id == 3) {
          return 'Solution Card'
        } else if (id == 4) {
          return 'Alarms Count'
        }
      }
    },
    watch: {
      widgets: {
        handler(val) {
          this.parseWidgets(val)
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  $page-height: 180px;

  .preview {
    height: 70vh;
    width: 100%;
    max-width: 198px;
    padding: 10px;
    margin-top: 10px;
    overflow-y: auto;

    .page-wrapper {
      padding-top: 22px;
      padding-bottom: 28px;
      background: url('~@/assets/images/preview-page-background.svg') no-repeat;
      margin-bottom: 30px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

      .page {
        height: $page-height;
        padding: 0 10px;
        display: flex;
        flex-direction: column;

        .widget-wrapper {
          padding: 3px 0;

          .widget {
            height: 100%;
            position: relative;
            /*background: #d8d8d8;*/
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .section-wrapper {
              padding: 3px;

              .section {
                height: 100%;
                display: grid;
                place-items: center;
                color: white;
                border-radius: 4px;
              }
            }


            &.blank {
              padding: 0;

              .section {
                color: #666;
                margin: 0;
              }
            }

            &.empty {
              background: url('~@/assets/images/preview-empty-section.svg');
            }
          }
        }


        .row-1 {
          height: calc(1 / 12 * #{$page-height})
        }

        .row-2 {
          height: calc(2 / 12 * #{$page-height})
        }

        .row-3 {
          height: calc(3 / 12 * #{$page-height})
        }

        .row-4 {
          height: calc(4 / 12 * #{$page-height})
        }

        .row-5 {
          height: calc(5 / 12 * #{$page-height})
        }

        .row-6 {
          height: calc(6 / 12 * #{$page-height})
        }

        .row-7 {
          height: calc(7 / 12 * #{$page-height})
        }

        .row-8 {
          height: calc(8 / 12 * #{$page-height})
        }

        .row-9 {
          height: calc(9 / 12 * #{$page-height})
        }

        .row-10 {
          height: calc(10 / 12 * #{$page-height})
        }

        .row-11 {
          height: calc(11 / 12 * #{$page-height})
        }

        .row-11 {
          height: calc(11 / 12 * #{$page-height})
        }

        .row-12 {
          height: calc(12 / 12 * #{$page-height})
        }

        .section-row-1 {
          flex: 1
        }

        .section-row-2 {
          flex: 2
        }

        .section-row-3 {
          flex: 3
        }

        .section-row-4 {
          flex: 4
        }

        .section-row-5 {
          flex: 5
        }

        .section-row-6 {
          flex: 6
        }

        .section-row-7 {
          flex: 7
        }

        .section-row-8 {
          flex: 8
        }

        .section-row-9 {
          flex: 9
        }

        .section-row-10 {
          flex: 10
        }

        .section-row-11 {
          flex: 11
        }

        .section-row-11 {
          flex: 11
        }

        .section-row-12 {
          flex: 12
        }

      }
    }
  }
</style>