class CustomerTableSingleRow {
  constructor(anObject) {
    this.id = anObject["customer_id"];
    this.lat = anObject['lat'];
    this.lng = anObject['lng'];
    this.cityName = anObject['city_name'];
    this.communityId = anObject['community_id'];
    this.arabicCommunityName = anObject['community_name_ar'];
    this.englishCommunityName = anObject['community_name_en'];
    this.countryId = anObject['country_id'];
    this.countryName = anObject['country_name'];  
    this.customerName = anObject['customer_name'];
    this.nvrCount = anObject['nvr_count'];
    this.activeAlarmsCount = anObject['active_alarms_count'];
    this.cameraCount = anObject['camera_count'];
    this.structureId = anObject['structure_id'];
    this.structureName = anObject['structure_name'];
    this.contacts = anObject['contacts'];
    this.primaryContact = anObject['contacts'] && anObject['contacts'].length > 0 ? anObject['contacts'][0] : {}; 
    this.address = `${this.countryName}, ${this.cityName}`;
    this.zone = anObject['zone'] ? anObject['zone'] : '-';
    this.contactNumber = this.primaryContact ? this.primaryContact['phone'] : '-';
  }
}

export const mapArrayOfObjectsToArrayOfCustomerTableSingleRow = data => data.map(obj => new CustomerTableSingleRow(obj));
