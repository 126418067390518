const alarm= [
  {
    status: "ok",
    notification: 2,
    email: true,
    whatsapp: false,
    sms: false,
  },
  {
    status: "poor",
    notification: 2,
    email: true,
    whatsapp: false,
    sms: false,
  },
  {
    status: "problem",
    notification: 2,
    email: true,
    whatsapp: false,
    sms: false,
  },
];

export default alarm;
