import VsaasService from '../../services/vsaas.service';

export default {
    namespaced: true,
    state: {
        loading: false,
        data: [],
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        }
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setData(state,data) {
            state.data = data;
        },
        addNewAlarm(state,data) {
            let statisticsCard = state.data.find(s => s.status == data.status);
            if(statisticsCard) {
                statisticsCard.activeAlarms++;
                statisticsCard.week++;
                statisticsCard.month++;
            } else {
                let newStatisticsCard = {
                    activeAlarms: 1,
                    week: 1,
                    month: 1,
                    status:data.status
                };
                state.data.push(newStatisticsCard);
            }
        },
        archiveAlarm(state,data) {
            let index = state.data.findIndex(s => s.status == data.status);
            if(index != -1) {
                statisticsCard[index].activeAlarms--;
                if(statisticsCard[index].activeAlarms == 0) {
                    state.data.splice(index,1);
                }
            }
        }
    },
    actions: {
        async fetchData({commit}) {
            commit('setLoading',true);
            try {
                const data = await VsaasService.getAlarmsStatistics();
                commit('setData',data);
                commit('setLoading',false);
            } catch(error) {
                commit('setLoading',false);
                console.log(error);
            }
        },
        onNewAlarmSocketEvent({commit},payload) {
            commit('addNewAlarm',payload);
        },
        onArchiveAlarmSocketEvent({commit},payload) {
            commit('archiveAlarm',payload);
        },
    }
};