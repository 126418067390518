var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:{
    'input-group-focus': _vm.focused,
    'has-danger': _vm.error || _vm.$slots.error,
    'has-label': _vm.label,
    'has-icon': _vm.hasIcon,
    'view-mode':_vm.viewMode,
    'inline-select':_vm.inline,
  }},[_vm._t("label",[(_vm.label)?_c('label',{staticClass:"font-color-4 font-weight-bold h4"},[_vm._v(" "+_vm._s(_vm.label)+" "+_vm._s(_vm.required ? '*' : '')+" ")]):_vm._e()]),_c('div',{staticClass:"mb-0",class:{'input-group': _vm.hasIcon}},[_vm._t("addonLeft",[(_vm.addonLeftIcon)?_c('span',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_c('i',{class:_vm.addonLeftIcon})])]):_vm._e()]),_vm._t("default",[_c('el-select',{staticClass:"select-primary",class:{'view-mode':_vm.viewMode,'light':_vm.light,'normal':!_vm.light, 'hide-shadow': _vm.hideShadow},attrs:{"size":_vm.size,"placeholder":_vm.placeholder,"value":_vm.value,"disabled":_vm.disabled || _vm.viewMode,"no-data-text":_vm.noData,"filterable":_vm.filterable,"remote":_vm.remote,"remote-method":_vm.remoteMethod,"loading":_vm.loading,"popper-class":_vm.popperClass},on:{"change":_vm.onInput}},_vm._l((_vm.options),function(option){return _c('el-option',{key:option.value,staticClass:"select-primary",attrs:{"value":option.value,"label":_vm.i18n?_vm.$tc(option.label):option.label,"disabled":option.disabled}})}),1)]),_vm._t("addonRight",[(_vm.addonRightIcon)?_c('span',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text"},[_c('i',{class:_vm.addonRightIcon})])]):_vm._e()])],2),(_vm.error || _vm.$slots.error)?_vm._t("error",[_c('label',{staticClass:"error"},[_vm._v(_vm._s(_vm.error))])]):_vm._e(),_vm._t("helperText")],2)}
var staticRenderFns = []

export { render, staticRenderFns }