<template>
  <card v-loading="loading" class="pl-3 pr-3 text-align-start" style="height: 157px;">
      <div class="row m-0">
          <div class="col-9">
              <router-link
                :to="getUrl()"
                class="clickable font-weight-bold font-color-3 h4 m-0"
              >
                  {{ $t('alarmsWords.operationalAlarms') }}
              </router-link>
          </div>
          <h4 class="col-3 font-weight-bold m-0">{{ total }}</h4>
      </div>
      <hr class="mt-1 mb-1" style="border-top-width: 2px;"/>
      <div class="row m-0">
          <div class="col-12">
              <operationalAlarmsDetails :link="getUrl()" :data="data" />
          </div>
      </div>
  </card>
</template>

<script>
import operationalAlarmsDetails from '@/pages/SWAM/components/operational-alarms-details';
import { mapActions, mapGetters,mapState } from "vuex";

export default {
    components:{
        operationalAlarmsDetails,
    },
    props:{
        namespace:{
            type:String,
            default: "SwamStore/StructurePortfolio"
            // required:true
        },
    },
    computed: {
        ...mapState({
            loading(state,getters){
              return getters[this.namespace+"/StructuresStatistics/loading"];
            },
            data(state,getters) {
             return getters[this.namespace+"/StructuresStatistics/operationalAlarms"];
            },
            structureId(state,getters) {
                return getters[this.namespace+"/structureId"];
            }
        }),
        total() {
            if(this.data.ok == undefined) {
                return '-';
            }
            let total = 0;
            total += this.data.ok;
            total += this.data.poor;
            total += this.data.problem;
            return total;
        },
    },
    methods: {
        ...mapActions({
            fetchData(dispatch, payload){
                return dispatch(this.namespace+"/StructuresStatistics/fetchData",payload)
            },
        }),
        getUrl() {
            return '/SWAM/Alarms/operational' + (this.structureId ? '?structure_id=' + this.structureId : '' );
        },
    },
    created() {
        this.fetchData(this.structureId);
    },
    watch: {
        structureId() {
            this.fetchData(this.structureId);
        }
    }
}
</script>

<style>

</style>