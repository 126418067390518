import LocksService from "../../services/locks.service"

export default {
  namespaced: true,
  state: {
    locks: [],
    loading: false
  },
  getters: {
    locks(state){
      return state.locks
    },
    loading(state){
      return state.loading
    }
  },
  mutations: {
    setLocks(state,payload){
      state.locks = payload
    },
    setLoading(state, payload){
      state.loading = payload
    }
  },
  actions: {
    async fetchData({ commit }) {
      return new Promise((resolve, reject) => {
          commit('setLoading', true);
          LocksService.getLocks()
              .then((res) => {
                  commit('setLocks', res.data)
                  commit("setLoading", false);
                  resolve(res.data.data)
              })
              .catch((err) => reject(err));
      });
    },
    async updateLock({ commit }, payload) {
      return new Promise((resolve, reject) => {
          commit('setLoading', true);
          LocksService.updateLock(payload)
              .then((res) => {
                  commit("setLoading", false);
                  resolve(res.data.data)
              })
              .catch((err) => reject(err));
      });
    },
  }
}