var loadingPromise = null;

export const load = () => {
  if(!loadingPromise)
    loadingPromise = new Promise((resolve, reject)=>{
      const zingchartScript = document.createElement('SCRIPT')

      let url = '/js/zingchart.min.js'

      zingchartScript.setAttribute('src', url)
      zingchartScript.setAttribute('async', '')
      zingchartScript.setAttribute('defer', '')
      document.head.appendChild(zingchartScript)

      zingchartScript.addEventListener('load', () => {
        zingchart.LICENSE = [
          "ca741d2594bfc891414cb47cd675b119",
          "a455e7f7b7e49ca41b0fac0e8c52d4ed"
        ];
        zingchart.EXPORTURL = process.env.VUE_APP_ZINGCHART_EXPORT;
        zingchart.AJAXEXPORT = process.env.VUE_APP_ZINGCHART_AJAX_EXPORT;
        resolve()
      })
    })
  return loadingPromise;
}