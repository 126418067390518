<template>
  <el-dialog :title="$t('edit user')" :visible.sync="show" width="650px">
    <div class="p-3 row justify-content-center">
      <div class="col-12">
        <image-upload
          class="d-flex justify-content-center align-items-center"
          type="avatar"
          :src="user.img_path"
          select-text="Edit photo"
          @change="onAvatarChange"
        >
        </image-upload>
      </div>
      <div class="col-md-6">
        <base-input
          :label="$t('first name')"
          v-model="user.first_name"
          :placeholder="$t('first name')"
        ></base-input>
      </div>
      <div class="col-md-6">
        <base-input
          :label="$t('last name')"
          v-model="user.last_name"
          :placeholder="$t('last name')"
        ></base-input>
      </div>
      <div class="col-md-6">
        <base-input
          :label="$t('email')"
          v-model="user.email"
          :placeholder="$t('email')"
        ></base-input>
      </div>
      <div class="col-md-6">
        <base-input
          :label="$t('address')"
          v-model="user.address"
          :placeholder="$t('address')"
        ></base-input>
      </div>
      <div class="col-md-6">
        <base-input
          :label="$t('notifications email')"
          v-model="user.notifications_email"
          :placeholder="$t('notifications email')"
        ></base-input>
      </div>
      <div class="col-md-6">
        <base-input
          :label="$t('billing email')"
          v-model="user.billing_email"
          :placeholder="$t('billing email')"
        ></base-input>
      </div>
      <div class="col-md-6">
        <base-input
          :label="$t('phone')"
          v-model="user.phone"
          :placeholder="$t('phone')"
        ></base-input>
        <phone-input
          :placeholder="$t('Phone')"
          v-model="user.phone"
          :countryId="getCountryCode(user.phone)"
        >
        </phone-input>
      </div>
      <div class="col-md-6">
        <base-input
          :label="$t('phone2')"
          v-model="user.phone2"
          :placeholder="$t('phone2')"
        ></base-input>
      </div>
    </div>
    <div class="d-flex justify-content-center py-3">
      <base-button type="primary" size="sm" class="mx-2" @click="submit(false)">
        {{ $t("Submit") }}
      </base-button>
      <base-button
        type="secondary"
        simple
        size="sm"
        class="mx-2"
        @click="close()"
      >
        {{ $t("close") }}
      </base-button>
    </div>
  </el-dialog>
</template>

<script>
import { ImageUpload } from "src/components/index";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
  props: {
    isOpen: {
      type: Boolean
    },
    user: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    ImageUpload
  },
  data: () => ({}),
  methods: {
    getCountryCode(phone) {
      const phoneNumber = parsePhoneNumberFromString("+96355555555");
      console.log(phoneNumber)
      return phoneNumber.countryCallingCode;
    },
    submit(escelate) {
      // this.$emit('submit', this.form)
    },
    close() {
      this.$emit("close");
    },
    onAvatarChange(file) {
      this.user.avatar = file;
    }
  },
  computed: {
    show: {
      get() {
        return this.isOpen;
      },
      set(val) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style></style>
