<template>
  <div
    class="row justify-content-center align-items-center position-relative mb-2"
  >
    <div class="col-12">
      <component
        class="widget mb-2"
        :class="{
          'card-error': !config,
          'widget-active': activeWidgetKey == widget.key,
        }"
        :ref="widget.key"
        :is="widget.id"
        @valid="handleValid"
        :data="{...widget.data,period:period}"
        :disableDataFetch="!dataPreview"
        :namespace="'ReportsDesignerStore'"
        :moduleName="moduleName"
        v-bind="widget.props"
        :globalMode="true"
      >
        <div slot="header-right">
          <card class="card-p-0 mb-2">
            <div class="d-flex align-items-center">
              <div
                class="card-content"
                :class="{ 'card-content-expand': expandToolbar }"
              >
                <div class="d-flex align-items-center p-1" style="height: 50px">
                  <div class="mx-3">
                    <checkbox v-model="dataPreview" class="mt-0">{{
                      $t("widgets.dataPreview")
                    }}</checkbox>
                  </div>
                  <div class="mt-1 mx-2" style="height: 30px">
                    <inline-svg
                      @click="$emit('delete')"
                      class="delete-icons"
                      viewBox="0 0 60 80"
                      :src="require('@/assets/Icons/delete_sqr.svg')"
                    />
                    <inline-svg
                      @click="$emit('up')"
                      class="up-down-icons"
                      viewBox="0 0 60 80"
                      :src="require('@/assets/Icons/up.svg')"
                    />
                    <inline-svg
                      @click="$emit('down')"
                      class="up-down-icons"
                      viewBox="0 0 60 80"
                      :src="require('@/assets/Icons/down.svg')"
                    />
                  </div>
                </div>
              </div>
              <div
                class="mr-2 ml-3 edit-icon"
                style="cursor: pointer"
                @click="expandToolbar = !expandToolbar"
              >
                <i class="fa fa-edit fa-2x"></i>
              </div>
            </div>
          </card>
        </div>
      </component>
    </div>
    <div class="col-12 add-button">
      <base-button round icon type="primary" @click="$emit('create')">
        <i class="tim-icons icon-simple-add font-weight-bolder"></i>
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StructureComparsion from "@/pages/SWAM/core/Structure-Comparison";
import DeviceComparison from "@/pages/SWAM/core/Device-Comparison";
import SolutionStatistics from "@/pages/SWAM/core/Solution-Statistics";
import SolutionStatisticsStore from "@/pages/SWAM/core/Solution-Statistics/store";
import checkbox from "./../../../components/Inputs/BaseCheckbox";
import StructureComparisonStore from "@/pages/SWAM/core/Structure-Comparison/store";
import DeviceComparisonStore from "@/pages/SWAM/core/Device-Comparison/store";
import BlankCard from "./Blank-Card";
import StructuresWithDevicesList from '@/pages/SWAM/components/StructuresWithDevicesList';
import Statistics from '@/pages/SWAM/core/Statistics/Overall';
import StatisticsStore from '@/pages/SWAM/core/Statistics/store';
import AlarmsCount from '@/pages/SWAM/core/Alarms-Count';
import AlarmsCountStore from '@/pages/SWAM/core/Alarms-Count/store';

export default {
  name: "widget-wrapper",
  props: {
    widget: {},
    period: {},
    activeWidgetKey: {},
  },
  data() {
    return {
      config: {},
      dataPreview: false,
      moduleName: this.widget.id,
      expandToolbar: false,
    };
  },
  computed: {
    ...mapGetters("ReportsDesignerStore", {
      widgetsWithStore: "widgetsWithStore",
    }),
    thisWidgetHasStore() {
      return this.widgetsWithStore.includes(this.widget.id);
    },
  },

  created() {
    if (!this.thisWidgetHasStore) return;

    this.$store.registerModule(
      ["ReportsDesignerStore", this.widget.id + this._uid],
      this.getMyStore()
    );
    this.moduleName = this.widget.id + this._uid;
  },
  destroyed() {
    if (this.thisWidgetHasStore) {
      this.$store.unregisterModule(
        ["ReportsDesignerStore", this.moduleName],
        this.getMyStore()
      );
    }
  },
  methods: {
    extractConfig() {
      return this.config;
    },
    isValid() {
      this.config = this.$refs[this.widget.key].extractConfig();
      if (this.config) {
        return true;
      }
      return false;
    },
    handleValid(config) {
      console.log(config);
      this.config = config;
      if(this.widget.id == "SolutionStatistics"){
        this.widget.chartsCount = config.chartsCount;
      }
      delete config.chartsCount;
      this.$emit("valid", config);
    },
    getMyStore() {
      let store;
      switch (this.widget.id) {
        case "StructureComparsion":
          store = StructureComparisonStore;
          break;
        case "DeviceComparison":
          store = DeviceComparisonStore;
          break;
        case "Statistics":
          store = StatisticsStore;
          break;
        case "AlarmsCount":
          store = AlarmsCountStore;
          break;
        case "SolutionStatistics":
          store = SolutionStatisticsStore;
          break;
      }
      return store;
    },
  },
  watch: {
    dataPreview() {
      if(!this.dataPreview) {
        if (!this.thisWidgetHasStore) return;
        this.$store.dispatch('ReportsDesignerStore/'+this.moduleName + '/resetData');
      }
    }
  },
  components: {
    StructureComparsion,
    DeviceComparison,
    SolutionStatistics,
    "blank-card": BlankCard,
    checkbox,
    StructuresWithDevicesList,
    Statistics,
    AlarmsCount,
  },
};
</script>

<style scoped lang="scss">
.card-content {
  width: 0;
  opacity: 0;
  transition: width, opacity 0.3s ease-in-out;
  z-index: -100;

  &-expand {
    width: auto;
    opacity: 1;
    z-index: auto;
  }
}

.widget-active {
  border: 2px solid $primary;
}
.widget {
  max-height: 550px;
  overflow-y: auto;
}
.add-button {
  position: absolute;
  bottom: -26px;
  z-index: 1;
  justify-content: center;
  display: flex;
  min-block-size: 50px;
  left: 20px;
}
.add-button button {
  display: none;
}
.add-button:hover button {
  display: block;
}
.card-error {
  background-color: #37273d;
}
</style>

<style lang="scss">
.card-p-0 {
  .card-body {
    padding: 0;
  }
}
.delete-icons:hover path,
.up-down-icons:hover path {
  fill: $primary;
  cursor: pointer;
}

.icon-wrapper:hover {
  background: $primary;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.edit-icon {
  color: $primary;
  &:hover {
    cursor: pointer;
  }
}
.white-content {
  .icon-wrapper:hover {
    background: $primary-states;
  }

  .up-down-icons path,
  .delete-icons path,
  .icon-wrapper svg path {
    fill: $primary;
  }

  .delete-icons:hover path,
  .up-down-icons:hover path {
    fill: $primary-states;
  }
}
</style>
