<template>
  <div ref="chart" :id="id"></div>
</template>

<script>
  import { getId } from './helpers/idGenerator'
  import { load } from './helpers/loader'

  export default {
    name: 'zingchart',
    props: {
      data: {
        type: Object,
        default: () => ({})
      },
      height: {
        type: String,
        default: '200px'
      },
      width: {
        type: String,
        default: '400px'
      }
    },
    data: () => ({
      id: ''
    }),
    created() {
      this.id = getId()
    },
    mounted() {
      load().then(() => {
        this.render()
      })
    },
    methods: {
      render() {
        zingchart.render({
          id: this.id,
          data: this.data,
          height: this.height,
          width: this.width
        })
      },
      updateData() {
        load().then(() => {
          zingchart.exec(this.id, 'setdata', {
            data: this.data,
          })
        })
      }
    },
    watch: {
      data: {
        handler(val) {
          this.updateData()
        },
        deep: true
      }
    }
  }
</script>