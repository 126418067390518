<template>
  <card class="card-body tabs-events p-1">
    <div class="row justify-content-around">
      <h5 class="dash-title nav-link c-p"
          @click.prevent="currentTab = 1"
          :class="currentTab === 1 ? 'active' : ''">
        {{$t("Permissions")}}
      </h5>
      <!-- <h5
        class="dash-title nav-link c-p"
        @click.prevent="currentTab = 2"
        :class="currentTab === 2 ? 'active' : ''"
      >Groups</h5> -->
    </div>
    <template v-if="currentTab == 1">
      <div>
        <autocomplete :placeholder="$t('Search For Permission')"
                      ref="autocomplete"
                      :source="permissionsArray"
                      results-property="data"
                      results-display="key"
                      @input="selectPermission"></autocomplete>

        <el-table :data="selectedPermissions" height="400" style="width:100%">
          <el-table-column :min-width="mode=='Group'?35:70" label="ID">
            <span slot-scope="{row}"
                  style="font-weight: bold;color: white;text-transform: uppercase;">{{row.key}}</span>
          </el-table-column>
          <el-table-column v-if="mode=='Group'" :min-width="55" :label="$t('Alias Name')">
            <template slot-scope="{row}">
              <input :placeholder="$t('Set An Alias Name')"
                     type="text"
                     v-model="row.name"
                     maxlength="20"
                     minlength="3"
                     class="my-input" />
            </template>
          </el-table-column>

          <el-table-column :min-width="15" :label="$t('Remove')">
            <template slot-scope="data">
              <i @click="removePermission(data.row,data.$index)"
                 class="fa fa-trash text-danger fa-lg"></i>
            </template>
          </el-table-column>

          <div slot="empty" class="w-100 d-flex align-items-center vh-25">
            <p class="h5 no-per">{{$t("No Permissions Selected")}}</p>
          </div>
        </el-table>
      </div>
    </template>

    <template v-if="currentTab == 2">
      <div>
        <autocomplete :placeholder="$t('Search For Groups')"
                      ref="gautocomplete"
                      :source="groupsArray"
                      results-property="data"
                      results-display="display"
                      @input="addGroup"></autocomplete>
        <p class="mt-1 text-white mb-0">{{$("included Groups")}}</p>
        <el-table :data="selectedGroups" height="400" style="width:100%;border: 1px solid;" border>
          <el-table-column :min-width="30" :label="$('Group Name')">
            <template slot-scope="{row,$index}">
              <div class="position-relative">
                <i @click="excludeGroup($index)"
                   class="el-icon el-icon-close text-danger remove-icon"></i>
                <p class="h6 text-center group-name">{{row.name}}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column :min-width="65" :label="$('Permissions')">
            <template slot-scope="{row}">
              <div class="row group-permissions">
                <p class="badge badge-dark col-lg-5 mb-1"
                   style="font-size:1rem;white-space: pre-wrap;"
                   v-for="(perm,index) in row.permissions"
                   :key="index">{{(perm.name && viewAliases)?perm.name:perm.key}}</p>
              </div>
            </template>
          </el-table-column>

          <div slot="empty" class="w-100 d-flex align-items-center vh-25">
            <p class="h5 no-per">{{$t("No Groups Included")}}</p>
          </div>
        </el-table>

        <!-- <table class="table table-striped table-recent mb-4 mt-0">
          <thead class="thead-dark">
            <tr>
              <td style="width:30%;">Group Name</td>
              <td style="width:65%">Permissions</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(group,index) of selectedGroups" :key="group.name+(index+0.1)">
              <td style="width:30%;" class="position-relative">
                <i
                  @click="excludeGroup(index)"
                  class="el-icon el-icon-close text-danger remove-icon"
                ></i>
                <p class="h6 text-center group-name">{{group.name}}</p>
              </td>
              <td style="width:65%;"></td>
            </tr>
          </tbody>
        </table>-->
      </div>
    </template>

    <base-button slot="footer" @click="done" type="primary" size="sm">{{$t("Done")}}</base-button>
  </card>
</template>

<script>
  import autocomplete from "./../../../components/BaseAutoComplete";
  import { mapGetters } from "vuex";
  export default {
    props: ["viewAliases"],
    data() {
      return {
        permissionsArray: [],
        groupsArray: [],
        currentTab: 1,
        selectedGroups: []
      };
    },
    computed: {
      ...mapGetters({
        selectedPermissions: "permissions__selectedPermissions",
        includedGroups: "permissions__includedGroups",
        mode: "permissionsPage__mode"
      })
    },
    created() {
      this.$store.dispatch("getPermissions").then(res => {
        this.permissionsArray = res;
        for (let i = 0; i < this.permissionsArray.length; i++) {
          for (const sp of this.selectedPermissions) {
            if (this.permissionsArray[i].key == sp.key) {
              this.permissionsArray.splice(i, 1);
            }
          }
        }
      });

      this.$store
        .dispatch("getGroups")
        .then(allGroups => {
          console.log(allGroups);
          console.log(this.includedGroups);
          for (const g of allGroups) {
            let Included = false;
            for (const gg of this.includedGroups) {
              if (g.id == gg) {
                Included = true;
                this.selectedGroups.push(g);
                break;
              }
            }
            if (!Included) {
              this.groupsArray.push(g);
            }
          }
        })
        .catch(err => {
          console.log(this);
          console.log(err);
        });
    },
    methods: {
      con(t) {
        console.log(t);
      },
      selectPermission(e) {
        if (!e) return;
        this.addOne(e.selectedObject);
        this.$refs.autocomplete.clear();
      },
      addOne(permission) {
        const index = this.permissionsArray.findIndex(val => {
          return val.key == permission.key;
        });

        this.permissionsArray.splice(index, 1);
        permission.markedBy = 1;
        permission.value = true;
        this.selectedPermissions.push(permission);
      },
      addGroup(group) {
        if (!group) return;
        const g = group.selectedObject;
        //remove the selected group from the group list
        const index = this.groupsArray.findIndex(val => {
          return val.id == g.id;
        });
        this.groupsArray.splice(index, 1);

        for (const gPer of g.permissions) {
          let exist = false;
          for (const per of this.selectedPermissions) {
            if (gPer.key == per.key) {
              if (!per.markedBy) {
                per.markedBy = 1;
              } else {
                per.markedBy++;
              }
              exist = true;
            }
          }
          if (!exist) {
            gPer.value = true;
            this.addOne(gPer);
          }
        }

        this.selectedGroups.push(g);
        this.includedGroups.push(g.id);
        this.$refs.gautocomplete.clear();
      },
      excludeGroup(index) {
        const toDelete = this.selectedGroups[index];
        for (const per of toDelete.permissions) {
          for (let i = 0; i < this.selectedPermissions.length; i++) {
            const sper = this.selectedPermissions[i];
            if (per.key == sper.key) {
              if (!sper.markedBy || sper.markedBy <= 1) {
                this.removePermission(sper, i);
              } else {
                sper.markedBy--;
              }
            }
          }
        }

        this.selectedGroups.splice(index, 1);
        this.includedGroups.splice(index, 1);
        this.groupsArray.push(toDelete);
      },
      removePermission(perm, index) {
        perm.markedBy = 0;
        this.selectedPermissions.splice(index, 1);
        this.permissionsArray.push(perm);
      },
      done() {
        this.$emit("done", {
          sp: this.selectedPermissions,
          sg: this.includedGroups
        });
      }
    },
    components: {
      autocomplete
    }
  };
</script>

<style scoped>
    ::v-deep .el-table::before {
      background-color: rgba(0, 0, 0, 0);
    }

    ::v-deep .el-table--border {
      border: none;
    }

    .c-p {
      cursor: pointer;
    }

    .key-cell {
      font-weight: bold;
      color: white;
      text-transform: uppercase;
    }

    .vh-25 {
      height: 25vh;
    }

    ::v-deep .el-table__empty-block {
      background: #00000047;
    }

    .active {
      color: #ff33df;
      font-weight: 900;
    }

    .nav-link {
      font-weight: 700;
    }

    .w-35 {
      width: 35%;
    }

    .w-70 {
      width: 70%;
    }

    .tabs-events .nav-link {
      transition: all 0.5s ease-in-out;
    }

    .done-btn {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
    /*
  .autocomplete >>> .autocomplete__box {
    background: #23303d !important;
    border-color: #4b5a6a;
    border-radius: 0.25rem;
  }
  .autocomplete >>> .autocomplete__box:focus {
    border-color: #2fbd75;
  }
  .autocomplete >>> .autocomplete__box img.autocomplete__icon {
    margin-left: 12px;
  }
  .autocomplete >>> .autocomplete__box span.autocomplete__icon img {
    margin-bottom: 10px;
  }
  .autocomplete >>> .autocomplete__results {
    max-height: 250px;
    background-color: #525c66;
  }
  .autocomplete >>> .autocomplete__results li {
    color: #ccc;
    border: 1px solid #1c2631;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
  }*/
    .my-input {
      background: inherit;
      height: 1rem;
      border-radius: 0.25rem;
      margin: 0;
      color: white;
      padding: 0;
      outline: none;
      font-size: 0.9rem;
      border: 0;
      border-bottom: 1px solid transparent;
      width: 100%;
      text-transform: capitalize;
    }

    table {
      overflow-y: auto;
      max-height: 350px;
      display: block;
      margin-top: 30px;
      margin-bottom: 4px !important;
      border-bottom: 1.5px solid #839bbb;
    }

    th {
      width: 75px;
      position: fixed;
      background: white;
    }

      th:nth-of-type(2) {
        left: 75px;
      }

    td {
      width: 75px;
    }

    thead,
    tbody {
      width: 99%;
      display: inline-table;
    }

    .no-per {
      text-align: center;
      width: 100%;
      height: fit-content;
    }

    .group-permissions {
      max-height: 100px;
      overflow: auto;
      justify-content: space-around;
      padding-top: 0.7rem;
    }

    .remove-icon {
      position: absolute;
      top: 10px;
      font-weight: bold;
      cursor: pointer;
    }

      .remove-icon:hover {
        color: #ff0056 !important;
      }

    .group-name {
      font-weight: 700;
      font-size: 1.2rem;
    }
</style>
