// URLs object
export const IntrusionConfigurations = {
  GETTING_MORO_ALARMS_URL: `intrusion/alarms`,
  GETTING_POLICE_ALARMS_URL: `intrusion/alarms/police`,
  GETTING_ALARMS_HISTORY_URL: `intrusion/alarms/history`,
  BY_MORO_POST_ACKNOWLEDGE_ALARM: alarmId => `intrusion/alarms/${alarmId}/acknowledge`,
  BY_POLICE_POST_ACKNOWLEDGE_ALARM: alarmId => `intrusion/alarms/${alarmId}/police/acknowledge`,
  POST_VERIFY_ALARM: alarmId => `intrusion/alarms/${alarmId}/verify`,
  POST_TERMINATE_ALARM: alarmId => `intrusion/alarms/${alarmId}/terminate`,
  POST_COMMENT_ALARM: alarmId => `intrusion/alarms/${alarmId}/comment`,
  GETTING_CUSTOMER_CONTACTS: customerId => `company/${customerId}/contacts`,
  GETTING_ALARMS_STATISTICS: `intrusion/alarms/statistics`,
  GETTING_VILLAS_LOCATIONS: `intrusion/villas/for-map`,
  GETTING_VILLAS_DETAILS: `/intrusion/villas/`,
  GETTING_VILLA_INFO: villaId => `/intrusion/villas/${villaId}`,
  ON_ALARMS_MORE_INFORMATION_URL: '/intrusion/info'

};
