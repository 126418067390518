import VsaasService from "../../services/vsaas.service";

export default {
    namespaced: true,
    state: {
        loading: false,
        center: { lat: 25.221643, lng: 55.255257 },
        mapType: 1,
        markers: [],
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        markerIcon(state) {
            if(state.mapType == 1)
                return require("src/assets/icons-2/google-map-marker.svg");
            else
                return require("src/assets/icons-2/google-map-marker-red.svg");
        },
        markers(state) {
            if(state.mapType == 2) {
                return state.markers.filter(m => m.activeAlarmsCount);
            }
            return state.markers;
        },
        devicesCount(state) {
            let result = 0;
            if(!state.markers.length)
                return 0;
            state.markers.forEach(m => {
                result += m.devicesCount;
            })
            return result;
        },
        alarmsCount(state) {
            let result = 0;
            if(!state.markers.length)
                return 0;
            state.markers.forEach(m => {
                result += m.activeAlarmsCount;
            })
            return result;
        },
        modalData(state) {
            let result = {};
            result.title = {
                label: state.center.structureName ? state.center.structureName : '--',
                link: state.center.structureId ? `/VSAAS/Customer-Info/${state.center.structureId}` : undefined
            };
            result.data = [];
            if(!state.center.structureName)
                return result;
            let data = [];
            data.push({
                key: 'Structure ID',
                value: state.center.structureId,
                icon: require("@/assets/Icons/id.svg")
            });
            data.push({
                key: 'Devices Count',
                value: state.center.devicesCount,
                icon: require("@/assets/Icons/communities.svg"),
            });
            data.push({
                key: 'Alarms Count',
                value: state.center.activeAlarmsCount,
                icon: require("@/assets/Icons/bell-filled-white.svg")
            });
            data.push({
                key: 'LAT',
                value: state.center.lat,
                icon: require("@/assets/Icons/communities.svg")
            });
            data.push({
                key: 'LNG',
                value: state.center.lng,
                icon: require("@/assets/Icons/communities.svg")
            });
            result.data = data;
            return result;
        }
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setCenter(state,value) {
            state.center = value;
        },
        setMapType(state,value) {
            state.mapType = value;
        },
        setMarkers(state,value) {
            state.markers = value;
        }
    },
    actions: {
        async fetchData({commit}) {
            commit('setLoading',true);
            try {
                const data = await VsaasService.getMapMarkers();
                commit('setMarkers',data);
                commit('setLoading',false);
            } catch(error) {
                commit('setLoading',false);
                console.log(error);
            }
        }
    }
}