<template>
  <div>
    <card v-if="d" class="high-card-shadow">
      <div class="d-flex justify-content-between">
        <h3>{{$t('deviceWords.deviceInfo')}}</h3>
        <base-button @click.native="moreInfo = true" size="sm" round icon type="info">
          <i class="fas fa-info-circle"></i>
        </base-button>
      </div>
      <div class="card-description">
        <div class="col-12">
          <div class="row">
            <div class="col-5">
              <label>{{$t('deviceWords.deviceName')}}:</label>
            </div>
            <div class="col-7 blue">{{d.device_name}}</div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-5">
              <label>{{$t('deviceWords.communicationType') }}:</label>
            </div>
            <div class="col-7 blue">{{d.communication}}</div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-5">
              <label>{{$t('contractDuration')}}:</label>
            </div>
            <div class="col-7 blue">{{d.contract_duration}}</div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-5">
              <label>{{$t('payingCustomer')}}:</label>
            </div>
            <div class="col-7 blue">{{d.customer}}</div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-5">
              <label>AMC:</label>
            </div>
            <div class="col-7 blue">{{d.amc_name}}</div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-5">
              <label>FMC:</label>
            </div>
            <div class="col-7 blue">{{d.fmc}}</div>
          </div>
        </div>
      </div>
      <!-- <modal :show.sync="moreInfo" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
        <DeviceInfoModal :device="d"></DeviceInfoModal>
      </modal>-->
    </card>
    <card class="high-card-shadow">
      <div class="d-flex justify-content-between">
        <h3 class="mb-2">{{$t('siteInfo')}}</h3>
        <base-button @click.native="moreInfo = true" size="sm" round icon type="info">
          <i class="fas fa-info-circle"></i>
        </base-button>
      </div>
      <div class="card-description">
        <div class="col-12">
          <div class="row">
            <div class="col-5">
              <label>{{$t('maintenanceOrder.community')}} :</label>
            </div>
            <div class="col-7 blue">{{o.Community_English}}</div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-5">
              <label>{{$tc('structureWords.structure')}}:</label>
            </div>
            <div class="col-7 blue">{{s.parent_structure.structure_name}}</div>
          </div>
        </div>
        <div class="col-12" v-if="s.parent_floor">
          <div class="row">
            <div class="col-5">
              <label>{{$tc('structureWords.floor')}}:</label>
            </div>
            <div class="col-7 blue">{{s.parent_floor.name}}</div>
          </div>
        </div>
        <div class="col-12" v-if="s.parent_space">
          <div class="row">
            <div class="col-5">
              <label>{{$t('structureWords.spaceName')}}:</label>
            </div>
            <div class="col-7 blue">{{getSpaceName(s.parent_space)}}</div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-5">
              <label>
                <template v-if="s.parent_space">{{ $t('structureWords.indoorSpaces') }}</template>
                {{$t('structureWords.spaceName')}}:
              </label>
            </div>
            <div class="col-7 blue">{{getSpaceName(s)}}</div>
          </div>
        </div>
        <!-- <div class="col-12">
        <div class="row">
          <div class="col-5">
            <label>FMC:</label>
          </div>
          <div class="col-7 blue">{{d.fmc}}</div>
        </div>
        </div>-->
      </div>
    </card>

    <modal :show.sync="moreInfo" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
      <card
        style="height:40vh"
        :bodyClasses="'align-items-center d-flex justify-content-around'"
        class="mb-0"
      >
        <div class>
          <h4>{{$t('Not Supported Yet')}}</h4>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import DeviceInfoModal from "./DeviceInfoModal";
export default {
  data() {
    return {
      moreInfo: false,
    };
  },
  computed: {
    d() {
      return this.$store.getters.selectedMO.device_info;
    },
    o() {
      return this.$store.getters.selectedMO;
    },
    s() {
      return this.o.space;
    },
  },
  methods: {
    getSpaceName(space) {
      return space.space_id || !space.floor_id ? space.name : space.number;
    },
  },
  components: {
    DeviceInfoModal,
  },
};
</script>

<style scoped>
.blue {
  color: #1d8cf8;
}
.rtl .col-5,
.rtl .col-7 {
  text-align: right;
}
</style>
