import ActionsTypes from "../../types/actions-types";
import MutationsTypes from "../../types/mutations-type";
import GettersTypes from "../../types/getter-types";
import { IntrusionAlarmsService } from "../../../services/intrusion-system-services.bundle";

export const intrusionAlarmsActions = {
  [ActionsTypes.GETTING_INTRUSION_ALARMS_ACTION]: async ({ state, commit, getters }, qs) => {
    const service = new IntrusionAlarmsService();
    let reply;
    if(getters[GettersTypes.MORO_OR_POLICE_GETTER] == "police"){
      reply = await service.getPoliceIntrusionAlarms(qs);
    }else{
      reply = await service.getMoroIntrusionAlarms(qs);
    }
    if (reply != null) {
      console.log('wow nice reply: ', reply);
      const { totalRecords, data } = reply;
      commit(MutationsTypes.GETTING_INTRUSION_ALARMS_MUTATION, data);
      commit(
        MutationsTypes.INTRUSION_ALARMS_TOTAL_RECORDS_MUTATION,
        totalRecords
      );
    }
  },

  [ActionsTypes.GETTING_HISTORY_DATA_ACTION]: async ({ state, commit }, qs) => {
    const service = new IntrusionAlarmsService();
    const reply = await service.getAlarmsHistory(qs);
    if(reply != null) {
      commit(MutationsTypes.GETTING_HISTORY_DATA_MUTATION, reply.data);
      commit(MutationsTypes.HISTORY_ALARMS_TOTAL_RECORDS_MUTATION, reply.totalRecords);
    }
  },

  [ActionsTypes.GETTING_INTRUSION_ALARMS_STATISTICS_ACTION]: async ({ commit }, data) => {
    const service = new IntrusionAlarmsService();
    const reply = await service.getAlarmsStatistics();
    if(reply != null) {
      commit(MutationsTypes.GETTING_INTRUSION_ALARMS_STATISTICS_MUTATION, reply.data);
    }
  },
  [ActionsTypes.UPDATE_FILTER]: async ({ commit }, data) => {
      commit(MutationsTypes.SETTING_INTRUSION_ALARMS_CURRENT_FILTER, data);
  }
  
};
