<template>
  <div>
    <template v-if="company">
      <profile-picture
        :imgURL="imagePath"
        :placeholderURL="companyPlaceholderImage"
        :canEdit="canEdit"
        @deleteImg="onDeleteImage"
        @imageChanged="onImageChanged"
        :alt="'Company Image'"
      ></profile-picture>
      <a class="d-block text-center" href="javascript:void(0)">
        <p class="user-name mb-0">
          <input
            v-model="company.name"
            placeholder="--"
            :class="{
              'form-control': editingInfo,
              error: $v.company.name.$error && editingInfo,
            }"
            :disabled="!editingInfo"
          />
        </p>
      </a>
      <p class="description">{{ $t("Type") }} : {{ type }}</p>
    </template>
    <div class="row" v-if="company">
      <div class="col-12 col-md-6">
        <card>
          <p slot="header" class="title w-100 justify-content-between">
            {{ $t("companyWords.companyInfo") }}
            <template v-if="isAdminOrSuperAdmin">
              <i
                v-if="!editingInfo"
                @click="editInfo"
                class="tim-icons icon-pencil float-right edit-icon"
              ></i>
              <span v-else class="w-25 d-flex justify-content-between">
                <i
                  @click="undoChanging"
                  class="tim-icons icon-simple-remove"
                ></i>
                <i @click="saveInfo" class="tim-icons icon-check-2"></i>
              </span>
            </template>
          </p>
          <span class="title">{{ $t("id") }} :</span>
          <span class="value id">{{ $generateID(company.id, type) }}</span>

          <span class="title">{{ $t("country") }} :</span>
          <countries-select
            class="value"
            :disabled="!editingInfo"
            :viewMode="!editingInfo"
            :placeholder="$t('country')"
            :inputValue="this.company.country_id"
            style="margin-bottom: 5px"
            :class="{ error: $v.company.country.$error && editingInfo }"
            @input="(event)=> company.country_id  = event.id"

          >
          </countries-select >

          <span class="title">{{ $t("city") }} :</span>
          <cities-select
            class="value"
            :placeholder="$t('city')"
            :disabled="!editingInfo"
            :viewMode="!editingInfo"
            style="margin-bottom: 5px"
            :class="{ error: $v.company.city.$error && editingInfo }"
            :countryId="company.country_id"
            :inputValue="company.city_id"
            @input="(event)=> company.city_id  = event.id"
          >
          </cities-select >

          <span class="title">{{ $t("address") }} :</span>
          <span @click="openAddressMap">
            <gmap-autocomplete
              :placeholder="$t('instructions.enterLocation')"
              ref="gmap"
              id="location_input"
              @place_changed="setPlace"
              class="value mb-2"
              :disabled="!editingInfo"
              :class="{ error: $v.company.address.$error && editingInfo }"
            ></gmap-autocomplete>
          </span>

          <span class="title">{{ $t("maxUsers") }} :</span>
          <input
            class="value"
            type="number"
            :class="{
              'form-control': editingInfo,
              error: $v.company.max_users.$error && editingInfo,
            }"
            :disabled="!editingInfo"
            v-model.number="company.max_users"
            placeholder="--"
          />

          <span class="title">{{ $tc("admin", 1) }} :</span>
          <ul class="value">
            <li
              v-for="(admin, index) in company.admins"
              :key="index"
              @click="openUser(admin.id)"
              class="linkable mb-3"
              :class="{ creator: admin.id == company.parent_id }"
            >
              <span style="width: 35%">{{ $generateID(admin.id) }}</span>
              <span style="width: 65%">{{ admin.name }}</span>
            </li>
          </ul>

          <span class="title">{{ $t("account.createdAt") }} :</span>
          <span class="value">{{ gDateFormat(company.created_at) }}</span>

          <span class="title">{{ $t("account.updatedAt") }} :</span>
          <span class="value">{{
            company.updated_at ? gDateFormat(company.updated_at) : "--"
          }}</span>
          <span class="d-flex" v-if="canEdit">
            <span class="title align-items-center"> Notifications Templates  :</span>
            <span
              class="value d-flex justify-content-center align-items-center"
            >
              <router-link
                :to="`/SWAM/notifications-templates/list/${company.id}`"
                tag="a"
                type="info"
                >open</router-link
              >
            </span>
          </span>
          <span class="d-flex" v-if="isPayingCustomer">
            <span class="title align-items-center">{{ $tc("zone", 2) }} :</span>
            <span
              class="value d-flex justify-content-center align-items-center"
            >
              <router-link
                :to="`/profiles/customer/${company.id}/zones`"
                tag="base-button"
                type="info"
                >{{ company.zone_count }}</router-link
              >
            </span>
          </span>
          <span class="d-flex" v-if="isPayingCustomer">
            <span class="title align-items-center"
              >{{ $tc("companyWords.department", 2) }} :</span
            >
            <span
              class="value d-flex justify-content-center align-items-center"
            >
              <router-link
                :to="`/profiles/company/customer/${company.id}/departments`"
                tag="base-button"
                type="info"
                >{{ company.departments_count }}</router-link
              >
            </span>
          </span>
        </card>
      </div>
      <div class="col-12 col-md-6">
        <card>
          <p slot="header" class="title w-100 justify-content-between">
            {{ $tc("companyWords.companyUser", 2) }}
            <i
              v-if="canCreateUser"
              @click="expandUsers"
              class="tim-icons icon-align-left-2 float-right disabled"
            ></i>
            <i
              v-if="canCreateUser"
              @click="addUser"
              class="tim-icons icon-pencil float-right icon-simple-add disabled"
            ></i>
          </p>
          <div
            class="mb-3"
            v-for="(users, type) in company.groupedUsers"
            :key="type"
          >
            <span class="title">{{ $tc(type, 2) }} :</span>
            <ul class="value">
              <li
                v-for="(user, index) in users"
                @click="openUser(user.id)"
                :key="index"
                class="linkable mb-2"
              >
                <span style="width: 35%; vertical-align: top">{{
                  user.id
                }}</span>
                <span style="width: 65%">{{ user.email }}</span>
              </li>
            </ul>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Dialog } from "element-ui";
import helper from "../../util/helper";
import { required } from "vuelidate/lib/validators";
import ProfilePicture from "../../components/ProfilePicture.vue";

const debounce = helper.debounce;

export default {
  props: ["id", "type"],
  data() {
    return {
      // country: '',
      // city: '',
      dialogContent: null,
      showDialog: false,
      editingInfo: false,

      companyInfoCopy: {
        name: null,
        city: null,
        city_id:null,
        country: null,
        country_id:null,
        address: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "user",
      companyPlaceholderImage: "companyPlaceholderImage",
    }),
    company() {
      if (this.$store.getters.CompanyData) {
        this.$nextTick().then((_) => {
          this.$refs.gmap.setTextValue(this.$store.getters.CompanyData.address);
        });
      }
      return this.$store.getters.CompanyData;
    },
    imagePath() {
      if (this.company && this.company.img_path) {
        return this.company.img_path;
      } else {
        return "";
      }
    },
    isPayingCustomer() {
      return (
        this.$store.getters.CompanyData &&
        this.$store.getters.CompanyData.type == "customer"
      );
    },
    canCreateUser() {
      let t2 = this.company.users.find(
        (u) => u.isSupervisor && u.id == this.currentUser.id
      );
      let test = t2 && this.currentUser.id == t2.id;
      return (
        this.currentUser.role_id == 1 ||
        this.currentUser.id == this.company.parent_id ||
        test
      );
    },
    isSuperAdminOpening() {
      return this.currentUser.role_id == 1;
    },
    isAdminOpening() {
      return (
        (this.company.admins &&
          this.company.admins.some((u) => u.id == this.currentUser.id)) ||
        this.isSuperAdminOpening
      );
    },
    isSupervisorOpening() {
      return this.company.users.some((u) => {
        return u.isSupervisor && u.id == this.currentUser.id;
      });
    },
    isAdminOrSupervisourOpening() {
      return this.isAdminOpening || this.isSupervisorOpening;
    },
    isAdminOrSuperAdmin() {
      return this.isSuperAdminOpening || this.isAdminOpening;
    },
    canEdit() {
      return this.isAdminOrSupervisourOpening || this.isAdminOrSuperAdmin;
    },
  },
  validations: {
    company: {
      name: {
        required,
      },
      max_users: {
        // range: function(val) {
        //   return val >= this.company.users.length;
        // }
      },
      city: {
        required,
      },
      country: {
        required,
      },

      city_id:{
      required,
      },
      country_id:{
        required,
      },
      address: {
        required,
      },
    },
  },
  created() {
    this.address = this.company.address;
  },
  methods: {
    ...mapActions(["addEmployee"]),
    editInfo() {
      this.editingInfo = true;
      this.companyInfoCopy = {
        name: this.company.name,
        city: this.company.city,
        country: this.company.country,
        city_id:this.company.city_id,
        country_id:this.company.country_id,
        address: this.company.address,
        lat: this.company.lat,
        lng: this.company.lng,
        max_users: this.company.max_users,
      };
    },
    saveInfo() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$notify({
          message: "You Have Errors on Your new data!",
          type: "danger",
        });
        return;
      }
      // search for changing
    /*  console.log("this.companyInfoCopy",this.companyInfoCopy);
      console.log("compdata",this.company);*/

      const changing = {};
      Object.keys(this.companyInfoCopy).forEach((key) => {
        if (this.company[key]) {
          this.company[key] = this.company[key];
        }
        if (this.companyInfoCopy[key]) {
          this.companyInfoCopy[key] = this.companyInfoCopy[key];
        }
        if (this.company[key] != this.companyInfoCopy[key]) {
          changing[key] = this.company[key];
        }
      });

      //if there is one change at least
      if (Object.keys(changing).length != 0) {
        this.$store
          .dispatch("saveCompanyChanges", changing)
          .then((_) => {
            this.editingInfo = false;
          })
          .catch((err) => {
            //show error notification
            console.error(err);
          });
      } else {
        //no change happens
        this.editingInfo = false;
      }
    },
    undoChanging() {
      this.editingInfo = false;
      this.$store.commit("setCompanyData", {
        ...this.company,
        ...this.companyInfoCopy, //This will override all changes
      });
    },
    addUser() {
      if (this.company.users.length < this.company.max_users) {
        this.$store.commit("initNewUser", {});
      } else {
        this.$notify({
          message: "Maximum number of users exceeded",
          type: "danger",
        });
      }
    },
    openAddressMap(e) {
      console.log(e);
      if (!this.editingInfo) {
        this.$store.commit("openAddressMap", {
          lat: this.company.lat,
          lng: this.company.lng,
        });
      }
    },
    openUser(id) {
      this.resetAll();
      this.$router.push(`/profiles/user/${id}`);
      this.$store.dispatch("getUserData", id);
    },
    resetAll() {
      this.dialogContent = null;
      this.showDialog = false;
      this.editingInfo = false;
      this.companyInfoCopy = {
        name: null,
        city: null,
        country: null,
        city_id:null,
        country_id:null,
        address: null,
      };
    },
    setPlace(place) {
      if (this.company.country.trim() == "") {
        this.$refs.gmap.setTextValue("");
        alert("You Should Select a Country First");
        return;
      }
      const addressComponents = place.address_components;
      if (!addressComponents) {
        alert("Please Choose A Valid Address");
        return;
      }
      const validPlace =
        true ||
        addressComponents.some((value) => {
          return (
            value.short_name == this.company.country &&
            value.types.some((type) => {
              return type == "country";
            })
          );
        });
      if (validPlace) {
        if (place) {
          this.company.lat = place.geometry.location.lat();
          this.company.lng = place.geometry.location.lng();
          this.company.address = this.$refs.gmap.getTextValue();
        }
      } else {
        // reset the location input
        this.$refs.gmap.setTextValue("");
        alert("Can not choose address out of the selected Country");
      }
    },
    expandUsers() {
      this.$router.push({
        name: "company users",
        params: {
          type: this.type,
          id: this.id,
        },
      });
    },
    onImageChanged(base64) {
      this.$store
        .dispatch("saveCompanyChanges", {
          logo: base64,
        })
        .then((data) => {
          console.log(data);
          console.log("Image Changed Successfuly");
          this.$nextTick((_) => {
            this.company.img_path = base64;
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onDeleteImage() {
      this.$store
        .dispatch("saveCompanyChanges", {
          logo: null,
        })
        .then((x) => {
          this.$nextTick((__) => {
            this.company.img_path = null;
          });
        });
    },
  },
  components: {
    [Dialog.name]: Dialog,
    ProfilePicture
  },
  destroyed() {
    // this.$store.commit("setUserData", null);
  },
};
</script>

<style scoped lang="scss">
::v-deep select:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
p input {
  color: unset;
  text-transform: capitalize;
  text-align: center;
  display: unset !important;
  width: unset !important;
}
p input:disabled {
  background: transparent;
  border: none;
  outline: none;
}
.last-name:disabled {
  text-align: left;
  margin-left: 3px;
}
.first-name:disabled {
  text-align: right;
  margin-right: 3px;
}

ul {
  padding-inline-start: 20px;
}
li {
  margin-bottom: 5px;
}
li.creator {
  list-style-type: none;
  position: relative;
}
li.creator::before {
  content: "\2605";
  position: absolute;
  left: -19px;
  color: var(--primary);
}
li span {
  display: inline-block;
}
.card {
  height: 95%;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.4);
}
::v-deep .card-body {
  min-height: unset;
}
.user-name {
  font-size: 2rem;
  text-transform: capitalize;
}
.description {
  text-transform: capitalize;
}
.title {
  width: 35%;
  /* align-items: center; */
  display: inline-flex;
  font-size: 1rem;
  margin-bottom: 10px;
  letter-spacing: 1px;
  vertical-align: top;
  text-transform: capitalize;
}
.value {
  width: 65%;
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  text-transform: capitalize;
  min-height: calc(2.25rem + 2px);
  margin-bottom: 5px;
  text-overflow: ellipsis;
}

.white-content .form-control[disabled] {
  color: #1d253b;
}
.white-content .value {
  color: #1d253b;
}
.input-group-text {
  min-height: calc(2.25rem + 2px);
  font-size: 0.75rem;
  background: gray;
  padding: 10px !important;
}
.input-group:focus-within .input-group-text {
  border-color: var(--primary);
}
.input-group:focus-within .input-group-text.error {
  border-color: #ec250d !important;
}
.id {
  color: var(--primary);
}
input:disabled.value,
::v-deep .form-control[disabled] {
  background: transparent;
  border: none;
  outline: none;
}
input:disabled::placeholder,
::v-deep input.form-control[disabled],
.title {
  color: white;
}

::v-deep .form-control[disabled] {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.875rem;
  text-align: center;
  cursor: auto;
}
/deep/ #location_input:disabled {
  box-shadow: none;
}

.linkable,
#location_input:not(disabled) {
  text-decoration: underline var(--primary);
  cursor: pointer;
}
.linkable:hover,
#location_input:not(disabled):hover {
  color: var(--primary);
}

.user-status {
  cursor: pointer;
}
.user-status:hover {
  opacity: 0.8;
}
.status-dialog {
  text-transform: capitalize;
  letter-spacing: 1.2px;
}
p {
  color: wheat !important;
  text-align: center;
}
::v-deep .el-dialog__body {
  padding-top: 0;
}
::v-deep .el-dialog__body button {
  text-transform: capitalize;
}
.tim-icons {
  padding: 3px;
  border-radius: 25%;
  cursor: pointer;
}
.edit-icon {
  border: 1px solid var(--primary);
  color: var(--primary);
}
.edit-icon:hover {
  background: var(--primary);
  color: var(--default);
}
.icon-check-2,
.icon-simple-add {
  border: 1px solid var(--success);
  color: var(--success);
}
.icon-check-2:hover,
.icon-simple-add:hover {
  background: var(--success);
  color: white;
}
.icon-simple-remove {
  border: 1px solid var(--danger);
  color: var(--danger);
}
.icon-simple-remove:hover {
  background: var(--danger);
  color: white;
}
.error {
  border-color: #ec250d !important;
  background-color: rgba(222, 222, 222, 0.05) !important;
}
.icon-align-left-2 {
  border: 1px solid $info;
  color: $info;
}
.icon-align-left-2:hover {
  background: $info;
  color: white;
}
@include font_secondary(".title");
@include font_primary(".user-name");
@include font_primary(".description");

</style>
