<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <!-- <router-link to="FooterM">

          </router-link>-->
          <a target="_blank" :href="home" rel="noopener" class="nav-link">{{title}}</a>
        </li>
        <li class="nav-item">
          <!--  <router-link to="FooterA">

          </router-link>-->
          <a target="_blank" :href="aboutUs" rel="noopener" class="nav-link">About Us</a>
        </li>
        <li class="nav-item">
          <!-- <router-link to="FooterT">

          </router-link>-->
          <a target="_blank" :href="terms" rel="noopener" class="nav-link">TERMS & CONDITIONS</a>
        </li>
        <!-- <li class="nav-item">
          <a
            href="javascript:void(0)"
                  target="_blank"
            rel="noopener"
            class="nav-link"
          >
            DOCUMENTAION
          </a>
        </li>-->
      </ul>
      <div class="copyright">
        &copy; {{ year }} - IoT Platform Powered by
        <a
          href="//www.morohub.com"
          target="_blank"
        >{{title}}</a>
        <h6 class="h-footer h-m">
          <span class="span-info">Support Email:</span>
          <a :href="'mailto:'+support" class="link footer-link">{{support}}</a>
        </h6>
        <h6 class="h-footer">
          <span class="span-info">Support Phone:</span>
          <a href="javascript:void(0)" class="link footer-link">{{phone}} (Within UAE)</a>
        </h6>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    support() {
      return process.env.VUE_APP_SUPPORT;
    },
    phone() {
      return process.env.VUE_APP_PHONE;
    },
    title() {
      return process.env.VUE_APP_TITLE;
    },
    home() {
      return process.env.VUE_APP_LINKS_HOME;
    },
    aboutUs() {
      return process.env.VUE_APP_LINKS_ABOUT;
    },
    terms() {
      return process.env.VUE_APP_LINKS_TERMS;
    },
  },
};
</script>
<style scoped>
@media (max-width: 575.98px) {
  .h-footer {
    padding-left: 10px;
  }
  .h-m {
    margin-top: 5px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .h-footer {
    padding-left: 10px;
  }
  .h-m {
    margin-top: 5px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .h-footer {
    padding-left: 10px;
  }
  .h-m {
    margin-top: 5px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .h-footer {
    padding-left: 10px;
    display: inline-block;
  }
  .h-m {
    margin-top: 3px;
  }
}

@media (min-width: 1200px) {
  .h-footer {
    padding-left: 10px;
    display: inline-block;
  }
}

.span-info {
  padding-right: 3px;
}

.footer .nav-link {
  padding-right: 0.1rem;
}
</style>
