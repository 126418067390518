
import VsaasService from '../../services/vsaas.service';

export default {
    namespaced: true,
    state: {
        loading: false,
        orderId: undefined,
        data: {},
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        }
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setData(state,data) {
            state.data = data;
        },
        setOrderId(state,value) {
            state.orderId = value;
        }
    },
    actions: {
        async fetchData({state,commit}) {
            if(state.data && +state.data.id == +state.orderId)
                return;
            commit('setLoading',true);
            try {
                const data = await VsaasService.getWorkOrderData(state.orderId);
                commit('setData',data.data);
                commit('setLoading',false);
            } catch(error) {
                console.log(error);
                commit('setLoading',false);
            }
        }
    }
};