export const GENERAL_INFO = "intrusion/info";
export const VILLA_LIST = "intrusion/villas/for-map";
export const ALARMS_COMPARISON = "intrusion/analysis/periodCounts";
export const POLICE_STATIONS_STATISTICS = "intrusion/alarms/statistics";
export const VERIFICATION_REASONS = "intrusion/analysis/verificationCounts";
export const ALARMS_TYPES_COUNTS = "intrusion/analysis/statusesCounts";
export const ALARMS_STATISTICS = "intrusion/analysis/statistics";
export const MORO_STATISTICS = "/intrusion/analysis/moroStatistics";

export default {
    GENERAL_INFO,
    VILLA_LIST,
    ALARMS_COMPARISON,
    POLICE_STATIONS_STATISTICS,
    VERIFICATION_REASONS,
    ALARMS_STATISTICS,
    ALARMS_TYPES_COUNTS,
    MORO_STATISTICS
}