


class MapLocationWrapper {
    constructor(anObject) {
        this.lat = anObject['lat'] ? anObject['lat'] : '45.508';
        this.lng = anObject['lng'] ? anObject['lng'] : '-73.587';
        this.structureId = anObject['structure_id'];
        this.structureName = anObject['structure_name'];
        this.devicesCount = anObject['devices_count'];// ??
        this.customerName = anObject['customer_name'];// ??
        this.activeAlarmsCount = anObject['active_alarms_count'];
    }
}


export const mapArrayOfObjectsToArrayOfMapLocationWrapper = (data) => data.map(obj => new MapLocationWrapper(obj));