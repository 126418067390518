
export default {
    namespaced:true,
    state:() => ({
        loading:false,
        solutions:[
            {key:'Swimming Pool Monitoring',value:'-',id:1},
            {key:'Water pressure Monitoring',value:'-'},
            {key:'Indoor Air Monitoring',value:'-',id:3},
            {key:'Outdoor Air Monitoring',value:'-'},
            {key:'Cold Storage',value:'-'},
        ]
    }),
    getters: {
        loading(state) {
            return state.loading;
        },
        getData(state) {
            // let result = [];
            // Object.keys(state.solutions).forEach(k => {
            //     result.push({name:k,value:state.solutions[k]});
            // })
            return state.solutions;
        },
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setData(state,data) {
            state.solutions = data;
        }
    },
    actions: {
        fetchData({state,commit},payload) {
            commit('setLoading',true);
            let data = state.solutions;
            data.forEach(s => {
                s.value = 0;
            });
            let structures = payload;
            structures.forEach((s) => {
                s.solutions.forEach(sol => {
                    data.forEach(s => {
                        if(s.key == sol.name) {
                            s.value += sol.devices;
                        }
                    })
                })
            })
            commit('setData',data);
            commit('setLoading',false);
        },
        fetchDataByStructure({state,commit},payload) {
            commit('setLoading',true);
            let data = state.solutions;
            data.forEach(s => {
                s.value = 0;
            });
            let structures = payload.structures;
            let structureId = payload.structureId;
            structures = structures.filter((s) => s.id == structureId);
            structures.forEach((s) => {
                s.solutions.forEach(sol => {
                    data.forEach(s => {
                        if(s.key == sol.name) {
                            s.value += sol.devices;
                        }
                    })
                })
            })
            commit('setData',data);
            commit('setLoading',false);
        },
        reset({state}) {
            state.solutions = [
              { key: "Swimming Pool Monitoring", value: "-", id: 1 },
              { key: "Water pressure Monitoring", value: "-" },
              { key: "Indoor Air Monitoring", value: "-", id: 3 },
              { key: "Outdoor Air Monitoring", value: "-" },
              { key: "Cold Storage", value: "-" },
            ];
        }
    },
}