import {applyUniqueIdToAllObjectKeys} from "../../util/unique-id-generator";

let USER_PREFERENCES_ACTIONS = {
  SET_ITEM_ACTION: 'SET_ITEM_ACTION',
  GET_ITEM_ACTION: 'GET_ITEM_ACTION',
  DELETE_ITEM_ACTION: 'DELETE_ITEM_ACTION',
  CHANGE_SENSOR_COLOR : "CHANGE_SENSOR_COLOR",
  RESET_SENSOR_COLOR : "RESET_SENSOR_COLOR",
};

USER_PREFERENCES_ACTIONS = applyUniqueIdToAllObjectKeys(USER_PREFERENCES_ACTIONS);
export default USER_PREFERENCES_ACTIONS;
