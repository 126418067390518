<template>
  <el-dialog
      :title="notification.title"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :before-close="close"
      width="37%"
      top="8vh"
      >

      <!-- Type Card Static-->
      <div class="infos">
        <div>
          <i class="el-icon-warning"></i>
          <span class="title">Type</span>
        </div>
        <div>
          <span>{{ notification.type }}</span>
        </div>
      </div>

      <!-- Other Cards -->
      <div class="infos" v-for="(info,i) in infos" :key="i">
        <!-- Icon And Title -->
        <div>
          <i :class="types[notification.type].descriptionFields[info[0]]"></i>
          <span class="title">{{ info[0] }}</span>
        </div>
        <!-- Value  -->
        <div>
          <span>{{ info[1] }}</span>
        </div>
      </div>
      <!-- Footer And Close Button -->
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-round btn-success btn-sm" @click="close">Close</button>
        <button
          v-if="types[notification.type].routeLink"
          class="btn btn-round btn-success btn-sm"
        >
          Page
        </button>
      </span>
    </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui';
import { mapGetters } from 'vuex';
export default {
  props: ['notification','dialogVisible'],
  components:{
    [Dialog.name]: Dialog,
  },
  computed:{
    infos(){
      let result = [];
      let description = this.notification.description;
      while(description.includes('\n'))
      {
        description = description.replace('\n','');
      }

      result = description.split('- ');
      result.splice(0,1);
      result.splice(result.length-1,1);
      for(let i=0;i<result.length;i++){
        result[i] = result[i].split(': ');
      }
      //console.log(result);
      return result;
    },
    ...mapGetters({
      types:"notifications/types"
    }),
  },
  methods:{
    close(){
      // Close Modal
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

div.infos{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #343453;
  padding: 10px;
  margin-top:10px;
  border-radius: 10px;
  color: #ffff;
  align-items: center;
}

div.infos span.title{
  font-weight: bold;
  font-size: 17px;
}

div.infos i{
  margin-right: 8px;
  font-size: 17px;

}

span.dialog-footer{
  display: flex;
  justify-content: space-around;

}

.dialog{
  border-radius: 15px;
}
.el-dialog__body{
  padding-bottom: 5px;
  padding-top: 5px;
}
.el-dialog__title{
  color: #ffff !important;
}
</style>
