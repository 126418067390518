import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import { M } from '../../Permissions';


// const DeviceList = () =>
//     import( './DeviceList.vue');

// const DevicesMap = () =>
//     import( './DevicesMap.vue');

const DeviceInfo = () =>
    import( './DeviceInfo.vue');

const AdminDashboard = () =>
    import( './AdminDashboard/AdminDashboard.vue');

const DeviceZone = () =>
    import( './DeviceZone.vue');
const Attendence = ()=>
    import('./AttendenceModule/Attendence.vue')





export default [
    {
        path: '/healthcare',
        component: DashboardLayout,
        redirect:'/healthcare/dashboard',
        name:'Health Care',
        meta:{
            module:M.teltonika
        },
        children: [
            {
                path:'dashboard',
                name:'Admin Dashboard',
                component:AdminDashboard
            },
            {
                path: 'deviceinfo/:id',
                name:'healthcare-deviceinfo',
                component: DeviceInfo
            },
            {
                path: 'devicezone/:id',
                name:'Device Zone',
                component: DeviceZone
            },




        ]
    },
    {
        path: '/attendence',
        component: DashboardLayout,
        redirect:'/',
        name:'Attendence',
        meta:{
            module:M.teltonika
        },
        children: [
            {
                path:'/',
                name:'Attendence Page',
                component:Attendence
            },





        ]
    },

]
