import PortfolioVehicleInfo from '../../core/PortfolioVehicleInfo/store';
import PortfolioVehicleAlarms from '../../core/PortfolioVehicleAlarms/store';
import PortfolioNeedsAttention from '../../core/PortfolioNeedsAttention/store';
import VehicleStatusAlarms from '../../core/VehicleStatusAlarms/store';
import VehicleRepairInventory from '../../core/VehicleRepairInventory/store';
import PortfolioMap from '../../core/PortfolioMap/store';
import PortfoliotService from '../../services/portfolio.service';
export default {
  namespaced: true,
  modules: {
    PortfolioVehicleInfo,
    PortfolioVehicleAlarms,
    PortfolioNeedsAttention,
    VehicleRepairInventory,
    VehicleStatusAlarms,
    PortfolioMap
  },
  state: {
    driversPerformanceData: [],
    performanceTotal: 0,
    totalRepairRecords: 0,
    vehicleRepairInventoryData:[],
    totalVehicleList: 0,
    vehicleList:[],
    loading: false,
    dates: [],
    chartData: {
      data: {
        ok: 0,
        poor: 0,
        problem: 0,
      }
    },
    chartLoading: false,
    forklifts: [],
    forkliftChart: [],
    intervalChart: []
  },
  getters: {
    driversPerformanceData(state) {
      return state.driversPerformanceData;
    },
    performanceTotal(state){
      return state.performanceTotal
    },
    totalRepairRecords(state){
      return state.totalRepairRecords
    },
    vehicleRepairInventoryData(state){
      return state.vehicleRepairInventoryData
    },
    totalVehicleList(state){
      return state.totalVehicleList
    },
    vehicleList(state){
      return state.vehicleList
    },
    loading(state){
      return state.loading
    },
    chartLoading(state){
      return state.chartLoading
    },
    forklifts(state){
      return state.forklifts
    },

    forkliftChart(state){
      return state.forkliftChart
    },
    intervalChart(state){
      return state.intervalChart
    },
  },
  mutations: {
    setDriverPerformanceData(state, payload) {
      state.driversPerformanceData = payload
    },
    SET_PERFORMANCE_TOTAL(state, payload){
      state.performanceTotal = payload
    },
    setTotalRepairRecords(state, payload){
      state.totalRepairRecords = payload
    },
    setVehicleRepairInventoryData(state, payload){
      state.vehicleRepairInventoryData = payload
    },
    setTotalVehicleList(state, payload){
      state.totalVehicleList = payload
    },
    setVehicleList(state, payload){
      state.vehicleList = payload
    },
    setLoading(state, payload){
      state.loading = payload
    },
    setChartLoading(state, payload){
      state.chartLoading = payload
    },
    setForklifts(state, payload){
      state.forklifts = payload
    },
    setForkliftChart(state, payload){
      state.forkliftChart = payload
    },
    setIntervalChart(state, payload){
      state.intervalChart = payload
    },

  },
  actions: {
    fetchDriverPerformance({ commit }, date) {
      return new Promise((resolve, reject) => {
        PortfoliotService.getDriverPerformance(date).then(res => {

          commit('SET_PERFORMANCE_TOTAL', 2)
          commit('setDriverPerformanceData', res.data.data)
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    async fetchVehicleRepairInventoryData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true);
        PortfoliotService.getForkliftsData(payload)
          .then((res) => {
            commit("setVehicleRepairInventoryData", res.data.data);
            commit("setTotalRepairRecords", res.data.total_records)
            commit("setLoading", false);
            resolve(res.data.data)
          })
          .catch((err) => reject(err));
      });
    },
    async fetchVehicleList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true);
        PortfoliotService.getVehicleList(payload)
          .then((res) => {
            commit("setVehicleList", res.data.data);
            commit("setTotalVehicleList", res.data.total_records)
            commit("setLoading", false);
            resolve(res.data.data)
          })
          .catch((err) => reject(err));
      });
    },

    async fetchForklifts({commit}){
      return new Promise((resolve, reject) => {
        PortfoliotService.getForklifts()
          .then(res => {
            commit('setForklifts', res.data.data)
            resolve(res)
          }).catch(err => reject(err))
      })
    },

    async fetchChartForkliftComparison({commit}, data){
      return new Promise((resolve, reject) => {
        commit('setChartLoading', true);
        PortfoliotService.getChartForkliftComparison(data)
          .then(res => {
            console.log(res.data)
            commit('setChartLoading', false)
            commit('setForkliftChart', res.data.data)
            resolve(res)
          }).catch(err => reject(err))
      })
    },
    async fetchChartIntervalComparison({commit}, data){
      return new Promise((resolve, reject) => {
        commit('setChartLoading', true);
        PortfoliotService.getChartIntervalComparison(data)
          .then(res => {
            console.log(res.data)
            commit('setChartLoading', false)
            commit('setIntervalChart', res.data.data)
            resolve(res)
          }).catch(err => reject(err))
      })
    },
  }
}