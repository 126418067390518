<template>
    <sidebar-item
        v-if="canModule(M.vsaas)"
        :link="{
          name: $t('sidebar.vsaasSmb.vsaasSmb'),
          cartLeft : true,
        }"
    >
        <sidebar-item
          :link="{
          name: $t('alarmsWords.operationalAlarms'),
          path: '/VSAAS/Operational-Alarms',
        }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
          name: $t('sidebar.vsaasSmb.customerList'),
          path: '/VSAAS/Customer-List',
        }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
          name: 'WorkOrder',
          path: '/VSAAS/WorkOrders',
        }"
        >
        </sidebar-item>
    </sidebar-item>
</template>

<script>
import SidebarItem from '@/components/SidebarPlugin/SidebarItem.vue';

export default {
    name:"vsaas-sidebar-items",
    components:{
        SidebarItem
    }
}
</script>

<style>

</style>