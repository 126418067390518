import { getProps } from './props'

const widget1 = (widget) => {
  let props = getProps()[1]
  return [
    {size: props.header},
    {size: props.gaugeCharts},
    {size: props.lineChart},
  ]
}

const widget2 = (widget) => {
  let props = getProps()[2]
  return [
    {size: props.header},
    {size: props.gaugeCharts},
    {size: props.lineChart},
  ]
}

const widget3 = (widget) => {
  let props = getProps()[3]
  let chartsSections = []

  if (widget.chartsCount) {
    let rowsCount = Math.ceil(widget.chartsCount / 2)

    chartsSections = new Array(rowsCount).fill({size: props.sensorsRow})
  }

  return [
    {size: props.header},
    ...chartsSections
  ]
}

const widget4 = (widget) => {
  let props = getProps()[4]
  return [
    {size: props.header},
    {size: props.lineChart},
    {size: props.lineChart},
    {size: props.lineChart},
    {size: props.lineChart},
  ]
}

const widget5 = (widget) => {
  let rows = 1

  if (widget.data && widget.data.rows)
    rows = widget.data.rows

  return [
    {size: rows},
  ]
}

export default (widget) => {
  if (widget.widget_id == 1) {
    return widget1(widget)
  } else if (widget.widget_id == 2) {
    return widget2(widget)
  } else if (widget.widget_id == 3) {
    return widget3(widget)
  } else if (widget.widget_id == 4) {
    return widget4(widget)
  } else if (widget.widget_id == 5) {
    return widget5(widget)
  } else {
    return []
  }
}