var statuses = {
    active: {
        p: `this user is deactivated, do you want to activate him/her?`,
        p2: `If you activate him/her, he/she will be able to log in again.`,
        yes: "activate",
        no: "no",
        storeAction: 'saveUserPersonalChanging',
        status: 'active'
    },
    inactive: {
        p: `this user is in active mode, do you want to deactivate him/her?`,
        p2: `If you deactivate him/her, he/she will not be able to log in again.`,
        yes: "deactivate",
        no: "no",
        storeAction: 'saveUserPersonalChanging',
        status: 'inactive'
    },
    verified: {
        p: `This user has been verified.`,
        p2: `this means he/she has have filled out their credentials but have not yet signed in.`,
        yes: "Close",
        no: "no"
    },
    unverified: {
        p: `This user is unverified.`,
        p2: `This means that he/she did not fill out his/her credentials.`,
        yes: "Resend Registration Email",
        no: "Close",
        storeAction: 'resendRegistrationEmail',
        status: 'unverified'
    },
    suspended: {
        p: `This user is suspended.`,
        p2: `This means that there are more than 3 failed login attempts for his account.`,
        yes: "unsuspend",
        storeAction: 'suspended',
        status: 'suspended'
    }
}
export default statuses;