export default {
  async getUser(id) {
    let { data } = await this.$api.get(`user/${id}/info`);
    return data;
  },
  async submitChangePassword(password) {
    let { data } = await this.$api.patch(`/user/setPassword`, password);
    return data;
  },
  async updateUser(id, user) {
    let { data } = await this.$api.patch(`user/${id}/update`, user);
    return data;
  },
  async resendRegisterEmail(id) {
    let { data } = await this.$api.patch(`user/resend_registration_email/${id}`);
    return data;
  },
  async unsuspend(id) {
    let { data } = this.$api.patch(`user/unsuspend/${id}`);
    return data;
  }
};
