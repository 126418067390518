import SwamService from '@/pages/SWAM/services/swam.service';

export default {
    namespaced:true,
    state:() => ({
        loading:false,
        administrative:[
            {key:'communities',value:'-'},
            {key:'alarmsNotAcknowledged',value:'-'},
            {key:'maintenanceOrders',value:'-'},
            {key:'workOrders',value:'-'},
        ],
    }),
    getters: {
        loading(state) {
            return state.loading;
        },
        getData(state) {
            return state.administrative;
        }
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setData(state,data) {
            let result = [];
            
            result.push({key:'communities',value:data.communities});
            result.push({key:'alarmsNotAcknowledged',value:data.unAckAlarms});
            result.push({key:'maintenanceOrders',value:data.maintenanceOrders});
            result.push({key:'workOrders',value:data.workOrders});
           
            state.administrative = result;
        }, 
    },
    actions: {
        async fetchData({state,commit}) {
            commit('setLoading',true);
            try {
                let params = {
                    interval:'day',
                };
                const data = await SwamService.getStatistics(params);
                commit('setData',data.administrative);
                commit("setLoading", false);
            } catch (error) {
                commit("setLoading", false);
                console.error(error);
            }
        },
        async fetchDataByStructure({state,commit},payload) {
            commit('setLoading',true);
            try {
                let params = {
                    interval:'day',
                    id:payload,
                };
                const data = await SwamService.getStatistics(params);
                commit('setData',data.administrative);
                commit("setLoading", false);
            } catch (error) {
                commit("setLoading", false);
                console.error(error);
            }
        },
        reset({state}) {
            state.administrative=[
                {key:'communities',value:'-'},
                {key:'alarmsNotAcknowledged',value:'-'},
                {key:'maintenanceOrders',value:'-'},
                {key:'workOrders',value:'-'},
            ];
        }
    },
}