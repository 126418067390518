import SwamService from './swam.service';

export default {
    list(query) {
      return SwamService.getStructuresData(query);
    },
    async find({id,query}) {
      throw Error("Not Implemented yet")
    }
  };
  