import ActionsTypes from "../../types/actions-types";
import MutationsTypes from "../../types/mutations-type";
import {
  IntrusionActionsOnAlarmsService,
  IntrusionVillasListService
} from "../../../services/intrusion-system-services.bundle";

export const onAlarmsActions = {
  [ActionsTypes.BY_MORO_ACKNOWLEDGE_ALARM_ACTION]: (
    { commit, dispatch },
    data
  ) => {
    let comment, alarm, ackType;
    if(!data.alarm){
      comment = "ACKNOWLEDGEMENT";
      alarm = data;
      ackType = "intrusion"
    }else{
      alarm = data.alarm;
      comment = data.comment;
      ackType = "operational"
    }
    const service = new IntrusionActionsOnAlarmsService();
    service
      .byMoroAcknowledgeAlarm(alarm.id, comment)
      .then(reply => {
        if (reply != null) {
          dispatch(ActionsTypes.OPEN_ACKNOWLEDGEMENT_MODAL_ACTION, {
            ...alarm,
            closeOrOpen: ackType == "intrusion"
          });
        }
      })
      .catch(console.error);
  },
  [ActionsTypes.BY_POLICE_ACKNOWLEDGE_ALARM_ACTION]: (
    { commit, dispatch },
    { alarm, data }
  ) => {
    if (!alarm || !data) return console.error("missing required data");
    const service = new IntrusionActionsOnAlarmsService();
    service
      .byPoliceAcknowledgeAlarm(alarm.id, data)
      .then(reply => {
        dispatch(ActionsTypes.OPEN_ACKNOWLEDGEMENT_MODAL_ACTION, {
          ...alarm,
          closeOrOpen: false
        });
      })
      .catch(console.error);
  },
  [ActionsTypes.VERIFY_ALARM_ACTION]: ({ commit, dispatch }, payload) => {
    const { data, alarm } = payload;
    if (!alarm || !data) return console.error("missing required data");
    const service = new IntrusionActionsOnAlarmsService();
    service
      .verifyAlarm(alarm.id, data)
      .then(reply => {
        dispatch(ActionsTypes.OPEN_ACKNOWLEDGEMENT_MODAL_ACTION, {
          ...alarm,
          closeOrOpen: false
        });
      })
      .catch(console.error);
  },
  [ActionsTypes.TERMINATE_ALARM_ACTION]: async (
    { },
    alarmToTerminate
  ) => {
    const comment = "TERMINATE"; //alarmToTerminate.comment;
    const alarmId = alarmToTerminate.id;
    const service = new IntrusionActionsOnAlarmsService();
    return await service.terminateAlarm(alarmId, comment);
  },
  [ActionsTypes.COMMENT_ON_ALARM_ACTION]: ({ commit }, data) => {
    const { comment } = data;

    const service = new IntrusionActionsOnAlarmsService();
    service
      .commentOnAlarm(comment)
      .then(reply => {
        // TODO
      })
      .catch(console.error);
  },

  async [ActionsTypes.RE_OPEN_ACK_MODAL_ACTION]({ commit }, alarm) {
    const customerId = alarm.customer ? alarm.customer.id : undefined;
    const service = new IntrusionVillasListService();
    const reply = await service.getCustomerContacts(customerId);
    if (reply != null) {
      const contacts = reply.data.map(c=>({
        email: c.email,
        id: c.id,
        phone: c.phone,
        lastName: c.last_name,
        firstName: c.first_name,
      }))
      commit(MutationsTypes.CUSTOMER_CONTACTS_LIST_MUTATION, contacts);
      commit(MutationsTypes.OPEN_ACKNOWLEDGEMENT_MODAL_MUTATION, true);
    }
  },

  [ActionsTypes.LISTEN_TO_ACKNOWLEDGE_ALARM_ACTION]: (
    { commit, rootState },
    data
  ) => {
    try {
      data.rootUserId = rootState.user.id;
    } catch (e) {}
    commit(MutationsTypes.LISTEN_TO_ACKNOWLEDGE_ALARM_MUTATION, data);
  },
  [ActionsTypes.LISTEN_TO_VERIFY_ALARM_EVENT_ACTION]: (
    { commit, rootState },
    data
  ) => {
    try {
      data.rootUserId = rootState.user.id;
    } catch (e) {}
    commit(MutationsTypes.LISTEN_TO_VERIFY_ALARM_EVENT_MUTATION, data);
  },
  [ActionsTypes.NEW_ALARM_RECEIVED_FROM_SERVER_ACTION]: ({ commit }, data) => {
    commit(MutationsTypes.NEW_ALARM_RECEIVED_FROM_SERVER_MUTATION, data);
  },
  [ActionsTypes.INTRUSION_ALARMS_CURRENT_FILTER]: ({ commit }, data) => {
    commit(MutationsTypes.NEW_ALARM_RECEIVED_FROM_SERVER_MUTATION, data);
  },
  [ActionsTypes.LISTEN_TO_TERMINATE_ALARM_EVENT_ACTION]: (
    { commit, rootState },
    data
  ) => {
    try {
      data.rootUserId = rootState.user.id;
    } catch (e) {}
    commit(MutationsTypes.LISTEN_TO_TERMINATE_ALARM_EVENT_MUTATION, data);
  }
};
