<template>
  <SidebarItem
    :link="{
      name: 'demos pages',
    }"
  >
    <SidebarItem :link="{
        name: 'test',
        path: '/demos/test'
    }"></SidebarItem>
    <SidebarItem :link="{
        name: 'cloud orbit',
        path: '/demos/cloud-orbit'
    }"></SidebarItem>
  </SidebarItem>
</template>

<script>
import SidebarItem from "../components/SidebarPlugin/SidebarItem.vue";
export default {
  components: {
    SidebarItem,
  }
};
</script>

<style>
</style>