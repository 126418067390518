<template>
  <div>
    <base-map :lat="25.276987" :lng="55.296249" :zoom="10">
      <base-map-marker :lat="25.276987" :lng="55.296249" />
      <base-heatmap :heatmapData="heatmapCoordinates" :radius="60"/>
    </base-map>
  </div>
</template>
<script>
import MapMarker from "./MapMarker";
import Map from "./Map";
import Heatmap from "./HeatMap";

export default {
  data() {
    return {
      heatmapCoordinates: [
        { lat: 25.235052, lng: 55.511372, weight: 4 },
        { lat: 25.242636, lng: 55.275191, weight: 488 },
        { lat: 25.201643, lng: 55.245257, weight: 40 },
        { lat: 25.204849, lng: 55.270783, weight: 102 }
      ]
    };
  },
  components: {
    [MapMarker.name]: MapMarker,
    [Map.name]: Map,
    [Heatmap.name]: Heatmap
  }
};
</script>