import AuthLayout from '../Auth/AuthLayout.vue';
import medical from "./medical.vue";
export default {
    path: '/start_ups',
    component: AuthLayout,
    name: 'Start Ups',
    children: [
      {
        path: '/medical',
        name: 'set medical info',
        component: medical
      }
    ]
  };
