import MutationsTypes from './types/mutations-type'
import GettersTypes from './types/getter-types';
export const mutations = {
  /**
   * @param commit
   * @param getters
   * @param {Object} data
   * @type array
   * @desc save new change action api
   * @author karam mustafa
   * @return mixed
   *
   * */
  [MutationsTypes.CHANGE_SENSOR_COLOR]: ({state}, {getters , data}) => {
    getters[GettersTypes.SET_SENSORS_COLORS](data , function (item) {
      return data.color;
    });
  },
  /**
   * @param commit
   * @param getters
   * @param rootState
   * @param {Object} data
   * @type array
   * @desc set new sensor color after we modified
   * @author karam mustafa
   * @return mixed
   *
   * */
  [MutationsTypes.RESET_SENSOR_COLOR]: ({state}, {getters , rootState, data}) => {
    getters[GettersTypes.SET_SENSORS_COLORS](data , function (item) {
      return rootState.devicesstore.selected_device.sensors.find(item => item.id === data.sensorId).color
    });
  },
};
