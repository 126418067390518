import helper from "../../../../util/helper.js";

export default {
    namespaced:true,
    state: {
        center: { lat: 25.1525217, lng: 55.29445630000001 },
        mapType: 1, // (1: devices), (2: alarms)
        selectedStructure: { solutions: [] },
    },
    getters: {
        singleStructureCardData(state) {
            const data = {
              title: {
                icon: require("@/assets/Icons/structure.svg"),
                text1: state.selectedStructure.structure_name,
                onClick: (state.selectedStructure || {}).structure_name
                  ? undefined
                  : `SWAM/Structure-Portfolio/${state.selectedStructure.id}`,
              },
              rows: [
                {
                  icon: require("@/assets/Icons/id.svg"),
                  key: "ID",
                  value: state.selectedStructure.id,
                },
                {
                  icon: require("@/assets/Icons/communities.svg"),
                  key: "City",
                  value: state.selectedStructure.city,
                },
                {
                  icon: require("@/assets/Icons/communities.svg"),
                  key: "Community",
                  value: state.selectedStructure.community_name,
                },
                {
                  icon: require("@/assets/Icons/floors.svg"),
                  key: "Floors",
                  value: state.selectedStructure.floors,
                },
              ],
            };
            state.selectedStructure.solutions.forEach((sol) => {
              data.rows.push({
                value: sol.devices,
                key: sol.name,
                icon: require(`@/assets/icons-2/solutions-icons/${sol.name.toLowerCase()}.svg`),
              });
            });
            return data;
        },
    },
    mutations: {
        setCenter(state,value) {
            state.center = value;
        },
        setMapType(state,value) {
            state.mapType = value;
        },
        setStructure(state,value) {
            state.selectedStructure = value;
        }
    },
}