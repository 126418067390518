const log = console.log;

export class MoroIoTSocketEmitter {
  constructor(parentSocket, namespace = "") {
    this.namespace = namespace;
    this[`listeners_${this.namespace}`] = new Map();
    this.parentSocket = parentSocket;
    this.socketConnectionInterval = null;
  }

  /**
   * register new event listener with vuejs component instance
   * @param event
   * @param callback
   * @param component
   */
  addListener(event, callback, component) {
    if (component.$options.name == undefined || component.$options.name == "") {
      throw new Error("component name must be provided to connect to the socket.");
    }
    if (typeof callback === "function") {
      const oldListenersCount = this[`listeners_${this.namespace}`].size;
      if (!this[`listeners_${this.namespace}`].has(event)) this[`listeners_${this.namespace}`].set(event, []);
      this[`listeners_${this.namespace}`].get(event).push({ callback, component });
      log(`#${event} subscribe, component: ${component.$options.name}`);
      if (oldListenersCount == 0) {
        this.parentSocket.wakeUp();
      }
    } else {
      throw new Error(`callback must be a function`);
    }
  }

  /**
   * remove a listenler
   * @param event
   * @param component
   */
  removeListener(event, component) {
    if (this[`listeners_${this.namespace}`].has(event)) {
      const listeners = this[`listeners_${this.namespace}`]
        .get(event)
        .filter(listener => listener.component !== component);

      if (listeners.length > 0) {
        this[`listeners_${this.namespace}`].set(event, listeners);
      } else {
        this[`listeners_${this.namespace}`].delete(event);
      }
      log(`#${event} unsubscribe, component: ${component.$options.name}`);
      const newListenersCount = this[`listeners_${this.namespace}`].size;
      if (newListenersCount == 0) {
        this.parentSocket.sleep();
      }
    }
  }

  /**
   * broadcast incoming event to components
   * @param event
   * @param args
   */
  emit(event, args) {
    // log("for namespace: ", this.namespace);
    // log("this.listeners ---------------------------------: ", this[`listeners_${this.namespace}`]);
    // log(
    //   "........................................................................."
    // );
    if (this[`listeners_${this.namespace}`].has(event)) {
      if (typeof args == "object")
        log(`Broadcasting: #${event}, Data: ${JSON.stringify(args)}`);
      else
        log(`Broadcasting: #${event}, Data: ${args}`);
      this[`listeners_${this.namespace}`].get(event).forEach(listener => {
        listener.callback.call(listener.component, args);
      });
    }

    if (event !== "ping" && event !== "pong") {
      log(
        `# an event with value of '''<<<${event}>>>''' will be emitted ---------------------------------`
        , args);
    }
  }
}
