<template>
  <card v-loading="loading" class="full-width">
    <!-- Title -->
    <template slot="header">
      <div class="d-flex justify-content-between">
        <h3>{{ $t("widgets.deviceComparison") }}</h3>
        <slot name="header-right">
          <base-select
            v-if="!hidePeriodSelector"
            :light="true"
            :options="periodsOptions"
            v-model="currentPeriodIndex"
          />
        </slot>
      </div>
    </template>
    <!-- Filters -->
    <div class="row align-items-start">
      <!-- First Row -->
<!-- First Structure  -->
      <div class="col-md-6 d-flex align-items-end justify-content-between">
          <base-select
            :label="$tc('structureWords.structure', 2) + ': '"
            :options="structureOptions"
            v-model="currentFirstStructure"
            class="col-6 px-1"
          />
          <base-select
            :options="structureOptions"
            v-model="currentSecondStructure"
            class="col-6 px-1"
          />
      </div>
      <!-- Solution -->
      <div class="col-md-4">
          <base-select
            :label=" $tc('solutionWords.solution', 2) + ': '"
            :options="solutionOptions"
            v-model="currentSolutionId"
          />
      </div>
       <!-- First Device -->
      <div
        class="col-md-6 d-flex align-items-end justify-content-between"
      >
        <base-select
          :label="$tc('deviceWords.device', 2) + ':'"
            :options="firstDevicesOptions"
            v-model="currentFirstDevice"
            class="col-6 px-1"
          />
          <base-select
            class="col-6 px-1"
            :options="secondDevicesOptions"
            v-model="currentSecondDevice"
          />
      </div>
      <!-- Sensor  -->
      <div class="col-md-4">
          <base-select
          :label="$tc('sensorWords.sensor', 2) + ': '"
          :options="sensorOptions"
          v-model="currentSensorId"
        />
      </div>
      
     
      
    </div>

    <comparisonView
      :performanceData="performanceData"
      :gaugeChartData="gaugeChartData"
      :sensorId="currentSensorId"
      :firstSide="firstDeviceMeasurements"
      :secondSide="secondDeviceMeasurements"
      :dates="measurementDates"
      :chartType="selectedChartType"
      @chartTypeChange="sendValidData()"
      ref="comp-view"
      :isDarkMode="getAppliedTheme"
    />
  </card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import comparisonView from "../../components/comparison-view";
import { getDeep } from "@/util/helper";
export default {
  components: {
    comparisonView
  },
  props: {
    namespace:{
      type:String,
      default: "SwamStore/StructurePortfolio"
      // required:true
    },
    data: {
      type: Object,
      default: ()=> ({}),
      //description: 'solutionI, firstStructure, secondStructure, firstDevice, secondDeivce'
    },
    disableDataFetch: {
      type: Boolean,
      default: false,
    },
    moduleName: {
      type: String,
      default: 'DeviceComparison',
    },
    hidePeriodSelector: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    console.log(this.firstDeviceMeasurements)
    console.log(this.secondDeviceMeasurements)
  },
  data() {
    return {
      selectedChartType: 'area',
      canUpdateInFirstDevice: true,
    }
  },
  computed: {
    storePath() {
      return this.namespace + '/' + this.moduleName;
    },
    ...mapState({
      loading(state, getters) {
        return getters[this.storePath + "/loading"];
      },
      periodsOptions(state, getters) {
        return getters[this.storePath + "/periodsOptions"];
      },
      getAppliedTheme(state, getters) {
        return getters.isDarkMode;
      },
      firstDevicesOptions(state, getters) {
        return getters[
          this.storePath + "/firstDevicesOptions"
        ];
      },
      secondDevicesOptions(state, getters) {
        return getters[
          this.storePath + "/secondDevicesOptions"
        ];
      },
      sensorOptions(state, getters) {
        return getters[this.storePath + "/sensorOptions"];
      },
      performanceData(state, getters) {
        return getters[this.storePath + "/performanceData"];
      },
      gaugeChartData(state, getters) {
        return getters[this.storePath + "/gaugeChartData"];
      },
      firstDevices(state, getters) {
        return getDeep(
          state,
          this.storePath + "/firstDevices"
        );
      },
      secondDevices(state, getters) {
        return getDeep(
          state,
          this.storePath + "/secondDevices"
        );
      },
      firstDeviceMeasurements(state, getters) {
        return getters[
          this.storePath + "/firstDeviceMeasurements"
        ];
      },
      secondDeviceMeasurements(state, getters) {
        return getters[
          this.storePath + "/secondDeviceMeasurements"
        ];
      },
      measurementDates(state, getters) {
        return [getters[this.storePath + "/measurementDates"]];
      }
    }),
    ...mapGetters("SwamStore", {
      structures: "haveDevices",
      allSolutionsOptions: "solutionsOptions",
      allSolutions: "solutions",
      structuresForSolution: "structuresForSolution",
    }),
       overallSolutions() {
      return this.allSolutionsOptions.filter(s => s.value);
    },
    solutionOptions() {
      let result = this.overallSolutions;
      
      return result;
    },
    structureOptions() {
      let structures = this.structuresForSolution(this.currentSolutionId);
      let result = [];
      structures.forEach(el => {
        result.push({ label: el.structure_name, value: el.id });
      });
      return result;
    },
    currentSolutionId: {
      get() {
        return getDeep(
          this.$store.state,
          this.storePath + "/currentSolutionId"
        );
      },
      set(value) {
        this.$store.commit(
          this.storePath + "/selectSolution",
          +value
        );
      }
    },
    currentSensorId: {
      get() {
        return getDeep(
          this.$store.state,
          this.storePath + "/currentSensorId"
        );
      },
      set(value) {
        this.$store.commit(
          this.storePath + "/selectSensor",
          value
        );
      }
    },
    currentPeriodIndex: {
      get() {
        return getDeep(
          this.$store.state,
          this.storePath + "/currentPeriodIndex"
        );
      },
      set(value) {
        this.$store.commit(
          this.storePath + "/selectPeriod",
          value
        );
      }
    },
    currentFirstStructure: {
      get() {
        return getDeep(
          this.$store.state,
          this.storePath + "/currentFirstStructure"
        );
      },
      set(value) {
        this.$store.commit(
          this.storePath + "/selectFirstStructure",
          value
        );
      }
    },
    currentSecondStructure: {
      get() {
        return getDeep(
          this.$store.state,
          this.storePath + "/currentSecondStructure"
        );
      },
      set(value) {
        this.$store.commit(
          this.storePath + "/selectSecondStructure",
          value
        );
      }
    },
    currentFirstDevice: {
      get() {
        return getDeep(
          this.$store.state,
          this.storePath + "/currentFirstDevice"
        );
      },
      set(value) {
        this.$store.commit(
          this.storePath + "/selectFirstDevice",
          value
        );
      }
    },
    currentSecondDevice: {
      get() {
        return getDeep(
          this.$store.state,
          this.storePath + "/currentSecondDevice"
        );
      },
      set(value) {
        this.$store.commit(
          this.storePath + "/selectSecondDevice",
          value
        );
      }
    }
  },
  methods: {
    ...mapActions({
      fetchDevices(dispatch, payload) {
        return dispatch(
          this.storePath + "/fetchDevices",
          payload
        );
      },
      fetchData(dispatch, payload) {
        return dispatch(
          this.storePath + "/fetchData",
          payload
        );
      }
    }),
    ...mapMutations({
      selectCustomPeriod(commit, payload) {
        return commit(
          this.storePath + "/selectCustomPeriod",
          payload
        );
      },
      selectSolution(commit, payload) {
        return commit(
          this.storePath + "/selectSolution",
          payload
        );
      },
      selectSensor(commit, payload) {
        return commit(
          this.storePath + "/selectSensor",
          payload
        );
      },
      selectFirstStructure(commit, payload) {
        return commit(
          this.storePath + "/selectFirstStructure",
          payload
        );
      },
      selectSecondStructure(commit, payload) {
        return commit(
          this.storePath + "/selectSecondStructure",
          payload
        );
      },
      setFirstDevices(commit, payload) {
        return commit(
          this.storePath + "/setFirstDevices",
          payload
        );
      },
      setSecondDevices(commit, payload) {
        return commit(
          this.storePath + "/setSecondDevices",
          payload
        );
      },
      selectFirstDevice(commit, payload) {
        return commit(
          this.storePath + "/selectFirstDevice",
          payload
        );
      },
      selectSecondDevice(commit, payload) {
        return commit(
          this.storePath + "/selectSecondDevice",
          payload
        );
      }
    }),
    customPeriodCreated(data) {
      this.selectCustomPeriod(data);
      if (!data[0].from) {
        this.temporarilySelectedPeriod = this.currentPeriodIndex;
      } else {
        this.currentPeriodIndex = this.temporarilySelectedPeriod;
      }
      this.CustomPeriodDialog = false;
    },
    dialogClose() {
      this.temporarilySelectedPeriod = this.currentPeriodIndex;
      this.CustomPeriodDialog = false;
    },
    updateData() {
      if(!this.disableDataFetch) {
        this.fetchData();
      }
      this.sendValidData();
    },
    sendValidData() {
      if(this.currentFirstDevice && this.currentSecondDevice && this.currentSensorId) {
        this.$emit('valid', {
          ...this.extractConfig()
        });
      }
    },
    extractConfig() {
      if(this.currentFirstDevice && this.currentSecondDevice && this.currentSensorId) {
         return {
          ...this.$refs['comp-view'].extractConfig(),
          solution_id: this.currentSolutionId,
          structure1_id: this.currentFirstStructure,
          structure2_id: this.currentSecondStructure,
          device1_id: this.currentFirstDevice,
          device2_id: this.currentSecondDevice,
          sensor_id: this.currentSensorId,
        }
      }
    },
  },
  mounted() {
    if (this.data) {
      if(this.data.chart_type) {
        this.selectedChartType = this.data.chart_type;
      }
      if (this.data.solution_id) {
        this.currentSolutionId = this.data.solution_id;
      } else {
        this.currentSolutionId = this.solutionOptions[0].value;
        if (this.data.structure1_id) {
          this.currentFirstStructure = this.data.structure1_id;
        }
        if (this.data.structure2_id) {
          this.currentSecondStructure = this.data.structure2_id;
        }
      }
      if (this.data.device1_id) {
        this.currentFirstDevice = this.data.device1_id;
      }
      if (this.data.device2_id) {
        this.currentSecondDevice = this.data.device2_id;
      }
      if (this.data.sensor_id) {
        this.currentSensorId = this.data.sensor_id;
      }
      if(this.data.period) {
          let p = this.data.period;
          switch(p) {
            case 'week':
              this.currentPeriodIndex = 2;
              break;
            case 'month':
              this.currentPeriodIndex = 4;
              break;
            case 'quarter': 
              this.currentPeriodIndex = 6;
              break;
            case 'year': 
              this.currentPeriodIndex = 7;
              break;
          }
      }
    } else {
      this.currentSolutionId = this.solutionOptions[0].value;
    }
    
  },
  watch: {
    data: {
      handler() {
        if (this.data.solution_id) {
          this.currentSolutionId = this.data.solution_id;
        } else {
          if (this.data.structure1_id) {
            this.currentFirstStructure = this.data.structure1_id;
          }
          if (this.data.structure2_id) {
            this.currentSecondStructure = this.data.structure2_id;
          }
        }
        if (this.data.device1_id) {
          this.currentFirstDevice = this.data.device1_id;
        }
        if (this.data.device2_id) {
          this.currentSecondDevice = this.data.device2_id;
        }
        if (this.data.sensor_id) {
          this.currentSensorId = this.data.sensor_id;
        }
        if(this.data.period) {
          let p = this.data.period;
          switch(p) {
            case 'week':
              this.currentPeriodIndex = 2;
              break;
            case 'month':
              this.currentPeriodIndex = 4;
              break;
            case 'quarter': 
              this.currentPeriodIndex = 6;
              break;
            case 'year': 
              this.currentPeriodIndex = 7;
              break;
          }
        }
      },
      deep: true
    },
    currentSolutionId: {
      async handler() {
        let firstStructureOld = this.currentFirstStructure;
        let secondStructureOld = this.currentSecondStructure;
        if(this.data && this.data.solution_id && this.data.solution_id == this.currentSolutionId) {
          if(this.data.structure1_id) {
            this.currentFirstStructure = this.data.structure1_id;
          } else {
            this.currentFirstStructure = this.structureOptions[0].value;
          }
          if (this.data.structure2_id) {
            this.currentSecondStructure = this.data.structure2_id;
          } else {
            this.currentSecondStructure = this.structureOptions[0].value;
          }
        } else {
          this.currentFirstStructure = this.structureOptions[0].value;
          this.currentSecondStructure = this.structureOptions[0].value;
        }
        if(this.currentFirstStructure == firstStructureOld) {
          await this.fetchDevices('first');
          if(this.currentFirstDevice != this.firstDevicesOptions[0].value) {
            this.canUpdateInFirstDevice = false;
          }
          this.currentFirstDevice = this.firstDevicesOptions[0].value;
        }
        if(this.currentSecondStructure == secondStructureOld) {
          await this.fetchDevices('second');
          this.currentSecondDevice = this.secondDevicesOptions[0].value;
        }
      }
    },
    async currentFirstStructure() {
      await this.fetchDevices("first");
      if (
        this.data &&
        this.data.solution_id &&
        this.data.solution_id == this.currentSolutionId &&
        this.data.structure1_id &&
        this.data.structure1_id == this.currentFirstStructure
      ) {
        if (this.data.device1_id) {
          this.currentFirstDevice = this.data.device1_id;
        } else {
          this.currentFirstDevice = this.firstDevicesOptions[0].value;
        }
      } else {
        this.currentFirstDevice = this.firstDevicesOptions[0].value;
      }
    },
    async currentSecondStructure() {
      await this.fetchDevices("second");
      if (
        this.data &&
        this.data.solution_id &&
        this.data.solution_id == this.currentSolutionId &&
        this.data.structure2_id &&
        this.data.structure2_id == this.currentSecondStructure
      ) {
        if (this.data.device2_id) {
          this.currentSecondDevice = this.data.device2_id;
        } else {
          this.currentSecondDevice = this.secondDevicesOptions[0].value;
        }
      } else {
        this.currentSecondDevice = this.secondDevicesOptions[0].value;
      }
    },
    disableDataFetch() {
      this.updateData();
    },
    currentPeriodIndex() {
      this.updateData();
    },
    currentFirstDevice() {
      if(!this.canUpdateInFirstDevice) {
        this.canUpdateInFirstDevice = true;
        return;
      }
      let oldSensor = this.currentSensorId;
      if(this.sensorOptions.length) {
        let newSensor = this.sensorOptions[0].value;
        if(oldSensor == newSensor) {
          this.updateData();
        } else {
          this.currentSensorId = newSensor;
        }
      }
    },
    currentSecondDevice() {
      let oldSensor = this.currentSensorId;
      if(this.sensorOptions.length) {
        let newSensor = this.sensorOptions[0].value;
        if(oldSensor == newSensor) {
          this.updateData();
        } else {
          this.currentSensorId = newSensor;
        }
      }
    },
    currentSensorId() {
      this.updateData();
    },
  },
}
</script>

<style></style>
