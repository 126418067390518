<template>
  <div
    class="col-12 d-flex justify-content-center justify-content-sm-between align-items-center flex-wrap"
  >
    <div>
      <p class="card-category mb-4 font-weight-normal font-size-14 description-text">
        {{ $t('showingFromTo', [from, to, total]) }}
      </p>
    </div>
    
    <base-select :options="perPageOptions" :placeholder="'placeholder'" v-model="perPage">
    </base-select>
    <base-pagination
      class="pagination-no-border"
      v-model="currentPage"
      :per-page="perPage"
      :total="total"
    ></base-pagination>
  </div>
</template>

<script>
import BaseSelect from "@/components/Inputs/BaseSelect.vue"
export default {
    props:{
        total:{
            default:0
        },
        initialPerPage:{
          default: 10
        }
    },
  data() {
    return {
      perPage: 10,
      perPageOptions: [{value: 5, label: '5'}, { value:10, label: '10'}, {value: 25, label: '25'}, { value:50, label: '50'}],
      currentPage: 1,
    };
  },
  created(){
    this.perPage = this.initialPerPage;
  },
  computed: {
    offset() {
      return this.perPage * (this.currentPage - 1);
    },
    from() {
        return this.offset + 1;
    },
    to() {
        const temp = this.perPage * this.currentPage;
        if(this.total < temp){
            return this.total;
        }
        return temp;
    },
  },
  methods:{
      emit(){
          this.$emit('change',{limit:this.perPage,offset:this.offset});
      },
      goTo(limit,offset){
        this.perPage = limit;
        this.currentPage = (offset/limit)+1;
      }
  },
  watch:{
      perPage:{
          handler(){
              this.emit();
          }
      },
      currentPage:{
          handler(){
              this.emit();
          }
      }
  },
  components: {
    [BaseSelect.name]: BaseSelect
  }
};
</script>

<style scoped>
.description-text {
  color: #707070;
}
</style>
