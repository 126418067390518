<template>
  <form @submit.prevent="submit">
    <card class="card-login card-white mb-0">
      <template slot="header">
        <h4 class="card-title">{{ $t('account.forgotPassword') }} </h4>
      </template>

      <div class="row justify-content-center">
        <div class="col-12 input" :class="{'text-right': isRTL}" dir="ltr">
          <label for="email" id="email-label">{{ $t('instructions.enterYourEmail') }}</label>
          <base-input
            name="email"
            id="email"
            class="input-email"
            v-model="$v.email.$model"
            :placeholder="$t('email')"
            :addon-left-icon="!isRTL ? 'tim-icons icon-email-85' : ''"
            :addon-right-icon="isRTL ? 'tim-icons icon-email-85' : ''"
          >
            <template v-if="$v.email.$error" v-slot:error>
              <label class="error" v-if="!$v.email.required">{{ $t('email')  + ' ' + $t('validations.isRequired')}}</label>
              <label class="error" v-if="!$v.email.email">{{ $t('email') + ' ' + $t('validations.notValid')}}</label>
            </template>
          </base-input>
        </div>

        <div class="col-12 d-flex justify-content-center">
          <vue-recaptcha @expired="recaptchaExpired" :sitekey="sitekey" @verify="verify"></vue-recaptcha>
          
        </div>
        <template v-if="$v.security.$error">
            <label class="error" v-if="!$v.security.required">{{ $t('validations.recaptcha') + $t('validations.isRequired') }}</label>
          </template>
      </div>

      <div slot="footer">
        <base-button
          native-type="submit"
          type="success"
          class="mb-3"
          size="lg"
          block
        >{{ $t('instructions.sendResetLink') }}</base-button>
        <div class="pull-left">
          <h6>
            <a class="link footer-link" href="#" @click="back">{{ $t('instructions.back') }}</a>
          </h6>
        </div>

        <!-- <div class="pull-right">
          <h6>
            <a href="#" class="link footer-link">Need Help?</a>
          </h6>
        </div>-->
      </div>
    </card>
  </form>
</template>
<script>
import { required, requiredIf, email } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
export default {
  data() {
    return {
      email: "",
      security: null
    };
  },
  computed: {
    sitekey() {
      return process.env.VUE_APP_ENVI === "production"
        ? process.env.VUE_APP_RECAPTCHA_SITE_KEY
        : process.env.VUE_APP_RECAPTCHA_LOCAL_KEY;
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  methods: {
    recaptchaExpired(){
      console.log("*************** the recaptcha is expired!!!!***********");
      this.security = null;
    },
    back() {
      this.$emit("back");
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    verify(response) {
      this.$api
        .post("/recaptcha", {
          response
        })
        .then(res => {
          console.log(res);
          if (res.data.success) {
            this.security = "success";
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let formData = {
          email: this.email
        };
        this.$store.commit("setSpinner",true);
        this.$api
          .post("auth/reset_password", formData)
          .then(res => {
            let data = res.data;
            // this.status = this.$t("message will be sent");
            this.$notify({
              title: "Check Your Email",
              message: this.$t("message will be sent"),
              type: "success"
            });
            this.back();
          })
          .catch(err => {
            //this.status = this.$t("Please check your credentials again");
            console.log(err);
            // this.$notify({
            //   title: "No Account Was Found",
            //   message: this.$t("Please check your credentials again"),
            //   type: "danger"
            // });
          });
      }
    }
  },
  validations: {
    email: {
      required,
      email
    },
    security: {
      required: requiredIf(function() {
        return process.env.VUE_APP_ENVI === "production";
      })
    }
  },
  components: {
    VueRecaptcha
  }
};
</script>

<style lang="scss" scoped>
::v-deep  .input-email .input-group .form-control:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

::v-deep .input-email .input-group-append .input-group-text  {
    border-color: rgba(29, 64, 91, 0.2);
    color: $black-states;
}

.card-white label { color: $light-secondary; }
</style>
