// import DashboardLayout from "./../Layout/DashboardLayout.vue";
import store from "./../../store/index";
import PermissionsStore from "./Permissions-store";
import PermissionsTable from "./Permissions-Table.vue";
import PermissionsEdit from "./Permissions-Edit.vue";
import { getAll,M } from "./../../Permissions";
import Main from "./Main.vue";


export default {
    path: '/permissions',
    component: Main,
    meta:{
        module:M.rules
    },
    beforeEnter: (to, from, next) => {
        store.registerModule('PermissionsStore',PermissionsStore)
        getAll.then(res => {
            store.commit('setPermissionsModules', res)
            next();
        })
    },
    children:[
        {
            path: '',
            redirect:'rules'
        },
        {
            path: 'groups',
            name: 'Permissions Groups',
            component: PermissionsTable,
        },
        {
            path: 'rules',
            name: 'Rules',
            component: PermissionsTable,
        },
        {
            path: 'rules/:id',
            name: 'Rule',
            component: PermissionsEdit,
        },
        {
            path:'*',
            redirect:'rules'
        }
    ]
}