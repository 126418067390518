<template>
  <div class="row">
    <div class="col-md-12">
      <card card-body-classes="table-full-width ">
        <div slot="header" class="createBu" v-if="canPage(P.create_ticket)">
          <router-link to="/tickets/create">
            <base-button class="animation-on-hover" type="success">
              <i class="fa fa-ticket"></i>
              {{$t('create_new_ticket')}}
            </base-button>
          </router-link>
        </div>

        <!-- <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <el-select
            class="select-primary mb-3 pagination-select"
            v-model="page"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>

          <base-input>
            <el-input
              type="search"
              class="mb-3 search-input"
              clearable
              prefix-icon="el-icon-search"
              placeholder="Search records"
              v-model="searchQuery"
              aria-controls="datatables"
            ></el-input>
          </base-input>
        </div>-->

        <div class="row">
          <div class="col-12">
            <!--        <table class="table table-striped table-recent">
              <thead class="thead-dark">
                <tr>
                  <th scope="col" v-if="false">
                    ORIGIN
                    <i class="ml-2 fa fa-sort iCon-Filter"></i>
                  </th>
                  <th scope="col">
                    TICKET
                    <i class="ml-2 fa fa-sort iCon-Filter"></i>

                  </th>
                  <th scope="col">
                    FROM
                    <i class="ml-2 fa fa-sort iCon-Filter"></i>
                  </th>
                  <th scope="col">
                    TO
                    <i class="ml-2 fa fa-sort iCon-Filter"></i>
                  </th>
                  <th scope="col">
                    STATUS
                    <i class="ml-2 fa fa-sort iCon-Filter"></i>
                  </th>
                  <th scope="col">
                    CREATED AT
                    <i class="ml-2 fa fa-sort iCon-Filter"></i>
                  </th>
                  <th scope="col">
                    LAST ACTION
                    <i class="ml-2 fa fa-sort iCon-Filter"></i>
                  </th>
                  <th scope="col">
                    Details
                    <i class="ml-2 fa fa-sort iCon-Filter"></i>
                  </th>
                </tr>
              </thead>
              <tbody>

              <p class="h5">No tickets</p>
                </tr>
                <template v-for="ticket in ticketsList">
                  <tr :key="ticket.id">
                    <td v-if="false">
                      <span>{{ticket.method}}</span>
                    </td>
                    <td>
                      <span>{{ticket.subject}}</span>
                    </td>
                    <td>

                      <span>{{ticket.fromWho}}</span>
                    </td>
                    <td>
                      <span v-if="ticket.to">{{ticket.to}}</span>

                      <span v-else @click="expand(ticket)" class="my-a">Multiple Receivers</span>
                    </td>
                    <td>
                      <h6 class="text-center">
                        <base-button
                          class="btn my-status animation-on-hover AnsStlye"
                          :type="getType(ticket.status ? ticket.status : ticket.receivers[0].status)"
                          v-if="isOne(ticket)"
                        >{{getStatus(ticket)}}</base-button>
                        <template v-else>--</template>
                      </h6>
                    </td>
                    <td>
                      <span>{{ticket.created_at}}</span>
                    </td>
                    <td>
                      <span>{{ticket.last_action_at? ticket.last_action_at : "...."}}</span>
                    </td>
                    <td>
                      <base-button
                        v-tooltip="$t('View')"
                        class="animation-on-hover but-Multi_res"
                        type="success"
                        v-if="isOne(ticket)"
                        @click="openTicket(ticket)"
                      >{{$t('Details')}}</base-button>

                      <base-button
                        class="animation-on-hover but-Multi_res"
                        type="info"
                        v-else
                        @click="expand(ticket)"
                      >EXPAND</base-button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            --->

            <div id="netxT" class="striped-table">
              <el-table :data="ticketsList">
                <el-table-column
                  min-width="150"
                  sortable
                  align="left"
                  header-align="left"
                  :label="$t('TICKET')"
                  class-name="okay"
                  property="subject"
                ></el-table-column>

                <el-table-column
                  min-width="150"
                  sortable
                  align="left"
                  header-align="left"
                  :label="$t('from')"
                  class-name="okay"
                  property="fromWho"
                >
                  <template slot-scope="{row}">
                    <el-tooltip :disabled="row.iAmOwner">
                      <div slot="content">
                        <span v-if="!row.iAmSender" class="mb-1">
                          <span v-if="row.from.company">Company</span>
                          <span v-else>Account</span>
                          Type: {{row.from.type}}
                        </span>
                        <br />
                        Sent By: {{row.from.email}}
                      </div>

                      <span v-if="row.iAmOwner" class="you">You</span>
                      <span v-else-if="row.iAmSender" class="your-company">Your Company</span>
                      <span v-else>{{row.from.name}}</span>
                    </el-tooltip>
                  </template>
                </el-table-column>

                <el-table-column
                  min-width="150"
                  sortable
                  align="left"
                  header-align="left"
                  :label="$t('TO')"
                  class-name="okay"
                >
                  <template slot-scope="data">
                    <el-tooltip
                      :disabled="data.row.iAmReceiver"
                      v-if="data.row.isOneReceiver || data.row.iAmReceiver"
                    >
                      <div slot="content">
                        <span v-if="data.row.to.company">Company</span>
                        <span v-else>Account</span>
                        <span class="mb-1">Type: {{data.row.to.type}}</span>
                        <br />
                        <!-- admin || super_admin -->
                        <span
                          v-if="!data.row.to.company && data.row.to.email"
                        >Email: {{data.row.to.email}}</span>
                      </div>
                      <!-- i am a sender -->
                      <span v-if="data.row.isOneReceiver">{{data.row.to.name}}</span>
                      <!-- i am a receiver -->
                      <span v-else-if="data.row.to.company" class="your-company">Your Company</span>
                      <span v-else class="you">You</span>
                    </el-tooltip>

                    <span v-else @click="expand(data.row)" class="my-a">Multiple Receivers</span>
                  </template>
                </el-table-column>

                <el-table-column
                  min-width="150"
                  sortable
                  align="left"
                  header-align="left"
                  :label="$t('STATUS')"
                  class-name="okay"
                >
                  <template slot-scope="scope">
                    <base-button
                      class="btn my-status animation-on-hover AnsStlye"
                      v-if="scope.row.isOneReceiver || scope.row.iAmReceiver"
                      :type="getType(scope.row.status)"
                    >{{getStatus(scope.row.status)}}</base-button>
                    <template v-else>--</template>
                  </template>
                </el-table-column>

                <el-table-column
                  min-width="150"
                  sortable
                  align="left"
                  header-align="left"
                  :label="$t('CREATED AT')"
                  class-name="okay"
                  property="created_at"
                ></el-table-column>

                <el-table-column
                  min-width="150"
                  sortable
                  align="left"
                  header-align="left"
                  :label="$t('LAST ACTION')"
                  class-name="okay"
                  property
                >
                  <template slot-scope="scope">
                    <span>{{scope.row.last_action_at? scope.row.last_action_at : "...."}}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  min-width="150"
                  sortable
                  align="left"
                  header-align="left"
                  :label="$t('details')"
                  class-name="okay"
                >
                  <template slot-scope="scope">
                    <el-tooltip :content="$t('View')" v-if="scope.row.isOneReceiver || scope.row.iAmReceiver">
                      <base-button
                        class="animation-on-hover but-Multi_res"
                        type="success"
                        @click="openTicket(scope.row)"
                      >{{$t('details')}}</base-button>
                    </el-tooltip>

                    <base-button
                      class="animation-on-hover but-Multi_res"
                      type="info"
                      v-else
                      @click="expand(scope.row)"
                    >{{$t('EXPAND')}}</base-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <TablePagination
          slot="footer"
          ref="paginator"
          v-if="total"
          :total="total"
          @change="updatePagination"
        ></TablePagination>
      </card>
    </div>
  </div>
</template>

<script>
import { BasePagination } from "src/components";
import { mixin } from "./TicketsHelper";
import TablePagination from "@/components/TablePagination.vue";

export default {
  data: () => {
    return {
      total: 0,
      offset: 0,
      limit: 10,
    };
  },
  mixins: [mixin],
  components: {
    BasePagination,
    TablePagination,
  },
  asyncComputed: {
    ticketsList: {
      get() {
        return this.$store
          .dispatch("getTicketsList", {
            limit: this.limit,
            offset: this.offset,
          })
          .then((res) => {
            this.total = res.count;
            return res.data;
          });
      },
    },
  },

  created() {
    this.$store.commit("setSpinner", true);
  },
  methods: {
    updatePagination(data) {
      this.limit = data.limit;
      this.offset = data.offset;
    },
    getTickets() {},
    expand(ticket) {
      this.$store.commit("openTicket", ticket);
      this.$router.push("/tickets/Receivers");
    },
    openTicket(ticket) {
      this.$store.commit("openTicket", ticket);
      let room;
      if (ticket.iAmSender) {
        room = { ...ticket.receivers[0], ...ticket };
      } else {
        room = ticket;
      }
      this.$store.commit("openedRoom", room);
      this.$router.push(`/tickets/${ticket.room_id}/Messages/`);
    },
  },
};
</script>

<style scoped>
* {
  transition: all 0.2s ease-in;
}
.my-a:hover {
  /* font-size: medium; */
  color: #00c6d9;
  cursor: pointer;
  text-decoration: underline;
}

.createBu {
  margin-bottom: 40px;
}
.table .thead-dark th,
.el-table table .thead-dark th {
  font-size: 16px;
  color: white;
  background-color: transparent;
  border-bottom: 4px solid #a59e9e;
}

.iCon-Filter {
  color: #3ea877;
}
.but-Multi_res {
  width: 93px;
  padding: 7px 14px;
}
.AnsStlye {
  width: 110px;
  padding: 7px 14px;
}

.animation-on-hover {
  cursor: pointer;
}
.footerPag {
  justify-content: flex-end !important;
}
.your-company,
.you {
  font-weight: 600;
  color: var(--lighter);
  text-transform: capitalize;
}
.white-content .you {
  color: #1d253b;
}
</style>