import SwamService from '@/pages/SWAM/services/swam.service';

export default {
    namespaced:true,
    state:{
        loading:false,
        statusAlarms:{
            devicesOff:0,
            lowBattery:0,
            contractsExpiring:0,
        },
        operationalAlarms:{},
        devices: {on: 0, off: 0},
    },
    getters:{
        loading(state) {
            return state.loading;
        },
        statusAlarms(state) {
            return state.statusAlarms;
        },
        operationalAlarms(state) {
            return state.operationalAlarms;
        },
        devices(state) {
            return state.devices;
        },
        devicesOff(state) {
            return state.statusAlarms.devicesOff;
        }
    },
    mutations:{
        setLoading(state,value) {
            state.loading = value;
        },
        setData(state,data) {
            state.statusAlarms = data.statusAlarms;
            state.operationalAlarms = data.operationalAlarms;
            state.devices = data.devices;
        },
    },
    actions:{
        async fetchData({state,commit},payload) {
            if(state.loading) {
                return;
            }
            commit('setLoading',true);
            try {
                let params = {
                    structure_id: payload
                };
                const data = await SwamService.getStructureStatistics(params);
                commit('setData',data);
                commit("setLoading", false);
            } catch (error) {
                commit("setLoading", false);
                console.error(error);
            }
        },
        reset({state}) {
            state.statusAlarms={
                devicesOff:0,
                lowBattery:0,
                contractsExpiring:0,
            };
            state.operationalAlarms={};
            state.devices = {on: 0, off: 0};
        }
    },
}