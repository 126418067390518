<template>
  <div>
    <h1>Style Guide</h1>
    <div class="container color-pallet clearfix">
      <div class="rect bg font-color-1">font-color-1</div>
      <div class="rect bg font-color-2">font-color-2</div>
      <div class="rect bg font-color-3">font-color-3</div>
      <div class="rect bg font-color-4">font-color-4</div>

      <div class="rect black-border background-1">background-1</div>
      <div class="rect black-border background-2">background-2</div>
      <div class="rect black-border background-3">background-3</div>
      <div class="rect black-border background-4">background-4</div>
    </div>

    <ol>
      <li>
        <h2>font weights</h2>
        <ul>
          <li>
            <p class="font-weight-bold text-lowercase">font-weight-bold</p>
          </li>
          <li>
            <p class="text-uppercase">normal font ( without any classes )</p>
          </li>
          <li>
            <p class="font-italic text-lowercase">font-italic</p>
          </li>
        </ul>
      </li>
    </ol>

    <h2
      class="text-lowercase"
    >font sizes using h&lt;n&gt; classes (Use them ONLY when you can't change the tag itself for some reason)</h2>
    <p class="border-white h1 text-lowercase">.h1 class</p>
    <p class="border-white h2 text-lowercase">.h2 class</p>
    <p class="border-white h3 text-lowercase">.h3 class</p>
    <p class="border-white h4 text-lowercase">.h4 class</p>
    <p class="border-white h5 text-lowercase">.h5 class</p>
    <p class="border-white h6 text-lowercase">.h6 class</p>
  </div>
</template>

<script>
export default {};
</script>

<style>
.rect {
  width: 200px;
  height: 150px;
  float: left;
  margin: 20px;
  font-size: 25px;
  padding-top: 45px;
  font-weight: bolder;
  text-transform: lowercase;
  text-align: center;
}

.bg {
  background: #fff;
  border: 10px solid #f8f8f8;
}

.white-content .bg {
  background: #c4c4c4;
}

.black-border {
  border: 10px solid #515151;
}

.border-white {
  border: 4px solid white;
  padding: 10px;
}
</style>