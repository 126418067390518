export default {
  namespaced: true,
  modules: {
  },
  state: {
  },
  getters: {
  },
  mutations: {

  },
  actions: {
  }
}