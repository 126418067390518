import { getDateString, dateFormat } from "../../../util/helper";

class AlarmsTableSingleRow {
  constructor(anObject) {
    this.id = anObject["id"] ? anObject["id"] : null;
    this.alartTime = anObject["created_at"] ? anObject["created_at"]: null;
    this.alarmType = anObject["status"] ? anObject["status"] : null;
    this.area = anObject["device"]
      ? anObject["device"]["community_name_en"]
      : null;
    this.deviceId = anObject["device"] ? anObject["device"]["id"] : 0;

    this.deviceType = anObject["device"] ? anObject["device"]["type"] : null;
    this.deviceChannelId = anObject["device"]&&anObject["device"]["channel_id"] ? anObject["device"]["channel_id"] : null;
    this.isDeviceOff = anObject["device"] && anObject["device"]["off"] ? 'off': 'on';
    this.alarmEmail= anObject["device"] ? anObject["device"]["alarm_email"] : null;
    this.structureName= anObject["device"] ? anObject["device"]["structure_name"] : null;

    this.customerName = anObject["device"]
      ? anObject["device"]["customer_name"]
      : null;

    this.customerId = anObject["device"]
      ? anObject["device"]["customer_id"]
      : null;
    this.operatorAcknowledgementTime = anObject["acknowledgement"]
      ? anObject["acknowledgement"]["created_at"]
        ? anObject["acknowledgement"]["created_at"]
        : null
      : null;
    this.operatorEmail = anObject["acknowledgement"]
      ? anObject["acknowledgement"]["email"]
      : null;
    this.acknowledgement = anObject["acknowledgement"];
    this.device = anObject["device"];
    this.endedAt = anObject["ended_at"];
    this.termination = anObject["termination"];
  }
 
}
export const mapObjectToSingleAlarmRow = obj => new AlarmsTableSingleRow(obj);
export const mapArrayOfObjectsToArrayOfAlarmsSingleRow = data =>
  data.map(obj => new AlarmsTableSingleRow(obj));
