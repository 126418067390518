export default {
    namespaced: true,
    state: {
        downloadingPDF: false
    },
    getters: {
        widgets() {
            return [
                {
                    label: "widgets.structureComparison",
                    value: 1,
                    zoom: "75",
                    imagePath: require('@/assets/images/system-wdigets/structure-comparison.jpg'),
                    data: {
                        widget_id: 1,
                        id: "StructureComparsion",
                        props: {
                            hidePeriodSelector: true,
                        }
                    },
                },
                {
                    label: "widgets.deviceComparison",
                    value: 2,
                    zoom: "75",
                    imagePath: require('@/assets/images/system-wdigets/device-comparison.jpg'),
                    data: {
                        widget_id: 2,
                        id: "DeviceComparison",
                        props: {
                            hidePeriodSelector: true,
                        }
                    },
                },
                {
                    label: "widgets.solutionCard",
                    value: 3,
                    zoom: "90",
                    imagePath: require('@/assets/images/system-wdigets/solution-card.jpg'),
                    data: {
                        widget_id: 3,
                        id: "SolutionStatistics",
                        props: {
                            hidePeriodSelector: true,
                            globalMode: true,
                            fullWidth: true,
                        }
                    },
                    
                },
                {
                    label: "widgets.alarmsCount",
                    value: 4,
                    zoom: "100",
                    imagePath: require('@/assets/images/system-wdigets/alarm-reports.jpg'),
                    data: {
                        widget_id: 4,
                        id: "AlarmsCount",
                        props: {
                            hidePeriodSelector: true,
                        }
                    },
                },
                {
                    label: "widgets.blank",
                    value: 5,
                    zoom: "90",
                    imagePath: require('@/assets/images/system-wdigets/blank.jpg'),
                    data: {
                        widget_id: 5,
                        id: "blank-card",
                    },
                },
                // {
                //     label: "widgets.structuresWithDevicesList",
                //     value: 6,
                //     zoom: "90",
                //     imagePath: require('@/assets/images/system-wdigets/solution-card.jpg'),
                //     data: {
                //         widget_id: 6,
                //         id: "structuresWithDevicesList",
                //         props: {
                //             globalMode: true,
                //         }
                //     },
                // },
                // {
                //     label: "widgets.statistics",
                //     value: 7,
                //     zoom: "90",
                //     imagePath: require('@/assets/images/system-wdigets/solution-card.jpg'),
                //     data: {
                //         widget_id: 7,
                //         id: "Statistics",
                //         props: {
                //             globalMode: true,
                //         }
                //     },
                // },
                // {
                //     label: "widgets.operationalStatuses",
                //     value: 4,
                //     zoom: "100",
                //     imagePath: require('@/assets/images/system-wdigets/alarm-reports.jpg'),
                //     data: {
                //         widget_id: 4,
                //         id: "alarm-reports",
                //     },
                // },
            ]
        },
        widgetsWithStore(){
            return [
                "StructureComparsion",
                "DeviceComparison",
                "Statistics",
                "AlarmsCount",
                "SolutionStatistics",
            ]
        }
    },
    mutations: {
        startDownloadingPDF(state) {
            state.downloadingPDF = true;
        },
        pdfDownloadingFinished(state) {
            state.downloadingPDF = false;
        }
    },
    actions: {
        exportReportToPDF({ state, commit }, { id, name }) {
            if (state.downloadingPDF) {
                this._vm.$notify({
                    title: "busy",
                    message: "waitPrevDownload",
                    type: "warning"
                });
                return;
            }
            commit("startDownloadingPDF");
            this.dispatch("downloadFile", {
                filename: name + ".pdf",
                url: `iot/report/pdf/${id}`,
            }).finally(_ => {
                commit('pdfDownloadingFinished')
            })
        },
        exportCurrentWorkspace({ state, commit }, configs) {
            if (state.downloadingPDF) {
                this._vm.$notify({
                    title: "busy",
                    message: "waitPrevDownload",
                    type: "warning"
                });
                return;
            }
            commit("startDownloadingPDF");
            this.dispatch("downloadFile", {
                filename: configs.name + ".pdf",
                url: 'iot/report/pdf',
                method: 'post',
                data: configs
            })
                .finally(_ => {
                    commit('pdfDownloadingFinished')
                })
        }
    }
}