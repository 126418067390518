import api from "../../axiosWithDelimiterFile";
import axios from "axios";
import { users_types } from "../../store/index"
import { groupBy } from "../../util/helper";

export default {
    state: {
        UserData: null,
        CompanyData: null,
        addressMap: null,
        imageObject: null,
        newUser: null,
        countriesCode: [],
        teltonika_device: false,
        teltonikas: [],
        workingInfo: null,
        medicalInfo: null,
        primaryInfo: null,
        companyZones: null
    },
    getters: {
        UserData(state) {
            return state.UserData;
        },
        CompanyData(state) {
            return state.CompanyData;
        },
        getAddressMap(state) {
            return state.addressMap;
        },
        imageObject(state) {
            return state.imageObject;
        },
        getNewUser(state) {
            return state.newUser;
        },
        teltonika_device(state) {
            return state.teltonika_device;
        },
        teltonikas(state) {
            return state.teltonikas;
        },
        workingInfo(state) {
            return state.workingInfo;
        },
        medicalInfo(state) {
            if (state.medicalInfo) {
                return state.medicalInfo;
            } else {
                return {
                    chronic_diseases: [],
                    sars_diseases: []
                }
            }
        },
        medicalPrimaryInfo(state) {
            if (state.primaryInfo) {
                return state.primaryInfo;
            } else {
                return {
                    sars_type: [],
                    chronic_diseases: []
                }
            }
        },
    },
    mutations: {
        resetProfilesStore(state) {
            state.UserData = null;
            state.CompanyData = null;
            state.addressMap = null;
            state.imageObject = null;
            state.newUser = null;
            state.teltonika_device = false;
            state.teltonikas = [];
            state.workingInfo = null;
            state.medicalInfo = null;
            state.companyZones = null;
        },
        setUserData(state, data) {
            state.UserData = data;
            if (state.CompanyData) {
                const index = state.CompanyData.users.findIndex(u => u.id == state.UserData.id);
                if (index != -1) {
                    state.CompanyData.users[index] = state.UserData;
                }
            }
        },
        updateUserStatus(state, data) {
            state.UserData.status = data;
        },
        setCompanyData(state, data) {
            state.CompanyData = data;
        },
        openAddressMap(state, data) {
            if (data != null) {
                const { lat, lng } = data;
                state.addressMap = { lat: +lat, lng: +lng };
            } else {
                state.addressMap = data;
            }
        },
        openImage(state, data) {
            state.imageObject = data;
            console.log('openImage');
            console.log(data);
        },
        initNewUser(state, data) {
            state.newUser = data;
            if (data && state.CompanyData && !state.CompanyData.callCode) {
                this.dispatch('getCompanyCallCode');
            }
        },
        teltonika_device(state, data) {
            state.teltonika_device = data;
            if (data && !state.UserData.teltonika_device) {
                this.dispatch('getTeltonikaDevices');
            }
        },
        setTeltonikas(state, data) {
            state.teltonikas = data;
        },
        setWorkingInfo(state, data) {
            state.workingInfo = data;
        },
        setMedicalInfo(state, data) {
            state.medicalInfo = data;
        },
        setMedicalPrimaryInfo(state, data) {
            state.primaryInfo = data;
        }
    },
    actions: {
        getUserData({ state, rootState }, id) {
            this.commit('setSpinner', true);
            return api.get(`user/${id}/info`)
                .then(({ data }) => {
                    data.data.tfa_enable = data.data.tfa_enable ? true : false;
                    if (data.data.is_suspended) {
                        data.data.THE_STATUS = data.data.status;
                        data.data.status = 'suspended';
                    }
                    const role = rootState.roles.find(r => r.id == data.data.role_id);
                    if (role) {
                        data.data.slug = role.slug;
                        if (data.data.slug == "paying_customer") {
                            data.data.slug = "customer";
                        }
                    }
                    data.data.isSupervisor = data.data.type == users_types.Supervisor
                    this.commit('setUserData', data.data)
                    return data.data;
                })
                .catch(error => {
                    console.error(error);
                    throw error;
                })
        },
        getCompanyData({ state, rootState }, { type, id }) {
            this.dispatch('fetchCountryCodes');
            const company = rootState.companiesTypes.filter(item => { return item.value == type || item.label == type })[0];
            if (!company) {
                console.error('Company Type Not Found')
                return Promise.reject('Company Type Not Found')
            }
            if (state.CompanyData && state.CompanyData.type == type && state.CompanyData.id == id) {
                state.CompanyData.users.forEach(u => {
                    u.isSupervisor = u.type == users_types.Supervisor;
                    if (u.is_suspended) {
                        // u.THE_STATUS = data.data.status;
                        u.status = 'suspended';
                    }
                })
                state.CompanyData.groupedUsers = groupBy(state.CompanyData.users, 'type');
                this.commit('setCompanyData', { ...state.CompanyData, type: company.value })
                return Promise.resolve(state.CompanyData);
            }
            this.commit('setSpinner', true);
            return api
                .get(`${company.value}/getbyid/${id}`)
                .then(({ data }) => {
                    data.data.users.forEach(u => {
                        u.isSupervisor = u.type == users_types.Supervisor;
                        if (u.is_suspended) {
                            // u.THE_STATUS = data.data.status;
                            u.status = 'suspended';
                        }
                    })
                    data.data.groupedUsers = groupBy(data.data.users, 'type');
                    this.commit('setCompanyData', { ...data.data, type: company.value })
                    return data.data;
                })
                .catch(err => {
                    console.log(err);
                    throw error;
                });
        },
        saveUserPersonalChanging({ state, rootState }, payload) {
            this.commit('setSpinner', true);
            return api.patch(`user/${state.UserData.id}/update`, payload).then(({ data }) => {
                state.UserData.updated_at = new Date();
                if (data.qr) {
                    this.commit('openImage', {
                        img: data.qr, text: [
                            "Your account is protected with OTP, please scan this QR using your phone with any QR Scaner, Like Google Authenticator."
                        ]
                    });
                }
                this.commit('setUserData', { ...state.UserData, ...data });
                if (state.UserData.id == rootState.user.id) {
                    this.commit('updateCurrentUser', { ...rootState.user, ...data });
                }

            })
        },
        deleteUser({ state }, payload) {
            if (state.UserData) {
                if (state.UserData.role_id == 2) {
                    this.commit('setSpinner', true);
                    return api.delete(`people/${state.UserData.id}`).then(res => {
                        if (state.CompanyData) {
                            const index = state.CompanyData.admins.findIndex(u => u.id == state.UserData.id);
                            if (index != -1) {
                                state.CompanyData.admins.splice(
                                    index,
                                    1
                                );
                            }
                        }
                        return res;
                    });
                } else if (state.UserData.role_id >= 3) {
                    this.commit('setSpinner', true);
                    return api.post(`company/remove-user/mark?userId=${state.UserData.id}&companyId=${state.UserData.info.id}`).then(res => {
                        if (state.CompanyData) {
                            const index = state.CompanyData.users.findIndex(u => u.id == state.UserData.id);
                            state.CompanyData.users.splice(
                                index,
                                1
                            );
                        }
                        return res;
                    });
                }
            }
        },
        saveCompanyChanges({ state, rootState }, payload) {
            return api.post(`${state.CompanyData.type}/update/${state.CompanyData.id}`, payload)
                .then(_ => state.CompanyData.updated_at = new Date());
        },
        addEmployee({ state }, payload) {
            console.log(payload);
        },
        addNewUser({ state }, data) {
            data.phone = state.CompanyData.callCode + data.phone;
            this.commit('setSpinner', true);
            return api.post("company/create-user", { ...data, company_id: state.CompanyData.id })
                .then(res => {

                    state.CompanyData.users.push({
                        ...data,
                        id: res.data.data.userId
                    })
                    state.CompanyData.groupedUsers = groupBy(state.CompanyData.users, 'type');
                    this.commit('initNewUser', null);
                    return;
                });
        },

        // code to be refactored
        getCompanyCallCode({ state }) {
            state.CompanyData.callCode = state.countriesCode.find(c => {
                return c.name == state.CompanyData.country;
            });
            if (state.CompanyData.callCode) {
                state.CompanyData.callCode = '+' + state.CompanyData.callCode.callingCodes[0];
            }
        },
        // code to be replaced
        fetchCountryCodes({ state }) {
            if (!state.countriesCode || state.countriesCode.length == 0) {
                axios.get("https://restcountries.eu/rest/v2/all")
                    .then(response => (state.countriesCode = response.data));
            }
        },


        getTeltonikaDevices({ state }) {
            this.commit('setSpinner', true);
            api.get(`teltonika/devices/assignable/${state.UserData.info.id}`)
                .then(({ data }) => {
                    this.commit('setTeltonikas', data.data);
                })
                .catch(console.error);
        },
        getUserWorkingInfo({ state }) {
            this.commit('setSpinner', true);
            return api.get(`people/${state.UserData.id}/employment_info`)
                .then(({ data }) => {
                    this.commit('setWorkingInfo', data.data);
                    return data.data;
                })
        },
        getUserMedicalInfo({ state }, id) {
            this.commit('setSpinner', true);
            if (!state.primaryInfo) {
                this.commit('setSpinner', true);
                api.get('people/info').then(({ data }) => {
                    this.commit('setMedicalPrimaryInfo', data.data)
                })
            }
            return api.get(`people/${id}/medical`)
                .then(({ data }) => {
                    this.commit('setMedicalInfo', data.data);
                    return data.data;
                })
        },
        getZones({ state }) {
            if (state.CompanyData && state.CompanyData.zones) {
                return Promise.resolve(state.CompanyData.zones);
            }
            if (state.companyZones) {
                return Promise.resolve(state.companyZones);
            }
            this.commit("setSpinner", true);
            return api.get(`teltonika/geofence/${state.UserData.info.id}`).then(({ data }) => {
                state.companyZones = data.data;
                return Promise.resolve(state.companyZones);
            }).catch(console.error)
        },
        getDepartments({ state }) {
            if (state.CompanyData) {
                this.commit('setSpinner', true);
                return api.get(`customer/${state.CompanyData.id}/departments`)
                    .then(({ data }) => {
                        return data.data;
                    })
                    .catch(console.error);
            } else {
                throw new Error('No Company Selected!');
            }
        },
        createDepartment({ state }, data2) {
            this.commit("setSpinner", true);
            return api.post(`customer/${state.CompanyData.id}/departments`, data2)
                .then(({ data }) => {
                    return data.data;
                });
        }
    }
}