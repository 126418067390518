import OperationalAlarms from "./pages/Operational-Alarms/store";
import CustomerList from "./pages/Customer-List/store";
import CustomerInfo from './pages/Customer-Info/store';
import OperationalAlarmsHistory from './pages/Operational-Alarms-History/store';
import WorkOrder from './pages/Work-Order/store';

export default {
    namespaced: true,
    modules: {
        OperationalAlarms,
        CustomerList,
        CustomerInfo,
        OperationalAlarmsHistory,
        WorkOrder
    }
}