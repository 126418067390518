import api from "./axiosWithDelimiterFile.js";
import launcher from "./launcher";

import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import DashboardPlugin from "./plugins/dashboard-plugin";
import Vuelidate from "vuelidate";
import AsyncComputed from "vue-async-computed";
import store from "./store";
import App from "./App.vue";
// import zingchart from "zingchart/es6";
// import "zingchart/modules-es6/zingchart-calendar.min.js";
// import zingchartVue from "zingchart-vue";


import Chat from "vue-beautiful-chat";
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files

import "vue-datetime/dist/vue-datetime.css";

//import VueSocketIOExt from 'vue-socket.io-extended';

// Vue.use(VueSocketIOExt, socket);


// defined ABOVE the render and sets flags
// globally for ALL charts on a page
//zingchart.DEV.CACHECANVASTEXT = true;
//zingchart.DEV.CHECKDECIMALS = false;
//zingchart.DEV.CACHESELECTION = true;
//zingchart.DEV.MEDIARULES = false;
//zingchart.DEV.SKIPTRACKERS = true;
// skips the intro loading screen (most likely invisible to human eye anyway)
//zingchart.DEV.SKIPPROGRESS = true;
// indicates to the lib that there are no external resources to load (images)
//zingchart.DEV.RESOURCES = false;
// prevents lib from storing the original data package
//zingchart.DEV.KEEPSOURCE = false;
// prevents lib from creating a copy of the data package instead of working with the provided one (which can be altered)
//zingchart.DEV.COPYDATA = false;
// forces the library to accept only long tokes (e.g. %node-value instead of %v) saving some parsing time
//zingchart.DEV.SORTTOKENS = false;
// skips calculations of several plot relates statistics (min, max, sum, avg values)
//zingchart.DEV.PLOTSTATS = false;
// tells library dashed syntax only is used so there is no conversion (saves time for large JSON's)
//zingchart.SYNTAX = 'dashed';

// helper
import helper, {
  generateID,
  dateFormat,
  WarningIndectorName
} from "./util/helper.js";
import ErrorCheck from "./util/error-check.js";
import ButtonStateMixin from "./util/AlarmButtonsStateMixin";

// import * as VueGoogleMaps from "vue2-google-maps";

import Vuex from "vuex";

//Vue.component('zingchart', zingchartVue)
// Vue.component("zingchart", zingchartVue);
Vue.use(Vuex);
Vue.use(Vuelidate);
Vue.use(AsyncComputed);
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: "AIzaSyBpRIs7w1fBQn_ISEVJZEpuncss0XIYxw0",
//     libraries: "places" // necessary for places input
//   }
// });

// router setup
import router from "./routes/router";
import i18n from "./i18n";
import "./registerServiceWorker";
import { checkAccess, P, M } from "./Permissions.js";
import { Modal } from "src/components";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Transfer,
  Button,
  Slider
} from "element-ui";
import { BasePagination } from "src/components";

Vue.component(Modal.name, Modal);
Vue.component(BasePagination.name, BasePagination);

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Transfer);
Vue.use(Button);
Vue.use(Chat);
Vue.use(Slider)
Vue.mixin(ButtonStateMixin);
Vue.component("vue-datetime", Datetime);

import InlineSvg from "vue-inline-svg";
Vue.component("inline-svg", InlineSvg);

Vue.prototype.$api = api;
Vue.prototype.$helper = helper;
Vue.prototype.gDateFormat = dateFormat;
Vue.prototype.getCountry = country => country;
Vue.prototype.getCity = city => city;
Vue.prototype.$generateID = generateID;
Vue.prototype.$ErrorCheck = ErrorCheck;
Vue.prototype.$WarningIndectorName = WarningIndectorName;
Vue.prototype.log = (...data) => console.log(...data);

Vue.prototype.canPage = (...data) => {
  return checkAccess(store.state.user.permissions, ...data);
};
Vue.prototype.canModule = data => {
  return checkAccess(store.state.user.modules, data);
};

Vue.prototype.P = P;
Vue.prototype.M = M;

Vue.prototype.$eventBus = new Vue();

import { MoroIoTSocketIO } from "./plugins/moro-iot-socket/socket/index";
import { SOCKET_NAMESPACES } from "./plugins/moro-iot-socket/socket/namespaces";


SOCKET_NAMESPACES.forEach(namespace => {
  const moroSocketIoT = new MoroIoTSocketIO({
    connection: `${process.env.VUE_APP_URL}${namespace}`,
    options: {
      reconnection: true,
      reconnectionDelay: 500,
      maxReconnectionAttempts: Infinity,
      path: '/ws/socket.io'
    },
    namespace
  });

  Vue.use(moroSocketIoT);
});

export var app;
launcher.finally(() => {
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  console.log("token >", token);
  if (token) {
    store.commit('setToken', token);
    store.commit('setRefreshToken', refreshToken);
    // const socket = io(process.env.VUE_APP_SOCKET,{
    //   query:`token=${token}`,
    // });
    // Vue.prototype.$socket = socket;

  }

  /* eslint-disable no-new */
  app = new Vue({
    el: "#app",
    render: h => h(App),
    created() { },
    store,
    router,
    i18n
  });
  //remove splash screen aftar loading all important data and start the vue app
  document.getElementById("preloader").remove();
  document.getElementById("splashStyle").remove();
  console.log("router.currentRoute");
  console.log(router.currentRoute);
  // if (!store.state.user && router.currentRoute.name != 'set password'){
  //   console.log('to login')
  //   router.push('/login')
  // }
});
