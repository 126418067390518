import SwamService from "@/pages/SWAM/services/swam.service";
import { onePeriod } from "@/util/periodOptions";

export default {
  namespaced: true,
  state: {
    loading: false,
    currentStructureId: undefined,
    currentSolutionId: undefined,
    data: {}
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    data(state) {
      return state.data;
    },
    totalOperationalAlarms(state) {
      let result = 0;
      if (!state.data || !state.data.operationalAlarms) return "-";
      result += state.data.operationalAlarms.ok;
      result += state.data.operationalAlarms.poor;
      result += state.data.operationalAlarms.problem;
      return result;
    }
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },
    setData(state, data) {
      state.data = data;
    },
    selectStructure(state, value) {
      state.currentStructureId = value;
    },
    selectSolution(state, value) {
      state.currentSolutionId = value;
    }
  },
  actions: {
    async fetchSolutionData({ state, commit }, payload) {
      let params = {
        from: onePeriod[1].from().toDate(),
        to: onePeriod[1].to().toDate(),
        id: state.currentStructureId,
        by: state.currentStructureId ? "structure" : undefined
      };
      commit("setLoading", true);
      try {
        const data = await SwamService.getSolutionStatistics(
          params,
          state.currentSolutionId
        );
        commit("setData", data[state.currentSolutionId]);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    }
  }
};
