import api from './../../axiosWithDelimiterFile'
import { app } from "./../../main";

export default {
    state: {
        selectedMO: null,
        loadingMO: false,
        currentTask: null,
        rightSideSlot: null,
        rightSideState: true,
        updatePercentage: null, // typeof function
        tasks: null,
        percentage: -1,
        headerAction: null,
        workTime: null,
        timeInterval: null,
        orderHistory: null,

        moPrimaryInfo: {
            priorities: null,
            MaintenanceTypes: null,
            valid: false
        }
    },
    getters: {
        priorities(state){
            return state.moPrimaryInfo.priorities;
        },
        MaintenanceTypes(state){
            return state.moPrimaryInfo.MaintenanceTypes;
        },
        selectedMO(state) {
            return state.selectedMO
        },
        rightSideSlot(state) {
            if (state.rightSideSlot == null) {
                return 'mo-details'
            }
            return state.rightSideSlot;
        },
        currentTask(state) {
            return state.currentTask;
        },
        rightSideState(state) {
            return state.rightSideState;
        },
        tasks(state) {
            return state.tasks;
        },
        percentage(state) {
            return state.percentage;
        },
        headerAction(state) {
            return state.headerAction;
        },
        rejected(state) {
            return state.selectedMO.state.id == 2
        },
        submitted(state) {
            return state.selectedMO.state.id == 1
        },
        accepted(state) {
            return state.selectedMO.state.id == 3
        },
        working(state) {
            return state.selectedMO.state.id == 4 && state.selectedMO.last_start != null
        },
        stopped(state) {
            return state.selectedMO.state.id == 4 && state.selectedMO.last_start == null;
        },
        resolved(state) {
            return state.selectedMO.state.id == 5;
        },
        closed(state) {
            return state.selectedMO.state.id == 6;
        },
        isCreator(state, g, rs) {
            return state.selectedMO && (rs.user.role == 'super_admin' || rs.user.id == state.selectedMO.creator_id);
        },
        workTime(state) {
            return parseInt(state.workTime)
        },
        canFinishTask(state, g, rs, rg) {
            return rs.user.role == 'super_admin' || (state.selectedMO.current_assignment.user_id && state.selectedMO.current_assignment.user_id == rs.user.id)
        },
        canUnFinishTask(state, g, rs) {
            return rs.user.role == 'super_admin' || rs.user.id == state.selectedMO.creator_id
                || (state.selectedMO.current_assignment.user_id && state.selectedMO.current_assignment.user_id == rs.user.id)
        },
        orderHistory(state) {
            return state.orderHistory;
        }
    },
    mutations: {
        selectMO(state, mo) {
            state.selectedMO = mo;
        },
        getSlot(state, component) {
            state.rightSideSlot = component;
        },
        openTask(state, task) {
            state.rightSideSlot = 'addNewTask';
            state.currentTask = task;
            state.rightSideState = true;
        },
        toggleRightSide(state, t) {
            state.rightSideSlot = null;
            if (typeof t != 'undefined') {
                state.rightSideState = t;
                return;
            }
            state.rightSideState = !state.rightSideState;
        },
        setHistory(state, history) {
            if (state.selectedMO) {
                state.selectedMO.history = history;
            }
            state.orderHistory = history;
        },
        setTasks(state, tasks) {
            state.tasks = tasks;
            this.dispatch('updatePercentage');
        },
        setPercentage(state, val) {
            state.percentage = val;
        },
        setHeaderAction(state, val) {
            state.headerAction = val;
        },
        setWorkTime(state, val) {
            state.workTime = val;
        },
        setMOPrimaryInfo(state, data) {
            state.moPrimaryInfo = data;
        }
    },
    actions: {
        getMOPrimaryInfo({state}) {
            if (!state.moPrimaryInfo.valid){
                api.get("maintenance/info")
                    .then(res => {
                        const data = res.data.data;
                        data.MaintenanceTypes = data.types;
                        delete data.types;
                        data.valid = true;
                        this.commit("setMOPrimaryInfo", data)
                    })
                    .catch(err => { // fallback data
                        this.commit("setMOPrimaryInfo", {
                            priorities: [{ id: 1, name: "High" }, { id: 3, name: "Low" }, { id: 2, name: "Medium" }],
                            types: [{ id: 2, name: "Corrective" }, { id: 1, name: "Preventive" }],
                            valid:false
                        })
                        console.error(err);
                    });
            }
        },
        updatePercentage({ state }) {
            let total = 0;
            let completed = 0;
            console.log(state.tasks)
            state.tasks.forEach(t => {
                if (t.finished === true) {
                    completed++;
                    total++;
                }
                if (t.finished === false) {
                    total++;
                }
            });
            if (total == 0) {
                state.percentage = -1;
                this.dispatch('pauseTimer')
                this.commit('setPercentage', -1)
                return;
            }
            const percent = parseInt((completed / total) * 100);
            console.log(percent)
            if (percent == 100) {
                this.dispatch('pauseTimer')
                state.selectedMO.last_start = null //resolved
            }
            this.commit('setPercentage', percent)
        },
        openMO({ state }, mo) {
            const id = mo.id;
            app.$router.push({ path: `./order/${id}` });
            this.dispatch(actions.loadMO, id);
        },
        loadMO({ state }, id) {
            console.log(state)
            this.commit("setSpinner", true);
            state.loadingMO = true;
            if (state.selectedMO && state.selectedMO.id != id) {
                state.selectedMO = null;
                state.currentTask = null;
                state.rightSideSlot = null;
            }
            api.get('maintenance/order/' + id)
                .then(res => {
                    const mo = res.data.data;
                    this.dispatch('getHeaderAction', mo)
                    this.commit(mutations.selectMO, mo)
                    this.commit('setTasks', mo.tasks)
                    this.commit('setWorkTime', mo.seconds_elapsed)
                    if (mo.state.id == 4 && mo.last_start != null) {
                        this.dispatch('startTimer')
                    }
                })
                .catch(err => {
                    //  app.$router.push({ path: '/maintenance' })
                    console.log(err)
                }).finally(() => {
                    state.loadingMO = false;
                })

            /*api
                .get(`maintenance/order/${id}/task`)
                .then(res => {
                    this.commit('setTasks', res.data.data)
                    // var data = res.data.data;
                    // data.forEach(element => {
                    //     this.tasks.push(element);
                    // });

                })
                .catch(() => { });*/
        },
        loadHistory({ state }) {
            //if (!state.selectedMO) {
            //app.$router.push({ namse: 'Maintenance Order Summary' })
            //  return;
            //}
            const id = app.$router.currentRoute.params.id;

            api.get(`maintenance/order/${id}/log`)
                .then(res => {
                    let data = res.data.history;
                    data.forEach(element => {
                        element.created_at = this._vm.gDateFormat(element.created_at);
                    });

                    this.commit('setHistory', res.data.history);
                })
                .catch(console.error);
        },
        getHeaderAction({ state }, mo) {
            switch (mo.state.id) {
                case 1: {//submitted
                    this.commit('setHeaderAction', 'accept')
                    return;
                }
                case 2: {//rejected
                    this.commit('setHeaderAction', 'accept')
                    return;
                }
            }
            this.commit('setHeaderAction', 'rest')

        },
        changeTaskState({ state }, { task, newState }) {//newState 0 / 1
            this.commit("setSpinner");
            const mo = state.selectedMO
            api
                .patch(`maintenance/order/${mo.id}/task/${task.id}/${newState}`)
                .then((res) => {
                    if (res.data.state) {
                        mo.state.id = res.data.state;
                        //mo.last_start = null;
                        this.commit('selectMO', mo)
                        this.dispatch('getHeaderAction', mo)
                    }
                    if (newState == 1) {
                        task.finished = true;
                    } else {
                        task.finished = false;
                    }
                    this.commit('setWorkTime', res.data.seconds_elapsed)

                    this.dispatch('updatePercentage')
                })
                .catch(console.error);
        },
        moStateAction({ state, rootState }, { newState, company_id }) {
            const mo = state.selectedMO;
            this.commit("setSpinner", true);
            api.patch(`maintenance/order/${mo.id}/${newState}`, {
                company_id
            })
                .then(res => {
                    if (res.data.state) {

                        const mo = state.selectedMO;

                        if (newState == 'stopWork') {
                            mo.last_start = null;
                            this.commit('setWorkTime', res.data.seconds_elapsed)
                            this.dispatch('pauseTimer')
                        } else if (newState == 'startWork') {
                            mo.last_start = new Date();
                            this.dispatch('startTimer')
                        }

                        mo.state.id = res.data.state;

                        if (newState == 'accept') {
                            mo.current_assignment.user = rootState.user;
                            mo.current_assignment.user_id = rootState.user.id;
                        }

                        this.commit('selectMO', mo)
                        this.dispatch('getHeaderAction', mo)
                    }
                })
                .catch(err => { console.log(err) })
            /*switch(newState){
                case 'accept':{
                    this.commit("setSpinner", true);
                    api.patch(`maintenance/order/${mo.id}/accept`)
                    .then(res=>{
                        console.log(res);
                    })
                    .catch(err=>{console.log(err)})
                    break;
                }
                case 'reject':{
                    this.commit("setSpinner", true);
                    api.patch(`maintenance/order/${mo.id}/accept`)
                    .then(res=>{
                        console.log(res);
                    })
                    .catch(err=>{console.log(err)})
                    break;
                }
            }*/
        },
        startTimer({ state }) {
            if (!state.timeInterval)
                state.timeInterval = setInterval(() => {
                    this.commit('setWorkTime', state.workTime + 1)
                }, 1000)
        },
        pauseTimer({ state }) {
            clearInterval(state.timeInterval)
            state.timeInterval = null;
        }
    }
}

export const mutations = {
    selectMO: 'selectMO'
}
export const actions = {
    openMO: 'openMO',
    loadMO: 'loadMO'
}