import api from "@/axiosWithDelimiterFile.js"

import {
    MAP_MARKERS,
    VILLAS,
    ALARMS_STATISTICS,
    OPERATIONAL_ALARMS,
    VILLA_BY_ID,
    OPERATIONAL_ALARMS_HISTORY,
    ALARMS_INFO,
    CUSTOMER_CONTACTS,
    DEVICES_COMPANIES,
    ACKNOWLEDGE_ALARM,
    ESCALATE_ALARM,
    TERMINATE_ALARM,
    WORK_ORDER_LIST,
    WORK_ORDER_BY_ID,
    WORK_ORDER_DEVICES,
    WORK_ORDER_DEVICE_BY_ID,
} from "../services/endpoints"

class VSAASService {
    static getMapMarkers() {
        return api.get(MAP_MARKERS).then(res => {
            return res.data.data;
        });
    }
    static getVillas(qp = {}) {
        return api.get(VILLAS, {
            params: qp,
        }).then(res => {
            return res.data;
        })
    }
    static getAlarmsStatistics() {
        return api.get(ALARMS_STATISTICS).then(res => {
            return res.data.data;
        });
    }
    static getOperationalAlarms(qp = {}) {
        return api.get(OPERATIONAL_ALARMS, {
            params: qp
        }).then(res => {
            return res.data;
        })
    }
    static getVillaData(id) {
        return api.get(VILLA_BY_ID(id)).then(res => {
            return res.data.data;
        })
    }
    static getOperationalAlarmsHistory(qp = {}) {
        return api.get(OPERATIONAL_ALARMS_HISTORY, {
            params: qp
        }).then(res => {
            return res.data;
        })
    }
    static getAlarmsInfo() {
        return api.get(ALARMS_INFO).then(res => {
            return res.data.data;
        })
    }
    static getCustomerContacts(id) {
        if(!id) throw new Error("customer id must be presented");
        return api.get(CUSTOMER_CONTACTS(id)).then(res => {
            return res.data.data;
        })
    }
    static getDevicesCompanies(id) {
        return api.get(DEVICES_COMPANIES(id)).then(res => {
            return res.data.data;
        })
    }
    static acknowledgeAlarm(id, qp = {}) {
        return api.post(ACKNOWLEDGE_ALARM(id),qp);
    }
    static escalateAlarm(id, qp = {}) {
        return api.post(ESCALATE_ALARM(id),qp);
    }
    static terminateAlarm(id, qp = {}) {
        return api.post(TERMINATE_ALARM(id),qp);
    }
    static getWorkOrderList(qp = {}) {
        return api.get(WORK_ORDER_LIST,{
            params: qp
        }).then(res => {
            return res.data;
        })
    }
    static createWorkOrder(qp = {}) {
        return api.post(WORK_ORDER_LIST,qp);
    }
    static getWorkOrderData(id) {
        return api.get(WORK_ORDER_BY_ID(id)).then(res => {
            return res.data;
        });
    }
    static updateWorkOrderData(id,qp = {}) {
        return api.patch(WORK_ORDER_BY_ID(id),qp);
    }
    static deleteWorkOrder(id) {
        return api.delete(WORK_ORDER_BY_ID(id));
    }
    static getDevicesList(qp = {}) {
        return api.get(WORK_ORDER_DEVICES,{
            params: qp
        }).then(res => {
            return res.data;
        });
    }
    static createDevice(qp = {}) {
        return api.post(WORK_ORDER_DEVICES,qp);
    }
    static getDeviceData(id) {
        return api.get(WORK_ORDER_DEVICE_BY_ID(id)).then(res => {
            return res.data;
        })
    }
    static updateDevice(id,qp = {}) {
        return api.patch(WORK_ORDER_DEVICE_BY_ID(id),qp);
    }
    static deleteDevice(id) {
        return api.delete(WORK_ORDER_DEVICE_BY_ID(id));
    }
}

export default VSAASService;