<template>
  <div class="row">
    <div class="col-4 mx-auto">
      <h4 class="m-0 font-weight-bold font-color-4 py-3">
        {{ label }}
      </h4>
    </div>
    <div class="col-4 mx-auto align-self-center">
      <div v-if="custom">
        <slot />
      </div>
      <h4 v-else class="m-0 font-color-4 py-3">
        {{ value }}
      </h4>
    </div>
    <el-divider v-if="!last" class="col-10 mx-auto my-0" />
  </div>
</template>

<script>
import { Tag, Divider } from "element-ui";

export default {
  props: {
    last: false,
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    custom: {
      type: Boolean,
      default: false
    },
    last: {
      type: Boolean,
      default: false
    }
  },
  components: {
    [Divider.name]: Divider,
    [Tag.name]: Tag
  }
};
</script>

<style></style>
