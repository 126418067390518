<template>
  <div
    class="form-group"
    :class="{
      'input-group-focus': focused,
      'has-danger': error || $slots.error,
      'has-label': label,
      'has-icon': hasIcon,
      'view-mode':viewMode,
      'inline-select':inline,
    }"
  >
    <slot name="label">
      <label v-if="label" class="font-color-4 font-weight-bold h4" > {{ label }} {{ required ? '*' : '' }} </label>
    </slot>
    <div class="mb-0" :class="{'input-group': hasIcon}">
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text"><i :class="addonLeftIcon"></i></div>
        </span>
      </slot>
      <slot>
             <el-select
                  class="select-primary"
                  :class="{'view-mode':viewMode,'light':light,'normal':!light, 'hide-shadow': hideShadow}"
                  :size="size"
                  :placeholder="placeholder"
                  :value="value"
                  @change="onInput"
                  :disabled="disabled || viewMode"
                  :no-data-text="noData"
                  :filterable="filterable"
                  :remote="remote"
                  :remote-method="remoteMethod"
                  :loading="loading"
                  :popper-class="popperClass"
                >
                  <el-option
                    v-for="option in options"
                    class="select-primary"
                    :value="option.value"
                    :label="i18n?$tc(option.label):option.label"
                    :key="option.value"
                    :disabled="option.disabled"
                  >
                  </el-option>
        </el-select>
      </slot>
      <slot name="addonRight">
        <span v-if="addonRightIcon" class="input-group-append">
          <div class="input-group-text"><i :class="addonRightIcon"></i></div>
        </span>
      </slot>
    </div>

    <slot name="error" v-if="error || $slots.error">
      <label class="error">{{ error }}</label>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'base-select',
  props: {
    size:{
      default:"large"
    },
    light: {
      type: Boolean,
      default: false
    },
    i18n:{
      default:true
    },
    inline:{
      default:false
    },
    viewMode:{
      type: Boolean,
      default:false
    },
    required: Boolean,
    placeholder:{
      type:String
    },
    filterable:{
      default:false
    },
    remote:{
      default:false
    },
    remoteMethod: {
      type: Function
    },
    loading: {
      default:false
    },
    label: {
      type: String,
      description: 'Input label'
    },
    disabled:{
      type: Boolean,
      description: 'Input disabled',
      default : false
    },
    error: {
      type: String,
      description: 'Input error',
      default: ''
    },
    value: {
      type: [String, Number,Boolean],
      description: 'Input value'
    },
    options:{
        type:Array
    },
    addonRightIcon: {
      type: String,
      description: 'Input icon on the right'
    },
    addonLeftIcon: {
      type: String,
      description: 'Input icon on the left'
    },
    noData:{
      
    },
    popperClass:{
      
    },
    hideShadow:{
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      touched: false,
    };
  },
  computed: {
    hasIcon() {
      return this.hasLeftAddon || this.hasRightAddon
    },
    hasLeftAddon() {
      const { addonLeft } = this.$slots;
      return (
        addonLeft !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    hasRightAddon() {
      const { addonRight } = this.$slots;
      return (
        addonRight !== undefined ||
        this.addonRightIcon !== undefined
      );
    },
    // listeners() {
    //   return {
    //     ...this.$listeners,
    //     input: this.onInput,
    //     blur: this.onBlur,
    //     focus: this.onFocus,
    //   };
    // }
    //removed class
    // 'has-success': !error &&  !$slots.error && touched
  },

  methods: {
    onInput(val) {
      if (!this.touched) {
        this.touched = true;
      }
      console.log('select base',val)
       this.$emit('input', val);
       this.$emit('change', val); 
    },
    onChange(evt){
        console.log('change:',evt);
       //this.$emit('change', evt.target.value); 
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit('focus', evt)
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit('blur', evt)
    }
  }
};
</script>
<style lang="scss">
.inline-select{
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  label{
    margin: 0;
    margin-inline-end: 15px;
  }
}
[dir="rtl"] span.el-input__suffix {
  left: 5px;
  right: initial;
}
label {
  text-transform: capitalize;
}
.light div input{
  background-color: #41B581 !important;
  color: #fff !important;
}
.light div span span i{
  color: #fff !important;
}
.normal div input {
  color: #41B581 !important;
}
#label {
  color: #707070;
}
.hide-shadow input{
  box-shadow: none !important;
}
</style>
