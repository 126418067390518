import api from "src/axiosWithDelimiterFile";

export default class LocksService {
  static async getLocks() {
    const response = await api.get(`/locks/list`)
    return response;
  }
  static async updateLock(param) {
    const response = await api.patch(`/locks/unlock?mac=${param.mac}`)
    return response;
  }

}