import BaseInput from 'src/components/Inputs/BaseInput.vue';
import BaseDropdown from 'src/components/BaseDropdown.vue';
import Card from 'src/components/Cards/Card.vue';
import BaseButton from 'src/components/BaseButton.vue';
import BaseCheckbox from 'src/components/Inputs/BaseCheckbox.vue';
import BaseSelect from 'src/components/Inputs/BaseSelect.vue';
import BaseMultiSelect from 'src/components/Inputs/BaseMultiSelect.vue';
import { Input, InputNumber, Tooltip, Popover } from 'element-ui';
import { TimeSelect, DatePicker, Select, Option, Dialog } from 'element-ui';
import CountriesSelect from 'src/components/CountriesAndCities/CountriesSelect.vue'
import CitiesSelect from 'src/components/CountriesAndCities/CitiesSelect.vue'
import PhoneInput from 'src/components/CountriesAndCities/PhoneInput.vue'
import MapAutocomplete from 'src/components/BaseMap/MapAutocomplete'
import Zingchart from 'src/components/Charts/Zingchart/Zingchart'

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseSelect.name, BaseSelect);
    Vue.component(BaseMultiSelect.name, BaseMultiSelect);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(Card.name, Card);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(Input.name, Input);
    Vue.component(InputNumber.name, InputNumber);

    Vue.component(CountriesSelect.name,CountriesSelect);
    Vue.component(CitiesSelect.name,CitiesSelect);
   Vue.component(PhoneInput.name,PhoneInput);



    Vue.component(TimeSelect.name, TimeSelect);
    Vue.component(DatePicker.name, DatePicker);
    Vue.component(Select.name, Select);
    Vue.component(Option.name, Option);
    Vue.component(Dialog.name, Dialog);

    Vue.component(MapAutocomplete.name, MapAutocomplete)
    Vue.component(Zingchart.name, Zingchart);

    Vue.use(Tooltip);
    Vue.use(Popover);
  }
};

export default GlobalComponents;
