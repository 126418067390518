import MutationsTypes from "../types/mutations-type";
import StateTypes from "../types/state-types";
import { initialState } from "./initial-state";

export const mutations = {
  [MutationsTypes.RESET_THE_WHOLE_STATE_MUTATION]: (state, _) => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },

  // Alarms and villas
  [MutationsTypes.GETTING_INTRUSION_ALARMS_MUTATION]: (state, data) => {
    state[StateTypes.GETTING_INTRUSION_ALARMS_STATE_TYPE] = data;
  },
  [MutationsTypes.GETTING_STATUS_ALARMS_MUTATION]: (state, data) => {
    state[StateTypes.GETTING_STATUS_ALARMS_STATE_TYPE] = data;
  },
  [MutationsTypes.GETTING_VILLAS_LIST_MUTATION]: (state, data) => {
    state[StateTypes.GETTING_VILLAS_LIST_STATE_TYPE] = data;
  },

  // Total records
  [MutationsTypes.INTRUSION_ALARMS_TOTAL_RECORDS_MUTATION]: (state, data) => {
    state[StateTypes.INTRUSION_ALARMS_TOTAL_RECORDS_STATE_TYPE] = data;
  },
  [MutationsTypes.VILLAS_DETAILS_TOTAL_RECORDS_MUTATION]: (state, data) => {
    state[StateTypes.VILLAS_DETAILS_TOTAL_RECORDS_STATE_TYPE] = data;
  },

  [MutationsTypes.BY_MORO_ACKNOWLEDGE_ALARM_MUTATION]: (state, data) => {},
  [MutationsTypes.BY_POLICE_ACKNOWLEDGE_ALARM_MUTATION]: (state, data) => {},
  [MutationsTypes.VERIFY_ALARM_MUTATION]: (state, data) => {},
  [MutationsTypes.TERMINATE_ALARM_MUTATION]: (state, data) => {},
  [MutationsTypes.COMMENT_ON_ALARM_MUTATION]: (state, data) => {},

  [MutationsTypes.CUSTOMER_CONTACTS_LIST_MUTATION]: (state, data) => {
    state[StateTypes.CUSTOMER_CONTACTS_LIST_STATE_TYPE] = data;
  },
  [MutationsTypes.OPEN_ACKNOWLEDGEMENT_MODAL_MUTATION]: (state, data) => {
    state[StateTypes.OPEN_ACKNOWLEDGEMENT_MODAL_STATE_TYPE] = data;
  },
  [MutationsTypes.OPEN_MORE_INFORMATION_MODAL_MUTATION]: (state, data) => {
    state[StateTypes.OPEN_MORE_INFORMATION_MODAL_STATE_TYPE] = data;
  },

  [MutationsTypes.LISTEN_TO_ACKNOWLEDGE_ALARM_MUTATION]: (state, data) => {
    console.log("**********************LISTEN_TO_ACKNOWLEDGE_ALARM_MUTATION********************")
    console.log(data);
    const alarms = state[StateTypes.GETTING_INTRUSION_ALARMS_STATE_TYPE];
    const anAlarmToAck = alarms.filter(a => +a.id === +data.id)[0];
    if (anAlarmToAck) {
      const idx = alarms.indexOf(anAlarmToAck);

      if (data.archived) {

        // Update statistcs
        const statistics = state[StateTypes.INTRUSION_ALARMS_STATISTICS_STATE_TYPE];
        if(statistics && anAlarmToAck.police_station.police_station_id) {
          const requiredStatisticCard = statistics.filter(aStatistic => +aStatistic.police_station_id === anAlarmToAck.police_station.police_station_id)[0];
          if(requiredStatisticCard) requiredStatisticCard['active_alarms'] = +requiredStatisticCard['active_alarms'] - 1;
        }


        alarms.splice(idx, 1);
        // Update total records
        state[StateTypes.INTRUSION_ALARMS_TOTAL_RECORDS_STATE_TYPE] =
          alarms.length;
      } else {
        // If moro
        if (data.acknowledgement) {
          anAlarmToAck.acknowledged_at = data.acknowledgement.created_at;
          anAlarmToAck.acknowledgement = { ...data.acknowledgement };
          if ( data.acknowledgement.user_id && data.acknowledgement.user_id != data.rootUserId ) {
            anAlarmToAck.highlight = true;
          }
        }
        // If police
        if (data.police_acknowledgement) {
          anAlarmToAck.acknowledged_at = data.police_acknowledgement.created_at;
          anAlarmToAck.police_acknowledgement = {
            ...data.police_acknowledgement
          };
          if ( data.police_acknowledgement.user_id && data.police_acknowledgement.user_id != data.rootUserId ) {
            anAlarmToAck.highlight = true;
          }
        }

        alarms.splice(idx,1,anAlarmToAck)
      }
    }
  },

  [MutationsTypes.NEW_ALARM_RECEIVED_FROM_SERVER_MUTATION]: (
    state,
    newAlarm
  ) => {
    console.log('new alarm received over socket: ', newAlarm);
    newAlarm.highlight = true;
    const alarms = state[StateTypes.GETTING_INTRUSION_ALARMS_STATE_TYPE];

    // Check if the new alarm agree with current table's filter (for all critiria)
    const filter = state[StateTypes.INTRUSION_ALARMS_CURRENT_FILTER];
    if((!filter.police_station_id || filter.police_station_id === newAlarm.police_station.police_station_id)
    && (!filter.villa_id || filter.villa_id === newAlarm.customer.id)
    && (!filter.area_id || filter.area_id === newAlarm.area_id)
    && (!filter.alarm_status || filter.alarm_status === newAlarm.type)
    && (!filter.alarm_id || filter.alarm_id === newAlarm.id)){
      // add it to current list if agree
      alarms.unshift(newAlarm);
    }

    // Updating statistics
    const statistics = state[StateTypes.INTRUSION_ALARMS_STATISTICS_STATE_TYPE];
    if(statistics) {
      const requiredStatisticCard = statistics.filter(aStatistic => +aStatistic.police_station_id === newAlarm.police_station.police_station_id)[0];
      
      if(requiredStatisticCard)
      {
        requiredStatisticCard['active_alarms'] = +requiredStatisticCard['active_alarms'] + 1;
        requiredStatisticCard['week'] = +requiredStatisticCard['week'] + 1;
        requiredStatisticCard['month'] = +requiredStatisticCard['month'] + 1;

      }
      else if(newAlarm.police_station.police_station_id) {
        const newStatisticCard = {
          month: 1,
          week: 1,
          active_alarms: 1,
          police_station_id: newAlarm.police_station.police_station_id,
          police_station_name: newAlarm.police_station.police_station_name,
        };
        statistics.push(newStatisticCard);
      }
      
    }
  },

  [MutationsTypes.LISTEN_TO_VERIFY_ALARM_EVENT_MUTATION]: (state, data) => {
    const alarms = state[StateTypes.GETTING_INTRUSION_ALARMS_STATE_TYPE];
    const anAlarmToVerify = alarms.filter(a => +a.id === +data.id)[0];
    if (anAlarmToVerify) {
      const idx = alarms.indexOf(anAlarmToVerify);
      if (data.archived) {


        // Update statistcs
        const statistics = state[StateTypes.INTRUSION_ALARMS_STATISTICS_STATE_TYPE];
        if(statistics) {
          const requiredStatisticCard = statistics.filter(aStatistic => +aStatistic.police_station_id === anAlarmToVerify.police_station.police_station_id)[0];
          if(requiredStatisticCard) requiredStatisticCard['active_alarms'] = +requiredStatisticCard['active_alarms'] - 1;
        }


        alarms.splice(idx, 1);
        // Update total records
        state[StateTypes.INTRUSION_ALARMS_TOTAL_RECORDS_STATE_TYPE] =
          alarms.length;
      } else {
        anAlarmToVerify.verified_at = data.verification.created_at;

        // Constructing verification object
        anAlarmToVerify.verification = {
          ...data.verification
        };

        // Highlighting
        if ( data.verification.user_id && data.verification.user_id != data.rootUserId ) {
          anAlarmToVerify.highlight = true;
        }

        // Update alarm
        alarms[idx] = anAlarmToVerify;
      }
    }
  },
  [MutationsTypes.LISTEN_TO_TERMINATE_ALARM_EVENT_MUTATION]: (state, data) => {
    console.log("*************************** data ********************************");
    console.log(data);
    const alarms = state[StateTypes.GETTING_INTRUSION_ALARMS_STATE_TYPE];
    const anAlarmToTerminate = alarms.filter(a => +a.id === +data.id)[0];
    if (anAlarmToTerminate) {
      const idx = alarms.indexOf(anAlarmToTerminate);

      if (data.archived) {

        // Update statistcs
        const statistics = state[StateTypes.INTRUSION_ALARMS_STATISTICS_STATE_TYPE];
        if(statistics) {
          const requiredStatisticCard = statistics.filter(aStatistic => +aStatistic.police_station_id === anAlarmToTerminate.police_station.police_station_id)[0];
          if(requiredStatisticCard) requiredStatisticCard['active_alarms'] = +requiredStatisticCard['active_alarms'] - 1;
        }

        // Removing alarm
        alarms.splice(idx, 1);
        // Update total records
        state[StateTypes.INTRUSION_ALARMS_TOTAL_RECORDS_STATE_TYPE] =
          alarms.length;
      } else {
        anAlarmToTerminate.ended_at = data.termination.created_at;
        anAlarmToTerminate.termination = { ...data.termination };
        // Highlight
        if (
          (data.termination.user && +data.termination.user.id !== +data.rootUserId) ||
          (!data.termination.user && !data.termination.user_id) 
          // || (!data.user && +data.user_id !== +data.rootUserId)
        ) {
          anAlarmToTerminate.highlight = true;
        }
        alarms[idx] = anAlarmToTerminate;
      }
    }
  },

  [MutationsTypes.GETTING_INTRUSION_ALARMS_HISTORY_TABLE_COLUMNS_MUTATION]: (
    state,
    data
  ) => {
    state[StateTypes.INTRUSION_ALARMS_HISTORY_TABLE_COLUMNS_STATE_TYPE] = data;
  },
  [MutationsTypes.HISTORY_ALARMS_TOTAL_RECORDS_MUTATION]: (state, data) => {
    state[StateTypes.HISTORY_ALARMS_TOTAL_RECORDS_STATE_TYPE] = data;
  },
  [MutationsTypes.GETTING_HISTORY_DATA_MUTATION]: (state, data) => {
    state[StateTypes.HISTORY_DATA_STATE_TYPE] = data;
  },

  [MutationsTypes.GETTING_INTRUSION_ALARMS_STATISTICS_MUTATION]: (
    state,
    data
  ) => {
    state[StateTypes.INTRUSION_ALARMS_STATISTICS_STATE_TYPE] = data;
  },

  [MutationsTypes.CUSTOMER_LOCATIONS_ARRAY_MUTATION]: (state, data) => {
    state[StateTypes.CUSTOMER_LOCATIONS_ARRAY_STATE_TYPE] = data;
  },
  [MutationsTypes.SETTING_INTRUSION_ALARMS_CURRENT_FILTER]: (state, data) => {
    state[StateTypes.INTRUSION_ALARMS_CURRENT_FILTER] = data
  }
};
