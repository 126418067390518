<template>
  <card class=" pb-4 pl-2 pr-2" style="height: 157px;" v-loading="loading">
      <div class="row font-weight-bold mb-2">
          <div class="col-9 ">
              <router-link
                :to="getUrl()"
                class="clickable font-color-4 font-weight-bold h4"
              >
                  {{ $t('deviceWords.totalDevices') }}
              </router-link>
          </div>
          <h4 class="col-3 font-weight-bold m-0">{{ devices.total }}</h4>
      </div>
        <hr class="mt-1 mb-1" style="border-top-width: 2px;"/>
      <div class="row font-weight-900 mt-3">
          <div class="col-9 ">
              <router-link
                :to="getUrl('true')"
                class="clickable font-color-4 font-weight-900"
              >
                  {{ $t('deviceWords.devicesOn') }}
              </router-link>
          </div>
          <p class="col-3 font-color-5">{{ devices.on }}</p>
      </div>
      <div class="row font-weight-900">
          <div class="col-9">
              <router-link
                :to="getUrl('false')"
                class="clickable font-color-4 font-weight-900"
              >
                  {{ $t('deviceWords.devicesOff') }}
              </router-link>
          </div>
          <p class="col-3 font-color-5">{{ devices.off }}</p>
      </div>
  </card>
</template>

<script>
import { mapActions, mapGetters,mapState } from "vuex";
export default {
    props:{
        namespace:{
            type:String,
            default: "SwamStore/StructurePortfolio"
            // required:true
        },
    },
    computed: {
        ...mapState({
            loading(state,getters){
              return getters[this.namespace+"/StructuresStatistics/loading"];
            },
            devicesData(state,getters) {
             return getters[this.namespace+"/StructuresStatistics/devices"];
            },
            structureId(state,getters) {
                return getters[this.namespace+"/structureId"];
            }
        }),
        devices() {
            let total = this.devicesData.off + this.devicesData.on;
            return {
                total,
                off: this.devicesData.off,
                on: this.devicesData.on
            };
        }
    },
    methods: {
        ...mapActions({
            fetchData(dispatch, payload){
                return dispatch(this.namespace+"/StructuresStatistics/fetchData",payload)
            },
        }),
        getUrl(state) {
            let url = 'SWAM/Devices/list?' + (this.structureId ? '?structure_id=' + this.structureId : '');
            if(state) {
                url += '&on=' + state;
            }
            return url;
        },
    },
    created() {
        this.fetchData(this.structureId);
    },
    watch: {
        structureId() {
            this.fetchData(this.structureId);
        }
    }
}
</script>

<style>

</style>