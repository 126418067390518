import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';

export default {
  path: '/smartlock',
  component: DashboardLayout,
  redirect: '/smartlock/lock',
  children: [
    {
      path: 'locks',
      name: 'locks',
      component: () => import('../pages/locks/index.vue')
    },
    {
      path: 'permissions',
      name: 'permissions',
      component: () => import('../pages/permissions/index.vue')
    },
    {
      path: 'add-lock',
      name: 'add-lock',
      component: () => import('../pages/addLock/index.vue')
    },
    {
      path: 'locks/:id',
      name: 'lock',
      component: () => import('../pages/lock/index')
    },
  ],
}