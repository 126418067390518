import IntrusionService from "../../services/intrusion.service";

export default {
    namespaced:true,
    state: {
        loading: false,
        policeStationId:undefined,
        data: {
            totalVillas:undefined,
            today:undefined,
            week:undefined,
            month:undefined,
            year:undefined,
            active: {
                acknowledgments:undefined,
                terminations:undefined,
                total:undefined
            }
        },
        villaList: [],
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        },
        totalDevices(state) {
            if(!state.villaList.length) {
                return '-';
            }
            let result = 0;
            state.villaList.forEach(villa => {
                result += villa.devicesCount;
            });
            return result;
        }
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setPoliceStation(state,value) {
            state.policeStationId = value;
        },
        setData(state,data) {
            state.data = data;
        },
        setVillaList(state,data) {
            state.villaList = data;
        }
    },
    actions: {
        async fetchData({state,commit}) {
            commit('setLoading',true);
            try {
                const data = await IntrusionService.getAlarmsStatistics({policeStationId:state.policeStationId});
                data.totalVillas = data.total_villas;
                commit("setData", data);
                commit("setLoading", false);
            } catch (error) {
                commit("setLoading", false);
                console.error(error);
            }
        },
        async fetchVillaList({state,commit}) {
            commit('setLoading',true);
            try {
                let villas = await IntrusionService.getVillaList({ policeStationId: state.policeStationId })
                commit("setVillaList",villas);
                commit("setLoading", false);
            } catch (error) {
                commit("setLoading", false);
                console.error(error);
            }
        },
        reset({state}) {
            state.policeStationId=undefined;
            state.data = {
                totalVillas:undefined,
                today:undefined,
                week:undefined,
                month:undefined,
                year:undefined,
                active: {
                    acknowledgments:undefined,
                    terminations:undefined,
                    total:undefined
                }
            };
        }
    }
}