<template>
    <!-- contents -->
    <div class="contents">
      <!-- Title -->
      <div class="row" style="padding-bottom: 20px;">
        <div class="col-12 title">Notifications</div>
      </div>
      <div class="msg" v-if="allDone && list.length == 0" style="margin-top: 20%">
        <span>There are not Notifications now</span>
      </div>
      <!-- All Notifications -->
      <Notification
      v-for="(notifi,i) in list"
      :key="i"
      :data="notifi"
      @click.native="display(notifi)"
      >
      </Notification>
      <!-- See More Card -->
      <div
        class="content"
        v-loading="loading"
        v-if="!allDone"
        @click="getMore"
        >
        <a v-if="!loading" href="javascript:void(0);">See More</a>
      </div>
      <!-- All Done -->
      <div class="msg" v-if="allDone && list.length != 0">
        <span>All Notifications have been Loaded</span>
      </div>

       <!-- Dialog Notification -->
      <NotificationModal
        v-if="notification"
        :dialogVisible="dialogVisible"
        :notification="notification"
        @close="dialogVisible = false;"
      >
      </NotificationModal>
    </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Notification from '@/pages/Notifications/notification.vue';
import NotificationModal from '@/pages/Notifications/NotificationModal.vue';

export default {
  data()
  {
    return{
      notification: null,
      dialogVisible: false,
    };
  },
  components:{
    Notification,
    NotificationModal,
  },
  computed:{
      ...mapGetters({
          list:"notifications/list",
          loading:"notifications/loadingState",
          allDone:"notifications/isAllNotificationsFetched",
      })
  },
  created(){
      console.log("created!");
      if(this.list.length == 0){
          this.getMore();
      }
  },
  methods:{
    display(notification)
    {
      this.notification = notification;
      this.dialogVisible = true;
    },
    ...mapActions({
        getMore:"notifications/getNotifications",
    })
  }
};
</script>

<style scoped>

div.contents{
  height: 40vh;
  overflow-y: scroll;
  overflow-x:hidden;
}

div.title{
  position: fixed;
  margin-inline-start: 4px;
  height: 25px;
  background-color: #ffffff;
  width: 365px;
  text-align: start;
}

div.content{
  display: flex;
  width: 98%;
  border: 1px solid var(--lighter);
  border-radius: 10px;
  padding: 8px;
  margin-top: 8px;
  color: var(--gray);
  cursor: pointer;
  transition-duration: 0.2s;
  justify-content: center;
  height: 50px;
  overflow: hidden;
  align-items: center;
}

div.content:hover{
  border: 1px solid var(--primary);
}

div.msg{
  display: flex;
  justify-content: center;
  padding: 10px;
}

#el-popover-4873 {
  
}

</style>
