import VsaasService from '../../services/vsaas.service';
import { emptyTableCellFormatter } from "@/modules/common/utils/helper";
export default {
    namespaced:true,
    state: {
        loading:false,
        columns: [
            {
              label: "Account ID",
              prop: "customerId",
              minWidth: 200,
              maxWidth: 300
            },
            {
              label: "Address",
              prop: "address",
              minWidth: 200,
              maxWidth: 300,
              customTemplate: true
            },
            {
              label: "Area",
              prop: "communityNameEn",
              minWidth: 200,
              maxWidth: 300,
              formatter: emptyTableCellFormatter,
            },
            {
              label: "Owner",
              prop: "customerName",
              minWidth: 200,
              maxWidth: 300
            },
        ],
        data: [],
        pagination: {
            limit: 10,
            offset: 0,
        },
        total: 0,
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        columns(state) {
            return state.columns;
        },
        data(state) {
            return state.data;
        },
        pagination(state) {
            return state.pagination
        },
        total(state) {
            return state.total;
        }
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setData(state,data) {
            state.data = data;
        },
        setTotal(state,value) {
            state.total = value;
        },
        updatePagination(state,value) {
            state.pagination = value;
        }
    },
    actions: {
        async fetchData({state,commit}) {
            commit('setLoading',true);
            try {
                const data = await VsaasService.getVillas({...state.pagination,total:''});
                commit('setData',data.data);
                commit('setTotal',data.totalRecords);
                commit('setLoading',false);
            } catch(error) {
                commit('setLoading',false);
                console.log(error);
            }
        }
    }
};