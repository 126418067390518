export default {
    methods: {
      getButtonAcknowledgeState(alarm) {
        const result = {};
        result.action = "acknowledge";
        if (!alarm.acknowledgement) {
          if (alarm.termination)
            result.type = "primary";
          else
            result.type = "warning";
        }
        else {
          result.action = "acknowledged";
          result.type = "warning";
        }
  
        return result;
      },
      getButtonTerminateStatus(alarm) {
        const result = {};
        
        if(alarm.termination) {
          if(alarm.termination.user_id)
            result.type = "warning";
          else 
            result.type = "primary"
        } else {
          result.type = "warning";
        }
  
        return result;
      }
    }
  };