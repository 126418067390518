import StateTypes from "../types/state-types";
import GettersTypes from "../types/getter-types";

export const getters = {
  [GettersTypes.ALARMS_GETTER]: state => state[StateTypes.GETTING_ALARMS_STATE_TYPE],
  [GettersTypes.ALARMS_HISTORY_GETTER]: state => state[StateTypes.GETTING_ALARMS_HISTORY_STATE_TYPE],
  [GettersTypes.OPERATIONAL_ALARMS_HISTORY_TABLE_COLUMNS_GETTER]: state => state[StateTypes.OPERATIONAL_ALARMS_HISTORY_TABLE_COLUMNS_STATE_TYPE],
  [GettersTypes.OPERATIONAL_ALARMS_TABLE_COLUMNS_GETTER]: state => state[StateTypes.OPERATIONAL_ALARMS_TABLE_COLUMNS_STATE_TYPE],
  [GettersTypes.ALARMS_TOTAL_RECORDS_GETTER]: state => state[StateTypes.ALARMS_TOTAL_RECORDS_STATE_TYPE],
  [GettersTypes.CUSTOMER_TABLE_COLUMNS_GETTER]: state => state[StateTypes.CUSTOMER_TABLE_COLUMNS_STATE_TYPE],
  [GettersTypes.CUSTOMER_TOTAL_RECORDS_GETTER]: state => state[StateTypes.CUSTOMER_TOTAL_RECORDS_STATE_TYPE],
  [GettersTypes.CUSTOMER_LIST_GETTER]: state => state[StateTypes.CUSTOMER_STATE_TYPE],
  [GettersTypes.CUSTOMER_LOCATIONS_ARRAY_GETTER]: state => state[StateTypes.CUSTOMER_LOCATIONS_ARRAY_STATE_TYPE],
  [GettersTypes.CUSTOMER_CONTACTS_LIST_GETTER]: state => state[StateTypes.CUSTOMER_CONTACTS_LIST_STATE_TYPE],
  [GettersTypes.OPEN_ACKNOWLEDGEMENT_MODAL_GETTER]: state => state[StateTypes.OPEN_ACKNOWLEDGEMENT_MODAL_STATE_TYPE],
  [GettersTypes.OPEN_COMMENTS_MODAL_GETTER]: state => state[StateTypes.OPEN_COMMENTS_MODAL_STATE_TYPE],
  [GettersTypes.OPEN_TERMINATE_MODAL_GETTER]: state => state[StateTypes.OPEN_TERMINATE_MODAL_STATE_TYPE],
  [GettersTypes.COMMENTS_USERS_GETTER]: state => state[StateTypes.COMMENTS_USERS_STATE_TYPE],
  [GettersTypes.COMMENTS_LIST_GETTER]: state => state[StateTypes.COMMENTS_LIST_STATE_TYPE],
  [GettersTypes.OPEN_ESCALATE_MODAL_GETTER]: state => state[StateTypes.OPEN_ESCALATE_MODAL_STATE_TYPE],
  [GettersTypes.DEVICES_COMPANIES_GETTER]: state => state[StateTypes.DEVICES_COMPANIES_STATE_TYPE],
  [GettersTypes.ALARMS_STATISTICS_GETTER]: state => state[StateTypes.GETTING_ALARMS_STATISTICS_STATE_TYPE],
  [GettersTypes.HISTORY_ALARMS_TOTAL_RECORDS_GETTER]: state => state[StateTypes.HISTORY_ALARMS_TOTAL_RECORDS_STATE_TYPE],
  [GettersTypes.CUSTOMER_INFORMATION_GETTER]: state => state[StateTypes.CUSTOMER_INFORMATION_STATE_TYPE],


};
