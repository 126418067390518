import {
  dateTableCellFomatter,
  emptyTableCellFormatter
} from "../../../common/utils/helper";
import StateTypes from "../types/state-types";

export const initialState = () => ({
  [StateTypes.GETTING_ALARMS_STATE_TYPE]: [],

  [StateTypes.GETTING_ALARMS_HISTORY_STATE_TYPE]: [],

  [StateTypes.OPERATIONAL_ALARMS_TABLE_COLUMNS_STATE_TYPE]: [
    {
      label: "Alarm ID",
      prop: "id",
      tableProps: {
        minWidth: 200,
        maxWidth: 300
      }
    },
    {
      label: "Alarm Time",
      prop: "alartTime",
      tableProps: {
        minWidth: 200,
        maxWidth: 300,
        formatter: dateTableCellFomatter
      }
    },
    {
      label: "Alarm Type",
      prop: "alarmType",
      tableProps: {
        minWidth: 200,
        maxWidth: 300
      }
    },
    {
      label: "device type",
      prop: "deviceType",
      tableProps: {
        minWidth: 200,
        maxWidth: 300
      }
    },
    {
      label: "channel id",
      prop: "deviceChannelId",
      tableProps: {
        minWidth: 200,
        maxWidth: 300
      }
    },
    {
      label: "device status",
      prop: "isDeviceOff",
      tableProps: {
        minWidth: 200,
        maxWidth: 300
      }
    },
    {
      label: "alarm email",
      prop: "alarmEmail",
      tableProps: {
        minWidth: 200,
        maxWidth: 300
      }
    },
    {
      label: "villa name",
      prop: "structureName",
      tableProps: {
        minWidth: 200,
        maxWidth: 300
      }
    },
    {
      label: "Area",
      prop: "area",
      tableProps: {
        minWidth: 200,
        maxWidth: 300
      }
    },
    {
      label: "Customer Name",
      prop: "customerName",
      tableProps: {
        minWidth: 200,
        maxWidth: 300
      }
    },
    {
      label: "Operator Acknowledgement Time",
      prop: "operatorAcknowledgementTime",
      tableProps: {
        minWidth: 200,
        maxWidth: 300,
        formatter: dateTableCellFomatter
      }
    },
    {
      label: "Operator Email",
      prop: "operatorEmail",
      tableProps: {
        minWidth: 200,
        maxWidth: 300
      }
    },
    {
      label: "Terminated At",
      prop: "endedAt",
      tableProps: {
        minWidth: 200,
        maxWidth: 300,
        formatter: dateTableCellFomatter
      }
    }
  ],

  [StateTypes.OPERATIONAL_ALARMS_HISTORY_TABLE_COLUMNS_STATE_TYPE]: [
    {
      label: "Alarm ID",
      prop: "alarm.id",
      minWidth: 200,
      maxWidth: 300
    },
    {
      label: "Alarm Date",
      prop: "alarm.created_at",
      minWidth: 200,
      maxWidth: 300,
      formatter: dateTableCellFomatter
    },
    {
      label: "ended at",
      prop: "alarm.ended_at",
      minWidth: 200,
      maxWidth: 300,
      formatter: dateTableCellFomatter
    },
    {
      label: "event time",
      prop: "event.created_at",
      minWidth: 200,
      maxWidth: 300,
      formatter: dateTableCellFomatter
    },
    {
      label: "event type",
      prop: "event.type",
      minWidth: 200,
      maxWidth: 300
    },
    {
      label: "status",
      prop: "alarm.status",
      minWidth: 200,
      maxWidth: 300
    },
    {
      label: "by",
      prop: "event.user.email",
      minWidth: 200,
      maxWidth: 300,
      formatter: emptyTableCellFormatter
    },
    {
      label: "comment",
      prop: "event.comment",
      minWidth: 200,
      maxWidth: 500,
      formatter: emptyTableCellFormatter
    },
    {
      label: "device type",
      prop: "device.type",
      minWidth: 200,
      maxWidth: 500
    },
    {
      label: "device name",
      prop: "device.device_name",
      minWidth: 200,
      maxWidth: 500
    },
    {
      label: "device status",
      prop: "device.off",
      minWidth: 200,
      maxWidth: 500,
      formatter: (_, __, value) => {
        return value ? "off" : "on";
      }
    },
    {
      label: "channel id",
      prop: "device.channel_id",
      minWidth: 200,
      maxWidth: 500,
      formatter: emptyTableCellFormatter
    },
    {
      label: "villa",
      prop: "device.structure_name",
      minWidth: 200,
      maxWidth: 500
    },
    {
      label: "area",
      prop: "device.community_name_en",
      minWidth: 200,
      maxWidth: 500
    },
    {
      label: "alarm email",
      prop: "device.alarm_email",
      minWidth: 200,
      maxWidth: 500
    }
  ],

  [StateTypes.ALARMS_TOTAL_RECORDS_STATE_TYPE]: 0,
  [StateTypes.HISTORY_ALARMS_TOTAL_RECORDS_STATE_TYPE]: 0,

  [StateTypes.CUSTOMER_TABLE_COLUMNS_STATE_TYPE]: [
    {
      label: "Account ID",
      prop: "id",
      minWidth: 200,
      maxWidth: 300
    },
    {
      label: "Address",
      prop: "address",
      minWidth: 200,
      maxWidth: 300
    },
    {
      label: "Area",
      prop: "community_name_en",
      minWidth: 200,
      maxWidth: 300
    },
    {
      label: "Owner",
      prop: "customerName",
      minWidth: 200,
      maxWidth: 300
    },
    {
      label: "Contact Number",
      prop: "contactNumber",
      minWidth: 200,
      maxWidth: 300
    }
  ],

  [StateTypes.CUSTOMER_STATE_TYPE]: [],
  [StateTypes.CUSTOMER_TOTAL_RECORDS_STATE_TYPE]: 0,
  [StateTypes.CUSTOMER_LOCATIONS_ARRAY_STATE_TYPE]: [],
  [StateTypes.CUSTOMER_CONTACTS_LIST_STATE_TYPE]: [],
  [StateTypes.OPEN_ACKNOWLEDGEMENT_MODAL_STATE_TYPE]: false,
  [StateTypes.OPEN_COMMENTS_MODAL_STATE_TYPE]: false,
  [StateTypes.COMMENTS_USERS_STATE_TYPE]: [],
  [StateTypes.COMMENTS_LIST_STATE_TYPE]: [],
  [StateTypes.OPEN_ESCALATE_MODAL_STATE_TYPE]: false,
  [StateTypes.OPEN_TERMINATE_MODAL_STATE_TYPE]: false,
  [StateTypes.DEVICES_COMPANIES_STATE_TYPE]: [],
  [StateTypes.GETTING_ALARMS_STATISTICS_STATE_TYPE]: [],
  [StateTypes.CUSTOMER_INFORMATION_STATE_TYPE]: [],
});
