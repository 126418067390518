<template>
  <form class="my-container">
    <div class="info">
      <!-- <div class="col-12" align="right">
        <base-button @click="openZones">Geo-fences</base-button>
      </div>-->
      <div class="row">
        <div class="col-12 col-md-12 row m-0">
          <div class="col-5">
            <base-multi-select
              v-model="departments"
              :options="departmentOptions"
              label="Select a Departments"
              placeholder="Select a Departments"
            ></base-multi-select>
            <!-- <p
              v-if="departments.length>0"
            >You will receive email from department that your selected.</p>-->
          </div>
          <div class="col-5">
            <base-input
              label="Employee ID"
              type="text"
              placeholder="Enter Employee ID"
              v-model="employee_id"
            ></base-input>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-3">
          <base-checkbox v-model="is_geofencing">Geo Fencing</base-checkbox>
        </div>
        <div class="col-3">
          <base-checkbox v-model="is_attendance">Attendance</base-checkbox>
        </div>
        <div class="col-3">
          <base-checkbox v-model="is_access_restriction">Access Restriction</base-checkbox>
        </div>
        <div class="col-3">
          <base-checkbox v-model="is_proximity">Proximity</base-checkbox>
        </div>
      </div>
    </div>
    <!-- <div class="working-hours">
      <working-periods
        ref="workingPeriods"
        :workDaysData="workingInfo.work_schedule"
        @change="isDirty = true"
      ></working-periods>
    </div>-->

    <div class="d-flex mt-5 justify-content-between">
      <base-button type="info" @click="goBack">Back</base-button>
      <base-button type="success" @click="submitForm">Submit</base-button>
    </div>
    <el-dialog :visible.sync="openZonesDialog">
      <p slot="title" class="all-zones-title">All Zones</p>

      <zones-selector :zones="zones" :selectedZones="selectedZones" @submit="submitZones"></zones-selector>
    </el-dialog>
  </form>
</template>
<script>
import { Select, Option, TimeSelect, Dialog } from "element-ui";
import { BaseRadio } from "src/components";
import WorkingPeriods from "../../../components/WorkingPeriods/working-periods";
import BaseSelect from "../../../components/Inputs/BaseSelect";
import { mapGetters } from "vuex";
import ZonesSelectorVue from "../components/Zones-Selector.vue";
export default {
  data() {
    return {
      employee_id: "",
      is_geofencing: false,
      is_attendance: false,
      is_access_restriction: false,
      is_proximity: false,
      departmentOptions: [],
      departments: [],
      openZonesDialog: false,
      zones: [],
      selectedZones: undefined,
    };
  },
  computed: {
    ...mapGetters(["UserData", "workingInfo"]),
  },
  created() {
    this.departments = (this.workingInfo.departments || []).map(d=>d.id);

    this.employee_id = this.workingInfo.employee_id;
    this.is_geofencing = this.workingInfo.is_geofencing;
    this.is_attendance = this.workingInfo.is_attendance;
    this.is_access_restriction = this.workingInfo.is_access_restriction;
    this.is_proximity = this.workingInfo.is_proximity;

    this.$store.dispatch("getZones").then((res) => {
      this.zones = res;
    });

    this.$store.dispatch("getDepartments").then((res) => {
      this.departmentOptions = res.map((d) => {
        return {
          label: d.name,
          value: d.id,
        };
      });
    });

    this.selectedZones = this.workingInfo.zones;
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    submitForm(e) {
      // const data = this.$refs.workingPeriods.getData();
      // if (!data) {
      //   this.$notify({
      //     title: "Invalid Data",
      //     message: "Check Working Days data",
      //     type: "danger",
      //   });
      //   return;
      // }
      this.$store.commit('setSpinner',true);
      this.$api
        .patch(`people/${this.UserData.id}/employment_info`, {
          departments: this.departments,
          employee_id: this.employee_id,
          is_geofencing: this.is_geofencing,
          is_attendance: this.is_attendance,
          is_access_restriction: this.is_access_restriction,
          is_proximity: this.is_proximity,
          // work_schedule: data
          //   .filter((w) => w.checked)
          //   .map((w) => {
          //     return { to: w.to, from: w.from, name: w.name };
          //   }),
        })
        .then((_) => {
          // this.workingInfo.work_schedule = data;
        })
        .catch(console.error);
    },
    openZones() {
      this.openZonesDialog = true;
    },

    submitZones() {
      const zones = this.selectedZones.map((z) => z.zone);
      this.$api
        .patch(`people/${this.UserData.id}/employment_info`, { zones })
        .then((_) => {
          this.workingInfo.zones = zones;
          this.openZonesDialog = false;
        })
        .catch(console.error);
    },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [TimeSelect.name]: TimeSelect,
    [BaseRadio.name]: BaseRadio,
    [BaseSelect.name]: BaseSelect,
    [Dialog.name]: Dialog,
    [WorkingPeriods.name]: WorkingPeriods,
    [ZonesSelectorVue.name]: ZonesSelectorVue,
  },
};
</script>

<style scoped lang="scss">
.message {
  color: white;
}

select option {
  color: black;
}

.inspections span {
  color: white;
}

.working-hours {
  margin-top: 25px;
  margin-left: 10px;

  p.title {
    color: white;
    letter-spacing: 1.5px;
    font-size: 1rem;
    text-decoration: underline $primary;
    margin-bottom: 1rem;
  }
}

.my-container {
  margin-top: 120px;
}

.all-zones-title {
  color: white;
  font-size: 1.5rem;
  letter-spacing: 1px;
  margin-bottom: 0;
}

::v-deep .el-table .cell {
  font-size: 1rem;
  color: white;
}
</style>
