import api from "../../axiosWithDelimiterFile";
import convertObjectToArray from './utilities';

export default {
  namespace: true,
  state: {
    logs: [],
    loading: false,
    error: '',
    pagination:{
      limit:10,
      offset:0
    },
    total: 0,
  },
  getters: {
    logs(state) { return state.logs },
    loading(state) { return state.loading },
    error(state) { return state.error },
    total(state) { return state.total },
    limit(state) {return state.pagination.limit},
    offset(state) {return state.pagination.offset}
  },
  actions: {
    updatePaginationAction({ commit }, newPagination) {
      commit('updatePagination', newPagination);

    },

    async fetchLogs({ commit }, { filterOptions}) {
      let level, action, target, taarget, targetKey, targetId;
      let obj = {};

      if (filterOptions) {
        level = convertObjectToArray(filterOptions.level);
        action = convertObjectToArray(filterOptions.action);
        target = convertObjectToArray(filterOptions.targets);

        taarget = filterOptions.target;
        if (taarget) {
          targetId = taarget.target_id;
          targetKey = taarget.target_key;
          obj[targetKey] = targetId;
        }
      }
      commit('updateLoading', true);
      await api
        .get(`logs/list`, {
          params: {
            limit: this.getters.limit,
            offset: this.getters.offset,
            ...obj,
            level,
            action,
            target
          },
        })
        .then((res) => {
          commit('updateLoading', false);
          commit('updateLogs', res.data.data);
          commit('updateTotalCount', res.data.total_records.count);
        })
        .catch((err) => {
          commit('updateLoading', false);
          commit('updateError', err);
        });
    },
    updatePaginationAction({commit},newPagination)
    {
      commit('updatePagination',newPagination);
    }
  },
  mutations: {
    updatePagination(state,newPagination) {
      state.pagination.limit = newPagination.limit;
      state.pagination.offset = newPagination.offset ;
    },
    updateLoading(state, newState) {
      state.loading = newState;
    },
    updateLogs(state, logs) {
      state.logs = logs;
    },
    updateError(state, error) {
      state.error = error;
    },
    updateTotalCount(state, total) {
      state.total = total;
    }
  },
}
