export const MAP_MARKERS = "/vsaas/villas/for-map";
export const VILLAS = "vsaas/villas";
export const ALARMS_STATISTICS = "vsaas/alarms/statistics";
export const OPERATIONAL_ALARMS = "vsaas/alarms";
export const OPERATIONAL_ALARMS_HISTORY = "vsaas/alarms/history";
export const ALARMS_INFO = "/vsaas/alarms/info";
export const WORK_ORDER_LIST = "/vsaas/orders";
export const WORK_ORDER_DEVICES = "/vsaas/devices";
export const VILLA_BY_ID = id => `/vsaas/villas/${id}`;
export const CUSTOMER_CONTACTS = customerId => `company/${customerId}/contacts`;
export const DEVICES_COMPANIES = deviceId => `/devices/${deviceId}/companies`;
export const ACKNOWLEDGE_ALARM = alarmId => `vsaas/alarms/${alarmId}/acknowledge`;
export const ESCALATE_ALARM = alarmId => `vsaas/alarms/${alarmId}/escalate`;
export const TERMINATE_ALARM = alarmId => `vsaas/alarms/${alarmId}/terminate`;
export const WORK_ORDER_BY_ID = id => `/vsaas/orders/${id}`;
export const WORK_ORDER_DEVICE_BY_ID = id => `/vsaas/devices/${id}`;

export default {
    MAP_MARKERS,
    VILLAS,
    ALARMS_STATISTICS,
    OPERATIONAL_ALARMS,
    OPERATIONAL_ALARMS_HISTORY,
    ALARMS_INFO,
    WORK_ORDER_LIST,
    WORK_ORDER_DEVICES,
    VILLA_BY_ID,
    CUSTOMER_CONTACTS,
    DEVICES_COMPANIES,
    ACKNOWLEDGE_ALARM,
    ESCALATE_ALARM,
    TERMINATE_ALARM,
    WORK_ORDER_BY_ID,
    WORK_ORDER_DEVICE_BY_ID,
}