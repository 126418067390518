
import api from 'src/axiosWithDelimiterFile.js';

const dayOfWeekMap = {
    0:'Sun',
    1:'Mon',
    2:'Tue',
    3:'Wed',
    4:'Thu',
    5:'Fri',
    6:'Sat'
}

export default {
    namespaced:true,
    state:{
        devices:[],
        SelectedDeviceData:null,
        SelectedDeviceProximity:[],
        interactions:[],
        pagination: {
            perPage: 3,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0
          },
        

    },
    mutations:{
        
    },
    actions:{
        init({dispatch}){

            dispatch('fetchDevices');
            dispatch('fetchInteractions');

        },
        setPage({state,dispatch},value){
            state.pagination.currentPage = value;
            dispatch('fetchDevices');

        },
        setSelectedDevice({state},device){
            let p1 = api.get(`teltonika/devices/${device.id}/measurements`);
            let p2 = api.get(`teltonika/proximity/${device.id}`);
            Promise.all([p1,p2]).then(res=>{
                    state.SelectedDeviceData = res[0].data.data;
                    state.SelectedDeviceProximity = res[1].data.data
            })

        },
        fetchDevices({state}){
            let limit = state.pagination.perPage;
            let offset = state.pagination.perPage * (state.pagination.currentPage - 1);

            api.get(`teltonika/devices/list?limit=${limit}&offset=${offset}`).then(res=>{
                if(res.status === 200){
                    state.devices = res.data.data;
                    state.pagination.total = res.data.total_records;
                }
            }).catch(err=>{console.log(err)})

        },
        fetchInteractions({state}){
            api.get(`teltonika/proximity/interactions/past-week`).then(res=>{
                    if(res.status === 200){
                        let data = res.data.data;
                        let result = [];
                        Object.keys(data).forEach(key=>{
                                let dayOfweek = dayOfWeekMap[new Date(key).getDay()];
                                let val = data[key];
                                let node = [dayOfweek,val];
                                result.push(node); 
                        });

                        state.interactions = [{values:result.reverse()}];

                    }
            }).catch(err=>{console.log(err)})
        }   
    }
}