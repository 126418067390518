var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SidebarItem',{attrs:{"link":{
    name: 'demos pages',
  }}},[_c('SidebarItem',{attrs:{"link":{
      name: 'test',
      path: '/demos/test'
  }}}),_c('SidebarItem',{attrs:{"link":{
      name: 'cloud orbit',
      path: '/demos/cloud-orbit'
  }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }