import DashboardLayout from "./../Layout/DashboardLayout.vue";
import TicketsList from "./Tickets-List.vue";
import { P, M } from "../../Permissions";

const TicketReceivers = () =>
    import('./Ticket-Receivers.vue');
const CreateTicket = () =>
    import('./Create-Ticket.vue');
const TicketMessages = () =>
    import('./Ticket-Messages.vue');

export default {
    path: '/tickets',
    component: DashboardLayout,
    meta: {
        module: M.ticketing
    },
    children: [
        {
            path: '',
            redirect:'list'
        },
        {
            path: 'list',
            name: 'Tickets List',
            component: TicketsList,
            meta: {
                permissions: [P.view_tickets]
            },
        },
        {
            path: 'Receivers',
            name: 'Ticket Receivers',
            component: TicketReceivers,
            meta: {
                permissions: [P.view_tickets]
            },
        },
        {
            path: 'Create',
            name: 'Create Ticket',
            component: CreateTicket,
            meta: {
                permissions: [P.create_ticket]
            },
        },
        {
            path: ':id/Messages',
            name: 'Ticket Messages',
            component: TicketMessages,
            meta: {
                permissions: [P.create_ticket]
            },
            props:true
        },
        {
            path: '*',
            redirect: 'list'
        }
    ]
};