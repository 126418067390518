import IntrusionComparison from "../../core/IntrusionComparison/store";
import IntrusionVillasOverall from "../../core/VillasOverall/store";
import PoliceStationStatistics from "../../core/PoliceStationStatistics/store";
import AlarmsStatistics from "../../core/AlarmsStatistics/store"
import VerificationReasons from "../../core/CountStatistics/store";
import AlarmsTypes from "../../core/CountStatistics/store";
import MoroStatistics from "../../core/MoroStatistics/store";

export default {
    namespaced:true,
    modules:{
        IntrusionComparison: IntrusionComparison,
        IntrusionComparison2: IntrusionComparison,
        IntrusionVillasOverall,
        PoliceStationStatistics,
        AlarmsStatistics,
        VerificationReasons,
        AlarmsTypes,
        MoroStatistics,
    },
    state: {
        policeStationId:undefined,
    },
    getters: {
        policeStationId(state) {
            return state.policeStationId;
        },
    },
    mutations: {
        setPoliceStationId(state,value) {
            state.policeStationId = value;
        }
    },
    actions: {
        reset({state,commit,dispatch}) {
            dispatch('IntrusionComparison/reset');
            dispatch('IntrusionVillaMap/reset');
            dispatch('PoliceStationStatistics/reset');
            dispatch('AlarmsStatistics/reset');
            dispatch('VerificationReasons/reset');
            state.policeStationId = undefined;
        }
    }
}