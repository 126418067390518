import api from "../../axiosWithDelimiterFile";
export default {
    namespaced: true,
    state: {
        isLoading: false,
        data: [
        ],
        allDone:false,
        types: {
          'operationalAlarm': {
            color: '--danger',
            icon: 'el-icon-odometer',
            routeLink: '',
            descriptionFields: {
              'Alert Level':'el-icon-sunrise-1',
              'Alert Description':'el-icon-edit-outline',
              'Alert Time':'el-icon-time',
              'Sensor':'el-icon-cpu',
              'Structure Name':'el-icon-notebook-1',
            },
          },
        },
    },
    getters: {
        list(state){
            return state.data;
        },
        loadingState(state){
            return state.isLoading;
        },
        isAllNotificationsFetched(state){
            return state.allDone;
        },
        types(state){
          return state.types;
        },
    },
    mutations: {
        changeLoadingState(state, newState) {
            state.isLoading = newState;
        },
        addNotifications(state, { data, from, to }) {
            state.data.push(...data);
        },
        allNotificationsFetched(state){
            state.allDone = true;
        },
        setAsSeen(state,index)
        {
            state.data[index].seen = true;
        },
        reset(state){// we call this method on every store when user logout
            state.data = [];
            state.isLoading = false;
            state.allDone = false;
        }
    },
    actions: {
        getNotifications({ state, dispatch, commit }) {
            if(state.allDone || state.isLoading){
                return;
            }
            commit('changeLoadingState', true);
            api.get('notifications', {
                params: {
                    limit: 5,
                    offset: state.data.length
                }
            }).then(res => {
                if(res.data.notifications.length == 0){
                    commit('allNotificationsFetched');
                }else{
                    commit('addNotifications',{data:res.data.notifications});
                }
            }).finally(_ => {
                commit('changeLoadingState', false);
            })
        },
        setAsSeen({ state, dispatch, commit }, notificationId) {
            let index;
            for(let i=0;i<state.data.length;i++)
            {
                if(state.data[i].id == notificationId)
                {
                  index = i;
                  break;
                }
            }
            commit('setAsSeen',index);

        },
    }
}
