<template>
  <card class="mx-auto">
    <div class="header d-flex justify-content-between align-items-center">
      <h2 class="card-title">{{ $t("Security Info") }}</h2>
      <span>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <i class="tim-icons icon-bullet-list-67 float-right edit-icon"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-lock">
              <span v-if="user.tfa_enable">{{ $t("Disable TFA") }}</span>
              <span v-else>{{ $t("Enable TFA") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="user.status === 'active'"
              icon="el-icon-error"
            >
              <span @click="changeStatus('inactive')">{{
                $t("inactive")
              }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="user.status === 'inactive'"
              icon="el-icon-check"
            >
              <span @click="changeStatus('active')">{{ $t("active") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="user.status === 'unverified'"
              icon="el-icon-check"
            >
              <span @click="changeStatus('verify')">{{ $t("verify") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="user.status === 'suspended'"
              icon="el-icon-check"
            >
              <span @click="changeStatus('unsuspend')">{{
                $t("unsuspend")
              }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </span>
    </div>
    <info-item
      v-if="exist(user.last_login_date)"
      :label="`${$t('last Login')} :`"
      :value="gDateFormat(user.last_login_date)"
    />
    <info-item
      v-if="exist(user.last_login_ip)"
      :label="`${$t('last login ip')} :`"
      :value="user.last_login_ip"
    />
    <info-item
      v-if="exist(user.tfa_enable)"
      :label="`${$t('account.twoFactorAuthentication')} :`"
      custom
      last
    >
      <el-tag :type="getTagType(user.tfa_enable)">
        {{ booleanMapped(user.tfa_enable) }}
      </el-tag>
    </info-item>
    <ensure-dialog
      :dialogContent="ensureContent"
      :isOpen="ensureDialogVisible"
      @close="ensureDialogVisible = false"
      @confirm="confirmChangeStatus()"
    />
  </card>
</template>

<script>
import InfoItem from "../../../components/InfoItem";
import { Tag, Dropdown, DropdownMenu, DropdownItem } from "element-ui";
import statuses from "../static/statuses";
import ensureDialog from "../../../components/ensureDialog";
import EnsureDialog from "../../../components/ensureDialog.vue";
export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    InfoItem,
    [Tag.name]: Tag,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    EnsureDialog
  },
  data: () => ({
    ...statuses,
    ensureContent: {},
    ensureDialogVisible: false
  }),
  methods: {
    exist(value) {
      return value !== null && value !== undefined && value !== "";
    },
    booleanMapped(value) {
      return value === true ? "Enabled" : "Disabled";
    },
    getTagType(value) {
      return value === true ? "success" : "danger";
    },
    changeStatus(status) {
      console.log(status);
      this.ensureContent = statuses[status];
      this.ensureDialogVisible = true;
    },
    async confirmChangeStatus() {
      await this.$store.dispatch(this.ensureContent.storeAction, {status: this.ensureContent.status})
      this.ensureDialogVisible = false;
    }
  }
};
</script>

<style></style>
