import ForkliftService from "../../services/forklift.service";

export default {
  namespaced: true,
  state: {
    loading: false,
    portfolioVehicleInfo: {},
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    portfolioVehicleInfo(state) {
      return state.portfolioVehicleInfo;
    }
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },
    setPortfolioVehicleInfo(state, portfolioVehicleInfo) {
      state.portfolioVehicleInfo = portfolioVehicleInfo;
    }
  },
  actions: {
    async fetchPortfolioVehicleInfo({ commit }) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        ForkliftService.getPortfolioVehicleInfo()
          .then(res => {
            commit("setPortfolioVehicleInfo", res.data.data);
            commit("setLoading", false);
            resolve(res.data.data);
          })
          .catch(err => {
            commit("setLoading", false);
            reject(err);
          });
      });
    }
  }
};
