<template>
  <card class="high-card-shadow">
    <div class="d-flex justify-content-between">
      <h3>Site Info</h3>
    </div>
    <div class="card-description">
      <div class="col-12">
        <div class="row">
          <div class="col-5">
            <label>Community:</label>
          </div>
          <div class="col-7 blue">{{o.Community_English}}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-5">
            <label>Structure:</label>
          </div>
          <div class="col-7 blue">{{s.parent_struture.structure_name}}</div>
        </div>
      </div>
      <div class="col-12" v-if="s.parent_floor">
        <div class="row">
          <div class="col-5">
            <label>Floor:</label>
          </div>
          <div class="col-7 blue">{{s.parent_floor.name}}</div>
        </div>
      </div>
      <div class="col-12" v-if="s.parent_space">
        <div class="row">
          <div class="col-5">
            <label>Space:</label>
          </div>
          <div class="col-7 blue">{{getSpaceName(s.parent_space)}}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-5">
            <label><template v-if="!s.parent_space"> 
                Indoor 
            </template> Space:</label>
          </div>
          <div class="col-7 blue">{{getSpaceName(s)}}</div>
        </div>
      </div>
      <!-- <div class="col-12">
        <div class="row">
          <div class="col-5">
            <label>FMC:</label>
          </div>
          <div class="col-7 blue">{{d.fmc}}</div>
        </div>
      </div> -->
    </div>
  </card>
</template>

<script>
export default {
    methods:{
        getSpaceName(space){
            return (space.space_id || !space.floor_id)?space.name:space.number;
        }
    },
  computed: {
    o() {
      return this.$store.getters.selectedMO;
    },
    s(){
        return this.o.space;
    }
  },
};
</script>

<style scoped>
.blue {
  color: #1d8cf8;
}
</style>