<template>
  <span
    class="icon-container d-flex justify-content-center align-items-center"
    :style="solutionBackgroundStyle"
  >
    <inline-svg
      :src="iconPath"
      style="width: 100%; height: 100%"
    />
  </span>
</template>

<script>
export default {
  name: 'SolutionIcon',
  props: {
    solutionName: {
      type: String,
      required: true,
      default: 'default'
    }
  },
  data() {
    return {
      allSolutionsBackground: {
        'swimming pool monitoring': '#E8E7FF',
        'water pressure monitoring': '#DAF7E8',
        'indoor air monitoring': '#F0F0F7',
        'outdoor air monitoring': '#FFF4E5',
        'cold storage': '#FFE2E6',
        'default': '#eee'
      },
      iconPath: ''
    };
  },
  computed: {
    solutionBackgroundStyle() {
      return {
        background: this.allSolutionsBackground[this.solutionName.toLowerCase()]
      };
    }
  },
  created() {
    try {
      this.iconPath = require(`@/assets/icons-2/solutions-icons/${this.solutionName.toLowerCase()}.svg`);
    } catch {
      console.warn('ICON NOT FOUND!')
    }
  }
}
</script>

<style>
.icon-container {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
  margin: 0 5px;
}
</style>