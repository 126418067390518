import api from "src/axiosWithDelimiterFile";
import { IntrusionConfigurations } from "./configuration";
import { resolvePromiseForObjectOrNull } from "../../common/wrappers/promise-resolver";



export class IntrusionActionsOnAlarmsService {


    byMoroAcknowledgeAlarm(alarmId,comment) {
        const promise = api.post(IntrusionConfigurations.BY_MORO_POST_ACKNOWLEDGE_ALARM(alarmId), { comment });
        return resolvePromiseForObjectOrNull(promise);
    }


    byPoliceAcknowledgeAlarm(alarmId, data) {
        const promise = api.post(IntrusionConfigurations.BY_POLICE_POST_ACKNOWLEDGE_ALARM(alarmId), data);
        return resolvePromiseForObjectOrNull(promise);
    }

    verifyAlarm(alarmId, data) {
        const promise = api.post(IntrusionConfigurations.POST_VERIFY_ALARM(alarmId), data);
        return resolvePromiseForObjectOrNull(promise);
    }


    terminateAlarm(alarmId, comment) {
        const promise = api.post(IntrusionConfigurations.POST_TERMINATE_ALARM(alarmId), { comment });
        return resolvePromiseForObjectOrNull(promise);
    }

    commentOnAlarm(comment) {
        const promise = api.post(IntrusionConfigurations.POST_COMMENT_ALARM, { comment });
        return resolvePromiseForObjectOrNull(promise);
    }
}