
import api from "src/axiosWithDelimiterFile";
export default class ForkliftReportService {

  static async getForkliftsData(payload) {
    const response = await api.get(`/teltonika/forklift/reparation?limit=${payload.limit}&offset=${payload.offset}`)
    return response;
  }
  // --------------------------------------- //
  static async getForkliftReport(forkID) {
    const response = await api.get(`/teltonika/forklift/reparation/${forkID}`)
    return response;
  }
  static async getAutoshops() {
    const response = await api.get(`/teltonika/forklift/reparation/autoshops`)
    return response;
  }
  static async updateReport(forkID, data) {
    const response = await api.patch(`/teltonika/forklift/reparation/${forkID}`, data)
    return response;
  }

}