<template>
  <div class="fixed-plugin" v-click-outside="closeDropDown">
    <div class="dropdown show-dropdown" :class="{ show: isOpen }">
      <a data-toggle="dropdown" class="settings-icon">
        <i class="fa fa-cog fa-2x" @click="toggleDropDown"></i>
      </a>
      <ul class="dropdown-menu" :class="{ show: isOpen }">
        <li class="header-title">Sidebar Background</li>
        <li class="adjustments-line">
          <a class="switch-trigger background-color">
            <div class="badge-colors text-center">
              <span
                v-for="item in sidebarColors"
                :key="item.color"
                class="badge filter"
                :class="[`badge-${item.color}`, { active: item.value === backgroundColor }]"
                :data-color="item.color"
                @click="changeSidebarBackground(item);"
              ></span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>

        <li class="header-title">Sidebar Mini</li>
        <li class="adjustments-line">
          <div class="togglebutton switch-sidebar-mini">
            <span class="label-switch">OFF</span>
            <base-switch v-model="sidebarMini" @input="minimizeSidebar"></base-switch>
            <span class="label-switch label-right">ON</span>
          </div>

          <div class="togglebutton switch-change-color mt-3">
            <span class="label-switch">LIGHT MODE</span>
            <base-switch v-model="darkMode" ></base-switch>
            <span class="label-switch label-right">DARK MODE</span>
          </div>
        </li>

        <li class="button-container mt-4">
          <!-- <a
            href="https://demos.creative-tim.com/vue-black-dashboard-pro/documentation"
            target="_blank"
            rel="noopener"
            class="btn btn-default btn-block btn-round"
          >
            Documentation
          </a>
          <a
            href="https://creative-tim.com/product/vue-black-dashboard-pro"
            target="_blank"
            rel="noopener"
            class="btn btn-primary btn-block btn-round"
          >
            Buy for $59
          </a>
          <a
            href="https://demos.creative-tim.com/vue-black-dashboard"
            target="_blank"
            rel="noopener"
            class="btn btn-info btn-block btn-round"
          >
            Free Version
          </a>-->
            <span class="d-block header-title text-center py-2 text-white settings-icon">Dashboards</span>

          <div class="custom-height">
            <span
              class="d-block text-center py-2 text-white settings-icon"
              :class="[item.active ? 'font-weight-bold bg-primary':'']"
              v-for="(item,index) in dashboards"
              @click="changeCurrentDash(item,index)"
              :key="index"
            >{{item.name}}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { BaseSwitch } from "src/components";
import {mapState,mapActions} from 'vuex';

export default {
  name: "sidebar-share",
  components: {
    BaseSwitch
  },
  props: {
    backgroundColor: String
  },
  data() {
    return {
      dashboards: [{ name: "default", active: true }],
      sidebarMini: true,
      //darkMode: true,
      isOpen: false,
      sidebarColors: [
        { color: "primary", active: false, value: "primary" },
        { color: "vue", active: true, value: "vue" },
        { color: "info", active: false, value: "blue" },
        { color: "success", active: false, value: "green" },
        { color: "warning", active: false, value: "orange" },
        { color: "danger", active: false, value: "red" }
      ]
    };
  },
  computed:{
    ...mapState({
      preferences:state=>state.preferences
    }),
    darkMode:{
      get(){
        let dark_mode = this.preferences.dark_mode;
        return typeof dark_mode === 'boolean' ? dark_mode : true;
      },
      set(value){
        this.setPreferences({dark_mode:value});
      }
    }
  },
  created() {
    this.getUserDashboards();
  },
  mounted(){
          this.$eventBus.$on('getNewDashboards',()=>{
            this.getUserDashboards();
          })

  },  
  methods: {
    ...mapActions([
      'setPreferences'
    ]),
    changeCurrentDash(item,index) {
      
      for (let i in this.dashboards) {
        this.dashboards[i].active = false;
      }
      
      this.dashboards[index].active = true;
      

      this.$api
        .patch(`/user/dashboard/${item.id}/activate`)
        .then(res => {
          console.log(res)
          this.$eventBus.$emit("changeCurrentDashboard", this.dashboards[index].id);
         
          if(this.$route.name != 'Dashboard'){
          this.$router.push("/dashboard");

          }
        })
        .catch(err => {});
    },
    async getUserDashboards() {
      this.$api
        .get("user/dashboard")
        .then(res => {
          if (res) {
            let dashboards = res.data.dashboards;
             let default_dash = res.data.default;
             // to disable other dashes
             if(Object.keys(default_dash).length != 0 && default_dash.constructor === Object){
              default_dash.active = 0;
              default_dash.name = 'default dashboard';
              default_dash.id = -2;
              dashboards.unshift(default_dash);
            }

            
              // let dash = {
              //   active: 0,
              //   id: -2,
              //   name: "default dashboard"
              // };
              

              this.dashboards = dashboards;
            

            let flag = false;
            for (let i in this.dashboards) {
              if (this.dashboards[i].active) {
                flag = true;
              }
            }

            if (!flag) {
              this.dashboards[0].active = 1;
            }
          }
        })
        .catch(err => {});
    },
    toggleDropDown() {
      this.isOpen = !this.isOpen;
    },
    closeDropDown() {
      this.isOpen = false;
    },
    // toggleList(list, itemToActivate) {
    //   list.forEach(listItem => {
    //     listItem.active = false;
    //   });
    //   itemToActivate.active = true;
    // },
    changeSidebarBackground(item) {
      this.$emit("update:backgroundColor", item.value);
      //this.toggleList(this.sidebarColors, item);
     
    },
    // toggleMode(type) {
    //   let docClasses = document.body.classList;
    //   if (type) {
    //     docClasses.remove("white-content");
    //     this.$store.commit("changeThem", "dark");
    //   } else {
    //     docClasses.add("white-content");
    //     this.$store.commit("changeThem", "light");
    //   }
      
    // },
    
 
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    }
  }
};
</script>
<style scoped lang="scss">
@import "~@/assets/sass/dashboard/custom/variables";

.custom-height{
  max-height: 190px;
  overflow-y: auto;
}
.settings-icon {
  cursor: pointer;
}

.badge-vue {
  background-color: $vue;
}
</style>
