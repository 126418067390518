import ActionsTypes from "../types/actions-types";
import MutationsTypes from "../types/mutations-types";

import { operationalAlarmsActions } from './operational-alarms/operational-alarms-actions';
import { customerActions } from './customer/customer-actions';

export const actions = {
  /**
   *
   * @param commit
   */
  [ActionsTypes.RESET_THE_WHOLE_STATE_ACTION]: ({ commit }) => {
    commit(MutationsTypes.RESET_THE_WHOLE_STATE_MUTATION);
  },
  ...operationalAlarmsActions,
  ...customerActions
};
