import ScooterAlarms from './core/Alarms/store'
import ScooterBattery from './core/Battery/store'
import ScooterCustomerHistory from './core/CustomerHistory/store'
import ScooterHeader from './core/Header/store'
import ScooterInfo from './core/Info/store'
import ScooterMap from './core/Map/store'
import ScooterRentals from './core/Rentals/store'
import ScooterSpeed from './core/Speed/store'
import ScooterPayments from './core/Payment/store'
import ScooterService from '../../services/globalMobility.service'
export default {
  namespaced: true,
  modules: {
    ScooterAlarms,
    ScooterBattery,
    ScooterCustomerHistory,
    ScooterHeader,
    ScooterInfo,
    ScooterMap,
    ScooterRentals,
    ScooterSpeed,
    ScooterPayments
  },
  state: {
    mileData: [],
    mileLoading: false,
    timeData: [],
    timeLoading: false,
  },
  getters: {
    mileData(state){
      return state.mileData
    },
    mileLoading(state){
      return state.mileLoading
    },
    timeData(state){
      return state.timeData
    },
    timeLoading(state){
      return state.timeLoading
    },
  },
  mutations: {
    SET_MILEDATA(state, payload){
      state.mileData = payload
    },
    SET_MILELOADING(state, payload){
      state.mileLoading = payload
    },
    SET_TIMEDATA(state, payload){
      state.timeData = payload
    },
    SET_TIMELOADING(state, payload){
      state.timeLoading = payload
    },
  },
  actions: {
    async fetchMileageData({ commit }, payload) {
      return new Promise((resolve, reject) => {
          commit('SET_MILELOADING', true);
          ScooterService.getMileage(payload)
              .then((res) => {
                  commit('SET_MILEDATA', res.data.data)
                  commit("SET_MILELOADING", false);
                  resolve(res.data.data)
              })
              .catch((err) => reject(err));
      });
    },
    async fetchTimeData({ commit }, payload) {
      return new Promise((resolve, reject) => {
          commit('SET_TIMELOADING', true);
          ScooterService.getTime(payload)
              .then((res) => {
                  commit('SET_TIMEDATA', res.data.data)
                  commit("SET_TIMELOADING", false);
                  resolve(res.data.data)
              })
              .catch((err) => reject(err));
      });
    },
  }
}