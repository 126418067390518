<template>
  <div class="font-color-1">
    <div class="chartContainer pt-2" style="height: 200px">
      <zingchart
        ref="chart"
        v-if="config"
        :data="config"
        style="text-transform: capitalize; width: 400px !important"
        :height="'275px'"
        :width="'100%'"
        dir="ltr"
      ></zingchart>
      <div v-else class="temmp"></div>
    </div>

    <div class="d-flex justify-content-center pt-2 mb-2">
      <div class="periods infos" style="width: 260px">
        <span class="stats">
          <div>
            {{ $t("minimum") }}
          </div>
          <span>
            {{ fixNumber(stats.min) }} &nbsp;
            <span class="s-c-unit" v-if="typeof stats.max == 'number'">{{
              unit
            }}</span>
          </span>
        </span>
        <span class="stats">
          <div>
            {{ $t("average") }}
          </div>
          <span>
            {{ fixNumber(stats.avg) }} &nbsp;
            <span class="s-c-unit" v-if="typeof stats.max == 'number'">{{
              unit
            }}</span>
          </span>
        </span>
        <span class="stats">
          <div>
            {{ $t("maximum") }}
          </div>
          <div>
            {{ fixNumber(stats.max) }} &nbsp;
            <span class="s-c-unit" v-if="typeof stats.max == 'number'">{{
              unit
            }}</span>
          </div>
        </span>
      </div>
    </div>

    <div class="d-flex w-100" v-if="title">
      <div class="col-12 periods text-center">
        <span>{{ title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { round } from "@/util/helper.js";
import { load } from 'src/components/Charts/Zingchart/helpers/loader'
// import zingchart from 'zingchart/es6'

const drawValue = function(p, value, inside = false) {
  var graph = zingchart.exec(p.id, 'getobjectinfo', {
    object : 'graph',
    name : '0'
  });
  var info = zingchart.exec(p.id, 'getobjectinfo', {
    object : 'scale',
    name : 'scale-r'
  });

  var center = [info.x + info.width/2, info.y + info.height/2];
  var angles = [270 - info.aperture/2, 270 + info.aperture/2];
  var ratio = (value - info.minValue) / (info.maxValue - info.minValue);
  var angle = angles[0] + info.aperture * ratio;
  var r = 0.85 * Math.min(info.width, info.height) / 2;
  var scale = inside ? 1.10 : 1.55

  var xy = [
    center[0] + r * scale * Math.cos(angle * 2 * Math.PI / 360) - graph.x,
    center[1] + r * scale * Math.sin(angle * 2 * Math.PI / 360) - graph.y
  ];
  zingchart.exec(p.id, 'addobject', {
    type : 'label',
    data : {
      text : value,
      fontColor: '#999',
      anchor : 'c',
      x : xy[0],
      y : xy[1]
    }
  });
}

export default {
  props: ["title", "configrations", "stats", "sensorTypeId", "ranges"],
  data: () => ({
    chartID: ''
  }),
  mounted() {
    this.chartID = this.$refs.chart.$el.id

    load().then(() => {
      zingchart.bind(this.chartID, 'complete', (p) => {
        this.setLabels(this.chartID, this.ranges)
      });

      this.setLabels(this.chartID, this.ranges)
    })
  },
  methods: {
    fixNumber(x) {
      return typeof x == "number" ? round(x) : x;
    },
    getConfigGauge(ranges = [], stats, configs = {}) {
      const colors = {
        good: "rgb(65, 181, 129)",
        poor: "rgb(243, 165, 29)",
        ok: "rgb(255, 218, 131)",
        problem: "rgb(229, 58, 88)",
      };

      const minValue = Math.min(...(ranges || []).map((v) => v.start_range));
      const maxValue = Math.max(...(ranges || []).map((v) => v.end_range));
      const totalRange = Math.abs(maxValue - minValue);
      const sideRange = Math.ceil(totalRange / 10);

      const min = minValue - sideRange;
      const max = maxValue + sideRange;

      const maxStat = Math.max(stats.min, stats.avg, stats.max);
      const minStat = Math.min(stats.min, stats.avg, stats.max);

      const getPercent = (val, side) => {
        let from, to;

        if (side == "right") {
          from = maxValue;
          to = maxStat;
        } else {
          from = minStat;
          to = minValue;
        }

        val -= from;
        to -= from;

        return val / to;
      };

      const getValue = (val) =>
        val > maxValue
          ? maxValue + getPercent(val, "right") * sideRange
          : val < minValue
          ? min + getPercent(val, "left") * sideRange
          : val;
      const config = {
        backgroundColor: "transparent",
        type: "gauge",
        plotarea: {
          marginTop: 50,
        },
        scale: {
          sizeFactor: '120%'
        },
        plot: {
          size: "100%",
          valueBox: {
            placement: "center",
            text: "",
            color: "white",
            borderRadius: "30%",
            padding: "0 10px",
            border: "none",
            fontSize: 200,
          },

          backgroundColor: "#000000",
        },
        tooltip: {
          borderRadius: 150,
          text: "%t",
        },
        scaleR: {
          aperture: 210,
          minValue: min,
          maxValue: max,
          itemsOverlap: true,
          maxItems: ranges.length ? 100000 : 0,
          center: {
            visible: false,
          },
          tick: {
            visible: false,
          },
          ring: {
            backgroundColor: "#344675",
            size: 14,
          },
        },
        series: [
          {
            values: [getValue(stats.min)],
            indicator: [8, -5, 80, 1, 0],
            borderRadius: 0,
            borderWidth: 4,
            borderColor: "gray",
            backgroundColor: "none",
            size: "73%",
            csize: 8,
            text: stats.min ? stats.min.toString() : "",
            animation: {
              effect: 2,
              method: 1,
              sequence: 4,
              speed: 900,
            },
          },
          {
            values: [getValue(stats.avg)],
            indicator: [8, -5, 80, 1, 0],
            borderRadius: 0,
            borderWidth: 4,
            borderColor: "gray",
            backgroundColor: "none",
            size: "73%",
            csize: 8,
            text: stats.avg ? stats.avg.toString() : "",
            animation: {
              effect: 2,
              method: 1,
              sequence: 4,
              speed: 900,
            },
          },
          {
            values: [getValue(stats.max)],
            indicator: [8, -5, 80, 1, 0],
            borderRadius: 0,
            borderWidth: 4,
            borderColor: "gray",
            backgroundColor: "none",
            size: "73%",
            csize: 8,
            text: stats.max ? stats.max.toString() : "",
            animation: {
              effect: 2,
              method: 1,
              sequence: 4,
              speed: 900,
            },
          },
        ],
      };

      const good = (ranges || []).find(
        (s) => s.status == "good" || s.status == "default"
      );

      if (good) {
        config.scaleR.ring.rules = ranges.map((r) => {
          return {
            rule: ` %v >= ${r.start_range} && %v < ${r.end_range}`,
            backgroundColor: colors[r.status],
          };
        });
        config.scaleR.ring.rules.unshift({
          rule: `%v < ${minValue}`,
          backgroundColor: "#7E1527",
        });
        config.scaleR.ring.rules.push({
          rule: `%v >= ${maxValue}`,
          backgroundColor: "#7E1527",
        });

        config.scaleR.item = {
          offsetR: 0,
          fontSize: 10,
          visible: 'false',
          rules: [
            {
              rule: `%v > ${maxValue} && %v != ${max}`,
              visible: false,
            },
            {
              rule: `%v < ${minValue} && %v != ${min}`,
              visible: false,
            },
            {
              rule: `%v == ${min}`,
              text: "Out Of Range",
              offsetX: 60,
              visible: true,
              offsetY: 7,
              // angle:-90, offsetY:-70,fontSize:15
            },
            {
              rule: `%v == ${max}`,
              text: "Out Of Range",
              offsetX: -60,
              visible: true,
              offsetY: 7,
              // angle:90, offsetY:-70,fontSize:15
            },
          ],
        };
        let rule = `%v != ${minValue}`;
        ranges.forEach((r) => {
          rule += ` && %v != ${r.end_range}`;
        });

        config.scaleR.item.rules.push({
          rule,
          visible: false,
        });
      }

      update(config, configs);

      return config;

      function update(obj, newObj) {
        for (let prop in newObj) {
          let val = newObj[prop];

          if (typeof val == "object") update(obj[prop], val);
          else obj[prop] = val;
        }
      }
    },
    setLabels(chartID, ranges = []) {
      const minValue = Math.min(...(ranges || []).map((v) => v.start_range));
      const maxValue = Math.max(...(ranges || []).map((v) => v.end_range));

      const getPercent = (val) => {
        let from = minValue, to = maxValue;

        to -= from;

        return (val / to) * 100
      };

      let prevRangeClose = false, prevValue = 0

      if (ranges.length) {
        drawValue({id: chartID}, ranges[0].start_range)
        prevValue = ranges[0].start_range

        ranges.forEach((range, i) => {
          if (range.end_range != prevValue) {
            let diff = Math.abs(range.end_range - prevValue)
            prevRangeClose = !prevRangeClose ? getPercent(diff) < 5 : false
          }

          drawValue({id: chartID}, range.end_range, prevRangeClose)
          prevValue = range.end_range
        })
      }
    }
  },
  computed: {
    unit() {
      return this.$store.getters.units[this.sensorTypeId];
    },
    config() {
      return this.getConfigGauge(this.ranges, this.stats);
    },
  },
};
</script>

<style scoped>
.temmp {
  display: block;
  width: 200px;
  height: 200px;
  background-color: rgba(245, 245, 245, 0.9);
}

div.chartContainer {
  margin: auto;
  display: flex;
  justify-content: center;
}

.bullit {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block !important;
  margin: 0 3px;
  text-decoration: capitalize;
}
div.periods {
  font-size: 0.9rem;
  letter-spacing: 0.7px;
}

div.infos {
  display: flex;
  justify-content: space-evenly;
  font-size: 0.9rem;
}

span.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: capitalize;
  height: 40px;
  font-size: .7rem;
  font-weight: bolder;
}

.s-c-unit {
  font-size: 0.7rem;
  padding: 0 !important;
  text-transform: lowercase;
}
</style>