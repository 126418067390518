<template>
  <div class="row position-relative">


    <div v-if="showModal" class="modal fadeIn modal-active">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" @click="showModal=false" class="close">
              <span>&times;</span>
            </button>
            <h4 class="modal-title">{{$t("Create New Dashboard")}}</h4>
          </div>
          <div class="modal-body">
            <slot>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="dropdown dropdown">
                    <button
                      data-toggle="dropdown"
                      class="dropdown-toggle no-caret dropdown-toggle btn btn-primary btn-block searchdropdown"
                      @click="addNewDash()"
                      :disabled="newDashName.length == 0"
                    >{{$t("Create")}}</button>
                  </div>
                </div>
                <input
                  type="text"
                  :placeholder="$t('Dashboard Name')"
                  class="form-control color-black"
                  v-model="newDashName"
                />
              </div>
            </slot>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn mx-auto mb-2" @click="showModal=false">{{$t("Close")}}</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showEditModal" class="modal fadeIn modal-active">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" @click="showEditModal=false" class="close">
              <span>&times;</span>
            </button>
            <h4 class="modal-title">{{$t("Change")}} {{$t("Dashboard Name")}}</h4>
          </div>
          <div class="modal-body">
            <slot>
              <div style="min-height:50px;">
                <div class="input-group" v-if="objectDashToEdit != null">
                  <div class="input-group-prepend"></div>
                  <input
                    type="text"
                    :placeholder="$t('Dashboard Name')"
                    class="custom-input"
                    v-model="objectDashToEdit.name"
                  />
                </div>
              </div>
            </slot>
          </div>
          <div class="modal-footer mx-auto">
            <button type="button" class="btn my-2 mx-3" @click="showEditModal=false">{{$t("Close")}}</button>
            <button
              type="button"
              class="btn my-2 mx-3"
              @click="saveDashNewName()"
              :disabled="objectDashToEdit.name.length == 0"
            >{{$t("Save")}}</button>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div
      class="d-block d-lg-none py-3 mb-2 pl-0 w-100 border-left-0 border-right-0 rounded-0"
      :class="[isDark ? 'text-white bg-custom':'text-black custom-subnav-border']"
    >
      <!-- <template v-for="(item,index) in dashboards"> -->
      <span
        style="padding: 21px 7px 19px;"
        class="pointer font-size-16 font-weight-bold selected-dash-bg"
      >{{dashboards[0].name.length > 20 ? dashboards[0].name.slice(0, 20).concat('...') :dashboards[0].name}}&nbsp;(active)</span>
      <!-- </template> -->

      <div
        v-if="dashboards.length >= 2"
        class="dropdown d-inline-block"
        :class="[dropDownFlag ? 'show':'hide']"
        @click="dropDownFlag = !dropDownFlag"
      >
        <i class="fas fa-bars font-size-20 mt-1 ml-3 pointer" id="dropdownMenuLink"></i>

        <div class="dropdown-menu ml-2" aria-labelledby="dropdownMenuLink">
          <template v-for="(item,index2) in dashboards">
            <div
              :key="index2"
              v-if="index2 >= 1"
              class="pointer font-size-16 py-2 pl-3"
              :class="[item.active == true ? 'position-relative under-line font-weight-bold':'']"
              @click="changeCurrentDashAndFlip(item,index2,'small')"
            >{{item.name}}</div>
          </template>
        </div>
      </div>

      <div
        class="dropdown custom-dropdown d-inline-block float-right dropleft"
        :class="[dropDownFlagSettings ? 'show':'hide']"
        @click="dropDownFlagSettings = !dropDownFlagSettings"
      >
        <i class="fas fa-ellipsis-v font-size-20 mr-4 pr-3 mt-1 pointer" id="dropdownMenuLink2"></i>

        <div
          class="dropdown-menu"
          style="margin: 27px -58px 0 0px;"
          aria-labelledby="dropdownMenuLink2"
        >
          <div class="pointer font-size-16 py-2 pl-3" @click="editDashboard()">{{$t("Rename")}}</div>
          <div class="pointer font-size-16 py-2 pl-3" @click="removeCurrentDash()">{{$t("Remove")}}</div>
          <hr class="my-1" />
          <div
            class="pointer font-size-16 py-2 pl-3"
            @click="setAsDefaultDash()"
          >{{$t("set as default")}}</div>
          <div
            class="pointer font-size-16 py-2 pl-3"
            @click="showModal=true"
          >{{$t("new dashboard")}}</div>
        </div>
      </div>
    </div>
    <div
      class="d-block d-lg-none text-center font-size-21 mt-4 mb-0 w-100"
      :class="[isDark ? 'text-white':'']"
    >{{layout.name}}</div>
    <div
      class="d-block d-lg-none text-center font-size-14 mb-4 w-100"
      :class="[isDark ? 'text-white':'']"
      v-if="layout.name != undefined"
    >selected dashboard</div>
    <div v-if="showHideWigetsListFlag" class="d-inline-block col-sm-12 col-lg-2 mb-4 mb-lg-0 pt-2">
      <ul class="list-group" style="max-width:400px">
        <li class="list-group-item text-info border-0">
          <span class="d-inline-block font-weight-bold font-size-21">{{$t("Widgets List")}}</span>
        </li>
        <li
          class="list-group-item text-info pointer"
          v-for="(item,index) in widgetList"
          :key="index"
          @click="addNewWidget(item)"
        >
          <i class="tim-icons icon-simple-add pr-2 pb-1"></i>
          <span class="d-inline-block">{{item.name}}</span>
        </li>
      </ul>
    </div>
    <div class="col pt-2 d-inline-block">
      <!-- zxzx rgb(39, 41, 61) -->
      <div
        class="d-none d-lg-block py-3 pl-4 pr-2 mb-2"
        :class="[isDark ? 'text-white bg-custom':'text-black custom-subnav-border']"
        style="margin-left: 10px !important;margin-right: 10px !important;"
      >
        <template v-for="(item,index) in dashboards">
          <span
            :key="index"
            v-if="index < 4"
            style="padding: 21px 18px 19px 18px"
            class="pointer font-size-16"
            :class="[item.active == true ? 'font-weight-bold selected-dash-bg':'', (item.id == layout.id && !item.active) ?'position-relative under-line':'']"
            @click="changeCurrentDash(item)"
          >{{item.name}}</span>
        </template>

        <div
          v-if="dashboards.length > 4"
          class="dropdown d-inline-block"
          :class="[dropDownFlag ? 'show':'hide']"
          @click="dropDownFlag = !dropDownFlag"
        >
          <i class="fas fa-bars font-size-20 mr-5 mt-1 ml-3 pointer" id="dropdownMenuLink"></i>

          <div class="dropdown-menu ml-2" aria-labelledby="dropdownMenuLink">
            <template v-for="(item,index2) in dashboards">
              <div
                :key="index2"
                v-if="index2 >= 4"
                class="pointer font-size-16 py-2 pl-3"
                :class="[item.active == true ? 'position-relative under-line font-weight-bold':'']"
                @click="changeCurrentDashAndFlip(item,index2,'large')"
              >{{item.name}}</div>
            </template>
          </div>
        </div>

        <div
          class="dropdown custom-dropdown d-inline-block float-right dropleft"
          :class="[dropDownFlagSettings ? 'show':'hide']"
          @click="dropDownFlagSettings = !dropDownFlagSettings"
        >
          <i class="fas fa-ellipsis-v font-size-20 mr-2 mt-1 pointer" id="dropdownMenuLink2"></i>

          <div
            class="dropdown-menu"
            style="margin: 27px -58px 0 0px;"
            aria-labelledby="dropdownMenuLink2"
          >
            <div class="pointer font-size-16 py-2 pl-3" @click="editDashboard()">Rename</div>
            <div class="pointer font-size-16 py-2 pl-3" @click="removeCurrentDash()">Remove</div>
            <hr class="my-1" />
            <div class="pointer font-size-16 py-2 pl-3" @click="setAsDefaultDash()">set as default</div>
            <div class="pointer font-size-16 py-2 pl-3" @click="showModal=true">new dashboard</div>
          </div>
        </div>

        <!-- <button type="button" @click="" class="btn-sm btn-info btn-simple float-right mr-4">
        <i class="fas fa-th-large d-inline-block mr-2 font-size-16"></i>
        <span class="d-none d-lg-inline-block">New Dashboard</span>
        </button>-->
        <button
          @click="showHideWigetsListFlag = !showHideWigetsListFlag"
          type="button"
          class="btn-sm btn-info btn-simple float-right mr-3"
          :class="[isDark ? 'text-white':'']"
        >
          <span v-if="!showHideWigetsListFlag" class="d-inline-block">{{$t("show widgets")}}</span>
          <span v-else class="d-inline-block">{{$t("hide widgets")}}</span>
        </button>
        <button
          @click="saveUserDashboardNewChanges"
          type="button"
          class="btn-sm btn-info btn-simple float-right mr-2"
          :class="[isDark ? 'text-white':'']"
          :disabled="waitingFlag"
        >
          <i
            v-if="waitingFlag"
            class="fas fa-spin fa-circle-notch d-inline-block mr-2 font-size-16"
          ></i>
          <span v-if="!waitingFlag" class="d-inline-block">{{$t("Save Changes")}}</span>
          <span v-if="waitingFlag" class="d-inline-block">{{$t("please wait")}}</span>
        </button>
      </div>
      <grid-layout
        style="background:inherit;min-height:800px"
        :layout="layout.data"
        :col-num="12"
        :cols="customCols"
        :row-height="20"
        :is-draggable="true"
        :is-resizable="true"
        :responsive="true"
        :is-mirrored="false"
        :vertical-compact="true"
        :margin="[10, 10]"
        :use-css-transforms="true"
      >
        <h1
          v-if="layout.data.length == 0"
          style="color:black"
          class="text-center pt-5 text-info"
        >{{$t("Add New Widgets")}}</h1>

        <grid-item
          :ref="'item'+item.i"
          :id="'item'+item.i"
          style="background: inherit;padding: 0;"
          class="custom-border col-sm-12"
          v-for="item in layout.data"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
          :is-resizable="true"
          drag-allow-from=".custom-draggable-icon-location"
        >
          <div class="dodo-pa" style="height: 100%;overflow: hidden;" :style="{background: isDark}">
            <component
              class="m-0"
              :ref="'com'+item.i"
              :is="item.componentName"
              v-bind="item.props?item.props:item.data"
              :i="item.i"
              :predefined="item.data"
              @valid="item.data.populateWith = $event"
            ></component>
            <i
              v-if="layout.id != -2"
              @click="deleteItem(item.i)"
              class="tim-icons icon-simple-remove position-absolute custom-location"
            ></i>
            <i class="fas fa-arrows-alt position-absolute custom-draggable-icon-location"></i>
          </div>
        </grid-item>
      </grid-layout>
    </div>
  </div>
</template>
   <script>
import VueGridLayout from "vue-grid-layout";

import DeviceStatus from "./MainDashboard/components/DeviceStatus";
//import AlarmMap from './components/AlarmMap';
import { Select, Option } from "element-ui";
// import StructureComparsion from "@/pages/Structure/Dashboards/components/Structure-Comparsion";
// import DeviceComparison from "@/pages/Structure/Dashboards/components/Device-Comparison";

import DevicesStatistics from "@/pages/SWAM/core/Structures-Statistics/devices.vue"
import OperationalAlarmsStatistics from "@/pages/SWAM/core/Structures-Statistics/operational-alarms.vue"
import StatusAlarmsStatistics from "@/pages/SWAM/core/Structures-Statistics/status-alarms.vue"
import StructuresLocation from "./SWAM/core/StructuresLocations/index.vue"
import StructureStatistics from "./SWAM/core/Statistics/Overall.vue"
import StructureComparison from "./SWAM/core/Structure-Comparison/index"
import DeviceComparison from "./SWAM/core/Device-Comparison/index"
import StructuresWithDevicesList from "./SWAM/components/StructuresWithDevicesList.vue"
import AlarmsCount from "./SWAM/core/Alarms-Count/index";

import SolutionStatistics from "./SWAM/core/Solution-Statistics/index"


export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    DeviceStatus,
    AlarmsCount,
    DevicesStatistics,
    OperationalAlarmsStatistics,
    StatusAlarmsStatistics,
    StructuresLocation,
    StructureStatistics,
    StructureComparison,
    DeviceComparison,
    StructuresWithDevicesList,
    SolutionStatistics
  },

  computed: {
    isDark() {
      return this.$store.getters.themMode == "light" ? false : true;
    },
  },

  created() {
    this.getUserDashboards();
  },
  data() {
    return {
      dropDownFlag: false,
      dropDownFlagSettings: false,
      customCols: { lg: 12, md: 12, sm: 1, xs: 1, xxs: 1 },
      objectDashToEdit: null,
      newDashName: "",
      dashboards: [{ name: "default", active: true }],
      showModal: false,
      showEditModal: false,
      showHideWigetsListFlag: true,
      waitingFlag: false,
      layout: {
        id: null,
        data: [],
      },
      widgetList: [
        {
          x: 0,
          y: 0,
          w: 3,
          h: 5.5,
          componentName: "DevicesStatistics",
          name: "Devices Statistics",
          data: {},
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 5.5,
          componentName: "OperationalAlarmsStatistics",
          name: "Operational Alarms Statistics",
          data: {},
        },
        {
          x: 0,
          y: 0,
          w: 3,
          h: 5.5,
          componentName: "StatusAlarmsStatistics",
          name: "Status Alarms Statistics",
          data: {},
        },
        {
          x: 0,
          y: 0,
          w: 5,
          h: 11,
          componentName: "StructuresLocation",
          name: "Structures Location",
          data: {},
        },
        {
          x: 0,
          y: 0,
          w: 7,
          h: 11,
          componentName: "StructureStatistics",
          name: "Structures Statistics",
          data: {},
        },
        {
          x: 0,
          y: 0,
          w: 12,
          h: 16,
          componentName: "AlarmsCount",
          name: 'Alarms Count',
          data: {},
        },
        {
          x: 0,
          y: 0,
          w: 12,
          h: 37.5,
          componentName: "DeviceComparison",
          name: 'Device Comparison',
          data: {},
        },
        {
          x: 0,
          y: 0,
          w: 12,
          h: 36,
          componentName: "StructureComparison",
          name: 'Structure Comparison',
          data: {},
        },
        {
          x: 0,
          y: 0,
          w: 12,
          h: 15,
          componentName: "StructuresWithDevicesList",
          name: 'Structures List',
          data: {},
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 22,
          componentName: "SolutionStatistics",
          name: 'Solution Statistics',
          data:{
            globalMode:true
          }
        },


      ],
    };
  },
  methods: {
    setPropsData(dash) {
      console.log(dash);
      for (let j in dash.data) {
        if (dash.data[j].data !== undefined && dash.data[j].data !== null) {
          dash.data[j].props = JSON.parse(dash.data[j].data);
          dash.data[j].data = JSON.parse(dash.data[j].data);
        }
      }
    },
    async getUserDashboards() {
      this.$api
        .get("user/dashboard")
        .then((res) => {
          if (res) {
            let dashboards = res.data.dashboards;
            let default_dash = res.data.default;
            //let data = me.getDefaultDash();

            if (
              Object.keys(default_dash).length != 0 &&
              default_dash.constructor === Object
            ) {
              default_dash.active = 0;
              default_dash.name = "default dashboard";
              default_dash.id = -2;
              dashboards.unshift(default_dash);
            }

            this.dashboards = dashboards;

            let flag = false;
            for (let i in this.dashboards) {
              this.setPropsData(this.dashboards[i]);
              if (this.dashboards[i].active) {
                this.layout.data = this.dashboards[i].data;
                this.layout.id = this.dashboards[i].id;
                this.layout.name = this.dashboards[i].name;
                flag = true;
                if (i > 0) {
                  let b = this.dashboards[0];
                  this.dashboards[0] = this.dashboards[i];
                  this.dashboards[i] = b;
                }
              }
            }

            if (!flag) {
              this.layout.data = default_dash.data;
              this.layout.id = default_dash.id;
              this.dashboards[0].active = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("Error White getting User Dashboards.");
        });
    },
    EditCurrentDash() {
      this.$router.push("/userDashboard");
    },
    setAsDefaultDash() {
      this.$api
        .patch(`/user/dashboard/${this.layout.id}/activate`)
        .then((res) => {
          for (let i in this.dashboards) {
            this.dashboards[i].active = false;
          }
          let index = this.dashboards.findIndex(
            (item) => item.id == this.layout.id
          );

          if (index != -1) {
            this.dashboards[index].active = true;

            let b = this.dashboards[0];
            this.dashboards[0] = this.dashboards[index];
            this.dashboards[index] = b;
          }
        })
        .catch((err) => {
          console.log("Error White Create User Dashboard.");
        });
    },
    removeCurrentDash() {
      if (this.layout.id != -2 && !this.layout.active) {
        let index = this.dashboards.findIndex(
          (item) => item.id == this.layout.id
        );

        if (index != -1) {
          this.$api.delete(`user/dashboard/${this.layout.id}`);
          this.dashboards.splice(index, 1);
        }
      }
    },
    changeCurrentDashAndFlip(item, index2, size) {
      this.layout.data = item.data;
      this.layout.id = item.id;
      this.layout.name = item.name;

      let z = 3;
      if (size == "small") {
        z = 1;
      }
      let b = this.dashboards[z];
      this.dashboards[z] = this.dashboards[index2];
      this.dashboards[index2] = b;
    },
    changeCurrentDash(item) {
      /* for (let i in this.dashboards) {
        this.dashboards[i].active = false;
      }

      item.active = true; */

      this.layout.data = item.data;
      this.layout.id = item.id;
      this.layout.name = item.name;

      /* this.$api
        .patch(`/user/dashboard/${item.id}/activate`)
        .then(res => {
          this.$eventBus.$emit("getNewDashboards");
        })
        .catch(err => {
          console.log("Error White activate User Dashboard.");
        }); */
    },
    addNewDash() {
      let newDash = {
        name: this.newDashName,
        active: 0,
        data: [],
      };

      this.newDashName = "";

      let me = this;
      this.$api
        .post("user/dashboard", newDash)
        .then((res) => {
          me.dashboards.push(res.data.dash);
          me.$eventBus.$emit("getNewDashboards");
        })
        .catch((err) => {
          console.log("Error White Create User Dashboard.");
        });
    },
    saveUserDashboardNewChanges() {
      // POST new changes - this.layout - to UserDashboard API
      let currentDash = this.dashboards.find(
        (item) => this.layout.id == item.id
      );

      if (currentDash.id != -2) {
        currentDash.data = JSON.parse(JSON.stringify(this.layout.data));

        for (let i in currentDash.data) {
          if (currentDash.data[i].hasOwnProperty("props")) {
            delete currentDash.data[i].props;
            delete this.layout.data[i].props;
          }
          if (
            currentDash.data[i].data != null &&
            typeof currentDash.data[i].data == "object"
          ) {
            currentDash.data[i].data = JSON.stringify(currentDash.data[i].data);
          }
        }
        this.waitingFlag = true;

        this.$api
          .patch(`/user/dashboard/${currentDash.id}`, currentDash)
          .then((res) => {
            this.waitingFlag = false;
            this.$eventBus.$emit("getNewDashboards");
          })
          .catch((err) => {
            this.waitingFlag = false;
            console.log("Error White Create User Dashboard.");
          });
      }
    },
    addNewWidget(item) {
      if (this.layout.id == -2) {
        return;
      }
      let element = Object.assign({}, item);
      element.i = this.layout.data.length + 1;
      let index = this.layout.data.findIndex((el) => el.i == element.i);
      if (index == -1) {
        this.layout.data.push(element);
      } else {
        element.i = this.layout.data.length + 2;
        this.layout.data.push(element);
      }
    },

    deleteItem(i) {
      let index = this.layout.data.findIndex((item) => item.i == i);

      if (index != -1) {
        this.layout.data.splice(index, 1);
      }
    },

    editDashboard() {
      if (this.layout.id != -2) {
        this.objectDashToEdit = { ...this.layout };
        this.showEditModal = true;
      }
    },
    saveDashNewName() {
      let edititedDash = this.dashboards.find(
        (item) => item.id == this.objectDashToEdit.id
      );

      try {
        if (this.objectDashToEdit.name.length > 0) {
          this.$api
            .patch(
              `user/dashboard/${this.objectDashToEdit.id}`,
              this.objectDashToEdit
            )
            .then((res) => {
              if (edititedDash) {
                edititedDash.name = this.objectDashToEdit.name;
                this.showEditModal = false;
              }

              this.objectDashToEdit = null;
              this.$eventBus.$emit("getNewDashboards");
            })
            .catch((err) => {
              this.objectDashToEdit = null;
            });
        }
      } catch (error) {}
    },
  },
};
</script>
<style>
.color-black .form-control {
  color: black !important;
}
.custom-dropdown > .dropdown-menu::before {
  display: inline-block !important;
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  vertical-align: middle !important;
  content: "" !important;
  top: -5px !important;
  right: 30% !important;
  left: auto !important;
  color: #ffffff !important;
  border-bottom: 0.4em solid !important;
  border-right: 0.4em solid transparent !important;
  border-left: 0.4em solid transparent !important;
}
.modal-active {
  display: block;
}
.vue-grid-item.vue-grid-placeholder {
  background: green;
}

.vue-grid-item .vue-resizable-handle {
  z-index: 99999 !important;
  width: 15px;
  height: 15px;
  background-color: #e8e8e8;
  background-position: 65% 71%;
  border-radius: 20%;
  border-bottom-right-radius: 0px;
  /* display: none; */
}

.dodo {
  display: inherit !important;
  left: 100%;
}
</style>
<style scoped>
.no-actions {
  pointer-events: none;
}
.gray-bg {
  background: #cacaca !important;
}
.custom-input-border {
  color: black;
}
.custom-text-truncate {
  width: 120px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px !important;
}
.selected-dash-bg {
  background: #42b782 !important;
}
.custom-input {
  border: 1px solid black;
  border-radius: 5px;
  width: 94%;
  padding: 5px;
}
.bg-custom {
  background: rgb(39, 41, 61);
}
.custom-subnav-border {
  border: 1px solid #2196f3;
  border-radius: 10px;
}
.under-line:after {
  content: "";
  width: 85%;
  height: 4px;
  background: #41b580;
  position: absolute;
  left: 50%;
  bottom: -0px;
  transform: translateX(-50%);
}
.dash-item-settings {
  float: right;
  font-size: 14px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-18 {
  font-size: 18px;
}
.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}
.color-black {
  color: black;
}
.pointer {
  cursor: pointer;
}
.font-size-16 {
  font-size: 16px;
}
.custom-btns-location {
  top: -16px;
  right: 13px;
  z-index: 10;
}

.show-hide-widgets {
  top: -16px;
  right: 33px;
  z-index: 10;
  padding: 9px 25px;
}
.font-size-21 {
  font-size: 21px;
}

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.custom-location {
  background-color: #e8e8e8;
  width: 15px;
  height: 15px;
  top: 0px;
  right: 0px;
  text-align: center;
  padding: 2px 17px 15px 2px;
  border-bottom-left-radius: 20%;
  z-index: 100;
  cursor: pointer;
}

.custom-draggable-icon-location {
  background-color: #e8e8e8;
  color: #565454;
  width: 22px;
  height: 18px;
  top: 0px;
  left: 0px;
  text-align: center;
  padding: 2px 13px 15px 3px;
  border-bottom-left-radius: 20%;
  z-index: 100;
  cursor: move;
}
.custom-border {
  border: 2px solid #2196f3 !important;
  border-collapse: inherit;
}
.pointer {
  cursor: pointer;
}
</style>
