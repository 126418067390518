<template>
  <div class="row">
    <div class="col-12">
      <!-- Sensor Name And Icon -->
      <div class="row mb-2" v-if="sensorData">
        <div class="col-12 mt-3 d-flex align-items-center">
          <inline-svg
            style="vertical-align: -webkit-baseline-middle"
            class="icon-color-1 icon"
            :src="sensorData.icon"
          />
          <h4 class="mb-0 mx-2 font-weight-bold text-uppercase">
            {{ sensorData.name }}
          </h4>
        </div>
      </div>
      <!-- Gauge Charts And Performance -->
      <div class="row">
        <!-- Gauge Charts -->
        <div class="col-lg-8 col-md-12 pr-4 pl-2">
          <card class="shadow-2 h-100 radius-2">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <GaugeChart
                  :title="gaugeChartData[0].title"
                  :stats="gaugeChartData[0].stats"
                  :ranges="gaugeChartData[0].ranges"
                  :sensorTypeId="gaugeChartData[0].sensorTypeId"
                />
              </div>
              <div class="col-md-6 col-sm-12">
                <GaugeChart
                  :title="gaugeChartData[1].title"
                  :stats="gaugeChartData[1].stats"
                  :ranges="gaugeChartData[1].ranges"
                  :sensorTypeId="gaugeChartData[1].sensorTypeId"
                />
              </div>
            </div>
          </card>
        </div>
        <!-- Performance -->
        <div class="col-lg-4 col-md-12 mt-4 mt-lg-0 pr-3 pl-4">
          <alarmPerformance :performanceData="performanceData" />
        </div>
      </div>
      <!-- Chart-->
      <div class="mt-3">
        <ZingChartWrapper
          :customConfiguration="{}"
          :series="chartSeries"
          :labels="chartLabels"
          :showRanges="chartLabels.length > 1 && chartLabels[0].labels.length > 0"
          :xLabel="'Date'"
          :yLabel="yAxisLabel"
          :requireZoom="requireZoom"
          :height="'350px'"
          @changeChart="changeChart"
          :chartType="chartType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GaugeChart from "@/components/Charts/GaugeChart";
import alarmPerformance from "@/pages/SWAM/components/alarm-performance";
import { getSensorIcon } from "@/util/helper";
import ZingChartWrapper from "@/components/Charts/zingchart-wrapper";

export default {
  components: {
    GaugeChart,
    alarmPerformance,
    ZingChartWrapper
  },
  props: {
    chartType: {
      type: String,
      required: false,
      default: 'area'
    },
    gaugeChartData: {},
    performanceData: {},
    sensorId: {
      default: 1
    },
    firstSide: {
      default() {
        return [];
      }
    },
    secondSide: {
      default() {
        return [];
      }
    },
    chartTitle: {
      type: String,
      default: "readings"
    },
    requireZoom: {
      type: Boolean,
      default: false,
    },
    dates: {
      default() {
        return [[]];
      }
    },
    isDarkMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      currentCharType: undefined,
    };
  },
  computed: {
    firstSideTitle(){
      return this.performanceData[0].title;
    },
    secondSideTitle(){
      return this.performanceData[1].title;
    },
    chartSeries() {
      let series = [
        { values: this.firstSide, label: this.firstSideTitle },
        { values: this.secondSide, label: this.secondSideTitle }
      ]

      return series
    },
    chartLabels() {
      let labels = this.dates.map(dates => ({ labels: dates }))

      return labels
    },
    sensorData() {
      let sensor = this.$store.state.sensor_type.find(
        s => s.id == this.sensorId
      );
      if(!sensor){
        return;
      }
      let name = sensor.name;
      let result = {
        ...sensor,
        name: name.toLowerCase(),
        icon: name ? getSensorIcon(name.toLowerCase()) : "",
      };
      return result;
    },
    yAxisLabel(){
      if(this.sensorData){
        return `${this.sensorData.name.toUpperCase()}` + (this.sensorData.unit? `( ${this.sensorData.unit} )` : ""); 
      }
      else 'readings';
    }
  },
  methods: {
    changeChart(newChartType) {
      this.currentCharType = newChartType;
      this.$emit('chartTypeChange');
    },
    extractConfig() {
      return {
        chart_type: this.currentCharType,
      };
    }
  },
};
</script>

<style></style>
