<template>

  <div :class="'content ' + (!data.seen ? 'notSeenContent' : '')">
    <div class="ico" :style="!data.seen ? 'border: 2px solid var(' + getColor + ')' : ''">
      <i :class="getIcon"></i>
    </div>
    <div class="info">
      <div class="title"> {{ data.title }} </div>
      <div class="when">
        <i class="el-icon-time"></i>
        <div class="date"> {{ date }} </div>
      </div>
    </div>
    <div class="seen">
      <span v-if="!data.seen"></span>
    </div>
  </div>


</template>

<script>
import { mapActions,mapGetters } from 'vuex';
import { getDateString } from "./../../util/helper";
export default {
  props: ['data'],
  computed:{
    date() // return date from data
    {
      return getDateString(this.data.created_at);
    },
    getColor() // return color of icon border if and use it when not seen
    {
      return this.types[this.data.type].color;
    },
    getIcon() // return icon class that match the type of notification
    {
      return this.types[this.data.type].icon;
    },
    ...mapGetters({
      types:"notifications/types"
    })
  },
  methods:{
      ...mapActions({
          setAsSeen:"notifications/setAsSeen"
      })
  },
  created()
  {
    if(!this.data.seen){
      // make it seen after 4s from loading
      setTimeout(() => {
        this.setAsSeen(this.data.id);
      }, 4000);
    }
  }
};
</script>

<style scoped>

div.content{
  display: flex;
  width: 98%;
  border: 1px solid var(--lighter);
  border-radius: 10px;
  padding: 8px;
  margin-top: 8px;
  color: var(--gray);
  cursor: pointer;
  transition-duration: 0.2s
}

div.content:hover{
  border: 1px solid var(--primary);
}

div.content > div{
  margin-right: 10px;
}

div.ico{
  width: 40px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  border: 2px solid var(--gray);
  background-color: var(--lighter);
}
div.ico i{
  margin: auto;
}

div.info{
  width: 85%;
  display: flex;
  flex-direction: column;

}

div.when{
  display: flex;
  color: var(--info);
  font-size: 12px;
  align-items: center;
}

div.date{
  width: 50%;
}

div.seen{
  width: 5%;
}

div.seen span{
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--primary);
  margin-top: 15px;
  margin-left: 15px;
}

.notSeenContent{
  background-color: var(--lighter);
}

</style>
