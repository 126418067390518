import USER_PREFERENCES_ACTIONS from "./action-types";
import {UserPreferencesService} from "./user-preferences.service";

const service = new UserPreferencesService();
import UserPreferencesGetters from './action-types';
import UserPreferencesMutations from './types/mutations-type';

export const actions = {
  /**
   *
   * @param commit
   */
  [USER_PREFERENCES_ACTIONS.RESET_THE_WHOLE_STATE_ACTION]: ({commit}) => {
    // TODO
  },

  /**
   * Set item
   * @param {Object {key, value} } data
   * @returns {Promise}
   */
  [USER_PREFERENCES_ACTIONS.SET_ITEM_ACTION]: ({}, data) => {
    try {
      const {key, value} = data;
      if (!key || typeof key != "string" || key.length == 0) return console.error("Key Must be provided");
      return service.setItem(key, value);
    } catch (e) {
      return null;
    }
  },


  /**
   * Get item
   * @returns {Promise}
   */
  [USER_PREFERENCES_ACTIONS.GET_ITEM_ACTION]: ({}, key) => {
    try {
      if (!key || typeof key != "string" || key.length == 0) return console.error("Key Must be provided");
      return service.getItem(key);
    } catch (e) {
      return null;
    }
  },
  /**
   * Delete item
   * @returns {Promise}
   */
  [USER_PREFERENCES_ACTIONS.DELETE_ITEM_ACTION]: ({}, key) => {
    try {
      if (!key || typeof key != "string" || key.length == 0) return console.error("Key Must be provided");
      return service.deleteItem(key);
    } catch (e) {
      return null;
    }
  },

  /**
   * @param commit
   * @param rootState
   * @param getters
   * @param {Object} data
   * @type array
   * @desc reset sensor color to default value
   * @author karam mustafa
   * @return promise
   *
   * */
  [USER_PREFERENCES_ACTIONS.RESET_SENSOR_COLOR]: ({commit, rootState , getters}, data)  => {
    try {
      service.resetToDefault(data).then(res => {
        commit(UserPreferencesMutations.RESET_SENSOR_COLOR, {getters , rootState, data});
      });
    } catch (e) {
      return null;
    }
  },

  /**
   * @param commit
   * @param rootState
   * @param getters
   * @param {Object} data
   * @type array
   * @desc set new sensor color after we modified action pai
   * @author karam mustafa
   * @return promise
   *
   * */
  [USER_PREFERENCES_ACTIONS.CHANGE_SENSOR_COLOR]: ({commit, rootState, getters}, data) => {
    try {
      service.changeSensorColor(data).then(res => {
        commit(UserPreferencesMutations.CHANGE_SENSOR_COLOR, {getters , data});
      });
    } catch (e) {
      return null;
    }
  },


};
