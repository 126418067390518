<template>
  <card class="card-stats" :show-footer-line="true">
    <div class="row">
      <slot name="button"></slot>
      <div class="col-3" v-if="$slots.icon || icon">
        <div class="info-icon text-center" :class="`icon-${type}`">
          <slot name="icon">
            <i :class="icon"></i>
          </slot>
        </div>
      </div>
      <div class="col-9 sec-stats" v-if="$slots.content || title || subTitle">
        <div class="numbers">
          <slot>
            <p v-if="subTitle" dir="auto" class="card-category">{{ $t(subTitle) }}</p>
            <h5 v-if="title" dir="ltr" class="card-title">{{ $t(title) }}</h5>
          </slot>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
import Card from "./Card.vue";

export default {
  name: "stats-card",
  components: {
    Card,
  },
  props: {
    type: {
      type: String,
      default: "primary",
    },
    icon: String,
    title: [String, Number],
    subTitle: String,
  },
};
</script>
<style scoped>
.card-category {
  font-family: MTextFont;
}
.rtl .card-category {
  margin-right: 10%;
  text-align: right;
}
.rtl .card-title {
  margin-right: 10%;
  text-align: right;
}
.card-title {
  font-family: greyFont;
}
.card-stats hr {
  border-color: #41475a;
}
.sec-stats,
.numbers {
  text-align: left !important;
  padding-top: 4px;
}
.card-stats {
  margin-bottom: 5px;
}
</style>
