import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
//import { M } from '../../Permissions';



const SmartTracker = () =>
    import('./SmartTracker.vue');
    
const VsassCustomer = ()=>
    import('./VsassCustomer/VsassCustomer.vue');

const VsassCustomer2 = ()=>
    import('./VsassCustomer/VsassCustomer2.vue');




export default {
        path: '/smarttracker',
        component: DashboardLayout,
        redirect: '/',
        name: 'Smart Tracker',
        children: [
            {
                path: '/',
                name: 'Smart Tracker Dashboard',
                component: SmartTracker
            },
            {
                path: '/vsass-customer',
                name: 'VSASS Customer',
                component: VsassCustomer
            },
            {
                path: '/vsass-customer-2',
                name: 'vsass customer 2',
                component: VsassCustomer2
            },






        ]
    }



