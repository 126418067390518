import api from "src/axiosWithDelimiterFile";
import { IntrusionConfigurations } from "./configuration";
import { resolvePromiseForObjectOrNull } from "../../common/wrappers/promise-resolver";


export class IntrusionAlarmsService {
  /**
   *
   * @param {Object} QueryParams
   */
  async getMoroIntrusionAlarms(QueryParams) {
    const promise = api.get(IntrusionConfigurations.GETTING_MORO_ALARMS_URL, {
      params: {
        ...QueryParams
      }
    });
    return resolvePromiseForObjectOrNull(promise);
  }
  async getPoliceIntrusionAlarms(QueryParams) {
    const promise = api.get(IntrusionConfigurations.GETTING_POLICE_ALARMS_URL, {
      params: {
        ...QueryParams,
      }
    });
    return resolvePromiseForObjectOrNull(promise);
  }


  async getAlarmsHistory(QueryParams) {
    const promise = api.get(IntrusionConfigurations.GETTING_ALARMS_HISTORY_URL, {
      params: {
        ...QueryParams,
      }
    });
    return resolvePromiseForObjectOrNull(promise);
  }

  /**
   * @returns {Promise} resolved of { data }
   */
  async getAlarmsStatistics() {
    const promise = api.get(IntrusionConfigurations.GETTING_ALARMS_STATISTICS);
    return resolvePromiseForObjectOrNull(promise);
  }


  async getMoreInformationForAlarms() {
    const promise = api.get(IntrusionConfigurations.ON_ALARMS_MORE_INFORMATION_URL);
    return resolvePromiseForObjectOrNull(promise);
  }
}
