import SwamService from '@/pages/SWAM/services/swam.service';
import { onePeriod } from '@/util/periodOptions';

export default {
    namespaced: true,
    state:() => ({
        loading:false,
        currentStructureId:undefined,
        currentSolutionId:undefined,
        currentPeriodIndex:1,
        dates: [],
        data: {
            ok: 0,
            poor: 0,
            problem: 0,
        }
    }),
    getters: {
        loading(state) {
            return state.loading;
        },
        periodsOptions(state) {
            return onePeriod;
        }
    },
    mutations: {
        selectStructure(state,value) {
            state.currentStructureId = value;
        },
        selectSolution(state,value) {
            state.currentSolutionId = value;
        },
        selectPeriod(state,value) {
            state.currentPeriodIndex = value;
        },
        setLoading(state,value) {
            state.loading = value;
        },
        setData(state,data) {
            state.dates = data.dates;
            state.data = {
                ok: data.ok,
                poor: data.poor,
                problem: data.problem,
            };
        }
    },
    actions: {
        async fetchData({state,commit}) {
            commit('setLoading',true);
            try {
                let params = {
                    structure: state.currentStructureId,
                    solution: state.currentSolutionId,
                    from: onePeriod[state.currentPeriodIndex].from().toDate(),
                    to: onePeriod[state.currentPeriodIndex].to().toDate(),
                };
                const data = await SwamService.getOperationalStatuses(params);
                console.log('chart data => ', data)
                commit('setData',data);
                commit("setLoading", false);
            } catch (error) {
                commit("setLoading", false);
                console.error(error);
            }
        },
        resetData({state}) {
            state.dates = [];
            state.data= {
                ok: 0,
                poor: 0,
                problem: 0,
            }
        }
    }
}