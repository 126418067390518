import StateTypes from "../types/state-types";
import GettersTypes from "../types/getter-types";

export const getters = {
  // Alarms and villas
  [GettersTypes.INTRUSION_ALARMS_GETTER]: state =>
    state[StateTypes.GETTING_INTRUSION_ALARMS_STATE_TYPE],
  [GettersTypes.STATUS_ALARMS_GETTER]: state =>
    state[StateTypes.GETTING_STATUS_ALARMS_STATE_TYPE],
  [GettersTypes.VILLAS_LIST_GETTER]: state =>
    state[StateTypes.GETTING_VILLAS_LIST_STATE_TYPE],

  // Total records
  [GettersTypes.INTRUSION_ALARMS_TOTAL_RECORDS_GETTER]: state =>
    state[StateTypes.INTRUSION_ALARMS_TOTAL_RECORDS_STATE_TYPE],
  [GettersTypes.STATUS_ALARMS_GETTER]: state =>
    state[StateTypes.STATUS_ALARMS_TOTAL_RECORDS_STATE_TYPE],
  [GettersTypes.CUSTOMER_CONTACTS_LIST_GETTER]: state =>
    state[StateTypes.CUSTOMER_CONTACTS_LIST_STATE_TYPE],

  [GettersTypes.VILLAS_DEATILS_COLUMNS_CONFIG_GETTER]: state =>
    state[StateTypes.VILLAS_DEATILS_COLUMNS_CONFIG_STATE_TYPE],

  [GettersTypes.VILLAS_DETAILS_TOTAL_RECORDS_GETTER]: state =>
    state[StateTypes.VILLAS_DETAILS_TOTAL_RECORDS_STATE_TYPE],
  [GettersTypes.INTRUSION_ALARMS_TABLE_COLUMNS_CONFIG]: (
    state,
    rootState,
    getter,
    rootGetter
  ) =>
    rootGetter.isPoliceUser
      ? state[StateTypes.INTRUSION_POLICE_TABLE_CONFIGS_STATE_TYPE]
      : state[StateTypes.INTRUSION_MORO_TABLE_CONFIGS_STATE_TYPE],
  [GettersTypes.MORO_OR_POLICE_GETTER]: (
    state,
    rootState,
    getter,
    rootGetter
  ) => {
    return rootGetter.isPoliceUser ? "police" : "moro";
  },
  [GettersTypes.OPEN_ACKNOWLEDGEMENT_MODAL_GETTER]: state =>
    state[StateTypes.OPEN_ACKNOWLEDGEMENT_MODAL_STATE_TYPE],
  [GettersTypes.OPEN_MORE_INFORMATION_MODAL_GETTER]: state =>
    state[StateTypes.OPEN_MORE_INFORMATION_MODAL_STATE_TYPE],
  [GettersTypes.HISTORY_DATA_GETTER]: state =>
    state[StateTypes.HISTORY_DATA_STATE_TYPE],

  [GettersTypes.INTRUSION_ALARMS_HISTORY_TABLE_COLUMNS_GETTER]: state =>
    state[
      StateTypes.FOR_MORO_INTRUSION_ALARMS_HISTORY_TABLE_COLUMNS_STATE_TYPE
    ],

  [GettersTypes.HISTORY_ALARMS_TOTAL_RECORDS_GETTER]: state =>
    state[StateTypes.HISTORY_ALARMS_TOTAL_RECORDS_STATE_TYPE],
  [GettersTypes.INTRUSION_ALARMS_STATISTICS_GETTER]: state =>
    state[StateTypes.INTRUSION_ALARMS_STATISTICS_STATE_TYPE],
  [GettersTypes.CUSTOMER_LOCATIONS_ARRAY_GETTER]: state =>
    state[StateTypes.CUSTOMER_LOCATIONS_ARRAY_STATE_TYPE],
  [GettersTypes.INTRUSION_ALARMS_CURRENT_FILTER]: state => 
    state[StateTypes.INTRUSION_ALARMS_CURRENT_FILTER]
};
