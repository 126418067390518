import ForkliftService from "../../services/forklift.service";

export default {
  namespaced: true,
  state: {
    vehicleStatusAlarmsData: [],
    loading: false
  },
  getters: {
    vehicleStatusAlarmsData(state) {
      return state.vehicleStatusAlarmsData;
    },
    loading(state){
      return state.loading
    }
  },
  mutations: {
    SET_VEHICLE_LIST(state, payload){
      state.vehicleStatusAlarmsData = payload
    },
    SET_LOADING(state, payload){
      state.loading = payload
    }
  },
actions: {
    async fetchStatusAlarms({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true);
        ForkliftService.getStatusAlarms()
          .then((res) => {
            commit("SET_VEHICLE_LIST", res.data.data);
            commit("SET_LOADING", false);
            resolve(res.data.data)
          })
          .catch((err) => reject(err));
      });
    },
  },
}