<script>
export default {
  name: "base-heatmap",
  props: {
    heatmapData: {
      type: Array,
      required: true
    },
    radius: {
      type: Number,
      default: 20
    },
    opacity: {
      type: Number,
      default: 0.8
    }
  },
  data() {
    return { heatmap: null };
  },
  mounted() {

    this.updateHeatmap()
  },
  methods: {
    updateHeatmap() {
      const heatmapCoordinates = this.heatmapData.map(coordinates => ({
        location: new window.google.maps.LatLng(coordinates.lat, coordinates.lng),
        weight: coordinates.weight ? coordinates.weight : 1000
      }));

      if (this.heatmap)
        this.heatmap.setMap(null)

      this.$parent.getMap(map => {
        this.heatmap = new window.google.maps.visualization.HeatmapLayer({
          data: heatmapCoordinates,
          map: map,
          radius: this.radius,
          opacity: this.opacity
        });
      });
    }
  },
  watch: {
    heatmapData() {
      this.updateHeatmap()
    }
  },
  beforeDestroy() {
    this.heatmap.setMap(null);
    window.google.maps.event.clearInstanceListeners(this.heatmap);
  },
  render() {
    return null;
  }
};
</script>