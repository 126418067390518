import { applyUniqueIdToAllObjectKeys } from "../../../../util/unique-id-generator";

let types = {
  RESET_THE_WHOLE_STATE_MUTATION: "RESET_THE_WHOLE_STATE_MUTATION",

  // Alarms mutations
  GETTING_INTRUSION_ALARMS_MUTATION: "GETTING_INTRUSION_ALARMS_MUTATION",
  GETTING_STATUS_ALARMS_MUTATION: "GETTING_STATUS_ALARMS_MUTATION",
  GETTING_VILLAS_LIST_MUTATION: "GETTING_VILLAS_LIST_MUTATION",
  SETTING_INTRUSION_ALARMS_CURRENT_FILTER: "SETTING_INTRUSION_ALARMS_CURRENT_FILTER",

  // Total records
  INTRUSION_ALARMS_TOTAL_RECORDS_MUTATION: "INTRUSION_ALARMS_TOTAL_RECORDS_MUTATION",
  VILLAS_DETAILS_TOTAL_RECORDS_MUTATION: 'VILLAS_DETAILS_TOTAL_RECORDS_MUTATION',


  // Mutation on alarms
  BY_MORO_ACKNOWLEDGE_ALARM_MUTATION: 'BY_MORO_ACKNOWLEDGE_ALARM_MUTATION',
  BY_POLICE_ACKNOWLEDGE_ALARM_MUTATION: 'BY_POLICE_ACKNOWLEDGE_ALARM_MUTATION',
  VERIFY_ALARM_MUTATION: 'VERIFY_ALARM_MUTATION',
  TERMINATE_ALARM_MUTATION: 'TERMINATE_ALARM_MUTATION',
  COMMENT_ON_ALARM_MUTATION: 'COMMENT_ON_ALARM_MUTATION',



  // Modals
  OPEN_ACKNOWLEDGEMENT_MODAL_MUTATION: 'OPEN_ACKNOWLEDGEMENT_MODAL_MUTATION',
  RE_OPEN_ACK_MODAL_MUTATION: 'RE_OPEN_ACK_MODAL_MUTATION',
  MORO_OR_POLICE_MUTATION: 'MORO_OR_POLICE_MUTATION',
  CUSTOMER_CONTACTS_LIST_MUTATION: 'CUSTOMER_CONTACTS_LIST_MUTATION',
  OPEN_MORE_INFORMATION_MODAL_MUTATION: 'OPEN_MORE_INFORMATION_MODAL_MUTATION',

  // Socket
  LISTEN_TO_ACKNOWLEDGE_ALARM_MUTATION: 'LISTEN_TO_ACKNOWLEDGE_ALARM_MUTATION',
  NEW_ALARM_RECEIVED_FROM_SERVER_MUTATION: 'NEW_ALARM_RECEIVED_FROM_SERVER_MUTATION',
  LISTEN_TO_TERMINATE_ALARM_EVENT_MUTATION: 'LISTEN_TO_TERMINATE_ALARM_EVENT_MUTATION',
  LISTEN_TO_VERIFY_ALARM_EVENT_MUTATION: 'LISTEN_TO_VERIFY_ALARM_EVENT_MUTATION',
  GETTING_INTRUSION_ALARMS_HISTORY_TABLE_COLUMNS_MUTATION: 'GETTING_INTRUSION_ALARMS_HISTORY_TABLE_COLUMNS_MUTATION',
  HISTORY_ALARMS_TOTAL_RECORDS_MUTATION: 'HISTORY_ALARMS_TOTAL_RECORDS_MUTATION',
  GETTING_HISTORY_DATA_MUTATION: 'GETTING_HISTORY_DATA_MUTATION',
  GETTING_INTRUSION_ALARMS_STATISTICS_MUTATION: 'GETTING_INTRUSION_ALARMS_STATISTICS_MUTATION',
  CUSTOMER_LOCATIONS_ARRAY_MUTATION: 'CUSTOMER_LOCATIONS_ARRAY_MUTATION',

  OPEN_VILLA_INFORMATION_MODAL_MUTATION: 'OPEN_VILLA_INFORMATION_MODAL_MUTATION'
};

types = applyUniqueIdToAllObjectKeys(types);
export default types;
