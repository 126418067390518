
import userService from '../../services/user.service'
import { users_types } from "@/store/index"

export default {
  state: {
    UserData: null,
  },
  getters: {
    UserData(state) {
      return state.UserData;
    },
  },
  actions: {
    async getUserData({ state, rootState }, id) {
      let data = await requestService.getUser(id);
      if (data.data.is_suspended) {
        data.data.THE_STATUS = data.data.status;
        data.data.status = "suspended";
      }
      const role = rootState.roles.find((r) => r.id == data.data.role_id);
      if (role) {
        data.data.slug = role.slug;
        if (data.data.slug == "paying_customer") {
          data.data.slug = "customer";
        }
      }
      data.data.isSupervisor = data.data.type == users_types.Supervisor;
      this.commit("setUserData", data.data);
      return data.data;
    },

    async submitChangePassword(password) {
      userService.submitChangePassword(password);
      return password;
    },
    async saveUserPersonalChanging({ state, rootState }, payload) {
      let data = await userService.updateUser(state.UserData.id, data);
      state.UserData.updated_at = new Date();
      if (data.qr) {
        this.commit('openImage', {
          img: data.qr, text: [
            "Your account is protected with OTP, please scan this QR using your phone with any QR Scaner, Like Google Authenticator."
          ]
        });
      }
      this.commit('setUserData', { ...state.UserData, ...data });
      if (state.UserData.id == rootState.user.id) {
        this.commit('updateCurrentUser', { ...rootState.user, ...data });
      }
    },
    async resendRegistrationEmail({ state }, payload) {
      let data = await userService.resendRegisterEmail(payload);
      this.$notify({
        message: "Email sent successfully!",
      });
    }
  },
};
