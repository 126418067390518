<template>
  <card v-loading="loading">
    <!-- title % filter -->
    <template slot="header">
      <div class="d-flex justify-content-between">
        <h3>{{ $t("widgets.blank") }}</h3>
        <slot name="header-right"></slot>
      </div>
    </template>
    <div class="d-flex justify-content-center mb-4">
      <div class="col-5 d-flex justify-content-around">
        <span class="h3">Rows :</span>
        <span class="w-50">
          <base-select
            :options="rows"
            v-model="rowsCount"
            :popperClass="globalMode?'zoomedout-widget':''"
          ></base-select>
        </span>
      </div>
    </div>
  </card>
</template>

<script>

  export default {
    props: {
      data: {
        default: function () {
          return {
            rows: 1
          };
        },
      },
      globalMode: {
        default: false,
      },
      disableDataFetch: {
        type: Boolean,
        default: false,
      },
      expanded: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        rows: [
          {value: 1},
          {value: 2},
          {value: 3},
          {value: 4},
          {value: 5},
          {value: 6},
          {value: 7},
          {value: 8},
          {value: 9},
          {value: 10},
          {value: 11},
          {value: 12}
        ],
        rowsCount: 1,
        loading: false,
      };
    },
    created() {
      if (this.data.rows) {
        this.rowsCount = this.data.rows
      }
    },
    methods: {
      extractConfig() {
        return {
          rows: this.rowsCount
        }
      }
    },
    watch: {
      disableDataFetch: {
        handler(val) {

        },
      },
      data: {
        handler() {
          if (this.data.rows) {
            this.rowsCount = this.data.rows
          }
        },
        deep: true,
      },
      rowsCount(val) {
        this.data.rows = val
        this.$emit('valid', this.data)
      }
    },
  };
</script>

<style scoped lang="scss">
  .input-title {
    color: white;
    font-size: 1.3rem;
    vertical-align: middle;
  }

  .title .icon {
    width: 50px;
    fill: white;
  }

  .white-content .title ::v-deep.icon {
    fill: $light-mode-svg-color;

    path {
      fill: $light-mode-svg-color;
    }
  }

  .title span {
    vertical-align: middle;
  }

  .title .text {
    font-size: 1.5rem;
    color: white;
    letter-spacing: 1px;
    vertical-align: middle;
    padding: 0 1rem;
  }

  .white-content .title .text {
    color: #1d253b;
  }

  .white-content .status-body {
    .icon-wraper {
      ::v-deep.icon {
        path {
          fill: $light-mode-svg-color;
        }
      }
    }

    .text {
      color: #1d253b;
    }
  }

  ::v-deep .status-body {
    .icon-wraper {
      display: inline-block;
      width: 10%;
      text-align: center;

      .icon {
        width: 40px;

        path {
          fill: white;
        }
      }

      .icon.share {
        width: 25px;
      }
    }

    .text {
      font-size: 1.45rem;
      color: white;
      font-weight: 500;
      letter-spacing: 0.6px;
      padding-left: 10px;
    }
  }

  .white-content .detailes {
    .detailes-block {
      ::v-deep svg {
        fill: $light-mode-svg-color;

        path {
          fill: $light-mode-svg-color;
        }
      }

      .db-title {
        color: #1d253b;
      }

      .sec-2 {
        border: 1px solid #1d253b;

        .sec-2__value {
          color: #1d253b;
        }
      }
    }
  }

  .detailes {
    // justify-content: center;
    display: flex;
    overflow: hidden;
    padding-bottom: 1rem;
    justify-content: center;

    .detailes-block {
      // max-width: 50%;
      svg {
        width: 35px;
        fill: white;
      }

      .db-title {
        font-size: 1.5rem;
        color: white;
        vertical-align: sub;
        text-transform: capitalize;
        padding-left: 10px;
      }

      .sec-2 {
        margin-top: 3vh;
        border: 1px solid whitesmoke;
        border-radius: 10px;
        height: 15vh;
        display: flex;
        align-items: center;

        span.item {
          width: 35%;
          display: inline-block;
          padding: 0 6px;
          font-size: 1rem;
          font-weight: 600;
          text-align: center;
        }

        .sec-2__title {
          color: black !important;
          border-radius: 3px;
          margin-bottom: 5px;
        }

        .sec-2__value {
          color: white;
          letter-spacing: 1px;
        }
      }
    }

    border-radius: 10px;
  }

  .detailes.empty {
    height: 25%;
  }

  .detailes-block.empty {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    text-transform: capitalize;
  }

  .unit {
    font-size: 0.85rem;
  }

  .detailes::-webkit-scrollbar {
    height: 10px !important;
  }

  ::v-deep .sensor-icon path {
    fill: white;
  }

  .white-content .sensor-icon path {
    fill: $light-mode-svg-color;
  }
</style>

<style lang="scss">
  .btn-vue {
    background: $vue;
    background-image: -webkit-gradient(
        linear,
        right top,
        left bottom,
        from($vue),
        color-stop($vue-states),
        to($vue)
    );
    background-size: 210% 210%;
    background-position: top right;
    background-color: $vue;
    transition: all 0.15s ease;
    box-shadow: none;
  }

  .linkable:hover {
    color: $primary !important;
    cursor: pointer;
  }

  .white-content .metars {
    border: 1px solid #1d253b;

    .sensor-name {
      color: #1d253b;
    }

    .index {
      color: #1d253b;
    }
  }

  .metars {
    border: 1px solid white;
    border-radius: 20px;

    .sensor-name {
      font-size: 1.1rem;
      color: whitesmoke;
      min-width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: -webkit-fill-available;
      // object-fit: cover;
      // padding: 0.5rem 1.4rem;
    }

    .index {
      color: white;
      font-size: 1.1rem;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: capitalize;

      span {
        padding: 0 30px;
      }
    }
  }

  .bullit {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block !important;
    margin: 0 3px;
  }

  .avg {
    background: $vue;
  }

  .max {
    background-image: linear-gradient(to bottom, #fd5d93, #ec250d);
  }

  ::v-deep.sensor {
    display: flex;
    position: absolute;

    .sensor-name {
      color: white;
      font-size: 3rem;
      font-weight: bold;
      letter-spacing: 1.3px;
      text-transform: uppercase;
    }

    .icon {
      width: 40px;
      padding-right: 15px;

      path {
        fill: white !important;
      }
    }
  }

  .white-content .sensor {
    .sensor-name {
      color: #1d253b;
    }

    .icon {
      path {
        fill: #1d253b !important;
      }
    }
  }
</style>
