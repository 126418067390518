import CustomerMap from "../../core/CustomersMap/store";
import AlarmsStatistics from '../../core/AlarmsStatistics/store';
import OperationalAlarmsTable from '../../core/OperationalAlarmsTable/store';
import OperationalAlarmsActions from '../../components/ActionColumnStore';

export default {
    namespaced: true,
    modules: {
        CustomerMap,
        AlarmsStatistics,
        OperationalAlarmsTable,
        OperationalAlarmsActions
    }
}