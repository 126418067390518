import IntrusionService from "../../services/intrusion.service";

export default{
    namespaced: true,

    state: () => ({
        loading:false,
        reasons: [],
        policeStationId: undefined
    }),
    getters:{
        getReasons(state){
            return state.reasons;
        },
        loading(state) {
            return state.loading
        }
    },
    mutations:{
        setLoading(state,value) {
            state.loading = value;
        },
        setPoliceStationId(state,value) {
            state.policeStationId = value;
        },
        setReasons(state,data){
            state.reasons = Object.keys(data).map(key =>{
                return {
                    label: key,
                    value: data[key]
                }
            });
        }
    },
    actions:{
        async fetchReasons({commit, state}, serviceAction){
            commit('setLoading',true);
            try {
                const data = await IntrusionService[serviceAction]({ policeStationId: state.policeStationId })
                commit("setReasons", data);
                commit("setLoading", false);
            } catch (error) {
                commit("setLoading", false);
                console.error(error);
            }
        },
        reset({state}) {
            state.reasons = [];
            state.policeStationId = undefined;
        },
    }
}