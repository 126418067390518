let props = {
  1: {
    header: 2,
    gaugeCharts: 3,
    lineChart: 4
  },
  2: {
    header: 3,
    gaugeCharts: 3,
    lineChart: 4
  },
  3: {
    header: 2,
    sensorsRow: 3
  },
  4: {
    header: 2,
    lineChart: 3
  },
  pageSize: 12
}

export const setProps = (_props) => {
  props = _props
}

export const getProps = () => props