<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1572.039" height="413.337" viewBox="0 0 1572.039 413.337" class="moro-logo">
    <g id="Group_1" data-name="Group 1" transform="translate(-87.529 -169.809)">
      <g id="Group_18" data-name="Group 18" transform="translate(87.529 169.808)">
        <path class="moro-logo-dynamic-char" id="Path_1849" data-name="Path 1849" d="M372.67,468.759c71.236-5.227,71.257-5.237,96.862,60.844,25.008,64.551,49.986,129.113,76.4,197.351,31.268-78.174,60.935-152.723,91.018-227.1,4.193-10.362,4.173-27.014,15.953-28.857a295.4,295.4,0,0,1,62.394-.253c8.387.5,6.26,9.43,6.229,15.254-.425,99.891-1.185,199.771-1.276,299.662,0,13.319-4.994,17.1-17.685,17.645-48.072,2.026-48.062,2.421-47.829-45.418.243-47.221.506-94.441-2.583-142.908l-34.59,82.986c-12.155,29.252-25.494,58.079-35.714,87.979-7.911,23.155-25.454,15.1-40.363,16.206-14.18,1.094-26.042.689-32.777-17.29-21.149-56.367-44.567-111.894-67.144-167.724-.172-.436-1.114-.578-4.4-2.137-.274,50.877-.223,100.539-.9,150.2-.527,38.854,7.586,36.535-37.8,36.2-9.2-.061-18.374-1.661-27.561-2.542Z" transform="translate(-370.907 -434.346)" fill="grey"/>
        <path class="moro-logo-dynamic-char" id="Path_1850" data-name="Path 1850" d="M969.142,696.007c-16.926,52.021-98.736,133.7-202.993,110.313A171.764,171.764,0,1,1,971.572,590.049a154.135,154.135,0,0,1,3.545,15.4A208.569,208.569,0,0,1,969.142,696.007ZM806.706,747.553a106.667,106.667,0,1,0-75.952-31.942,106.628,106.628,0,0,0,75.952,31.942Z" transform="translate(594.471 -430.555)" fill="grey"/>
        <path class="moro-logo-dynamic-char" id="Path_1851" data-name="Path 1851" d="M652,468.949c23.975,1.013,50.32-1.215,76.128,6.168A100.276,100.276,0,0,1,803.4,565.073c5.429,44.678-13.3,85.953-51.729,105.391-17.421,8.822-14.95,15.416-6.077,28.259,19.346,27.946,37.547,56.722,55.9,85.326,3.1,4.832,9.967,9.693,5.936,16.125-3.757,6.006-11.365,3.039-17.3,3.271-9.278.324-18.627-.678-27.845.1a27.6,27.6,0,0,1-28.361-15.437c-18.11-29.677-37.872-58.362-55.627-88.253A24.634,24.634,0,0,0,649.075,687.5a25.006,25.006,0,0,0-7.414,4.123c-5.692,4.922-7.6,14.909-3.92,44.435.892,7.161,1.783,20.957,3.576,48.548a18.046,18.046,0,0,1-3.445,13.542,19.061,19.061,0,0,1-13.7,4.6c-48.618,2.39-48.618,2.836-48.345-44.78.446-87.432,1.783-174.875.851-262.337-.243-23.145,7.243-29.84,28.969-27.277a432.017,432.017,0,0,0,46.36.6Zm18.111,161.363a108.787,108.787,0,0,0,24.31-2.512,59.757,59.757,0,0,0,37.71-19.335,53.851,53.851,0,0,0,7.89-42.765c-4.467-25.444-25.555-38.692-62.7-39.381-34.813-.628-34.813-.628-34.985,33.02-.061,13.167.892,26.446-.425,39.5-2.269,22.537,2.816,26.335,5.723,28.665,4.021,3.2,10.23,3.11,22.5,2.806Z" transform="translate(377.759 -430.262)" fill="grey"/>
        <path id="Path_1852" data-name="Path 1852" d="M687.247,670.042A238.149,238.149,0,0,1,577.562,865.691c-18.779,12.59-25.484,8.579-45.388-21.149-10.534-15.74,1.55-20.258,10.858-26.7a186.857,186.857,0,0,0,49.793-258.62,159.3,159.3,0,0,0-47.17-46.065c-12.6-8.033-13.279-15.275-5.53-25.738,5.064-6.837,10.808-13.167,16.074-19.863,7.92-10.068,15.963-9.43,25.939-2.249C651.056,514.949,685.879,582.012,687.247,670.042Z" transform="translate(203.486 -459.083)" fill="#41b581"/>
        <path id="Path_1853" data-name="Path 1853" d="M459.039,660.367a242.376,242.376,0,0,1,104.388-193.9c26.183-18.789,31.065,6.685,42.7,17.847,14.717,14.109,4.052,22.5-7.941,30.579C497.8,582.76,495.24,747.009,602.383,817.749c9.967,6.583,19.914,12.155,9.44,27.206-19.661,28.28-26.264,32.19-43.868,20.937A244.024,244.024,0,0,1,459.039,660.367Z" transform="translate(-48.881 -459.759)" fill="#41b581"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MoroLogo'
}
</script>

<style lang="scss" scoped>
.moro-logo {
  height: 50px;
  width: 110px;

  .moro-logo-dynamic-char {
    fill: $white;
  }
}

.white-content {
  .moro-logo {
    .moro-logo-dynamic-char {
      fill: $dark-blue-5;
    }
  }
}

@media screen and (min-width: 992px) {
  .sidebar-mini {
    .sidebar {
      .moro-logo-dynamic-char {
        display: none;
      }
      .moro-logo {
        margin-left: 17px;
      }
    }

    .sidebar:hover {
      .moro-logo {
        margin-left: unset;
      }

      .moro-logo-dynamic-char {
        display: unset;
      }
    }
  }
}
</style>
