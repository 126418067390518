export const lineChartConfigurations = {
    type: "line",
    // legend: {
    //   layout: "x10",
    //   alpha: 0.05,
    //   shadow: false,
    //   y: "92%",
    //   x: "2%",
    //   padding: "18px"
    // },
    plotarea: {
      "margin-top": "40",
      "margin-right": "30",
      "margin-bottom": "90",
      "margin-left": "30"
    },
    plot: {
      hoverMarker: {
        type: "circle",
        borderWidth: "1px",
        size: "4px"
      },
      lineWidth: "3px",
      marker: {
        type: "circle",
        size: "4px"
      },
      shadow: false,
      tooltipText: "%t : %v<br>"
    }
  };