<template>
  <div>
    <card class="card-mo-main">
      <div class="row">
        <div class="col-md-5 col-12">
          <div class="row">
            <i
              @click="goBack"
              :class="isRTL ? 'fa-arrow-right' : 'fa-arrow-left'"
              class="fas fa-lg back-icon"
            ></i>

            <div>
              <h3 class="order-title">{{selectedMO?selectedMO.subject:''}}</h3>
              <small class="order-id">{{selectedMO?$generateID(selectedMO.id,'MO'):''}}</small>
            </div>
          </div>

          <div class="col-xs-12 d-flex d-sm-block">
            <div
              class="btn-group btn-group-toggle"
              :class="isRTL ? 'float-right' : 'float-left'"
              data-toggle="buttons"
            >
              <header>
                <nav>
                  <ul>
                    <li
                      v-for="(option, index) in bigLineChartCategories"
                      :key="option.name"
                      :id="index"
                    >
                      <router-link
                        :to="{name: option.nameRoute}"
                        @click.native="selectClick(option.click)"
                        replace
                      >
                        <button
                          class="tab"
                          @click="setTabActive(option.name)"
                          :class="{'active': option.name.toLowerCase() === activeTab}"
                        >
                          <span class="tab-copy">{{ $tc(option.name, 2) }}</span>
                          <span class="tab-background">
                            <span class="tab-rounding left"></span>
                            <span class="tab-rounding right"></span>
                          </span>
                        </button>
                      </router-link>
                    </li>
                  </ul>
                </nav>
              </header>
            </div>
          </div>
        </div>
        <div class="col-md-7 col-12">
          <component :is="headerAction"></component>
        </div>
      </div>
    </card>
    <card class="all">
      <div class="row">
        <div class="mb-3" :class="{'col-12':!rightSideState,'col-md-8':rightSideState}">
          <!-- <keep-alive> -->
          <router-view></router-view>
          <!-- </keep-alive> -->
        </div>
        <div class="col-md-4" v-if="rightSideState">
          <card class="h-100 high-card-shadow">
            <component :is="rightSideSlot"></component>
          </card>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { actions } from "./../MaintenaceStore";
import { mapGetters } from "vuex";
import addNewTask from "./Add-Task";
import MODetails from "./MO-Details";
import Accept from "./MO-Header-Actions/Accept";
import rest from "./MO-Header-Actions/rest";
export default {
  data() {
    return {
      activeTab: "",
    };
  },
  props: ["id"],
  components: {
    addNewTask,
    "mo-details": MODetails,
    Accept,
    rest,
  },

  created() {
    if (
      (this.$store.state.MaintenaceStore.selectedMO == null || // No selected MO
        this.$store.state.MaintenaceStore.selectedMO.id != this.id) && // Or old selected Mo still exist
      !this.$store.state.MaintenaceStore.loadingMO //and we are not in loading mode
    ) {
      this.$store.dispatch(actions.loadMO, this.id); // so we will load it!
    }
    const path = this.$route.path.split("/").pop();
    this.activeTab = path.toLowerCase();
  },
  methods: {
    setTabActive(tabName) {
      this.activeTab = tabName;
    },
    selectClick(obj) {
      if (obj == "toSummary") {
        this.toSummary();
      } else if (obj == "toTasks") {
        this.toTasks();
      } else {
        this.toHistory();
      }
    },
    toSummary() {
      this.$store.commit("getSlot", null);
      this.$store.commit("toggleRightSide", true);
    },
    toTasks() {
      this.$store.commit("toggleRightSide", true);
    },
    toHistory() {},
    goBack() {
      this.$router.push({ path: "/maintenance/list" });
    },
  },
  computed: {
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return [
        {
          name: "summary",
          nameRoute: "Maintenance Order Summary",
          click: "toSummary",
        },
        {
          name: "task",
          nameRoute: "Maintenance Order Tasks",
          click: "toTasks",
        },
        {
          name: "history",
          nameRoute: "Maintenance Order History",
          click: "toHistory",
        },
      ];
    },
    ...mapGetters({
      selectedMO: "selectedMO",
      rightSideSlot: "rightSideSlot",
      rightSideState: "rightSideState",
      headerAction: "headerAction",
    }),
  },
};
</script>

<style scoped lang="scss">
::v-deep .card-mo-main .card-body {
  padding-bottom: 0;
}
.all {
  border-radius: 0;
  box-shadow: none;
}
.back-icon {
  color: var(--light);
  margin: 1rem;
}
.back-icon:hover {
  color: var(--primary);
}
.order-title {
  color: white;
  text-transform: capitalize;
  margin-bottom: 0;
}
.white-content .order-title {
  color: #1d253b;
}
.order-id {
  position: relative;
  top: -5px;
  margin-left: 5px;
  color: var(--primary);
}
label,
label a,
label a span {
  cursor: pointer;
}
.active_tab {
  color: $primary !important;
  font-weight: 700;
  background: #27293d;
}
a {
  color: rgb(181, 186, 195);
  font-weight: 700;
}

a:hover,
label:hover a {
  color: $primary !important;
}
.Tabed span {
  background-color: $primary !important;
  color: white !important;
}
.parent-label {
  padding: 0 0;
  border: 0;
}

body {
  font-family: "PT Sans", sans-serif;
}

header {
  height: auto;
  min-height: auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
}
header nav {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 20px solid #fff;
  filter: drop-shadow(0 -4px 4px rgba(64, 46, 89, 0.3));
}
header nav ul {
  padding-left: 0;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  list-style: none;
}
header nav .tab {
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  border: none;
  padding: 9px 0px;
  margin: 0 13px 0;
  outline: none;
  cursor: pointer;
  font-family: MTextFont;
  position: relative;
  background: none;
}
header nav .tab:hover .tab-background,
header nav .tab.active .tab-background {
  height: 9px !important;
}
.white-content header nav .tab {
  color: #1d253b;
}
header nav .tab:hover .tab-rounding {
  transform: scaleY(1);
}
header nav .tab.active {
  color: $primary;
}
header nav .tab.active .tab-background {
  height: 100%;
}
header nav .tab.active .tab-rounding {
  transform: scaleY(1);
}
header nav .tab-copy {
  position: relative;
  z-index: 1;
}
header nav .tab-background {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: $primary;
  z-index: 0;
  height: 0;
  border-radius: 8px 8px 0 0;
  transition: height cubic-bezier(0.86, 0, 0.07, 1) 0.24s;
}
header nav .tab-rounding {
  padding: 4px 8px;
  display: block;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform cubic-bezier(0.86, 0, 0.07, 1) 0.24s;
}
header nav .tab-rounding:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: -8px;
  left: -8px;
  border-radius: 100%;
}
header nav .tab-rounding.left {
  left: 0px;
}
header nav .tab-rounding.left:before {
  box-shadow: 0 0 0 10rem $primary;
}
header nav .tab-rounding.right {
  right: 0px;
}
header nav .tab-rounding.right:before {
  left: 8px;
  box-shadow: 0 0 0 10rem $primary;
}

article .container {
  width: 100%;
  padding: 40px;
  max-width: 1024px;
  box-sizing: border-box;
  margin: 0 auto;
  height: 200px;
}
article h1 {
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 30px;
  color: $primary;
}
article p {
  line-height: 1.6;
  font-size: 19px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 20px;
}

.fade-enter-active > *,
.fade-leave-active > * {
  transition-duration: 200ms;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.6, 0.15, 0.35, 0.8);
}

.fade-enter > *,
.fade-leave-to > * {
  opacity: 0;
  transform: translateY(40px);
}

.fade-enter-active > *:nth-child(2) {
  transition-delay: 100ms;
}
.fade-enter-active > *:nth-child(3) {
  transition-delay: 200ms;
}

.fade-leave-active > *:nth-child(1) {
  transition-delay: 200ms;
}
.fade-leave-active > *:nth-child(2) {
  transition-delay: 100ms;
}

nav {
  border-bottom: 0 !important;
}
.card-mo-main {
  -webkit-box-shadow: none;
  margin-bottom: 0px;
  box-shadow: none;
  border-radius: 0;
}
/* #363a58 */
</style>
