<template>
  <card v-loading="loading" :class="fullWidth ? 'fullWidth-card-height' : 'normal-card-height' ">
    <!-- Title -->
    <template slot="header">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <solution-icon :solution-name="solutionName" :key="solutionName" />
          <router-link
            :to="getUrl()"
          >
            <h3 class="ml-2 clickable">{{ $t(solutionName) }}</h3>
          </router-link>
        </div>
        <slot name="header-right">
          <base-select
            v-if="!hidePeriodSelector"
            class="mb-0"
            :options="periodsOptions"
            v-model="currentPeriodIndex"
            :light="true"
          />
        </slot>
        <base-dropdown class="my-dropdown" v-if="unSelectedSolutions.length && !globalMode" :menuOnRight="true">
          <template slot="title">
              <inline-svg
                :src="require('@/assets/Icons/more_info.svg')"
                fill="white"
                class="icon"
              ></inline-svg>
          </template>
          <div class="solutionCard"
              v-for="(solution,i) in unSelectedSolutions"
              :key="i"
              @click="selectSolution(solution)"
          >
             <span>{{ solution.label }}</span>
          </div>
          </base-dropdown>
      </div>
    </template>
    <!-- Information Section -->
    <div class="row py-4 justify-content-around" v-if="!hideSelectors">
        <base-select
          class="mb-0"
          :options="structuresOptions"
          v-model="currentStructureId"
          :inline="true"
          :label="$t('Structure') + ': '"
        />
        <base-select
          class="mb-0"
          :options="solutionsOptions"
          v-model="currentSolutionId"
          :inline="true"
          :label="$tc('solutionWords.solution', 1) + ':' "
        />
    </div>
    <div v-if="!fullWidth" :class="!sensors.length ? 'd-flex flex-column align-items-center' : ''">
      <div class="row justify-content-center">
        <div class="col-md-5 col-sm-12">  
          <div class="col-12 p-0 d-flex align-items-center mb-2 font-weight-bold">
            <div class="col-10 p-0 d-flex align-items-center">
              <i class="fas fa-microchip devices-icon"></i>
              <p class="mb-0 px-2 font-color-4"> {{ $t('deviceWords.numberOfDevices') }} </p>
            </div>
            <div class="col-2 p-0">
              <p class="mb-0 font-color-5 text-right">{{ solutionData.devices}}</p>
            </div>   
          </div>
          <div class="col-12 p-0 d-flex align-items-center font-weight-bold">
            <div class="col-10 p-0 d-flex align-items-center">
              <i class="el-icon-sunrise"></i>
              <p class="mb-0 px-2 font-color-4"> {{ $t('alarmsWords.operationalAlarms') }} </p>
            </div>
            <div class="col-2 p-0">
              <p class="mb-0 font-color-5 text-right">{{ totalOperationalAlarms }}</p>
            </div>   
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <operationalAlarmsDetails :hideBar="!fullWidth" :data="{...solutionData.operationalAlarms,title:''}" />
        </div>
      </div>
          <slider v-if="sensors.length"
                  :items="sensors"
                  :firstItemIndex="2"
                  :countOfElementShown="5"
                  :centerElementPrevIndex="2"
                  :centerElementNextIndex="3"
                  :slideMove="true"
          >
            <template #indicatorArea="{item}">
              <img :src="item.icon" alt="" class="sensor-icon" />
            </template>
            <template #sliderArea="{item}">
              <GaugeChart
                :stats="{
                  min: item.values.min,
                  max: item.values.max,
                  avg: item.values.avg,
                  }"
                :sliderTypeId="item.values.sliderTypeId"
                :ranges="item.values.ranges"
              />
            </template>
          </slider>
          <h4 v-if="!sensors.length && !loading" class="font-color-5" style="margin-top:150px">No Data</h4>
    </div>
    <div v-else>
      <div class="row align-items-center">
        <div class="col-lg-3 col-md-12">
          <div class="row">
            <div class="col-12 d-flex align-items-center mb-2 font-weight-bold">
              <div class="col-10 p-0 d-flex align-items-center">
                <i class="fas fa-microchip devices-icon"></i>
                <p class="mb-0 px-2 font-color-4"> {{ $t('deviceWords.numberOfDevices') }} </p>
              </div>
              <div class="col-2 p-0">
                <p class="mb-0 font-color-5">{{ solutionData.devices}}</p>
              </div>   
            </div>
            <div class="col-12 d-flex align-items-center font-weight-bold">
              <div class="col-10 p-0 d-flex align-items-center">
                <i class="el-icon-sunrise"></i>
                <p class="mb-0 px-2 font-color-4"> {{ $t('alarmsWords.operationalAlarms') }} </p>
              </div>
              <div class="col-2 p-0">
                <p class="mb-0 font-color-5">{{ totalOperationalAlarms }}</p>
              </div>   
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
           <slider v-if="sensors.length"
                  :items="sensors"
                  :firstItemIndex="3"
                  :countOfElementShown="5"
                  :centerElementPrevIndex="1"
                  :centerElementNextIndex="2"
          >
            <template #indicatorArea="{item}">
              <img :src="item.icon" alt="" class="sensor-icon" />
            </template>
            <template #sliderArea="{item}">
              <GaugeChart
                :stats="{
                  min: item.values.min,
                  max: item.values.max,
                  avg: item.values.avg,
                  }"
                :sliderTypeId="item.values.sliderTypeId"
                :ranges="item.values.ranges"
              />
            </template>
          </slider>
          <h4 v-if="!sensors.length && !loading" class="font-color-5" style="margin-top:200px;text-align: center;">No Data</h4>
        </div>
        <div class="col-lg-3 col-md-12">
          <operationalAlarmsDetails :vertical="fullWidth" :hideBar="!fullWidth" :data="{...solutionData.operationalAlarms,title:''}" />
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getDeep,getSensorIcon } from '@/util/helper';
import operationalAlarmsDetails from '@/pages/SWAM/components/operational-alarms-details';
import GaugeChart from "@/components/Charts/GaugeChart";
import Slider from "@/components/Slider/Slider";
import SolutionIcon from '@/pages/SWAM/components/SolutionIcon.vue';

export default {
  components: {
    Slider,
    GaugeChart,
    operationalAlarmsDetails,
    SolutionIcon
  },
  props: {
    namespace:{
        type:String,
        default: "SwamStore/StructurePortfolio"
        // required:true
    },
    moduleName: {
      type:String,
      default: "SolutionStatistics1"
    },
    globalMode: {
      type: Boolean,
      default:false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disableDataFetch: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: ()=> ({})
    },
    hidePeriodSelector: {
      type: Boolean,
      default: false,
    },
    hideSelectors: {
      type: Boolean,
      default:false,
    },
    selectedSolutions: {},
  },
  computed: {
    storePath() {
      return this.namespace + '/' + this.moduleName;
    },
    currentStructureId: {
      get() {
        return getDeep(this.$store.state,this.storePath + "/currentStructureId");
      },
      set(value) {
        this.$store.commit(this.storePath + "/selectStructure",value);
      }
    },
    currentSolutionId: {
      get() {
        return getDeep(this.$store.state,this.storePath + "/currentSolutionId");
      },
      set(value) {
        this.$store.commit(this.storePath + "/selectSolution",+value);
      }
    },
    currentPeriodIndex: {
      get() {
        return getDeep(this.$store.state,this.storePath + "/currentPeriodIndex");
      },
      set(value) {
        this.$store.commit(this.storePath + "/selectPeriod",value);
      }
    },
    sensorList() {
      if(!this.currentSolutionId) {
        return [];
      }
      let structures = this.haveDevices;
      if(this.currentStructureId) {
        structures = structures.filter(s => s.id == this.currentStructureId);
      }
      let sensorsIds = [];
      let result = [];
      structures.forEach(st => {
        let solution = st.solutions.find(sol => sol.id == this.currentSolutionId);
        if(solution) {
          solution.sensors.forEach(sensor => {
            if(sensorsIds.indexOf(sensor.id) == -1) {
              result.push(sensor);
              sensorsIds.push(sensor.id);
            }
          })
        }
      });
      return result;
    },
    solutionsOptions() {
      return this.solutionsForStructure(this.currentStructureId);
    },
    ...mapState({
      loading(state, getters) {
        return getters[this.storePath + "/loading"];
      },
      periodsOptions(state, getters) {
        return getters[this.storePath + "/periodsOptions"];
      },
      totalOperationalAlarms(state, getters) {
        return getters[this.storePath + "/totalOperationalAlarms"];
      },
      solutionData(state, getters) {
        return getters[this.storePath + "/solutionData"];
      },
    }),
    ...mapGetters("SwamStore", {
      structuresOptions: "structuresOptions",
      solutionsForStructure:"solutionsForStructure",
      haveDevices: "haveDevices",
    }),
    sensors() {
      let result = [];
      if(!this.solutionData || !this.solutionData.sensors)
        return result;
      Object.keys(this.solutionData.sensors).forEach(name => {
        result.push({
          title:name,
          values:this.solutionData.sensors[name],
          icon: getSensorIcon(name)
        });
      })
      return result;
    },
    solutionName() {
      let solution = this.solutionsOptions.find(sol => +sol.value == +this.currentSolutionId);
      if(!solution)
        return '-';
      return solution.label;
    },
    unSelectedSolutions() {
      if(!this.selectedSolutions)
        return [];
      return this.solutionsForStructure(this.currentStructureId).filter(sol => !this.selectedSolutions.includes(sol.value));
    },
  },
  methods: {
    ...mapActions({
        fetchSolutionData(dispatch, payload){
          return dispatch(this.storePath + "/fetchSolutionData",payload)
        },
    }),
    updateData() {
      if(this.currentSolutionId) {
        this.$emit('valid', {
          ...this.extractConfig()
        });
      }
      if(this.disableDataFetch) {
        return;
      }
      this.fetchSolutionData();
    },
    extractConfig() {
      if(this.currentSolutionId) {
        return {
          structure_id:this.currentStructureId,
          solution_id: this.currentSolutionId,
          chartsCount: this.sensorList.length
        }
      }
    },
    getUrl() {
      let url = '/SWAM/Solution-Portfolio?solution=' + this.currentSolutionId;
      if(this.currentStructureId) {
        url += '&structure=' + this.currentStructureId;
      }
      return url;
    },
    selectSolution(solution) {
      let oldId = this.currentSolutionId;
      let newId = solution.value;
      this.$emit('selectSolution',{oldId,newId});
      this.currentSolutionId = newId;
    }
  },
  mounted() {
    if(!this.globalMode) {
      this.currentSolutionId = this.solutionsOptions[0].value;
    }
    if(this.data.period) {
      let p = this.data.period;
      switch(p) {
        case 'week':
          this.currentPeriodIndex = 2;
          break;
        case 'month':
          this.currentPeriodIndex = 4;
          break;
        case 'quarter': 
          this.currentPeriodIndex = 6;
          break;
        case 'year': 
          this.currentPeriodIndex = 7;
          break;
      }
    }
    if(this.globalMode) {
      this.currentStructureId = this.data.structure_id;
      if(this.currentStructureId) {
        this.currentStructureId = +this.currentStructureId;
      }
      if(this.data.solution_id) {
        this.currentSolutionId = +this.data.solution_id;
      } else {
        this.currentSolutionId = this.solutionsOptions[0].value;
      }
    }
    
    
  },
  watch: {
    data: {
      handler() {
        if(this.data.period) {
          let p = this.data.period;
          switch(p) {
            case 'week':
              this.currentPeriodIndex = 2;
              break;
            case 'month':
              this.currentPeriodIndex = 4;
              break;
            case 'quarter': 
              this.currentPeriodIndex = 6;
              break;
            case 'year': 
              this.currentPeriodIndex = 7;
              break;
          }
        }
        if(this.globalMode) {
          this.currentStructureId = this.data.structure_id;
          if(this.currentStructureId) {
            this.currentStructureId = +this.currentStructureId;
          }
          if(this.data.solution_id) {
            this.currentSolutionId = +this.data.solution_id;
          }
        }
        
      },
      deep:true,
    },
    disableDataFetch() {
      this.updateData();
    },
    solutionsOptions() {
      if(this.globalMode) {
        this.currentSolutionId = (this.solutionsOptions[0] || {}).value;
      }
    },
    currentPeriodIndex() {
      this.updateData();
    },
    currentSolutionId() {
      this.updateData();
    },
    currentStructureId() {
      this.updateData();
    }
  },
};
</script>

<style scoped lang="scss">
i{
  font-size: 1.8rem;
}
.col-7 h4 {
  font-weight: bold;
}
.devices-icon{
  font-size: 1.5rem;
  transform: rotate(90deg);
  padding-left: 5px;
}
.sensor-icon{
  width: 20px;
}
.fullWidth-card-height {
  min-height: 500px;
}
.normal-card-height {
  min-height: 550px;
}

</style>
