<template>
  <card class="high-card-shadow">
    <div class="d-flex justify-content-between">
      <h3>Structure Info</h3>
    </div>
    <div class="card-description">
      <div class="col-12">
        <div class="row">
          <div class="col-5">
            <label>Structure Name:</label>
          </div>
          <div class="col-7 blue">{{s.structure_name}}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-5">
            <label>Structure Number:</label>
          </div>
          <div class="col-7 blue">{{s.structure_number}}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-5">
            <label>Structure Type:</label>
          </div>
          <div class="col-7 blue">{{s.structure_type}}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-5">
            <label>Address:</label>
          </div>
          <div class="col-7 blue">{{s.address}}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-5">
            <label>Latitude:</label>
          </div>
          <div class="col-7 blue">{{s.lat}}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-5">
            <label>longitude:</label>
          </div>
          <div class="col-7 blue">{{s.lng}}</div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
export default {
  data() {
    return {
      moreInfo: false
    };
  },
  computed: {
    s() {
      return this.$store.getters.selectedMO.structure_info;
    }
  }
};
</script>

<style scoped>
.blue {
  color: #1d8cf8;
}
</style>