




import {applyUniqueIdToAllObjectKeys} from "../../../../util/unique-id-generator";

let types = {
  RESET_THE_WHOLE_STATE_ACTION: 'RESET_THE_WHOLE_STATE_ACTION',
  GETTING_ALARMS_ACTION: 'GETTING_ALARMS_ACTION',
  GETTING_ALARMS_HISTORY_ACTION: 'GETTING_ALARMS_HISTORY_ACTION',
  ALARMS_TOTAL_RECORDS_ACTION: 'ALARMS_TOTAL_RECORDS_ACTION',
  GETTING_CUSTOMER_ACTION: 'GETTING_CUSTOMER_ACTION',
  CUSTOMER_CONTACTS_LIST_ACTION: 'CUSTOMER_CONTACTS_LIST_ACTION',
  OPEN_ACKNOWLEDGEMENT_MODAL_ACTION: 'OPEN_ACKNOWLEDGEMENT_MODAL_ACTION',
  OPEN_COMMENTS_MODAL_ACTION: 'OPEN_COMMENTS_MODAL_ACTION',
  OPEN_ESCALATE_MODAL_ACTION: 'OPEN_ESCALATE_MODAL_ACTION',
  OPEN_TERMINATE_MODAL_ACTION: 'OPEN_TERMINATE_MODAL_ACTION',
  UPDATE_ALARMS_TABLE_COLUMNS_CONFIG_ACTION: 'UPDATE_ALARMS_TABLE_COLUMNS_CONFIG_ACTION',
  ACK_ALARM_ACTION: 'ACK_ALARM_ACTION',
  ESCALATE_ALARM_ACTION: 'ESCALATE_ALARM_ACTION',
  DEVICES_COMPANIES_ACTION: 'DEVICES_COMPANIES_ACTION',
  LISTEN_TO_ACKNOWLEDGE_ALARM_ACTION: 'LISTEN_TO_ACKNOWLEDGE_ALARM_ACTION',
  NEW_ALARM_RECEIVED_FROM_SERVER_ACTION: 'NEW_ALARM_RECEIVED_FROM_SERVER_ACTION',
  TERMINATE_ALARM_ACTION: 'TERMINATE_ALARM_ACTION',
  LISTEN_TO_TERMINATE_ALARM_EVENT_ACTION: 'LISTEN_TO_TERMINATE_ALARM_EVENT_ACTION',
  UPDATE_ALARM_STATUS_ACTION: 'UPDATE_ALARM_STATUS_ACTION',
  CUSTOMER_LOCATIONS_ARRAY_ACTION: 'CUSTOMER_LOCATIONS_ARRAY_ACTION',
  GETTING_ALARMS_STATISTICS_ACTION: 'GETTING_ALARMS_STATISTICS_ACTION',
  GETTING_ALARMS_TABLE_COLUMNS_CONFIG_ACTION: 'GETTING_ALARMS_TABLE_COLUMNS_CONFIG_ACTION',
  GETTING_CUSTOMER_TABLE_COLUMNS_CONFIG_ACTION: 'GETTING_CUSTOMER_TABLE_COLUMNS_CONFIG_ACTION',
  GETTING_CUSTOMER_INFORMATION_ACTION:'GETTING_CUSTOMER_INFORMATION_ACTION',
  MORE_INFORMATION_FOR_ALARMS_ACTION: 'MORE_INFORMATION_FOR_ALARMS_ACTION'


};


types = applyUniqueIdToAllObjectKeys(types);
export default types;
