import VsaasService from '../../services/vsaas.service';
import { emptyTableCellFormatter } from "@/modules/common/utils/helper";

export default {
    namespaced:true,
    state: {
        loading: false,
        villaId: undefined,
        data: {},
        columns: [
            {
                prop:"deviceName",
                label:"nvr",
                minWidth:"100",
                align:"center",
                customTemplate: true
            },
            {
                prop: "nvrDetails",
                label:"nvr details",
                minWidth:"100",
                align:"center",
                customTemplate: true
            },
            {
                prop: "nvrAlarmsEmail",
                label:"nvr alarms email",
                minWidth:"150",
                align:"center",
                customTemplate: true
            },
            {
              prop:"cameraName",
              label:'camera name',
              minWidth:"100",
            },
            {
              prop:"channel",
              label:'channel',
              minWidth:"100",
            },
            {
              prop:"cameraDetails",
              label:'camera details',
              minWidth:"150",
              formatter: emptyTableCellFormatter,
            },
            {
              prop:"off",
              label:'state',
              minWidth:"100",
              formatter: emptyTableCellFormatter,
            }
        ],
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        villaId(state) {
            return state.villaId;
        },
        data(state) {
            return state.data;
        },
        tableColumns(state) {
            return state.columns;
        }
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setVillaId(state,value) {
            state.villaId = value;
        },
        setData(state,data) {
            state.data = data;
        }
    },
    actions: {
        async fetchData({state,commit}) {
            commit('setLoading',true);
            try {
                let data = await VsaasService.getVillaData(state.villaId);
                commit('setData',data);
                commit('setLoading',false);
            } catch(error) {
                commit('setLoading',false);
                console.log(error);
            }
        }
    }
};