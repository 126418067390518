export const MoroIoTSocketMixin = (namespace) => ({
  /**
   *  Assign runtime callbacks
   */
  beforeCreate() {
    if (!this[`sockets_${namespace}`]) this[`sockets_${namespace}`] = {};

    this[`sockets_${namespace}`].subscribe = (event, callback) => {
      // this.$moroIoTSocketIo.emitter.addListener(event, callback, this);
      this[`$moroIoTSocketIo_${namespace}`][`emitter_${namespace}`].addListener(event, callback, this);
    };

    this[`sockets_${namespace}`].unsubscribe = event => {
      // this.$moroIoTSocketIo[`emitter_${namespace}`].removeListener(event, this);
      this[`$moroIoTSocketIo_${namespace}`][`emitter_${namespace}`].removeListener(event, this);
    };
  },

  /**
   * Register all socket events
   */
  mounted() {
    if (this.$options[`sockets_${namespace}`]) {
      Object.keys(this.$options[`sockets_${namespace}`]).forEach(event => {
        if (event !== "subscribe" && event !== "unsubscribe") {
          this[`$moroIoTSocketIo_${namespace}`][`emitter_${namespace}`].addListener(
            event,
            this.$options[`sockets_${namespace}`][event],
            this
          );
        }
      });
    }
  },

  /**
   * unsubscribe when component unmounting
   */
  beforeDestroy() {
    if (this.$options[`sockets_${namespace}`]) {
      Object.keys(this.$options[`sockets_${namespace}`]).forEach(event => {
        this[`$moroIoTSocketIo_${namespace}`][`emitter_${namespace}`].removeListener(event, this);
      });
    }
  }
});
