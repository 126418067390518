<template>
  <div class="form-check" :class="[{ disabled: disabled }, inlineClass]">
    <label :for="cbId" class="form-check-label font-weight-normal font-size-14" style="padding-left: 30px; color: #707070;" :class="{'viewMode' : viewMode}">
      <input
        :id="cbId"
        class="form-check-input"
        type="checkbox"
        :disabled="disabled"
        v-model="model"
      />
      <span class="form-check-sign"></span>
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>
<script>
export default {
  name: "base-checkbox",
  model: {
    prop: "checked",
  },
  props: {
    checked: [Array, Boolean, Number],
    disabled: Boolean,
    inline: Boolean,
    hasError: Boolean,
    viewMode:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      cbId: "",
      touched: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(check) {
        if (!this.touched) {
          this.touched = true;
        }
        this.$emit("input", check);
      },
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
    },
  },
  created() {
    this.cbId = Math.random().toString(16).slice(2);
  },
};
</script>

<style lang="scss" scoped>
.viewMode{
  color: #ffff !important;
  opacity: 1 !important;
}
.form-check-label {
  text-transform: capitalize;
}
</style>

