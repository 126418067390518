<template>
  <card>
    <!-- add new zone -->
    <template slot="header">
      <div class="row">
        <div class="col-lg-6">Zones</div>

        <div class="col-lg-6 d-flex d-sm-block">
          <div
            class="btn-group btn-group-toggle"
            :class="isRTL ? 'float-left' : 'float-right'"
            data-toggle="buttons"
          >
            <label class="btn btn-sm btn-primary btn-simple" @click="addZone('circle')" >
              <input type="radio" name="options" autocomplete="off"/>
              <span class="d-none d-sm-block">Add circle</span>
            </label>
            <label class="btn btn-sm btn-primary btn-simple" @click="addZone('rectangle')">
              <input type="radio" name="options" autocomplete="off" />
              <span class="d-none d-sm-block">Add rectangle</span>
            </label>
          </div>
        </div>
      </div>
    </template>

    <!-- circle -->

    <div class="row">
      <div class="col-md-8">
        <GmapMap :center="center" :zoom="zoom" ref="map" style="width:100%;  height: 500px">
          <GmapCircle
            v-for="(zone, index) in circleZones"
            :key="index"
            :center="{lat:+zone.x1,lng:+zone.y1}"
            :radius="+zone.r"
            :options="{fillColor: zone.zone == form.zone ? 'green':'red' ,fillOpacity:0.4}"
            :editable="zone.zone === form.zone"
            :draggable="zone.zone === form.zone"
            @radius_changed="radiusChange"
            @center_changed="centerChanged"
            @click="updateZone(zone)"
          />
          <GmapRectangle
            v-for="(rectZone,index) in rectangleZones"
            :key="'rect'+index"
            :center="{lat:+rectZone.x1,lng:+rectZone.y1}"
            :options="{fillColor: rectZone.zone == form.zone ? 'green':'red' ,fillOpacity:0.4}"
            :editable="rectZone.zone === form.zone"
            :draggable="rectZone.zone === form.zone"
            :bounds="{south:+rectZone.x1,west:+rectZone.y1,north:+rectZone.x2,east:+rectZone.y2}"
            @bounds_changed="(bounds)=>{boundsChanged(bounds,rectZone)}"
            @click="updateZone(rectZone)"
          ></GmapRectangle>
        </GmapMap>
      </div>
      <div class="col-md-4">
        <div class="row">
          <!-- zone -->
          <div class="col-md-12">
            <base-input type="text" label="zone number" v-model="$v.form.zone.$model" readonly>
              <template v-if="$v.form.zone.$error" v-slot:error>
                <label class="error" v-if="!$v.form.zone.required">Zone number is require</label>
              </template>
            </base-input>
          </div>
        </div>
        <div class="row">
          <!-- name -->
          <div class="col-md-12">
            <base-input type="text" label="Name" v-model="$v.form.name.$model">
              <template v-if="$v.form.name.$error" v-slot:error>
                <label class="error" v-if="!$v.form.name.required">Zone name is require</label>
              </template>
            </base-input>
          </div>
        </div>
        <!-- radius -->
        <div class="row">
          <div class="col-md-12">
            <base-input
              type="text"
              label="Radius"
              v-model="$v.form.r.$model"
              :readonly="form.shape_type == 1"
            >
              <template v-if="$v.form.r.$error" v-slot:error>
                <label class="error" v-if="!$v.form.r.required">Radius is require</label>
              </template>
            </base-input>
          </div>
        </div>
        <!-- x1 -->
        <div class="row">
          <div class="col-md-12">
            <base-input type="text" label="x1" v-model="$v.form.x1.$model">
              <template v-if="$v.form.x1.$error" v-slot:error>
                <label class="error" v-if="!$v.form.x1.required">x1 is required</label>
              </template>
            </base-input>
          </div>
        </div>

        <!-- y1 -->

        <div class="row">
          <div class="col-md-12">
            <base-input type="text" label="y1" v-model="$v.form.y1.$model">
              <template v-if="$v.form.y1.$error" v-slot:error>
                <label class="error" v-if="!$v.form.y1.required">y1 is required</label>
              </template>
            </base-input>
          </div>
        </div>
        <!-- x2 -->
        <div class="row">
          <div class="col-md-12">
            <base-input
              label="X2"
              type="text"
              v-model="$v.form.x2.$model"
              :readonly="form.shape_type == 0"
            >
              <template v-if="$v.form.x2.$error" v-slot:error>
                <label class="error" v-if="!$v.form.x2.required">x2 is required</label>
              </template>
            </base-input>
          </div>
        </div>
        <!-- y2 -->
        <div class="row">
          <div class="col-md-12">
            <base-input
              label="Y2"
              type="text"
              v-model="$v.form.y2.$model"
              :readonly="form.shape_type == 0"
            >
              <template v-if="$v.form.y2.$error" v-slot:error>
                <label class="error" v-if="!$v.form.y2.required">y2 is required</label>
              </template>
            </base-input>
          </div>
        </div>
        <base-button
          native-type="submit"
          type="primary"
          class="btn-fill btn-sm"
          @click.prevent="submit"
        >Save All</base-button>
        

        <!-- delete -->
        <base-button
          native-type="submit"
          type="primary"
          class="btn-fill btn-sm"
          @click.prevent="deleteZone(false)"
        >Delete</base-button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <el-table :data="tabledata">
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="column.label"
          ></el-table-column>

          <el-table-column min-width="150" header-align="right" align="right" label="Shape Type">
            <div
              slot-scope="{ row}"
              class="text-right table-actions"
            >{{row.shape_type == 0 ? 'Circle' : 'Rectangle'}}</div>
          </el-table-column>

          <el-table-column min-width="150" header-align="right" align="right" label="Actions">
            <div slot-scope="{ row }" class="text-right table-actions">
              <el-tooltip content="update zone" effect="light" :open-delay="300" placement="top">
                <base-button
                  @click.native="updateZone(row)"
                  class="edit btn-link"
                  type="warning"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-pencil"></i>
                </base-button>
              </el-tooltip>
              <el-tooltip content="Delete" effect="light" :open-delay="300" placement="top">
                <base-button
                  @click.native="deleteZone(row)"
                  class="remove btn-link"
                  type="danger"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </card>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { required, email, requiredIf, sameAs } from "vuelidate/lib/validators";
import swal from "sweetalert2";

export default {
  props:['customer_id'],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      form: {
        name: "",
        zone: 0,
        shape_type: "",
        r: 0,
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
        is_edit: false,
        // frame_border: 1,
        // priority: 3,
        // operand: 3,
        // eventual_records: 1,
        // send_sms_to: 0,
        // sms_text: "Geofence Zone #",
      },

      device_id: "",
      // customer_id: "4",
      zoom: 17,
      center: { lat: 25.2048, lng: 55.2708 },
      zones: [
        // {
        //     "device_id": 36,
        //     "zone": 1,
        //     "is_ack": 1,
        //     "shape_type": 0,
        //     "r": 2,
        //     "x1": 25.1224,
        //     "y1": 55.3804,
        //     "x2": 0,
        //     "y2": 0,
        //     "frame_border": 1,
        //     "priority": 3,
        //     "operand": 3,
        //     "eventual_records": 1,
        //     "send_sms_to": 0,
        //     "sms_text": "Geofence Zone #"
        // }
      ],

      tableColumns: [
        {
          prop: "zone",
          label: "Zone",
          minWidth: 100,
        },
        {
          prop: "r",
          label: "Radius",
          minWidth: 100,
        },

        {
          prop: "x1",
          label: "Lat",
          minWidth: 200,
        },
        {
          prop: "y1",
          label: "Lng",
          minWidth: 200,
        },
        {
          prop: "x2",
          label: "Lat2",
          minWidth: 200,
        },
        {
          prop: "y2",
          label: "Lng2",
          minWidth: 200,
        },
      ],
    };
  },

  watch: {
    form: {
      deep: true,
      handler(val) {
        let index = this.zones.findIndex((zone) => zone.zone == val.zone);
        if (index > -1) {
          let zone = this.zones[index];
          this.zones.splice(index, 1, {
            ...zone,
            ...val,
          });
        }
      },
    },
  },
  computed: {
    isRTL() {
      return this.$rtl.isRTL;
    },
    circleZones() {
      return this.zones.filter((zone) => zone.shape_type == 0);
    },
    rectangleZones() {
      return this.zones.filter((zone) => zone.shape_type == 1);
    },
    tabledata() {
      return this.zones.filter((zone) => zone.is_edit);
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
      zone: {
        required,
      },
      shape_type: {
        required,
      },
      r: {
        required: requiredIf(function () {
          return this.form.shape_type == 0;
        }),
      },
      x1: {
        required,
      },
      y1: {
        required,
      },
      x2: {
        required: requiredIf(function () {
          return this.form.shape_type == 1;
        }),
      },
      y2: {
        required: requiredIf(function () {
          return this.form.shape_type == 1;
        }),
      },
    },
  },
  mounted() {

    this.$api
      .get(`teltonika/geofence/${this.customer_id}`)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data.map((zone) => {
            zone.is_edit = true;
            return zone;
          });
          this.zones = data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    updateZone(selectedZone) {
      let form = {
        name: selectedZone.name,
        zone: selectedZone.zone,
        shape_type: selectedZone.shape_type,
        r: selectedZone.r,
        x1: selectedZone.x1,
        y1: selectedZone.y1,
        x2: selectedZone.x2,
        y2: selectedZone.y2,
        is_edit: selectedZone.is_edit,
      };
      this.form = form;
    },
    addZone(type) {
      let zone_numbers = this.zones.map((z) => z.zone);
      let zone;

      if (zone_numbers.length > 0) {
        for (var i = 1; i <= 50; i++) {
          if (zone_numbers.indexOf(i) == -1) {
            zone = i;
            break;
          }
        }
      } else {
        zone = 1;
      }

      if (!zone) {
        this.$notify({
          title: "Warning",
          message: "You can not add more than 50 zone",
          timeout: 3000,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "warning",
        });
        return;
      }

      if (type == "circle") {
        this.addCircleZone(zone);
      } else if (type === "rectangle") {
        this.addRectangleZone(zone);
      }
    },
    addCircleZone(zone) {
      let x1 = this.center.lat;
      let y1 = this.center.lng;
      let r = 50;

      let zoneToInsert = {
        name: "",
        zone,
        shape_type: 0,
        r,
        x1,
        y1,
        x2: 0,
        y2: 0,
        is_edit: false,
      };
      //
      this.form = zoneToInsert;

      this.zones.push(zoneToInsert);
    },
    addRectangleZone(zone) {
      let x1 = this.center.lat;
      let y1 = this.center.lng;
      let x2 = this.center.lat + 0.0008;
      let y2 = this.center.lng + 0.0008;

      let zoneToInsert = {
        name: "",
        zone,
        shape_type: 1,
        r: 0,
        x1,
        y1,
        x2,
        y2,
        is_edit: false,
      };
      //

      this.form = zoneToInsert;

      this.zones.push(zoneToInsert);
    },

    radiusChange(radius, zone) {
      this.form.r = radius;
    },
    centerChanged(center, zone) {
      this.form.x1 = center.lat();
      this.form.y1 = center.lng();
    },

    // done
    boundsChanged(bounds, zone) {
      var ne = bounds.getNorthEast();
      var sw = bounds.getSouthWest();

      this.form.x1 = sw.lat();
      this.form.y1 = sw.lng();
      this.form.x2 = ne.lat();
      this.form.y2 = ne.lng();
    },

    reset() {
      this.form = {
        name: "",
        zone: 0,
        shape_type: "",
        r: 0,
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
        is_edit: false,
      };
    },
    confirmDelete(zone) {
      // api
      this.$api
            .patch(`teltonika/geofence/${this.customer_id}`, {zones:[],to_delete:[zone]})
            .then((res) => {
              if(res.data.msg === 'success'){
                this.removeZone(zone);
                this.reset();
              }
            })
            .catch((err) => {
              console.log(err);
            });
    },
    removeZone(zone) {
      let index = this.zones.findIndex((z) => z.zone === zone);
      if (index > -1) {
        this.zones.splice(index, 1);
      }
    },
    deleteZone(zone) {
      if (zone) {
        console.log("zone exist", zone);
        swal({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: "Yes, delete it!",
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.confirmDelete(zone.zone);
            swal({
              title: "Deleted!",
              text: `You deleted ${zone.zone}`,
              type: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          }
        });
      } else {
        if (this.form.zone) {
          if (this.form.is_edit) {
            swal({
              title: "Are you sure?",
              text: `You won't be able to revert this!`,
              type: "warning",
              showCancelButton: true,
              confirmButtonClass: "btn btn-success btn-fill",
              cancelButtonClass: "btn btn-danger btn-fill",
              confirmButtonText: "Yes, delete it!",
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.confirmDelete(this.form.zone);
                swal({
                  title: "Deleted!",
                  text: `You deleted ${this.form.zone}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false,
                });
              }
            });
          } else {
            this.removeZone(this.form.zone);
            this.reset();
          }
        } else {
          this.$notify({
            title: "Warning",
            message: "You must select Zone first",
            timeout: 3000,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "warning",
          });
        }
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {

        let data = this.zones;

        data = data.map((zone) => {
          return {
            customer_id : this.customer_id,
            name:zone.name,
            zone: zone.zone,
            shape_type: zone.shape_type,
            r: zone.r,
            x1: zone.x1,
            y1: zone.y1,
            x2: zone.x2,
            y2: zone.y2,
            is_new: zone.is_edit ? 0:1
          };
        });

        if(data.find(z=>z.name == '')){
              this.$notify({
            title: "Warning",
            message: "One of your zone don't have name",
            timeout: 3000,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "warning",
          });
          return;
        }
        

        
          this.$api
            .patch(`teltonika/geofence/${this.customer_id}`, {zones:data,to_delete:[]})
            .then((res) => {
              console.log(res);
              
              if (res.data.msg == 'success') {

                for(var i=0;i<this.zones.length;i++){
                  this.$set(this.zones,i,{...this.zones[i],is_edit:true})
                }
                console.log(res)  
                
              }
            })
            .catch((err) => {
              console.log(err);
            });
        
      }
    },

    
  },
};
</script>
<style scoped>
</style>