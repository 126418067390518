<template>
  <card class="my-container">
    <h1>Company Depatments</h1>
    <div align="right">
      <base-button round icon type="primary" @click="registerNewDepatment">
        <i class="tim-icons icon-simple-add"></i>
      </base-button>
    </div>
    <el-table :data="departments" style="width: 100%">
      <el-table-column label="Name" prop="name" sortable></el-table-column>
      <!-- <el-table-column label="Floors" prop="floors" sortable></el-table-column> -->
      <el-table-column label="Zones Count" prop="zones_count" sortable></el-table-column>
      <el-table-column label="Working Hours" prop="working_hours" sortable></el-table-column>
      <el-table-column label="registered At" prop="created_at" sortable></el-table-column>
    </el-table>
  </card>
</template>

<script>
import { mapGetters } from "vuex";
import CompanyDepartmentsCreate from "./Company-Departments-Create.vue";

export default {
  data() {
    return {
      departments:[]
    };
  },
  created(){
    this.$store.dispatch('getDepartments')
      .then(data=>{
        this.departments = data;
        this.departments.forEach(d=>{
          d.created_at = this.gDateFormat(d.created_at);
        })
      });
  },
  methods: {
    registerNewDepatment() {
      this.$router.replace({ name: "register new department" });
    },
  },
  computed: {
    ...mapGetters({
      company: "CompanyData",
    }),
  },
  components: {
    CompanyDepartmentsCreate,
  },
};
</script>

<style scoped>
.my-container {
  margin-top: 50px;
}
.card {
  background-color: #27293d99 !important;
}
</style>
