<template>
  <div class="col-12 p-0" v-loading="loading">
    <h4 class="col-12 card-title font-weight-bold">{{ $t('needsImprovements') }}</h4>
    <hr class="m-0" style="border-top-width: 2px;"/>
    <div class="col-12 background-1">
      <operationalAlarmsDetails 
        :vertical="true" 
        v-for="(structure,i) in data" :key="i" 
        :data="getData(structure)" 
        class="p-0"
        :link="getUrl(structure.id)"
      />  
    </div>
  </div>
  
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import operationalAlarmsDetails from '@/pages/SWAM/components/operational-alarms-details';
export default {
    components: {
      operationalAlarmsDetails,
    },
    props: {
      namespace:{
          type:String,
          required:true
      },
      disableDataFetch: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState({
          loading(state,getters){
            return getters[this.namespace+"/HighestProblems/loading"]
          },
          data(state,getters){
            return getters[this.namespace+"/HighestProblems/getData"]
          },
      }),
      ...mapGetters("SwamStore", {
        structures: "haveDevices",
      }),
    },
    methods: {
      ...mapActions({
          fetchData(dispatch, payload){
            return dispatch(this.namespace+"/HighestProblems/fetchData",payload)
          },
      }),
      getUrl(id) {
        return '/SWAM/Alarms/operational' + '?structure_id=' + id;
      },
      getData(structure) {
        let structureName = this.structures.find(s => s.id == structure.id);
        if(!structureName) {
          return {
            ...structure,
            title: '-'
          }
        }
        structureName = structureName.structure_name;
        return {
          ...structure,
          title: structureName
        }
      }
    },
    mounted() {
      if(!this.disableDataFetch) {
        this.fetchData();
      }
    },
    watch: {
      disableDataFetch() {
        if(!this.disableDataFetch) {
          this.fetchData();
        }
      }
    }
}
</script>

<style scoped lang="scss">
h4 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>