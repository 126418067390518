import Axios from "axios";


/**
 * Permissions Access Level
 */
export var P = {
    add_amc: -1,
    edit_amc: -1,
    view_amc: -1,
    add_fmc: -1,
    edit_fmc: -1,
    view_fmc: -1,
    add_customer: -1,
    edit_customer: -1,
    view_customer: -1,
    add_authority: -1,
    edit_authority: -1,
    view_authority: -1,
    change_permissions: -1,
    create_ticket: -1,
    view_tickets: -1,
    create_maintenance_order: -1,
    view_maintenance_orders: -1
}
/**
 * same data as P object but in the array format
 * [ { action_id, key } ]
 */
export var permissionsArray;

export const setPermissions = (permissions) => {
    permissions.forEach(element => {
        P[element.key] = element.action_id;
    });
    permissionsArray = permissions;
}

export const getAllRaw = Axios.get("auth/permissions/all")
    .then(res => {
        return { permissionsArray: res.data.permissions, modulesArray: res.data.modules }
    });
export const getAll = getAllRaw
    .then(res => {
        setPermissions(res.permissionsArray);
        setModules(res.modulesArray);
        return { P, M }
    });
/**
 * Modules Access Level
 */
export var M = {
    amc: -1,
    fmc: -1,
    operator: -1,
    authority: -1,
    paying_customer: -1,
    maintenance: -1,

    admin: -1,
    rules: -1,
    alarms: -1,
    analysis: -1,
    logs: -1,
    // release :-1,
    orders: -1,
    teltonika: -1,
    iot: -1,
    templates: -1,
    sensors: -1,
    ticketing: -1,
    alarms_notifications: -1,
    structures: -1,
    reports_builder: -1,
    attendance: -1,
    tracking: -1,
    user_dashboard: -1,
    status_alarms: -1
};

export var setModules = (modules) => {
    modules.forEach(element => {
        M[element.key] = element.module_id;
    });
    // console.log(M);

    // if(M.amc || M.fmc || M.paying_customer || M.operator || M.authority){
    //     M.companies=true;
    // }else{
    //     M.companies=false;
    // }
}

export function checkAccess(hexString, ...args) {
    //return true;
    if (!hexString || typeof hexString !== "string") {
        return true;
    }
    if (args == undefined) {
        return true;
    }
    var pairs = hexString.match(/[\dA-F]{2}/gi);

    var integers = pairs.map(function (s) {
        return parseInt('0x' + s);
    });

    let buffer = new Uint8Array(integers);

    for (let i = 0; i < args.length; i++) {
        if (args[i] == -1 || args[i] == undefined) {
            console.error("UNREGISTRED MODULE/PERMISSION ERROR!!!", args[i])
            return true;
        }
        const byteIndex = parseInt(args[i] / 8);
        const bitIndex = args[i] % 8;
        if ((buffer[byteIndex] & (1 << (7 - bitIndex))) == 0)
            return false;
    }
    return true;
}

// export function page(...permissions) {
//     const user = JSON.parse(localStorage.getItem('user'))
//     if (!user)
//         return false;
//     console.log('page check ', permissions)
//     console.log('page check ', checkAccess(user.userPermissions, ...permissions))
//     return checkAccess(user.userPermissions, ...permissions)
// }
// export function module(module) {
//     const user = JSON.parse(localStorage.getItem('user'))
//     if (!user)
//         return false;
//     console.log('modules check ', module)
//     if (!user.userModule)
//         user.userModule = "fffff"
//     return checkAccess(user.userModule, module)
// }
