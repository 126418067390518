<template>
  <div class="row justify-content-center">
    <div class="col-7">
      <card>
        <h3 slot="header">Fill Your Medical Information To Continue :</h3>
        <MedicalInfo @submitted="submitted" :formOnly="true" :id="id"></MedicalInfo>
      </card>
    </div>
  </div>
</template>

<script>
import MedicalInfo from "./../Profiles/Create-User/Medical-info";
export default {
  data() {
    return {
      id: null,
    };
  },
  created() {
    this.id = this.$store.getters.user.id;
    this.$store.dispatch("getUserMedicalInfo", this.id);
  },
  methods: {
    submitted() {
      this.$store.state.user.medical_info_required = 0;
      localStorage.setItem('user', JSON.stringify(this.$store.state.user))
      this.$router.push("/");
    },
  },
  components: {
    MedicalInfo,
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  width: 70%;
}
</style>