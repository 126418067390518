<template>
  <div>
    <card :bodyClasses="'pb-0 pt-0'">
      <h2 slot="header" class="card-title mb-5">
        {{ $t("account.changePassword") }}
      </h2>
      <div class="row h-100">
        <!-- old password -->
        <base-input
          name="password"
          type="password"
          v-model="$v.password.old_password.$model"
          :placeholder="$t('account.oldPassword')"
          class="col-12 edit-input"
          addon-left-icon="tim-icons icon-email-85"
          autocomplete="off"
        >
          <template v-if="$v.password.old_password.$error" v-slot:error>
            <label class="error" v-if="!$v.password.old_password.required"
              >{{ $t("account.oldPassword") }}
              {{ $t("validations.isRequired") }}</label
            >
          </template>
        </base-input>
        <!-- new password -->
        <base-input
          name="password"
          type="password"
          v-model="$v.password.password.$model"
          :placeholder="$t('account.password')"
          class="col-12"
          addon-left-icon="tim-icons icon-email-85"
          autocomplete="off"
        >
          <template v-if="$v.password.password.$error" v-slot:error>
            <label class="error" v-if="!$v.password.password.required"
              >{{ $t("account.password") }}
              {{ $t("validations.isRequired") }}</label
            >
            <label
              class="error"
              v-else-if="!$v.password.password.checkPassword"
              >{{ $t("validations.passwordLength") }}</label
            >
          </template>
        </base-input>
        <!-- confirm password -->
        <base-input
          name="confirm password"
          type="password"
          v-model="$v.password.confirm_password.$model"
          :placeholder="$t('account.confirmPassword')"
          addon-left-icon="tim-icons icon-email-85"
          autocomplete="off"
          class="col-12"
        >
          <template v-if="$v.password.confirm_password.$error" v-slot:error>
            <label class="error" v-if="!$v.password.confirm_password.required"
              >{{ $t("account.password") }}
              {{ $t("validations.isRequired") }}</label
            >
            <label
              class="error"
              v-if="!$v.password.confirm_password.sameAsPassword"
              >{{ "validations.notSamePassword" }}</label
            >
          </template>
        </base-input>
      </div>
      <!-- submit button -->
      <span class="d-flex justify-content-center mt-0" slot="footer">
        <base-button @click="submitChangePassword" block type="success">{{
          $t("Submit")
        }}</base-button>
      </span>
    </card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, sameAs } from "vuelidate/lib/validators";
import store from "../store";

function checkPassword(str) {
  let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}

export default {
  name: "ChangePassword",

  data() {
    return {
      password: {
        password: null,
        confirm_password: null,
        old_password: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      user: "UserData",
      currentUser: "user",
    }),
  },

  validations: {
    password: {
      old_password: {
        required,
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
      password: {
        checkPassword,
        required,
      },
    },
    validationGroup: ["password"],
  },

  methods: {
    submitChangePassword() {
      this.$v.validationGroup.password.$touch();
      if (!this.$v.validationGroup.password.$invalid) {
        this.$notify({
          message: "You Have Errors on Your new data!",
          type: "danger",
        });
        return;
      }
      store.actions
        .submitChangePassword(this.password)
        .then((data) => {
          console.log(data);
          this.password = {
            password: null,
            confirm_password: null,
            old_password: null,
          };
          this.$v.$reset();
          this.$notify({
            message: "Your Password Updated Successfully!",
            type: "success",
          });
        })
        .catch();
    },
  },
};
</script>

<style lang="scss" scoped></style>
