<template>
  <card card-body-classes="table-full-width " class="deviceState-mar">
    <h4 slot="header" class="card-title">{{$t("Devices status")}}</h4>

    <div>
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <!--<img src="./../assets/images/chart.png">-->

        <div class="col-xl-6 col-lg-7 col-md-6 col-xs-12">
          <!--<img src="./../assets/images/chart.png">-->
          <span class="ch-numb">
            <img
              class="papa"
              src="./../../../assets/images/sv .svg"
              style="width: 57px;
                    padding-right: 6px"
            />

            <span class="ch-title">{{$t("Total Devices")}}</span>
            &nbsp; {{totalDevices}}
          </span>
        </div>
      </div>

      <table class="table tablesorter devicestatus">
        <thead>
          <tr>
            <th class="th-table-dv-first"></th>
            <th class="d-s-th">
              <span class="state-dev">{{problem}}</span>

              <span>
                <i class="fas fa-circle fa-s circle-problem"></i>
                <span class="sizeState">{{$t("PROBLEM")}}</span>
              </span>
            </th>
            <th class="d-s-th">
              <span class="state-dev">{{poor}}</span>
              <span>
                <i class="fas fa-circle fa-s circle-poor"></i>
                <span class="sizeState">{{$t("POOR")}}</span>
              </span>
            </th>
            <th class="d-s-th">
              <span class="state-dev">{{ok}}</span>
              <span>
                <i class="fas fa-circle fa-s circle-ok"></i>
                <span class="sizeState">{{$t("OK")}}</span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(solution, index) in solutions" :key="index">
            <td>
              <div class="devicesnum w-100 d-flex">
                <button
                  style
                  class="btn d-inline-block"
                  :class="{
                            'btn-danger text-center btn-sm w-100': solution.name =='Indoor Air Monitoring',
                            'btn-info text-center btn-sm w-100': solution.name === 'Swimming Pool Monitoring',
                            'btn-warning text-center btn-sm w-100': solution.name == 'Cold Storage',
                            'btn-success text-center btn-sm w-100':solution.name === 'Water Pressure Monitoring',
                            'btn-primary text-center btn-sm w-100': solution.name === 'Outdoor Air Monitoring'
                        }"
                  @click="goToDevices(solution.id)"
                >{{solution.name}}</button>
                <!-- <span class=""> -->

                <span class="inside-table-devices d-inline-block">
                  <span>{{solution.numOfDevices}}</span>
                  <span>{{$t("Devices")}}</span>
                </span>
                <!-- </span> -->
              </div>
            </td>
            <td>
              <span>{{solution.alarm_counts.problem}}</span>
            </td>
            <td>
              <span>{{solution.alarm_counts.poor}}</span>
            </td>
            <td>
              <span>{{solution.alarm_counts.ok}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    ></div>
  </card>
</template>

<script>
export default {
  data() {
    return {
      solutions: [],
    };
  },

  computed: {
    totalDevices() {
      return this.solutions.reduce(function (acc, curr) {
        let num = curr.numOfDevices ? curr.numOfDevices : 0;
        return acc + parseInt(num);
      }, 0);
    },

    problem() {
      return this.solutions.reduce(
        (acc, curr) => acc + parseInt(curr.alarm_counts.problem),
        0
      );
    },
    poor() {
      return this.solutions.reduce(
        (acc, curr) => acc + parseInt(curr.alarm_counts.poor),
        0
      );
    },
    ok() {
      return this.solutions.reduce(
        (acc, curr) => acc + parseInt(curr.alarm_counts.ok),
        0
      );
    },
  },
  mounted() {
    this.$api
      .get(`iot/solutions/devicestatuses`)
      .then((res) => {
        if (res.data.msg === "success") {
          this.solutions = res.data.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    goToDevices(id) {
      this.$router.push({ path: `device/list?solution_id=${id}` });
    },
  },
};
</script>
<style scoped>
@media (max-width: 575.98px) {
  .state-dev {
    padding-left: 0px;
    font-size: 10px;
  }
}

@media (max-width: 767.98px) {
  .state-dev {
    padding-left: 0px;
    font-size: 10px;
  }

  .th-table-dv-first {
    max-width: 140px;
  }
}

@media (max-width: 991.98px) {
  .state-dev {
    padding-left: 0px;
    font-size: 10px;
  }

  .th-table-dv-first {
    width: 140px;
  }
}

@media (max-width: 1199.98px) {
  .state-dev {
    padding-left: 0px;
    font-size: 10px;
  }
}

@media (min-width: 1200px) {
  .devicestatus .sizeState {
    font-family: greyFont;
    font-size: 13px !important;
  }
}

.total-devices {
  padding-left: 3px;
  color: rgb(63, 172, 121);
  padding-right: 3px;
}

.devicestatus,
.devicestatus .btn,
.devicestatus th,
.devicestatus span {
  font-size: 10px !important;
  text-align: left;
  padding: 4px 4px;
}

.ch-numb {
  font-family: greyFont;
  font-size: 15px;
  color: #ffff;
  margin-left: 10px;
  vertical-align: middle;
}

.devicestatus .n-l {
  padding-left: 4px;
}



.devicestatus .state-dev {
  padding-right: 8px;
  font-family: greyFont;
  font-size: 16px !important;
}

.deviceState-mar {
  margin-bottom: 0px !important;
}

.card {
  height: 96% !important;
}

th {
  padding-top: 1px !important;
}

.inside-table-devices {
  padding-left: 5px;
}

.d-s-th span {
  display: inline-block;
}

.d-s-th {
  flex-direction: column-reverse;
}
</style>
