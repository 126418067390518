import ForkliftService from "../../services/forklift.service";

export default {
    namespaced: true,
    state: {
        loading: false,
        vehicleInfo: {},
        vehicles: [],
        currentVehicleId: undefined
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        vehicleInfo(state) {
            return state.vehicleInfo;
        },
        vehicles(state) {
            return state.vehicles;
        },
        vehicleDrivers(state) {
            return state.vehicleInfo.drivers;
        }
    },
    mutations: {
        setLoading(state, value) {
            state.loading = value;
        },
        setVehicleInfo(state, vehicleInfo) {
            state.vehicleInfo = vehicleInfo;
        },
        setVehicles(state, vehicles) {
            state.vehicles = vehicles
        },
        selectVehicle(state, value) {
            state.currentVehicleId = value;
        }
    },
    actions: {
        async fetchVehicleInfo({ state, commit }, { id, params = '' }) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true);
                ForkliftService.getForkliftInfo(id, params)
                    .then((res) => {
                        commit("setVehicleInfo", res.data.data);
                        commit("setLoading", false);
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        commit('setLoading', false)
                        reject(err)
                    });
            });
        },
        async fetchVehicles({ state, commit }) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true);
                ForkliftService.getForklifts()
                    .then((res) => {
                        commit("setVehicles", res.data.data);
                        commit("selectVehicle", res.data.data[0].id)
                        commit("setLoading", false);
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        commit('setLoading', false)
                        reject(err)
                    });
            });
        },
    }
}