var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canModule(_vm.M.intrusion))?_c('sidebar-item',{attrs:{"link":{
    name: 'Intrusion System',
    cartLeft: true,
  }}},[_c('sidebar-item',{attrs:{"link":{
      name: 'general status',
      path: '/intrusion-system/dashboard',
    }}}),_c('sidebar-item',{attrs:{"link":{
      name: 'Intrusion Alarms',
      path: '/intrusion/intrusion-alarms',
    }}}),(_vm.canModule(_vm.M.intrusion_operational))?_c('sidebar-item',{attrs:{"link":{
      name: 'Operational Alarms',
      path: '/intrusion/operational-alarms',
    }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
      name: 'Villas List',
      path: '/intrusion/villas-list',
    }}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }