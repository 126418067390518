import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import { M } from "../../Permissions";

const CompanyList = () =>
  import('./CompanyList.vue');

const CreateCompany = () =>
  import('./CreateCompany.vue');

const WebhookList = ()=>
  import('src/pages/Webhooks/WebhookList');

const CreateWebhook = ()=>
  import('src/pages/Webhooks/CreateWebhook');




export default {
  path: '/company',
  component: DashboardLayout,
  redirect: '/company/list',
  meta: {
    // role: 2
    modules: {
      data: [M.fmc, M.amc, M.operator, M.authority, M.paying_customer],
      opration: 'or'
    }
  },
  //  name: 'Company',
  children: [
    {
      path: 'list',
      name: 'Company List',
      component: CompanyList
    },

    {
      path: 'create',
      name: 'Company Create',
      component: CreateCompany
    },
    {
      path: ':id/webhook',
      name: 'Company Webhook',
      component: WebhookList,
      props:true
    },
    {
      path: ':id/webhook/create',
      name: 'Create company Webhook',
      component: CreateWebhook,
      props:true
    },
    {
      path: ':id/webhook/:webhookid/update',
      name: 'Update company Webhook',
      component: CreateWebhook,
      props:true
    },
  ]
}
