import HighestProblems from "@/pages/SWAM/core/Statistics/highest-Problems/store";
import Administrative from "@/pages/SWAM/core/Statistics/Administrative/store";
import StatisticsSolutions from "@/pages/SWAM/core/Statistics/Solutions/store";

export default {
    namespaced:true,
    modules: {
        HighestProblems,
        Administrative,
        StatisticsSolutions,
    }
}