var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"card-body tabs-events p-1"},[_c('div',{staticClass:"row justify-content-around"},[_c('h5',{staticClass:"dash-title nav-link c-p",class:_vm.currentTab === 1 ? 'active' : '',on:{"click":function($event){$event.preventDefault();_vm.currentTab = 1}}},[_vm._v(" "+_vm._s(_vm.$t("Permissions"))+" ")])]),(_vm.currentTab == 1)?[_c('div',[_c('autocomplete',{ref:"autocomplete",attrs:{"placeholder":_vm.$t('Search For Permission'),"source":_vm.permissionsArray,"results-property":"data","results-display":"key"},on:{"input":_vm.selectPermission}}),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.selectedPermissions,"height":"400"}},[_c('el-table-column',{attrs:{"min-width":_vm.mode=='Group'?35:70,"label":"ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{staticStyle:{"font-weight":"bold","color":"white","text-transform":"uppercase"}},[_vm._v(_vm._s(row.key))])}}],null,false,625544957)}),(_vm.mode=='Group')?_c('el-table-column',{attrs:{"min-width":55,"label":_vm.$t('Alias Name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.name),expression:"row.name"}],staticClass:"my-input",attrs:{"placeholder":_vm.$t('Set An Alias Name'),"type":"text","maxlength":"20","minlength":"3"},domProps:{"value":(row.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "name", $event.target.value)}}})]}}],null,false,694353717)}):_vm._e(),_c('el-table-column',{attrs:{"min-width":15,"label":_vm.$t('Remove')},scopedSlots:_vm._u([{key:"default",fn:function(data){return [_c('i',{staticClass:"fa fa-trash text-danger fa-lg",on:{"click":function($event){return _vm.removePermission(data.row,data.$index)}}})]}}],null,false,4181878329)}),_c('div',{staticClass:"w-100 d-flex align-items-center vh-25",attrs:{"slot":"empty"},slot:"empty"},[_c('p',{staticClass:"h5 no-per"},[_vm._v(_vm._s(_vm.$t("No Permissions Selected")))])])],1)],1)]:_vm._e(),(_vm.currentTab == 2)?[_c('div',[_c('autocomplete',{ref:"gautocomplete",attrs:{"placeholder":_vm.$t('Search For Groups'),"source":_vm.groupsArray,"results-property":"data","results-display":"display"},on:{"input":_vm.addGroup}}),_c('p',{staticClass:"mt-1 text-white mb-0"},[_vm._v(_vm._s(_vm.$("included Groups")))]),_c('el-table',{staticStyle:{"width":"100%","border":"1px solid"},attrs:{"data":_vm.selectedGroups,"height":"400","border":""}},[_c('el-table-column',{attrs:{"min-width":30,"label":_vm.$('Group Name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('div',{staticClass:"position-relative"},[_c('i',{staticClass:"el-icon el-icon-close text-danger remove-icon",on:{"click":function($event){return _vm.excludeGroup($index)}}}),_c('p',{staticClass:"h6 text-center group-name"},[_vm._v(_vm._s(row.name))])])]}}],null,false,3676855963)}),_c('el-table-column',{attrs:{"min-width":65,"label":_vm.$('Permissions')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row group-permissions"},_vm._l((row.permissions),function(perm,index){return _c('p',{key:index,staticClass:"badge badge-dark col-lg-5 mb-1",staticStyle:{"font-size":"1rem","white-space":"pre-wrap"}},[_vm._v(_vm._s((perm.name && _vm.viewAliases)?perm.name:perm.key))])}),0)]}}],null,false,3315414228)}),_c('div',{staticClass:"w-100 d-flex align-items-center vh-25",attrs:{"slot":"empty"},slot:"empty"},[_c('p',{staticClass:"h5 no-per"},[_vm._v(_vm._s(_vm.$t("No Groups Included")))])])],1)],1)]:_vm._e(),_c('base-button',{attrs:{"slot":"footer","type":"primary","size":"sm"},on:{"click":_vm.done},slot:"footer"},[_vm._v(_vm._s(_vm.$t("Done")))])],2)}
var staticRenderFns = []

export { render, staticRenderFns }