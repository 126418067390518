import { 
    GENERAL_INFO,
    VILLA_LIST, 
    ALARMS_COMPARISON, 
    POLICE_STATIONS_STATISTICS, 
    VERIFICATION_REASONS, 
    ALARMS_STATISTICS,
    ALARMS_TYPES_COUNTS, 
    MORO_STATISTICS
} from "./endpoints";
import api from "@/axiosWithDelimiterFile.js"
import { camelToSnakeCase, snakeToCamelCase } from "../../../util/helper";
var villaListCache = {
    promise: null,
    time: null
};
class IntrusionService{
    static getGeneralInfo(){
        return new Promise((resolve,reject)=>{
            api.get(GENERAL_INFO).then(res=>{
                resolve(convertObjectKeysToCamelCase(res.data.data))
            }).catch(err=>{
                reject(err);
            })
        });
    }

    static getVillaList(qp){
        const qpString = JSON.stringify(qp);
        if(!isValidCache(villaListCache, qpString)){
            villaListCache.time = new Date().getTime();
            villaListCache.qp = qpString;
            villaListCache.promise = new Promise((resolve,reject)=>{
                api.get(VILLA_LIST, {
                    params: convertObjectKeysToSnakeCase(qp)
                }).then(res=>{
                    resolve(res.data.data.map(convertObjectKeysToCamelCase))
                }).catch(err=>{
                    reject(err);
                })
            });
        }else{
            console.log("cache hit!")
        }
        return villaListCache.promise;
    }

    static getIntrusionAlarmsComparison(qp = {}){
        return new Promise((resolve,reject)=>{
            api.get(ALARMS_COMPARISON, {
                params: convertObjectKeysToSnakeCase(qp)
            })
                .then(res=>{
                    resolve(res.data.data);
                })
                .catch(reject);
        });
    }

    static getPoliceStationsStatistics(){
        return new Promise((resolve, reject)=>{
            api.get(POLICE_STATIONS_STATISTICS)
            .then(res =>{
                resolve(res.data.data.map(convertObjectKeysToCamelCase));
            })
            .catch(reject);
        });
    }
    
    static getVerificationReasons(qp){
        return new Promise((resolve, reject)=>{
            api.get(VERIFICATION_REASONS, {
                params: convertObjectKeysToSnakeCase(qp)
            })
            .then(res =>{
                resolve(res.data.data.verification_counts);
            })
            .catch(reject);
        });
    }
    static getAlarmsStatistics(qp = {}){
        return new Promise((resolve,reject)=>{
            api.get(ALARMS_STATISTICS, {
                params: convertObjectKeysToSnakeCase(qp)
            })
                .then(res=>{
                    resolve(res.data.data);
                })
                .catch(reject);
        });
    }

    static getAlarmsTypes(qp = {}){
        return new Promise((resolve,reject)=>{
            api.get(ALARMS_TYPES_COUNTS, {
                params: convertObjectKeysToSnakeCase(qp)
            })
                .then(res=>{
                    resolve(res.data.data.status_counts);
                })
                .catch(reject);
        });
    }
    static getMoroStatistics(qp = {}) {
        return api.get(MORO_STATISTICS,{
            params: qp
        }).then(({ data }) => {
            return data;
        })
    }
}

function isValidCache(cache, cacheQp){
    if(!cache || !cache.promise || !cache.time || villaListCache.qp != cacheQp) return false;
    const timeDiff = new Date().getTime() - cache.time;
    if(timeDiff < 10000){
        return true;
    }
    return false;
}

const convertObjectKeysToCamelCase = (ob)=>{
    const newObject = {};
    Object.keys(ob).forEach(key=>{
        newObject[snakeToCamelCase(key)] = ob[key];
    });
    return newObject;
}
const convertObjectKeysToSnakeCase = (ob)=>{
    const newObject = {};
    Object.keys(ob).forEach(key=>{
        newObject[camelToSnakeCase(key)] = ob[key];
    });
    return newObject;
}

export default IntrusionService;