<template>
  <div class="working-hours">
    <p class="title">Working Periods</p>
    <div class="row" v-for="day in workDays" :key="day.name" style="margin-top: 20px">
      <div class="col-2">
        <base-checkbox v-model="day.checked">{{day.name}}</base-checkbox>
      </div>
      <div class="col-3">
        <base-input>
          <el-time-select
            :disabled="!day.checked"
            placeholder="From"
            :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:59'
              }"
            v-model="day.from"
            :clearable="false"
          ></el-time-select>
        </base-input>
      </div>
      <div class="col-3">
        <base-input>
          <el-time-select
            :disabled="!day.checked"
            placeholder="To"
            :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:59',
                minTime: day.from
              }"
            v-model="day.to"
            :clearable="false"
          ></el-time-select>
        </base-input>
      </div>
      <div class="col-3">
        <base-input>
          <el-input-number
            :min="0"
            :max="12"
            :disabled="!day.checked"
            :controls="false"
            :value="day.to.split(':')[0] - day.from.split(':')[0]"
            placeholder="Hours"
            @change="calculateTo($event, day)"
          ></el-input-number>
        </base-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "working-periods",
  props: {
    workDaysData: {
      type: Array,
    },
  },
  data() {
    return {
      workDays: [
        {
          name: "saturday",
          from: "",
          to: "",
          checked: false,
        },
        {
          name: "sunday",
          from: "",
          to: "",
          checked: false,
        },
        {
          name: "monday",
          from: "",
          to: "",
          checked: false,
        },
        {
          name: "tuesday",
          from: "",
          to: "",
          checked: false,
        },
        {
          name: "thursday",
          from: "",
          to: "",
          checked: false,
        },
        {
          name: "friday",
          from: "",
          to: "",
          checked: false,
        },
      ],
    };
  },
  created() {
    (this.workDaysData || []).forEach((w, i) => {
      if (this.workDays[i].name == w.name) {
        this.workDays[i] = w;
        this.workDays[i].checked = true;
      }
    });
  },
  methods: {
    calculateTo(event, day) {
      day.to = +day.from.split(":")[0] + +event + ":00";
    },
    getData() {
      let length = this.workDays.length;
      for (let i = 0; i < length; ++i) {
        if (this.workDays[i].checked === true) {
          if (
            this.workDays[i].from === "" ||
            this.workDays[i].to === "" ||
            this.workDays[i].from === this.workDays[i].to
          )
            return null;
        }
      }
      return this.workDays;
    },
  },
  watch: {
    workDays: {
      handler() {
        this.$emit("change");
      },
      deep: true,
    },
  },
};
</script>


<style scoped lang="scss">
.working-hours {
  p.title {
    color: white;
    letter-spacing: 1.5px;
    font-size: 1rem;
    text-decoration: underline $primary;
    margin-bottom: 1rem;
  }
}
::v-deep .form-group .el-input__inner {
  font-size: 1rem;
  text-align: center;
}
</style>