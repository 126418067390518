<template>
  <card class="h-100">
    <div align="right" class="d-flex flex-row-reverse">
      <slot name="header-right">
        <base-input
          v-if="!globalMode"
          type="search"
          class="mb-3 search-input"
          clearable
          prefix-icon="el-icon-search"
          :placeholder="$t('search.search')"
          v-model="search"
          aria-controls="datatables"
        ></base-input>
      </slot>
      
    </div>
    <div class="striped-table">
      <el-table :data="tableDate" max-height="350px">
        <el-table-column :min-width="50" align="left" :label="$t('id')">
          <div slot-scope="props">
            <router-link :to="`/SWAM/Structure-Portfolio/${props.row.id}`" class="linkable">{{props.row.id}}</router-link>
          </div>
        </el-table-column>
        <el-table-column :min-width="75" align="left" :label="$t('name')">
          <div slot-scope="props">
            <router-link
              :to="`/SWAM/Structure-Portfolio/${props.row.id}`"
              class="linkable"
            >{{props.row.structure_name}}</router-link>
          </div>
        </el-table-column>
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          :min-width="column.minWidth"
          :prop="column.prop"
          :label="$t(column.label)"
        ></el-table-column>

        <el-table-column :min-width="150" align="left" :label="$t('solutionWords.solutionDevices')">
          <div slot-scope="props">
            <ul>
              <li v-for="(solution, index) in props.row.solutions" :key="index">
                <router-link
                  :to="getUrl(props.row.id,solution)"
                  class="clickable"
                >
                   <div class="row" style="margin-bottom:4px">
                    <div class="col-8">{{ $t(solution.name) }}</div>
                    <div class="col-4" style="text-align:center">{{solution.devices}}</div>
                  </div>
                </router-link>   
              </li>
            </ul>
          </div>
        </el-table-column>
      </el-table>
    </div>
  </card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    globalMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      structures: "SwamStore/haveDevices"
    }),
    tableDate() {
      let res = this.structures.filter(
        data =>
          !this.search ||
          data.structure_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          data.structure_number
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          data.structure_type
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          data.id.toString().includes(this.search)
      );

      this.structures.map(s => (s.structure_type = this.$t(s.structure_type)));
      return res;
    }
  },
  data() {
    return {
      search: "",
      tableColumns: [
        {
          prop: "community_name",
          label: "communityName",
          minWidth: 75
        },
        {
          prop: "structure_number",
          label: "structureWords.number",
          minWidth: 50
        },
        {
          prop: "structure_type",
          label: "structureWords.structureType",
          minWidth: 75
        }
      ]
    };
  },
  created() {
    console.log("data =>", this.structures);
  },
  methods: {
    getUrl(structureId,solution) {
      return "/SWAM/Solution-Portfolio?structure=" + structureId + "&solution=" + solution.id;
    }
  }
};
</script>

<style scoped>
.my-link {
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 1px;
}
</style>
