<template>
  <div>
    <el-table
      :data="zones"
      height="50vh"
      style="width: 100%"
      ref="multipleTable"
      @selection-change="handleSelectionChange"
    >
      <el-table-column :label="$t('Number')" prop="zone"></el-table-column>
      <el-table-column :label="$t('Name')" prop="name"></el-table-column>
      <el-table-column type="selection" width="55"></el-table-column>
    </el-table>
    <div class="row justify-content-center">
      <base-button type="info" @click="submitZones">Submit</base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "zones-selector",
  props: {
    zones: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedZones: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  created() {
    this.calcSelectedZones();
  },
  methods: {
    submitZones() {
      this.$emit("submit", this.selectedZones);
    },
    calcSelectedZones() {
      console.log(
        "****************************calcSelectedZones****************************"
      );
      const startWith = this.zones.filter((z) => {
        return this.selectedZones.some((zz) => {
          if (zz == z.zone) {
            return true;
          }
        });
      });

      this.$nextTick().then((_) => {
        if (startWith && startWith.length != 0) {
          startWith.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      });
    },
    handleSelectionChange(val) {
      while (this.selectedZones.length != 0) {
        this.selectedZones.pop();
      }
      val.forEach((v) => this.selectedZones.push(v));
    },
  },
  watch: {
    zones: {
      handler() {
        this.calcSelectedZones();
      },
    },
  },
};
</script>

<style>
</style>