import ScooterStore from '../pages/scooter/store'
import Scooters from '../pages/scooters/store'
export default {
    namespaced: true,

    modules: {
      ScooterStore,
      Scooters
    },

    state: {},

    getters: {},

    mutations: {},

    actions: {}
}