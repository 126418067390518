<template>
  <card v-loading="loading" class="full-width">
    <!-- Title -->
    <template slot="header">
      <div class="d-flex justify-content-between">
        <h3>{{ $t("widgets.structureComparison") }}</h3>
        <slot name="header-right">
          <base-select
            v-if="!hidePeriodSelector"
            :light="true"
            :options="periodsOptions"
            v-model="temporarilySelectedPeriod"
          />
        </slot>
      </div>
    </template>

    <!-- Filters -->
    <div class="row">
      <div v-if="!hideStructureSelector" class="col-lg-3 col-md-4">
        <base-select
          :inline="true"
          :label="$t('Structure') + ': '"
          :options="structureOptions"
          v-model="currentStructureId"
        />
      </div>
      <div v-if="!hideSolutionSelector" class="col-lg-3 col-md-4">
        <base-select
          :inline="true"
          :label="$tc('solutionWords.solution', 1) + ':' "
          :options="solutionOptions"
          v-model="currentSolutionId"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <base-select
          :inline="true"
          :label="$tc('sensorWords.sensor', 1) + ':' "
          :options="sensorOptions"
          v-model="currentSensorId"
        />
      </div>
    </div>
    <!-- Comparison View -->
      <comparison-view
        :performanceData="performanceData"
        :gaugeChartData="gaugeChartData"
        :sensorId="currentSensorId"
        :firstSide="firstPeriodMeasurements"
        :secondSide="secondPeriodMeasurements"
        :dates="measurementDates"
        @chartTypeChange="sendValidData()"
        :chartType="selectedChartType"
        ref="comp-view"
      />
    <el-dialog
      :visible.sync="CustomePeriodDialog"
      :before-close="dialogClose"
      :modal="false"
    >
      <CustomPeriodCreator :type="true" @submit="customPeriodCreated" />
    </el-dialog>
  </card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Dialog } from "element-ui";
import comparisonView from "@/pages/SWAM/components/comparison-view";
import CustomPeriodCreator from "@/pages/SWAM/components/CustomPeriod-Creator";
import { getDeep } from "@/util/helper";
export default {
  components: {
    [Dialog.name]: Dialog,
    comparisonView,
    CustomPeriodCreator,
  },
  data() {
    return {
      CustomePeriodDialog: false,
      temporarilySelectedPeriod: 0,
      selectedChartType: 'area',
    };
  },
  props: {
    namespace: {
      type: String,
      default: "SwamStore/StructurePortfolio"
      // required:true
    },
    data: {
      type: Object,
      default: ()=> ({})
    },
    hideStructureSelector: {
      type: Boolean,
      default: false,
    },
    hideSolutionSelector: {
      type: Boolean,
      default: false,
    },
    hidePeriodSelector: {
      type: Boolean,
      default: false,
    },
    disableDataFetch: {
      type: Boolean,
      default: false,
    },
    moduleName: {
      type: String,
      default: 'StructureComparison',
    },
  },
  computed: {
    storePath() {
      return this.namespace + '/' + this.moduleName;
    },
    ...mapGetters("SwamStore", {
      structures: "haveDevices",
      structureOptions: "structuresOptions",
      allSolutionsOptions: "solutionsOptions",
      allSolutions: "solutions",
      CustomSensors: "CustomSensors",
    }),
    overallSolutions() {
      return this.allSolutionsOptions.filter((s) => s.value);
    },
    ...mapState({
      loading(state, getters) {
        return getters[this.storePath + "/loading"];
      },
      periodsOptions(state, getters) {
        return getters[this.storePath + "/periodsOptions"];
      },
      getAppliedTheme(state, getters) {
        return getters.isDarkMode;
      },
      performanceData(state, getters) {
        return getters[this.storePath + "/performanceData"];
      },
      gaugeChartData(state, getters) {
        return getters[this.storePath + "/gaugeChartData"];
      },
      firstPeriodMeasurements(state, getters) {
        return getters[
          this.storePath + "/firstPeriodMeasurements"
        ];
      },
      secondPeriodMeasurements(state, getters) {
        return getters[
          this.storePath + "/secondPeriodMeasurements"
        ];
      },
      measurementDates(state, getters) {
        return [
          getters[
            this.storePath + "/firstPeriodMeasurementsDates"
          ],
          getters[
            this.storePath + "/secondPeriodMeasurementsDates"
          ],
        ];
      },
    }),
    currentStructureId: {
      get() {
        return getDeep(
          this.$store.state,
          this.storePath + "/currentStructureId"
        );
      },
      set(value) {
        this.$store.commit(
          this.storePath + "/selectStructure",
          value
        );
      },
    },
    currentSolutionId: {
      get() {
        return getDeep(
          this.$store.state,
          this.storePath + "/currentSolutionId"
        );
      },
      set(value) {
        this.$store.commit(
          this.storePath + "/selectSoluiton",
          value
        );
      },
    },
    currentSensorId: {
      get() {
        return getDeep(
          this.$store.state,
          this.storePath + "/currentSensorId"
        );
      },
      set(value) {
        this.$store.commit(
          this.storePath + "/selectSensor",
          value
        );
      },
    },
    currentPeriodIndex: {
      get() {
        return getDeep(
          this.$store.state,
          this.storePath + "/currentPeriodIndex"
        );
      },
      set(value) {
        this.$store.commit(
          this.storePath + "/selectPeriod",
          value
        );
      },
    },
    solutionOptions() {
      let result = [];
      if (!this.currentStructureId) {
        result = this.overallSolutions;
      } else {
        result = this.overallSolutions.filter((sol) =>
          sol.structuresIds.some((id) => id == this.currentStructureId)
        );
      }
      return result;
    },
    sensorOptions() {
      let data = [];
      if (!this.currentSolutionId) return data;
      else if (!this.currentStructureId) {
        data = this.allSolutions[`${this.currentSolutionId}`].sensors;
      } else {
        data = this.CustomSensors(
          this.currentStructureId,
          this.currentSolutionId
        );
      }
      data = data.map((s) => {
        return {
          label: s.name,
          value: s.id,
        };
      });
      return data;
    },
  },
  methods: {
    ...mapActions({
      fetchData(dispatch, payload) {
        return dispatch(
          this.storePath + "/fetchData",
          payload
        );
      },
    }),
    ...mapMutations({
      updateFilter(commit, payload) {
        return commit(
          this.storePath + "/updateFilter",
          payload
        );
      },
      selectCustomPeriod(commit, payload) {
        return commit(
          this.storePath + "/selectCustomPeriod",
          payload
        );
      },
      selectSoluiton(commit, payload) {
        return commit(
          this.storePath + "/selectSoluiton",
          payload
        );
      },
      selectSensor(commit, payload) {
        return commit(
          this.storePath + "/selectSensor",
          payload
        );
      },
    }),
    customPeriodCreated(data) {
      this.selectCustomPeriod(data);
      this.CustomePeriodDialog = false;
      if (!data[0].from()) {
        this.temporarilySelectedPeriod = this.currentPeriodIndex;
      } else {
        this.currentPeriodIndex = this.temporarilySelectedPeriod;
      }
      this.CustomePeriodDialog = false;
    },
    dialogClose() {
      this.temporarilySelectedPeriod = this.currentPeriodIndex;
      this.CustomePeriodDialog = false;
    },
    updateData() {
      if(!this.disableDataFetch) {
        this.fetchData();
      }
      this.sendValidData();
    },
    sendValidData() {
      if(this.currentSolutionId && this.currentSensorId) {
        this.$emit('valid',{
          ...this.extractConfig()
        });
      }
    },
    extractConfig() {
      if(this.currentSolutionId && this.currentSensorId) {
          return {
          ...this.$refs['comp-view'].extractConfig(),
          structure_id:this.currentStructureId,
          solution_id: this.currentSolutionId,
          sensor_id: this.currentSensorId,
        }
      }
    }
  },
  mounted() {
    if (this.data.structure_id) {
      this.currentStructureId = +this.data.structure_id;
    }
    if (this.data.solution_id) {
      this.currentSolutionId = this.data.solution_id;
    } else {
      this.currentSolutionId = this.solutionOptions[0].value;
    }
    if (this.data.sensor_id) {
      this.currentSensorId = +this.data.sensor_id;
    }
    if(this.data.chart_type) {
      this.selectedChartType = this.data.chart_type;
    }
    if(this.data.period) {
      let p = this.data.period;
      switch(p) {
        case 'week':
          this.currentPeriodIndex = 1;
          break;
        case 'month':
          this.currentPeriodIndex = 2;
          break;
        case 'quarter': 
          this.currentPeriodIndex = 3;
          break;
        case 'year': 
          this.currentPeriodIndex = 4;
          break;
      }
    }
  },
  watch: {
    disableDataFetch() {
      this.updateData();
    },
    currentSensorId() {
      this.updateData();
    },
    currentPeriodIndex() {
      this.updateData();
    },
    currentSolutionId() {
      let oldSensor = this.currentSensorId;
      let newSensor = this.sensorOptions[0].value;
      if(this.data.sensor_id && this.sensorOptions.find(s => s.value ==this.data.sensor_id)) {
        newSensor = this.data.sensor_id;
      }
      this.currentSensorId = newSensor;
      if(oldSensor == newSensor) {
        this.updateData();
      }
    },
    currentStructureId() {
      let oldSolution = this.currentSolutionId;
      let newSolution = this.solutionOptions[0].value;
      this.currentSolutionId = newSolution;
      if(oldSolution == newSolution) {
        this.updateData();
      }
    },
    data: {
      handler() {
        if (this.data.structure_id) {
          this.currentStructureId = +this.data.structure_id;
        }
        if (this.data.solution_id) {
          this.currentSolutionId = +this.data.solution_id;
        }
        if (this.data.sensor_id) {
          this.currentSensorId = +this.data.sensor_id;
        }
        if(this.data.period) {
          let p = this.data.period;
          switch(p) {
            case 'week':
              this.currentPeriodIndex = 1;
              break;
            case 'month':
              this.currentPeriodIndex = 2;
              break;
            case 'quarter': 
              this.currentPeriodIndex = 3;
              break;
            case 'year': 
              this.currentPeriodIndex = 4;
              break;
          }
        }
      },
      deep: true,
    },
    temporarilySelectedPeriod() {
      if (this.temporarilySelectedPeriod != 5) {
        this.currentPeriodIndex = this.temporarilySelectedPeriod;
      } else {
        this.CustomePeriodDialog = true;
      }
    },
  },
};
</script>

<style>
</style>