import StructureComparison from "../../core/Structure-Comparison/store";
import DeviceComparison from "../../core/Device-Comparison/store";
import StructuresStatistics from "../../core/Structures-Statistics/store";
import Statistics from "../../core/Statistics/store";
import SolutionStatistics1 from "../../core/Solution-Statistics/store";
import SolutionStatistics2 from "../../core/Solution-Statistics/store";
import AlarmsCount from '../../core/Alarms-Count/store';
import StructuresLocations from '../../core/StructuresLocations/store';
export default {
    namespaced:true,
    modules:{
        StructureComparison,
        DeviceComparison,
        StructuresStatistics,
        Statistics,
        SolutionStatistics1,
        SolutionStatistics2,
        AlarmsCount,
        StructuresLocations
    },
    state:{
        structureId: undefined,
    },
    getters:{
        structureId(state) {
            return state.structureId;
        }
    },
    mutations:{
        setStructureId(state,value) {
            state.structureId = value;
        }
    },
    actions:{
        reset({state,commit,dispatch}) {
            state.solutions = {};
            dispatch('StructureComparison/reset');
            dispatch('DeviceComparison/reset');
            dispatch('StructuresStatistics/reset');
            dispatch('SolutionStatistics1/reset');
            dispatch('SolutionStatistics2/reset');
            dispatch('Statistics/HighestProblems/reset');
            dispatch('Statistics/Administrative/reset');
            dispatch('Statistics/StatisticsSolutions/reset');
            commit('setStructureId',undefined);
        }
    },
}