import moment from "moment";

export const twoPeriods = [
  {
    label: "today - yesterday",
    value: 0,
    periods: [
      {
        from: () => moment().startOf("day"),
        to: () => moment().endOf("day"),
        title: "today",
        type: "24",
      },
      {
        from: () => moment().subtract(1, "day").startOf("day"),
        to: () => moment().subtract(1, "day").endOf("day"),
        title: "yesterday",
        type: "24",
      },
    ],
  },
  {
    label: "this week - last week",
    value: 1,
    periods: [
      {
        from: () => moment().startOf("week"),
        to: () => moment().endOf("week"),
        title: "this week",
        type: "7",
      },
      {
        from: () => moment().subtract(1, "week").startOf("week"),
        to: () => moment().subtract(1, "week").endOf("week"),
        title: "last week",
        type: "7",
      },
    ],
  },
  {
    label: "this month - last month",
    value: 2,
    periods: [
      {
        from: () => moment().startOf("month"),
        to: () => moment().endOf("month"),
        title: "this month",
        type: "31",
      },
      {
        from: () => moment().subtract(1, "month").startOf("month"),
        to: () => moment().subtract(1, "month").endOf("month"),
        title: "last month",
        type: "31",
      },
    ],
  },
  {
    label: "this quarter - last quarter",
    value: 3,
    periods: [
      {
        from: () => moment().startOf("quarters"),
        to: () => moment().endOf("quarters"),
        title: "this quarter",
        type: "90",
      },
      {
        from: () => moment().subtract(1, "quarters").startOf("quarters"),
        to: () => moment().subtract(1, "quarters").endOf("quarters"),
        title: "last quarter",
        type: "90",
      },
    ],
  },
  {
    label: "this year - last year",
    value: 4,
    periods: [
      {
        from: () => moment().startOf("year"),
        to: () => moment().endOf("year"),
        title: "this year",
        type: "360",
      },
      {
        from: () => moment().subtract(1, "year").startOf("year"),
        to: () => moment().subtract(1, "year").endOf("year"),
        title: "last year",
        type: "360",
      },
    ],
  },
  {
    label: "custom period",
    value: 5,
    periods: [],
  },
]
export const onePeriod = [
  {
    value: 0,
    label: "last24Hours",
    from: () => moment().subtract(24, 'hours'),
    to: () => moment(),
    type: 24
  },
  {
    value: 1,
    label: "last7Days",
    from: () => moment().subtract(7, 'days'),
    to: () => moment(),
    type: 7
  },
  {
    value: 2,
    label: "thisWeek",
    from: () => moment().startOf("week"),
    to: () => moment().endOf("week"),
    type: 7
  },
  {
    value: 3,
    label: "lastWeek",
    from: () => moment().subtract(1, "week").startOf("week"),
    to: () => moment().subtract(1, "week").endOf("week"),
    type: 7
  },
  {
    value: 4,
    label: "thisMonth",
    from: () => moment().startOf("month"),
    to: () => moment().endOf("month"),
    type: 31
  },
  {
    value: 5,
    label: "lastMonth",
    from: () => moment().subtract(1, "month").startOf("month"),
    to: () => moment().subtract(1, "month").endOf("month"),
    type: 31
  },
  {
    value: 6,
    label: "thisQuarter",
    from: () => moment().startOf("quarter"),
    to: () => moment().endOf("quarter"),
    type: 92
  },
  {
    value: 7,
    label: "thisYear",
    from: () => moment().startOf("year"),
    to: () => moment().endOf("year"),
    type: 12
  },
]
export const onePeriodInOneDay = [
  {
    value: 0,
    label: "Last hour",
    from: () => moment().subtract(1, 'hours'),
    to: () => moment(),
    type: 24
  },
  {
    value: 1,
    label: "Last 2 hours",
    from: () => moment().subtract(2, 'hours'),
    to: () => moment(),
    type: 24
  },
  {
    value: 2,
    label: "Last 3 hours",
    from: () => moment().subtract(3, 'hours'),
    to: () => moment(),
    type: 24
  },
  {
    value: 3,
    label: "Last 5 hours",
    from: () => moment().subtract(5, 'hours'),
    to: () => moment(),
    type: 24
  },
  {
    value: 4,
    label: "Last 12 hours",
    from: () => moment().subtract(12, 'hours'),
    to: () => moment(),
    type: 24
  },
  {
    value: 5,
    label: "Last 24 hours",
    from: () => moment().subtract(24, 'hours'),
    to: () => moment(),
    type: 24
  },
]
export const onePeriodInTwoDays = [
  {
    value: 0,
    label: "Last hour",
    from: () => moment().subtract(1, 'hours'),
    to: () => moment(),
    type: 24
  },
  {
    value: 1,
    label: "Last 2 hours",
    from: () => moment().subtract(2, 'hours'),
    to: () => moment(),
    type: 24
  },
  {
    value: 2,
    label: "Last 3 hours",
    from: () => moment().subtract(3, 'hours'),
    to: () => moment(),
    type: 24
  },
  {
    value: 3,
    label: "Last 5 hours",
    from: () => moment().subtract(5, 'hours'),
    to: () => moment(),
    type: 24
  },
  {
    value: 4,
    label: "Last 12 hours",
    from: () => moment().subtract(12, 'hours'),
    to: () => moment(),
    type: 24
  },
  {
    value: 5,
    label: "Last 24 hours",
    from: () => moment().subtract(24, 'hours'),
    to: () => moment(),
    type: 24
  },
  {
    value: 6,
    label: "Last 48 hours",
    from: () => moment().subtract(48, 'hours'),
    to: () => moment(),
    type: 24
  },
  {
    value: 7,
    from: () => moment().subtract(1, "week"),
    to: () => moment(),
    label: "last week",
    type: 24,
  },
]
export default {
  onePeriod,
  twoPeriods,
  onePeriodInOneDay,
  onePeriodInTwoDays
}