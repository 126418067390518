

import api from 'src/axiosWithDelimiterFile.js';
import moment from 'moment';


const worker = new Worker('./worker.js', { type: 'module' });


export default {
  namespaced: true,
  state: {
    duration: 1,
    datefrom: "",
    dateto: "",
    selected_device: null,
    values: {
      dates: []
    },
    sortedValues:{

    }


  },
  getters: {
    getSelectedDevice(state) {
      return state.selected_device;
    },
    getDeviceReadings(state) {
      return state.values;
    },
  },
  mutations: {

    setDuration(state, { name, value }) {
      state[name] = value;
    },
    setValues(state, values) {
      state.values = values;
      state.sortedValues = {
        dates: values.dates.map(x=>x).reverse()
      };
      Object.keys(values).forEach(sensorID => {
        if(sensorID == "dates"){
          return;
        }
        state.sortedValues[sensorID] = {
          values: values[sensorID].values.map(x=>x).reverse(),
          meta: values[sensorID].meta,
          statuses: values[sensorID].statuses.map(x=>x).reverse()
        }
      });

    },
    setSelectedDevice(state, device) {
      state.selected_device = device;
    },
  },
  actions: {

    destroyDevice({ state }) {
      state.selected_device = null;
      state.values = { dates: [] };
      state.duration = 1;
    },

    setSelectedDevice({ state, dispatch, commit }, id) {

      this.commit('setBlockSpinner', true);
      const devicePromiss = api.get(`iot/devices/deviceinfo/${id}`);
      console.log(devicePromiss)
      dispatch('feed', { _id: id, devicePromiss });

      devicePromiss.then(res => {
        console.log(res.data.data)
        commit("setSelectedDevice", res.data.data)
      }).finally(() => {
        this.commit('setBlockSpinner', false);
      });

    },

    feed({ state, commit }, extra) {
      let id = (extra && extra._id) || state.selected_device.id;
      let datefrom = state.datefrom;
      let dateto = state.dateto;
      let duration = state.duration;

      let data;
      if (datefrom && dateto) {
        data = {
          fromDate: datefrom.toISOString(),
          toDate: dateto.toISOString()
        };
      } else {
        let startDate = new Date();
        startDate.setDate(startDate.getDate() - duration);
        data = {
          start: startDate.toISOString(),
          end: new Date().toISOString()
        };
      }

      //worker.postMessage({name:'feed',id,data})
      // const x = new Promise();


      this.commit('setBlockSpinner', true);
      api.get(`iot/devices/reading/${id}`, {
        params: {
          ...data,
          // limit: -1,
          // offset: -1
        }
      }).then(_ => {
        if (extra && extra.devicePromiss) {
          return extra.devicePromiss.then(__ => _);
        }
        return _;
      })
        .then(res => {
          commit("setValues", Object.freeze(res.data.readings));
        })
        .catch(err => {
          console.error(err);
        }).finally(_ => {
          this.commit('setBlockSpinner', false);
        });






    },
    downloadPDF({ state }, charts) {
      let id = state.selected_device.id;
      let datefrom = state.datefrom;
      let dateto = state.dateto;
      let duration = state.duration;
      let data;
      if (datefrom && dateto) {
        data = {

          fromDate: datefrom.toISOString(),
          toDate: dateto.toISOString()
        };
      } else {
        let startDate = new Date();
        startDate.setDate(startDate.getDate() - duration);
        data = {
          fromDate: startDate.toISOString(),
          toDate: new Date().toISOString()
        };
      }

      data.charts = charts;
      this.dispatch("downloadFile", {
        filename: `${state.selected_device.device_name}-${state.selected_device.structure}-${new Date().toLocaleDateString()}.pdf`,
        url: `iot/devices/reading/${id}/-1/-1/pdf`,
        method: 'post',
        data
      })
      // api.post(`iot/devices/reading/${id}/-1/-1/pdf`, data)
      //   .then(res => {
      //     if (res.status == 200) {

      //       let a = document.createElement("a");
      //       a.style = "display: none";
      //       document.body.appendChild(a);
      //       a.href = res.data;
      //       a.download = `${state.selected_device.device_name}-${state.selected_device.structure}-${new Date().toLocaleDateString()}.pdf`;
      //       a.click();
      //     }

      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });


    }
  },
  modules : {}
}
function getDataPromiss() {

}
