
let  errors = {
    required: 'is required',
    email: 'must be valid email',
    notEmpty:' must to be not empty'

}

export default (fieldName,validation)=>{

    

    let keys = Object.keys(errors);
    let msg;
    if(validation.$anyDirty){
        for(var i=0;i<keys.length;i++){
            let key = keys[i];
            if(validation[key] === false){
                msg = `${fieldName} ${errors[key]}`;
                break;
            }
    
        }
    }
    return msg;
    

};