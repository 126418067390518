<template>
  <div>
    <LocationsMap
      :markers="markers"
      :center="center"
      :zoom="structureId ? 14 : 6"
      :markerIcon="markerIcon"
      :mapType="mapType"
      @onMarkerClicked="onStructureClicked"
      :markerLabel="markerLabel"
    >
      <div slot="footer">
        <div class="d-flex justify-content-between algin-items-center">
          <div slot="left-footer" class="pl-2 d-flex align-items-center">
            <img class="marker-image" :src="markerIcon" alt="" />
            <span class="font-weight-bold pl-2 font-color-1 h4 mb-0"> {{ structureName }} </span>
          </div>

          <div slot="right-footer" class="overflow-hidden rounded">
            <base-button
              :round="false"
              size="sm"
              :type="'primary'"
              :simple="mapType != 1"
              class="py-1"
              @click="changeMap(1)"
              >{{ $t("deviceWords.devicesMap") }}</base-button
            >
            <base-button
              :round="false"
              size="sm"
              :type="'primary'"
              :simple="mapType != 2"
              class="py-1"
              @click="changeMap(2)"
              >{{ $t("deviceWords.alarmsMap") }}</base-button
            >
          </div>
        </div>
      </div>
    </LocationsMap>
    <LocationInfoStructure
      v-if="singleStructureCardData"
      :data="singleStructureCardData"
      :openDialog="openDialog"
      @closeModel="closeModel"
    />
  </div>
</template>

<script>
import LocationsMap from "src/components/Locations/locations.vue";
import LocationInfoStructure from "@/pages/SWAM/components/LocationInfoStructure";
import { mapGetters, mapState } from "vuex";
import { getDeep } from "@/util/helper";

export default {
  name: "Locations",
  components: { 
    LocationsMap,
    LocationInfoStructure 
  },
  props: {
    namespace: {
      type: String,
      default: "SwamStore/StructurePortfolio"
    },
    moduleName: {
      type: String,
      default: 'StructuresLocations',
    },
  },
  data() {
    return {
      openDialog: false,
      customClassDialg: `margin:0; background-color:red`,
    };
  },
  created() {
    if (this.structures.length == 0) {
      this.$store.dispatch("SwamStore/FETCH_ITEMS", {
        key: "all",
      });
    }
  },
  computed: {
    storePath() {
      return this.namespace + '/' + this.moduleName;
    },
    markerIcon() {
      return this.mapType == 1 ?
        require("src/assets/icons-2/google-map-marker.svg") :
        require("src/assets/icons-2/google-map-marker-red.svg");
    },
    structureName() {
      if(!this.structureId) {
        return ('Total Structures: ' + this.structures.length);
      }
      return this.structures.find(s => s.id == this.structureId).structure_name;
    },
    ...mapState({
      structureId(state,getters) {
        return getters[this.namespace+"/structureId"];
      },
      singleStructureCardData(state,getters) {
        return getters[this.storePath + "/singleStructureCardData"];
      }
    }),
    ...mapGetters({
      structures: "SwamStore/haveDevices",
    }),
    markers() {
      if(!this.structureId) {
        return this.structures;
      }
      return this.structures.filter(s => s.id == +this.structureId);
    },
    center: {
      get() {
        return getDeep(this.$store.state,this.storePath + "/center");
      },
      set(value) {
        this.$store.commit(this.storePath + "/setCenter",value);
      }
    },
    mapType: {
      get() {
        return getDeep(this.$store.state,this.storePath + "/mapType");
      },
      set(value) {
        this.$store.commit(this.storePath + "/setMapType",value);
      }
    },
    selectedStructure: {
      get() {
        return getDeep(this.$store.state,this.storePath + "/selectedStructure");
      },
      set(value) {
        this.$store.commit(this.storePath + "/setStructure",value);
      }
    }
  },
  methods: {
    closeModel() {
      this.openDialog = false;
    },
    markerLabel(marker) {
      return (
        (this.mapType == 1 ? marker.device_count : marker.alarms_count) + ""
      );
    },
    onStructureClicked(pos) {
      this.center = pos;
      this.openDialog = true;
      this.selectedStructure = pos;
    },
    changeMap(type) {
      this.mapType = type;
    },
  },
  watch: {
    structureId() {
      if(!this.structureId) {
        this.center = { lat: 25.1525217, lng: 55.29445630000001 };
      } else {
        let structure = this.structures.find(s => s.id == this.structureId);
        this.center = { lat: structure.lat, lng: structure.lng};
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// $color:#389466;
.card {
  height: 318px;
  .marker-image {
    width: 15px;
  }
  button {
    margin: 0;
    border-radius: 0;
    border: 3px solid $primary !important;
  }
  .btn-primary:active,
  .btn-primary:visited {
    background: $primary !important;
  }
  .btn-primary:hover {
    border-color: $primary !important;
    background: $primary !important;
    color: white;
    transform: translateY(0px);
  }
  .btn-primary:focus {
    background: $primary !important;
    color: white;
    transform: translateY(0px);
  }

  .location-info-dialog {
    margin: 0;
    background-color: red;
  }
}
</style>
