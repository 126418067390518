import api from "src/axiosWithDelimiterFile";
import {resolvePromiseForObjectOrNull} from "../../modules/common/wrappers/promise-resolver";

export const API_CONFIG = {
  PREFERENCES_URL: `User/parameter`
};

export class UserPreferencesService {

  /**
   *
   * @param {string} key
   * @param {any} value
   */
  setItem(key, value) {
    return api.post(
      API_CONFIG.PREFERENCES_URL,
      {value: JSON.stringify(value)},
      {params: {key}}
    );
  }

  /**
   *
   * @param {string} key
   */
  getItem(key) {
    const promise = api.get(API_CONFIG.PREFERENCES_URL, {params: {key}});
    return resolvePromiseForObjectOrNull(promise);
  }


  /**
   *
   * @param {string} key
   */
  deleteItem(key) {
    return api.delete(API_CONFIG.PREFERENCES_URL, {params: {key}});
  }

  /**
   * @param {Object} data
   * @type array
   * @desc set new sensor color after we modified action pai
   * @author karam mustafa
   * @return promise
   **/
  async changeSensorColor(data) {
    let {sensorId , color} = data;
    return await api.post(`/iot/sensors/colors/` + sensorId, {color: color})
      .then(res => {
        return res
      })
      .catch((err) => console.log(err));
  }
  /**
   * @param {Object} data
   * @type array
   * @desc reset sensor color to default color
   * @author karam mustafa
   * @return promise
   **/
  async resetToDefault(data) {
    let {sensorId} = data;
    return await api.delete(`/iot/sensors/colors/` + sensorId)
      .then(res => {
        return res
      })
      .catch((err) => console.log(err));
  }
}
