<template>
  <card class="my-container">
    <h1>Company Users </h1>
    <div  align="right">
    <base-input
        type="search"
        class="mb-3 search-input"
        clearable
        prefix-icon="el-icon-search"
        :placeholder="$t('Search')"
        v-model="search"
        aria-controls="datatables">
    </base-input>
    </div>
    <el-table
      :data="users.filter(data => !search || data.email.toLowerCase().includes(search.toLowerCase())||
      data.type.toLowerCase().includes(search.toLowerCase())||
      data.status.toLowerCase().includes(search.toLowerCase())||
      data.id.toString().includes(search))"
      style="width: 100%">
      <el-table-column
        label="ID"
        prop="id"
        sortable>
        <template slot-scope="scope">
          <p @click="viewUserProfile(scope.row.id)" class="mo-link linkable">{{ $generateID(scope.row.id, 'MO') }}</p>
        </template>
      </el-table-column>
      <el-table-column
        label="Email"
        prop="email"
        sortable>
      </el-table-column>

      <el-table-column
        label="Status"
        prop="status"
        sortable>
      </el-table-column>
      <el-table-column
        label="Type"
        prop="type"
        sortable>
      </el-table-column>
    </el-table>
  </card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      search: ''
    }
  },
  methods: {
    viewUserProfile(id) {
      this.$router.push({
        path: `/profiles/user/${id}`
      });
    },
  },
  computed: {
    ...mapGetters({
      company: "CompanyData"
    }),
    users() {
      return this.company.users;
    }
  }
}
</script>

<style scoped>
.my-container {
  margin-top: 50px;
}
</style>
