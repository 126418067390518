<template>
  <div>
    <div class="d-flex justify-content-end">
      <ChartTypeSelector
        class="mb-0"
        :requireZoom="requireZoom"
        :chartType="chartType"
        @changeChart="changeChart"
      />
    </div>
    <zingchart
      :data="chartConfig"
      :height="height"
      width="100%"
      class="of-visible"
      dir="ltr"
    ></zingchart>
  </div>
</template>

<script>
let colors = [
  '#4A63BF',
  '#694B53',
  '#BD4A24',
  '#339625',
  '#41b581',
  '#ff8373',
  '#ffda83',
  '#f5a520',
  '#e43b58',
  '#91debe',
  '#6f6f95',
  '#9fe175',
  '#55dce0',
  '#3566ce',
];

import ChartTypeSelector from "./zingchart-type-selector";
import moment from 'moment'

import {
  areaChartConfigurations,
  lineChartConfigurations,
  barChartConfigurations
} from "../../handlers/charts/charts-configurations.bundle";
import { getChartConfigurationsWithData } from '../../handlers/charts/chart-generator';
export default {
  name: "ZingChartWrapper",
  components: {
    ChartTypeSelector
  },
  props: {
    requireZoom: {
      type: Boolean,
      default: false
    },
    series: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    },
    xLabel: {
      type: String,
      default: ''
    },
    yLabel: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: "300px"
    },
    customConfiguration: {
      type: Object,
      default: () => {}
    },
    chartType: {
      type: String,
      required: false,
      default: 'area'
    },
    showRanges: {
      type: Boolean
    }
  },
  data() {
    return {
      selectedChartType: this.chartType,
      chartConfig: {}
    }
  },
  mounted() {
      this.chartConfig = this.getChartConfigurationBasedOnType
  },
  computed: {
    isDarkMode() {
      return this.$store.getters.themMode == "light" ? false : true;
    },
    getSeries() {
      return this.series.map((item, index) => {
        const color = item.color ? item.color : colors[index]
        let from ;
        let to;
        if(this.showRanges){
          const lastLabel = this.labels[index].labels[this.labels[index].labels.length - 1];
          if(lastLabel== null || lastLabel.length == 0){
            to = this.labels[index].labels[this.labels[index].labels.length - 2];
          }else{
            to = lastLabel;
          }
          to = this.gDateFormat(to);
          from = this.gDateFormat(this.labels[index].labels[0]);
        }

        return {
          values: item.values,
          text: this.showRanges ?
            `${item.label} <span style="font-size: 10px">(${from} -> ${to})</span>` :
            item.label,
          lineColor: color,
          backgroundColor: this.selectedChartType == 'area' ? `${color} none` : color,
          aspect: "spline",
          alphaArea: ".4",
          marker: {
            backgroundColor: color,
            size: 7,
            borderColor: "#fff",
            borderWidth: 3
          },
          legendMarker: {
            backgroundColor: color
          }
        }
      })
    },
    legend() {
      return {
        legend: {
          layout: "x10",
          alpha: 0.05,
          shadow: false,
          y: "90%",
          x: -10,
          backgroundColor: 'none',
          marker: {
          type: "circle",
            borderColor: "none",
            size: "4px"
          },
          item: {
            fontSize: "15px",
            fontColor: this.getPrimaryColor
          },
          borderWidth: 0
        }
      }
    },
    scales() {
      let max = this.series.length ?
        Math.max(...this.series.map(item => {
          return item.values && item.values.length ? Math.max(...item.values) : 0
        })) : 0

      let labels1 = this.labels.length ? [...this.labels[0].labels] : []
      let labels2 = this.labels.length > 1 ? [...this.labels[1].labels] : []

      let isSameDates = this.isSameDates(labels1, labels2)

      const transformObject = this.xAxisTransformObjectHandler(labels1);

      let config = {
        scaleX: {
          values: labels1.map(date => new Date(date).getTime()),
          label: {
            fontWeight: "bold",
            fontAngle: 360,
            offsetX: "0",
            offsetY: 35,
            textAlign: 'right',
            verticalAlign: 'bottom',
            text: this.xLabel || '',
            width: 20,
            fontColor: this.getPrimaryColor,
            fontSize: '15px',
          },
          item: {
            offsetY: 7,
            fontColor: this.getPrimaryColor
          },
          ...transformObject
        },
        scaleY: {
          maxValue: Math.max(max, 6),
          label: {
            text: this.yLabel || '',
            fontColor: this.getPrimaryColor,
            fontSize: '15px',
            textAlign: 'left',
            verticalAlign: 'top',
            fontAngle: 0,
            width: 20,
            offsetX: 30,
            offsetY: -30,
          },
          item: {
            fontColor: this.getPrimaryColor
          }
        }
      }

      if (labels2.length && !isSameDates)
        config.scaleX2 = {
          values: labels2.map(date => new Date(date).getTime()),
          placement: 'default',
          lineWidth: 0,
          item: {
            offsetY: -15,
            fontColor: this.getPrimaryColor
          },
          tick: {
            visible: false
          },
          ...transformObject
        }

      return config
    },
    getChartConfigurationBasedOnType() {
      console.log('selectedChartType: ', this.selectedChartType);

      switch (this.selectedChartType) {
        case "area":
          return getChartConfigurationsWithData(
            { ...areaChartConfigurations, series: this.getSeries, ...this.scales, ...this.legend },
            this.customConfiguration
          );
        case "line":
          return getChartConfigurationsWithData(
            { ...lineChartConfigurations, series: this.getSeries, ...this.scales, ...this.legend },
            this.customConfiguration
          );
        case "bar":
          return getChartConfigurationsWithData(
            { ...barChartConfigurations, series: this.getSeries, ...this.scales, ...this.legend },
            this.customConfiguration
          );
        default:
          return {};
      }
    },
    getPrimaryColor() {
      return this.isDarkMode ? "#A9A9B1":"#1D405B";
    }
  },
  methods: {
    extractConfig() {
      return {
        chart_type: this.chartType
      };
    },
    changeChart(newChartType) {
      this.selectedChartType = newChartType;
      this.$emit('changeChart',newChartType);
    },
    xAxisTransformObjectHandler(datesArray) {
      const daysDiff = this.getDifferenceInDays(
        new Date(datesArray[0]),
        new Date(datesArray[datesArray.length - 1])
      );
      if (daysDiff > 2) {
        return {
          transform: {
            type: "date",
            all: "%Y-%mm-%dd",
            item: {
              visible: false
            }
          }
        };
      }
      return {
        transform: {
          type: "date",
          all: "%H:%i",
          item: {
            visible: false
          }
        }
      };
    },
    getDifferenceInDays(date1, date2) {
      const diffInMs = Math.abs(date2 - date1);
      return diffInMs / (1000 * 60 * 60 * 24);
    },
    isSameDates(dates1, dates2) {
      const daysDiff = this.getDifferenceInDays(new Date(dates1[0]), new Date(dates1[dates1.length - 1]))
      let same = true
      let format = 'YYYY:MM:DD'

      if (daysDiff <= 2)
        format = 'mm:ss'

      dates1.forEach((date, i) => {
        if (i > Math.min(dates1.length, dates2.length)) return

        if (moment(date).format(format) != moment(dates2[i]).format(format))
          same = false
      })

      return same
    }
  },
  watch: {
    customConfiguration: {
      handler(val) {
        this.chartConfig = this.getChartConfigurationBasedOnType
      },
      deep: true
    },
    getChartConfigurationBasedOnType: {
      handler(val) {
        this.chartConfig = val
      },
      deep: true
    },
    chartType: {
      handler(val) {
        this.chartConfig = this.getChartConfigurationBasedOnType
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
  .of-visible {
    overflow: visible !important;
    z-index: -1;
    div {
      overflow: visible !important;
    }

    svg {
      overflow: visible !important;
    }
  }
</style>