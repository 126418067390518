// URLs object
export const VSaaSConfigurations = {
  GETTING_ALARMS_URL: `vsaas/alarms`,
  GETTING_ALARMS_HISTORY_URL: `vsaas/alarms/history`,
  GETTING_VILLAS_URL: `vsaas/villas`,
  GETTING_VILLAS_FOR_MAP_URL: "/vsaas/villas/for-map",
  GETTING_CUSTOMER_CONTACTS: customerId => `company/${customerId}/contacts`,
  POST_ACKNOWLEDGE_ALARM: alarmId => `vsaas/alarms/${alarmId}/acknowledge`,
  POST_ESCALATE_ALARM: alarmId => `vsaas/alarms/${alarmId}/escalate`,
  POST_TERMINATE_ALARM: alarmId => `vsaas/alarms/${alarmId}/terminate`,
  GETTING_DEVICES_COMPANIES: deviceId => `/devices/${deviceId}/companies`,
  GETTING_ALARMS_STATISTICS: `vsaas/alarms/statistics`,
  GETTING_CUSTOMER_INFORMATION: customerId => `/vsaas/villas/${customerId}`,
  ON_ALARMS_MORE_INFORMATION_URL: `/vsaas/alarms/info`
};
