import {applyUniqueIdToAllObjectKeys} from "../../../../util/unique-id-generator";

let types = {
  RESET_THE_WHOLE_STATE_MUTATION: 'RESET_THE_WHOLE_STATE_MUTATION',
  GETTING_ALARMS_MUTATION: 'GETTING_ALARMS_MUTATION',
  GETTING_ALARMS_HISTORY_MUTATION: 'GETTING_ALARMS_HISTORY_MUTATION',
  ALARMS_TOTAL_RECORDS_MUTATION: 'ALARMS_TOTAL_RECORDS_MUTATION',
  GETTING_CUSTOMER_MUTATION: 'GETTING_CUSTOMER_MUTATION',
  CUSTOMER_TOTAL_RECORDS_MUTATION: 'CUSTOMER_TOTAL_RECORDS_MUTATION',
  CUSTOMER_LOCATIONS_ARRAY_MUTATION: 'CUSTOMER_LOCATIONS_ARRAY_MUTATION',
  CUSTOMER_CONTACTS_LIST_MUTATION: 'CUSTOMER_CONTACTS_LIST_MUTATION',
  OPEN_ACKNOWLEDGEMENT_MODAL_MUTATION: 'OPEN_ACKNOWLEDGEMENT_MODAL_MUTATION',
  OPEN_COMMENTS_MODAL_MUTATION: 'OPEN_COMMENTS_MODAL_MUTATION',
  OPEN_ESCALATE_MODAL_MUTATION: 'OPEN_ESCALATE_MODAL_MUTATION',
  OPEN_TERMINATE_MODAL_MUTATION: 'OPEN_TERMINATE_MODAL_MUTATION',
  UPDATE_ALARMS_TABLE_COLUMNS_CONFIG_MUTATION: 'UPDATE_ALARMS_TABLE_COLUMNS_CONFIG_MUTATION',
  DEVICES_COMPANIES_MUTATION: 'DEVICES_COMPANIES_MUTATION',
  LISTEN_TO_ACKNOWLEDGE_ALARM_MUTATION: 'LISTEN_TO_ACKNOWLEDGE_ALARM_MUTATION',
  NEW_ALARM_RECEIVED_FROM_SERVER_MUTATION: 'NEW_ALARM_RECEIVED_FROM_SERVER_MUTATION',
  LISTEN_TO_TERMINATE_ALARM_EVENT_MUTATION: 'LISTEN_TO_TERMINATE_ALARM_EVENT_MUTATION',
  UPDATE_ALARM_STATUS_MUTATION: 'UPDATE_ALARM_STATUS_MUTATION',
  GETTING_ALARMS_STATISTICS_MUTATION: 'GETTING_ALARMS_STATISTICS_MUTATION',
  HISTORY_ALARMS_TOTAL_RECORDS_MUTATION: 'HISTORY_ALARMS_TOTAL_RECORDS_MUTATION',
  GETTING_CUSTOMER_INFORMATION_MUTATION: 'GETTING_CUSTOMER_INFORMATION_MUTATION'

};


types = applyUniqueIdToAllObjectKeys(types);
export default types;
