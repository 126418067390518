<template>
  <el-dialog :visible.sync="openDialogComp" :append-to-body="true" top="3%">
    <div slot="title">
      <router-link class="h2 px-3 m-0 font-color-1" :to="data.title.onClick" v-if="data.title.onClick"
        >{{ $t(data.title.text1) }} &nbsp; {{ data.title.text2 }}</router-link
      >
      <h2 v-else>{{ $t(data.title.text1) }} &nbsp; {{ data.title.text2 }}</h2>
    </div>
    <LocationInfo class="mt-0" v-if="data" :data="data" />
    <div slot="footer" class="dialog-footer d-flex justify-content-center mt-0">
      <base-button type="info" @click="closeModel">{{$t('close')}}</base-button>
    </div>
  </el-dialog>
</template>

<script>
import LocationInfo from "./LocationInfo.vue";

export default {
  name: "LocationInfoStructure",
  props: ["data", "openDialog"],
  components: { LocationInfo },
  methods: {
    closeModel() {
      this.$emit("closeModel", false);
    },
  },
  computed: {
    openDialogComp: {
      get() {
        return this.openDialog;
      },
      set(val) {
        this.closeModel();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  margin-top: -25px;
  overflow: auto;
}
::v-deep .el-dialog__footer {
  margin-top: -50px;
}
</style>
