import SwamService from '@/pages/SWAM/services/swam.service';
import { onePeriod } from '@/util/periodOptions';

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        currentPeriodIndex: 0,
        currentSolutionId: undefined,
        currentFirstStructure: undefined,
        currentSecondStructure: undefined,
        currentFirstDevice: undefined,
        currentSecondDevice: undefined,
        currentSensorId: undefined,
        firstDevices: [],
        secondDevices: [],
        firstDeviceData:{
          performance:{
              ok:0,
              poor:0,
              problem:0,
          },
          stats:{
            min: '-',
            avg: '-',
            max: '-',
          },
          measurements:[]
        },
        secondDeviceData:{
          performance:{
            ok:0,
            poor:0,
            problem:0,
          },
          stats:{
            min: '-',
            avg: '-',
            max: '-',
          },
          measurements: []
      },
      measurementsDates: [],
      ranges:[]
    }),
    getters:{
        loading(state) {
            return state.loading;
        },
        periodsOptions(state) {
          return onePeriod;
        },
        firstDevicesOptions(state) {
          let result = [];
          state.firstDevices.forEach(d => {
            result.push({label:d.name,value:d.id});
          });
          return result;
        },
        secondDevicesOptions(state) {
          let result = [];
          state.secondDevices.forEach(d => {
            result.push({label:d.name,value:d.id});
          });
          return result;
        },
        sensorOptions(state) {
          let result = [];
          if(!state.firstDevices || !state.secondDevices || !state.currentFirstDevice || !state.currentSecondDevice)
            return result;
          let firstDevice = state.firstDevices.find(d => d.id == state.currentFirstDevice);
          let secondDevice = state.secondDevices.find(d => d.id == state.currentSecondDevice);
          if(!firstDevice || !secondDevice)
            return result;
          let sensors = firstDevice.sensors;
          sensors.filter(d => secondDevice.sensors.some(d2 => d2.type == d.type));
          sensors.forEach(s => {
            result.push({label:s.name,value:s.type});
          });
          return result;
        },
        performanceData(state) {
            let result = [];
            let firstDevice = state.firstDevices.find(d => d.id == state.currentFirstDevice);
            let secondDevice = state.secondDevices.find(d => d.id == state.currentSecondDevice);
            // firstDevice
            result.push({
              title:(firstDevice ? firstDevice.name : ''),
              ok:state.firstDeviceData.performance.ok,
              poor:state.firstDeviceData.performance.poor,
              problem:state.firstDeviceData.performance.problem,
            });
            // secondDevice
            result.push({
              title:(secondDevice ? secondDevice.name : ''),
              ok:state.secondDeviceData.performance.ok,
              poor:state.secondDeviceData.performance.poor,
              problem:state.secondDeviceData.performance.problem,
            });
            return result;
        },
        gaugeChartData(state) {
            let result = [];
            let firstDevice = state.firstDevices.find(d => d.id == state.currentFirstDevice);
            let secondDevice = state.secondDevices.find(d => d.id == state.currentSecondDevice);
            result.push({
                title:(firstDevice ? firstDevice.name : ''),
                stats: state.firstDeviceData.stats,
                sensorTypeId:state.currentSensorId,
                ranges:state.ranges
              });
              result.push({
                title:(secondDevice ? secondDevice.name : ''),
                stats: state.secondDeviceData.stats,
                sensorTypeId:state.currentSensorId,
                ranges:state.ranges
            });
            return result;
        },
        firstDeviceMeasurements(state){
          return state.firstDeviceData.measurements;
        },
        secondDeviceMeasurements(state){
          return state.secondDeviceData.measurements;
        },
        measurementDates(state){
          return state.measurementsDates;
        },
    },
    mutations:{
        setLoading(state,value) {
          state.loading = value;
        },
        selectSolution(state,value) {
          state.currentSolutionId = value;
        },
        selectFirstStructure(state,value) {
          state.currentFirstStructure = value;
        },
        selectSecondStructure(state,value) {
          state.currentSecondStructure = value;
        },
        selectFirstDevice(state,value) {
          state.currentFirstDevice = value;
        },
        selectSecondDevice(state,value) {
          state.currentSecondDevice = value;
        },
        selectSensor(state,value) {
          state.currentSensorId = value;
        },
        selectPeriod(state,value) {
          state.currentPeriodIndex = value;
        },
        selectCustomPeriod(state,value) {
          state.periodsOptions[5].periods = value;
        },
        setFirstDevices(state,value) {
          state.firstDevices = value;
        },
        setSecondDevices(state,value) {
          state.secondDevices = value;
        },
        setData(state,data) {
          state.firstDeviceData.performance = data.performance1;
          state.firstDeviceData.stats = data.stats1;
          state.firstDeviceData.measurements = data.measurements1;
          state.secondDeviceData.performance = data.performance2;
          state.secondDeviceData.stats = data.stats2;
          state.secondDeviceData.measurements = data.measurements2;
          state.ranges = data.ranges;
          state.measurementsDates = data.dates;
        }
    },
    actions:{
      async fetchDevices({ state, commit, dispatch },payload) {
        commit('setLoading',true);
        try {
            let params = {
              structure_id:(payload=='first' ? state.currentFirstStructure : state.currentSecondStructure),
              solution_id:state.currentSolutionId,
            };
            const data = await SwamService.getDevices(params);
            if(payload == 'first') {
              commit('setFirstDevices',data);
            } else {
              commit('setSecondDevices',data);
            }
            commit("setLoading", false);
        } catch (error) {
            commit("setLoading", false);
            console.error(error);
        }
      },
      async fetchData({ state, commit, dispatch }) {
        if(!state.currentFirstDevice || !state.currentSecondDevice || !state.currentSensorId)
          return;
        commit('setLoading',true);
        try {
          let params = {
            device1: state.currentFirstDevice,
            device2: state.currentSecondDevice,
            sensor: state.currentSensorId,
            from: onePeriod[state.currentPeriodIndex].from().toDate(),
            to: onePeriod[state.currentPeriodIndex].to().toDate(),
          };
          const data = await SwamService.getDeviceComparisonData(params);
          commit('setData',data);
          commit("setLoading", false);
        } catch (error) {
          commit("setLoading", false);
          console.error(error);
        }
      },
      resetData({state}) {
        let p = {
          performance:{
            ok:0,
            poor:0,
            problem:0,
          },
          stats:{
            min: '-',
            avg: '-',
            max: '-',
          },
          measurements:[]
        };
        state.firstDeviceData = p;
        state.secondDeviceData = p;
        state.measurementsDates = [];
        state.ranges = [];
      },
      reset({state,dispatch}) {
        state.currentPeriodIndex= 0;
        state.currentSolutionId= undefined;
        state.currentFirstStructure= undefined;
        state.currentSecondStructure= undefined;
        state.currentFirstDevice= undefined;
        state.currentSecondDevice= undefined;
        state.currentSensorId= undefined;
        dispatch('resetData');
      }
    },
}