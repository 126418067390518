import VsaasSrevice from '../../services/vsaas.service';
import { dateTableCellFomatter,emptyTableCellFormatter } from "@/modules/common/utils/helper";

export default {
    namespaced: true,
    state: {
        loading: false,
        columns: [
            {
              label: "Alarm ID",
              prop: "alarm.id",
              minWidth: 200,
              maxWidth: 300
            },
            {
              label: "Alarm Date",
              prop: "alarm.createdAt",
              minWidth: 200,
              maxWidth: 300,
              formatter: dateTableCellFomatter
            },
            {
              label: "ended at",
              prop: "alarm.endedAt",
              minWidth: 200,
              maxWidth: 300,
              formatter: dateTableCellFomatter
            },
            {
              label: "event time",
              prop: "event.createdAt",
              minWidth: 200,
              maxWidth: 300,
              formatter: dateTableCellFomatter
            },
            {
              label: "event type",
              prop: "event.type",
              minWidth: 200,
              maxWidth: 300
            },
            {
              label: "alarm type",
              prop: "alarm.status",
              minWidth: 200,
              maxWidth: 300
            },
            {
              label: "by",
              prop: "event.user.email",
              minWidth: 200,
              maxWidth: 300,
              formatter: emptyTableCellFormatter
            },
            {
              label: "comment",
              prop: "event.comment",
              minWidth: 200,
              maxWidth: 500,
              formatter: emptyTableCellFormatter
            },
            {
              label: "device type",
              prop: "device.type",
              minWidth: 200,
              maxWidth: 500
            },
            {
              label: "device name",
              prop: "device.name",
              minWidth: 200,
              maxWidth: 500
            },
            {
              label: "device status",
              prop: "device.on",
              minWidth: 200,
              maxWidth: 500,
              formatter: (_, __, value) => {
                return value ? "on" : "off";
              }
            },
            {
              label: "channel id",
              prop: "device.channelId",
              minWidth: 200,
              maxWidth: 500,
              formatter: emptyTableCellFormatter
            },
            {
              label: "villa",
              prop: "device.structureName",
              minWidth: 200,
              maxWidth: 500
            },
            {
              label: "area",
              prop: "device.communityNameEn",
              minWidth: 200,
              maxWidth: 500
            },
            {
              label: "alarm email",
              prop: "device.alarmEmail",
              minWidth: 200,
              maxWidth: 500
            }
        ],
        tableData: [],
        pagination: {
            limit: 10,
            offset: 0,
        },
        total: 0,
        filters: {},
        filterOptions: {},
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        tableColumns(state) {
            return state.columns;
        },
        tableData(state) {
            return state.tableData;
        },
        pagination(state) {
            return state.pagination;
        },
        total(state) {
            return state.total;
        },
        filters(state) {
            return state.filters;
        },
        filterOptions(state) {
            return state.filterOptions;
        }
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setData(state,data) {
            state.tableData = data;
        },
        setTotal(state,value) {
            state.total = value;
        },
        updatePagination(state,value) {
            state.pagination = value;
        },
        updateFilters(state,value) {
            state.filters = value;
        },
        setFilterOptions(state,value) {
            state.filterOptions = value;
        }
    },
    actions: {
        async fetchData({state,commit},payload) {
            commit('setLoading',true);
            try {
                let params = {
                    ...state.pagination,
                    ...state.filters,
                    ...payload
                }
                const data = await VsaasSrevice.getOperationalAlarmsHistory(params);
                commit('setData',data.data);
                if(data.totalRecords)
                  commit('setTotal',data.totalRecords);
                commit('setLoading',false);
            } catch(error) {
                commit('setLoading',false);
                console.log(error);
            }
        },
        async fetchFilterOptions({state,commit}) {
            try {
                const data = await VsaasSrevice.getAlarmsInfo();
                let filterOptions = {
                    villaOptions: [{label: "all"},...data.villas.map(v=>({
                      label: v.name,
                      value: v.id
                    }))],
                    areaOptions: [{label: "all"},...data.areas.map(v=>({
                      label: v.name,
                      value: v.id
                    }))],
                    eventOptions: [{label: "all"},...data.events.map(v=>({
                      label: v,
                      value: v
                    }))],
                    alarmOptions: [{label: "all"},...data.alarms.map(v=>({
                      label: v,
                      value: v
                    }))],
                    statusOptions: [{label: "all"}]
                };
                commit('setFilterOptions',filterOptions);
            } catch(error) {
                console.log(error);
            }
        }
    },
};