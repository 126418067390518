import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';

export default {
    path:"/intrusion-system",
    component: DashboardLayout,
    name: "intrusion system",
    redirect: "/intrusion-system/dashboard",
    children: [
        {
            path: 'dashboard',
            name: "general status",
            component: ()=> import('../pages/dashboard/index.vue')
        }
    ]
}