import api from "src/axiosWithDelimiterFile";
// import qs from 'qs'
export default class ScooterService {
  static async getScooters(payload) {
    const response = await api.get(`/teltonika/scooter?limit=${payload.limit}&offset=${payload.offset}`)
    return response;
  }
  static async getRentals(scooterID) {
    const response = await api.get(`/teltonika/scooter/${scooterID}/rentals`)
    return response;
  }
  static async getSpeed(payload) {
    const response = await api.get(`/teltonika/scooter/${payload.id}/speed?start=${payload.start}&end=${payload.end}`)
    return response;
  }
  static async getInfo(scooterID) {
    const response = await api.get(`/teltonika/scooter/${scooterID}/info`)
    return response;
  }
  static async getBasicInfo(scooterID) {
    const response = await api.get(`/teltonika/scooter/${scooterID}/basic-info`)
    return response;
  }
  static async getTimeline(payload) {
    const response = await api.get(`/teltonika/scooter/${payload.id}/timeline${payload.start ? `?start=${payload.start}&end=${payload.end}`: ''}`)
    return response;
  }
  static async getGeofence(scooterID) {
    const response = await api.get(`/teltonika/scooter/${scooterID}/geofence`)
    return response;
  }
  static async getBattery(scooterID) {
    const response = await api.get(`/teltonika/scooter/${scooterID}/battery`)
    return response;
  }
  static async getAlarms(payload) {
    const response = await api.get(`/teltonika/scooter/${payload.id}/alarms?start=${payload.start}&end=${payload.end}`)
    return response;
  }
  static async getMileage(payload) {
    const response = await api.get(`/teltonika/scooter/${payload.id}/mileage`)
    return response;
  }
  static async getTime(payload) {
    const response = await api.get(`/teltonika/scooter/${payload.id}/time`)
    return response;
  }
  static async getPayments(payload) {
    const response = await api.get(`/teltonika/scooter/${payload.id}/payments`)
    return response;
  }
  static async getCustomerData(payload) {
    const response = await api.get(`/teltonika/scooter/${payload.id}/customer-history?offset=${payload.offset}&limit=${payload.limit}`)
    return response;
  }
}
