import api from "@/axiosWithDelimiterFile.js"
import { camelToSnakeCase } from "@/util/helper";
import {
    OPERATIONAL_STATUSES,
    DEVICES_BASIC_INFO,
    DEVICE_COMPARISON,
    STATISTICS,
    HIGHEST_PROBLEMS,
    STRUCTURE_COMPARISON,
    STRUCTURE_STATISTICS,
    SOLUTION_STATISTICS,
    STRUCTURES,
    DEVICES_LIST,
    OPERATIONAL_ALARMS,
    STATUS_ALARMS,
    ALARMS_HISTORY,
    ALARMS_EVENTS,
    ALARMS_COMMENT,
    ALARMS_ESCALATE,
    ALARMS_ACKNOWLEDGE,
    SENSOR_TEMPLATE_LIST,
    SENSOR_TEMPLATE_BY_ID,
} from './endpoints';
class SWAMService{
    static getOperationalStatuses(qp = {}) {
        return new Promise((resolve,reject)=>{
            api.get(OPERATIONAL_STATUSES, {
                params: convertObjectKeysToSnakeCase(qp)
            })
            .then(res=>{
                resolve(res.data.data);
            })
            .catch(reject);
        });
    }
    static getDevices(qp = {}) {
        return new Promise((resolve,reject)=>{
            api.get(DEVICES_BASIC_INFO, {
                params: convertObjectKeysToSnakeCase(qp)
            })
            .then(res=>{
                resolve(res.data.data);
            })
            .catch(reject);
        });
    }
    static getDeviceComparisonData(qp = {}) {
        return new Promise((resolve,reject)=>{
            api.get(DEVICE_COMPARISON, {
                params: convertObjectKeysToSnakeCase(qp)
            })
            .then(res=>{
                resolve(res.data.data);
            })
            .catch(reject);
        });
    }
    static getStatistics(qp = {}) {
        return new Promise((resolve,reject)=>{
            api.get(STATISTICS, {
                params: convertObjectKeysToSnakeCase(qp)
            })
            .then(res=>{
                resolve(res.data.data);
            })
            .catch(reject);
        });
    }
    static getHighestProblems(qp = {}) {
        return new Promise((resolve,reject)=>{
            api.get(HIGHEST_PROBLEMS, {
                params: convertObjectKeysToSnakeCase(qp)
            })
            .then(res=>{
                resolve(res.data.data);
            })
            .catch(reject);
        });
    }
    static getStructureComparisonData(qp = {}) {
        return new Promise((resolve,reject)=>{
            api.get(STRUCTURE_COMPARISON, {
                params: convertObjectKeysToSnakeCase(qp)
            })
            .then(res=>{
                resolve(res.data.data);
            })
            .catch(reject);
        });
    }
    static getStructureStatistics(qp = {}) {
        return new Promise((resolve,reject)=>{
            api.get(STRUCTURE_STATISTICS, {
                params: convertObjectKeysToSnakeCase(qp)
            })
            .then(res=>{
                resolve(res.data.data);
            })
            .catch(reject);
        });
    }
    static getSolutionStatistics(qp = {},solutionId) {
        return api.get(SOLUTION_STATISTICS + '/' + solutionId,{
            params: convertObjectKeysToSnakeCase(qp)
        }).then(({ data }) => {
            return data.result;
        })
    }
    static getStructuresData(qp = {}) {
        return api.post(STRUCTURES,{
            params: qp
        }).then(list=>{
          return {
            data: list.data.data,
            total_records : list.data.total_records.count
          };
        });
    }
    static getDevicesList(qp = {}) {
        return api.get(DEVICES_LIST,{
            params: qp
        }).then(list=>{
            return {
                data: list.data.data,
                total_records : list.data.total_records
            };
        });
    }
    static getOperationalAlarms(qp = {}) {
        return api.get(OPERATIONAL_ALARMS,{
            params: qp
        }).then(res=>{
          return res.data
        });
    }
    static getStatusAlarms(qp = {}) {
        return api.get(STATUS_ALARMS,{
            params: qp
        }).then(res=>{
          return res.data
        });
    }
    static getAlarmsHistory(alarmType,qp = {}) {
        return api.get(ALARMS_HISTORY(alarmType), {
            params: qp
          }).then(res => {
            return res.data;
          })
    }
    static getAlarmComments(alarmType,alarmId) {
        return api.get(ALARMS_EVENTS(alarmType,alarmId));
    }
    static submitAlarmComment(alarmType,alarmId,msgText) {
        return api.post(ALARMS_COMMENT(alarmType,alarmId), {
            comment: msgText,
        });
    }
    static escalateAlarm(alarmType,alarmId,data) {
        return api.post(ALARMS_ESCALATE(alarmType,alarmId), data);
    }
    static acknowledgeAlarm(alarmType,alarmId,data) {
        return api.post(ALARMS_ACKNOWLEDGE(alarmType,alarmId), data);
    }

    static getSensorTemplatesList(qp = {}){
        return api.post(SENSOR_TEMPLATE_LIST(qp.limit, qp.offset),qp);
    }
    static getSensorTemplateById(id, qp = {}){
        return api.get(SENSOR_TEMPLATE_BY_ID(id), {
            params: qp
        });
    }
}

const convertObjectKeysToSnakeCase = (ob)=>{
    const newObject = {};
    Object.keys(ob).forEach(key=>{
        newObject[camelToSnakeCase(key)] = ob[key];
    });
    return newObject;
}

export default SWAMService;