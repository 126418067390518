<template>
  <div v-if="slider" class="row justify-content-around">
    <div class="prev">
      <button class="float-left btn btn-primary" @click="prev" type="button">
        <svg
          width="1.5em"
          height="1.5em"
          viewBox="0 4 18 10"
          class="bi bi-arrow-left-circle"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
          />
          <path
            fill-rule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
          />
        </svg>
      </button>
    </div>
    <div
      :class="[
        { 'col-md-4': vertical && !tabNavWrapperClasses },
        { 'col-12': centered && !tabNavWrapperClasses },
        tabNavWrapperClasses,
        'tabs'
      ]"
    >
      
        <transition-group name="list" class="nav nav-pills" tag="ul"
        role="tablist"
        closable="true"
        :class="[
          `nav-pills-${type}`,
          { 'nav-pills-icons': square },
          { 'flex-column': vertical },
          { 'justify-content-center': centered },
          tabNavClasses
        ]">
          <li
            v-for="tab in tabsVisible"
            class="nav-item active tab-Dashboard list-item"
            data-toggle="tab"
            role="tablist"
            aria-expanded="true"
            closable="true"
            :key="tab.id"
          >
            <a
              data-toggle="tab"
              role="tablist"
              closable="true"
              :href="`#${tab.id}`"
              @click.prevent="activateTab(tab)"
              :aria-expanded="tab.active"
              class="nav-link"
              :class="{ active: tab.active }"
            >
              <tab-item-content :tab="tab"></tab-item-content>
            </a>
          </li>
        </transition-group>
    </div>
    <div class="next">
      <button class="float-right btn btn-primary" @click="next" type="button">
        <svg
          width="1.5em"
          height="1.5em"
          viewBox="0 4 18 10"
          class="bi bi-arrow-right-circle"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
          />
          <path
            fill-rule="evenodd"
            d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"
          />
        </svg>
      </button>
    </div>
    <div
      class="tab-content"
      :class="[
        { 'tab-space col-11': !vertical },
        { 'col-md-8': vertical && !tabContentClasses },
        tabContentClasses
      ]"
    >
      <slot></slot>
    </div>
  </div>
  <div v-else>
    <div
      :class="[
        { 'col-md-4': vertical && !tabNavWrapperClasses },
        { 'col-12': centered && !tabNavWrapperClasses },
        tabNavWrapperClasses
      ]"
    >
      <ul
        class="nav nav-pills"
        role="tablist"
        closable="true"
        :class="[
          `nav-pills-${type}`,
          { 'nav-pills-icons': square },
          { 'flex-column': vertical },
          { 'justify-content-center': centered },
          tabNavClasses
        ]"
      >
        <li
          v-for="tab in tabs"
          class="nav-item active tab-Dashboard"
          data-toggle="tab"
          role="tablist"
          aria-expanded="true"
          closable="true"
          :key="tab.id"
        >
          <a
            data-toggle="tab"
            role="tablist"
            closable="true"
            :href="`#${tab.id}`"
            @click.prevent="activateTab(tab)"
            :aria-expanded="tab.active"
            class="nav-link"
            :class="{ active: tab.active }"
          >
            <tab-item-content :tab="tab"></tab-item-content>
          </a>
        </li>
      </ul>
    </div>
    <div
      class="tab-content"
      :class="[
        { 'tab-space': !vertical },
        { 'col-md-8': vertical && !tabContentClasses },
        tabContentClasses
      ]"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "tabs",
  components: {
    TabItemContent: {
      props: ["tab"],
      render(h) {
        return h("div", [this.tab.$slots.label || this.tab.label]);
      }
    }
  },
  provide() {
    return {
      addTab: this.addTab,
      removeTab: this.removeTab
    };
  },
  computed: {
    tabsVisible() {
      return this.tabs.filter(t => t.visible);
    }
  },
  props: {
    slider: {
      type: Boolean,
      default: false
    },
    visibleTabsCount: {
      type: Number,
      default() {
        return 4;
      }
    },
    type: {
      type: String,
      default: "primary",
      validator: value => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger"
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    activeTab: {
      type: String,
      default: ""
    },
    tabNavWrapperClasses: {
      type: [String, Object],
      default: ""
    },
    tabNavClasses: {
      type: [String, Object],
      default: ""
    },
    tabContentClasses: {
      type: [String, Object],
      default: ""
    },
    vertical: Boolean,
    square: Boolean,
    centered: Boolean,
    value: String
  },
  model: {
    prop: "value",
    event: "change"
  },
  data() {
    return {
      tabs: []
    };
  },
  methods: {
    findAndActivateTab(label) {
      let tabToActivate = this.tabs.find(t => t.label === label);
      if (tabToActivate) {
        this.activateTab(tabToActivate);
      } else if (this.tabs[0]) {
        this.activateTab(this.tabs[0]);
      }
    },
    activateTab(tab) {
      if (this.handleClick) {
        this.handleClick(tab);
      }
      this.deactivateTabs();
      tab.active = true;
      this.$emit("change", tab.id);
    },
    deactivateTabs() {
      this.tabs.forEach(tab => {
        tab.active = false;
      });
    },
    addTab(tab) {
      this.deactivateTabs();
      const index = this.$slots.default.indexOf(tab.$vnode);
      if (!this.activeTab && index === 0) {
        tab.active = true;
      } else {
        this.tabs[0].active = true;
      }
      if (this.activeTab === tab.name) {
        tab.active = true;
      }
      this.tabs.splice(index, 0, tab);
    },
    removeTab(tab) {
      const tabs = this.tabs;
      const index = tabs.indexOf(tab);
      if (index > -1) {
        tabs.splice(index, 1);
      }
    },
    next() {
      let index = this.tabs.indexOf(
        this.tabsVisible[this.tabsVisible.length - 1]
      );
      let indexVisible = this.tabs.findIndex(t => t.visible);
      if (indexVisible != -1 && indexVisible != 0) {
        indexVisible -= 1;
      }
      this.tabs[indexVisible].visible = true;
      this.tabs[index].visible = false;
      this.tabs.unshift(this.tabs.pop());
    },
    prev() {
      let len = this.tabs.length;
      let firstIndex = this.tabs.findIndex(t => t.visible);
      let lastIndex = this.tabs.findIndex(t => !t.visible);
      if (lastIndex == len - 1) {
        lastIndex = 0;
      }
      if (firstIndex == len - 1) {
        firstIndex = 0;
      }
      this.tabs[lastIndex].visible = true;
      this.tabs[firstIndex].visible = false;
      this.tabs.push(this.tabs.shift());
    },
    setDefaultVisibleTabs() {
      if (this.slider) {
        let maxIndex =
          this.tabs.length >= this.visibleTabsCount
            ? this.visibleTabsCount
            : this.tabs.length;
        for (let i = 0; i < maxIndex; ++i) {
          this.tabs[i].visible = true;
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.value) {
        this.findAndActivateTab(this.value);
      }
    });
    this.setDefaultVisibleTabs();
  },
  watch: {
    value(newVal) {
      this.findAndActivateTab(newVal);
    },
    tabs() {
      this.setDefaultVisibleTabs();
    }
  }
};
</script>

<style scoped>

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.next {
  position: absolute;
  right: 10px;
}


.prev {
  position: absolute;
  left: 10px;
}
.prev button,
.next button {
  width: 40px;
  height: 40px;
  padding-left: 24px;
}

.tabs {
  height: 50px;
  overflow-y: hidden;
}
.ParentMap .tab-Dashboard a.active {
  padding-right: 8px !important;
  padding-left: 8px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  background-image: linear-gradient(
    to bottom left,
    #31d88b,
    #4eb383,
    #33ea94
  ) !important;
  background-size: 206% 130% !important;
}
.ParentMap .tab-Dashboard a {
  padding-right: 8px !important;
  padding-left: 8px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  margin-right: 0 !important;
  border-radius: 2px !important;
  background: transparent;
  border: 1px solid #4e5063;
}

.ParentMap .tab-Dashboard a {
  margin-right: 0 !important;
  border-left: 0px !important;
  border-right: 0px !important;
}
.ParentMap li.tab-Dashboard:last-child a {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}
.ParentMap li.tab-Dashboard:first-child a {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
::v-deep.no-spaceing .tab-content.tab-space {
  padding-bottom: 0;
}
</style>
