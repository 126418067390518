import Vue from "vue";

const asArray = x => Array.isArray(x) ? x : [x];

export default function generateInitalStore({
  maxCacheAge = 30000,
  dataProvider,
}) {
  return {
    namespaced: true,
    actions: {
      async FETCH_ITEMS({ commit, state }, {
        key,
        query,
        useCache = true,
      }) {
        if (!key) throw new Error('Missing `key` attribute.');
        const queryId = `ITEMS_${JSON.stringify(query)}`;
        // Cleanup the cache.
        commit('CLEANUP');

        // By saving the `queryId` with the given `key` in the usage table, we can later
        // determine which queries are still active and can not be pruned from cache.
        state.cache.usage[key] = queryId;
        let response;
        const cacheHit = useCache && state.cache.queries[queryId];
        if (cacheHit) {
          console.log('From cache:', queryId)
          response = state.cache.queries[queryId].response;
        }

        if (!cacheHit && !state.loadingList[queryId]) {
          state.loadingList[queryId] = {
            promise:dataProvider.list(query),
            counter: 0
          };
          try {
            response = await state.loadingList[queryId].promise;
            commit('ADD_QUERY', {
              response,
              id: queryId,
              query,
            })
          }
          finally{
            if(state.loadingList[queryId].counter == 0){
              delete state.loadingList[queryId];
            }
          }
        }else if (state.loadingList[queryId]){
          state.loadingList[queryId].counter++;
          try {
            response = await state.loadingList[queryId].promise;
            state.loadingList[queryId].counter--;
          } finally {
            if(state.loadingList[queryId].counter == 0){
              delete state.loadingList[queryId];
            }
          }
        }

        return response;
      },
      async FETCH_ITEM({ commit, state }, {
        key,
        query,
        useCache = true,
      }) {
        if (!key) throw new Error('Missing `key` attribute.');
        const queryId = `ITEM_${JSON.stringify(query)}`;
        // Cleanup the cache.
        commit('CLEANUP');

        state.cache.usage[key] = queryId;
        // const cacheHit = useCache && state.byId[queryId];
        const cacheHit = useCache && state.cache.queries[queryId];
        let response;
        if (cacheHit){
          console.log('From cache:', queryId);
          response = { data: state.byId[query.id] };
        }else{
          response = await dataProvider.find(query);
          commit('ADD_QUERY', {
            response,
            id: queryId,
            query,
          })
        }

        return response;
      }
    },
    mutations: {
      ADD_QUERY(state, { response, id, query }) {
        const queryDetails = {
          createdAt: Date.now(),
          id,
          response: {
            ...response,
            data: Array.isArray(response.data) ? response.data.map(x => `${x.id}`) : `${response.data.id}`,
          },
          query,
        };
        Vue.set(state.cache.queries, id, queryDetails);
        asArray(response.data).forEach(item => {
          const newItem = { ...item, ...(state.byId[item.id] || {}) }
          Vue.set(state.byId, item.id, newItem)
        })
      },
      CLEANUP(state) {
        // const queriyIdsInUse = Object.values(state.cache.usage);
        // const unusedQueries = Object.values(state.cache.queries)
        //   .filter(x => !queriyIdsInUse.includes(x.id));
        const expiredQueries = Object.values(state.cache.queries)
          .filter(x => x.createdAt < Date.now() - maxCacheAge);
        // Delete quries from cache if they are expired.
        expiredQueries.forEach((queryDetail) => {
          delete state.cache.queries[queryDetail.id];
          delete state.loadingList[queryDetail.id];
        });

        const itemIdsInUse = Object.values(state.cache.queries)
          .reduce((prev, queryDetail) => [...prev, ...asArray(queryDetail.response.data)], []);
        const expiredItems = Object.keys(state.byId).filter(x => !itemIdsInUse.includes(x));
        // Delete items which are not referenced anymore in the cache.
        expiredItems.forEach((id) => {
          delete state.byId[id];
        });
      },
      FREE_UP(state) {
        state.cache = {
          usage: {},
          queries: {},
        };
        state.byId = {};
      },
      DELETE_ITEM(state,id){
        this.commit("CLEANUP");
        Object.keys(state.cache.queries).forEach(query=>{
          const data = state.cache.queries[query];
          data.response.data = data.response.data.filter(d=>d.id != id);
        });
        state.byId[id]= undefined;
      }
    },
    getters: {
      items: state => query => {
        const queryId = `ITEMS_${JSON.stringify(query)}`;
        if (!state.cache.queries[queryId]) return [];
        return state.cache.queries[queryId].response.data.map(id => state.byId[id]);
      },
      item: state => id => {
        return state.byId[id] || null;
      },
      response: state => queryId => {
        if (!state.cache.queries[queryId]) return null;
        const response = state.cache.queries[queryId].response;
        const data = response.data;
        return {
          ...response,
          data: Array.isArray(data) ? data.map(id => state.byId[id]) : state.byId[data.id],
        };
      },
    },
    state: {
      loadingList:{

      },
      cache: {
        usage: {},
        queries: {},
      },
      byId: {},
    }
  };
}
