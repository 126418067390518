import { MoroIoTSocketMixin } from "./mixin";
import { MoroIoTSocketListener } from "./listener";
import { MoroIoTSocketEmitter } from "./emitter";
import SocketIO from "socket.io-client";
const log = console.log;
export class MoroIoTSocketIO {
  /**
   *
   * @param io
   * @param options
   */
  constructor({ connection, options, namespace = 'vue' }) {
    this.namespace = namespace;
    this[`io_${namespace}`] = this.registerSocketIO(connection, options);
    this[`emitter_${namespace}`] = new MoroIoTSocketEmitter(this, namespace);
    this[`listener_${namespace}`] = new MoroIoTSocketListener(this[`io_${namespace}`], this[`emitter_${namespace}`], namespace);
    // this.emitter.emit('connect', true);
  }

  /**
   * Vue.js entry point
   * @param Vue
   */
  install(Vue) {
    Vue.prototype[`$socket_${this.namespace}`] = this.io;
    Vue.prototype[`$moroIoTSocketIo_${this.namespace}`] = this;
    Vue.mixin(MoroIoTSocketMixin(this.namespace));
  }

  /**
   * registering SocketIO instance
   * @param connection
   * @param options
   */
  registerSocketIO(connection, options) {
    if (connection && typeof connection === "object") {
      console.log("Received socket.io-client instance");

      return connection;
    } else if (typeof connection === "string") {
      console.log("Received connection string");

      return (this[`io_${this.namespace}`] = SocketIO(connection, options));
    } else {
      throw new Error("Unsupported connection type");
    }
  }

  wakeUp() {
    if (this.socketConnectionInterval == null) {
      log(`^^^ ${this.namespace} socket well be awakened...`)
      this.connect();
      this.socketConnectionInterval = setInterval(() => {
        if (!this[`io_${this.namespace}`].connected) {
          this.connect();
        }
      }, 1500);
    }
  }

  connect() {
    const token = localStorage.getItem('token');
    if (token) {
      this[`io_${this.namespace}`].io.opts.query = `token=${token}`;
      this[`io_${this.namespace}`].connect();
    } else {
      throw new Error("Please provide an access token");
    }
  }

  sleep() {
    log(`^^^ ${this.namespace} socket well go idle after two second...`);
    setTimeout(() => {
      const newListenersCount = this[`emitter_${this.namespace}`][`listeners_${this.namespace}`].size;
      if (newListenersCount == 0) {
        this[`io_${this.namespace}`].disconnect();
        clearInterval(this.socketConnectionInterval);
        this.socketConnectionInterval = null;
      } else {
        log(`^^^ ${this.namespace} is awake agaen...`);
      }
    }, 2000)
  }
}
