import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import { M } from "../../Permissions";

const DeviceReading = () =>
    import( './DeviceReading.vue');
const DeviceAlarmsHistory = ()=>
    import('./DeviceAlarmsHistory.vue');


export default {
    path: '/device',
    component: DashboardLayout,
    redirect:'/device/list',
    name:'device',
    meta:{
        module:M.iot
    },
    children: [
        {
            path: 'reading/:id',
            name: 'Devices Visualization',
            component: DeviceReading,
            props:true
        },
        {
            path: 'status-history/:id',
            name: 'Status History',
            component: DeviceAlarmsHistory,
            props:true
        },


    ]
}
