import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';



const WorkOrderList = () =>
    import( './WorkOrderList.vue');

const CreateWorkOrder = () =>
    import( './CreateWorkOrder.vue');

const UpdateMainOrder = ()=>
    import('./UpdateMainOrder.vue');

const CreateHardwareLot = ()=>
    import('./CreateHardwareLot.vue');




export default {
    path: '/order',
    component: DashboardLayout,
    redirect:'/order/list',
    name:'order',
    children: [
        {
            path: 'list',
            name:'Work Order Details',
            component: WorkOrderList
        },

        {
            path: 'create',
            name:'Work Order Create',
            component: CreateWorkOrder
        },
        {
            path: 'updatemain/:id',
            name:'Update Main',
            component: UpdateMainOrder
        },
        {
            path: 'createLot/:orderid',
            name:'Create',
            component: CreateHardwareLot
        },
        {
            path: 'updateLot/:orderid/:id',
            name:'Update',
            component: CreateHardwareLot
        },

    ]
}
