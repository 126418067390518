<template>
  <card v-loading="loading">
        <template slot="header">
            <div class="d-flex justify-content-between">
                <h3>{{ $t("widgets.statusesChart") }}</h3>
                <slot name="header-right">
                    <base-select
                        v-if="!hidePeriodSelector"
                        :inline="true"
                        :light="true"
                        v-model="currentPeriodIndex"
                        :options="periodsOptions"
                    />
                </slot>
            </div>
        </template>
        <div class="row">
          <div class="col-md-3 col">
              <base-select
                :inline="true"
                :label="$t('Structure') + ': '"
                v-model="currentStructureId"
                :options="structureOptions"
                :disabled="!enableStructureSelect"
              />
          </div>
          <div class="col-md-3">
              <base-select
                :inline="true"
                :label="$t('Solution') + ': '"
                v-model="currentSolutionId"
                :options="solutionsOptions"
                :disabled="!enableSolutionSelect"
              />
          </div>
        </div>
        <ZingchartWrapper
            :customConfiguration="{}"
            :series="chartSeries"
            :labels="chartLabels"
            height="350px"
            :requireZoom="false"
         />
  </card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getDeep,colors } from "@/util/helper";
import ZingchartWrapper from "@/components/Charts/zingchart-wrapper";

export default {
    components: {
        ZingchartWrapper
    },
    props: {
        namespace: {
            type: String,
            default: "SwamStore/StructurePortfolio"
        },
        moduleName: {
            type: String,
            default: 'AlarmsCount',
        },
        disableDataFetch: {
            type: Boolean,
            default: false
        },
        hidePeriodSelector: {
            type: Boolean,
            default: false,
        },
        enableSolutionSelect: {
            type: Boolean,
            default: true,
        },
        enableStructureSelect: {
            type: Boolean,
            default: true,
        },
        data: {},
    },
    data() {
        return {
            CustomDate:undefined,
        }
    },
    computed: {
        storePath() {
            return this.namespace + '/' + this.moduleName;
        },
        ...mapGetters("SwamStore", {
            structureOptions: "structuresOptions",
            allSolutionsOptions: "solutionsOptions",
            solutionsForStructure: "solutionsForStructure",
        }),
        solutionsOptions() {
            let result = this.solutionsForStructure(this.currentStructureId);
            result.unshift({label:'All'});
            return result;
        },
        ...mapState({
            loading(state, getters) {
                return getters[this.storePath + "/loading"];
            },
            periodsOptions(state, getters) {
                return getters[this.storePath + "/periodsOptions"];
            },
            dates(state,getters) {
                return getDeep(
                    state,
                    this.storePath + "/dates"
                );
            },
            chartData(state,getters) {
                return getDeep(
                    state,
                    this.storePath + "/data"
                );
            }
        }),
        currentStructureId: {
            get() {
              return getDeep(
                this.$store.state,
                this.storePath + "/currentStructureId"
              );
            },
            set(value) {
              this.$store.commit(
                this.storePath + "/selectStructure",
                value
              );
            },
        },
        currentSolutionId: {
            get() {
              return getDeep(
                this.$store.state,
                this.storePath + "/currentSolutionId"
              );
            },
            set(value) {
              this.$store.commit(
                this.storePath + "/selectSolution",
                value
              );
            },
        },
        currentPeriodIndex: {
            get() {
                return getDeep(
                    this.$store.state,
                    this.storePath + "/currentPeriodIndex"
                )
            },
            set(value) {
                this.$store.commit(
                    this.storePath + "/selectPeriod",
                    value
                )
            }
        },

        chartSeries() {
          let series = [
            { values: this.chartData.ok, label: this.$i18n.t("Ok"), color: colors.ok },
            { values: this.chartData.poor, label: this.$i18n.t("Poor"), color: colors.poor },
            { values: this.chartData.problem, label: this.$i18n.t("Problem"), color: colors.problem },
          ]

          return series
        },
        chartLabels() {
          let labels = [
            { labels: this.dates }
          ]

          return labels
        },
    },
    methods: {
        ...mapActions({
           fetchData(dispatch, payload) {
                return dispatch(
                  this.storePath + "/fetchData",
                  payload
                );
            } 
        }),
        updateData() {
            if(!this.disableDataFetch) {
                this.fetchData();
            }
            this.$emit('valid', {
                ...this.extractConfig()
            });
        },
        extractConfig() {
            return {
                structure_id: this.currentStructureId,
                solution_id: this.currentSolutionId,
            };
        },
        // For Chart
        initializeSeries(dataValuesArray, color, title) {
            return {
              values: dataValuesArray,
              text: title,
              "line-color": color,
              aspect: "spline",
              "background-color": `${color} none`,
              "alpha-area": ".4",
              marker: {
                "background-color": color,
                size: 7,
                "border-color": "#fff",
                "border-width": 3
              },
              "legend-marker": {
                "background-color": color
              }
            };
        },
        xAxisTransformObjectHandler(datesArray) {
            const daysDiff = this.getDifferenceInDays(
              new Date(datesArray[0]),
              new Date(datesArray[datesArray.length - 1])
            );
            if (daysDiff > 2) {
              return {
                transform: {
                  type: "date",
                  all: "%Y-%mm-%d",
                  item: {
                    visible: false
                  }
                }
              };
            }
            return {
              transform: {
                type: "date",
                all: "%H:%i",
                item: {
                  visible: false
                }
              }
            };
        },
        getDifferenceInDays(date1, date2) {
            const diffInMs = Math.abs(date2 - date1);
            return diffInMs / (1000 * 60 * 60 * 24);
        },
    },
    mounted() {
        if(this.data) {
            if(this.data.structure_id) {
                this.currentStructureId = +this.data.structure_id;
            }
            if(this.data.solution_id) {
                this.currentSolutionId = +this.data.solution_id;
            }
            if(this.data.period) {
                switch(this.data.period) {
                    case 'week':
                        this.currentPeriodIndex = 1;
                        break;
                    case 'month':
                        this.currentPeriodIndex = 5;
                        break;
                    case 'quarter': 
                        this.currentPeriodIndex = 6;
                        break;
                    case 'year': 
                        this.currentPeriodIndex = 7;
                        break;
                }
            }
        }
        this.updateData();
    },
    watch: {
        disableDataFetch() {
            this.updateData();
        },
        currentStructureId() {
            let oldSolution = this.currentSolutionId;
            this.currentSolutionId = this.solutionsOptions[0].value;
            if(this.data && this.data.solution_id) {
                this.solutionsOptions.forEach(sol => {
                    if(this.data.solution_id == sol.value) {
                        this.currentSolutionId = sol.value;
                    }
                })
            }
            if(oldSolution == this.currentSolutionId) {
                this.updateData();
            }
        },
        currentSolutionId() {
            this.updateData();
        },
        currentPeriodIndex() {
            this.updateData();
        },
        data: {
            handler() {
                if(this.data.structure_id) {
                    this.currentStructureId = +this.data.structure_id;
                }
                if(this.data.solution_id) {
                    this.currentSolutionId = +this.data.solution_id;
                }
                if(this.data.period) {
                    switch(this.data.period) {
                        case 'week':
                            this.currentPeriodIndex = 1;
                            break;
                        case 'month':
                            this.currentPeriodIndex = 5;
                            break;
                        case 'quarter': 
                            this.currentPeriodIndex = 6;
                            break;
                        case 'year': 
                            this.currentPeriodIndex = 7;
                            break;
                    }
                }
            },
            deep:true
        }
    }
}
</script>

<style>

</style>