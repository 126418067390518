<template>
  <div class="sidebar background-3">
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <ul class="nav nav-header">
        <li class="d-flex justify-content-center align-items-center">
          <router-link to="/" class="display-on-hover simple-text logo-mini">
            <!-- <moro-logo v-if="isMoro" /> -->
            <!-- <img v-else :src="appLogo" alt="Logo" class="nav-logo"> -->
          </router-link>
        </li>
          <slot name="links-header"></slot>
      </ul>
      <slot></slot>
      <ul class="nav nav-body">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>

<script>
import MoroLogo from '../moro/MoroLogo';

  export default {
    name: 'sidebar',
    props: {
      shortTitle: {
        type: String,
        default: 'CT',
        description: 'Sidebar short title'
      },
      sidebarLinks: {
        type: Array,
        default: () => [],
        description:
          "List of sidebar links as an array if you don't want to use components for these."
      },
      autoClose: {
        type: Boolean,
        default: true,
        description:
          'Whether sidebar should autoclose on mobile when clicking an item'
      }
    },
    components: {
      MoroLogo
    },
    data() {
      return {
        appLogo: process.env.VUE_APP_LOGO,
        // isMoro: process.env.VUE_APP_TITLE.toLowerCase().includes('moro')
      };
    },
    provide() {
      return {
        autoClose: this.autoClose
      };
    },
    methods: {
      minimizeSidebar() {
        if (this.$sidebar) {
          this.$sidebar.toggleMinimize();
        }
      }
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false;
      }
    }
  };
</script>

<style scoped lang="scss">
  @media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
      display: none;
    }
  }

  .sidebar {
    top: 10px;
    left: 0;
    margin: 0;
    height: 100%;
  }

  .rtl .sidebar {
    margin-right: 0;
  }

  /*work for chrome */
  ::-webkit-scrollbar {
    width: 7px !important;
  }

  .sidebar-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 20px;
    background-color: #0000005c;
  }

  .sidebar-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent !important;
  }

  .sidebar-wrapper {
    margin: 30px 0;
    scrollbar-color: #05080a #C2D2E4;
    scrollbar-width: thin;
  }

  .nav-header {
    margin-bottom: 20px;
    padding-inline-start: 0;
  }

  .f-20 {
    font-size: 20px;
  }

  .nav-body {
    padding-inline-start: 0;
    margin-bottom: 60px;
  }

  .nav-logo {
    height: 50px;
    width: 110px;
  }
</style>
