<template>
  <card class="shadow-2 h-100 radius-2">
    <div class="row">
      <h4 class="font-weight-bold card-title col-12">{{ $t('alarmsPerformance') }}</h4>
    </div>
    <div class="d-flex" style="height: 93%;">
      <div class="d-flex flex-column justify-content-around">
        <operationalAlarmsDetails
          v-for="(item, i) in performanceData"
          :key="i"
          :data="item"
        />
      </div>
    </div>
  </card>
</template>

<script>
import operationalAlarmsDetails from "@/pages/SWAM/components/operational-alarms-details";
export default {
  components: {
    operationalAlarmsDetails,
  },
  props: {
    performanceData: {},
  },
};
</script>

<style>
</style>