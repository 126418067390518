import moment from 'moment';
import swal from "sweetalert2";
import i18n from "../i18n";
var timeoutID = null
export default {
  getBase64: (file) => {

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  },
  debounce: (fn, delay = 500, empty = 500) => {

    return function () {
      clearTimeout(timeoutID)
      var args = arguments;
      var that = this
      timeoutID = setTimeout(function () {
        fn.apply(that, args)
      }, args[0] == "" || !args[0] ? empty : delay)
    }
  },
  checkIdentityForUserList: (alluser, email, phone) => {
    for (let i = 0; i < alluser.length; i++) {
      for (let j = i + 1; j < alluser.length; j++) {
        if (
          alluser[i].email.toLowerCase().trim() ==
          alluser[j].email.toLowerCase().trim()
        ) {
          alert(
            "Error, Email : " +
            alluser[i].email +
            " is used in more than one user."
          );
          return true;
        }
        if (
          alluser[i].phone.toLowerCase().trim() ==
          alluser[j].phone.toLowerCase().trim()
        ) {
          alert(
            "Error, Phone : " +
            alluser[i].phone +
            " is used in more than one user."
          );
          return true;
        }
      }
      if (
        alluser[i].email.toLowerCase().trim() ==
        email.toLowerCase().trim()
      ) {
        alert(
          "Error, Email : " + alluser[i].email + " is used in main user."
        );
        return true;
      }
      if (
        alluser[i].phone.toLowerCase().trim() ==
        phone.toLowerCase().trim()
      ) {
        alert(
          "Error, Phone : " + alluser[i].phone + " is used in main user."
        );
        return true;
      }
      return false;
    }
  },
}
export const dateFormat = (date) => {
  let temp = moment(date);
  return temp.isValid() ? temp.format("YYYY-MM-DD HH:mm") : '';
}
export const getTime = (date) => {
  let temp = moment(date);
  return temp.isValid() ? temp.format("HH:mm") : '';
}
export const getDate = (date) => {
  let temp = moment(date);
  return temp.isValid() ? temp.format("YYYY-MM-DD") : '';
}
export const getDateString = (date) => {
  let current = new Date() , created = new Date(date);
  let seconds = Math.floor((current - created) / 1000);
  // check year
  var interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  // check month
  interval = seconds / 2592000;
  if (interval > 1) {
    return getDate(date);
  }
  // check day
  interval = seconds / 86400;
  if (interval > 1) {
    if(interval == 2)
    {
      return "yasterday";
    }
    return Math.floor(interval) + " days ago";
  }
  // check hours
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  // check minutes
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return "now";
}

const createTime = millis => new moment.duration(millis);

export const millisToReadable = (millis) => {
  let result = "";
  const oneSecondInMillis = 1000;
  const oneMinuteInMillis = 60000;
  const oneHourInMillis = 3.6e6;
  const oneDayInMillis = 8.64e7;
  const oneMonthMillis = 2.628e9;
  const oneYearInMillis = 3.154e10;
  if (typeof millis !== "number") return "0 ms";

  let time = createTime(millis);

  let years = Math.floor(time.asYears());
  millis = millis - years * oneYearInMillis;
  time = createTime(millis);

  let months = Math.floor(time.asMonths());
  millis = millis - months * oneMonthMillis;
  time = createTime(millis);

  let days = Math.floor(time.asDays());
  millis = millis - days * oneDayInMillis;
  time = createTime(millis);
  let hours = Math.floor(time.asHours());
  millis = millis - hours * oneHourInMillis;
  time = createTime(millis);
  if (years > 0) {
    result += ` ${years} year${years > 1? 's' : ''}`;
  }
  if (months > 0) {
    result += ` ${months} month${months > 1? 's' : ''}`;
  }
  if (days > 0) {
    result += ` ${days} day${days > 1? 's' : ''}`;
  }
  if (hours > 0) {
    result += ` ${hours} hour${hours > 1? 's' : ''}`;
  }
  
  return result;
};
export const getDurationString = (date, ext = 'left') => {
  let seconds = Math.floor(date / 1000);
  // check year
  var interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + ` years ${ext}`;
  }
  // check month
  interval = seconds / 2592000;
  if (interval > 1) {
    console.log(interval)
    return Math.floor(interval) + ` months ${ext}`;
  }
  // check day
  interval = seconds / 86400;
  if (interval > 1) {
    if(interval == 2)
    {
      return "yasterday";
    }
    return Math.floor(interval) + ` days ${ext}`;
  }
  // check hours
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ` hours ${ext}`;
  }
  // check minutes
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ` minutes ${ext}`;
  }
  return "now";
}

export const futureDate = (yearPipeMonth, date) => {

  let [year, month] = yearPipeMonth.split('|');

  let basedate = date ? date : Date.now();

  var currentDate = moment(basedate);
  var futureDate = moment(currentDate).add(year, 'Y').add(month, 'M');
  var futureDateEnd = moment(futureDate).endOf('month');

  if (currentDate.date() != futureDate.date() && futureDate.isSame(futureDateEnd.format('YYYY-MM-DD'))) {
    futureDate = futureDate.add(1, 'd');
  }

  return dateFormat(futureDate);



}

export const generateID = function (id, prefx) {
  var str = "" + id
  var pad = "00000"
  var ans = pad.substring(0, pad.length - str.length) + str

  if (prefx)
    return `${prefx.toUpperCase()}-${ans}`;
  else return ans;
}

export function hhmmss(secs) {
  if (!secs) return '';
  secs = parseInt(secs)
  var minutes = Math.floor(secs / 60);
  secs = secs % 60;
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
}
function pad(num) {
  return ("0" + num).slice(-2);
}

export function ReplaceSpecialChar(value) {
  return value.replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '');
}

export function WarningIndectorName(name) {
  if (name != 'ok') {
    return name[0].toUpperCase() + name.slice(1);
  } else {
    return name.toUpperCase();
  }
}

export const colors = {
  good: 'rgb(63, 172, 121)',
  poor: 'rgb(230, 162, 60)',
  ok: 'rgb(232, 217, 29)',
  problem: 'rgb(242, 57, 59)',
}

export function getSensorIcon(name) {
  let icon;
  try {
    icon = require(`@/assets/icons-2/sensors/${name.toUpperCase()}.svg`);
  } catch (e) {
    console.warn("ICON NOT FOUND!")
    icon = require("@/assets/Icons/iot.svg");
  }
  return icon;
}

export function batteryStatus(ranges, battery_percentage) {

  let ok = ranges.find((r) => r.battery_status == "ok");
  let poor = ranges.find((r) => r.battery_status == "poor");
  let problem = ranges.find((r) => r.battery_status == "problem");

  if (ok && poor && problem) {
    if (battery_percentage > ok.percent) {
      return colors.good;
    } else if (
      battery_percentage <= ok.percent &&
      battery_percentage > poor.percent
    ) {
      return colors.ok;
    } else if (
      battery_percentage <= poor.percent &&
      battery_percentage > problem.percent
    ) {
      return colors.poor;
    } else if (battery_percentage <= problem.percent) {
      return colors.problem;
    }
  } else {
    return colors.good;
  }
}

export function ResponseStatus(response_percentage) {
  if (response_percentage > 80) {
    return colors.good;
  } else if (response_percentage <= 80 && response_percentage > 60) {
    return colors.ok;
  } else if (response_percentage <= 60 && response_percentage > 40) {
    return colors.poor;
  } else if (response_percentage <= 40) {
    return colors.problem;
  }

}

export function getConfigGauge(ranges, stats, configs = {}) {
  const colors = {
    good: 'rgb(65, 181, 129)',
    poor: 'rgb(243, 165, 29)',
    ok: 'rgb(255, 218, 131)',
    problem: 'rgb(229, 58, 88)',
  }

  const minValue = Math.min(...(ranges || []).map((v) => v.start_range))
  const maxValue = Math.max(...(ranges || []).map((v) => v.end_range))
  const totalRange = Math.abs(maxValue - minValue)
  const sideRange = Math.ceil(totalRange / 10)

  const min = minValue - sideRange
  const max = maxValue + sideRange

  const maxStat = Math.max(stats.min, stats.avg, stats.max)
  const minStat = Math.min(stats.min, stats.avg, stats.max)


  const getPercent = (val, side) => {
    let from, to

    if (side == 'right') {
      from = maxValue
      to = maxStat
    } else {
      from = minStat
      to = minValue
    }

    val -= from
    to -= from

    return (val / to)
  }

  const getValue = (val) =>
    (val > maxValue) ? maxValue + (getPercent(val, 'right') * sideRange) :
      (val < minValue) ? min + (getPercent(val, 'left') * sideRange) :
        val
  const config = {
    backgroundColor: "transparent",
    type: "gauge",
    plotarea: {
      marginTop: 50,
    },
    scale: {
    },
    plot: {
      size: "100%",
      valueBox: {
        placement: "center",
        text: "",
        color: "white",
        borderRadius: "30%",
        padding: "0 10px",
        border: "none",
        fontSize: 200
      },

      backgroundColor: "#000000",
    },
    tooltip: {
      borderRadius: 150,
      text: "%t",
    },
    scaleR: {
      aperture: 210,
      minValue: min,
      maxValue: max,
      itemsOverlap: true,
      maxItems: 100000,
      center: {
        visible: false,
      },
      tick: {
        visible: false,
      },
      item: {
        offsetR: 0,
        stats: {
          min: 7,
          avg: 8,
          max: 8
        }
      },
      ring: {
        backgroundColor: "#344675",
        size: 15,
      },
    },
    series: [
      {
        values: [getValue(stats.min)],
        indicator: [
          8, -6, 80, 1, 0
        ],
        borderRadius: 0,
        borderWidth: 5,
        borderColor: 'gray',
        backgroundColor: 'none',
        size: "73%",
        csize: 8,
        text: stats.min ? stats.min.toString() : '',
        animation: {
          effect: 2,
          method: 1,
          sequence: 4,
          speed: 900,
        }
      },
      {
        values: [getValue(stats.avg)],
        indicator: [
          8, -6, 80, 1, 0
        ],
        borderRadius: 0,
        borderWidth: 5,
        borderColor: 'gray',
        backgroundColor: 'none',
        size: "73%",
        csize: 8,
        text: stats.avg ? stats.avg.toString() : '',
        animation: {
          effect: 2,
          method: 1,
          sequence: 4,
          speed: 900,
        }
      },
      {
        values: [getValue(stats.max)],
        indicator: [
          8, -6, 80, 1, 0
        ],
        borderRadius: 0,
        borderWidth: 5,
        borderColor: 'gray',
        backgroundColor: 'none',
        size: "73%",
        csize: 8,
        text: stats.max ? stats.max.toString() : '',
        animation: {
          effect: 2,
          method: 1,
          sequence: 4,
          speed: 900,
        }
      }
    ],
  };

  const good = (ranges || []).find((s) => s.status == "good" || s.status == "default")

  if (good) {
    config.scaleR.ring.rules = ranges.map(r => {
      return {
        rule: ` %v >= ${r.start_range} && %v < ${r.end_range}`,
        backgroundColor: colors[r.status]
      };
    });
    config.scaleR.ring.rules.unshift(
      {
        rule: `%v < ${minValue}`,
        backgroundColor: '#7E1527'
      },
    );
    config.scaleR.ring.rules.push(
      {
        rule: `%v >= ${maxValue}`,
        backgroundColor: '#7E1527'
      },
    );

    config.scaleR.item = {
      offsetR: 0,
      rules: [
        {
          rule: `%v > ${maxValue} && %v != ${max}`,
          visible: false
        },
        {
          rule: `%v < ${minValue} && %v != ${min}`,
          visible: false
        },
        {
          rule: `%v == ${min}`,
          text: "Out Of Range",
          offsetX: 60,
          visible: true,
          offsetY: 7
          // angle:-90, offsetY:-70,fontSize:15
        },
        {
          rule: `%v == ${max}`,
          text: "Out Of Range",
          offsetX: -60,
          visible: true,
          offsetY: 7
          // angle:90, offsetY:-70,fontSize:15
        },
      ],
    };
    let rule = `%v != ${minValue}`;
    ranges.forEach(r => {
      rule += ` && %v != ${r.end_range}`
    })

    config.scaleR.item.rules.push(
      {
        rule,
        visible: false
      }
    )
  }

  update(config, configs)

  return config

  function update(obj, newObj) {
    for (let prop in newObj) {
      let val = newObj[prop]

      if (typeof val == "object")
        update(obj[prop], val)
      else
        obj[prop] = val
    }
  }
}

export function getDifferenceWithHours(d1, d2) {
  var now = moment(d1); //todays date
  var end = moment(d2); // another date
  var duration = moment.duration(now.diff(end));
  return Math.abs(duration.asHours());
}

export function deleteHandler(data,callback) {
  swal({
    title: i18n.t("instructions.areYouSure"),
    text: i18n.t("instructions.noRevert"),
    type: i18n.t("warning"),
    showCancelButton: true,
    cancelButtonText: i18n.t("instructions.cancel"),
    confirmButtonClass: "btn btn-success btn-fill text-capitalize",
    cancelButtonClass: "btn btn-danger btn-fill text-capitalize",
    confirmButtonText: i18n.t("instructions.yesDelete"),
    buttonsStyling: false,
  }).then((result) => {
    if (result.value) {
      callback(data);
    }
  });
};

export function round(val) {
  return (Math.round(val * 100) / 100).toFixed(2);
};

export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
  }, {});
}

export function getDeep(obj,string,splitter = '/'){
  let result = obj;
  string.split(splitter).forEach(item=>{
    result = result[item];
  })
  return result;
}

export const snakeToCamelCase = (str) => str.replace(
  /([-_][a-z])/g,
  (group) => group.toUpperCase()
                  .replace('-', '')
                  .replace('_', '')
);
export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

/**
 * Returns background color according to percent value
 * [0, 40[   - red
 * [40, 60[  - orange
 * [60, 80[  - yellow
 * [80, 100] - green
 * 
 * @param {number} percent The desired percent value.
 * @return {String} The background color
 */
export function getPercentColor(percent) {
  if (typeof percent !== 'number') {
    percent = 0;
  }

  // make percent an integer number
  percent = Math.trunc(percent);
  
  // make background color inherit if the percent is not in range [0, 100]
  let res = 'inherit';
  if (percent < 0 || percent > 100) {
    return res;
  }

  if (percent < 25) {
    res = 'problem';
  } else if (percent < 50) {
    res = 'poor';
  } else if (percent < 65) {
    res = 'ok';
  } else {
    res = 'good';
  }
  return res;
}