<template>
  <div
    class="form-group"
    :class="{
      'input-group-focus': focused,
      'has-danger': $v.phone.$error || error || $slots.error,
      'has-success': !$v.phone.$error && !error && !$slots.error && touched,
      'has-label': label,
    }"
  >
    <slot name="label">
      <label v-if="label"> {{ label }} {{ required ? "*" : "" }} </label>
    </slot>
    <div class="mb-0">
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text"><i :class="addonLeftIcon"></i></div>
        </span>
      </slot>
      <slot>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <el-select
              v-model="value"
              filterable
              placeholder="Call Code"
              class="select-primary"
              style="width: 120px"
            >
              <el-option
                v-for="(item, index) in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <span style="float: left">{{
                  callCodes[index].call_code
                }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  callCodes[index].short_code
                }}</span>
              </el-option>
            </el-select>
          </div>
          <input
            type="text"
            class="form-control"
            aria-label="Text input with dropdown button"
            v-model="$v.phone.$model"
            :placeholder="placeholder"
            @input="onInput"
            @focus="onFocus"
          />
        </div>
      </slot>
      <slot name="addonRight">
        <span v-if="addonRightIcon" class="input-group-append">
          <div class="input-group-text"><i :class="addonRightIcon"></i></div>
        </span>
      </slot>
    </div>

    <slot name="error" v-if="error || $slots.error">
      <label class="error">{{ error }}</label>
    </slot>

    <label
      class="error"
      v-if="touched && !$v.phone.isValidPhone && !$slots.error"
      >{{ $t("validations.Not valid phone number") }}</label
    >

    <slot name="helperText"></slot>
  </div>
</template>

<script>
import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
  name: "phone-input",
  props: {
    countryId: {},
    required: Boolean,
    label: {
      type: String,
      description: "Input label",
    },
    error: {
      type: String,
      description: "Input error",
      default: "",
    },

    addonRightIcon: {
      type: String,
      description: "Input icon on the right",
    },
    addonLeftIcon: {
      type: String,
      description: "Input icon on the left",
    },
    placeholder: {
      type: String,
      default: "Phone number",
    },
    disabled: {},
    autocomplete: {},
  },

  validations: {
    phone: {
      isValidPhone: function (value) {
        let phoneNumber = parsePhoneNumberFromString(
          "+" + this.callCodes.find((c) => c.id == this.value).call_code + value
        );
        if (phoneNumber) {
          return phoneNumber.isValid();
        }
        return false;
      },
    },
  },
  data() {
    return {
      focused: false,
      touched: false,
      options: [],
      callCodes: [],
      phoneValid: false,
      phone: "",
      value: {
        type: [String, Number],
        description: "Input value",
      },
    };
  },

  methods: {
    onInput(evt) {
      this.phone = evt.target.value.replace(/[^\d]/g, "");
      if (!this.touched) {
        this.touched = true;
      }
      if (!this.$v.$invalid) {
        this.$emit(
          "input",
          "+" +
            this.callCodes.find((c) => c.id == this.value).call_code +
            this.phone
        );
        this.phoneValid = true;
      } else {
        this.$emit(
          "input",
          "+" +
            this.callCodes.find((c) => c.id == this.value).call_code +
            this.phone
        );
        this.phoneValid = false;
      }
      this.$emit("phoneValid", this.phoneValid);
    },

    onFocus(evt) {
      this.focused = true;
    },
  },

  created() {
    this.$store
      .dispatch("CountriesAndCitiesStore/getCallCodes")
      .then((callCodes) => {
        // this.options = callCodes;
        this.callCodes = callCodes;
        this.options = callCodes.map((code) => {
          return {
            value: "" + code.id,
            label: code.call_code + " " + code.short_code,
          };
        });

        this.value = "" + callCodes.find((op) => op.short_code === "AE").id;
      });
  },

  watch: {
    countryId: {
      handler() {
        if (this.options.length > 0) {
          let option;
          option = this.options.find((op) => op.value == "" + this.countryId)
            .value;
          this.value = option ? option : null;
        }
      },
    },
  },
};
</script>

<style>
.form-group.has-success .input-group-prepend .el-input__inner {
  border-color: #00bf9a !important;
}
.form-group.has-success .form-control {
  border-color: #00bf9a !important;
}
.form-group.input-group-focus .input-group-prepend .el-input__inner {
  border-color: #00bf9a;
}
</style>
