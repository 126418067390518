import SwamService from './swam.service';

export default {
    async list(query) {
      const res = await SwamService.getSensorTemplatesList(query);

      return {
          data: res.data.data,
          total_records: res.data.total_records
      };
    },
    async find({id,query}) {
      const res = await SwamService.getSensorTemplateById(id, query)
      
      return {
        data: res.data.data,
      };
    }
  };
  