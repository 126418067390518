export const defaultConfigurations = {
    type: "area",
    backgroundColor: "transparent",
    series: [],
    legend: {
      backgroundColor: "none",
      borderWidth: "0px",
      item: {
        fontColor: "#808080"
      }
    },
    plot: {},
    plotarea: {},
    scaleX: {
      zooming: true,
  
      values: [],
      transform: {
        type: "date",
        all: "%M<br>%d",
        item: {
          visible: false
        }
      },
      "items-overlap": true
    },
    scaleX2: {
      zooming: true,
  
      values: [],
      transform: {
        type: "date",
        all: "%M<br>%d",
        item: {
          visible: false
        }
      },
      "items-overlap": true
    }
  };