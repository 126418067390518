import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import { M } from "../../Permissions";

const ReleaseList = () =>
  import('./ReleaseList.vue');


const ReleaseView = () =>
  import('./ReleaseView.vue');

const CreateRelease = () =>
  import('./CreateRelease.vue');

export default {
  path: '/release',
  component: DashboardLayout,
  redirect: '/release/list',
  name: 'release',
  meta: {
    module: M.release
  },
  children: [
    {
      path: 'list',
      name: 'Release List',
      component: ReleaseList
    },
    {
      path: 'view/:id',
      name: 'Release Changes',
      component: ReleaseView
    },
    {
      path: 'update/:id',
      name: 'Update Release',
      component: CreateRelease
    },
    {
      path: 'create',
      name: 'Create Release',
      component: CreateRelease
    },
  ]
}
