import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import { M } from "../../Permissions";


const TemplateList = () =>
  import('./TemplateList.vue');

const CreateTemplate = () =>
  import('./CreateTemplate.vue');

const TemplateView = () =>
  import('./TemplateView.vue');



export default {
  path: '/template',
  component: DashboardLayout,
  redirect: '/template/list',
  name: 'template',
  meta: {
    module: M.templates
  },
  children: [
    {
      path: 'list',
      name: 'Template List',
      component: TemplateList
    },

    {

      path: 'create',
      name: 'Template Create',
      component: CreateTemplate
    },
    {
      path: 'update/:id/:category',
      name: 'Template Update',
      component: CreateTemplate
    },
    {
      path: 'view/:id',
      name: 'Template View',
      component: TemplateView
    },

  ]
}
