<template>
  <div>
    <div class="cardView row font-weight-bold">
        <h6 v-if="!link" class="m-0 font-weight-bold font-color-4 col-9 p-0">
          {{ label }}
        </h6>
        <router-link
          :to="link"
          class="clickable m-0 font-weight-bold font-color-4 col-9 p-0 h6"
          v-else
        >
          {{ label }}
        </router-link>
        <h6 class="m-0 font-weight-bold font-color-5 col-2 p-0">
          {{ value }}
        </h6>
    </div>
    <hr class="m-0" style="border-top-width: 2px"/>
  </div>
</template>

<script>
export default {
    props: {
        label:{
            type:String,
            default: '',
        },
        value: {
            default: 0,
        },
        link: {
          type: String,
          default: ''
        }
    },
}
</script>

<style scoped lang="scss">
div.cardView{
  padding: 13px 5px;
  align-items: center;
  justify-content: space-between;
  h6 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>