import StructurePortfolio from "../pages/Structure-Portfolio/store";
import OperationalAlarms from "../pages/Operational-Alarms/store";
import StatusAlarms from "../pages/Status-Alarms/store";
import AlarmsHistory from "../pages/Alarms-History/store";
import DevicesList from "../pages/Devices-List/store";
import SensorsTemplates from "../pages/Sensor-Templates/store";
import generateInitalStore from "@/store/StoreTemplate";
import StructuresDataService from "../services/StructuresDataService";
import NotificationsTemplate from "../pages/Notifications-Template/store";
import SolutionPortfolio from "../pages/Solution-Portfolio/store";
const initalStore = generateInitalStore({
  dataProvider: StructuresDataService
});

const extraGetters = {
  haveDevices: state => {
    const queryId = `ITEMS_${undefined}`;
    if (!state.cache.queries[queryId]) return [];
    return state.cache.queries[queryId].response.data
      .map(id => state.byId[id])
      .filter(s => s.device_count);
  },
  solutions: (state, getters) => {
    const solutions = {};
    getters.haveDevices.forEach(struc => {
      struc.solutions.forEach(s => {
        if (!solutions[`${s.id}`]) {
          solutions[`${s.id}`] = {
            name: s.name,
            devices: s.devices,
            sensors: [...s.sensors],
            structuresIds: [struc.id]
          };
        } else {
          solutions[`${s.id}`].devices += s.devices;
          solutions[`${s.id}`].sensors.push(
            ...s.sensors.filter(
              sensor =>
                !solutions[`${s.id}`].sensors.find(
                  sensor1 => sensor1.id == sensor.id
                )
            )
          );
          solutions[`${s.id}`].structuresIds.push(struc.id);
        }
      });
    });
    return solutions;
  },
  communities: (state, getters) => {
    const communities = {};
    getters.haveDevices.forEach(s => {
      if (!communities[s.community_en]) {
        communities[s.community_en] = [s];
      } else {
        communities[s.community_en].push(s);
      }
    });
    return communities;
  },
  cities: (state, getters) => {
    const cities = {};
    getters.haveDevices.forEach(s => {
      if (!cities[s.city]) {
        cities[s.city] = [s];
      } else {
        cities[s.city].push(s);
      }
    });
    return cities;
  },
  solutionsOptions: (state, getters) => {
    let options = Object.keys(getters.solutions).map(s_id => ({
      value: +s_id,
      label: getters.solutions[s_id].name,
      structuresIds: getters.solutions[s_id].structuresIds
    }));
    return [{ label: "All" }, ...options];
  },
  structuresOptions: (state, getters) => {
    let options = getters.haveDevices.map(s => ({
      value: +s.id,
      label: s.structure_name
    }));
    return [{ label: "All" }, ...options];
  },
  communitiesOptions: (state, getters) => {
    let areas = Object.keys(getters.communities).map(communityName => {
      return {
        label: communityName,
        value: getters.communities[communityName][0].community
      };
    });
    return [{ label: "All" }, ...areas];
  },
  citiesOptions: (state, getters) => {
    let cities = Object.keys(getters.cities).map(cityName => {
      return { label: cityName, value: getters.cities[cityName][0].city_id };
    });
    return [{ label: "All" }, ...cities];
  },
  structuresForSolution: (state, getters) => solutionId => {
    let structures = getters.haveDevices.filter(s =>
      getters.solutionsOptions.some(
        sol =>
          sol.value &&
          sol.value == solutionId &&
          sol.structuresIds.some(id => id == s.id)
      )
    );
    return structures;
  },
  solutionsForStructure: (state, getters) => structureId => {
    let solutions = getters.solutionsOptions.filter(s => s.value);
    solutions.forEach(s => {
      s.value = +s.value;
    });
    if (!structureId) return solutions;
    return solutions.filter(s =>
      s.structuresIds.some(stId => stId == structureId)
    );
  },
  CustomSensors: (state, getters) => (structureId, solutionId) => {
    return getters.item(structureId).solutions.find(sol => sol.id == solutionId)
      .sensors;
  },
  totalDevices: (state, getters) => structureId => {
    let result = 0;
    getters.haveDevices.forEach(structure => {
      if (!structureId || structure.id == structureId) {
        structure.solutions.forEach(sol => {
          result += sol.devices;
        });
      }
    });
    return result;
  }
};

initalStore.getters = { ...initalStore.getters, ...extraGetters };
initalStore.modules = {
  StructurePortfolio,
  OperationalAlarms,
  StatusAlarms,
  AlarmsHistory,
  DevicesList,
  SensorsTemplates,
  NotificationsTemplate,
  SolutionPortfolio
};
initalStore.namespaced = true;

export default initalStore;
