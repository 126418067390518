import {applyUniqueIdToAllObjectKeys} from "../../../../util/unique-id-generator";

let types = {
    ALARMS_GETTER: 'ALARMS_GETTER',
    ALARMS_HISTORY_GETTER: 'ALARMS_HISTORY_GETTER',
    OPERATIONAL_ALARMS_TABLE_COLUMNS_GETTER: 'OPERATIONAL_ALARMS_TABLE_COLUMNS_GETTER',
    OPERATIONAL_ALARMS_HISTORY_TABLE_COLUMNS_GETTER: 'OPERATIONAL_ALARMS_HISTORY_TABLE_COLUMNS_GETTER',
    ALARMS_TOTAL_RECORDS_GETTER: 'ALARMS_TOTAL_RECORDS_GETTER',
    CUSTOMER_TABLE_COLUMNS_GETTER: 'CUSTOMER_TABLE_COLUMNS_GETTER',
    CUSTOMER_LIST_GETTER: 'CUSTOMER_LIST_GETTER',
    CUSTOMER_TOTAL_RECORDS_GETTER: 'CUSTOMER_TOTAL_RECORDS_GETTER',
    CUSTOMER_LOCATIONS_ARRAY_GETTER: 'CUSTOMER_LOCATIONS_ARRAY_GETTER',
    CUSTOMER_CONTACTS_LIST_GETTER: 'CUSTOMER_CONTACTS_LIST_GETTER',
    OPEN_ACKNOWLEDGEMENT_MODAL_GETTER: 'OPEN_ACKNOWLEDGEMENT_MODAL_GETTER',
    OPEN_COMMENTS_MODAL_GETTER: 'OPEN_COMMENTS_MODAL_GETTER',
    OPEN_TERMINATE_MODAL_GETTER: 'OPEN_TERMINATE_MODAL_GETTER',

    COMMENTS_USERS_GETTER: 'COMMENTS_USERS_GETTER',
    COMMENTS_LIST_GETTER: 'COMMENTS_LIST_GETTER',
    OPEN_ESCALATE_MODAL_GETTER: 'OPEN_ESCALATE_MODAL_GETTER',
    DEVICES_COMPANIES_GETTER: 'DEVICES_COMPANIES_GETTER',
    ALARMS_STATISTICS_GETTER: 'ALARMS_STATISTICS_ACTION',
    HISTORY_ALARMS_TOTAL_RECORDS_GETTER: 'HISTORY_ALARMS_TOTAL_RECORDS_GETTER',

    CUSTOMER_INFORMATION_GETTER:'CUSTOMER_INFORMATION_GETTER'
};


types = applyUniqueIdToAllObjectKeys(types);
export default types;
