



import { getters } from "./main/getters";
import { actions } from "./main/actions";
import { mutations } from './main/mutations';
import {initialState} from "./main/initial-state";


const state = initialState();


export const IntrusionStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
