import { defaultConfigurations } from "./charts-configurations.bundle";

/**
 * 
 * @param {any} value 
 */
const _isPrimitive = value => typeof value !== Object(value);

/**
 * @param {Object} obj
 * @returns {Object<obj>} cloned object
 * The will clones the params obj using json tech
 */
export const deepCloningObjectsHandler = (obj) => JSON.parse(JSON.stringify(obj))


/**
 * 
 * @param {ZingChart Configuration Object} source 
 * @param {ZingChart Configuration Object} target
 * @description 
 * 
 * it copies the configuration from target to source, in case some conifg not in target, the source's one will remain
 * 
 * for example:
 * 
 * let source = {
 *    'scale-x': {},
 *    'plot': {
 *      activeArea: true,
        alphaArea: 0.4,
        aspect: "spline",
 *     },
 * }
 * 
 * 
 * and let target = {
 *    'plot': {
 *      activeArea: false,
        alphaArea: 0,
 *     }
 * }
 * 
 * the output is
 * 
 * {
 *    'scale-x': {},
 *    'plot': {
 *      activeArea: false,
 *      alphaArea: 0,
 *      aspect: "spline",
 *    }
 * }
 */
export const mergeObjectsDeeply = (source, target) => {
  for (let [key, value] of Object.entries(target)) {
    if (_isPrimitive(value)) {
      source[key] = value;
    } else {
      if (!(key in source)) {
        source[key] = {};
      }
      mergeObjectsDeeply(source[key], target[key]);
    }
  }
  return source;
};


/**
 * 
 * @param {Object} config
 * @returns {ZingChart Configuration Object}
 * @description
 * This will map the input config to zingchart configurations and return it back
 */
export const getChartConfigurations = (config) => {
  try {
    const clonedConfigurations = deepCloningObjectsHandler(defaultConfigurations);
    mergeObjectsDeeply(clonedConfigurations, config);
    return clonedConfigurations;
  } catch(e) {
    console.log('Some error occured while cloning object [chart-generator.js]');
    return config;
  }
};


/**
 * 
 * @param {Object} chartConfigurations 
 * @param {Object} dataToSupply 
 */
export const getChartConfigurationsWithData = (chartConfigurations, dataToSupply) => {


  try {
    let defaultClonedConfigurations = deepCloningObjectsHandler(defaultConfigurations);
    let chartClonedConfigurations = deepCloningObjectsHandler(chartConfigurations);

    mergeObjectsDeeply(chartClonedConfigurations, dataToSupply);
    mergeObjectsDeeply(defaultClonedConfigurations, chartClonedConfigurations);

    return defaultClonedConfigurations;
  } catch(e) {
    console.log(e)
    return {};
  }
  

};