import {
  dateTableCellFomatter,
  emptyTableCellFormatter
} from "@/modules/common/utils/helper";
import i18n from '@/i18n'
import SwamService from '@/pages/SWAM/services/swam.service';

export default {
  namespaced: true,
  state: {
    alarms: {
      status: [],
      operational: [],
      total_records: 0
    },
    loading: false,
    pagination: {
      limit: 10,
      offset: 0,
    },
    filter:{},
    columns: [
      {
        prop:"alarm.id",
        label:i18n.t("alarmID"),
        minWidth:"100",
      },
      {
        prop:"device.id",
        label:i18n.t("deviceWords.device"),
        minWidth:"200",
        customTemplate: true
      },
      {
        prop:"alarm.created_at",
        label:i18n.t("receiveTime"),
        minWidth:"150",
        formatter: dateTableCellFomatter
      },
      {
        prop:"event.created_at",
        label:i18n.t("eventTime"),
        minWidth:"150",
        formatter: dateTableCellFomatter
      },
      {
        prop:"alarm.ended_at",
        label:i18n.t("normalizationTime"),
        minWidth:"150",
        formatter: dateTableCellFomatter
      },
      {
        prop:"event.type",
        label:i18n.t("actionType"),
        minWidth:"150",
        formatter:(row, col, cellValue) => {
          return i18n.t(`alarmsActions.${cellValue}`);
        }
      },
      {
        prop:"event.user",
        label:i18n.t("theUser"),
        minWidth:"200",
        customTemplate: true
      },
      {
        prop:"event.escalated_to",
        label:i18n.t("escalation"),
        minWidth:"200",
        customTemplate: true
      },
      {
        prop:"event.comment",
        label:i18n.t("deviceWords.message"),
        minWidth:"250",
        formatter: emptyTableCellFormatter
      },
      {
        prop:"device.structure_name",
        label:i18n.t("deviceWords.location"),
        minWidth:"200",
      },
    ],
    operationalExtraColumns: [
      {
        prop:"alarm.status",
        label:i18n.t("sensorWords.sensor"),
        minWidth:"170",
        customTemplate: true
      }
    ],
    statusExtraColumns: [
      {
        prop:"alarm.status",
        label:i18n.t("status"),
        minWidth:"250",
        customTemplate: true
      }
    ]
  },
  getters: {
    statusAlarms(state) {
      return state.alarms.status;
    },
    operationalAlarms(state) {
      return state.alarms.operational;
    },
    totalRecords(state){
      return state.alarms.total_records;
    },
    loadingState(state) {
      return state.loading;
    },
    getCurrentFilter(state){
      return state.filter;
    },
    columns(state) {
      return state.columns;
    },
    operationalExtraColumns(state) {
      return state.operationalExtraColumns;
    },
    statusExtraColumns(state) {
      return state.statusExtraColumns;
    }
  },
  mutations: {
    setOperationalData(state, data) {
      state.alarms.operational = data.data;
      state.alarms.total_records = data.total_records || data.data.length;
      delete state.filter.limit;
      delete state.filter.offset;
    },
    setStatusData(state, data) {
      state.alarms.status = data.data;
      state.alarms.total_records = data.total_records || data.data.length;
      delete state.filter.limit;
      delete state.filter.offset;
    },
    startLoading(state) {
      state.loading = true;
    },
    finishLoading(state) {
      state.loading = false;
    },
    setPaginationData(state, data) {
      state.pagination = data;
    },
    setFilter(state,data){
      state.filter = data;
    }
  },
  actions: {
    async fetchOperationalData({ state, commit, dispatch }) {
      commit("startLoading")
      let params = {
        limit: state.pagination.limit,
        offset: state.pagination.offset,
        ...state.filter
      }
      try {
          const data = await SwamService.getAlarmsHistory('operational',params);
          commit('setOperationalData', data);
          commit("finishLoading")
      } catch (error) {
          commit("finishLoading")
          console.error(error);
      }
    },
    async fetchStatusData({ state, commit, dispatch }) {
      commit("startLoading")
      let params = {
        limit: state.pagination.limit,
        offset: state.pagination.offset,
        ...state.filter
      }
      try {
          const data = await SwamService.getAlarmsHistory('status',params);
          commit('setStatusData', data);
          commit("finishLoading")
      } catch (error) {
          commit("finishLoading")
          console.error(error);
      }
    },
  },
}

