

import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';

// Routes components
const OperationalAlarms = () => import( './components/operational-alarms.vue');
const CustomerList = () => import( './components/customer-list.vue');
const operationalAlarmsHistory = () => import( './components/operational-alarms-history.vue');
const CustomerInfo = ()=> import('./components/customer-info.vue');


export default {
    path: '/vsass',
    component: DashboardLayout,
    redirect:'/vsass/operational-alarms',
    name:'VSaaS SMB',
    children: [
        {
            path: 'operational-alarms',
            name:'operaional alarms',
            component: OperationalAlarms
        },

        {
            path: 'customer-list',
            name:'customer list',
            component: CustomerList
        },
        {
            path: 'customer-info/:id',
            name:'customer info',
            component: CustomerInfo
        },
        {
            path: 'operational-alarms-history',
            name:'vsaas operational alarms history',
            component: operationalAlarmsHistory
        },
    ]
}
