import api from '../../axiosWithDelimiterFile'
export default {
    namespaced: true,
    state:{
        countriesPromise:null,
        subscribers: 0
    },
    actions: {
        getCountries({state}) {
            let countries = localStorage.getItem('countries');
            if (countries) {
                try {
                    countries = JSON.parse(countries);
                    return Promise.resolve(countries);
                } catch (error) {
                    console.error(error);
                }
            }
            if(state.countriesPromise){
                state.subscribers++;
                console.log("new subscriber!")
                return state.countriesPromise.then(_=>{
                    state.subscribers--;
                    console.log("subscriber died!")
                    if(state.subscribers == 0){
                        console.log("promise died!")
                        state.countriesPromise = null;
                    }
                    return _; 
                });
            }
            state.countriesPromise =  api.get('general/countries')
                .then(res => {
                    localStorage.setItem('countries', JSON.stringify(res.data.data));
                    if(state.subscribers == 0){
                        state.countriesPromise = null;
                    }
                    return res.data.data;
                })
            return state.countriesPromise;
        },
        getCities(obj, countryId) {
            return api.get('general/cities', {
                params: {
                    country_id: countryId
                }
            }).then(res => {
                return res.data.data;
            });
        },
        getCallCodes({dispatch}){
            return dispatch("getCountries")
                .then(countries=>{
                    return countries.map(c=>{
                        return{
                            id:c.id,
                            call_code :c.call_code,
                            short_code:c.short_code
                        }
                    })
                })
        }
    }
}