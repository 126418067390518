var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canModule(_vm.M.alarms))?_c('sidebar-item',{attrs:{"link":{
    name: _vm.$t('sidebar.swam.swam'),
    cartLeft: true,
  }}},[(_vm.canModule(_vm.M.analysis))?_c('sidebar-item',{attrs:{"link":{
      name: 'portfolio',
      path: '/SWAM/Structure-Portfolio/overall',
    }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
      name: _vm.$t('alarmsWords.operationalAlarms'),
      path: '/SWAM/Alarms/operational',
    }}}),(_vm.canModule(_vm.M.status_alarms))?_c('sidebar-item',{attrs:{"link":{
      name: _vm.$t('deviceWords.statusAlarms'),
      path: '/SWAM/Alarms/status',
    }}}):_vm._e(),(_vm.canModule(_vm.M.iot))?_c('sidebar-item',{attrs:{"link":{ name: 'devices', path: '/SWAM/Devices/list' }}}):_vm._e(),(_vm.canModule(_vm.M.iot))?_c('sidebar-item',{attrs:{"link":{ name: 'analysis', path: '/SWAM/Structure-Portfolio/analysis' }}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }