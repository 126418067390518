<template>
  <div>
    <div v-if="type">
      <div class="col-12 col-lg-12">
        <div class="d-flex">
          <div class="parameter d-flex pr-4">
            <span class="param__label">{{$t('instructions.choosePeriodDate')}}:</span>
            <span>
              <base-select
                class="param-select"
                v-model="selectedType"
                :placeholder="$t('instructions.select')"
                :options="options"
                @input="empty"
              />
            </span>
          </div>
        </div>
      </div>
      <br />
      <div class="row" v-if="selectedType">
        <div class="col-md-6">
          <h3>{{$t('instructions.firstPeriod')}} :</h3>
        </div>
        <div class="col-md-6">
          <h3>{{$t('instructions.secondPeriod')}} :</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <span>
            <el-date-picker
              v-if="selectedType == 'Week'"
              @change="parsWeek()"
              v-model="date1.from"
              type="week"
              format="Week WW"
              :placeholder="$t('instructions.pickWeek')"
            ></el-date-picker>
          </span>
        </div>
        <div class="col-md-6">
          <span>
            <el-date-picker
              v-if="selectedType == 'Week'"
              @change="parsWeek()"
              v-model="date2.from"
              type="week"
              format="Week WW"
              :placeholder="$t('instructions.pickWeek')"
            ></el-date-picker>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <span>
            <el-date-picker
              v-if="selectedType == 'Month'"
              @change="parsMonth()"
              v-model="date1.from"
              type="month"
              :placeholder="$t('instructions.pickMonth')"
            ></el-date-picker>
          </span>
        </div>
        <div class="col-md-6">
          <span>
            <el-date-picker
              v-if="selectedType == 'Month'"
              @change="parsMonth()"
              v-model="date2.from"
              type="month"
              :placeholder="$t('instructions.pickMonth')"
            ></el-date-picker>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <span>
            <el-date-picker
              v-if="selectedType == 'Year'"
              @change="parsYear()"
              v-model="date1.from"
              type="year"
              :placeholder="$t('instructions.pickYear')"
            ></el-date-picker>
          </span>
        </div>
        <div class="col-md-6">
          <span>
            <el-date-picker
              v-if="selectedType == 'Year'"
              @change="parsYear()"
              v-model="date2.from"
              type="year"
              :placeholder="$t('instructions.pickYear')"
            ></el-date-picker>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <span>
            <el-date-picker
              v-if="selectedType == 'Day'"
              v-model="date1.from"
              @change="parsDay1()"
              type="date"
              :placeholder="$t('instructions.pickDay')"
            ></el-date-picker>
          </span>
        </div>
        <div class="col-md-6">
          <span>
            <el-date-picker
              v-if="selectedType == 'Day'"
              v-model="date2.from"
              @change="parsDay2()"
              type="date"
              :placeholder="$t('instructions.pickDay')"
            ></el-date-picker>
          </span>
        </div>
      </div>
      <div class="row" v-if="selectedType == 'Custom'">
        <div class="col-md-6">
          <span>
            <el-date-picker
              v-model="dateCostom"
              type="daterange"
              @change="customChange"
              align="right"
              :start-placeholder="$t('instructions.startDate')"
              :end-placeholder="$t('instructions.endDate')"
            ></el-date-picker>
          </span>
        </div>
        <div class="col-md-3">
          <span>
            <div :class="{'cursor-drop' :dateCostom.length == 0}">
              <el-date-picker
                v-model="From"
                @change="selectRange"
                :readonly="dateCostom.length == 0"
                type="date"
                :placeholder="$t('from')"
              ></el-date-picker>
            </div>
          </span>
        </div>
        <div class="col-md-3">
          <span>
            <div class="cursor-drop">
              <el-date-picker v-model="To" readonly type="date" :placeholder="$t('to')"></el-date-picker>
            </div>
          </span>
        </div>
      </div>
      <br />
    </div>
    <div v-else>
      <el-date-picker
        v-model="date"
        type="daterange"
        align="right"
        :start-placeholder="$t('instructions.startDate')"
        :end-placeholder="$t('instructions.endDate')"
      ></el-date-picker>
    </div>
    <br />
    <div align="center">
      <base-button @click="submit">{{$t('submit')}}</base-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["type"],
  data() {
    return {
      To: "",
      From: "",
      countOfDays: 0,
      dateCostom: [],
      date: [],
      date1: {
        from: "",
        to: "",
      },
      date2: {
        from: "",
        to: "",
      },
      selectedType: "",
      options: [
        { value: "Day", label: "Day" },
        { value: "Week", label: "Week" },
        { value: "Month", label: "Month" },
        { value: "Year", label: "Year" },
        { value: "Custom", label: "Custom" },
      ],
    };
  },
  watch: {
    date: function (newVal, oldVal) {
      this.date1.from = moment(newVal[0]);
      this.date1.to = moment(newVal[1]);
    },
  },
  methods: {
    customChange() {
      if (!this.dateCostom) {
        this.dateCostom = [];
      }
      this.date1.from = moment(this.dateCostom[0]).add(1, "days");
      this.date1.to = moment(this.dateCostom[1]).endOf("day");
      this.countOfDays = this.date1.to.diff(this.date1.from, "days");
      this.date2.from = "";
      this.date2.to = "";
    },
    selectRange() {
      this.date2.to = moment(this.From, "DD-MM-YYYY")
        .add(this.countOfDays + 1, "days")
        .endOf("day");
      this.To = moment(this.From, "DD-MM-YYYY").add(
        this.countOfDays + 1,
        "days"
      );
      this.date2.from = moment(this.From).add(1, "days");
    },
    parsDay1() {
      this.date1.from = moment(this.date1.from);
      this.date1.to = moment(this.date1.from).endOf("day");
    },
    parsDay2() {
      this.date2.from = moment(this.date2.from);
      this.date2.to = moment(this.date2.from).endOf("day");
    },
    parsWeek() {
      this.date1.from = moment(this.date1.from);
      this.date2.from = moment(this.date2.from);
      this.date1.to = moment(this.date1.from).endOf("week");
      this.date2.to = moment(this.date2.from).endOf("week");
    },
    parsYear() {
      this.date1.from = moment(this.date1.from);
      this.date2.from = moment(this.date2.from);
      this.date1.to = moment(this.date1.from).endOf("year");
      this.date2.to = moment(this.date2.from).endOf("year");
    },
    parsMonth() {
      this.date1.from = moment(this.date1.from);
      this.date2.from = moment(this.date2.from);
      this.date1.to = moment(this.date1.from).endOf("month");
      this.date2.to = moment(this.date2.from).endOf("month");
    },
    empty() {
      this.date1.from = "";
      this.date2.from = "";
      this.date1.to = "";
      this.date2.to = "";
      this.From = "";
      this.To = "";
      this.dateCostom = [];
    },
    submit() {
      this.DateSelected = true;
      //TODO: validate the data
      let val = -1;
      let customVal = 0;
      if (this.countOfDays == 1) {
        customVal = 48;
      } else if (this.countOfDays == 0) {
        customVal = 24;
      } else {
        customVal = this.countOfDays + 1;
      }
      switch (this.selectedType) {
        case "Day":
          val = 24;
          break;
        case "Week":
          val = 7;
          break;
        case "Month":
          val = 31;
          break;
        case "Year":
          val = 365;
          break;
        case "Custom":
          val = customVal;
          break;
      }
      //console.log("date1", this.date1);
      //console.log("date2", this.date2);
      //if data valid emit the submit event
      this.$emit("submit", [
        {
          from:()=> this.date1.from,
          to:()=>  this.date1.to,
          title: "first period",
          type: val,
        },
        {
          from:()=>  this.date2.from,
          to:()=>  this.date2.to,
          title: "second period",
          type: val,
        },
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.unit {
  font-size: 0.85rem;
}
.param__label {
  font-size: 1.4rem;
  letter-spacing: 1px;
  color: #c7c7c7;
  margin-right: 30px;
  margin-inline-end: 30px;
}

.white-content .param__label {
  color: #1d253b;
}
.param-select {
  width: 220px;
}
.comp-card-header__intervals {
  display: flex;
  margin-top: 1rem;
  align-items: center;
  .filter-tabs {
    width: 70%;
    max-width: 50vw;
  }
  .filter-month {
    width: 20%;
    max-width: 15vw;
    margin-bottom: 0;
    padding: 0 25px;
  }
}

::v-deep.status-types {
  margin-top: 5px !important;
}
::v-deep .my-dropdown button {
  background: transparent;
  outline: none;
  border: none;
}
.active {
  color: $primary !important;
}
.bullit {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block !important;
  margin: 0 3px;
}
.avg {
  background: $vue;
}
.max {
  background: $danger;
}
</style>
<style lang="scss">
.cursor-drop {
  input {
    cursor: no-drop;
  }
}
</style>
