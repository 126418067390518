<template>
  <div>
    <div class="row">
      <div class="col-12">
        <p v-if="UserData.teltonika_device" class="title">current assigned device:</p>
        <p v-else class="title">Available Devices:</p>
      </div>
    </div>
    <div class="row mt-3 table-header">
      <div class="col-2">
        <span class="key">id</span>
      </div>
      <div class="col-6">
        <span class="key">Name</span>
      </div>
      <div class="col-4" v-if="UserData.teltonika_device">
        <span class="key">since</span>
      </div>
    </div>
    <div class="row mt-3" v-if="UserData.teltonika_device">
      <div class="col-2">
        <span class="value">{{UserData.teltonika_device.id}}</span>
      </div>
      <div class="col-6">
        <span class="value">{{UserData.teltonika_device.device_name}}</span>
      </div>
      <div class="col-4">
        <span class="value">{{gDateFormat(UserData.teltonika_device.start)}}</span>
      </div>
    </div>
    <div class="row mt-3" v-else v-for="(item, index) in teltonikas" :key="index">
      <div class="col-2">
        <span class="value">{{item.id}}</span>
      </div>
      <div class="col-7">
        <span class="value">{{item.device_name}}</span>
      </div>
      <div class="col-2">
        <base-button type="info" size="sm" @click="assign(item)">Select</base-button>
      </div>
    </div>
    <div class="row mt-5 justify-content-center" v-if="UserData.teltonika_device">
      <base-button type="danger" @click="unassign">Unassigne This Device</base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["UserData", "teltonikas"]),
  },
  methods: {
    unassign() {
      this.$api
        .patch(
          `teltonika/devices/${this.UserData.teltonika_device.id}/assignTo/null`
        )
        .then((_) => {
          this.$store.commit("setUserData", {
            ...this.UserData,
            teltonika_device: null,
          });
          this.$store.dispatch("getTeltonikaDevices");
        })
        .catch(console.error);
    },
    assign(tel) {
      this.$api
        .patch(
          `teltonika/devices/${tel.id}/assignTo/${this.UserData.id}`
        )
        .then((_) => {
          this.$store.commit("setUserData", {
            ...this.UserData,
            teltonika_device: {
              ...tel,
              start: new Date(),
            },
          });
        })
        .catch(console.error);
    },
  },
};
</script>

<style scoped lang="scss">
.title,
.key,
.value {
  font-size: 1.3rem;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: capitalize;
}
.key {
  margin-top: 20px;
  color: $primary;
  padding: 0 5px;
}
.value {
  color: azure;
  font-size: 1rem;
}
.table-header {
  border-bottom: 1px solid white;
}
</style>