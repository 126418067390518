import api from "../../../../axiosWithDelimiterFile";
import { app } from "../../../../main";
import { dateFormat } from "@/util/helper";

export default {
    state: {
        selectedAlarm: null,
        currentAction: null,// comments || ack || escelate
        alarmComments: null,
        deviceCompanies: [],
    },
    getters: {
        currentAction(state) {
            return state.currentAction;
        },
        commentsUsers(state, getters, rootState, rootGetters) {
            if (!state.alarmComments) {
                return [];
            } else {
                const users = {
                    "null": {
                        id: null,
                        name: "System",
                        imageUrl: rootGetters.userPlaceholderImage,
                    }
                };
                state.alarmComments.forEach((c) => {
                    if (c.user && !users[c.user.id]) {
                        users[c.user.id] = {
                            id: c.user_id,
                            name: c.user.email,
                            imageUrl: c.user.img_path
                                ? c.user.img_path
                                : rootGetters.userPlaceholderImage,
                        };
                    }
                });
                return Object.keys(users).map((k) => {
                    return users[k];
                });
            }
        },
        comments(state, getters, rootState, rootGetters) {
            if (!state.alarmComments) {
                return [];
            } else {
                return state.alarmComments.filter(x=>x.comment).map((c) => {
                    return {
                        type: "text",
                        author: rootGetters.user.id == c.user_id ? "me" : c.user_id,
                        data: { text: c.comment, meta: dateFormat(c.created_at) },
                    };
                });
            }
        },
        deviceCompanies: (state) => state.deviceCompanies ? state.deviceCompanies : []
    },
    mutations: {
        openCommentsBox(state, data) {
            state.currentAction = "comments";
            state.selectedAlarm = data.alarm;
            state.alarmComments = data.comments;
        },
        closeCommentsBox(state) {
            state.currentAction = null;
            state.selectedAlarm = null;
            state.alarmComments = null;
        },
        addNewComment(state, data) {
            state.alarmComments.push(data);
        },
        openEscalateModal(state, data) {
            state.currentAction = "escelate";
            state.selectedAlarm = data.alarm;
            state.deviceCompanies = data.companies;
        },
        closeEscalateModal(state) {
            if (state.currentAction == "escelate") {
                state.currentAction = null;
                state.selectedAlarm = null;
            }
        },
        openAcknowledgmentModal(state, alarm) {
            state.currentAction = "ack";
            state.selectedAlarm = alarm;
        },
        closeAcknowledgmentModal(state) {
            if (state.currentAction == "ack") {
                state.currentAction = null;
                state.selectedAlarm = null;
            }
        }
    },
    actions: {
        openCommentsBox({ commit, dispatch }, alarm) {
            dispatch("getComments", alarm).then(res => {
                commit("openCommentsBox", {
                    alarm,
                    comments: res.data.data
                });
            })
        },
        closeCommentsBox({ commit, dispatch }) {
            commit("closeCommentsBox");
        },
        addComment({ dispatch, commit, state, rootState }, msg) {
            const msgText = msg.data.text;
            return dispatch("submitComment", { msgText, alarm: state.selectedAlarm })
                .then((res) => {
                    commit("addNewComment",
                        {
                            acknowledgement: null,
                            acknowledgement_id: null,
                            comment: msgText,
                            created_at: new Date(),
                            escalation: null,
                            escalation_id: null,
                            id: res.data.data,
                            operational_alarm_id: state.selectedAlarm.id,
                            status_alarm_id: state.selectedAlarm.id,
                            type: "comment",
                            user_id: rootState.user.id
                        })
                });
        },
        openEscalateModal({ commit, dispatch, state, rootState }, alarm) {
            return api.get(`/devices/${alarm.device_id}/companies`)
                .then(res => {
                    commit("openEscalateModal", { alarm, companies: res.data.data })
                })
        },
        closeEscalateModal({ commit, dispatch, state, rootState }, data) {
            if (!data) {
                commit("closeEscalateModal")
            } else {
                dispatch("escalate", {
                    data: {
                        comment: data.comment,
                        company_ids: data.deviceCompanies,
                    },
                    alarm: state.selectedAlarm
                }).then(_ => {
                    commit("closeEscalateModal")
                })
            }
        },
        openAcknowledgmentModal({ dispatch, commit, state, rootState, }, alarm) {
            commit("openAcknowledgmentModal", alarm);
        },
        closeAcknowledgmentModal({ dispatch,getters, commit, state, rootState }, data) {
            const alarm = state.selectedAlarm;
            if (!data) {
                commit("closeAcknowledgmentModal")
            } else {
                dispatch("acknowledge", {
                    alarm,
                    data: { comment: data.comment }
                }).then(_ => {
                    alarm.acknowledgement = {
                        created_at: new Date(),
                        email: rootState.user.email,
                        first_name: rootState.user.first_name,
                        last_name: rootState.user.last_name,
                        user_id: rootState.user.id,
                    }
                    if(alarm.ended_at){
                        const index = getters.alarms.indexOf(alarm);
                        getters.alarms.splice(index,1);
                    }
                    if (data.escalate) {
                        dispatch("openEscalateModal", alarm);
                    }
                    commit("closeAcknowledgmentModal");
                })
            }
        },
    }
}
