<template>
  <div :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
    <svg
      style="position: absolute;width: 0;height: 0;z-index: -10000;"
      aria-hidden="true"
      focusable="false"
    >
      <linearGradient
        id="success-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#00a0ec" />
        <stop offset="1" stop-color="#00ecc6" />
      </linearGradient>
      <linearGradient
        id="danger-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#fd5d93" />
        <stop offset="1" stop-color="#ec250d" />
      </linearGradient>
    </svg>
    <div v-if="blockSpinner" class="spinner-container">
      <!-- spinner -->
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      preferences: (state) => state.preferences,
    }),
    blockSpinner() {
      return this.$store.getters.blockSpinner;
    },
    
  },

  watch: {
    preferences: {
      handler(newval, oldval) {
        console.log("preference update", newval, oldval);
        if (newval) {
          let docClasses = document.body.classList;
          let dark_mode = this.preferences.dark_mode;
          let language = this.preferences.language;

          let locale = this.$i18n.locale;

          if (language != locale) {
            if (language == "en") {
              document
                .getElementsByTagName("html")[0]
                .setAttribute("lang", "en");
              localStorage.setItem("lang", "en");
              this.$i18n.locale = "en";
              this.$rtl.disableRTL();
            } else {
              localStorage.setItem("lang", "ar");
              document
                .getElementsByTagName("html")[0]
                .setAttribute("lang", "ar");
              this.$i18n.locale = "ar";
              this.$rtl.enableRTL();
            }
          }

          
          if (dark_mode) {
            docClasses.remove("white-content");
            this.$store.commit("changeThem", "dark");
          } else {
            docClasses.add("white-content");
            this.$store.commit("changeThem", "light");
          }
        }
      },
      deep: true,
    },
  },

  methods: {
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    },
  },
  mounted() {
    this.initializeLayout();
    
  },
};
</script>

<style>
.rtl .navbar-minimize button {
  margin-right: 10px;
}
.rtl .content .content {
  margin-top: -9rem !important;
  margin-right: -8rem;
  margin-left: -4rem;
}
.rtl .search-bar {
  margin-left: -25px;
}
.rtl .navbar .btn {
  margin: 2px 5px 0px 30px;
}
.rtl .el-table td,
.rtl .el-table th {
  text-align: right;
}
.spinner-container {
  position: absolute;
  z-index: 1000000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000b8;
  cursor: pointer;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 180px;
  height: 180px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 86px;
    left: 86px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 172px;
    height: 172px;
    opacity: 0;
  }
}

/*  */

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
<style lang="scss">
.el-progress__text {
  color: #fff !important;
}
.circle-problem {
  color: rgb(242, 57, 59);
}

.circle-poor {
  color: rgb(230, 162, 60);
}

.circle-ok {
  color: rgb(232, 217, 29);
}
.circle-good {
  color: rgb(63, 172, 121);
}

body { text-align: start; }
</style>


