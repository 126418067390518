<template>
  <div>
    <template v-if="user">
      <profile-picture
        :imgURL="userImagePath"
        :placeholderURL="'/img/placeholder.jpg'"
        :canEdit="canEdit"
        @deleteImg="deleteImage"
        @imageChanged="onImageChange"
      ></profile-picture>
      <span class="d-block text-center">
        <p class="user-name mb-0">
          <!-- {{user.first_name}} -->
          <input
            v-model="user.first_name"
            class="first-name"
            placeholder="--"
            :class="{
              'form-control': editingPersonalInfo,
              error: $v.user.first_name.$error && editingPersonalInfo,
            }"
            :disabled="!editingPersonalInfo"
          />
          <!-- {{user.last_name}} -->
          <input
            v-model="user.last_name"
            placeholder="--"
            class="last-name"
            :class="{
              'form-control': editingPersonalInfo,
              error: $v.user.last_name.$error && editingPersonalInfo,
            }"
            :disabled="!editingPersonalInfo"
          />
        </p>
      </span>
      <!-- </span> -->
      <p class="description">{{ $t("account.userRole") }} / {{ user.slug }}</p>
    </template>
    <div class="row" v-if="user">
      <div class="col-md-6">
        <card :bodyClasses="'d-flex flex-wrap'">
          <p slot="header" class="title w-100 justify-content-between">
            {{ $t("Personal Info") }}
            <template v-if="canEdit">
              <i
                v-if="!editingPersonalInfo"
                @click="editPersonalInfo"
                class="tim-icons icon-pencil float-right edit-icon"
              ></i>
              <span v-else class="w-25 d-flex justify-content-between">
                <i
                  @click="undoPersonalInfoChanging"
                  class="tim-icons icon-simple-remove"
                ></i>
                <i @click="savePersonalInfo" class="tim-icons icon-check-2"></i>
              </span>
            </template>
          </p>
          <span class="title">{{ $t("id") }} : </span>
          <span class="value id" v-if="isCompanyUser">{{ user.id }}</span>
          <span class="value id" v-else-if="isUserAdmin">{{
            $generateID(user.id, user.slug)
          }}</span>
          <span class="value id" v-else>{{ user.id }}</span>

          <span class="title">{{ $t("email") }}:</span>
          <input
            class="value"
            type="email"
            :class="{
              'form-control': editingPersonalInfo && canEditEmail,
              error: $v.user.email.$error && editingPersonalInfo,
            }"
            :disabled="!editingPersonalInfo || !canEditEmail"
            v-model.trim="user.email"
            placeholder="--"
          />

          <span class="title">{{ $t("phone") }} :</span>
          <!-- <input
            class="value"
            type="text"
            :class="{'form-control':editingPersonalInfo}"
            :disabled="!editingPersonalInfo"
            v-model.trim="user.phone"
            placeholder="--"
          />-->
          <span class="value">
            <div class="input-group mb-0">
              <div class="input-group-prepend" v-if="editingPersonalInfo">
                <span
                  class="input-group-text"
                  :class="{ error: $v.user.phone.$error }"
                  >{{ user.callCode }}</span
                >
              </div>
              <input
                class="value mb-0"
                type="text"
                :class="{
                  'form-control': editingPersonalInfo,
                  'w-100': !editingPersonalInfo,
                  error: $v.user.phone.$error && editingPersonalInfo,
                }"
                :disabled="!editingPersonalInfo"
                v-model.trim="user.phone"
                placeholder="--"
              />
            </div>
          </span>

          <span class="title">{{ $t("phone") }} 2 :</span>
          <!-- <input
            class="value"
            type="text"
            :class="{'form-control':editingPersonalInfo}"
            :disabled="!editingPersonalInfo"
            v-model.trim="user.phone2"
            placeholder="--"
          />-->
          <span class="value">
            <div class="input-group mb-0">
              <div class="input-group-prepend" v-if="editingPersonalInfo">
                <span
                  class="input-group-text"
                  :class="{ error: $v.user.phone2.$error }"
                  >{{ user.callCode }}</span
                >
              </div>
              <input
                class="value mb-0"
                type="text"
                :class="{
                  'form-control': editingPersonalInfo,
                  'w-100': !editingPersonalInfo,
                  error: $v.user.phone2.$error && editingPersonalInfo,
                }"
                :disabled="!editingPersonalInfo"
                v-model.trim="user.phone2"
                placeholder="--"
              />
            </div>
          </span>

          <span class="title">{{ $t("address") }} :</span>
          <input
            class="value"
            type="text"
            :class="{ 'form-control': editingPersonalInfo }"
            :disabled="!editingPersonalInfo"
            v-model.trim="user.address"
            placeholder="--"
          />

          <span class="title">{{ $t("account.billingEmail") }} :</span>
          <input
            class="value"
            type="email"
            :class="{
              'form-control': editingPersonalInfo,
              error: $v.user.billing_email.$error && editingPersonalInfo,
            }"
            :disabled="!editingPersonalInfo"
            v-model.trim="user.billing_email"
            placeholder="--"
          />

          <span class="title">{{ $t("account.notificationEmail") }} :</span>
          <input
            class="value"
            type="email"
            :class="{
              'form-control': editingPersonalInfo,
              error: $v.user.notifications_email.$error && editingPersonalInfo,
            }"
            :disabled="!editingPersonalInfo"
            v-model.trim="user.notifications_email"
            placeholder="--"
          />

          <span class="title">{{ $t("account.createdAt") }} :</span>
          <span class="value">{{ gDateFormat(user.created_at) }}</span>

          <span class="title">{{ $t("updatedAt") }} :</span>
          <span class="value">{{
            user.updated_at ? gDateFormat(user.updated_at) : "--"
          }}</span>
        </card>
      </div>
      <div
        class="col-12 col-md-6"
        v-if="user && user.info && !isUserSuperAdmin"
      >
        <card :bodyClasses="'d-flex flex-wrap'">
          <p slot="header" class="title">{{ $t("Platform Info") }}</p>
          <template v-if="isCompanyUser">
            <span class="title">{{ $t("company") }} :</span>
            <span
              class="value linkable"
              @click="openCompany(user.slug, user.info.id)"
              >{{ user.info.name }}</span
            >

            <span class="title">{{ $t("companyWords.companyID") }} :</span>
            <span class="value id">{{
              $generateID(user.info.id, user.slug)
            }}</span>
          </template>

          <span class="title">{{ $t("country") }} :</span>
          <span class="value">{{ getCountry(user.info.country) }}</span>

          <span class="title">{{ $t("city") }} :</span>
          <span class="value">{{ getCity(user.info.city) }}</span>

          <span v-if="isCompanyUser" class="title">{{ $t("address") }} :</span>
          <span
            v-if="isCompanyUser"
            class="value linkable"
            @click="openAddressMap"
            >{{ user.info.address }}</span
          >

          <!-- <span class="title">Created At :</span>
          <span class="value">{{gDateFormat(user.info.created_at)}}</span>

          <span class="title">Updated At :</span>
          <span class="value">{{user.info.updated_at?gDateFormat(user.info.updated_at):'--'}}</span>-->

          <template v-if="isCompanyUser">
            <span class="title">{{ $tc("admin", 2) }} :</span>
            <ul class="value">
              <li
                v-for="(admin, index) in user.info.admins"
                :key="index"
                @click="openUser(admin.id)"
                class="linkable"
                :class="{ creator: admin.id == user.info.parent_id }"
              >
                <span style="width: 35%">{{ $generateID(admin.id) }}</span>
                <span style="width: 65%">{{ admin.name }}</span>
              </li>
            </ul>

            <span class="title">{{ $t("account.type") }} :</span>
            <!-- <span class="value">{{user.type}}</span> -->
            <base-select
              class="value"
              :viewMode="!editingPersonalInfo || !isHisAdminOrSuperAdminOpening"
              :options="usersTypesOptions"
              v-model="user.type"
              :disabled="!editingPersonalInfo || !isHisAdminOrSuperAdminOpening"
            ></base-select>
          </template>

          <template v-if="isUserAdmin">
            <span class="title">Solutions :</span>
            <ul class="value">
              <li v-for="(solution, index) in user.solutions" :key="index">
                {{ solution.name }}
              </li>
            </ul>

            <span class="title">Group Name :</span>
            <template v-if="user.info.group_id">
              <span class="value">{{ user.group_name }}</span>
              <span class="title">In A Group With :</span>
              <ul class="value">
                <li
                  v-for="(admin, index) in user.group_admins"
                  @click="openUser(admin.id)"
                  :key="index"
                  class="linkable"
                >
                  <span style="width: 35%">{{ admin.id }}</span>
                  <span style="width: 65%">{{ admin.name }}</span>
                </li>
              </ul>
            </template>
            <span v-else class="value">--</span>
          </template>
        </card>
      </div>
      <div class="col-12 col-md-6">
        <card>
          <p slot="header" class="title">{{ $t("account.securityInfo") }}</p>

          <span class="title">{{ $t("account.lastLogin") }} :</span>
          <span class="value">{{ gDateFormat(user.last_login_date) }}</span>

          <span class="title">{{ $t("account.status") }} :</span>
          <span
            @click="openStatusDialog"
            class="value user-status"
            style="font-size: 1rem"
            :class="'user-status-' + user.status"
          >
            <i class="tim-icons icon-settings-gear-63 mb-1"></i>&nbsp;
            <span style="text-decoration: underline">{{
              $t(user.status)
            }}</span>
          </span>

          <span class="d-flex">
            <span class="title"
              >{{ $t("account.twoFactorAuthentication") }} :</span
            >
            <span
              class="value d-flex justify-content-center align-items-center"
            >
              <base-button
                @click="change2FA"
                :disabled="!canEdit2FA"
                :type="user.tfa_enable ? 'info' : 'warning'"
                >{{
                  user.tfa_enable ? $t("Enabled") : $t("Disabled")
                }}</base-button
              >
            </span>
          </span>
          <span
            v-if="canDelete"
            class="d-flex justify-content-center"
            slot="footer"
          >
            <base-button @click="deleteUser" type="danger"
              >Delete Account</base-button
            >
          </span>
        </card>
      </div>
      <div class="col-12 col-md-6" v-if="canEdit && isPayingCustomer">
        <card>
          <p slot="header" class="title">{{ $t("additionalInfo") }}</p>
          <div class="additional-info">
            <div
              class="row"
              v-if="isPayingCustomer && canEditEmail && canModule(M.teltonika)"
            >
              <p class="tab" @click="teltonika_device">Health Care Device</p>
            </div>
            <div class="row">
              <router-link
                tag="p"
                :to="`/profiles/user/${user.id}/working-info`"
                class="tab"
                >{{ $t("workingInfo") }}</router-link
              >
            </div>
            <div class="row">
              <router-link
                tag="p"
                class="tab"
                :to="`/profiles/user/${user.id}/medical-info`"
                >{{ $t("medicalProfile") }}</router-link
              >
            </div>
          </div>
        </card>
      </div>

      <!-- change password -->
      <div class="col-12 col-md-6" v-if="currentUserIsOpening">
        <card :bodyClasses="'pb-0 pt-0'">
          <p slot="header" class="title">{{ $t("account.changePassword") }}</p>
          <div class="row h-100">
            <base-input
              name="password"
              type="password"
              v-model="$v.password.old_password.$model"
              :placeholder="$t('account.oldPassword')"
              class="col-12 shadow-fix"
              addon-left-icon="tim-icons icon-email-85"
              autocomplete="off"
            >
              <template v-if="$v.password.old_password.$error" v-slot:error>
                <label class="error" v-if="!$v.password.old_password.required"
                  >{{ $t("account.oldPassword") }}
                  {{ $t("validations.isRequired") }}</label
                >
              </template>
            </base-input>

            <base-input
              name="password"
              type="password"
              v-model="$v.password.password.$model"
              :placeholder="$t('account.password')"
              class="col-12 shadow-fix"
              addon-left-icon="tim-icons icon-email-85"
              autocomplete="off"
            >
              <template v-if="$v.password.password.$error" v-slot:error>
                <label class="error" v-if="!$v.password.password.required"
                  >{{ $t("account.password") }}
                  {{ $t("validations.isRequired") }}</label
                >
                <label
                  class="error"
                  v-else-if="!$v.password.password.checkPassword"
                  >{{ $t("validations.passwordLength") }}</label
                >
              </template>
            </base-input>

            <base-input
              name="confirm password"
              type="password"
              v-model="$v.password.confirm_password.$model"
              :placeholder="$t('account.confirmPassword')"
              addon-left-icon="tim-icons icon-email-85"
              autocomplete="off"
              class="col-12 shadow-fix"
            >
              <template v-if="$v.password.confirm_password.$error" v-slot:error>
                <label
                  class="error"
                  v-if="!$v.password.confirm_password.required"
                  >{{ $t("account.password") }}
                  {{ $t("validations.isRequired") }}</label
                >
                <label
                  class="error"
                  v-if="!$v.password.confirm_password.sameAsPassword"
                  >{{ "validations.notSamePassword" }}</label
                >
              </template>
            </base-input>
          </div>
          <span class="d-flex justify-content-center" slot="footer">
            <base-button @click="submitChangePassword" block type="success">{{
              $t("Submit")
            }}</base-button>
          </span>
        </card>
      </div>
    </div>
    <el-dialog :visible.sync="showDialog">
      <div v-if="dialogContent" class="status-dialog">
        <p class="text-center" v-text="dialogContent.p"></p>
        <p v-text="dialogContent.p2"></p>
      </div>
      <div
        slot="footer"
        class="row justify-content-around"
        v-if="dialogContent"
      >
        <base-button
          v-if="dialogContent.yes"
          @click="dialogContent.yesAction"
          class="text-capitalize"
          type="info"
          >{{ dialogContent.yes }}</base-button
        >
        <base-button
          v-if="dialogContent.no"
          @click="dialogContent.noAction"
          class="text-capitalize"
          type="warning"
          >{{ dialogContent.no }}</base-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Dialog } from "element-ui";
import helper from "../../util/helper";
import { email, required, requiredIf, sameAs } from "vuelidate/lib/validators";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { users_types } from "../../store";
import ProfilePicture from "../../components/ProfilePicture.vue";

const debounce = helper.debounce;
function checkPassword(str) {
  let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}
export default {
  data() {
    return {
      userImage: "",
      dialogContent: null,
      showDialog: false,
      editingPersonalInfo: false,

      personalInfoCopy: {
        email: null,
        phone: null,
        phone2: null,
        address: null,
        billing_email: null,
        notifications_email: null,
        first_name: null,
        last_name: null,
      },
      password: {
        password: null,
        confirm_password: null,
        old_password: null,
      },
      logo: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "UserData",
      currentUser: "user",
    }),
    usersTypesOptions() {
      return Object.keys(users_types).map((v) => {
        return {
          label: v,
          value: users_types[v],
        };
      });
    },
    userImagePath() {
      if (this.user && this.user.img_path) {
        return this.user.img_path;
      } else {
        return "";
      }
    },
    isUserSuperAdmin() {
      return this.user && this.user.role_id == 1;
    },
    isUserAdmin() {
      return this.user && this.user.role_id == 2;
    },
    isCompanyUser() {
      return !(this.isUserSuperAdmin || this.isUserAdmin);
    },
    canDelete() {
      return this.isSuperAdminOpening || this.isHisAdminOrSupervisourOpening;
    },
    currentUserIsOpening() {
      return this.user.id == this.currentUser.id;
    },
    isHisAdminOpening() {
      return (
        this.user.info.admins && // one of the admins
        this.user.info.admins.some((a) => {
          return a.id == this.currentUser.id;
        })
      );
    },
    isHisAdminOrSuperAdminOpening() {
      return this.isHisAdminOpening || this.isSuperAdminOpening;
    },
    isHisAdminOrSupervisourOpening() {
      // admin or supervisor
      return (
        this.user.info &&
        !this.currentUserIsOpening &&
        (this.isHisSupervisourOpening || this.isHisAdminOpening)
      );
    },
    isHisSupervisourOpening() {
      return (
        this.currentUser.type == "supervisor" &&
        this.currentUser.company_id == this.user.company_id
      );
    },
    isSuperAdminOpening() {
      return this.currentUser.role_id == 1 && this.user.role_id != 1;
    },
    canEditEmail() {
      return this.isHisAdminOrSupervisourOpening || this.isSuperAdminOpening;
    },
    canEdit() {
      return (
        this.currentUserIsOpening ||
        this.isHisAdminOrSupervisourOpening ||
        this.isSuperAdminOpening
      );
    },
    canEdit2FA() {
      return this.currentUserIsOpening;
    },
    isPayingCustomer() {
      return this.user && this.user.info.type == "paying_customer";
    },
  },
  validations: {
    user: {
      first_name: {
        required: requiredIf(function (val) {
          return this.user.status != "unverified";
        }),
      },
      last_name: {
        required: requiredIf(function (val) {
          return this.user.status != "unverified";
        }),
      },
      email: {
        email,
        required,
      },
      phone: {
        isValidPhone: function (value) {
          let phoneNumber = parsePhoneNumberFromString(
            this.user.callCode + value
          );
          if (phoneNumber) {
            return phoneNumber.isValid();
          }
          return false;
        },
      },
      phone2: {
        isValidPhone: function (value) {
          if (!value || value.length == 0) {
            return true;
          }
          let phoneNumber = parsePhoneNumberFromString(
            this.user.callCode + value
          );
          if (phoneNumber) {
            return phoneNumber.isValid();
          }
          return false;
        },
      },
      billing_email: {
        email,
      },
      notifications_email: {
        email,
      },
    },
    password: {
      old_password: {
        required,
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
      password: {
        checkPassword,
        required,
      },
    },
    validationGroup: ["user", "password"],
  },
  methods: {
    editPersonalInfo() {
      this.$v.$reset();
      this.editingPersonalInfo = true;
      this.personalInfoCopy = {
        email: this.user.email,
        phone: this.user.phone,
        phone2: this.user.phone2,
        address: this.user.address,
        billing_email: this.user.billing_email,
        notifications_email: this.user.notifications_email,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        type: this.user.type,
      };
      let purePhone = parsePhoneNumberFromString(this.user.phone);
      if (purePhone) {
        purePhone = purePhone.nationalNumber;
        this.user.callCode = this.user.phone.split(purePhone)[0];
        this.user.phone = purePhone;
      }
      if (this.user.phone2) {
        this.user.phone2 = parsePhoneNumberFromString(
          this.user.phone2
        ).nationalNumber;
      }
    },
    savePersonalInfo() {
      this.$v.validationGroup.user.$touch();
      console.log(this.$v.validationGroup.user);
      if (this.$v.validationGroup.user.$invalid) {
        this.$notify({
          message: "You Have Errors on Your new data!",
          type: "danger",
        });
        return;
      }
      //#region DO
      {
        this.user.phone_ = this.user.phone;
        this.user.phone = this.user.callCode + this.user.phone;
        if (this.user.phone2) {
          this.user.phone_2 = this.user.phone2;
          this.user.phone2 = this.user.callCode + this.user.phone2;
        }
      }
      //#endregion DO
      const changing = {};
      Object.keys(this.personalInfoCopy).forEach((key) => {
        if (this.user[key]) {
          this.user[key] = this.user[key].trim().toLowerCase();
        }
        if (this.personalInfoCopy[key]) {
          this.personalInfoCopy[key] = this.personalInfoCopy[key]
            .trim()
            .toLowerCase();
        }
        if (this.user[key] != this.personalInfoCopy[key]) {
          changing[key] = this.user[key];
        }
      });
      //#region UNDO
      {
        this.user.phone = this.user.phone_;
        delete this.user.phone_;
        if (this.user.phone2) {
          this.user.phone2 = this.user.phone_2;
          delete this.user.phone_2;
        }
      }
      //#endregion UNDO
      if (Object.keys(changing).length != 0) {
        this.$store.dispatch("saveUserPersonalChanging", changing).then((_) => {
          //#region REDO
          {
            this.user.phone = this.user.callCode + this.user.phone;
            if (this.user.phone2) {
              this.user.phone2 = this.user.callCode + this.user.phone2;
            }
          }
          //#endregion REDO
          this.editingPersonalInfo = false;
        });
      } else {
        //#region REDO
        {
          this.user.phone = this.user.callCode + this.user.phone;
          if (this.user.phone2) {
            this.user.phone2 = this.user.callCode + this.user.phone2;
          }
        }
        //#endregion REDO
        this.editingPersonalInfo = false;
      }
    },
    undoPersonalInfoChanging() {
      this.editingPersonalInfo = false;
      this.$store.commit("setUserData", {
        ...this.user,
        ...this.personalInfoCopy,
      });
    },
    openStatusDialog() {
      if (this.canDelete) {
        this.showDialog = true;
        switch (this.user.status) {
          case "active": {
            this.dialogContent = {
              p: `this user is in active mode, do you want to deactivate him/her?`,
              p2: `If you deactivate him/her, he/she will not be able to log in again.`,
              yes: "deactivate",
              yesAction: () => {
                this.$store
                  .dispatch("saveUserPersonalChanging", {
                    status: "inactive",
                  })
                  .then(({ data }) => {
                    this.showDialog = false;
                    this.$store.commit("updateUserStatus", "inactive");
                  })
                  .catch(console.error);
              },
              no: "no",
              noAction: () => {
                this.showDialog = false;
              },
            };
            break;
          }
          case "inactive": {
            this.dialogContent = {
              p: `this user is deactivated, do you want to activate him/her?`,
              p2: `If you activate him/her, he/she will be able to log in again.`,
              yes: "activate",
              yesAction: () => {
                this.$store
                  .dispatch("saveUserPersonalChanging", {
                    status: "active",
                  })
                  .then(({ data }) => {
                    this.showDialog = false;
                    this.$store.commit("updateUserStatus", "active");
                  })
                  .catch(console.error);
              },
              no: "no",
              noAction: () => {
                this.showDialog = false;
              },
            };
            break;
          }
          case "verified": {
            this.dialogContent = {
              p: `This user has been verified.`,
              p2: `this means he/she has have filled out their credentials but have not yet signed in.`,
              yes: "Close",
              yesAction: () => {
                this.showDialog = false;
              },
            };
            break;
          }
          case "unverified": {
            this.dialogContent = {
              p: `This user is unverified.`,
              p2: `This means that he/she did not fill out his/her credentials.`,
              yes: "Resend Registration Email",
              yesAction: () => {
                this.$store.commit("setSpinner", true);
                this.$api
                  .patch(`user/resend_registration_email/${this.user.id}`)
                  .then(({ data }) => {
                    this.showDialog = false;
                    this.$notify({
                      message: "Email sent successfully!",
                    });
                  })
                  .catch(console.error);
              },
              no: "Close",
              noAction: () => {
                this.showDialog = false;
              },
            };
            break;
          }
          case "suspended": {
            this.dialogContent = {
              p: `This user is suspended.`,
              p2: `This means that there are more than 3 failed login attempts for his account.`,
              yes: "unsuspend",
              yesAction: () => {
                this.$store.commit("setSpinner", true);
                this.$api
                  .patch(`user/unsuspend/${this.user.id}`)
                  .then(({ data }) => {
                    this.showDialog = false;
                    this.user.is_suspended = false;
                    this.$store.commit(
                      "updateUserStatus",
                      this.user.THE_STATUS
                    );
                  })
                  .catch(console.error);
              },
              no: "Close",
              noAction: () => {
                this.showDialog = false;
              },
            };
            break;
          }
        }
      }
    },
    openAddressMap() {
      this.$store.commit("openAddressMap", {
        lat: this.user.info.lat,
        lng: this.user.info.lng,
      });
    },
    openCompany(company_type, company_id) {
      this.$router.push(`/profiles/Company/${company_type}/${company_id}`);
    },
    openUser(id) {
      this.resetAll();
      this.$router.push(`/profiles/user/${id}`);
      this.$store.dispatch("getUserData", id);
    },
    resetAll() {
      this.dialogContent = null;
      this.showDialog = false;
      this.editingPersonalInfo = false;
      this.personalInfoCopy = {
        email: null,
        phone: null,
        phone2: null,
        address: null,
        billing_email: null,
        notifications_email: null,
      };
    },
    onImageChange(base64Image) {
      this.$store
        .dispatch("saveUserPersonalChanging", {
          avatar: base64Image,
        })
        .then((_) => {
          this.$forceUpdate();
        });
    },
    deleteImage() {
      this.$store.dispatch("saveUserPersonalChanging", {
        avatar: null,
      });
    },
    deleteUser() {
      // if (this.user.isSupervisor === true) {
      //   this.$notify({
      //     message: "You cannot delete the Supervisor  user",
      //     type: "warning",
      //   });
      //   return;
      // }
      this.showDialog = true;
      this.dialogContent = {
        p: `Are you sure you want to delete this user?`,
        yes: "Cancel",
        yesAction: () => {
          this.showDialog = false;
        },
        no: "Delete",
        noAction: () => {
          this.$store
            .dispatch("deleteUser")
            .then(({ data }) => {
              this.showDialog = false;
              this.$router.go(-1);
            })
            .catch(console.error);
        },
      };
    },
    change2FA() {
      if (this.canEdit2FA)
        this.$store
          .dispatch("saveUserPersonalChanging", {
            tfa_enable: !this.user.tfa_enable,
          })
          .then((res) => {
            this.user.tfa_enable = !this.user.tfa_enable;
          })
          .catch(console.error);
    },
    submitChangePassword() {
      this.$v.$reset();
      this.$v.validationGroup.password.$touch();
      if (this.$v.validationGroup.password.$invalid) {
        this.$notify({
          message: "You Have Errors on Your new data!",
          type: "danger",
        });
        return;
      }
      this.$api
        .patch(`/user/setPassword`, {
          old_password: this.password.old_password,
          new_password: this.password.password,
        })
        .then(({ data }) => {
          this.password = {
            password: null,
            confirm_password: null,
            old_password: null,
          };
          this.$v.$reset();
          this.$notify({
            message: "Your Password Updated Successfully!",
            type: "success",
          });
        })
        .catch();
    },
    teltonika_device() {
      this.$store.commit("teltonika_device", true);
    },
  },
  components: {
    [Dialog.name]: Dialog,
    [ProfilePicture.name]: ProfilePicture,
  },
  destroyed() {
    // this.$store.commit("setUserData", null);
  },
};
</script>

<style scoped lang="scss">
p input {
  color: unset;
  text-transform: capitalize;
  text-align: center;
  display: unset !important;
  width: unset !important;
}
p input:disabled {
  background: transparent;
  border: none;
  outline: none;
}
.last-name:disabled {
  text-align: start;
  margin-left: 3px;
}
.first-name:disabled {
  text-align: end;
  margin-right: 3px;
}

ul {
  padding-inline-start: 20px;
}
li {
  margin-bottom: 5px;
}
li.creator {
  list-style-type: none;
  position: relative;
}
li.creator::before {
  content: "\2605";
  position: absolute;
  left: -19px;
  color: var(--primary);
}
li span {
  display: inline-block;
}
.card {
  height: 95%;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.4);
}
::v-deep .card-body {
  min-height: unset;
}
.user-name {
  font-size: 2rem;
  text-transform: capitalize;
}
.description {
  text-transform: capitalize;
}
.title {
  width: 35%;
  /* align-items: center; */
  display: inline-flex;
  font-size: 1rem;
  margin-bottom: 10px;
  letter-spacing: 1px;
  vertical-align: top;
}
[dir="rtl"] .title {
  letter-spacing: 0;
}
.value {
  width: 65%;
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  text-transform: capitalize;
  min-height: calc(2.25rem + 2px);
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.input-group-text {
  min-height: calc(2.25rem + 2px);
  font-size: 0.75rem;
  background: gray;
  padding: 10px !important;
}
.input-group:focus-within .input-group-text {
  border-color: var(--primary);
}
.input-group:focus-within .input-group-text.error {
  border-color: #ec250d !important;
}
.id {
  color: var(--primary);
}
input:disabled.value {
  background: transparent;
  border: none;
  outline: none;
}
input:disabled::placeholder,
.title {
  color: white;
}

.linkable {
  text-decoration: underline var(--primary);
  cursor: pointer;
}
.linkable:hover {
  color: var(--primary);
}
.user-status {
  cursor: pointer;
}
.user-status:hover {
  opacity: 0.8;
}
.status-dialog {
  text-transform: capitalize;
  letter-spacing: 1.2px;
}
p {
  color: wheat !important;
  text-align: center;
}
::v-deep .el-dialog__body {
  padding-top: 0;
}
::v-deep .el-dialog__body button {
  text-transform: capitalize;
}
.tim-icons {
  padding: 3px;
  border-radius: 25%;
  cursor: pointer;
}
.edit-icon {
  border: 1px solid var(--primary);
  color: var(--primary);
}
.edit-icon:hover {
  background: var(--primary);
  color: var(--default);
}
.icon-check-2 {
  border: 1px solid var(--success);
  color: var(--success);
}
.icon-check-2:hover {
  background: var(--success);
  color: white;
}
.icon-simple-remove {
  border: 1px solid var(--danger);
  color: var(--danger);
}
.icon-simple-remove:hover {
  background: var(--danger);
  color: white;
}
.error {
  border-color: #ec250d !important;
  background-color: rgba(222, 222, 222, 0.05) !important;
}
p.title {
  width: 90%;
}
.muted {
  opacity: 0.7;
}
.additional-info {
  height: 95%;
}
.additional-info .row {
  justify-content: center;
  height: 30%;
  align-items: center;
}
.additional-info p.tab {
  font-size: 1.2rem;
  font-weight: bold;
  color: white !important;
  letter-spacing: 1.3px;
  cursor: pointer;
  padding-bottom: 5px;
  text-decoration: underline $primary;
}
.white-content .additional-info p.tab {
  color: #1d253b !important;
}
.additional-info p.tab:hover {
  color: $primary !important;
}
</style>

<style lang="scss" scoped>
@include font_secondary(".title");

@include font_secondary(".first-name");
@include font_secondary(".last-name");

@include font_primary(".value");
</style>
