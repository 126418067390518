<template>
  <div
    class="form-group"
    :class="{
      'input-group-focus': focused,
      'has-danger': error || $slots.error,
      'has-success': !error && !$slots.error && touched,
      'has-label': label,
      'has-icon': hasIcon,
    }"
  >
    <slot name="label">
      <label v-if="label" class="font-color-4 font-weight-bold h4"> {{ label }} {{ required ? '*' : '' }} </label>
    </slot>
    <div class="mb-0" :class="{'input-group': hasIcon}">
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text"><i :class="addonLeftIcon"></i></div>
        </span>
      </slot>
      <slot>
        <input
          :value="value"
          v-bind="$attrs"
          v-on="listeners"
          class="form-control input"
          aria-describedby="addon-right addon-left"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          :class="{'view-mode' : viewMode, 'text-right': isRTL}"
          ref="input"
        />
      </slot>
      <slot name="addonRight">
        <span v-if="addonRightIcon" class="input-group-append">
          <div class="input-group-text"><i :class="addonRightIcon"></i></div>
        </span>
      </slot>
    </div>

    <slot name="error" v-if="error || $slots.error">
      <label class="error">{{ error }}</label>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
import {ReplaceSpecialChar} from '@/util/helper.js';

export default {
  inheritAttrs: false,
  name: 'base-input',
  props: {
    viewMode: {
      type: Boolean,
      default: false,
    },
    required: Boolean,
    label: {
      type: String,
      description: 'Input label'
    },
    error: {
      type: String,
      description: 'Input error',
      default: ''
    },
    value: {
      type: [String, Number],
      description: 'Input value'
    },
    addonRightIcon: {
      type: String,
      description: 'Input icon on the right'
    },
    addonLeftIcon: {
      type: String,
      description: 'Input icon on the left'
    },
    placeholder:{
      type:String
    },
    specialChar:{
        type:Boolean,
        default:false
    },
    autocomplete:{

    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      touched: false
    };
  },
  computed: {
    isRTL() {
      return this.$rtl.isRTL;
    },
    hasIcon() {
      return this.hasLeftAddon || this.hasRightAddon
    },
    hasLeftAddon() {
      const { addonLeft } = this.$slots;
      return (
        addonLeft !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    hasRightAddon() {
      const { addonRight } = this.$slots;
      return (
        addonRight !== undefined ||
        this.addonRightIcon !== undefined
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    }
  },
  methods: {
    onInput(evt) {
      if (!this.touched) {
        this.touched = true;
      }

      this.$emit('input', this.specialChar ? evt.target.value : ReplaceSpecialChar(evt.target.value));
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit('focus', evt)
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit('blur', evt)
    }
  }
};
</script>
<style scoped>
.viewMode{
  background-color: #f5f7fa21 !important;
  color: #ffff !important;
  font-weight: bold !important;
  border: 0px !important;
  cursor:auto !important;
}
</style>

<style lang="scss" scoped>
.input {
  box-shadow: $custom-shadow;
}
</style>