import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';

export default {
  path: '/globalmobility',
  component: DashboardLayout,
  redirect: '/globalmobility/scooters',
  children: [
    {
      path: 'scooters',
      name: 'Scooters',
      component: () => import('../pages/scooters/index.vue')
    },
    {
      path: 'scooter/:id',
      name: 'Scooter',
      component: () => import('../pages/scooter/index.vue')
    },
  ]
}