<template>
  <div class="col-12 p-0">
    <h4 class="col-12 card-title font-weight-bold">{{ $t('solutionsStatistics') }}</h4>
    <hr class="m-0" style="border-top-width: 2px" />
    <div class="col-12 background-1">
      <template v-for="(solution, i) in data">
        <cardView :key="i" :label="$t(solution.key)" :value="solution.value" :link="getUrl(solution)" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import cardView from '@/components/cardView';

export default {
  components: {
    cardView,
  },
  props: {
    namespace: {
      type: String,
      required: true,
    },
    disableDataFetch: {
      type: Boolean,
      default: false
    },
    structureId: {},
  },
  computed: {
    ...mapState({
      loading(state, getters) {
        return getters[this.namespace + "/StatisticsSolutions/loading"];
      },
      data(state, getters) {
        return getters[this.namespace + "/StatisticsSolutions/getData"];
      },
    }),
  },
  methods: {
    ...mapActions({
      fetchData(dispatch, payload) {
        return dispatch(
          this.namespace + "/StatisticsSolutions/fetchData",
          payload
        );
      },
      fetchDataByStructure(dispatch, payload) {
        return dispatch(
          this.namespace + "/StatisticsSolutions/fetchDataByStructure",
          payload
        );
      },
    }),
    getUrl(solution) {
      if(solution.value == 0) {
        return '';
      }
      let url = '/SWAM/Solution-Portfolio?solution=' + solution.id;
      if(this.structureId) {
        url += '&structure=' + this.structureId;
      }
      return url;
    }
  },
  mounted() {
    if(!this.disableDataFetch) {
      let structures = this.$store.getters["SwamStore/haveDevices"];
      if (this.structureId) {
        this.fetchDataByStructure({ structures, structureId: this.structureId });
      } else {
        this.fetchData(structures);
      }
    }
  },
  watch: {
    disableDataFetch() {
      if(this.disableDataFetch) {
        return;
      }
      let structures = this.$store.getters["SwamStore/haveDevices"];
      if (this.structureId) {
        this.fetchDataByStructure({ structures, structureId: this.structureId });
      } else {
        this.fetchData(structures);
      }
    },
    structureId() {
      if(this.disableDataFetch) {
        return;
      }
      let structures = this.$store.getters["SwamStore/haveDevices"];
      if (this.structureId) {
        this.fetchDataByStructure({ structures, structureId: this.structureId });
      } else {
        this.fetchData(structures);
      }
    }
  },
};
</script>

<style scoped lang="scss">
h4 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>