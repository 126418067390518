import ActionsTypes from "../types/actions-types";
import MutationsTypes from "../types/mutations-type";

import { onAlarmsActions } from "./actions/on-alarms-actions";
import { intrusionAlarmsActions } from "./actions/intrusion-alarms-actions";
import { villasListActions } from "./actions/villas-list-actions";

import {
  IntrusionAlarmsService,
  IntrusionVillasListService
} from "../../services/intrusion-system-services.bundle";


export const actions = {
  /**
   *
   * @param commit
   */
  [ActionsTypes.RESET_THE_WHOLE_STATE_ACTION]: ({ commit }) => {
    commit(MutationsTypes.RESET_THE_WHOLE_STATE_MUTATION);
  },
  [ActionsTypes.GET_MORE_INFO_ABOUT_CUSTOMER]: ({ commit }) => {},
  [ActionsTypes.MORO_OR_POLICE_ACTION]: ({ commit }, moroOrPolice) => {
    commit(MutationsTypes.MORO_OR_POLICE_MUTATION, moroOrPolice);
  },

  /**
   *
   * @param commit
   * @param {Object}
   * @returns {Promise<boolean>}
   */
  async [ActionsTypes.OPEN_ACKNOWLEDGEMENT_MODAL_ACTION]({ commit }, ackData) {
    if (ackData.closeOrOpen) {
      const service = new IntrusionVillasListService();
      const customerId = ackData.customer.id;
      const reply = await service.getCustomerContacts(customerId);
      if (reply != null) {
        const contacts = reply.data.map(c=>({
          email: c.email,
          id: c.id,
          phone: c.phone,
          lastName: c.last_name,
          firstName: c.first_name,
        }));
        commit(MutationsTypes.CUSTOMER_CONTACTS_LIST_MUTATION, contacts);
        commit(
          MutationsTypes.OPEN_ACKNOWLEDGEMENT_MODAL_MUTATION,
          ackData.closeOrOpen
        );
      }
    } else {
      commit(
        MutationsTypes.OPEN_ACKNOWLEDGEMENT_MODAL_MUTATION,
        ackData.closeOrOpen
      );
    }
  },
  [ActionsTypes.OPEN_MORE_INFORMATION_MODAL_ACTION]({ commit }, openOrClose) {
    commit(MutationsTypes.OPEN_MORE_INFORMATION_MODAL_MUTATION, openOrClose);
  },

  [ActionsTypes.MORE_INFORMATION_FOR_ALARMS_ACTION]({}) {
    return new Promise(async (resolve, reject) => {
      const service = new IntrusionAlarmsService();
      const reply = await service.getMoreInformationForAlarms();
      if(reply != null) {
        resolve(reply.data);
      } else {
        reject(null);
      }
    });
  },
  ...onAlarmsActions,
  ...intrusionAlarmsActions,
  ...villasListActions
};
