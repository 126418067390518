const map = { 'open': 'Open', 'feed_back': 'Feed Back Needed', 'info': 'Info', 'closed': 'Closed','answered':'Answered','pending':'Pending' }

export var initTicket;
export const mixin = {
    data(){
        return {
            
        }
    },
    methods: {
       
        getStatus(status) {
            return this.statusText(
                status
            );
        },

        getStatusColor(status) {
            switch (status) {
                case "open":
                    return "btn-success";
                case "answered":
                    return "btn-secondary text-dark";
                case "feed_back":
                    return "btn-danger";
                case "pending":
                    return "btn-primary";
                case "closed":
                    return "btn-dark";
            }
        },

        getType(status){
            switch (status) {
                case "open":
                    return "success";
                case "answered":
                    return "default";
                case "feed_back":
                    return "danger";
                case "pending":
                    return "warning";
                case "closed":
                    return "default";
            } 
        },
        statusText: (status) => {
            return map[status];
        },

         /**
         * 
         * @param {string} subject 
         * @param {HTMLAllCollection} content 
         * @param {ArrayLike<{id:number,role:'fmc'|'amc'|'paying_customer'|'authority'|'admin',text:string}>} receivers the id is the company id, and the text is the company name
         */
        toTicket(
            subject,
            content,
            receivers
        ) {
            initTicket = {
                subject:subject,
                content:content,
                receivers:receivers
            }
            this.$router.push("/tickets/create")
        },
        reset(){
            initTicket=null
        },
        extractContent(s, space) {
            var span = document.createElement("div");
            span.innerHTML = s;
            var children = span.querySelectorAll("*");
            let res=``
            for (var i = 0; i < children.length; i++) {
                if(children[i].tagName=="UL" || children[i].tagName=="OL"){
                    continue;
                }
                let val = " ";
                const type = getElementType(children[i].tagName);
                if (type == "block") {
                    val = "\n";
                }
                if (type == "list-item") {
                    val = "\t\n";
                }
                if (children[i].textContent) children[i].textContent += val;
                else children[i].innerText += val;
                // if (children[i].textContent)  res += children[i].textContent + val;
                // else res+= children[i].innerText + val;
            }
            return [span.textContent || span.innerText]
                .toString()
                .replace(/ +/g, " ");
            
        }
        
    }
}
function getElementType(tag) {
    var cStyle,
        t = document.createElement(tag),
        gcs = "getComputedStyle" in window;

    document.body.appendChild(t);
    cStyle = (gcs ? window.getComputedStyle(t, "") : t.currentStyle).display;
    document.body.removeChild(t);
    return cStyle;
}