import api from "@/axiosWithDelimiterFile";
import alarm from './initial-alarms-type';

export default {
  namespaced: true,

  state: {
    pagination: {
      limit: 50,
      offset: 0
    },
    allSensors: [],
    allusers: [],
    assignedUserIds:[],
    templatesList: [],
    templateUsers: [],
    template: {
      company_id: null,
      name: "",
      structure_id: null,
      customer_id: null,
      community_id: null,
      reminder: "",
      operational:
      [{
        sensor_type_id: "",
        alarms: []
      }],
      status: []
    }
  },

  getters: {
    getLimit(state) {
      return state.pagination.limit
    },
    getOffset(state) {
      return state.pagination.offset
    },
    allSensors(state) {
      return state.allSensors;
    },
    templatesList(state) {
      return state.templatesList;
    },
    template(state) {
      return state.template;
    },
    allusers(state) {
      return state.allusers;
    },
    templateUsers(state) {
      return state.templateUsers;
    },
    assignedUserIds(state)
    {
      return state.assignedUserIds;
    }
  },
  mutations: {
    updateTemplateList(state, { data }) {
      state.templatesList = data;
    },
    updateUsersList(state, data) {
      state.allusers=[...data];
    },
    updateTemplate(state, data) {
      state.template={...data};
    },
    updateAllSensors(state, data) {
      state.allSensors = [...data];
    },
    resetTemplateMutation(state)
    {
      state.template={};
    },
    updateOperationalInTemplate(state,operational){
      state.template.operational={...operational};
    },
    updateAssignedUsersToTemplate(state,data){
      state.assignedUserIds=[...data];
    }
  },
  actions: {
    resetTemplate({commit}){
      commit('resetTemplateMutation')
    },
    getAllSensores({ commit, rootState }) {
      commit('updateAllSensors', rootState.sensor_type);
    },
    getTemplateById({ commit }, templateId) {
      api.get(`alert-templates/${templateId}`).
        then(res => {
          commit('updateTemplate', res.data.data);
        }).
        catch(err => { console.log(err) });
    },

    assignTemplateToUsers({ }, { templateId, usersIds }) {
      return api.post(`alert-templates/${templateId}/users`,
        {
          user_ids: usersIds
        }
      ).then(res => {
        const data = res.data.data;
        return;
      });
    },

    getList({ commit }, { companyId }) {
      api.get('alert-templates/list', {
        params: {
          company_id: companyId,
          limit: this.getters.getLimit,
          offset: this.getters.getOffset
        }
      }).then(res => {
        commit('updateTemplateList', { data: res.data.data });
      })
    },

    getCompanyUsers({ commit }, company_id) {
      api.get(`company/all-users`,
        {
          params: {
            companyId: parseInt(company_id),
          }
        }).then(res => {
          commit('updateUsersList', res.data.data.users);
        });
    },
    async createTemplate({},{payload}){
      await api.post(`alert-templates/`,
      {...payload}
      )
    },
      getUsersByTemplateId({commit},templateId){
        api.get(`/alert-templates/${templateId}/users`)
        .then((res) => {
          commit('updateAssignedUsersToTemplate',res.data.data);
        });

      },
      getOperationalSensorsByFilter({commit},filter)
      {
        api.get(`/iot/sensors/types`, {
          params: {
            structure_id: filter.structure_id,
            area_id: filter.community_id,
            customer_id: filter.customer_id,
          },
        })
        .then((res) => {
          let operational=[];
          res.data.data.forEach((element) => {

            let alarms=[];
            for(let i=0;i<alarm.length;i++)
            {
              var updatedAlarm =  JSON.parse(JSON.stringify(alarm[i]));
              updatedAlarm.sensor_type_id=element.id;
              alarms.push(updatedAlarm);
            }
            operational.push(alarms);
          })
        commit('updateOperationalInTemplate',operational);
      })
        .catch(err=>console.log(err));
      },

      async updateTemplate({commit},{templateId,payload}){
        return api.patch(`alert-templates/${templateId}`,
          {...payload}
        ).then(res=>{
        })
        .catch(err=>{
        })
      },
      deleteTemplate({commit},templateId){
        api.delete(`alert-templates/${templateId}`)

      }
   },


  }


