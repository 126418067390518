import ForkliftService from "../../services/forklift.service";

export default {
    namespaced: true,
    state: {
        loading: false,
        data: [],
        paginationData: {},
        totalRecords: 0
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        },
        pagination(state) {
            return state.pagination
        },
        totalRecords(state) {
            return state.totalRecords
        }
    },
    mutations: {
        setLoading(state, value) {
            state.loading = value;
        },
        setData(state, data) {
            state.data = data;
        },
        setPagination(state, pagination) {
            state.pagination = pagination
        },
        setTotalRecords(state, total) {
            state.totalRecords = total
        }
    },
    actions: {
        async fetchData({ state, commit }) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true);

                ForkliftService.getForklifts()
                    .then((res) => {
                        console.log(res)
                        commit("setData", res.data.data);
                        commit("setTotalRecords", res.data.total_records)
                        commit("setLoading", false);
                        resolve(res.data.data)
                    })
                    .catch((err) => reject(err));
            });
        },
    }
}