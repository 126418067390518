import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';

export default {
  path: '/forklift',
  component: DashboardLayout,
  redirect: '/forklift/devices',
  name: 'Forklift',
  children: [
    {
      path: 'devices',
      name: 'Forklift list',
      component: () => import('../pages/devices/index.vue')
    },
    {
      path: 'device/:id',
      name: 'Single forklift Page',
      component: () => import('../pages/device/index.vue')
    },
    {
      path: '/forklift/report',
      path: '/forklift/report/:id',
      name: 'Forklift Report',
      component: () => import('../pages/ForkliftReport/Index.vue')
    },
    {
      path: '/forklift/complete/:id',
      name: 'Forklift Complete',
      component: () => import('../pages/forkliftComplete/Index.vue')
    },
    {
      path: '/forklift/view/:id',
      name: 'Forklift View',
      component: () => import('../pages/forkliftView/Index.vue')
    },
    {
      path: 'portfolio',
      name: 'Technex Portfolio',
      component: () => import('../pages/portfolio/index.vue')
    },
    // {
    //   path: 'portfolio/history/:id',
    //   name: 'Vehicle Repair History',
    //   component: () => import('../core/VehicleRepairInventory/VehicleRepairHistory/index.vue')
    // },
  ]
}