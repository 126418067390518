<template>
  <div class="">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="customtableheader">
            <h4 slot="header" class="card-title">{{$t("report.reportTable")}}</h4>
            <router-link :to="{name: 'report editor'}">
              <base-button type="primary" round icon>
                <i class="tim-icons icon-simple-add"></i>
              </base-button>
            </router-link>
          </div>
          <div class="striped-table">
            <el-table :data="report_list">
              <div slot="empty">{{$t(emptyText)}}</div>

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
              ></el-table-column>
              <el-table-column :min-width="100" prop="every" align="left" :label="$t('report.every')">
                <div slot-scope="props">
                  <span v-if="props.row.every">{{ $t(`report.${props.row.every}ly`) }}</span>
                  <span v-else>-</span>
                </div>
              </el-table-column>
              <el-table-column
                :min-width="100"
                prop="next_due_date"
                align="left"
                :label="$t('report.nextDueDate')"
              >
                <div slot-scope="props">
                  <span v-if="props.row.next_due_date">{{gDateFormat(props.row.next_due_date)}}</span>
                  <span v-else>-</span>
                </div>
              </el-table-column>
              <el-table-column :min-width="60" align="left" :label="$t('report.lastSent')" prop="last_sent">
                  <div slot-scope="props">
                    <span v-if="props.row.last_sent">{{gDateFormat(props.row.last_sent)}}</span>
                    <span v-else>-</span>
                  </div>
              </el-table-column>
              <el-table-column :min-width="50" align="right" :label="$tc('report.action', 2)">
                <div slot-scope="props">
                  <el-tooltip :content="$t('report.view')" effect="light" :open-delay="300" placement="top">
                    <base-button
                      @click.native="viewReport(props.row)"
                      class="like btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-notes"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip
                    :content="$t('report.download')"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="download(props.row)"
                      class="remove btn-link"
                      type="success"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-cloud-download-93"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip :content="$t('report.delete')" effect="light" :open-delay="300" placement="top">
                    <base-button
                      @click.native="handleDelete(props.$index, props.row)"
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import swal from "sweetalert2";
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  created() {
    this.$api
      .get(`/iot/report-definition`)
      .then((res) => {
          this.state = "noData"
          this.report_list = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      state:"loading",
      report_list: [],
      tableColumns: [
        {
          prop: "id",
          label: "id",
          minWidth: 100,
        },
        {
          prop: "name",
          label: "name",
          minWidth: 100,
        }
      ],
    };
  },
  computed: {
    emptyText() {
      return this.$t(this.state)
    }
  },
  methods: {
    handleDelete(index, row) {
      swal({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteReport(row,index);
        }
      });
    },
    viewReport(row) {
      this.$router.push({name:'report editor',params:{startWith:row}})
    },
    download(row){
      this.$store.dispatch("ReportsDesignerStore/exportReportToPDF",row)
    },
    deleteReport(row,index) {
      this.$api
        .delete(`/iot/report-definition/${row.id}`)
        .then((res) => {
          this.report_list.splice(index,1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
