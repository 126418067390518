import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';

export default {
    path: '/VSAAS',
    component: DashboardLayout,
    name: "VSAAS",
    redirect: "/VSAAS/Operational-Alarms",
    children: [
        {
            path: "Operational-Alarms",
            component: ()=> import("./pages/Operational-Alarms/index.vue"),
            name: "Operational Alarms"
        },
        {
            path: "Operational-Alarms-History",
            component: ()=> import("./pages/Operational-Alarms-History/index.vue"),
            name: "Operational Alarms History"
        },
        {
            path: "Customer-List",
            component: ()=> import("./pages/Customer-List/index.vue"),
            name: "Customer List"
        },
        {
            path: 'Customer-Info/:id',
            name:'Customer Info',
            component: ()=> import("./pages/Customer-Info/index.vue"),
        },
        {
            path: 'WorkOrders',
            name:'WorkOrder List',
            component: ()=> import("./pages/Work-Order/Work-Order-List/index.vue"),
        },
        {
            path: 'WorkOrder/:id',
            name:'WorkOrder',
            component: ()=> import("./pages/Work-Order/Work-Order-Devices/index.vue"),
        },
    ],
};