import VsaasService from '../services/vsaas.service';

export default {
    namespaced: true,
    state: {
        selectedAlarm: null,
        currentAction: null,// terminate || ack || escelate
        deviceCompanies: [],
        customerContactsList: [],
    },
    
    getters: {
        currentAction(state) {
            return state.currentAction;
        },
        deviceCompanies: (state) => state.deviceCompanies ? state.deviceCompanies : [],
        customerContactsList: (state) => state.customerContactsList ? state.customerContactsList : [],
    },
    mutations: {
        openEscalateModal(state, data) {
            state.currentAction = "escelate";
            state.selectedAlarm = data.alarm;
            state.deviceCompanies = data.companies;
        },
        closeEscalateModal(state) {
            if (state.currentAction == "escelate") {
                state.currentAction = null;
                state.selectedAlarm = null;
            }
        },
        openAcknowledgmentModal(state, data) {
            state.currentAction = "ack";
            state.selectedAlarm = data.alarm;
            state.customerContactsList = data.customerContactsList;
        },
        closeAcknowledgmentModal(state) {
            if (state.currentAction == "ack") {
                state.currentAction = null;
                state.selectedAlarm = null;
                state.customerContactsList = [];
            }
        },
        openTerminationModal(state, alarm) {
            state.currentAction = "terminate";
            state.selectedAlarm = alarm;
        },
        closeTerminationModal(state) {
            if (state.currentAction == "terminate") {
                state.currentAction = null;
                state.selectedAlarm = null;
            }
        }
    },
    actions: {
        async openEscalateModal({ commit }, alarm) {
            let companies = await VsaasService.getDevicesCompanies(alarm.deviceId);
            commit("openEscalateModal", { alarm, companies })
        },
        closeEscalateModal({ commit, state }, data) {
            if (!data) {
                commit("closeEscalateModal")
            } else {
                let params = {
                    comment: data.comment,
                    companyIds: data.deviceCompanies,
                }
                VsaasService.escalateAlarm(state.selectedAlarm.id,params).then(_=>{
                    commit("closeEscalateModal");
                });
            }
        },
        async openAcknowledgmentModal({ commit }, alarm) {
            console.log('alarms',alarm);
            let customerContactsList = await VsaasService.getCustomerContacts(alarm.device.customerId);
            if(customerContactsList.length) {
                customerContactsList = customerContactsList.map(r=> ({
                    ...r,
                    firstName: r.first_name,
                    lastName: r.last_name,
                }));
            }
            commit("openAcknowledgmentModal", {alarm,customerContactsList});
        },
        closeAcknowledgmentModal({ commit, state }, data) {
            const alarm = state.selectedAlarm;
            if (!data) {
                commit("closeAcknowledgmentModal")
            } else {
                let params= { comment: data.comment };
                VsaasService.acknowledgeAlarm(state.selectedAlarm.id,params).then(_=>{
                    commit("closeAcknowledgmentModal");
                })
            }
        },
        openTerminationModal({ commit }, data) {
            commit('openTerminationModal',data);
        },
        closeTerminationModal({ commit, state }, data) {
            const alarm = state.selectedAlarm;
            if (!data) {
                commit("closeTerminationModal")
            } else {
                let params= { comment: data.comment };
                VsaasService.terminateAlarm(state.selectedAlarm.id,params).then(_=>{
                    commit("closeTerminationModal");
                })
            }
        },
    }
}