<template>
  <div>
      <Zones :customer_id="id" />
  </div>
</template>

<script>
import Zones from "../../TeltonikaDevices/DeviceZone";
export default {
    props:['id'],
    mounted(){
    },
    components:{
        Zones
    }
}
</script>

<style>

</style>