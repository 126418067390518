export const OPERATIONAL_STATUSES = "iot/analysis/operational-statuses";
export const DEVICES_BASIC_INFO = "iot/devices/search/basicInfo";
export const DEVICE_COMPARISON = "iot/analysis/deviceComparison";
export const STATISTICS = "iot/analysis/statistics";
export const HIGHEST_PROBLEMS = "iot/analysis/highest-problems"; 
export const STRUCTURE_COMPARISON = "iot/analysis/comparison";
export const STRUCTURE_STATISTICS = "iot/analysis/structure-statistics";
export const SOLUTION_STATISTICS = "iot/analysis/solution";
export const STRUCTURES = `structures/${100000000000000}/${0}?include_statistics=true`;
export const OPERATIONAL_ALARMS = "iot/alarms/operational";
export const STATUS_ALARMS = "iot/alarms/status";
export const DEVICES_LIST = "iot/devices/list";
export const ALARMS_HISTORY = (alarmType)=> {return `iot/alarms/${alarmType}/history`} ;
export const ALARMS_EVENTS = (alarmType,alarmId)=> { return `iot/alarms/${alarmType}/${alarmId}/events`}
export const ALARMS_COMMENT = (alarmType,alarmId)=> { return `iot/alarms/${alarmType}/${alarmId}/comment`}
export const ALARMS_ESCALATE = (alarmType,alarmId)=> { return `iot/alarms/${alarmType}/${alarmId}/escalate`}
export const ALARMS_ACKNOWLEDGE = (alarmType,alarmId)=> { return `iot/alarms/${alarmType}/${alarmId}/acknowledge`}

export const SENSOR_TEMPLATE_LIST = (limit, offset) => `iot/sensors/${limit}/${offset}`;
export const SENSOR_TEMPLATE_BY_ID = id => `iot/sensors/${id}`;

export default {
    OPERATIONAL_STATUSES,
    DEVICES_BASIC_INFO,
    DEVICE_COMPARISON,
    STATISTICS,
    HIGHEST_PROBLEMS,
    STRUCTURE_COMPARISON,
    STRUCTURE_STATISTICS,
    SOLUTION_STATISTICS,
    STRUCTURES,
    OPERATIONAL_ALARMS,
    STATUS_ALARMS,
    DEVICES_LIST,
    ALARMS_HISTORY,
    ALARMS_EVENTS,
    ALARMS_COMMENT,
    ALARMS_ESCALATE,
    ALARMS_ACKNOWLEDGE,
    SENSOR_TEMPLATE_LIST,
    SENSOR_TEMPLATE_BY_ID,
}