import GettersTypes from "./types/getter-types";

export const getters = {
  /**
   * @param {state , getters , rootState} {}
   * @type array
   * @desc get sensor color from rootState
   * @author karam mustafa
   * @return array
   * */
  [GettersTypes.GET_SENSORS_COLORS]: (state , getters , rootState) => {
    return rootState.sensor_type;
  },
  /**
   * @param {state , getters , rootState ,rootGetters } {}
   * @param {data , callback} {}
   * @type array
   * @desc set new sensor color after we modified
   * @author karam mustafa
   * @return void
   * */
  [GettersTypes.SET_SENSORS_COLORS]: (state, getters, rootState, rootGetters) => (data , callback) => {
    let newState = rootState;
    let newSensors = newState.sensor_type;
    let newFilter = newSensors.filter((item, index) => {
      if (item.id === data.sensorId) {
        newSensors[index].color = callback(item);
      }
    });
    Object.assign(rootState, newState);
  },
  /**
   * @type array
   * @desc get selected device from rootState
   * @author karam mustafa
   * @return array
   * */
  [GettersTypes.GET_SELECTED_DEVICE]: (state, getters, rootState) =>  {
    return rootState.devicesstore.selected_device;
  },
};
