import IntrusionService from "../../services/intrusion.service";
import { onePeriod } from "../../../../util/periodOptions";

export default {
    namespaced: true,
    state: {
        loading: false,
        villaList: [],
        selectedVilla: null,
        policeStationId: undefined
    },
    getters: {
        periodsFilter(){
            return onePeriod;
        },
        loading(state) {
            return state.loading;
        },
        getVillaList(state) {
            return state.villaList;
        },
        getVillaCardData(state, getters, rootState, rootGetters) {
            if (state.selectedVilla == null) {
                return null;
            }
            const data = {
                title: {
                    icon: require("@/assets/Icons/structure.svg"),
                    text1: state.selectedVilla.structureName,
                    onClick: "/intrusion/villas-info/"+state.selectedVilla.structureId
                },
                rows: [
                    {
                        icon: require("@/assets/Icons/id.svg"),
                        key: "structure id",
                        value: state.selectedVilla.structureId,
                    },
                    {
                        icon: require("@/assets/Icons/bell-filled-white.svg"),
                        key: "Active Alarms Count",
                        value: state.selectedVilla.activeAlarmsCount,
                    },
                    {
                        icon: require("@/assets/Icons/bell-filled-white.svg"),
                        key: "Active intrusion alarms",
                        value: state.selectedVilla.activeIntrusionAlarms,
                    },
                    {
                        icon: require("@/assets/Icons/communities.svg"),
                        key: "Devices count",
                        value: state.selectedVilla.devicesCount,
                    },
                ],
            };
            if (!rootGetters.isPoliceUser) {
                data.rows.splice(3, 0, {
                    icon: require("@/assets/Icons/bell-filled-white.svg"),
                    key: "Active operational alarms",
                    value: state.selectedVilla.activeOperationalAlarms,
                });
            }
            return data;
        },
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setVillaList(state, data) {
            state.villaList = data;
        },
        selectVilla(state, data){
            state.selectedVilla = data;
        },
        setPoliceStationId(state, data){
            state.policeStationId = data;
        }
    },
    actions: {
        async fetchVillaList({ commit, state }, query = {}) {
            commit('setLoading',true);
            try {
                let villas = await IntrusionService.getVillaList({ ...query ,policeStationId: state.policeStationId })
                villas.forEach(villa => {
                    villa.activeAlarmsCount = villa.activeIntrusionAlarms + villa.activeOperationalAlarms;
                });
                commit("setVillaList", villas);
                commit("setLoading", false);
            } catch (error) {
                commit("setLoading", false);
                console.error(error);
            }
        },
        selectVilla({ commit }, data){
            commit("selectVilla", data);
        },
        reset({state,commit}) {
            state.villaList = [];
            state.selectedVilla = null;
        }
    }
}