import { mergeObjectsDeeply } from "../../../handlers/charts/chart-generator";
import { dateFormat } from "../../../util/helper";

export const findObjectInArray = (arr, key, value) =>
  arr.filter(ele => key in ele && ele[key] === value)[0];

export const mergeColumnsConfigurationsArrays = (source, target) => {
  if (!Array.isArray(source) || !Array.isArray(target)) {
    throw new Error(`Mismatched types, you should provide arrays`);
  }
  /**
   *
   */
  for (let i = 0; i < source.length; i++) {
    const sourceObj = source[i];
    const targetObj = findObjectInArray(target, "prop", sourceObj["prop"]);
    if (targetObj) {
      mergeObjectsDeeply(sourceObj, targetObj);
    }
    // else {
    //    source.splice(i, 1);
    // }
  }
  /**
   * adding objects from target to source, in case source don't have'em
   */
  for (let i = 0; i < target.length; i++) {
    const targetObj = target[i];
    const sourceObj = findObjectInArray(source, "prop", targetObj["prop"]);
    if (!sourceObj) {
      source.push({ ...targetObj });
    }
  }
};

export const dateTableCellFomatter = (row, column, cellValue, index)=>{
    return !cellValue? '--' : dateFormat(cellValue);
}

export const emptyTableCellFormatter = (_,__,cellValue,___)=>{
    return !cellValue? '--' : cellValue;
}



export const dateCellMinWidth = 150;