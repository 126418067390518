import DashboardLayout from "./../Layout/DashboardLayout";
import { P, M } from "../../Permissions";
import MO_Details from "./MO-Details/MO-Router";
export default {
    path: '/maintenance',
    component: DashboardLayout,
    meta: {
        module: M.maintenance
    },
    children: [
        {
            path: '',
            redirect:'list'
        }, 
        {
            path: 'list',
            name: "maintenance order list",
            meta: {
               permissions: P.view_maintenance_orders
            },
            component: () => import('./Maintenance-List.vue')
        }, 
        {
            path: 'create',
            name: "create new maintenance order",
            meta: {
               permissions: P.create_maintenance_order
            },
            component: () => import('./Create-MO.vue')
        },
        MO_Details,
        {
            path:'*',
            redirect:'list'
        }
    ]
}
