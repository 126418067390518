import SwamService from '@/pages/SWAM/services/swam.service';
import { onePeriod } from '@/util/periodOptions';

export default {
    namespaced:true,
    state:()=>({
        loading: false,
        currentStructureId:undefined,
        currentSolutionId:undefined,
        currentPeriodIndex:1,
        data: {},
    }),
    getters:{
        loading(state) {
            return state.loading;
        },
        periodsOptions(state) {
            return onePeriod;
        },
        totalOperationalAlarms(state) {
            if(!state.data || !state.data.operationalAlarms) {
                return '-';
            }
            let result = 0;
            result += state.data.operationalAlarms.ok;
            result += state.data.operationalAlarms.poor;
            result += state.data.operationalAlarms.problem;
            return result;
        },
        solutionData(state) {
            return state.data;
        }
    },
    mutations:{
        setLoading(state,value) {
            state.loading = value;
        },
        selectStructure(state,value) {
            state.currentStructureId = value;
        },
        selectSolution(state,value) {
            state.currentSolutionId = value;
        },
        selectPeriod(state,value) {
            state.currentPeriodIndex = value;
        },
        setData(state,data) {
            state.data = data;
        }
    },
    actions:{
        async fetchSolutionData({state,commit},payload) {
            let params = {
                from: onePeriod[state.currentPeriodIndex].from().toDate(),
                to: onePeriod[state.currentPeriodIndex].to().toDate(),
                id: state.currentStructureId,
                by: state.currentStructureId ? 'structure' : undefined
            };
            commit('setLoading',true);
            try {
                const data = await SwamService.getSolutionStatistics(params,state.currentSolutionId);
                commit('setData',data[state.currentSolutionId]);
                commit('setLoading',false);
            } catch(error) {
                commit('setLoading',false);
            }
        },
        resetData({state}) {
            state.data = {};
        },
        reset({state,dispatch}) {
            dispatch('resetData');
            state.currentStructureId = undefined;
            state.currentSolutionId = undefined;
            state.currentPeriodIndex = 1;
        } 
    },
}