import IntrusionService from "../../services/intrusion.service"

export default {
    namespaced: true,

    state: {
        loading:false,
        statistics: []
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        getPoliceStationsStatistics(state){
            return state.statistics;
        }
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setData(state, data){
            state.statistics = data;
        }
    },
    actions: {
        async fetchPoliceStationsStatistics({ commit }) {
            commit('setLoading',true);
            try {
                const data = await IntrusionService.getPoliceStationsStatistics()
                commit("setData" , data);
                commit("setLoading", false);
            } catch (error) {
                commit("setLoading", false);
                console.error(error);
            }
        },
        reset({state}) {
            state.statistics = [];
        }
    }

}