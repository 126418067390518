import api from "src/axiosWithDelimiterFile";
import { VSaaSConfigurations } from "./configuration";
import { resolvePromiseForObjectOrNull } from "../../common/wrappers/promise-resolver";

/**
 * @description a service for dealing with operational alarms for VsaaS Module
 */
export class VsaaSAlarmsService {

  /**
   *
   * @param {Object} QueryParams
   */
  async getVSaaSAlarms(QueryParams) {
    const promise = api.get(VSaaSConfigurations.GETTING_ALARMS_URL, {
      params: QueryParams
    });
    return resolvePromiseForObjectOrNull(promise);
  }

  /**
   *
   * @param {Object} QueryParams
   */
  async getAlarmsHistory(QueryParams) {
    const promise = api.get(VSaaSConfigurations.GETTING_ALARMS_HISTORY_URL, {
      params: QueryParams
    });
    return resolvePromiseForObjectOrNull(promise);
  }

  /**
   *
   * @param {Number} alarmId
   * @param {String} comment
   */
  async acknowledgeAlarm(alarmId, comment) {
    const promise = api.post(
      VSaaSConfigurations.POST_ACKNOWLEDGE_ALARM(alarmId),
      { comment }
    );
    return resolvePromiseForObjectOrNull(promise);
  }

  /**
   *
   * @param {Number} alarmId
   * @param {Object} escalateObject
   */
  async escalateAlarm(alarmId, escalateObject) {
    const promise = api.post(
      VSaaSConfigurations.POST_ESCALATE_ALARM(alarmId),
      escalateObject
    );
    return resolvePromiseForObjectOrNull(promise);
  }

  /**
   *
   * @param {Number} alarmId
   * @param {Object} terminateObject
   */
  async terminateAlarm(alarmId, terminateObject) {
    const promise = api.post(VSaaSConfigurations.POST_TERMINATE_ALARM(alarmId), terminateObject);
    return resolvePromiseForObjectOrNull(promise);
  }

  /**
   * 
   * @param {Number} deviceId 
   */
  async getDevicesCompanies(deviceId) {
    const promise = api.get(VSaaSConfigurations.GETTING_DEVICES_COMPANIES(deviceId));
    return resolvePromiseForObjectOrNull(promise);
  }

  /**
   * 
   */
  async getAlarmsStatistics() {
    const promise = api.get(VSaaSConfigurations.GETTING_ALARMS_STATISTICS);
    return resolvePromiseForObjectOrNull(promise);
  }

  async getMoreInformationForAlarms() {
    const promise = api.get(VSaaSConfigurations.ON_ALARMS_MORE_INFORMATION_URL);
    return resolvePromiseForObjectOrNull(promise);
  }
}
