import IntrusionService from "../../services/intrusion.service"
import { onePeriod } from "@/util/periodOptions";

export default {
    namespaced: true,
    state: {
        loading: false,
        data: {},
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        },
        onePeriod(){
            return onePeriod;
        }
    },
    mutations: {
        setLoading(state,value) {
            state.loading = value;
        },
        setData(state,data) {
            state.data = data;
        }
    },
    actions: {
        async fetchData({state,commit}, selectedPeriodIndex) {
            let params = {
                start: onePeriod[selectedPeriodIndex].from().toDate(),
                end: onePeriod[selectedPeriodIndex].to().toDate(),
            };
            commit('setLoading',true);
            try {
                let data = await IntrusionService.getMoroStatistics(params);
                commit('setData',data.data);
                commit('setLoading',false);
            } catch (error) {
                console.log(error);
                commit('setLoading',false);
            }
        }
    }
}