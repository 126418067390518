import Vue from "vue";
import Vuex from "vuex";
import api from "./../axiosWithDelimiterFile.js";
import myRouter from "../routes/router";

//import * as rx from 'rxjs';
import { UserPreferencesStore } from "../global/user-preferences-store/index";

import SetupWatch from './SetupWatch.js';

import CountriesAndCitiesStore from "../components/CountriesAndCities/store";
import workorderstore from "src/pages/WorkOrder/store.js";
import TeltonikaStore from "src/pages/TeltonikaDevices/store.js";
import devicesstore from "src/pages/Devices/store";
//import HomeAutomationStore from 'src/pages/HomeAutomation/store.js';
import MaintenaceStore from "./../pages/Maintenance/MaintenaceStore";
import TicketingStore from "./../pages/Ticketing/Ticketing-Store";
import StructureStore from "./../pages/Structure/Structures/Structure-stores";
import ProfilesStore from "../pages/Profiles/Profiles-Store";

// import PermissionsStore from "./../pages/Permissions/Permissions-store"
import ReportsDesignerStore from "../pages/Reports-Designer/store";
import CarTrackerStore from 'src/pages/Tracking/CarTracker/store.js';
import VsassStore from 'src/pages/SmartTracker/VsassCustomer/store.js'
import notifications from "../pages/Notifications/store";

// Logs store
import LogsStore from "../pages/Logs/store";

import IntrusionSystem from "../pages/Intrusion-System/store/intrusion-store";
import { VSaaSStore } from "../modules/vsaas/store/vsass-store";
import { IntrusionStore } from "../modules/intrusion-system/store/intrusion-system-store";
import  ForkliftStore  from "../pages/Forklift/store/store";
import GlobalMobility from '../pages/GlobalMobility/store/store'
import SmartLockStore from '../pages/SmartLock/store/store'

import SwamStore from "../pages/SWAM/store/swam-store";
import VsaasStore from "../pages/VSAAS/store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    IntrusionSystem,
    IntrusionStore,
    UserPreferencesStore,
    workorderstore,
    MaintenaceStore,
    TicketingStore,
    devicesstore,
    ForkliftStore,
    GlobalMobility,
    //HomeAutomationStore,
    TeltonikaStore,
    ProfilesStore,
    // UserProfileStore,
    StructureStore,
    ReportsDesignerStore,
    CarTrackerStore,
    VsassStore,
    notifications,
    // PermissionsStore
    CountriesAndCitiesStore,
    VSaaSStore,
    LogsStore,
    SwamStore,
    VsaasStore,
    SmartLockStore
  },

  plugins: [

    SetupWatch,
  ],

  getters: {
    companyPlaceholderImage() {
      return "/img/company-placeholder.png";
    },
    userPlaceholderImage() {
      return "/img/placeholder.jpg";
    },
    spinner(state) {
      return state.spinner;
    },
    blockSpinner(state) {
      return state.blockSpinner;
    },
    isSuperAdmin(state) {
      return state.user.role_id == 1;
    },
    isAdmin(state) {
      return state.user.role_id == 2;
    },
    isPoliceUser(state) {
      return state.user.role_id == 9;
    },
    isRegularUser(state, g) {
      return !g.isAdmin && !g.isSuperAdmin;
    },
    themMode(state) {
      return state.themMode;
    },
    user(state) {
      return state.user;
    },
    units(state) {
      const data = {};
      state.sensor_type.forEach(s => {
        data[`${s.id}`] = s.unit;
      });
      return data;
    },
    isDarkMode(state) {
      return state.themMode == "dark";
    }
  },

  state: {

    preferences: {
      id: null,
      nav_bar_color: "vue",
      dark_mode: true,
      language: "en"
    },
    themMode: "dark",
    accounttypes: [],
    device_types: [],
    blockSpinner: false,
    blockSpinnerCounts: 0,
    reads: [],
    spinner: false,
    changePassPage: false,
    pushleft: true,
    user:
      localStorage.getItem("user") != "undefined"
        ? JSON.parse(localStorage.getItem("user"))
        : null,

    //primary
    solutions: [],
    device_categories: [],
    communications: [],
    sensor_type: [],
    parkings: [],
    roles: [],
    notification_methods: ["email", "sms", "whatsapp"],
    holduserdata: null,
    structure_types: [
      {
        id: 1,
        name: "building"
      },
      {
        id: 2,
        name: "villa"
      },
      {
        id: 3,
        name: "warehouse"
      },
      {
        id: 4,
        name: "other"
      }
    ],
    companiesTypes: [
      { value: "amc", label: "Amc" },
      { value: "fmc", label: "Facility" },
      { value: "authority", label: "Authority" },
      { value: "customer", label: "Customer" },
      { value: "operator", label: "Operator" },
      { value: "police_station", label: "Police Station" }
    ]
  },
  mutations: {
    updateCurrentUser(state, userData) {
      state.user = userData;
      localStorage.setItem("user", JSON.stringify(userData));
    },
    changeThem(state, newThem) {
      state.themMode = newThem;
    },
    pushMenuLeft(state) {
      state.pushleft = !state.pushleft;
    },
    setSpinner(state, status) {
      if (status) {
        state.blockSpinnerCounts++;
      } else {
        state.blockSpinnerCounts--;
      }
      if (state.blockSpinnerCounts <= 0) {
        state.spinner = false;
        state.blockSpinner = false;
        state.blockSpinnerCounts = 0;
      } else {
        state.spinner = true;
        state.blockSpinner = true;
      }
    },
    setBlockSpinner(state, status) {
      if (status) {
        state.blockSpinnerCounts += 5;
      } else {
        state.blockSpinnerCounts -= 4;
      }
      if (state.blockSpinnerCounts <= 0) {
        state.spinner = false;
        state.blockSpinner = false;
        state.blockSpinnerCounts = 0;
      } else {
        state.spinner = true;
        state.blockSpinner = true;
      }
    },
    setChangePassPage(state, status) {
      state.changePassPage = status;
    },
    login(state, data) {
      console.log("+++ login");
      state.user = data;
      localStorage.setItem("user", JSON.stringify(data));
    },
    deleteUser(state) {
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");

      if (api.defaults.headers.common.Authorization) {
        delete api.defaults.headers.common["Authorization"];
      }
    },
    logout(state) {
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");

      this.commit("notifications/reset");
      this.commit("operationalAlarms/reset");
      this.commit("statusAlarms/reset");

      if (api.defaults.headers.common.Authorization) {
        delete api.defaults.headers.common["Authorization"];
      }

      // api.post('user/logout', {}).then(() => {
      // }).catch(() => {})

      console.log("user logged OUT");

      if (myRouter.history.current.name != "Login") {
        myRouter.push("/login");
      }
    },
    setholdUserData(state, data) {
      state.holduserdata = data;
    },
    setToken(state, token) {
      if (token) {
        localStorage.setItem("token", token);
        api.defaults.headers.common.Authorization = `Bearer ${token}`;
        this.dispatch("init");
      }
    },
    setRefreshToken(state, refreshToken) {
      if (refreshToken) {
        localStorage.setItem("refreshToken", refreshToken);
      }
    }
    /*    setUserData(state, data) {

         if (data) {
           localStorage.setItem('user', data)
         }
       }, */
  },
  actions: {
    init({ dispatch }) {
      console.log("store init >>");
      dispatch("getPrimaryInfo");
    },
    clear({ state }) {
      state.reads = [];
      state.selected_device = null;
      //state.subject.next(null)
    },

    getPrimaryInfo({ state }) {
      api
        .get("general/primaryinfo")
        .then(res => {
          if (res.data.msg == "success") {
            let data = res.data.data;
            state.communications = data.communications;
            state.solutions = data.solutions;
            state.device_categories = data.device_categories;
            state.sensor_type = data.sensor_type;
            state.parkings = data.parkings;
            state.roles = data.roles;
            state.iotSolutions = data.grouped_solutions.IOT;
            state.teltonikaSolutions = data.grouped_solutions.Teltonika;
            let preferences = data.preferences;
            state.preferences = {
              id: preferences.id ? preferences.id : null,
              nav_bar_color: preferences.nav_bar_color
                ? preferences.nav_bar_color
                : "vue",
              dark_mode:
                preferences.dark_mode != null ? preferences.dark_mode : true,
              language: preferences.language ? preferences.language : "en"
            };
          }
        })
        .catch(() => {});
    },

    

    setPreferences({ state }, obj) {
      if (obj.silent) {
        state.preferences = {
          ...state.preferences,
          language: obj.language,
          actualLanguage: state.preferences.language
        };
        delete obj.language;
        delete obj.silent;
        if (!Object.keys(obj).length) return;
      }
      state.preferences = { ...state.preferences, ...obj };
      if (!obj.language)
        api
          .patch("user/preferences", obj)
          .then(_ => {})
          .catch(err => {
            console.log(err);
          });
    },

    uploadFile({ state }, { url, file }) {
      var formData = new FormData();
      formData.append("file", file);
      return api.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    },
    downloadFile(
      { state },
      {
        url,
        filename,
        method = "get",
        data = {
          responseType: "blob"
        },
        params
      }
    ) {
      return api[method](
        url,
        data,
        {
          responseType: "blob",
          params
        },
        data
      )
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
});

export const users_types = {
  Employee: "employee",
  Supervisor: "supervisor"
  // Visitor: 'visitor',
  // Technician: 'technician',
  // Team_leader: 'team_leader'
};

export const translatedPages = [
  "structure-portfolio",
  "solution-portfolio",
  "/reports-builder",
  "/logs",
  "/maintenance",
  "/profiles",
  "/device",
  "SWAM/Alarms",
  "/template",
  "/order",
  "dashboard"
];
// export const translatedPages = []
