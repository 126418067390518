<template>
  <div class="container d-flex">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <form @submit.prevent="login">
        <card class="card-login card-white">
          <template slot="header" class="ins-card">
            <img :src="logo" class="img-responsive logo-login" />
            <small>
              <p class="mt-2" style="color: #3d3d3d !important;">{{desc}}</p>
            </small>
          </template>

          <div dir="ltr" class="inputs">
            <base-input
              name="email"
              class="shadow-fix"
              v-model="$v.email.$model"
              :placeholder="$t('email')"
              :addon-left-icon=" !isRTL ? 'tim-icons icon-email-85': ''"
              :addon-right-icon=" isRTL ? 'tim-icons icon-email-85': ''"
              autofocus
            >
              <template v-if="$v.email.$error" v-slot:error>
                <label
                  class="error"
                  v-if="!$v.email.required"
                >{{ $t('email') + ' ' + $t('validations.isRequired')}}</label>
                <label
                  class="error"
                  v-if="!$v.email.email"
                >{{ $t('email') + ' ' + $t('validations.notValid')}}</label>
              </template>
            </base-input>

            <base-input
              name="password"
              class="shadow-fix"
              v-model="$v.password.$model"
              type="password"
              :placeholder="$t('account.password')"
              :addon-left-icon="!isRTL ? 'tim-icons icon-lock-circle' : ''"
              :addon-right-icon="isRTL ? 'tim-icons icon-lock-circle' : ''"
            >
              <template v-if="$v.password.$error" v-slot:error>
                <label
                  class="error"
                  v-if="!$v.password.required"
                >{{ $t('account.password') + ' ' + $t('validations.isRequired')}}</label>
              </template>
            </base-input>

            <!-- online -->
            <vue-recaptcha @expired="recaptchaExpired" :sitekey="sitekey" @verify="verify"></vue-recaptcha>
            <template v-if="$v.security.$error">
              <label
                class="error"
                v-if="!$v.security.required"
              >{{ $t('validations.recaptcha') + $t('validations.isRequired') }}</label>
            </template>
          </div>

          <div slot="footer">
            <base-button
              native-type="submit"
              type="success"
              class="mb-3"
              size="lg"
              block
            >{{ $t('account.getStarted') }}</base-button>
            <div class="pull-left" dir="auto">
              <h6>
                <a
                  class="link footer-link"
                  href="#"
                  @click="forgetPassword=true"
                >{{ $t('account.forgotPassword') }}</a>
              </h6>
            </div>

            <!-- <div class="pull-right">
              <h6>
                <a href="#pablo" class="link footer-link">Need Help?</a>
              </h6>
            </div>-->
            <div class="text-center text-Info" style="clear:both">
              <h6>
                <span class="span-info">{{ $t('supportEmail') }} :</span>
                <a :href="'mailto:'+ support" class="link footer-link">{{support}}</a>
              </h6>
            </div>
            <div class="text-center text-Info" style="clear:both">
              <h6>
                <span class="span-info">{{ $t('supportPhone') }} :</span>
                <a
                  href="javascript:void(0)"
                  class="link footer-link"
                >{{phone}} ( {{ $t('withinUAE') }} )</a>
              </h6>
            </div>
          </div>
        </card>
      </form>
    </div>
    <modal :show.sync="forgetPassword" body-classes="p-0" modal-classes="modal-dialog-centered">
      <ForgotPassword @back="forgetPassword=false"></ForgotPassword>
    </modal>
  </div>
</template>
<script>
import VueRecaptcha from "vue-recaptcha";
import ForgotPassword from "./ForgotPassword.vue";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import axios from "axios";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      email: "",
      password: "",
      subscribe: true,
      security: null,
      forgetPassword: false
    };
  },
  components: {
    VueRecaptcha,
    ForgotPassword
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    },
    security: {
      required: requiredIf(function() {
        return process.env.VUE_APP_ENVI === "production";
      })
    }
  },
  computed: {
    sitekey() {
      return process.env.VUE_APP_ENVI === "production"
        ? process.env.VUE_APP_RECAPTCHA_SITE_KEY
        : process.env.VUE_APP_RECAPTCHA_LOCAL_KEY;
    },
    support() {
      return process.env.VUE_APP_SUPPORT;
    },
    phone() {
      return process.env.VUE_APP_PHONE;
    },
    logo() {
      return process.env.VUE_APP_LOGO;
    },
    desc() {
      return process.env.VUE_APP_DESC;
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  methods: {
    verify(response) {
      this.$api
        .post("/recaptcha", {
          response
        })
        .then(res => {
          console.log(res);
          if (res.data.success) {
            this.security = "success";
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    recaptchaExpired() {
      console.log("*************** the recaptcha is expired!!!!***********");
      this.security = null;
    },

    async login() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let data = {
          email: this.email,
          password: this.password
        };

        this.$store.commit("setSpinner", true);

        this.$api
          .post("auth/login", data)
          .then(res => {
            let data = res.data;

            if (data.msg == "success") {
              this.$store.commit("login", data.data);
              this.$store.commit("setToken", data.token);
              if (data.refresh_token != undefined) {
                localStorage.removeItem("refreshToken");
                this.$store.commit("setRefreshToken", data.refresh_token);
              }

              this.$router.push({ path: "/" });
            } else {
              this.$store.commit("setholdUserData", data);
              this.$router.push({ path: "/token" });
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }
};
</script>
<style scoped>
.error {
  color: red;
}
@media (min-width: 576px) {
  .text-Info span,
  .text-Info a {
    color: #000000 !important;
    font-size: 0.5rem !important;
  }
  .card-header {
    padding-bottom: 35px !important;
  }
  .card-title {
    font-size: 32px !important;
  }
}

@media (min-width: 768px) {
  .span-info {
    display: inline-block;
    width: 31%;
  }
}

@media (min-width: 992px) {
  .span-info {
    display: inline-block;
    width: 35%;
  }
}

@media (min-width: 1200px) {
  .span-info {
    display: inline-block;
    width: 28%;
  }
  .text-Info span,
  .text-Info a {
    font-size: 0.75rem;
  }
}
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.login-page .card-login.card-white .card-header .card-title {
  color: rgb(46, 186, 116);
  text-align: center;
}
.login-page .link {
  color: rgb(46, 186, 116);
  font-size: 11px;
}

.login-page .card-login.card-white .form-control:focus,
.login-page .card-login.card-white .form-group .el-input__inner:focus,
.form-group .login-page .card-login.card-white .el-input__inner:focus,
.login-page
  .card-login.card-white
  .el-date-picker
  .el-input
  .el-input__inner:focus,
.el-date-picker
  .el-input
  .login-page
  .card-login.card-white
  .el-input__inner:focus {
  border-color: rgb(0, 242, 195) !important;
}
.login-page .input-group-focus,
.input-group-focus div,
.input-group:focus div,
.input-group-text:focus,
.input-group:active div,
.input-group:visited div {
  border-color: rgb(0, 242, 195) !important;
}
.text-Info span {
  color: rgba(29, 26, 26, 0.8) !important;
}
.login-page .card-login .card-header {
  padding: 15px 15px 0;
}
.card-login >>> .card-header {
  text-align: center;
}
.login-page .card-login .card-header img {
  position: relative;
  width: 80px;
}
.login-page .card-login >>> .card-header {
  padding: 15px 15px 0;
}

::v-deep .inputs .input-group .form-control:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
</style>
