import { app } from "../../../../main"
import AlarmsActionsStore from "@/pages/SWAM/components/AlarmsComponents/AlarmsActionsStore";
import {
    dateTableCellFomatter,
} from "@/modules/common/utils/helper";
import i18n from '@/i18n'
import SwamService from '@/pages/SWAM/services/swam.service';

export default {
    namespaced: true,
    modules:{
        AlarmsActionsStore
    },
    state: {
        loading: false,
        alarms: [],
        totalAlarms: 0,
        filter: {
            solutionId: undefined,
            structureId: undefined,
            cityId: undefined,
            communityId: undefined,
            acknowledgement: undefined,
            status: undefined // low battery || not responding || expiring
        },
        pagination: {
            limit: 10,
            offset: 0,
        },
        isSocketConnected: false,
        columns: [
            {
                prop:"id",
                label:i18n.t("alarmID"),
                minWidth:"100",
            },
            {
                prop:"device.device_unique",
                label:i18n.t("deviceWords.serial"),
                minWidth:"150",
                customTemplate: true
            },
            {
                prop:"created_at",
                label:i18n.t("receiveTime"),
                minWidth:"150",
                formatter: dateTableCellFomatter
            },
            {
                prop:"updated_at",
                label:i18n.t("lastUpdate"),
                minWidth:"150",
                formatter: dateTableCellFomatter
            },
            {
                prop:"acknowledged_at",
                label:i18n.t("acknowledgedBy"),
                minWidth:"200",
                customTemplate: true
            },
            {
                prop:"status",
                label:i18n.t("alarmType"),
                minWidth:"200",
                formatter:(row, col, cellValue) => {
                    cellValue = cellValue.toLowerCase();
                    return i18n.t(`alarmsTypes.${cellValue}`);
                }
            },
            {
                prop:"last_response_at",
                label:i18n.t("deviceWords.lastResponseAt"),
                minWidth:"200",
                formatter: dateTableCellFomatter
            },
            {
                prop:"battery_level",
                label:i18n.t("deviceWords.batteryLevel"),
                minWidth:"200",
                customTemplate: true
            },
            {
                prop:"contract_expires_at",
                label:i18n.t("contractExpiryDate"),
                minWidth:"200",
                formatter: dateTableCellFomatter
            },
            {
                prop:"ended_at",
                label:i18n.t("normalizationTime"),
                minWidth:"140",
                formatter: dateTableCellFomatter
            },
        ]
    },
    getters: {
        alarms(state) {
            return state.alarms
        },
        totalAlarms(state) {
            return state.totalAlarms;
        },
        loading(state) {
            return state.loading
        },
        filter(state) {
            return state.filter;
        },
        isLive(state) {
            return state.isSocketConnected;
        },
        pagination(state) {
            return state.pagination;
        },
        statusFilterOptions(state) {
            return [
                { title: 'off', value: false },
                { title: 'low battery', value: false },
                { title: 'contract expiring', value: false },
            ]
        },
        columns(state) {
            return state.columns;
        }
    },
    mutations: {
        reset(state) {
            state.loading = false;
            state.alarms = [];
            state.totalAlarms = 0;
            state.filter = {
                solutionId: undefined,
                structureId: undefined,
                cityId: undefined,
                communityId: undefined,
                acknowledgement: undefined,
                status: undefined, // ok || poor || problem
            };
            state.pagination = {
                limit: 10,
                offset: 0,
            };
            state.isSocketConnected = false;
            state.currentAction = null;
        },
        setFilter(state, newFilter) {
            state.filter = newFilter;
        },
        setPaginationFilter(state, data) {
            state.pagination = data;
        },
        resetPaginationFilter(state, data) {
            state.pagination = {
                limit: 10,
                offset: 0,
            };
        },
        startLoading(state) {
            state.loading = true;
        },
        finishLoading(state) {
            state.loading = false;
        },
        setAlarmsData(state, data) {
            state.alarms = data.data;
            state.totalAlarms = data.total_records;
        },
    },
    actions: {
        async updateFilter({ state, commit, dispatch }) {
            const {
                limit = state.pagination.limit,
                offset = state.pagination.offset,
                solutionId,
                structureId,
                cityId,
                communityId,
                acknowledgement,
                status
            } = state.filter;
            let params = {
                limit,
                offset,
                solution_id:solutionId,
                structure_id: structureId,
                city_id: cityId,
                community_id: communityId,
                acknowledged: acknowledgement, 
                status
            }
            commit("startLoading")
            try {
                const data = await SwamService.getStatusAlarms(params);
                commit('setAlarmsData', data);
                commit("finishLoading")
            } catch (error) {
                commit("finishLoading")
                console.error(error);
            }
        },
        getComments({ state, commit, dispatch, rootState }, alarm) {
            commit("startLoading")
            return SwamService.getAlarmComments('status',alarm.id)
                .finally(_ => {
                    commit("finishLoading")
                })
        },
        submitComment({ state, commit, dispatch, rootState }, { alarm, msgText }) {
            return SwamService.submitAlarmComment('status',alarm.id,msgText);

        },
        escalate({ state, commit, dispatch, rootState }, { alarm, data }){
            this.commit("setSpinner", true);
            return SwamService.escalateAlarm('status',alarm.id,data);
        },
        acknowledge({ state, commit, dispatch, rootState }, { alarm, data }){
            this.commit("setSpinner", true);
            return SwamService.acknowledgeAlarm('status',alarm.id,data);
        }
    }
}

export const AlarmStatusTypes = {
    off: "off",
    lowBattary: "low battery",
    contractExpiring: "contract expiring",
}