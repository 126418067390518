import ForkliftService from "../../services/forklift.service";

export default {
  namespaced: true,
  state: {
    alarms: [],
    loading: false,
    totalRecords: 5
  },
  getters: {
    alarms(state) {
      return state.alarms;
    },
    loading(state) {
      return state.loading;
    },
    totalRecords(state) {
        return state.totalRecords
    }
  },
  mutations: {
    setAlarms(state, alarms) {
      state.alarms = alarms;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setTotal(state, total) {
        state.totalRecords = total
    }
  },
  actions: {
    fetchAlarms({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true);
        ForkliftService.getPortfolioAlarms(payload).then(res => {
          commit("setTotal", res.data.total_records)
          commit("setLoading", false);
          commit('setAlarms', res.data.data);
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      });
    }
  },
  
}