import ScooterService from "../../services/globalMobility.service";

export default {
    namespaced: true,
    state: {
        loading: false,
        data: [],
        paginationData: {},
        totalRecords: 0
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        },
        pagination(state) {
            return state.pagination
        },
        totalRecords(state) {
            return state.totalRecords
        }
    },
    mutations: {
        SET_LOADING(state, value) {
            state.loading = value;
        },
        SET_DATA(state, data) {
            state.data = data;
        },
        setPagination(state, pagination) {
            state.pagination = pagination
        },
        SET_TOTALRECORDS(state, total) {
            state.totalRecords = total
        }
    },
    actions: {
        async fetchScootersData({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);

                ScooterService.getScooters(payload)
                    .then((res) => {
                        commit("SET_DATA", res.data.data);
                        commit("SET_TOTALRECORDS", res.data.total_records)
                        commit("SET_LOADING", false);
                        resolve(res.data.data)
                    })
                    .catch((err) => reject(err));
            });
        },
    }
}