<template>
  <card class="card-user">
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
    </div>
    <router-view></router-view>

    <el-dialog :visible.sync="openDialog">
      <template v-if="center">
        <base-map :zoom="20" :center="center" style="height: 400px">
            <base-map-marker :position="center"/>
        </base-map>
      </template>
      <template v-if="imageObject">
        <p
          class="mb-1"
          style="word-break: break-word;letter-spacing: .8px;"
          v-for="(text, index) in imageObject.text"
          :key="index"
        >{{text}}</p>
        <img :src="imageObject.img" alt />
      </template>
      <template v-if="theNewUser">
        <h3 slot="title" class="mb-0">Register New User With This Company</h3>
        <CreateUser />
      </template>
      <template v-if="teltonika_device">
        <teltonikaAssignment />
      </template>
    </el-dialog>
  </card>
</template>

<script>
import { Dialog } from "element-ui";
import { mapGetters } from "vuex";
import CreateUser from "./Create-User/InitalUser-Info";
import TeltonikaAssignment from "./TeltonikaAssignment";
import BaseMap from 'src/components/BaseMap/Map'
import MapMarker from "src/components/BaseMap/MapMarker.vue";

export default {
  computed: {
    ...mapGetters({
      center: "getAddressMap",
      imageObject: "imageObject",
      theNewUser: "getNewUser",
      CompanyData: "CompanyData",
      teltonika_device:'teltonika_device'
    }),
    openDialog: {
      get() {
        return this.center || this.imageObject || this.theNewUser || this.teltonika_device
          ? true
          : false;
      },
      set(data) {
        this.$store.commit("openAddressMap", null);
        this.$store.commit("openImage", null);
        this.$store.commit("initNewUser", null);
        this.$store.commit("teltonika_device", null);
      },
    },
  },
  components: {
    [Dialog.name]: Dialog,
    CreateUser,
    TeltonikaAssignment,
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker,
  },
  destroyed(){
    this.$store.commit('resetProfilesStore');
  }
};
</script>

<style scoped>
.card {
  height: 95%;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.4);
}
.card-user .author .block {
  width: 350px;
}
.card-user .author .block.block-one {
  transform: rotate(10deg);
  margin-top: -70px;
  margin-left: -45px;
}
.card-user .author .block.block-two {
  transform: rotate(30deg);
  margin-top: -17px;
  margin-left: 0px;
  left: -25px;
}
.card-user .author .block.block-three {
  transform: rotate(170deg);
  margin-top: -65px;
  right: -15px;
}
.card-user .author .block.block-four {
  transform: rotate(150deg);
  margin-top: -25px;
  right: -45px;
}
@media (min-width: 768px) {
  .card-user .avatar {
    top: -20px;
  }
  .user-name {
    margin-top: -20px;
  }
}
::v-deep .el-dialog__body {
  /* text-align: center; */
  padding-top: 15px;
}
/* ::v-deep .create-employee .el-dialog__header {
  display: none;
}
::v-deep .create-employee .el-dialog__body {
  padding: 0;
  text-align: left;
} */
</style>