import {applyUniqueIdToAllObjectKeys} from "../../../../util/unique-id-generator";

let types = {
  GETTING_ALARMS_STATE_TYPE: 'GETTING_ALARMS_STATE_TYPE',
  GETTING_ALARMS_HISTORY_STATE_TYPE: 'GETTING_ALARMS_HISTORY_STATE_TYPE',
  OPERATIONAL_ALARMS_HISTORY_TABLE_COLUMNS_STATE_TYPE: 'OPERATIONAL_ALARMS_HISTORY_TABLE_COLUMNS_STATE_TYPE',
  OPERATIONAL_ALARMS_TABLE_COLUMNS_STATE_TYPE: 'OPERATIONAL_ALARMS_TABLE_COLUMNS_STATE_TYPE',
  ALARMS_TOTAL_RECORDS_STATE_TYPE: 'ALARMS_TOTAL_RECORDS_STATE_TYPE',
  CUSTOMER_TABLE_COLUMNS_STATE_TYPE: 'CUSTOMER_TABLE_COLUMNS_STATE_TYPE',
  CUSTOMER_STATE_TYPE: 'CUSTOMER_STATE_TYPE',
  CUSTOMER_TOTAL_RECORDS_STATE_TYPE: 'CUSTOMER_TOTAL_RECORDS_STATE_TYPE',
  CUSTOMER_LOCATIONS_ARRAY_STATE_TYPE: 'CUSTOMER_LOCATIONS_ARRAY_STATE_TYPE',
  CUSTOMER_CONTACTS_LIST_STATE_TYPE: 'CUSTOMER_CONTACTS_LIST_STATE_TYPE',
  OPEN_ACKNOWLEDGEMENT_MODAL_STATE_TYPE: 'OPEN_ACKNOWLEDGEMENT_MODAL_STATE_TYPE',
  OPEN_COMMENTS_MODAL_STATE_TYPE: 'OPEN_COMMENTS_MODAL_STATE_TYPE',
  OPEN_TERMINATE_MODAL_STATE_TYPE: 'OPEN_TERMINATE_MODAL_STATE_TYPE',

  COMMENTS_USERS_STATE_TYPE: 'COMMENTS_USERS_STATE_TYPE',
  COMMENTS_LIST_STATE_TYPE: 'COMMENTS_LIST_STATE_TYPE',
  OPEN_ESCALATE_MODAL_STATE_TYPE: 'OPEN_ESCALATE_MODAL_STATE_TYPE',
  DEVICES_COMPANIES_STATE_TYPE: 'DEVICES_COMPANIES_STATE_TYPE',
  GETTING_ALARMS_STATISTICS_STATE_TYPE: 'GETTING_ALARMS_STATISTICS_STATE_TYPE',
  HISTORY_ALARMS_TOTAL_RECORDS_STATE_TYPE: 'HISTORY_ALARMS_TOTAL_RECORDS_STATE_TYPE',
  CUSTOMER_INFORMATION_STATE_TYPE: 'CUSTOMER_INFORMATION_STATE_TYPE'

  
};


types = applyUniqueIdToAllObjectKeys(types);
export default types;
