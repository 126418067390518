import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import List from "./List.vue";
import reportEditor from "./Editor.vue";
import { M } from "../../Permissions";
import store from "./../../store/index";
export default {
  path: '/reports-builder',
  component: DashboardLayout,
  redirect: '/reports-builder/list',
  name: 'reports builder',
  meta: {
    module: M.reports_builder
  },
  children: [
    {
      path: 'list',
      name: 'reports list',
      component: List
    },
    {
      path: 'editor',
      name: 'report editor',
      component: reportEditor,
      beforeEnter: (to, from, next) => {
        store.dispatch('SwamStore/FETCH_ITEMS',{
          key:'all',
        }).then(_=>{
            next();
        }).catch(_=>{
            next(false);
        });
    },
      props:true
    }
  ]
}
