import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';




const CameraPosition = () =>
    import('./CameraPosition.vue');

const UploadFiles = () =>
    import('./UploadFiles.vue');






export default {
        path: '/home-3d-wizard',
        component: DashboardLayout,
        redirect: '/',
        name: '',
        children: [
            
            {
                path: '/camera-position',
                name: 'Home Camera Controll',
                component: CameraPosition
            },
            {
                path: '/upload-files/:id',
                name: 'upload home files',
                component: UploadFiles,
                props:true
            },






        ]
    }



