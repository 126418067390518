<template>
  <card v-loading="loading" class="pl-2 pr-2 text-align-start" style="height: 157px;">
      <div class="row">
          <div class="col-9">
            <router-link
              :to="getUrl()"
              class="clickable font-color-4 font-weight-bold h4"
            >
              {{$t('deviceWords.statusAlarms')}}
            </router-link>
          </div>
          <h4 class="col-3 font-weight-bold m-0">{{ total }}</h4>
      </div>
      <hr class="mt-1 mb-1" style="border-top-width: 2px;"/>
      <div class="d-flex flex-column justify-content-between" style="height: 68%">
        <div class="row font-weight-900 ">
          <div class="col-9">
            <router-link
              :to="getUrl('contract expiring')"
              class="clickable font-color-4 font-weight-900"
            >
              {{ $t('companyWords.contractExpiring') }}
            </router-link>
          </div>
          <div class="col-3">
              <p class="font-color-5">{{ data.contractsExpiring }}</p>
          </div>
        </div>
        <div class="row font-weight-900">
          <div class="col-9">
            <router-link
              :to="getUrl('low battery')"
              class="clickable font-color-4 font-weight-900"
            >
              {{ $t('deviceWords.lowBattery') }}
            </router-link>
          </div>
          <div class="col-3">
              <p class="font-color-5">{{ data.lowBattery }}</p>
          </div>
        </div>
        <div class="row font-weight-900">
          <div class="col-9">
            <router-link
              :to="getUrl('off')"
              class="clickable font-color-4 font-weight-900"
            >
              {{ $t('alarmsTypes.off') }}
            </router-link>
          </div>
          <div class="col-3">
              <p class="font-color-5">{{ data.devicesOff }}</p>
          </div>
        </div>
      </div>
  </card>
</template>

<script>
import { mapActions, mapGetters,mapState } from "vuex";
export default {
    props:{
        namespace:{
            type:String,
            default: "SwamStore/StructurePortfolio"
            // required:true
        },
    },
    computed: {
        ...mapState({
            loading(state,getters){
              return getters[this.namespace+"/StructuresStatistics/loading"];
            },
            data(state,getters) {
             return getters[this.namespace+"/StructuresStatistics/statusAlarms"];
            },
            structureId(state,getters) {
                return getters[this.namespace+"/structureId"];
            }
        }),
        total() {
            let total = 0;
            total += this.data.devicesOff;
            total += this.data.lowBattery;
            total += this.data.contractsExpiring;
            return total;
        },
    },
    methods: {
        ...mapActions({
            fetchData(dispatch, payload){
                return dispatch(this.namespace+"/StructuresStatistics/fetchData",payload)
            },
        }),
        getUrl(state) {
            let url = '/SWAM/Alarms/status' + (this.structureId ? '?structure_id=' + this.structureId : '');
            if(!state) {
                return url;
            } else {
                return url + (this.structureId ? '&' : '?') + 'status=' + state;
            }
        },
    },
    created() {
        this.fetchData(this.structureId);
    },
    watch: {
        structureId() {
            this.fetchData(this.structureId);
        }
    }
};
</script>

<style scoped>
.text-align-start {
    text-align: start;
}
</style>