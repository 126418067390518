import api from "src/axiosWithDelimiterFile";
export default class PortfolioService {

static async getCities() {
    const response = await api.get(`/teltonika/forklift/portfolio/cities`)
    return response
  }
  static async getMap(data) {
    const response = await api.get(`/teltonika/forklift/portfolio/map`, {
      params: {
        city_ids: data
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
    return response
  }
  // static async getMap() {
  //   const response = await api.get(`/teltonika/forklift/portfolio/map`)
  //   return response
  // }
  static async getPortfolioVehicleInfo() {
    const response = await api.get(`/teltonika/forklift/portfolio/basic-info`)
    return response
  }
  static async getPortfolioAlarms(payload) {
    console.log(payload)
    const response = await api.get(`/teltonika/forklift/portfolio/alarms?type=${payload.type}&?limit=${payload.limit}&offset=${payload.offset}`)
    return response
  }
  static async getDriverPerformance(date) {
    const response = await api.get(`/teltonika/forklift/portfolio/driver-performance?start=${date.start}&end=${date.end}`)
    return response
  }
  static async getAttentionAlarms(payload) {
    const response = await api.get(`/teltonika/forklift/portfolio/expiry-alarms?type=${payload.type}&?limit=${payload.limit}&offset=${payload.offset}`)
    return response
  }
  static async getVehicleList() {
    const response = await api.get(`/teltonika/forklift/portfolio/vehicle-list`)
    return response
  }
  static async getStatusAlarms() {
    const response = await api.get(`/teltonika/forklift/portfolio/status-alarms`)
    return response
  }
  static async getInventoryData(payload) {
    const response = await api.get(`/teltonika/forklift/portfolio/repair-inventory?limit=${payload.limit}&offset=${payload.offset}`)
    return response
  }
  static async getRepairHistoryData(payload) {
    const response = await api.get(`/teltonika/forklift/portfolio/${payload.id}/repair-history?limit=${payload.limit}&offset=${payload.offset}`)
    return response
  }
  static async getChartForkliftComparison(data) {
    const response = await api.get(`/teltonika/forklift/portfolio/forklift-speed-comparison?start=${data.start}&end=${data.end}&forklift1=${data.forklift1}&forklift2=${data.forklift2}`)
    return response;
  }
  static async getChartIntervalComparison(data) {
    const response = await api.get(`/teltonika/forklift/portfolio/interval-speed-comparison?start1=${data.start1}&end1=${data.end1}&start2=${data.start2}&end2=${data.end2}&forklift_id=${data.forklift_id}`)
    return response;
  }
}