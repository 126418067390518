import { getAllRaw } from "./../../Permissions";
import api from "./../../axiosWithDelimiterFile";
export default {
    state: {
        permissions: null,
        permissionsArray: [],
        modulesArray: [],
        modules: null,
        groups: null,
        rules: null,

        currentRow: null,
        selectedPermissions: null,
        includedGroups: null,
        mode: null,
        isGroupMode: false,
        isRulesMode: false,
        viewAliases: true
    },
    getters: {
        permissions__currentRow(state) {
            return state.currentRow;
        },
        permissions__selectedPermissions(state) {
            if (state.currentRow) {
                return state.currentRow.permissions;
            }
            return null;
        },
        permissions__includedGroups(state) {
            if (state.currentRow) {
                return state.currentRow.includedGroups;
            }
            return null;
        },
        isGroupMode(state) {
            return state.isGroupMode;
        },
        isRulesMode(state) {
            return state.isRulesMode;
        },
        permissionsPage__mode(state) {
            return state.mode;
        },
        viewAliases(state) {
            return state.viewAliases;
        }
    },
    mutations: {
        Aliases_display(state, newVal) {
            state.viewAliases = newVal;
        },
        permissionsPage_mode(state, mode) {
            if (mode == 'Group') {
                state.isGroupMode = true;
                state.isRulesMode = false;
                state.viewAliases = true;
            } else if (mode == 'Rule') {
                state.isRulesMode = true;
                state.isGroupMode = false;
                state.viewAliases = false;
            } else {
                throw new Error('Unknown Mode!')
            }
            state.mode = mode;
        },
        permissions__selectRow(state, newRow) {
            state.currentRow = newRow;
        },
        setPermissionsModules(state, { permissions, modules }) {
            state.permissions = permissions;
            getAllRaw.then((res) => {
                state.permissionsArray = res.permissionsArray;
                state.modulesArray = res.modulesArray;
            })
            state.modules = modules;
        },
        setGroups(state, gr) {
            gr.forEach(g => {
                g.display =
                    g.name +
                    `     ( ${g.permissions.length} ${
                    g.permissions.length == 1 ? "Permission" : "Permissions"
                    } )`;
            });
            state.groups = gr;
        },
        reset_permissions_store(state) {
            state.permissions = null;
            state.permissionsArray = [];
            state.modulesArray = [];
            state.modules = null;
            state.groups = null;
            state.rules = null;
            state.currentRow = null;
            state.selectedPermissions = null;
            state.includedGroups = null;
            state.mode = null;
            state.isGroupMode = false;
            state.isRulesMode = false;
            state.viewAliases = true;
        }
    },
    actions: {
        getGroups({ state }) {
            if (!state.groups) {

                this.commit("setSpinner", true);
                console.log('null')
                return api.get("permission/groups")
                    .then(res => {
                        this.commit('setGroups', res.data.groups);
                        return JSON.parse(JSON.stringify(res.data.groups));
                    })
                    .catch(err => console.error(err));
            }
            return new Promise(function (resolve) {
                resolve(JSON.parse(JSON.stringify(state.groups)))//deep copy
            })
        },
        getRules({ state }) {
            if (!state.rules) {
                this.commit("setSpinner", true);
                return api
                    .get("permission/rules")
                    .then(res => {
                        res.data.rules.forEach(r=>{
                            r.permissions = r.permissions.filter(p=>p.key);
                        });
                        state.rules = res.data.rules
                        return state.rules;
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
            else return state.rules;
        },
        getPermissions({ state }) {
            return JSON.parse(JSON.stringify(state.permissionsArray))
        },
        getModulesArray({ state }) {
            return JSON.parse(JSON.stringify(state.modulesArray))
        }
    }
}