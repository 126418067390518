import ForkliftService from "../../services/forklift.service";

export default {
  namespaced: true,
  state: {
    attentionAlarms: [],
    loading: false,
    totalRecords: 5
  },
  getters: {
    attentionAlarms(state) {
      return state.attentionAlarms;
    },
    loading(state) {
      return state.loading;
    },
    totalRecords(state) {
      return state.totalRecords
  }
  },
  mutations: {
    SET_ATTENTION_ALARMS(state, payload) {
      state.attentionAlarms = payload;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    SET_TOTAL(state, total) {
      state.totalRecords = total
  }
  },
  actions: {
    async fetchAttentionAlarms({ commit }, type) {
      return new Promise((resolve, reject) => {
        ForkliftService.getAttentionAlarms(type).then(res => {
          commit("SET_TOTAL", res.data.data.total_records)
          // commit("setLoading", false);
          commit('SET_ATTENTION_ALARMS', res.data.data.result);
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })  
      });
    }
  },
}