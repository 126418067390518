<template>
  <div>
    <card card-body-classes="table-full-width">
      <div class="customtableheader">
        <h4 slot="header" class="card-title">{{$t("Rules")}} {{$t("Permissions")}}</h4>
        <el-tooltip content="Create New Rule" effect="light" :open-delay="300" placement="top">
            <base-button type="primary" round icon @click="addRule">
              <i class="tim-icons icon-simple-add"></i>
            </base-button>
        </el-tooltip>
      </div>

      <div>
        <el-table :data="[...rules, ...newItems]">
          <el-table-column :min-width="300" :label="$t('Rule Name')">
            <div slot-scope="props">
              <NameField :ref="props.row.id ? '' : `added-${props.$index}`" :item="props.row" :newItems="newItems" @delete="removeItem(props.row ,newItems)" :edit="!props.row.status" :disabled="!props.row.status"/>
            </div>
          </el-table-column>

          <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label"/>

          <el-table-column :min-width="200" align="center" :label="$t('Actions')">
            <div slot-scope="props">
              <router-link :to="`/permissions/rules/${props.row.id}`" v-if="props.row.id">
                <el-tooltip content="Show Rule Permissions" effect="light" :open-delay="300" placement="top">
                  <base-button class="like btn-link" type="info" size="sm" icon>
                    <i class="tim-icons icon-notes"></i>
                  </base-button>
                </el-tooltip>
              </router-link>
            </div>
          </el-table-column>
        </el-table>
        <button :disabled="newItems.length == 0" @click="submit" class="btn btn-success">{{$t("Submit")}}</button>
      </div>
    </card>
  </div>

</template>

<script>
  import PermissionsModal from "./components/PermissionsModal";
  import SingleRow from "./components/SingleRow.vue";
  import NameField from "./components/NameField.vue";
  import { Dialog } from "element-ui";
  import { mapGetters } from "vuex";
  export default {
    components: {
      PermissionsModal,
      SingleRow,
      NameField,
      [Dialog.name]: Dialog
    },
    data() {
      return {
        rules: [],
        newItems: [],
        tableColumns: [
          {
            prop: `permissions.length`,
            label: this.$t('Permissions'),
            minWidth: 200,
          },{
            prop: `modules.length`,
            label: this.$t('Modules'),
            minWidth: 200,
          },
        ],

        showPermissionModal: false,
      };
    },
    methods: {
      addRule() {
        this.newItems.push({
          status: 'new',
          name: null,
          permissions: [],
          modules: [],
        });
      },
      removeItem(item, array) {
        let index = array.indexOf(item)
        array.splice(index, 1)[0];
      },
      submit() {

        if (this.validate()) {
          this.$notify({
            message: "There are Invalid Data",
            type: "danger"
          });
        } else {
          this.$store.commit("setSpinner", true);

          this.$api.post(`permission/rules`, this.newItems).then(res => {
            const arr = res.data.data;
            for (let i = 0; i < arr.length; i++) {
              if (arr[i]) {
                this.$notify({type: "success", message: `Rule With Name: ${this.newItems[i].name} Was Created`})

                this.rules.push({
                  name: this.newItems[i].name,
                  id: arr[i],
                  permissions: this.newItems[i].permissions,
                  modules: this.newItems[i].modules
                })
                this.newItems.splice(i, 1)
              } else {
                this.$notify({
                  type: "danger",
                  title: "Duplicated Name",
                  message: `Rule With Name: ${this.newItems[i].name} Already Exist`,
                  duration: 7000
                })
              }
            }
          });
        }
      },
      validate() {
        let flag = false

        if (this.newItems.length == 0) return

        Object.keys(this.$refs).forEach(elKey => {
          let el = this.$refs[elKey]
          el.$v.$touch()

          if (el.$v.$invalid || !el.unique) {
            flag = true;
          }
        })

        return flag
      },
    },

    created() {
      this.$store.commit("permissionsPage_mode", 'Rule');
      this.$store.dispatch(`getRules`)
        .then(res => {
          this.rules = res;
          this.newItems = [];
        })
        .catch(console.error);
    }
  };
</script>

<style scoped>
  table {
    background: linear-gradient(to bottom left, #344675, #263148, #344675);
    border-radius: 12px;
  }

  .white-content .table {
    background: var(--lighter);
  }

  ::v-deep .el-dialog__header {
    display: none;
  }

  ::v-deep .el-dialog__body {
    padding: 0;
  }

  .transfer-footer {
    margin-left: 20px;
    padding: 6px 5px;
  }
</style>
